<template>
    <div>
        <!-- breadcrumb -->
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Facturar cotización</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link to="/">Inicio</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link to="/logistica/tripulaciones">Tripulaciones</router-link>
                            </li>
                            <li class="breadcrumb-item active">Facturar cotización</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>

        <!-- creación -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col">
                        <div class="card">
                            <loading v-if="loading"/>

                            <div class="card-body">
                                <!-- mensaje de cotización enviada -->
                        


                        <!-- colaciones -->
                                <div class="row d-flex align-items-center mb-3">
                                    <div class="col-10">
                                        <h4 class="mb-0">Facturas</h4>
                                    </div>

                                    <div class="col">
                                        <button class="btn btn-primary float-right" 
                                                
                                                @click="setInvoice(null)">
                                            Añadir
                                        </button>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col table-responsive p-0">
                                        <table class="table table-hover" v-if="quotation.quotations!=null && quotation.quotations.length > 0">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Código</th>
                                                     <th>Cuenta Bancaria</th>
                                                    <th>Nº Factura</th>
                                                    <th>Orden de Compra</th>
                                                    <th>Fecha Orden de Compra</th>
                                                    <th>Proveedor</th>
                                                    <th>Tipo Proveedor</th>

                                                    <th>Acciones</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(t, i) in quotation.quotations">
                                                    <td>{{ i + 1 }}</td>
                                                    <td>FB{{ t.id }}</td>
                                                    <td>{{ t.account_number }} - {{ t.account_name }}</td>
                                                    <td>{{ t.invoice_number }}</td>
                                                    <td>{{ t.purchase_order }}</td>
                                                     <td>{{ t.purchase_order_date }}</td>
                                                    <td>{{ t.supplier }}</td>
                                                    <td>{{ t.supplier_type }}</td>
                                                  
                                                   
                                                    <td>
                                                        <a href="#" @click.prevent="setInvoice(t.id)"
                                                           v-tooltip="'Editar Factura'">
                                                            <i class="fa green fa-edit"/>
                                                        </a>
                                                    
                                                        <span >/</span>
                                                        <a href="#" @click.prevent="deleteInvoice(t.id)"
                                                           v-tooltip="'Eliminar factura'">
                                                            <i class="fa red fa-times"/>
                                                        </a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <p class="text-center mb-4" v-else>
                                            <i>No se han agregado facturas aún.</i>
                                        </p>
                                    </div>
                                </div>
                                <!-- fin colacion -->

                                   <!-- alojamiento -->
                                <div class="row d-flex align-items-center mb-3">
                                    <div class="col-10">
                                        <h4 class="mb-0">Alojamientos</h4>
                                    </div>

                              
                                </div>

                                <div class="row">
                                    <div class="col table-responsive p-0">
                                        <table class="table table-hover" v-if="quotation.accommodations.length > 0">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                     <th>Código</th>
                                                    <th>Reservas</th>
                                                    <th>Fecha Subida</th>
                                                    <th>Fecha Bajada</th>
                                                    <th>Precio</th>
                                                    <th>Proveedor</th>
                                                    <th>Tramo</th>
                                                    <th>Observacion</th>
                                                    <th>Factura</th>
                                                    <th >Acciones</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(t, i) in quotation.accommodations" >
                                                    <td>{{ i + 1 }}</td>
                                                    <td>AB{{ t.id }}</td>
                                                    <td>{{ t.quantity }}</td>
                                                    <td>{{ t.entry_date | myDate}}</td>
                                                    <td>{{ t.exit_date }}</td>
                                                    <td>{{ t.price | dash }}</td>
                                                    <td>{{ t.supplier }}</td>
                                                    <td>{{ journey(t.journey_type) }}</td>
                                                    <td>{{  t.observation}}</td>
                                                     <td v-if="t.invoice_id!=null">FB{{ t.invoice_id }}</td>
                                                     <td v-else> - </td>
                                                    <td >      
                                                        <a href="#" @click.prevent="associateQuotationAccommodation(t.id,t.invoice_id)" v-if="t.quantity>0"
                                                           v-tooltip="'Asignar Factura a Alojamiento'">
                                                            <i class="fa green fa-file-invoice" v-if="t.invoice_id!=null"/>
                                                            <i class="fa gray fa-file-invoice" v-else/>
                                                        </a>


                                                        <span v-if="t.invoice_id!=null && t.quantity>0" >/</span>
                                                        <a href="#" @click.prevent="deleteQuotationAccommodation(t.id)" v-if="t.invoice_id!=null && t.quantity>0"
                                                           v-tooltip="'Eliminar factura'">
                                                            <i class="fa red fa-times"/>
                                                        </a>
                                                       
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <p class="text-center mb-4" v-else>
                                            <i>No se agregaron Alojamientos.</i>
                                        </p>
                                    </div>
                                </div>
                                <!-- fin alojamiento -->

                                   <!-- traslados -->
                                <div class="row d-flex align-items-center mb-3">
                                    <div class="col-10">
                                        <h4 class="mb-0">Traslados</h4>
                                    </div>

               
                                </div>

                                <div class="row">
                                    <div class="col table-responsive p-0">
                                        <table class="table table-hover" v-if="quotation.transfers.length > 0">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                     <th>Código</th>
                                                    <th>Reservas</th>
                                                    <th>Fecha Recogida</th>
                                                    <th>Entrada</th>
                                                    <th>Salida</th>
                                                    <th>Precio</th>
                                                    <th>Proveedor</th>
                                                    <th>Tramo</th>
                                                    <th>Observacion</th>
                                                    <th>Factura</th>
                                                    <th >Acciones</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(t, i) in quotation.transfers" v-if="t.quotation==1">
                                                    <td>{{ i + 1 }}</td>
                                                     <td>TB{{ t.id }}</td>
                                                    <td>{{ t.quantity }}</td>
                                                    <td>{{ t.upload_date | myDate }}</td>
                                                    <td>{{ t.entry_text }}</td>
                                                    <td>{{ t.exit_text }}</td>
                                                    <td>{{ t.price | dash }}</td>
                                                    <td>{{ t.supplier }}</td>
                                                    <td>{{ journey(t.journey_type) }}</td>
                                                    <td>{{ t.observation}}</td>
                                                    <td v-if="t.invoice_id!=null">FB{{ t.invoice_id }}</td>
                                                     <td v-else> - </td>
                                                        <td >      
                                                        <a href="#" @click.prevent="associateQuotationTransfer(t.id,t.invoice_id)"  v-if="t.quantity>0"
                                                           v-tooltip="'Asignar Factura a Traslado'">
                                                            <i class="fa green fa-file-invoice" v-if="t.invoice_id!=null"/>
                                                            <i class="fa gray fa-file-invoice" v-else/>
                                                        </a>


                                                        <span v-if="t.invoice_id!=null && t.quantity>0" >/</span>
                                                        <a href="#" @click.prevent="deleteQuotationTransfer(t.id)" v-if="t.invoice_id!=null && t.quantity>0"
                                                           v-tooltip="'Eliminar factura'">
                                                            <i class="fa red fa-times"/>
                                                        </a>
                                                       
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                       <p class="text-center mb-4" v-else>
                                            <i>No se agregaron traslados.</i>
                                        </p>
                                    </div>
                                </div>
                                <!-- fin trasaldos-->

                                <!-- pasajes -->
                                <div class="row d-flex align-items-center mb-3">
                                    <div class="col-10">
                                        <h4 class="mb-0">Pasajes</h4>
                                    </div>

                
                                </div>

                                <div class="row">
                                    <div class="col table-responsive p-0">
                                        <table class="table table-hover" v-if="quotation.tickets.length > 0">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                     <th>Código</th>
                                                    <th>Aerolínea</th>
                                                     <th>Código Vuelo</th>
                                                    <th>Tramo</th>
                                                    <th>Ruta</th>
                                                    <th>Fecha</th>
                                                    <th>Hora salida</th>
                                                    <th>Hora llegada</th>   
                                                    <th>Utilizó..</th>    
                                                    <th>Tarifa</th>
                                                    <th>Cantidad</th>
                                                    <th>Tarifa Total</th> 
                                                   <!-- <th>Multa</th>-->
                                                    <th>Afecto</th>
                                                    <th>Diferencial Tarifa</th>
                                                   
                                                    <th>OC</th>
                                                    <th>Vencimiento</th>
                                                     <th>Factura</th>
                                                   
                                                    
                                                    <th >Acciones</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(t, i) in quotation.tickets" >
                                                    <td>{{ i + 1 }}</td>
                                                    <td>PJ{{t.id }}</td>
                                                    <td>{{ t.airline.name }}</td>
                                                      <td>{{t.code}}</td>
                                                    <td>{{ t.flight_stretch.name }}</td>
                                                    <td>
                                                        {{ t.departure_airport.iata }} -
                                                        {{ t.arrival_airport.iata }}
                                                    </td>
                                                    <td>{{ t.flight_date | myDate }}</td>
                                                    <td>{{ t.departure_time | myHour }}</td>
                                                    <td>{{ t.arrival_time | myHour }}</td>
                                                     <td v-if="t.payment_type!=null">{{ paytype(t.payment_type) }}(${{t.payment_use_price| dotNumber}})</td>
                                                     <td v-else> Normal </td>
                                                    <td>
                                                        ${{ t.price | dotNumber }}
                                                    </td>
                                                    <td>{{ t.passengers }}</td>
                                                    <td>${{ t.price * t.passengers | dotNumber }}</td>
                                                   <!-- <td>$ {{t.penalty| dotNumber}}</td>-->
                                                    <td>$ {{t.dearness| dotNumber}}</td>
                                                    
                                                     <td>$ {{t.extra_price| dotNumber}}</td>
                                                    <!-- <td>$ {{t.unitary_extra_price| dotNumber}}</td>-->
                                                     <td>{{t.oc}}</td>
                                                    <td>{{t.expiration_date}}</td>
                                                    <td v-if="t.invoice_id!=null">FB{{ t.invoice_id }}</td>
                                                     <td v-else> - </td>
                                                   

                                                    <td>      

                                                      
                                                        <a v-if="t.passengers>0" href="#" @click.prevent="associateQuotationTicket(t.id,t.invoice_id)" 
                                                           v-tooltip="'Asignar Factura a Pasaje'">
                                                            <i class="fa green fa-file-invoice" v-if="t.invoice_id!=null"/>
                                                            <i class="fa gray fa-file-invoice" v-else/>
                                                        </a>


                                                        <span v-if="t.invoice_id!=null && t.passengers>0" >/</span>
                                                        <a href="#" @click.prevent="deleteQuotationTicket(t.id)" v-if="t.invoice_id!=null && t.passengers>0"
                                                           v-tooltip="'Eliminar factura'">
                                                            <i class="fa red fa-times"/>
                                                        </a>

                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <p class="text-center mb-4" v-else>
                                            <i>No se agregaron pasajes.</i>
                                        </p>
                                    </div>
                                </div>

                                <!-- trabajadores -->
                                <h4 class="mb-3">Tripulantes</h4>

                                <div class="row">
                                    <div class="col table-responsive p-0">
                                        <table class="table table-hover" v-if="!loading">
                                            <thead>
                                                <tr>
                                                    <th class="align-middle" align="center">Nombre</th>
                                                    <th class="align-middle" align="center">Rol</th>
                                                    <th class="align-middle" align="center">Aloj</th>
                                                    <th class="align-middle" align="center">Col</th>
                                                    <th class="align-middle" align="center">Tras</th>
                                                    <th class="align-middle" align="center">Pasajes</th>                                 
                                                    <th class="align-middle" align="center">$ Maleta Ida</th>
                                                    <th class="align-middle" align="center">Facturar Maleta Ida</th>
                                                    <th class="align-middle" align="center">Multa Ida</th>
                                                    <th class="align-middle" align="center">Facturar Multa Ida</th>
                                                    <th class="align-middle" align="center">$ Extra Ida</th>
                                                    <th class="align-middle" align="center">Obs. Ida</th>
                                                    <th class="align-middle" align="center">$ Maleta Vuelta</th>
                                                    <th class="align-middle" align="center">Facturar Maleta Vuelta</th>
                                                    <th class="align-middle" align="center">Multa Vuelta</th>
                                                    <th class="align-middle" align="center">Facturar Multa Vuelta</th>
                                                    <th class="align-middle" align="center">$ Extra Vuelta</th>
                                                    <th class="align-middle" align="center">Obs. Vuelta</th>
                                                    <th class="align-middle" align="center">Acciones</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(w,index)  in workers" :class="{ 'table-danger': w.worker.deleted_at!=null }">
                                                   
                                                    <td class="align-middle" >
                                                        {{ w.worker.name }} {{ w.worker.last_name }}<br>{{ w.worker.rut }}
                                                    </td>
                                               
                                                    <td class="align-middle" align="center">{{ w.worker.roles.map(r => r.name) | list }}</td>
                                                    <td class="align-middle" align="center">
                                                        <span v-if="w.lodging_id!=0" v-tooltip="'Alojamiento Ida'">AB{{ w.lodging_id }}</span>
                                                        <span v-if="w.lodging_id_vuelta!=0" v-tooltip="'Alojamiento Vuelta'">AB{{ w.lodging_id_vuelta}}</span>
                                                        <span v-if="w.lodging_id==0 && w.lodging_id_vuelta==0">
                                                            <span v-if="w.lodging">Solicitado</span>
                                                            <span v-else>No solicitado</span>
                                                        </span>
                                                    </td>
                                                    <td class="align-middle" align="center"> 
                                                        <span v-if="w.snack_id!=0" v-tooltip="'Colación Ida'">CB{{ w.snack_id }}</span>
                                                        <span v-if="w.snack_id_vuelta!=0" v-tooltip="'Colación Vuelta'">CB{{ w.snack_id_vuelta}}</span>
                                                        <span v-if="w.snack_id==0 && w.snack_id_vuelta==0">
                                                            <span v-if="w.lodging_food">Solicitado</span>
                                                            <span v-else>No solicitado</span>
                                                        </span>
                                                    </td>
                                                    <td class="align-middle" align="center">
                                                            <span v-if="w.relocation_id!=0" v-tooltip="'Traslado Ida'">TB{{ w.relocation_id }}</span>
                                                        <span v-if="w.relocation_id_vuelta!=0" v-tooltip="'Traslado Vuelta'">TB{{ w.relocation_id_vuelta}} </span>
                                                        <span v-if="w.relocation_id==0 && w.relocation_id_vuelta==0">
                                                            <span v-if="w.lodging">Solicitado</span>
                                                            <span v-else>No solicitado</span>
                                                        </span>
                                                    </td>
                                                    <!-- pasajes -->
                                                    <td class="align-middle" align="center">
                                                        <span v-if="w.ticketida!=null" v-tooltip="'Pasaje Ida'">PJ{{ w.ticketida.ticket_group_id }}</span>
                                                        <span v-if="w.ticketvuelta!=null" v-tooltip="'Pasaje Vuelta'">PJ{{ w.ticketvuelta.ticket_group_id }}</span>  
                                                    </td>
                                                    <td class="align-middle" align="center"><span v-if="w.ticketida!=null" >{{w.ticketida.carry_price}}</span><span v-else>0</span></td>
                                                    <td class="align-middle" align="center"><span v-if="w.ticketida!=null && w.ticketida.quotation==1" > 


                                                        
                                                        <a href="#" @click.prevent="associateQuotationWorkerCarry(w.ticketida.ticket_group_id,w.ticketida.id,w.ticketida.invoice_id)" v-if="w.worker.deleted_at==null"
                                                           v-tooltip="'Asociar Factura a Maleta Ida'">
                                                            <i class="fa green fa-file-invoice" v-if="w.ticketida.invoice_id!=null && w.worker.deleted_at!=null"/>
                                                            <i class="fa gray fa-file-invoice" v-else/>
                                                        </a>

                                                        <span v-if="w.ticketida.invoice_id!=null && w.worker.deleted_at==null " >/</span>
                                                        <a href="#" @click.prevent="deleteQuotationWorkerCarry(w.ticketida.id)" v-if="w.ticketida.invoice_id!=null && w.worker.deleted_at==null"
                                                           v-tooltip="'Eliminar factura'">
                                                            <i class="fa red fa-times"/>
                                                        </a>


                                                        </span> 
                                                        
                                                        <span v-else> - </span>
                                                            
                                                    </td>
                                                    <td class="align-middle" align="center"><span v-if="w.ticketida!=null" >{{w.ticketida.penalty}}</span><span v-else>0</span></td>

                                                     <!-- asociar factura a multa ida-->
                                                    <td class="align-middle" align="center"><span v-if="w.ticketida!=null && w.ticketida.penalty_quotation==1" > 
                                                        <a href="#" @click.prevent="associateQuotationWorkerPenalty(w.ticketida.ticket_group_id,w.ticketida.id,w.ticketida.penalty_invoice_id)" v-if="w.worker.deleted_at==null"
                                                           v-tooltip="'Asociar Factura a Multa Ida'">
                                                            <i class="fa green fa-file-invoice" v-if="w.ticketida.penalty_invoice_id!=null && w.worker.deleted_at!=null"/>
                                                            <i class="fa gray fa-file-invoice" v-else/>
                                                        </a>
                                                        <span v-if="w.ticketida.penalty_invoice_id!=null && w.worker.deleted_at==null " >/</span>
                                                        <a href="#" @click.prevent="deleteQuotationWorkerPenalty(w.ticketida.id)" v-if="w.ticketida.penalty_invoice_id!=null && w.worker.deleted_at==null"
                                                           v-tooltip="'Eliminar factura'">
                                                            <i class="fa red fa-times"/>
                                                        </a>
                                                        </span>                               
                                                        <span v-else> - </span>              
                                                    </td>
                                                    <!-- fin asociar factura a multa ida-->


                                                    <td class="align-middle" align="center"><span v-if="w.ticketida!=null" >{{w.ticketida.extra_price}}</span><span v-else>0</span></td>
                                                    <td class="align-middle" align="center"><span v-if="w.ticketida!=null" >{{w.ticketida.observation}}</span><span v-else>-</span></td>

                                                    <td class="align-middle" align="center"><span v-if="w.ticketvuelta!=null" >{{w.ticketvuelta.carry_price}}</span><span v-else>0</span></td>
                                                    <td class="align-middle" align="center"><span v-if="w.ticketvuelta!=null && w.ticketvuelta.quotation==1" >
                                                       

                                                          <a href="#" @click.prevent="associateQuotationWorkerCarry(w.ticketvuelta.ticket_group_id,w.ticketvuelta.id,w.ticketvuelta.invoice_id)" v-if="w.worker.deleted_at==null"
                                                           v-tooltip="'Asociar Factura a Maleta Vuelta'">
                                                            <i class="fa green fa-file-invoice" v-if="w.ticketvuelta.invoice_id!=null  && w.worker.deleted_at==null"/>
                                                            <i class="fa gray fa-file-invoice" v-else/>
                                                        </a>

                                                        <span v-if="w.ticketvuelta.invoice_id!=null  && w.worker.deleted_at==null" >/</span>
                                                        <a href="#" @click.prevent="deleteQuotationWorkerCarry(w.ticketvuelta.id)" v-if="w.ticketvuelta.invoice_id!=null  && w.worker.deleted_at==null"
                                                           v-tooltip="'Eliminar factura'">
                                                            <i class="fa red fa-times"/>
                                                        </a>

                                                        </span>
                                                        
                                                        <span v-else> - </span>
                                                        
                                                    
                                                    </td>
                                                    <td class="align-middle" align="center"><span v-if="w.ticketvuelta!=null" >{{w.ticketvuelta.penalty}}</span><span v-else>0</span></td>

                                                          <!-- asociar factura a multa vuelta-->
                                                    <td class="align-middle" align="center"><span v-if="w.ticketvuelta!=null && w.ticketvuelta.penalty_quotation==1" > 
                                                        <a href="#" @click.prevent="associateQuotationWorkerPenalty(w.ticketvuelta.ticket_group_id,w.ticketvuelta.id,w.ticketvuelta.penalty_invoice_id)" v-if="w.worker.deleted_at==null"
                                                           v-tooltip="'Asociar Factura a Multa Vuelta'">
                                                            <i class="fa green fa-file-invoice" v-if="w.ticketvuelta.penalty_invoice_id!=null && w.worker.deleted_at!=null"/>
                                                            <i class="fa gray fa-file-invoice" v-else/>
                                                        </a>
                                                        <span v-if="w.ticketvuelta.penalty_invoice_id!=null && w.worker.deleted_at==null " >/</span>
                                                        <a href="#" @click.prevent="deleteQuotationWorkerPenalty(w.ticketvuelta.id)" v-if="w.ticketvuelta.penalty_invoice_id!=null && w.worker.deleted_at==null"
                                                           v-tooltip="'Eliminar factura'">
                                                            <i class="fa red fa-times"/>
                                                        </a>
                                                        </span>                               
                                                        <span v-else> - </span>              
                                                    </td>
                                                    <!-- fin asociar factura a multa vuelta-->


                                                    <td class="align-middle" align="center"><span v-if="w.ticketvuelta!=null" >{{w.ticketvuelta.extra_price}}</span><span v-else>0</span></td>
                                                    <td class="align-middle" align="center"><span v-if="w.ticketvuelta!=null" >{{w.ticketvuelta.observation}}</span><span v-else> - </span></td>
                                                    <td class="align-middle" align="center">
                                                        <router-link :to="'/admin/trabajadores/' + w.worker.id"
                                                                     v-tooltip="'Ficha del tripulante'">
                                                            <i class="fa fa-info-circle green"></i>
                                                        </router-link>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <!--confirmar -->
                                <button type="button" class="btn btn-success float-right" @click="saveQuotation"
                                        >
                                    Guardar
                                </button>
                            </div>
                        </div>


                      
                     <div class="modal fade" id="invoice-modal" tabindex="-1" role="dialog"
                             aria-labelledby="form-dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                            <invoice-modal :invoice-id="selectedInvoice" v-if="showInvoiceModal"/>
                        </div>


                         <div class="modal fade" id="associate-accommodation-modal" tabindex="-1" role="dialog"
                             aria-labelledby="form-dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                            <associate-accommodation-modal :associate-id="selectedAssociate" :associate-selected-id="selectedAssociateId" :quotation-id="id" v-if="showAssociateInvoiceModal"/>
                        </div>

                         <div class="modal fade" id="associate-transfer-modal" tabindex="-1" role="dialog"
                             aria-labelledby="form-dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                            <associate-transfer-modal :associate-id="selectedAssociate" :associate-selected-id="selectedAssociateId" :quotation-id="id" v-if="showAssociateInvoiceModal"/>
                        </div>

                         <div class="modal fade" id="associate-ticket-modal" tabindex="-1" role="dialog"
                             aria-labelledby="form-dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                            <associate-ticket-modal :associate-id="selectedAssociate" :associate-selected-id="selectedAssociateId" :quotation-id="id" v-if="showAssociateInvoiceModal"/>
                        </div>

                          <div class="modal fade" id="associate-worker-carry-modal" tabindex="-1" role="dialog"
                             aria-labelledby="form-dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                            <associate-worker-carry-modal :associate-id="selectedAssociate" :associate-selected-id="selectedAssociateId" :worker-selected-id="selectedWorker" :quotation-id="id" v-if="showAssociateInvoiceModal"/>
                        </div>

                          <div class="modal fade" id="associate-worker-penalty-modal" tabindex="-1" role="dialog"
                             aria-labelledby="form-dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                            <associate-worker-penalty-modal :associate-id="selectedAssociate" :associate-selected-id="selectedAssociateId" :worker-selected-id="selectedWorker" :quotation-id="id" v-if="showAssociateInvoiceModal"/>
                        </div>
                       
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Loading from "../Common/Loading";
    import eventHub from "../../eventHub";
    import InvoiceModal from "./InvoiceModal";
    import AssociateAccommodationModal from "./AssociateAccommodationModal";
    import AssociateTransferModal from "./AssociateTransferModal";
    import AssociateTicketModal from "./AssociateTicketModal";
    import AssociateWorkerCarryModal from "./AssociateWorkerCarryModal";
    import AssociateWorkerPenaltyModal from "./AssociateWorkerPenaltyModal";

    export default {
        components: { Loading, InvoiceModal,AssociateAccommodationModal,AssociateTicketModal,AssociateTransferModal,AssociateWorkerCarryModal,AssociateWorkerPenaltyModal },
        computed: {
            id () {
                return this.$route.params.id;
            },
            workers () {
                return this.quotation.crew.workers.filter(w => w.is_replacement === 0);
            }
        },
        mounted(){
       
        },
        created () {
            this.$Progress.start();
            this.getQuotation();    
            this.$Progress.finish();

            this.$nextTick(() => $('#invoice-modal').on('hidden.bs.modal', () => {
                this.selectedInvoice = null;
                this.showInvoiceModal = false;
                eventHub.$emit('cleanInvoiceForm');
            }));

             eventHub.$on('reloadInvoice', () => {
                $('#invoice-modal').modal('hide');
                this.$nextTick(() => this.getQuotation());
            });


             this.$nextTick(() => $('#associate-accommodation-modal').on('hidden.bs.modal', () => {
                this.selectedAssociate = null;
                this.showAssociateInvoiceModal = false;
                eventHub.$emit('cleanInvoiceForm');
            }));

             eventHub.$on('reloadAccommodation', () => {
                $('#associate-accommodation-modal').modal('hide');
                this.$nextTick(() => this.getQuotation());
            });

              this.$nextTick(() => $('#associate-transfer-modal').on('hidden.bs.modal', () => {
                this.selectedAssociate = null;
                this.showAssociateInvoiceModal = false;
                eventHub.$emit('cleanInvoiceForm');
            }));

             eventHub.$on('reloadTransfer', () => {
                $('#associate-transfer-modal').modal('hide');
                this.$nextTick(() => this.getQuotation());
            });

              this.$nextTick(() => $('#associate-ticket-modal').on('hidden.bs.modal', () => {
                this.selectedAssociate = null;
                this.showAssociateInvoiceModal = false;
                eventHub.$emit('cleanInvoiceForm');
            }));

             eventHub.$on('reloadTicket', () => {
                $('#associate-ticket-modal').modal('hide');
                this.$nextTick(() => this.getQuotation());
            });


             this.$nextTick(() => $('#associate-worker-carry-modal').on('hidden.bs.modal', () => {
                this.selectedAssociate = null;
                this.showAssociateInvoiceModal = false;
                eventHub.$emit('cleanInvoiceForm');
            }));

             eventHub.$on('reloadWorkerCarry', () => {
                $('#associate-worker-carry-modal').modal('hide');
                this.$nextTick(() => this.getQuotation());
            });




            this.$nextTick(() => $('#associate-worker-penalty-modal').on('hidden.bs.modal', () => {
                this.selectedAssociate = null;
                this.showAssociateInvoiceModal = false;
                eventHub.$emit('cleanInvoiceForm');
            }));

             eventHub.$on('reloadWorkerPenalty', () => {
                $('#associate-worker-penalty-modal').modal('hide');
                this.$nextTick(() => this.getQuotation());
            });


        
            
        },
        data () {
            return {
                loading: true,
                lodgingCrewWorkerId: null,
                lodgingProviders: [],
                observations: null,
                carry1: null,
                carry2: null,
                quotate1: null,
                quotate2: null,
                penalty1: null,
                penalty2: null,
                extra1: null,
                extra2: null,
                obs1: null,
                obs2: null,
                quotation: {
                    entered: null,
                    entered_at: null,
                    crew: { workers: [] },
                    tickets: [],
                    transfers:[],
                    accommodations:[],
                    quotations:[]
                },
                relocateCrewWorkerId: null,
                relocateProviders: [],
                selectedTicket: null,
                selectedTransfer: null,
                selectedAccommodation: null,
                selectedSnack: null,

                selectedInvoice:null,
                showInvoiceModal: false,

                selectedWorker:null,
                selectedAssociateId:null,
                selectedAssociateType:null,
                selectedAssociate:null,
                showAssociateInvoiceModal: false,

                showLodgingModal: false,
                showRelocateModal: false,
                tooltip: {
                    lodging: 'Alojamiento asignado',
                    notLodging: 'No se ha asignado el alojamiento solicitado',
                    relocation: 'Traslado asignado',
                    notRelocation: 'No se ha asignado el traslado solicitado',
                }
            }
        },
        destroyed() {
           // eventHub.$off(['reloadQuotationTicket', 'reloadQuotationWorkers', 'lodgingSaved']);
        },
        methods: {
            journey(plus){
                if(plus==1){
                    return "Ida";
                }
                return "Vuelta";
            },
               plustype(plus){
                if(plus==1){
                    return "Si";
                }
                return "No";
            },
            paytype(plus){
                if(plus==1){
                    return "Stock";
                }
                if(plus==2){
                    return "Voucher";
                }
                return " - ";
            },
             quotationtype(plus){
                if(plus==1){
                    return "Si";
                }
                return "No";
            },
            saveQuotation (id) {
                
                
            },

            setInvoice(id=null){
                    this.selectedInvoice= id;
                    this.showInvoiceModal = true;
                    this.$nextTick(() => $('#invoice-modal').modal('show'));
            },
  
             associateQuotationTransfer (id,invoice_id=null) {
                    this.selectedAssociate= id;
                    this.selectedAssociateId= invoice_id;
                    this.showAssociateInvoiceModal = true;
                    this.$nextTick(() => $('#associate-transfer-modal').modal('show'));
                
            },

            associateQuotationAccommodation(id,invoice_id=null) {
                    this.selectedAssociate= id;
                    this.selectedAssociateId= invoice_id;
                    this.showAssociateInvoiceModal = true;
                    this.$nextTick(() => $('#associate-accommodation-modal').modal('show'));
            },
             associateQuotationWorkerCarry(id,id2,invoice_id=null) {
                    this.selectedAssociate= id;
                    this.selectedWorker= id2;
                    this.selectedAssociateId= invoice_id;
                    this.showAssociateInvoiceModal = true;
                    this.$nextTick(() => $('#associate-worker-carry-modal').modal('show'));
            },
            associateQuotationWorkerPenalty(id,id2,invoice_id=null) {
                    this.selectedAssociate= id;
                    this.selectedWorker= id2;
                    this.selectedAssociateId= invoice_id;
                    this.showAssociateInvoiceModal = true;
                    this.$nextTick(() => $('#associate-worker-penalty-modal').modal('show'));
            },
             deleteQuotationAccommodation(id) {
                    Swal.fire({
                                title: '¿Estás seguro que deseas quitar la factura?',
                                text: 'Puedes agregar otra luego',
                                showCancelButton: true,
                                confirmButtonColor: '#d33',
                                cancelButtonColor: '#3085d6',
                                confirmButtonText: 'Si',
                                cancelButtonText: 'No'
                            }).then((result) => {
                                if (result.value) {
                                    axios.delete(`/api/administracion/facturacion/elimina-factura-alojamiento/${id}`)
                                        .then(() => {
                                            this.getQuotation();
                                            Swal.fire(
                                                'Éxito',
                                                'La factura fue eliminado correctamente',
                                                'success'
                                            );
                                        })
                                        .catch(() => Swal.fire(
                                            'Error',
                                            'Error al eliminar factura',
                                            'warning'
                                        ));
                                }
                            });
            },
              deleteQuotationTransfer(id) {
                    Swal.fire({
                                title: '¿Estás seguro que deseas quitar la factura?',
                                text: 'Puedes agregar otra luego',
                                showCancelButton: true,
                                confirmButtonColor: '#d33',
                                cancelButtonColor: '#3085d6',
                                confirmButtonText: 'Si',
                                cancelButtonText: 'No'
                            }).then((result) => {
                                if (result.value) {
                                    axios.delete(`/api/administracion/facturacion/elimina-factura-traslado/${id}`)
                                        .then(() => {
                                            this.getQuotation();
                                            Swal.fire(
                                                'Éxito',
                                                'La factura fue eliminado correctamente',
                                                'success'
                                            );
                                        })
                                        .catch(() => Swal.fire(
                                            'Error',
                                            'Error al eliminar factura',
                                            'warning'
                                        ));
                                }
                            });
            },

             deleteQuotationWorkerCarry(id) {
                    Swal.fire({
                                title: '¿Estás seguro que deseas quitar la factura?',
                                text: 'Puedes agregar otra luego',
                                showCancelButton: true,
                                confirmButtonColor: '#d33',
                                cancelButtonColor: '#3085d6',
                                confirmButtonText: 'Si',
                                cancelButtonText: 'No'
                            }).then((result) => {
                                if (result.value) {
                                    axios.delete(`/api/administracion/facturacion/elimina-factura-maleta/${id}`)
                                        .then(() => {
                                            this.getQuotation();
                                            Swal.fire(
                                                'Éxito',
                                                'La factura fue eliminado correctamente',
                                                'success'
                                            );
                                        })
                                        .catch(() => Swal.fire(
                                            'Error',
                                            'Error al eliminar factura',
                                            'warning'
                                        ));
                                }
                            });
            },

             deleteQuotationWorkerPenalty(id) {
                    Swal.fire({
                                title: '¿Estás seguro que deseas quitar la factura?',
                                text: 'Puedes agregar otra luego',
                                showCancelButton: true,
                                confirmButtonColor: '#d33',
                                cancelButtonColor: '#3085d6',
                                confirmButtonText: 'Si',
                                cancelButtonText: 'No'
                            }).then((result) => {
                                if (result.value) {
                                    axios.delete(`/api/administracion/facturacion/elimina-factura-multa/${id}`)
                                        .then(() => {
                                            this.getQuotation();
                                            Swal.fire(
                                                'Éxito',
                                                'La factura fue eliminado correctamente',
                                                'success'
                                            );
                                        })
                                        .catch(() => Swal.fire(
                                            'Error',
                                            'Error al eliminar factura',
                                            'warning'
                                        ));
                                }
                            });
            },

              deleteQuotationTicket(id) {
                    Swal.fire({
                                title: '¿Estás seguro que deseas quitar la factura?',
                                text: 'Puedes agregar otra luego',
                                showCancelButton: true,
                                confirmButtonColor: '#d33',
                                cancelButtonColor: '#3085d6',
                                confirmButtonText: 'Si',
                                cancelButtonText: 'No'
                            }).then((result) => {
                                if (result.value) {
                                    axios.delete(`/api/administracion/facturacion/elimina-factura-pasaje/${id}`)
                                        .then(() => {
                                            this.getQuotation();
                                            Swal.fire(
                                                'Éxito',
                                                'La factura fue eliminado correctamente',
                                                'success'
                                            );
                                        })
                                        .catch(() => Swal.fire(
                                            'Error',
                                            'Error al eliminar factura',
                                            'warning'
                                        ));
                                }
                            });
            },

            associateQuotationTicket(id,invoice_id=null) {
                    this.selectedAssociate= id;
                    this.selectedAssociateId= invoice_id;
                    this.showAssociateInvoiceModal = true;
                    this.$nextTick(() => $('#associate-ticket-modal').modal('show'));
            },

            associateQuotationCarryVuelta(id){

            },


            deleteInvoice (id) {
                        
                            Swal.fire({
                                title: '¿Estás seguro que deseas eliminar esta factura?',
                                text: 'Se eliminara todas las asociaciones realizadas',
                                showCancelButton: true,
                                confirmButtonColor: '#d33',
                                cancelButtonColor: '#3085d6',
                                confirmButtonText: 'Si',
                                cancelButtonText: 'No'
                            }).then((result) => {
                                if (result.value) {
                                    axios.delete(`/api/administracion/facturacion/elimina-factura/${id}/${this.id}`)
                                        .then(() => {
                                            this.getQuotation();
                                            Swal.fire(
                                                'Éxito',
                                                'La factura fue eliminado correctamente',
                                                'success'
                                            );
                                        })
                                        .catch(() => Swal.fire(
                                            'Error',
                                            'Error al eliminar factura',
                                            'warning'
                                        ));
                                }
                            });
                        
                    },

            getQuotation () {
                this.loading = true;
                this.$Progress.start();

                axios.get(`/api/logistica/cotizaciones/${this.id}`)
                    .then(({ data }) => {
                        this.quotation = data.data;
                        this.$Progress.finish();
                        // genera el "formulario" de observaciones solo si no tiene datos de antemano       
                    })
                    .catch(() => {
                        this.$launchError('Error al obtener información de cotización');
                        this.$Progress.fail();
                    })
                    .finally(() => this.loading = false);
            },
        }
    }
</script>

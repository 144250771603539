<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <loading v-if="loading"/>

                        <!-- header -->
                        <div class="card-header">
                            <h3 class="card-title">Listado de pasajes</h3>
                        </div>

                        <!-- body -->
                        <div class="card-body table-responsive p-0">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th class="align-middle">Aerolínea</th>
                                        <th class="align-middle">Tramo</th>
                                        <th class="align-middle">Ruta</th>
                                        <th class="align-middle">Fecha</th>
                                        <th class="align-middle">Hora salida</th>
                                        <th class="align-middle">Hora llegada</th>
                                        <th class="align-middle">Tarifa</th>
                                        <th class="align-middle">Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="t in tickets.data">
                                        <td class="align-middle">{{ t.airline.name }}</td>
                                        <td class="align-middle">{{ t.flight_stretch.name }}</td>
                                        <td class="align-middle">
                                            {{ t.departure_airport.iata }} -
                                            {{ t.arrival_airport.iata }}
                                        </td>
                                        <td class="align-middle">{{ t.flight_date | myDate }}</td>
                                        <td class="align-middle">{{ t.departure_time | myHour }}</td>
                                        <td class="align-middle">{{ t.arrival_time | myHour }}</td>
                                        <td class="align-middle">${{ t.price | dotNumber }}</td>
                                        <td class="align-middle">
                                            <router-link :to="'/logistica/pasajes/' + t.id"
                                                         v-tooltip="'Ver detalles'">
                                                <i class="fa fa-info-circle green"></i>
                                            </router-link>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <!-- footer -->
                        <div class="card-footer">
                            <pagination :data="tickets" @pagination-change-page="getTickets"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import Loading from "../Common/Loading";

    export default {
        components: { Loading },
        created() {
            this.getTickets();
        },
        data () {
            return {
                loading: true,
                tickets: {}
            }
        },
        methods: {
            getTickets (page = 1) {
                this.loading = true;
                this.$Progress.start();

                axios.get('/api/logistica/pasajes?page=' + page)
                    .then(({ data }) => {
                        this.tickets = data.data;
                        this.$Progress.finish();
                    })
                    .catch(() => {
                        this.$launchError('Error al obtener listado de pasajes');
                        this.$Progress.fail();
                    })
                    .finally(() => this.loading = false);
            }
        }
    }
</script>

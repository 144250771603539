var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-dialog modal-xl",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"modal-body container"},[_c('div',{staticClass:"alert alert-warning",attrs:{"role":"alert"}},[_vm._v("\n                    Si escoge a una persona de esta lista, tendrá documentos vencidos que deberán ser actualizados antes de poder embarcarse, favor tomar en consideración esta advertencia. Abreviatura : (Des) = Descanso,(Emb) = Embarcado,(Dis) = Disponible,(Vac) = Vacaciones.\n                ")]),_vm._v(" "),_c('div',{staticClass:"table-responsive p-0"},[_c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Búsqueda","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_vm._v(" "),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.datos,"search":_vm.search,"loading":_vm.loading,"items-per-page":10,"no-results-text":"No se han encontrado tripulantes","no-data-text":"No existen tripulantes","loading-text":"Cargando... Espere","footer-props":{
           'items-per-page-text':'Tripulantes por página',
           'items-per-page-options': [10,15,20]
      }},scopedSlots:_vm._u([{key:"item.accion",fn:function(ref){
      var item = ref.item;
      var index = ref.index;
return [_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Escoger Tripulante'),expression:"'Escoger Tripulante'"}],attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.choose(item)}}},[_c('v-icon',{staticStyle:{"color":"#4BB050 !important"},attrs:{"small":""}},[_vm._v("\n                                                               fa fa-user-plus\n                                                                ")])],1)]}},{key:"item.motivo",fn:function(ref){
      var item = ref.item;
      var index = ref.index;
return [_c('worker-reasons',{attrs:{"status":item.motivo}})]}},{key:"item.estado",fn:function(ref){
      var item = ref.item;
      var index = ref.index;
return [_c('worker-status',{attrs:{"status":item.estado}})]}},{key:"footer.page-text",fn:function(items){return [_vm._v(" "+_vm._s(items.pageStart)+" - "+_vm._s(items.pageStop)+" de "+_vm._s(items.itemsLength)+" ")]}}])})],1)],1)]),_vm._v(" "),_vm._m(1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-header align-items-center"},[_c('h5',{staticClass:"modal-title"},[_vm._v("Escoger reemplazo")]),_vm._v(" "),_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-footer"},[_c('button',{staticClass:"btn btn-secondary",attrs:{"type":"button","data-dismiss":"modal"}},[_vm._v("Cerrar")])])}]

export { render, staticRenderFns }
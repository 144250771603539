<template>
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <!-- header -->
            <div class="modal-header">
                <h5 class="modal-title">{{ title }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <!-- body -->
            <div class="modal-body container">
                <!-- alerta -->
            
                <!-- tabla -->
                
                <div class="table-responsive p-0">

                     <table class="table table-hover" v-if="$parent.quotation.quotations!=null && $parent.quotation.quotations.length > 0">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Código</th>
                                                    <th>Cuenta Bancaria</th>
                                                    <th>Nº Factura</th>
                                                    <th>Orden de Compra</th>
                                                     <th>Fecha Orden de Compra</th>
                                                    <th>Proveedor</th>
                                                    <th>Tipo Proveedor</th>
                                             
                                                </tr>
                                            </thead>
                                            <tbody>
                                            
                                                <tr v-for="(t, i) in $parent.quotation.quotations" v-if="ticket_supplier_id==t.supplier_id">
                                                    <td>
                                                        <input :value="t.id" type="radio" v-model="onlyOneSelected" >
                                                   </td>
                                                    <td>FB{{ t.id }}</td>
                                                    <td>{{ t.account_number }} - {{ t.account_name }}</td>
                                                    <td>{{ t.invoice_number }}</td>
                                                    <td>{{ t.purchase_order }}</td>
                                                    <td>{{ t.purchase_order_date }}</td>
                                                    <td>{{ t.supplier }}</td>
                                                    <td>{{ t.supplier_type }}</td>
                                                </tr>
                                            </tbody>
                                        </table>



                    <p class="text-center mb-0" v-else>
                        <i>No hay facturas disponibles para asociar.</i>
                    </p>
                </div>
            </div>

            <!-- footer -->
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                <button type="button" class="btn btn-primary" @click="save">
                    Guardar
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import eventHub from "../../eventHub";

    export default {
        props: ['associateId', 'onlyOne','quotationId','associateSelectedId','workerSelectedId'],
        computed: {
            title () {
                return 'Asociar Factura a Pasaje PJ'+this.associateId;
            },
            ticket_supplier_id () {
                return this.$parent.quotation.tickets.find(t => t.id === this.associateId).supplier_id;
            },

        },
        created () {
           // this.getWorkers();
           //this.supplier_id = this.accomodation_supplier_id;
         
         this.fillForm();
         this.onlyOneSelected = this.associateSelectedId;
          
        },
        destroyed() {
          //  eventHub.$off('cleanSnackForm');
        },
        data () {
            return {
                //supplier_id:null,
                onlyOneSelected: null,
                form: {}
            }
        },
        methods: {
            fillForm () {
                this.$parent.quotation.quotations.forEach(w => { 

                    this.form[w.id] = { 
                    id: w.id,
                    selected: false,
                    enabled: true
                }
               /* if(w.used == 1){
                    this.form[w.id] = { 
                    id: w.id,
                    selected: false,
                    enabled: true
                }
                }if(w.used==0){
                    this.form[w.id] = { 
                    id: w.id,
                    selected: true,
                    enabled:false
                }
                }
                if(w.used==2){
                    this.form[w.id] = { 
                    id: w.id,
                    selected: false,
                    enabled:true
                }}*/
                });
            },
            save(){
                //eventHub.$emit('reloadAccommodation');
                     axios.post(`/api/administracion/facturacion/maleta/asociar-factura`, {
                        invoice_id: this.onlyOneSelected,
                        id:this.workerSelectedId,
                        quotation_id: this.quotationId
                    })
                        .then(() => {
                            eventHub.$emit('reloadWorkerCarry');
                            this.$launchSuccess(`Éxito al asociar factura`);
                        })
                        .catch(() => this.$launchError(`Error al asociar factura`));       
            }
        },
        watch: {
           /* workers () {
                this.form = {};
                this.fillForm();
            }*/
        }
    }
</script>

<template>

    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <!-- header -->
            <div class="modal-header align-items-center">
                <h5 class="modal-title">Escoger reemplazo</h5>

               <!-- <form class="ml-auto w-50" @submit.prevent="getWorkers">
                    <input type="text" v-model="searchInput" class="form-control"
                           placeholder="Ingrese nombre y/o apellido de tripulante">
                </form>-->

                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <!-- body -->
            <div class="modal-body container">

                 <div class="alert alert-warning" role="alert">
                    Si escoge a una persona de esta lista, tendrá documentos vencidos que deberán ser actualizados antes de poder embarcarse, favor tomar en consideración esta advertencia. Abreviatura : (Des) = Descanso,(Emb) = Embarcado,(Dis) = Disponible,(Vac) = Vacaciones.
                </div>
                <!-- tabla -->
                <div class="table-responsive p-0">


<v-card >
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Búsqueda"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
  <v-data-table 
      :headers="headers"
      :items="datos"
      :search="search"
       :loading="loading"
       :items-per-page="10"
       no-results-text="No se han encontrado tripulantes"
       no-data-text="No existen tripulantes"
      class="elevation-1"
        loading-text="Cargando... Espere"
         :footer-props="{
           'items-per-page-text':'Tripulantes por página',
           'items-per-page-options': [10,15,20]
      }"
    >
     <template v-slot:item.accion="{ item, index }">
              

                                         <a href="#"  @click.prevent="choose(item)"
                                                           v-tooltip="'Escoger Tripulante'">
                                                            <v-icon small style="color:#4BB050 !important;" >
                                                               fa fa-user-plus
                                                                </v-icon>
                                                        </a>  
  </template>

   <template v-slot:item.motivo="{ item, index }">
             <worker-reasons :status="item.motivo"/>
  </template>
  <template v-slot:item.estado="{ item, index }">

               <worker-status :status="item.estado"/>
  </template>


  <template v-slot:footer.page-text="items"> {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }} </template>
</v-data-table>
</v-card>

                   <!-- <table class="table table-hover">
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Rut</th>
                                <th>Rol</th>
                                <th>Estado</th>
                                <th>Motivo</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="w in workers.data">
                                <td class="align-middle">{{ w.name }} {{ w.last_name }} {{(w.attribute)}}</td>
                                <td class="align-middle">{{ w.rut }}</td>
                                <td class="align-middle">{{ w.roles.map(r => r.name) | list }}</td>
                                <td class="align-middle"><worker-status :status="w.status.status"/></td>
                                <td class="align-middle"><worker-reasons :status="w.status"/></td>
                                <td class="align-middle">
                                    <i class="fa fa-user-plus green" v-tooltip="'Escoger trabajador'"
                                       @click="choose(w)"/>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <pagination :data="workers" @pagination-change-page="getWorkers"/>-->


                </div>
            </div>

            <!-- footer -->
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            </div>
        </div>
    </div>
 
</template>

<script>
    import WorkerReasons from "../EditCrew/WorkerReasons";
    import WorkerStatus from "../EditCrew/WorkerStatus";
    import eventHub from "../../../eventHub";

    export default {
        components: { WorkerReasons, WorkerStatus },
        props:['crew_id'],
        created () {
            this.getWorkers();
        },
        data () {
            return {
                searchInput: null,
                search:'',
                loading:false,
                workers: {},
                 headers: [
               
                { text: 'Nombre', align: 'start', value: 'nombre' },
                { text: 'Rut', value: 'rut' },
                {text:'Atributo', value:'atributo'},
                { text: 'Rol', value: 'rol' },
       
                { text: 'Estado', value: 'estado' },
                { text: 'Motivo', value: 'motivo' },
                { text: 'Elegir', value: 'accion', sortable: false },
                ],
                datos:[], 
                firstWorkers:{}
            }
        },
        methods: {
            choose (w) {
                eventHub.$emit('replacementChosen', {
                    id: w.id,
                    name: `${w.nombre}`,
                    rut: w.rut
                });
            },

            getWorkers (page = 1) {
                this.$Progress.start();

                axios.get(`/api/jefe-flota/tripulaciones/${this.$parent.crewId}/trabajadores-reemplazo?crew_id=${this.crew_id}`)
                   // .then(({ data }) => this.workers = data.data)
                   .then(data => {
                     this.firstWorkers = data.data;
                      for(var i in this.firstWorkers.data){
                          var t = this.firstWorkers.data[i];
                          var roles = this.$options.filters.list(t.roles.map(r => r.name)); 
                          this.datos.push({atributo:t.attribute,nombre:t.name+" "+t.last_name,rut:t.rut,rol:roles,atributo:t.attribute,estado:t.status.status,motivo:t.status,id:t.id,accion:true});
                
                      }
                     console.log(this.datos);
                       this.loading = false;
                     })
                    .catch(() => this.$launchError('Error al obtener listado de tripulantes'))
                    .finally(() => this.$Progress.finish());
            }
        }
    }
</script>

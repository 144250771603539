<template>
    <div>
        <!-- breadcrumb -->
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Editar tripulante</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link to="/">Inicio</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link to="/admin/trabajadores">Tripulantes</router-link>
                            </li>
                            <li class="breadcrumb-item active">Editar tripulante</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>

        <!-- edición -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col">
                        <div class="card">
                            <loading v-if="loading"/>

                            <div class="card-header">
                                <ul class="nav nav-pills">
                                    <li class="nav-item" v-for="t in tabs">
                                        <a class="nav-link" :class="{'active': tab === t.component}"
                                           @click="tab = t.component">
                                            {{ t.title }}
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div class="card-body">
                                <component :is="tab"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Contract from "./EditWorker/Contract";
    import Documents from "./EditWorker/Documents";
    import ExtraDocuments from "./EditWorker/ExtraDocuments";
    import Loading from "./Common/Loading";
    import PersonalData from "./EditWorker/PersonalData";
    import Photo from "./EditWorker/Photo";
    import Roles from "./EditWorker/Roles";
    import eventHub from "../eventHub";

    export default {
        components: { Contract, Documents, Loading, PersonalData, Photo, Roles,ExtraDocuments },
        computed: {
            id () {
                return this.$route.params.id;
            }
        },
        created () {
            this.getWorker();

            eventHub.$on('getWorker', () => this.getWorker())
        },
        data () {
            return {
                loading: true,
                worker: {
                    id: '',
                    name: '',
                    last_name: '',
                    code: '',
                    rut: '',
                    birthdate: '',
                    phone_number: '',
                    attribute_id: null
                },
                tab: 'PersonalData',
                tabs: [
                    { title: 'Datos personales', component: 'PersonalData' },
                    { title: 'Contrato', component: 'Contract' },
                    { title: 'Roles', component: 'Roles' },
                    { title: 'Foto', component: 'Photo' },
                    { title: 'Documentos de Trabajo', component: 'Documents' },
                    { title: 'Documentos Extra', component: 'ExtraDocuments' }
                ]
            }
        },
        destroyed () {
            eventHub.$off('getWorker');
        },
        methods: {
            getWorker () {
                this.$Progress.start();
                this.loading = true;

                axios.get('/api/trabajadores/' + this.id)
                    .then(({ data }) => {
                        this.worker = data.data;
                        this.$Progress.finish();
                    })
                    .catch(() => {
                        this.$launchError('Error al obtener datos del tripulante');
                        this.$Progress.fail();
                    })
                    .finally(() => this.loading = false);
            }
        }
    }
</script>

<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">Detalle de alojamiento</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="modal-body container">
            <div class="row">
                <p class="col">
                    <strong>Fecha ingreso: </strong> {{ lodging.arrival_date | myDate }}.
                </p>

                <p class="col">
                    <strong>Fecha salida: </strong> {{ lodging.departure_date | myDate }}.
                </p>
            </div>

            <div class="row">
                <p class="col">
                    <strong>Tarifa: </strong> ${{ lodging.price | dotNumber }}.
                </p>

                <p class="col">
                    <strong>Proveedor: </strong> {{ lodging.lodging_provider.name }}.
                </p>
            </div>

            <div class="row">
                <p class="col">
                    <strong>Solicita alimentación: </strong> {{ worker.lodging_food ? 'Si': 'No' }}.
                </p>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-primary" data-dismiss="modal">Cerrar</button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        computed: {
            lodging () {
                return this.worker.lodging_data;
            },
            worker () {
                return this.$parent.quotations.find(q => q.id === this.$parent.lodgingQuotationId)
                    .crew.workers.find(w => w.id === this.$parent.lodgingCrewWorkerId);
            }
        }
    }
</script>

<template>
    <div>
        <!-- breadcrumb -->
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Editar artículo</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link to="/">Inicio</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link to="/bodega/articulos">Artículos</router-link>
                            </li>
                            <li class="breadcrumb-item active">Editar artículo</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>

        <!-- edición -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col">
                        <div class="card">
                            <loading v-if="loading"/>

                            <div class="card-header">
                                <ul class="nav nav-pills">
                                    <li class="nav-item" v-for="t in tabs">
                                        <a class="nav-link" :class="{'active': tab === t.component}"
                                           @click="tab = t.component">
                                            {{ t.title }}
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div class="card-body">
                                <component :is="tab"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Data from "./EditItem/Data";
    import Loading from "../Common/Loading";
    import Sizes from "./EditItem/Sizes";

    export default {
        components: { Data, Loading, Sizes },
        computed: {
            id () {
                return this.$route.params.id;
            }
        },
        data () {
            return {
                loading: true,
                tab: 'Data',
                tabs: [
                    { title: 'Datos', component: 'Data' },
                    { title: 'Tallas y cantidades', component: 'Sizes' }
                ]
            }
        }
    }
</script>

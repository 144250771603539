<template>
    <div id="accordion">
        <div >

   
            <div  class="collapse show" data-parent="#accordion">
                <div class="card-body">
                    <!-- información cotización -->
                   <!-- <div class="row mb-4">
                        <div class="col">
                            <span>
                                <strong>Tarifa total:</strong> ${{ totalPrice | dotNumber }}.
                            </span>
                        </div>
                        <div class="col">
                            <span>
                                <strong>Fecha creación:</strong> {{ quotation.entered_at | myDate }}.
                            </span>
                        </div>
                        <div class="col">
                            <span>
                                <strong>Fecha confirmación:</strong> {{ quotation.confirmed_at | myDate }}.
                            </span>
                        </div>
                    </div>-->

                    <!-- motivo de rechazo -->
                   <!-- <div class="alert alert-danger mb-4" role="alert" v-if="quotation.status === 0">
                        <strong>Motivo:</strong> {{ quotation.rejection_reason }}.
                    </div>-->

                    <!-- pasajes -->
 <div class="row float-right">
                                             <router-link :to="`/logistica/cotizacion/${quotation.cotizacion}`" v-if="quotation.cotizacion!==null">
                                               <!-- <i class="fas fa-cart-arrow-down green"></i>-->
                                               Detalle de Cotización
                                                 <v-icon small color="green">
                                                fa-cart-arrow-down
                                                </v-icon>
                                            </router-link>
 </div>

                    <h4 class="mb-4" v-if="quotation.tickets.length > 0">Pasajes</h4>

                    <div class="row" v-if="quotation.tickets.length > 0">
                        <div class="col table-responsive p-0">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Aerolínea</th>
                                        <th>Tramo</th>
                                        <th>Ruta</th>
                                        <th>Fecha</th>
                                        <th>Hora salida</th>
                                        <th>Hora llegada</th>
                                        <th>Codigo Vuelo</th>
                                        <th>Cantidad</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(t, i) in quotation.tickets">
                                        <td>{{ i + 1 }}</td>
                                        <td>{{ t.airline }}</td>
                                        <td>{{ t.stretch }}</td>
                                        <td>
                                            {{ t.airport1 }} -
                                            {{ t.airport2 }}
                                        </td>
                                        <td>{{ t.flight_date | myDate }}</td>
                                        <td>{{ t.departure_time | myHour }}</td>
                                        <td>{{ t.arrival_time | myHour }}</td>
                                        <td>
                                            {{ t.code }}
                                            <!--<strong v-if="t.tickets.length > 0">
                                                (Total ${{ t.price * t.tickets.length | dotNumber }})
                                            </strong>-->
                                        </td>
                                        <td>{{ t.passengers }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div v-else><span><i>No se han creado Pasajes para esta tripulación.</i></span><br><br></div>


                        <h4 class="mb-4" v-if="quotation.alojamientos.length > 0">Alojamientos</h4>
                        <div class="row" v-if="quotation.alojamientos.length > 0">
                        <div class="col table-responsive p-0">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Proveedor</th>
                                        <th>Entrada</th>
                                        <th>Salida</th>
                                        <th>Cantidad</th>
                                        <th>Precio Unitario</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(t, i) in quotation.alojamientos">
                                        <td>{{ i + 1 }}</td>
                                        <td>{{ t.name }}</td>
                                        <td>{{ t.entry_date  | myDate }}</td>
                                        <td>
                                            {{ t.exit_date  | myDate }} 
                                        </td>
                                         <td>
                                            {{ t.cuantos }} 
                                        </td>
                                        <td>
                                            ${{ t.price | dotNumber }}
                                        </td>
                                        <td>
                                            ${{ t.price * t.cuantos | dotNumber }}
                                        </td>
                                    
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                   <div v-else> <span><i>No se han creado Alojamientos para esta tripulación.</i></span><br><br></div>


                        <h4 class="mb-4" v-if="quotation.traslados.length > 0">Traslados</h4>
                        <div class="row" v-if="quotation.traslados.length > 0">
                        <div class="col table-responsive p-0">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Proveedor</th>
                                        <th>Fecha Recogida</th>
                                        <th>Entrada</th>
                                        <th>Salida</th>
                                        <th>Cantidad</th>
                                        <th>Precio Unitario</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(t, i) in quotation.traslados">
                                        <td>{{ i + 1 }}</td>
                                        <td>{{ t.name }}</td>
                                        <td>{{ t.upload_date | myDate }}</td>
                                        <td>{{ t.entry_text }}</td>
                                        <td>
                                            {{ t.exit_text }} 
                                        </td>
                                         <td>
                                            {{ t.cuantos }} 
                                        </td>
                                        <td>
                                            ${{ t.price | dotNumber }}
                                        </td>
                                        <td>
                                            ${{ t.price * t.cuantos | dotNumber }}
                                        </td>
                                    
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div v-else>  <span ><i>No se han creado Traslados para esta tripulación.</i></span><br><br></div>


                        <h4 class="mb-4" v-if="quotation.tripulantes.length > 0">Tripulantes</h4>
                        <div class="row" v-if="quotation.tripulantes.length > 0">
                        <div class="col table-responsive p-0">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Nombre</th>
                                        <th>Apellido</th>
                                        <th>Rut</th>
                                        <th>Rol</th>
                                        <th>Alojamiento</th>
                                        <th>Alimentacion</th>
                                        <th>Traslado</th>
                                        <th>Ing. Tardío</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(t, i) in quotation.tripulantes" :class="{ 'table-warning': t.filtered, 'table-danger': t.with_emergency }">
                                        <td>{{ i + 1 }}</td>
                                        <td>{{ t.name }}</td>
                                         <td>{{ t.last_name }}</td>
                                          <td>{{ t.rut }}</td>
                                           <td>{{ t.roles }}</td>
                                            <td>{{ t.lodging ? 'Si' : 'No' }}</td>
                                            <td>{{ t.lodging_food ? 'Si' : 'No' }}</td>
                                            <td>{{ t.relocation ? 'Si' : 'No' }}</td>
                                            <td>{{ t.entry_date ? t.entry_date : '-' }}</td>
                                      
                                    
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                     <div v-else><span><i>No existen tripulantes en esta tripulacion.</i></span><br><br></div>

                    <!-- trabajadores -->
                    <!--<h4 class="mt-4 mb-4">Trabajadores</h4>

                    <div class="row">
                        <div class="col table-responsive p-0">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th class="align-middle">Nombre</th>
                                        <th class="align-middle">Rut</th>
                                        <th class="align-middle">Rol</th>
                                        <th class="align-middle" v-if="someLodging">Alojamiento</th>
                                        <th class="align-middle" v-if="someRelocation">Traslado</th>
                                        <th class="align-middle">Pasaje ida</th>
                                        <th class="align-middle">Pasaje vuelta</th>
                                        <th class="align-middle">Observaciones</th>
                                        <th class="align-middle">Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="w in quotation.crew.workers" v-if="w.is_replacement==0">
                                        <td class="align-middle">
                                            {{ w.worker.name }} {{ w.worker.last_name }}
                                        </td>
                                        <td class="align-middle">{{ w.worker.rut }}</td>
                                        <td class="align-middle">
                                            {{ w.worker.roles.map(r => r.name) | list }}
                                        </td>
                                        <td class="align-middle" v-if="someLodging">
                                            <div v-if="w.lodging && w.lodging_data">
                                                <i class="fa fa-check green"/>
                                                {{ w.lodging_food ? '+ alim.' : '' }}
                                            </div>
                                            <span v-else>-</span>
                                        </td>
                                        <td class="align-middle" v-if="someRelocation">
                                            <div v-if="w.lodging && w.relocation_data">
                                                <i class="fa fa-check green"/>
                                            </div>
                                            <span v-else>-</span>
                                        </td>
                                        <td class="align-middle">
                                            #{{ getTicketNumber(w.id, 'Ida') }}
                                        </td>
                                        <td class="align-middle">
                                            #{{ getTicketNumber(w.id, 'Vuelta') }}
                                        </td>
                                        <td class="align-middle">
                                            {{ w.observations ? w.observations: '-' }}
                                        </td>
                                        <td class="align-middle">
                                            <router-link :to="'/admin/trabajadores/' + w.worker.id"
                                                         v-tooltip="'Ficha del trabajador'">
                                                <i class="fa fa-info-circle green"></i>
                                            </router-link>

                                            <span v-if="w.lodging">/</span>
                                            <a href="#" @click="viewLodging(w.id)" v-tooltip="'Alojamiento'"
                                               v-if="w.lodging">
                                                <i class="fa orange fa-bed"/>
                                            </a>

                                            <span v-if="w.relocation">/</span>
                                            <a href="#" @click="viewRelocation(w.id)" v-tooltip="'Traslado'"
                                               v-if="w.relocation">
                                                <i class="fa blue fa-bus-alt"/>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        computed: {
            // índica si existe al menos un trabajador con alojamiento, así se oculta la respectiva columna en la tabla
            /*someLodging () {
                return this.quotation.crew.workers.filter(w => w.lodging).length > 0;
            },
            // índica si existe al menos un trabajador con traslado, así se oculta la respectiva columna en la tabla
            someRelocation () {
                return this.quotation.crew.workers.filter(w => w.relocation).length > 0;
            },
            title () {
                let e = '';

                if (this.quotation.status === null) {
                    e = 'No validada';
                } else if (!this.quotation.status) {
                    e = 'Rechazada';
                } else if (this.quotation.status) {
                    e = 'Validada';
                }

                return `${this.myDate(this.quotation.entered_at)} - ${e}.`;
            },
            totalPrice () {
                if (this.quotation.crew.workers.length > 0) {
                    return this.quotation.tickets.map(w => w.price * w.tickets.length).reduce((a, b) => a + b, 0);
                } else return 0;
            }*/
        },
        methods: {
            /*getTicketNumber (crewWorkerId, stretch) {
                let index = null;

                this.quotation.tickets.forEach((t, i) => {
                    if (index === null && t.flight_stretch.name === stretch) {
                        t.tickets.forEach(w => {
                            if (w.crew_worker_id === crewWorkerId) index = i;
                        });
                    }
                });

                return index + 1;
            },

            viewLodging (crewWorkerId) {
                this.$parent.viewLodging(this.quotation.id, crewWorkerId);
            },

            viewRelocation (crewWorkerId) {
                this.$parent.viewRelocation(this.quotation.id, crewWorkerId);
            }*/
        },
        props: [
            'quotation'
        ]
    }
</script>

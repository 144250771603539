<template>
    <div>
        <!-- breadcrumb -->
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Ficha de embarcación</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link to="/">Inicio</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link to="/admin/embarcaciones">Embarcaciones</router-link>
                            </li>
                            <li class="breadcrumb-item active">Ficha embarcación</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>

        <!-- ficha -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">

                    <!-- profile -->
                    <div class="col-4">
                        <div class="card card-primary sticky-top">
                            <loading v-if="loading"/>

                            <div class="card-body box-profile">
                                <h3 class="profile-username text-center">
                                    {{ ship.name }}
                                </h3>

                                <p class="text-muted text-center">{{ ship.plate }}</p>

                                <ul class="list-group list-group-unbordered mb-3">
                                    <li class="list-group-item">
                                        <b>Código</b> <a class="float-right">{{ ship.code }}</a>
                                    </li>

                                    <li class="list-group-item">
                                        <b>Iden. llamada</b> <a class="float-right">{{ ship.call_identifier }}</a>
                                    </li>

                                    <li class="list-group-item">
                                        <b>Supervisor</b> <a class="float-right">
                                            {{ ship.supervisor.name }} {{ ship.supervisor.last_name }}
                                        </a>
                                    </li>

                                    <li class="list-group-item">
                                        <b>Habitabilidad máxima</b> <a class="float-right">{{ ship.capacity }}</a>
                                    </li>

                                    <li class="list-group-item">
                                        <b>Motores</b> <a class="float-right">{{ ship.engines }}</a>
                                    </li>

                                    <li class="list-group-item">
                                        <b>Generadores</b> <a class="float-right">{{ ship.generators }}</a>
                                    </li>

                                    <li class="list-group-item">
                                        <b>Fecha registro</b>
                                        <a class="float-right">{{ ship.created_at | myDate }}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <!-- content -->
                    <div class="col">
                        <div class="card">
                            <loading v-if="loading"/>

                            <div class="card-header">
                                <ul class="nav nav-pills">
                                    <li class="nav-item" v-for="t in tabs">
                                        <a class="nav-link" :class="{'active': tab === t.component}"
                                           @click="tab = t.component">
                                            {{ t.title }}
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div class="card-body">
                                <component :is="tab"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Careens from "./ShipDetails/Careens";
    import Certificates from "./ShipDetails/Certificates";
    import Headlines from "./ShipDetails/Headlines";
    import Loading from "./Common/Loading";
    import Reviews from "./ShipDetails/Reviews";
    import TechnicalSpecifications from "./ShipDetails/TechnicalSpecifications";
    import Plans from "./ShipDetails/Plans";

    export default {
        components: { Careens, Certificates, Headlines, Loading, Reviews, TechnicalSpecifications,Plans },
        created () {
            this.getShipDetails();
        },
        data () {
            return {
                loading: true,
                ship: {
                    name: null,
                    code: null,
                    plate: null,
                    headlines: [],
                    certifications: [],
                    supervisor: {
                        name: null,
                        last_name: null
                    },
                    created_at: null
                },
                tab: 'Headlines',
                tabs: [
                    { title: 'Titulares', component: 'Headlines' },
                    { title: 'Certificaciones', component: 'Certificates' },
                    { title: 'Revistas', component: 'Reviews' },
                    { title: 'Carenas', component: 'Careens' },
                    { title: 'Esp. técnicas', component: 'TechnicalSpecifications' },
                    { title: 'Planos', component: 'Plans' },
                ]
            }
        },
        methods: {
            getShipDetails () {
                this.$Progress.start();
                this.loading = true;

                axios.get('/api/embarcaciones/' + this.$route.params.id)
                    .then(({ data }) => {
                        this.ship = data.data;
                        this.$Progress.finish();
                    })
                    .catch(() => {
                        this.$launchError('Error al obtener ficha de embarcación');
                        this.$Progress.fail();
                    })
                    .finally(() => this.loading = false);
            }
        }
    }
</script>

<style scoped>
    #certifications-table td:not(:first-child) {
        width: 120px;
    }
</style>

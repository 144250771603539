<template>

    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <loading v-if="loading"/>

                        <!-- header -->
                        <div class="card-header">
                            <h3 class="card-title">Listado de inducciones</h3>

                            <div class="card-tools">
                                <button type="button" class="btn btn-sm btn-primary" @click="creationModal">
                                    <i class="fa fa-plus-square"></i>
                                    Nueva inducción
                                </button>
                            </div>
                        </div>

<div >
<v-app >
                         <v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Búsqueda"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
  <v-data-table
      :headers="headers"
      :items="datos"
      :search="search"
       :loading="loading"
       :items-per-page="30"
       no-results-text="No se han encontrado inducciones"
       no-data-text="No existen inducciones"
      class="elevation-1"
        loading-text="Cargando... Espere"
         :footer-props="{
           'items-per-page-text':'Inducciones por página',
           'items-per-page-options': [20, 50, 100, -1]
      }"
    >
 <template v-slot:item.accion="{ item, index }">

                                            
                                            <a href="#" @click.prevent="editionModal(item.id)">
                                                
                                                 <v-icon small style="color: #3490DC !important;">
                                               fa-edit 
                                                </v-icon>
                                            </a>
                                            /
                                            <a href="#" @click.prevent="deleteInduction(item.id)">
                                           
                                                  <v-icon small color="red">
                                               fa-trash 
                                                </v-icon>
                                            </a>
                                                                        
  </template>
  <template v-slot:footer.page-text="items"> {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }} </template>
</v-data-table>
</v-card> 

  </v-app>
</div>
              

                        <!-- 
                        <div class="card-body table-responsive p-0">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th class="align-middle">Nombre</th>
                                        <th class="align-middle">Clientes</th>
                                        <th class="align-middle">Fecha creación</th>
                                        <th class="align-middle">Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="i in inductions.data">
                                        <td class="align-middle">{{ i.name }}</td>
                                        <td class="align-middle">{{ i.clients.map(c => c.name) | list }}</td>
                                        <td class="align-middle">{{ i.created_at | myDate }}</td>
                                        <td class="align-middle">
                                            <a href="#" @click.prevent="editionModal(i.id)">
                                                <i class="fa fa-edit blue"></i>
                                            </a>
                                            /
                                            <a href="#" @click.prevent="deleteInduction(i.id)">
                                                <i class="fa fa-trash red"></i>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                      
                        <div class="card-footer">
                            <pagination :data="inductions" @pagination-change-page="getInductions"/>
                        </div>

                        -->
                    </div>
                </div>
            </div>

            <!-- Modal -->
            <div class="modal fade" id="form-modal" tabindex="-1" role="dialog" aria-labelledby="form-dialog"
                 aria-hidden="true" data-backdrop="static" data-keyboard="false">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">{{ editionModalId ? 'Editar': 'Crear nueva' }} inducción</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <form>
                            <div class="modal-body container">
                                <induction-form :form="form"/>

                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                                    <button type="button" class="btn btn-primary" @click.prevent="submit">
                                        Guardar
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
   
</template>

<script>
    import InductionForm from "./Inductions/Form";
    import Loading from "../Common/Loading";

    export default {
        components: { InductionForm, Loading },
        created () {
            this.getInductions();
        },
        data () {
            return {
                clients: [],
                editionModalId: null,
                form: new Form({ name: null, clients: [] }),
                inductions: {},
                loading: false,
                search: '',
                headers: [
                {
                    text: 'Nombre',
                    align: 'start',
                    value: 'nombre',
                },
                { text: 'Clientes', value: 'cliente' },
                { text: 'Fecha Creación', value: 'fecha' },
                { text: 'Acciones', value: 'accion', sortable: false },
                ],
                datos:[],
            }
        },
        methods: {
            createInduction () {
                this.$Progress.start();

                this.form.post('/api/prevencionista/inducciones')
                    .then(() => {
                        this.$Progress.finish();
                        this.$launchSuccess('Éxito al crear inducción');
                        this.form.reset();
                        $('#form-modal').modal('hide');
                        this.getInductions();
                    })
                    .catch(() => {
                        this.$Progress.finish();
                        this.$launchError('Error al crear inducción');
                    });
            },

            creationModal () {
                this.editionModalId = null;
                this.getClients();
                this.form.reset();
                $('#form-modal').modal('show');
            },

            deleteInduction (id) {
                Swal.fire({
                    title: '¿Estás seguro que deseas eliminar la inducción?',
                    text: 'No podrás revertir esta acción',
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Si',
                    cancelButtonText: 'No'
                }).then((result) => {
                    if (result.value) {
                        this.form.delete('/api/prevencionista/inducciones/' + id).then(() => {
                            Swal.fire(
                                'Éxito',
                                'La inducción fue eliminada correctamente',
                                'success'
                            );

                            this.getInductions();
                        }).catch(() => Swal.fire(
                            'Error',
                            'Error al eliminar inducción',
                            'warning'
                        ));
                    }
                });
            },

            editInduction () {
                this.$Progress.start();

                this.form.post('/api/prevencionista/inducciones/' + this.editionModalId)
                    .then(() => {
                        this.$Progress.finish();
                        this.$launchSuccess('Éxito al editar inducción');
                        this.form.reset();
                        $('#form-modal').modal('hide');
                        this.getInductions();
                    })
                    .catch(() => {
                        this.$Progress.finish();
                        this.$launchError('Error al editar inducción');
                    });
            },

            editionModal (id) {
                this.editionModalId = id;
                const i = this.inductions.data.find(i => i.id === id);
                this.getClients();
                this.form.fill({ name: i.name, clients: i.clients.map(c => c.id) });
                $('#form-modal').modal('show');
            },

            getClients () {
                if (this.clients.length === 0) {
                    axios.get('/api/clientes')
                        .then(({ data }) => this.clients = data.data)
                        .catch(() => this.$launchError('Error al obtener listado de clientes'));
                }
            },

            getInductions (page = 1) {
                this.$Progress.start();
                this.loading = true;

                axios.get('/api/prevencionista/inducciones')
                    /*.then(({ data }) => {
                        this.inductions = data.data;
                        this.$Progress.finish();
                    })*/
                     .then(data => {
                    this.datos = [];
                    this.inductions = data.data;
                     console.log(this.inductions);
                        for(let i = 0; i < this.inductions.data.length; i++) {
                            var t = this.inductions.data[i];
                            var fecha = this.$options.filters.myDate(t.created_at ); 
                            var clientes = this.$options.filters.list(t.clients.map(c => c.name));  
                            this.datos.push({id:t.id,nombre:this.$options.filters.capitalize(t.name),cliente:clientes,fecha:fecha,accion:true});
                        }
                       console.log(this.datos);
                       this.$Progress.finish();
                     })
                    .catch(() => {
                        this.$Progress.finish();
                        this.$launchError('Error al obtener listado de inducciones');
                    })
                    .finally(() => this.loading = false);
            },

            submit () {
                if (this.editionModalId === null) this.createInduction();
                else this.editInduction();
            }
        }
    }
</script>
<style>
  .v-application--wrap {
    min-height: 0vh !important;
  }
</style>
<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">Detalle de traslado</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="modal-body container">
            <div class="row">
                <p class="col">
                    <strong>Salida: </strong> {{ relocation.departure }}.
                </p>

                <p class="col">
                    <strong>Destino: </strong> {{ relocation.arrival }}.
                </p>
            </div>

            <div class="row">
                <p class="col">
                    <strong>Tarifa: </strong> ${{ relocation.price | dotNumber }}.
                </p>

                <p class="col">
                    <strong>Proveedor: </strong> {{ relocation.relocation_provider.name }}.
                </p>
            </div>

            <div class="row">
                <p class="col">
                    <strong>Fecha y hora de recogida: </strong> {{ relocation.pickup | fullDateTime | capitalize }}.
                </p>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-primary" data-dismiss="modal">Cerrar</button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        computed: {
            relocation () {
                return this.worker.relocation_data;
            },
            worker () {
                return this.$parent.quotations.find(q => q.id === this.$parent.relocationQuotationId)
                    .crew.workers.find(w => w.id === this.$parent.relocationCrewWorkerId);
            }
        }
    }
</script>

<template>
    <div id="edit-crew-container">
        <!-- breadcrumb -->
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Botón de emergencia</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link to="/">Inicio</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link to="/jefe-flota/tripulaciones">Tripulaciones</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="`/jefe-flota/tripulaciones/${this.crewId}/editar`">Editar tripulacion</router-link>
                            </li>
                            <li class="breadcrumb-item">Botón de emergencia</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>

        <!-- edición -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col">
                        <div class="card">
                            <div class="card-body">
                                <!-- datos del tripulante -->
                                <div class="row"></div>

                                <!-- motivo del reemplazo -->
                                <div class="row">
                                    <div class="col form-group">
                                        <label for="reason">Motivo</label>
                                        <textarea id="reason" class="form-control" v-model="form.reason"></textarea>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col form-group">
                                        <label for="reason">Comprar Pasaje</label>
                                       <input id="relocation" type="checkbox" v-model="with_ticket">
                                    </div>
                                </div>

                                <!-- utiliza reemplazo -->
                                <div class="row">
                                    <div class="col form-group">
                                        <input type="radio" id="con-reemplazo" :value="true" v-model="useReplacement">
                                        <label for="con-reemplazo">Con reemplazo</label>
                                    </div>

                                    <div class="col form-group">
                                        <input type="radio" id="sin-reemplazo" :value="false" v-model="useReplacement">
                                        <label for="sin-reemplazo">Sin reemplazo</label>
                                    </div>
                                  
                                      <div class="col form-group md-4" v-if="!useReplacement">
                                            <label for="exit_date">Fecha de Bajada</label>
                                            <input v-model="form.entry_date" type="date" name="fecha de Bajada" class="form-control" style="width:200px;">
                                            <has-error :form="form" field="exit_date"/>
                                    </div>
                                    <div class="col form-group" v-if="useReplacement">
                                    </div>
                                
                                </div>

                                <!-- datos del reemplazo (en caso de haber) -->
                                <div class="row" v-if="useReplacement">
                                    <div class="col-12">
                                        <div class="mb-4 d-flex align-items-center">
                                            <div class="w-75">
                                                <span v-if="replacement">
                                                    <strong>Nombre : </strong> {{ replacement.name }} -
                                                    <strong>Rut : </strong> {{ replacement.rut }}
                                                </span>
                                                <i v-else>No se ha escogido un reemplazo aún.</i>
                                            </div>
                                            <div class="w-25 float-right">
                                                <button class="btn btn-primary" @click="setReplacement">
                                                    {{ replacement ? 'Cambiar' : 'Escoger' }} reemplazo
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 row">
                                        <div class="col form-group">
                                            <input id="lodging" type="checkbox" v-model="services.lodging">
                                            <label for="lodging">Alojamiento</label>
                                        </div>
                                        <div class="col form-group">
                                            <input id="lodging-food" type="checkbox" :disabled="!services.lodging"
                                                   v-model="services.lodging_food">
                                            <label for="lodging-food">Alimentación</label>
                                        </div>
                                        <div class="col form-group">
                                            <input id="relocation" type="checkbox" v-model="services.relocation">
                                            <label for="relocation">Traslado</label>
                                        </div>

                                        
                                        <div class="col form-group">
                                            <label for="reason">Fecha de Ingreso</label>
                                            <input v-model="form.entry_date" type="date" name="fecha de ingreso" class="form-control" style="width:200px;">
                                            <has-error :form="form" field="entry_date"/>
                                        </div>
                                    </div>
                                </div>
                              

                                 <div class="col form-group">
                                <button class="btn btn-primary float-right" :disabled="submit" @click="confirm">
                                    <i class="fa fa-spinner fa-spin mr-2" v-if="submit"></i>
                                    {{ submit ? 'Guardando' : 'Guardar' }}
                                </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- modal de reemplazo -->
                    <div class="modal fade" id="replacement-modal" tabindex="-1" role="dialog"
                         aria-labelledby="form-dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                        <replacement-modal v-if="showReplacementModal" :crew_id="crewId"/>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import ReplacementModal from "./EmergencyButton/ReplacementModal";
    import eventHub from "../../eventHub";

    export default {
        components: { ReplacementModal },
        computed: {
            canConfirm () {},
            crewId () {
                return this.$route.params.id;
            },
            crewWorkerId () {
                return this.$route.params.crewWorkerId;
            }
        },
        created () {
            eventHub.$on('replacementChosen', payload => {
                this.replacement = payload;
                $('#replacement-modal').modal('hide');
            });
        },
        data () {
            return {
                form: { reason: null,entry_date:null},
                replacement: null,
                with_ticket:true,
                services: {
                    lodging: false,
                    lodging_food: false,
                    relocation: false
                },
                showReplacementModal: null,
                submit: false,
                useReplacement: true
            }
        },
        destroyed() {
            eventHub.$off('replacementChosen');
        },
        methods: {
            confirm () {
                // valida
                if (this.form.reason === null || this.form.reason === '') {
                    this.$launchError('Debe especificar el motivo de la emergencia');
                    return;
                }

                if (this.useReplacement && this.replacement === null) {
                    this.$launchError('Debe escoger un trabajador de reemplazo');
                    return;
                }
                if (this.form.entry_date === null && this.useReplacement) {
                    this.$launchError('Debe elegir fecha de ingreso');
                    return;
                }

                Swal.fire({
                    title: '¿Estás seguro que deseas registrar esta emergencia?',
                    text: 'No podrás revertir esta acción',
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Si',
                    cancelButtonText: 'No'
                }).then((result) => {
                    if (result.value) {
                        axios.post(`/api/jefe-flota/tripulaciones/${this.crewId}/boton-emergencia/${this.crewWorkerId}`, {
                            emergency_crew_worker_id: this.crewWorkerId,
                            replacement_worker_id: this.replacement ? this.replacement.id : null,
                            services: this.services,
                            reason: this.form.reason,
                            entry_date: this.form.entry_date,
                             with_ticket: this.with_ticket,
                        })
                            .then(() => {
                                Swal.fire(
                                    'Éxito',
                                    'La emergencia fue registrada correctamente',
                                    'success'
                                );

                                this.$router.push({ path: `/jefe-flota/tripulaciones/${this.crewId}/editar` });
                            })
                            .catch(() => Swal.fire(
                                'Error',
                                'No se pudo registrar la emergencia',
                                'warning'
                            ));
                    }
                });
            },

            setReplacement () {
                this.showReplacementModal = true;
                this.$nextTick(() => $('#replacement-modal').modal('show'));
            }
        },
        watch: {
            useReplacement () {
                if (!this.useReplacement) {
                    this.replacement = null;
                    this.services = {
                        lodging: false,
                        lodging_food: false,
                        relocation: false
                    }
                }
            }
        }
    }
</script>

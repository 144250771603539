<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">Rechazar compra</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <form @submit.prevent="rejectPurchase">
            <div class="modal-body container">
                <!-- alert -->
                <div class="alert alert-warning" role="alert">
                    ¡Atención! Al presionar rechazar esta acción no podrá ser revertida.
                </div>

                <div class="row">
                    <div class="col form-group">
                        <label>Motivo</label>
                        <textarea class="form-control" v-model="form.reason"
                                  :class="{ 'is-invalid': form.errors.has('reason') }">
                        </textarea>
                        <has-error :form="form" field="reason"/>
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                    <button type="submit" class="btn btn-primary">Rechazar</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
    import eventHub from "../../../eventHub";

    export default {
        data () {
            return {
                form: new Form({
                    reason: null
                })
            }
        },
        methods: {
            rejectPurchase () {
                this.form.post(`/api/operaciones/compras/${this.$parent.id}/rechazar`)
                    .then(() => {
                        this.$launchSuccess('Éxito al rechazar compra');
                        this.form.reset();
                        eventHub.$emit('purchaseRejected');
                    })
                    .catch(() => this.$launchError('Error al rechazar compra'));
            }
        }
    }
</script>

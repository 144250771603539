<template>
    <div>
        <!-- breadcrumb -->
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Nueva inducción</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link to="/">Inicio</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link to="/admin/salud/trabajadores">Salud Tripulantes</router-link>
                            </li>
                            <li class="breadcrumb-item active">Nueva inducción</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>

        <!-- ficha -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col">
                        <div class="card">
                            <loading v-if="loading"/>

                            <div class="card-body">
                                <form @submit.prevent="saveInduction">
                                    <div class="row">
                                        <div class="col form-group">
                                            <label>Fecha inducción</label>
                                            <input type="date" class="form-control" v-model="form.induction_date">
                                        </div>

                                        <div class="col form-group">
                                            <label>Inducción</label>
                                            <select class="form-control" v-model="form.induction_id"
                                                    :disabled="inductions.length === 0">
                                                <option :value="null" disabled>Escoja una inducción</option>
                                                <option :value="i.id" v-for="i in inductions">{{ i.name }}</option>
                                            </select>
                                        </div>

                                        <div class="col form-group">
                                            <label>Certificado</label><br>
                                            <div class="custom-file">
                                                <input id="certificate" type="file" class="custom-file-input"
                                                       @change.prevent="upload" accept="application/pdf">
                                                <label class="custom-file-label" for="certificate">
                                                    {{ fileName ? fileName : 'Escoja un archivo' }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <button type="submit" class="btn btn-primary float-right" :disabled="submit">
                                        <i class="fa fa-spinner fa-spin mr-2" v-if="submit"></i>
                                        {{ submit ? 'Guardando' : 'Guardar' }}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Loading from "../Common/Loading";
    import moment from "moment";

    export default {
        components: { Loading },
        created () {
            this.getInductions();
            this.form.induction_date = moment(new Date()).format('yyyy-MM-dd');
        },
        data () {
            return {
                certificate: null,
                inductions: [],
                fileName: null,
                form: {
                    induction_id: null,
                    induction_date: null
                },
                loading: false,
                submit: false
            }
        },
        methods: {
            getInductions () {
                this.loading = true;
                this.$Progress.start();

                axios.get('/api/prevencionista/inducciones')
                    .then(({ data }) => this.inductions = data.data)
                    .catch(() => {
                        this.$launchError('Error al obtener listado de inducciones');
                        this.$Progress.fail();
                    })
                    .finally(() => {
                        this.loading = false;
                        this.$Progress.finish();
                    });
            },

            saveInduction () {
                Swal.fire({
                    title: '¿Estás seguro que deseas guardar esta inducción?',
                    text: 'No podrás revertir esta acción',
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Si',
                    cancelButtonText: 'No'
                }).then((result) => {
                    if (result.isConfirmed==true) {
                        this.$Progress.start();
                        this.submit = true;

                        let formData = new FormData();
                        formData.append('certificate', this.certificate);
                        formData.append('induction_id', this.form.induction_id);
                        formData.append('induction_date', this.form.induction_date);

                        axios.post(`/api/trabajadores/${this.$route.params.id}/inducciones`, formData, {
                            headers: { 'Content-Type': 'multipart/form-data' }
                        })
                            .then(() => {
                                Swal.fire(
                                    'Éxito',
                                    'La inducción fue registrada correctamente',
                                    'success'
                                );
                                this.form.induction_id = null;
                                this.form.induction_date = null;
                                this.certificate = null;
                                this.fileName = null;
                                this.$Progress.finish();
                            })
                            .catch(() => {
                                Swal.fire(
                                    'Error',
                                    'No se pudo registrar la inducción',
                                    'warning'
                                )
                                this.$Progress.fail();
                            })
                            .finally(() => this.submit = false);
                    }
                });
            },

            upload (e) {
                const f = e.target.files[0];

                this.certificate = f;
                this.fileName = f.name;
            }
        }
    }
</script>

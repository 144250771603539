<template>
    <div class="card-body box-profile">


        <h3 class="profile-username text-center">
            {{ person.name }} {{ person.last_name }}
        </h3>

        <p class="text-muted text-center">{{ person.rut }}</p>

        <ul class="list-group list-group-unbordered mb-3">

            <li class="list-group-item">
                <b>Email</b>
                <a class="float-right">{{ person.email}}</a>
            </li>
             <li class="list-group-item">
                <b>Telefono</b>
                <a class="float-right">{{ person.phone}}</a>
            </li>
             <li class="list-group-item">
                <b>Cargo</b>
                <a class="float-right">{{ person.role}}</a>
            </li>


        </ul>
    </div>
</template>

<script>
    export default {
        computed: {
            person () {
                return this.$parent.person;
            }
        }
    }
</script>

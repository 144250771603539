import { render, staticRenderFns } from "./SnackWorkerModal.vue?vue&type=template&id=04645b61&"
import script from "./SnackWorkerModal.vue?vue&type=script&lang=js&"
export * from "./SnackWorkerModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
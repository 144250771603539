<template>
<v-app>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <!-- header -->
                        <div class="card-header">
                            <h3 class="card-title">Solicitudes abastecimiento</h3>
                        </div>



<v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Búsqueda"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
  <v-data-table
      :headers="headers"
      :items="datos"
      :search="search"
       :loading="loading"
       :items-per-page="30"
       no-results-text="No se han encontrado solicitudes"
       no-data-text="No existen solicitudes"
      class="elevation-1"
        loading-text="Cargando... Espere"
         :footer-props="{
           'items-per-page-text':'Solicitudes por página',
           'items-per-page-options': [20, 50, 100, -1]
      }"
    >
 <template v-slot:item.accion="{ item, index }">

                                                <router-link :to="`/bodega/solicitudes-abastecimiento/${item.id}`"
                                                     v-tooltip="'Generar compra'">
                                        
                                               <v-icon small style="color: #3490DC !important;">
                                               fas fa-dollar-sign
                                                </v-icon>
                                        </router-link>

                                        <span> / </span>

                                        <a  @click="downloadExcel(item.id)" v-tooltip="'Descargar Excel'"
                                           target="_blank">
                                            <v-icon small style="color: #3490DC !important;">
                                               fas fa-download
                                                </v-icon>
                                        </a>

                                   
                                                                                             
  </template>
  <template v-slot:footer.page-text="items"> {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }} </template>
</v-data-table>
</v-card> 


                        <!--
                        <div class="card-body table-responsive p-0">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th class="align-middle">Cliente</th>
                                        <th class="align-middle">Embarcación</th>
                                        <th class="align-middle">Fecha subida</th>
                                        <th class="align-middle">Fecha bajada real</th>
                                        <th class="align-middle">Fecha bajada protocolar</th>
                                        <th class="align-middle">Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                 
                                <tr v-for="c in crews.data">
                                
                                    <td class="align-middle">{{ c.client.name }}</td>
                                    <td class="align-middle">{{ c.ship.name }}</td>
                                    <td class="align-middle">{{ c.upload_date | myDate }}</td>
                                    <td class="align-middle">{{ c.real_download_date | myDate }}</td>
                                    <td class="align-middle">{{ c.download_date | myDate }}</td>
                                    <td class="align-middle">
                                        <router-link :to="`/bodega/solicitudes-abastecimiento/${c.id}`"
                                                     v-tooltip="'Generar compra'">
                                            <i class="fas fa-dollar-sign blue"></i>
                                        </router-link>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>

                       
                        <div class="card-footer">
                            <pagination :data="crews" @pagination-change-page="getCrews"/>
                        </div>

                        -->
                    </div>
                </div>
            </div>
        </div>
    </section>
    </v-app>
</template>

<script>
    export default {
        created () {
            this.getCrews();
        },
        data () {
            return {
               crews: {},
                 search:'',
                 headers: [{ text: 'Cliente', value: 'cliente' },
                {
                    text: 'Embarcación',
                    align: 'start',
                    value: 'nombre',
                },
                { text: 'Fecha Presentacion', value: 'presentacion' },
                { text: 'Fecha Bajada Real', value: 'real' },
                { text: 'Fecha Bajada Protocolar', value: 'protocolar' },
                { text: 'Acciones', value: 'accion', sortable: false },
                ],
                datos:[],
            }
        },
        methods: {
            downloadExcel(downloadid){
                        this.$Progress.start();
                        axios({
                                url: '/api/bodega/descargar-solicitud-abastecimiento/'+downloadid,
                                method: 'get',
                                responseType: 'blob',
                            }).then((response) => {
                                this.$Progress.finish();
                                this.$launchSuccess('Informe generado');
                                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                                var fileLink = document.createElement('a');
                                fileLink.href = fileURL;
                                var contentDisposition = response.headers['content-disposition'];
                                var fileName = 'unknown';
                                if (contentDisposition) {
                                    fileName = (contentDisposition|| "").split("filename=")[1];
                                    console.log(contentDisposition);
                                    fileName = fileName.slice(1,-1);
                                }
                                fileLink.setAttribute('download', fileName);
                                document.body.appendChild(fileLink);
                                fileLink.click();
                            }).catch(() => {
                            this.$Progress.fail();
                            this.$launchError('Error al generar informe');
                        });
            },
            getCrews (page = 1) {
                this.$Progress.start();
                this.loading =true;

                axios.get('/api/bodega/tripulaciones')
                    /*.then(({ data }) => {
                        this.crews = data.data;
                        this.$Progress.finish();
                    })*/
                    .then(data => {
                    this.datos = [];
                    this.crews = data.data;
                     console.log(this.crews);
                        for(let i = 0; i < this.crews.data.length; i++) {
                            var t = this.crews.data[i];
                            //var protocolar = this.$options.filters.capitalize(t.ship_type.name ); 
                            var barco = this.$options.filters.capitalize(t.ship.name); 
                            var presentacion = this.$options.filters.myDate(t.upload_date);  
                            var real = this.$options.filters.myDate(t.real_download_date);  
                            var protocolar = this.$options.filters.myDate(t.download_date);  
                            this.datos.push({nombre:barco,cliente:t.client.name,presentacion:presentacion,real:real,protocolar:protocolar,id:t.id,accion:true});
                        }
                       console.log(this.datos);
                       this.loading =false;
                       this.$Progress.finish();
                     })
                    .catch(() => {
                        this.$Progress.fail();
                        this.$launchError('Error al obtener listado de solicitudes de abastecimiento');
                    });

            }
        }
    }
</script>

<template>
    <div>
        <div class="card-body table-responsive p-0" v-if="hasExams">
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th class="align-middle">Condición</th>
                        <th class="align-middle">Fecha vencimiento</th>
                        <th class="align-middle">Observaciones</th>
                        <th class="align-middle">Fecha registro</th>
                        <th class="align-middle">Subido por</th>
                        <th class="align-middle">Descargar</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="e in exams.data" :key="e.id">
                        <td class="align-middle">
                            <span class="badge" :class="{
                                'bg-success': e.condition,
                                'bg-danger': !e.condition
                            }">
                                {{ e.condition ? 'Apto' : 'Contraindicado' }}
                            </span>
                        </td>
                        <td class="align-middle">{{ e.expiration_date | myDate }}</td>
                        <td class="align-middle">{{ e.observations | dash }}</td>
                        <td class="align-middle">{{ e.exam_date | myDate }}</td>
                        <td class="align-middle">{{ e.uploader.name}} {{ e.uploader.last_name }}</td>
                        <td class="align-middle">
                            <a :href="downloadFileUrl(
                                `occupational-exams/${$parent.id}/${e.certificate}`
                                )" target="_blank" v-if="e.condition">
                                <i class="fas fa-file-download blue"></i>
                            </a>
                            <span v-else>-</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <pagination :data="exams" @pagination-change-page="getExams" v-if="hasExams"/>
        <span v-else><i>No se han subido exámenes ocupacionales a este trabajador.</i></span>
    </div>
</template>

<script>
export default {
    created () {
        this.getExams();
    },
    computed: {
        hasExams () {
            return this.exams.data.length > 0;
        }
    },
    data () {
        return {
            exams: { data: [] }
        }
    },
    methods: {
        getExams (page = 1) {
            this.$Progress.start();
            this.$parent.loading = true;

            axios.get('/api/trabajadores/' + this.$route.params.id + '/examenes-ocupacionales?page=' + page)
                .then(({ data }) => {
                    this.exams = data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$launchError('Error al obtener exámenes ocupacionales del trabajador');
                    this.$Progress.fail();
                })
                .finally(() => {
                    this.$Progress.finish();
                    this.$parent.loading = false;
                });
        }
    }
}
</script>

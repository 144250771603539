<template>
    <form @submit.prevent="saveContract">
        <div class="row">
            <div class="col form-group">
                <label>Fecha de contrato</label>
                <input v-model="form.date" type="date" name="fecha contrato" class="form-control"
                       :class="{ 'is-invalid': form.errors.has('date') }">
                <has-error :form="form" field="date"/>
            </div>

            <div class="col form-group">
                <label>Tipo de contrato</label>
                <select v-model="form.contract_type_id" type="date" name="tipo contrato" class="form-control"
                        :class="{ 'is-invalid': form.errors.has('contract_type_id') }">
                    <option :value="null" disabled>Escoja un tipo de contrato</option>
                    <option :value="t.id" v-for="t in contractTypes">{{ t.name | capitalize }}</option>
                </select>
                <has-error :form="form" field="contract_type_id"/>
            </div>
        </div>

        <div class="row">
            <div class="col form-group">
                <label>AFP</label>
                <select v-model="form.afp_id" name="AFP" class="form-control"
                        :class="{ 'is-invalid': form.errors.has('afp_id') }">
                    <option :value="null" disabled>Escoja una AFP</option>
                    <option :value="a.id" v-for="a in afps">{{ a.name }}</option>
                </select>
                <has-error :form="form" field="afp_id"/>
            </div>

            <div class="col form-group">
                <label>Previsión de salud</label>
                <select v-model="form.health_forecast_id" name="AFP" class="form-control"
                        :class="{ 'is-invalid': form.errors.has('health_forecast_id') }">
                    <option :value="null" disabled>Escoja una previsión de salud</option>
                    <option :value="h.id" v-for="h in healthForecasts">{{ h.name }}</option>
                </select>
                <has-error :form="form" field="health_forecast_id"/>
            </div>
        </div>

        <div class="row">
            <div class="col-6 form-group">
                <label>Copia de carnet</label>
                <div class="custom-file">
                    <input id="identity-card" type="file" class="custom-file-input"
                           @change.prevent="uploadIdentityCard">
                    <label class="custom-file-label" for="identity-card">Escoja un archivo</label>
                </div>
                <has-error :form="form" field="identity_card_copy"/>
            </div>
        </div>

        <button type="submit" class="btn btn-primary float-right">Guardar</button>
    </form>
</template>

    <script>
    export default {
        created () {
            this.$Progress.start();
            this.getContract();
            this.getAfps();
            this.getHealthForecasts();
            this.getContractTypes();
            this.$Progress.finish();
        },
        data () {
            return {
                afps: [],
                healthForecasts: [],
                contractTypes: [],
                form: new Form({
                    date: null,
                    afp_id: null,
                    health_forecast_id: null,
                    contract_type_id: null
                }),
                identityCard: null
            }
        },
        methods: {
            getContract () {
                this.$parent.loading = true;

                axios.get(`/api/usuarios/${this.$parent.id}/contrato`)
                    .then(({ data }) => {
                        if (data.data) this.form.fill(data.data);
                    })
                    .catch(() => this.$launchError('Error al obtener contrato de usuarios'));
            },

            getAfps () {
                axios.get('/api/afps').then(({ data }) => this.afps = data.data)
                    .catch(() => this.$launchError('Error al obtener listado de AFP'));
            },

            getHealthForecasts () {
                axios.get('/api/previsiones-salud').then(({ data }) => this.healthForecasts = data.data)
                    .catch(() => this.$launchError('Error al obtener listado de previsiones de salud'));
            },

            getContractTypes () {
                axios.get('/api/tipos-contrato').then(({ data }) => this.contractTypes = data.data)
                    .catch(() => this.$launchError('Error al obtener listado de tipos de contrato'))
                    .finally(() => this.$parent.loading = false);
            },

            saveContract () {
                this.$Progress.start();

                let formData = new FormData();
                formData.append('date', this.form.date);
                formData.append('identity_card_copy', this.identityCard);
                formData.append('afp_id', this.form.afp_id);
                formData.append('health_forecast_id', this.form.health_forecast_id);
                formData.append('contract_type_id', this.form.contract_type_id);

                axios.post(`/api/usuarios/${this.$parent.id}/contrato`, formData)
                    .then(() => {
                        this.$Progress.finish();
                        this.$launchSuccess('Éxito al guardar contrato de usuario');
                    })
                    .catch(() => {
                        this.$Progress.fail();
                        this.$launchError('Error al guardar contrato de usuario');
                    });
            },

            uploadIdentityCard (e) {
                this.identityCard = e.target.files[0];
            }
        }
    }
</script>

<template>
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <!-- header -->
            <div class="modal-header">
                <h5 class="modal-title">{{ edit ? 'Editar': 'Añadir' }} Traslado</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <!-- body -->
            <form @submit.prevent="setTransfer">
                <div class="modal-body container">
                    <div class="row">
                        <div class="col form-group">
                                   <label>Fecha Recogida</label>
                                   <input type="date" class="form-control" v-model="form.upload_date">
                        </div>

                        <div class="col form-group">
                            <label>Precio Total</label>
                            <input type="number" class="form-control" min='0' v-model="form.price">
                            
                        </div>
                    </div>

                    <div class="row">
                        <div class="col form-group">
                            <label>Ingreso</label>
                            <input type="text" class="form-control" v-model="form.entry_text">
                             
                        </div>

                        <div class="col form-group">
                             <label>Salida</label>
                              <input type="text" class="form-control" v-model="form.exit_text">
                        </div>
                    </div>

                    <div class="row">
                        <div class="col form-group">
                             <label>Proveedor</label>
                                            <select class="form-control" v-model="form.supplier_id"
                                                    :disabled="suppliers.length === 0">
                                                <option :value="null" disabled>Escoja un proveedor</option>
                                                <option :value="i.id" v-for="i in suppliers">{{ i.name }}</option>
                                            </select>
                        </div>

                        <div class="col form-group">
                            <label>Observacion</label>
                            <input type="text" class="form-control" v-model="form.observation">
                        </div>
                    </div>

                    <div class="row">
                          <div class="col form-group">
                            <label>Tramo</label>
                            <select name="tramo" class="form-control" v-model="form.journey_type"
                                    :class="{ 'is-invalid': form.errors.has('journey_type') }">
                                <option :value="null" disabled>Escoja un tramo</option>
                                <option :value="1">Ida</option>
                                <option :value="2">Vuelta</option>
                            </select>
                            <has-error :form="form" field="journey_type"/>
                        </div>

                        <div class="col form-group">
                           <input id="nofacturar" type="checkbox" v-model="form.no_facturar" style="margin-top:40px !important;margin-left:40px !important;">
                            <label for="nofacturar">Facturar</label>
                        </div>

                     
                    </div>

                 
                  
                  
                </div>

                <div class="modal-footer">
                    <button type="submit" class="btn btn-primary float-right">Guardar</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    import eventHub from "../../../eventHub";

    export default {
        props: ['transferId'],
        computed: {
            edit () {
                return this.transferId !== null;
            }
        },
        created () {
            this.$Progress.start();
            this.getSuppliers();
            this.$Progress.finish();

            // si es edición, debe llenar el formulario
            console.log(this.edit);
            if (this.edit) this.fillEditForm();

            // registra eventos
            eventHub.$on('cleanTransferForm', () => this.form.reset());
        },
        data () {
            return {
                suppliers:[],
                form: new Form({
                    upload_date: '',
                    entry_text: '',
                    exit_text: '',
                    price:'',
                    supplier_id: null,
                    observation:'',
                    no_facturar:false,
                    id:null,
                    selected:[],
                    selectedWorkers:[],
                    isedit:0,
                    journey_type:null
                })
            }
        },
        destroyed() {
            eventHub.$off('cleanTransferForm');
        },
        methods: {
             getSuppliers () {
                this.loading = true;
                this.$Progress.start();

                axios.get('/api/bodega/proveedores/traslado')
                    .then(({ data }) => this.suppliers = data.data)
                    .catch(() => {
                        this.$launchError('Error al obtener listado de proveedores');
                        this.$Progress.fail();
                    })
                    .finally(() => {
                        this.loading = false;
                        this.$Progress.finish();
                    });
            },
             setTransfer(){
                        if(this.form.supplier_id==null || this.form.upload_date=='' || this.form.price=='') {
                            this.$launchError('Error, debe completar todos los datos y agregar personas');
                        }else{
                            Swal.fire({
                                    title: '¿Estás seguro que deseas guardar este traslado?',
                                    text: 'No podrás revertir esta acción',
                                    showCancelButton: true,
                                    confirmButtonColor: '#d33',
                                    cancelButtonColor: '#3085d6',
                                    confirmButtonText: 'Si',
                                    cancelButtonText: 'No'
                                }).then((result) => {
                                    if (result.value) {
                                        this.form.selected = this.selectedData;
                                        this.form.selectedWorkers = this.selectedDataWorkers;
                                        this.form.transfer_id = this.transferId;
                                        this.form.quotation_id = this.$parent.id;
                                        console.log(this.form);
                                        this.form.post('/api/administracion/personas/nuevo/trasladoCrew')
                                        .then(() => {
                                            eventHub.$emit('reloadTransfers');
                                            this.$launchSuccess('Se guardo el traslado correctamente');
                                            this.$Progress.finish();
                                        })
                                        .catch(() => {
                                            this.$Progress.fail();
                                            this.$launchError('Error al guardar traslado');
                                        });
                                        }
                                });   
                        }               
            },
            fillEditForm () {
                
                let t = this.$parent.quotation.transfers.find(t => t.id === this.transferId);
                console.log(t);
                this.form.entry_text = t.entry_text;
                this.form.exit_text = t.exit_text;
                this.form.price = t.price;
                this.form.upload_date = t.upload_date;
                this.form.observation = t.observation;
                this.form.no_facturar = t.quotation;
                this.form.id = this.transferId;
                this.form.isedit = 1;
                this.form.supplier_id = t.supplier_id;
                this.form.journey_type =t.journey_type;
               // this.form.fill(t);
            }
        }
    }
</script>

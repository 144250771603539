<template>
<v-app>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <loading v-if="loading"/>

                        <!-- header -->
                        <div class="card-header">
                            <h3 class="card-title">Listado de certificaciones</h3>

                            <div class="card-tools">
                                <button type="button" class="btn btn-sm btn-primary" @click="creationModal">
                                    <i class="fa fa-plus-square"></i>
                                    Nueva certificación
                                </button>
                            </div>
                        </div>

<v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Búsqueda"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
  <v-data-table
      :headers="headers"
      :items="datos"
      :search="search"
       :loading="loading"
       :items-per-page="30"
       no-results-text="No se han nencontrado certificaciones"
       no-data-text="No existen certificaciones"
      class="elevation-1"
        loading-text="Cargando... Espere"
         :footer-props="{
           'items-per-page-text':'Certificaciones por página',
           'items-per-page-options': [20, 50, 100, -1]
      }"
    >
 <template v-slot:item.accion="{ item, index }">

                                           <a href="#" @click="editCertificate(item.id)">
                                                <v-icon small style="color: #3490DC !important;">
                                               fa fa-edit
                                                </v-icon>
                                            </a>
                                            /
                                            <a href="#" @click="deleteCertificate(item.id)">
                                            
                                                   <v-icon small color="red">
                                               fa fa-trash
                                                </v-icon>
                                            </a>
                                                                                             
  </template>
  <template v-slot:footer.page-text="items"> {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }} </template>
</v-data-table>
</v-card> 

                        <!-- 
                        <div class="card-body table-responsive p-0">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th class="align-middle">Nombre</th>
                                        <th class="align-middle">Tipo embarcación</th>
                                        <th class="align-middle">Fecha creación</th>
                                        <th class="align-middle">Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="i in certificates.data">
                                        <td class="align-middle">{{ i.name }}</td>
                                        <td class="align-middle">{{ i.ship_type.name | capitalize }}</td>
                                        <td class="align-middle">{{ i.created_at | myDate }}</td>
                                        <td class="align-middle">
                                            <a href="#" @click="editCertificate(i.id)">
                                                <i class="fa fa-edit blue"></i>
                                            </a>
                                            /
                                            <a href="#" @click="deleteCertificate(i.id)">
                                                <i class="fa fa-trash red"></i>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                       
                        <div class="card-footer">
                            <pagination :data="certificates" @pagination-change-page="getCertificates"/>
                        </div>
                        -->
                    </div>
                </div>
            </div>

            <!-- Modal -->
            <div class="modal fade" id="form-modal" tabindex="-1" role="dialog" aria-labelledby="form-dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">{{ isEdition ? 'Editar' : 'Crear nueva' }} certificación</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <form @submit.prevent="saveCertificate">
                            <div class="modal-body container">
                                <certificate-form :form="form" :types="shipTypes"/>

                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                                    <button type="submit" class="btn btn-primary">Crear</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </v-app>
</template>

<script>
import CertificateForm from "./Form";
import Loading from "../Common/Loading";

export default {
    components: { CertificateForm, Loading },
    created () {
        this.getCertificates();
    },
    data () {
        return {
            isEdition: false,
            editionId: null,
            form: new Form({
                name: null,
                ship_type_id: null
            }),
            loading: true,
            shipTypes: [],
            certificates: {},
            search:'',
                 headers: [
                {
                    text: 'Nombre',
                    align: 'start',
                    value: 'nombre',
                },
                { text: 'Tipo embarcación', value: 'tipo' },
                { text: 'Fecha creación', value: 'fecha' },
                { text: 'Acciones', value: 'accion', sortable: false },
                ],
                datos:[],
        }
    },
    methods: {
        creationModal () {
            this.isEdition = false;
            this.editionId = null;
            this.form.reset();
            this.getShipTypes();
            this.$nextTick(() => $('#form-modal').modal('show'));
        },

        deleteCertificate (id) {
            Swal.fire({
                title: '¿Estás seguro que deseas eliminar la certificación?',
                text: 'No podrás revertir esta acción',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Si',
                cancelButtonText: 'No'
            }).then((result) => {
                if (result.value) {
                    axios.delete('/api/mantencion/certificaciones/' + id).then(() => {
                        Swal.fire(
                            'Éxito',
                            'La certificación fue eliminada correctamente',
                            'success'
                        );

                        this.getCertificates();
                    }).catch(() => Swal.fire(
                        'Error',
                        'No se pudo eliminar la certificación',
                        'warning'
                    ));
                }
            });
        },

        editCertificate (id) {
            const c = this.certificates.data.find(c => c.id === id);
            this.isEdition = true;
            this.editionId = id;
            this.form.fill(c);
            this.getShipTypes();
            this.$nextTick(() => $('#form-modal').modal('show'));
        },

        getCertificates (page = 1) {
            this.$Progress.start();
            this.loading = true;

            axios.get('/api/mantencion/certificaciones')
                /*.then(({ data }) => {
                    this.certificates = data.data;
                    this.$Progress.finish();
                })*/
                .then(data => {
                    this.datos = [];
                    this.certificates = data.data;
                     console.log(this.certificates);
                        for(let i = 0; i < this.certificates.data.length; i++) {
                            var t = this.certificates.data[i];
                            var tipo = this.$options.filters.capitalize(t.ship_type.name ); 
                            var fecha = this.$options.filters.myDate(t.created_at);  
                            this.datos.push({nombre:t.name,tipo:tipo,fecha:fecha,id:t.id,accion:true});
                        }
                       console.log(this.datos);
                       this.$Progress.finish();
                     })
                .catch(() => {
                    this.$launchError('Error al obtener listado de certificaciones');
                    this.$Progress.fail();
                })
                .finally(() => this.loading = false);
        },

        getShipTypes () {
            axios.get('/api/tipos-embarcaciones')
                .then(({ data }) => this.shipTypes = data.data)
                .catch(() => this.$launchError('Error al obtener listado de tipos de embarcación'));
        },

        saveCertificate () {
            this.$Progress.start();

            this.form.post('/api/mantencion/certificaciones' + (this.isEdition ? `/${this.editionId}` : ''))
                .then(() => {
                    this.$Progress.finish();
                    this.$launchSuccess(`Éxito al ${this.isEdition ? 'editar' : 'crear'} certificación`);
                    $('#form-modal').modal('hide');
                    this.getCertificates();
                })
                .catch(() => {
                    this.$Progress.fail();
                    this.$launchError(`Error al ${this.isEdition ? 'editar' : 'crear'} certificación`);
                });
        }
    }
}
</script>

export default class Gate {
    constructor (position) {
        this.position = position;
    }

    isAdmin () {
        return this.position.name === 'Administrador';
    }
    isSupervisor () {
        return this.position.name === 'Supervisor';
    }
    isAbastecimiento () {
        return this.position.name === 'Abastecimiento';
    }
    isBodega () {
        return this.position.name === 'Bodega';
    }
    isGerente () {
        return this.position.name === 'Gerente de operaciones';
    }
    isPlanificacion () {
        return this.position.name === 'Ing. planificación y control de mantenimiento';
    }
    isServiciotaller () {
        return this.position.name === 'Ing. servicio y taller';
    }
    isJefeflota () {
        return this.position.name === 'Jefe de flota';
    }
    isJefeoperaciones () {
        return this.position.name === 'Jefe de operaciones';
    }
    isLogistica () {
        return this.position.name === 'Logística';
    }
    isPrevencionista () {
        return this.position.name === 'Prevencionista';
    }
    isRrhh () {
        return this.position.name === 'RRHH';
    }
    isSubcontratacion () {
        return this.position.name === 'Sub contratación';
    }





}


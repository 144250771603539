<template>
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <!-- header -->
            <div class="modal-header">
                <h5 class="modal-title">Añadir trabajadores</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <!-- body -->
            <div class="modal-body container">
                <!-- tabla -->
                <div class="table-responsive p-0">
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Nombre</th>
                                <th>Rut</th>
                                <th>Rol</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="w in workers.data">
                                <td class="align-middle">
                                    <input type="checkbox" v-model="selected" :value="w.id"
                                           @change="toggleSelection(w.id)">
                                </td>
                                <td>{{ w.name }} {{ w.last_name }}</td>
                                <td>{{ w.rut }}</td>
                                <td>{{ w.roles.map(r => r.name) | list }}</td>
                            </tr>
                        </tbody>
                    </table>

                    <pagination :data="workers" @pagination-change-page="getAvailableWorkers"/>
                </div>

                <!-- selección -->
                <div class="tag-input" v-if="selectedData.length > 0">
                    <div v-for="s in selectedData" class="tag-input__tag">
                        <span @click="unselect(s.id)">x</span>
                        {{ s.name }}
                    </div>
                </div>
                <span v-else><i>Aún no se han escogido trabajadores</i></span>

                <!-- footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                    <button type="button" class="btn btn-primary" @click="saveHeadlines" :disabled="false">
                        Guardar
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import eventHub from "../../../eventHub";

    export default {
        created () {
            this.getAvailableWorkers();
        },
        data () {
            return {
                selected: [],
                selectedData: [],
                workers: {}
            }
        },
        methods: {
            getAvailableWorkers (page = 1) {
                this.$Progress.start();

                axios.get(`/api/embarcaciones/${this.$route.params.id}/trabajadores-disponibles-titulares?page=${page}`)
                    .then(({ data }) => this.workers = data.data)
                    .catch(() => this.$launchError('Error al obtener listado de trabajadores disponibles'))
                    .finally(() => this.$Progress.finish());
            },

            saveHeadlines () {
                if (this.selected.length) {
                    this.$Progress.start();

                    axios.post(`/api/embarcaciones/${this.$route.params.id}/titulares`, {
                        workers: this.selected
                    })
                        .then(() => {
                            eventHub.$emit('workersSaved');
                            this.$launchSuccess('Éxito al registrar titulares');
                        })
                        .catch(() => this.$launchError('Error al crear titulares'))
                        .finally(() => this.$Progress.finish());
                } else this.$launchError('Debe escoger al menos un trabajador antes de guardar');
            },

            toggleSelection (id) {
                if (this.selected.includes(id)) {
                    let w = this.workers.data.find(w => w.id === id);

                    this.selectedData.push({
                        id: id,
                        name: `${w.name} ${w.last_name}`
                    });
                } else {
                    this.selectedData.splice(this.selectedData.findIndex(s => s.id === id), 1);
                }
            },

            unselect (id) {
                this.selected.splice(this.selected.findIndex(s => s === id), 1);
                this.selectedData.splice(this.selectedData.findIndex(s => s.id === id), 1);
            }
        }
    }
</script>

<!-- TODO: pasar a componente -->
<style scoped>
.tag-input {
    width: 100%;
    border: 1px solid #eee;
    font-size: 0.9em;
    height: 50px;
    box-sizing: border-box;
    padding: 0 10px;
}

.tag-input__tag {
    height: 30px;
    float: left;
    margin-right: 10px;
    background-color: #eee;
    margin-top: 10px;
    line-height: 30px;
    padding: 0 5px;
    border-radius: 5px;
}

.tag-input__tag > span {
    cursor: pointer;
    opacity: 0.75;
}
</style>

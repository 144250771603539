<template>
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Acciones de artículo de bodega</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div>
                <div class="modal-body container">
                    <div class="row">
                        <div class="col form-group">
                            <input id="item-compra" type="radio" value="purchase" v-model="form.action">
                            <label class="ml-2" for="item-compra">Incluír en compra</label>
                        </div>

                        <div class="col form-group">
                            <input id="item-bodega" type="radio" value="warehouse" v-model="form.action" :disabled="(form.warehouseQuantity<quantity) || form.warehouseQuantity==0">
                            <label class="ml-2" for="item-bodega">Utilizar stock bodega ({{ form.warehouseQuantity }})</label>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col form-group">
                            <label>Valor</label>
                            <input min="0" type="number" class="form-control" v-model="form.price" :disabled="!purchase">
                        </div>
                    </div>

                    <div class="alert alert-warning" role="alert" v-if="purchase">
                        Si se modifica el valor original del artículo, este se convertirá en el nuevo valor del mismo.
                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" data-dismiss="modal">
                            Guardar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['itemPurchaseId','quantity'],
    computed: {
        // indica si se ha decidido incluír en la compra
        purchase () {
            return this.form.action === 'purchase';
        }
    },
    created () {
        this.loadForm();
    },
    data () {
        return {
            form: {
                action: null
            },
            given_quantity:null,
        }
    },
    methods: {
        loadForm () {
            this.form = this.$parent.form.warehouse_items[this.itemPurchaseId];
            this.given_quantity = this.quantity;
        }
    },
    watch: {
        itemPurchaseId () {
            this.loadForm();
        }
    }
}
</script>

<template>
    <div>
        <div class="row">
            <div class="col form-group">
                <label>Nombre</label>
                <input v-model="form.name" type="text" name="nombre" class="form-control"
                       :class="{ 'is-invalid': form.errors.has('name') }">
                <has-error :form="form" field="name"/>
            </div>
        </div>

        <!-- clientes -->
        <label>Clientes</label>
        <div class="row">
            <div class="col-6 form-group" v-for="c in clients">
                <input :id="`client-${c.id}`" :value="c.id" type="checkbox" v-model="form.clients"/>
                <label :for="`client-${c.id}`">{{ c.name }}</label>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        computed: {
            clients () {
                return this.$parent.clients;
            }
        },
        props: {
            form: Object
        }
    }
</script>

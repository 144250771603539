<template>
    <div>
        <div class="row">
            <div class="col form-group">
                <label>Nombre</label>
                <input v-model="form.name" type="text" name="nombre" class="form-control"
                       :class="{ 'is-invalid': form.errors.has('name') }">
                <has-error :form="form" field="name"></has-error>
            </div>

            <div class="col form-group">
                <label>Código</label>
                <input v-model="form.code" type="text" name="código"
                       class="form-control" :class="{ 'is-invalid': form.errors.has('code') }">
                <has-error :form="form" field="code"></has-error>
            </div>
        </div>

        <div class="row">
            <div class="col form-group">
                <label>Matrícula</label>
                <input v-model="form.plate" type="text" name="matrícula"
                       class="form-control" :class="{ 'is-invalid': form.errors.has('plate') }">
                <has-error :form="form" field="plate"></has-error>
            </div>

            <div class="col form-group">
                <label>Identificación de llamada</label>
                <input v-model="form.call_identifier" type="text" name="identificación de llamada"
                       class="form-control" :class="{ 'is-invalid': form.errors.has('call_identifier') }">
                <has-error :form="form" field="call_identifier"></has-error>
            </div>
        </div>

        <div class="row">
            <div class="col form-group">
                <label>Habitabilidad máxima</label>
                <input v-model="form.capacity" min="0" type="number" name="habitabilidad máxima"
                       class="form-control" :class="{ 'is-invalid': form.errors.has('capacity') }">
                <has-error :form="form" field="capacity"></has-error>
            </div>

            <div class="col form-group">
                <label>Motores</label>
                <input v-model="form.engines" min="0" type="number" name="motores"
                       class="form-control" :class="{ 'is-invalid': form.errors.has('engines') }">
                <has-error :form="form" field="engines"></has-error>
            </div>
        </div>

        <div class="row">
            <div class="col form-group">
                <label>Generadores</label>
                <input v-model="form.generators" min="0" type="number" name="generadores"
                       class="form-control" :class="{ 'is-invalid': form.errors.has('generators') }">
                <has-error :form="form" field="generators"></has-error>
            </div>

            <div class="col form-group">
                <label>Tipo</label>
                <select v-model="form.type_id" class="form-control" name="supervisor"
                        :class="{ 'is-invalid': form.errors.has('type_id') }">
                    <option :value="null" disabled>Escoja un tipo</option>
                    <option :value="t.id" v-for="t in shipTypes">{{ t.name | capitalize }}</option>
                </select>
                <has-error :form="form" field="type_id"></has-error>
            </div>
        </div>

        <div class="row">
            <div class="col form-group">
                <label>Supervisor</label>
                <select v-model="form.supervisor_id" class="form-control" name="supervisor"
                        :class="{ 'is-invalid': form.errors.has('supervisor_id') }">
                    <option :value="null" disabled>Escoja un supervisor</option>
                    <option :value="s.id" v-for="s in supervisors">{{ s.name }} {{ s.last_name }}</option>
                </select>
                <has-error :form="form" field="supervisor_id"></has-error>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            isEdition: {
                type: Boolean,
                default: false
            },
            supervisors: {
                type: Array,
                default () {
                    return []
                }
            },
            shipTypes: {
                type: Array,
                default () {
                    return []
                }
            },
            form: Object
        }
    }
</script>

<template>
    <div>
        <div class="row">
            <div class="col form-group">
                <label>Nombre</label>
                <input v-model="form.name" type="text" name="nombre" class="form-control"
                       :class="{ 'is-invalid': form.errors.has('name') }">
                <has-error :form="form" field="name"/>
            </div>

            <div class="col form-group">
                <label>Tipo embarcación</label>
                <select class="form-control" v-model="form.ship_type_id"
                        :class="{ 'is-invalid': form.errors.has('ship_type_id') }">
                    <option :value="null" disabled>Escoja un tipo de embarcación</option>
                    <option :value="t.id" v-for="t in types">{{ t.name | capitalize }}</option>
                </select>
                <has-error :form="form" field="ship_type_id"/>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: { form: Object, types: Array }
    }
</script>

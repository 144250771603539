<template>
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <!-- header -->
            <div class="modal-header">
                <h5 class="modal-title">{{ edit ? 'Editar': 'Añadir' }} Pasaje</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <!-- body -->
            <form @submit.prevent="saveTicket">
                <div class="modal-body container">
                    <div class="row">
                        <div class="col form-group">
                            <label>Aerolínea</label>
                            <select name="aerolínea" class="form-control" v-model="form.airline_id"
                                    :class="{ 'is-invalid': form.errors.has('airline_id') }">
                                <option :value="null" disabled>Escoja una aerolínea</option>
                                <option :value="a.id" v-for="a in airlines">{{ a.name }}</option>
                            </select>
                            <has-error :form="form" field="airline_id"/>
                        </div>

                        <div class="col form-group">
                            <label>Tarifa unitaria</label>
                            <input v-model="form.price" type="number" min="0" name="tarifa" class="form-control" v-on:change="calculate()" v-on:keyup="calculate()"
                                   :class="{ 'is-invalid': form.errors.has('price') }" >
                            <has-error :form="form" field="price"/>
                        </div>

                      <!--  <div class="col form-group">
                            <label>Tarifa Plus</label>
                                            <select name="plus" class="form-control" v-model="form.tarifa_plus"
                                                    :class="{ 'is-invalid': form.errors.has('tarifa_plus') }" :disabled='have_stock==1 || is_stock==1'>
                                                <option :value="0">No</option>
                                                <option :value="1">Si</option>     
                                            </select>
                                            <has-error :form="form" field="tarifa_plus"/>
                        </div>
                        -->

                        <div class="col form-group">
                                            <label>Diferencial Tarifa Total (x{{many}})</label>
                                            <input v-model="form.extra_price" type="number" min="0" name="extra" class="form-control"
                                                :disabled='have_stock==1 || is_stock==1'>
                                            <has-error :form="form" field="extra"/>
                                        </div>
                    </div>


                    <!-- fin primera linea -->
                    

                    <div class="row">

                         <div class="col form-group">
                            <label>Fecha de vuelo</label>
                            <input v-model="form.flight_date" type="date" name="fecha de vuelo" class="form-control"
                                   :class="{ 'is-invalid': form.errors.has('flight_date') }">
                            <has-error :form="form" field="flight_date"/>
                        </div>

                        <div class="col form-group">
                            <label>Tramo</label>
                            <select name="tramo" class="form-control" v-model="form.flight_stretch_id"
                                    :class="{ 'is-invalid': form.errors.has('flight_stretch_id') }">
                                <option :value="null" disabled>Escoja un tramo</option>
                                <option :value="f.id" v-for="f in flightStretches">{{ f.name }}</option>
                            </select>
                            <has-error :form="form" field="flight_stretch_id"/>
                        </div>

                       

                        <div class="col form-group">
                            <label>Hora de partida</label>
                            <input v-model="form.departure_time" type="time" name="hora de partida" class="form-control"
                                   :class="{ 'is-invalid': form.errors.has('departure_time') }">
                            <has-error :form="form" field="departure_time"/>
                        </div>

                        <div class="col form-group">
                            <label>Hora de llegada</label>
                            <input v-model="form.arrival_time" type="time" name="hora de llegada" class="form-control"
                                   :class="{ 'is-invalid': form.errors.has('arrival_time') }">
                            <has-error :form="form" field="arrival_time"/>
                        </div>
                    </div>


                    <div class="row">
                         <div class="col form-group">
                                             <label>Fecha de Vencimiento</label>
                                            <input v-model="form.expiration_date" type="date" name="fecha de vencimiento" class="form-control"
                                                :class="{ 'is-invalid': form.errors.has('expiration_date') }" :disabled='have_stock==1 || is_stock==1'>
                                            <has-error :form="form" field="expiration_date"/>
                                        </div>

                        <div class="col form-group">
                            <label>Aeropuerto de origen</label>
                            <select name="aeropuerto de origen" class="form-control" v-model="form.departure_airport_id"
                                    :class="{ 'is-invalid': form.errors.has('departure_airport_id') }">
                                <option :value="null" disabled>Escoja un aeropuerto</option>
                                <option :value="a.id" v-for="a in airports">{{ a.iata }} - {{ a.city }}</option>
                            </select>
                            <has-error :form="form" field="departure_airport_id"/>
                        </div>

                        <div class="col form-group">
                            <label>Aeropuerto de destino</label>
                            <select name="aeropuerto de destino" class="form-control" v-model="form.arrival_airport_id"
                                    :class="{ 'is-invalid': form.errors.has('arrival_airport_id') }">
                                <option :value="null" disabled>Escoja un aeropuerto</option>
                                <option :value="a.id" v-for="a in airports">{{ a.iata }} - {{ a.city }}</option>
                            </select>
                            <has-error :form="form" field="arrival_airport_id"/>
                        </div>
                    </div>




                 <div class="row">
                         <div class="col form-group">
                                            <label>Codigo de Vuelo</label>
                                            <input v-model="form.code"  type="text" min="0" name="code" class="form-control"
                                                :disabled='have_stock==1 || is_stock==1'>
                                            <has-error :form="form" field="carry"/>
                                        </div>

                        <!--<div class="col form-group">
                            <label>Multa</label>
                                            <input v-model="form.penalty" type="number" min="0" name="penalty" class="form-control"
                                                :disabled='have_stock==1 || is_stock==1' v-on:change="calculate()" v-on:keyup="calculate()">
                                            <has-error :form="form" field="carry"/>
                        </div>-->

                        <div class="col form-group">
                           <label>Afecto</label>
                                            <input v-model="form.dearness" type="number" min="0" name="dearness" class="form-control" v-on:change="calculate()" v-on:keyup="calculate()"
                                                :disabled='have_stock==1 || is_stock==1'>
                                            <has-error :form="form" field="carry"/>
                        </div>
                        

                                    <div class="col form-group">
                                        <label>Orden de compra</label>
                                        <input type="text" v-model="form.purchase_order" class="form-control"
                                               :class="{ 'is-invalid': form.errors.has('purchase_order') }">
                                        <has-error :form="form" field="purchase_order"/>
                                    </div>

                                    <div class="col form-group">
                                        <label>Fecha Orden de compra</label>
                                        <input type="date" v-model="form.purchase_order_date" class="form-control"
                                               :class="{ 'is-invalid': form.errors.has('purchase_order_date') }">
                                        <has-error :form="form" field="purchase_order_date"/>
                                    </div>

                        
                    </div>



                    <div class="row">


                        <div class="col form-group">
                            <label>Utilizar...</label>
                                                <select name="stock" class="form-control" v-model="form.stock_ticket_id"
                                                        :disabled="form.airline_id == null "  v-on:change="calculate()">
                                                    <option :value='null'>Ninguno</option>
                                                    <option :value='1' :disabled='stocks.length==0 || stocks==null'>
                                                        Pasaje en Stock
                                                    </option>
                                                     <option :value='2' :disabled='vouchers.length==0 || vouchers==null'>
                                                         Voucher
                                                    </option>
                                                </select>
                        </div>

                        <div class="col form-group" :hidden="form.stock_ticket_id!==1">
                                 <label>Pasajes en stock</label>
                                                <select name="stock" class="form-control" v-model="form.stock_id"
                                                        :disabled="stocks.length === 0 || stocks==null" v-on:change="calculate()">
                                                    <option :value="null">Escoja un pasaje</option>
                                                    <option :value="s.id" v-for="s in stocks">
                                                        {{ s.code }} - {{ s.name }} - {{s.price | dotNumber}}
                                                    </option>
                                                </select>
                        </div>

                        <div class="col form-group" :hidden="form.stock_ticket_id!==2">
                                <label>Vouchers</label>
                                                <select name="stock" class="form-control" v-model="form.voucher_id" :disabled="vouchers.length === 0 || vouchers==null" v-on:change="calculate()">
                                                    <option :value="null">Escoja un Voucher</option>
                                                    <option :value="s.id" v-for="s in vouchers">
                                                        Monto :${{ s.price | dotNumber}} - Código :{{ s.code }} {{s.contain_type}}
                                                    </option>
                                                </select>
                        </div>



                    </div>
                </div>

                <div class="modal-footer">
                    <button type="submit" class="btn btn-primary float-right">Guardar</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    import eventHub from "../../../eventHub";

    export default {
        props: ['ticketId'],
        computed: {
            edit () {
                return this.ticketId !== null;
            }
        },
        created () {
            this.$Progress.start();
            this.getAirlines();
            this.getAirports();
            this.getFlightStretches();

            this.$Progress.finish();

            // si es edición, debe llenar el formulario
            if (this.edit) this.fillEditForm();

                     if(this.edit!=null){
                            if(this.edit == false){
                                this.form.isedit = 0;
                            }else{
                                this.form.isedit = 1;
                            }
                    }else{
                        this.form.isedit = 0;
                    }

            // registra eventos
            eventHub.$on('cleanFlightForm', () => this.form.reset());
        },
        data () {
            return {
                airlines: [],
                airports: [],
                flightStretches: [],
                use_enabled:0,
                stocks:{},
                datos:null,
                have_stock:false,
                is_stock:false,
                stocks:{},
                vouchers:{},
                novoucher:0,
                nostock:0,
                loaded:0,
                editable:1,
                original_payment_type:null,
                original_voucher_id:null,
                original_stock_id:null,
                original_airline_id:null,
                final_total_price:0,
                many:0,
                form: new Form({
                    purchase_order_date:null,
                    purchase_order:null,
                    id: null,
                    flight_stretch_id: null,
                    flight_date: null,
                    departure_time: null,
                    arrival_time: null,
                    departure_airport_id: null,
                    arrival_airport_id: null,
                    extra_price:0,
                    carry_price:0,
                    price: 0,
                    tostock:null,
                    tostockWorkers:null,
                    airline_id: null,
                    stock_ticket_id: null,
                    tarifa_plus:0,
                    selected:[],
                    selectedWorkers:[],
                    isedit:1,
                    ticket_id:null,
                    penalty:0,
                    dearness:0,
                    stock_id:null,
                    voucher_id:null,
                    code:null,
                    expiration_date:null,
                    passengers:0,
                    total_price:0,
                    total_extra_price:0,
                    payment_price:0,
                    real_total_price:0,
                    payment_type:null,
                    unitary_extra_price:0
                }),
                stocks: []
            }
        },
        destroyed() {
            eventHub.$off('cleanFlightForm');
        },
        methods: {
            fillEditForm () {
                let t = this.$parent.quotation.tickets.find(t => t.id === this.ticketId);
                const d = t.departure_time.split(':');
                const a = t.arrival_time.split(':');
                t.departure_time = `${d[0]}:${d[1]}`;
                t.arrival_time = `${a[0]}:${a[1]}`;
                t.id = this.ticketId;
               

                this.form.fill(t);
                 this.form.extra_price = t.extra_price;
                 this.form.stock_ticket_id = t.payment_type;
                 this.form.voucher_id = t.voucher_id;
                  this.form.stock_id = t.stock_id;
                  this.form.tarifa_plus = t.plus;
                  this.form.purchase_order = t.oc;
                  this.form.purchase_order_date = t.oc_date;
                  this.many = t.passengers;
            },

            getAirlines () {
                axios.get('/api/logistica/aerolineas')
                    .then(({ data }) => this.airlines = data.data)
                    .catch(() => this.$launchError('Error al obtener aerolíneas'));
            },

            getAirports () {
                axios.get('/api/logistica/aeropuertos')
                    .then(({ data }) => this.airports = data.data)
                    .catch(() => this.$launchError('Error al obtener aeropuertos'));
            },

            getFlightStretches () {
                axios.get('/api/logistica/tramos')
                    .then(({ data }) => this.flightStretches = data.data)
                    .catch(() => this.$launchError('Error al obtener tramos'));
            },

            saveTicket () {
                this.calculate();
                this.form.ticket_id = this.ticketId;
                console.log(this.edit);
                if(this.edit!=null){
                    if(this.edit == false){
                         this.form.isedit = 0;
                    }else{
                         this.form.isedit = 1;
                    }
                   
                }else{
                    this.form.isedit = 0;
                }
                
                this.form.quotation_id = this.$route.params.id;
              //  this.form.total_price = this.final_total_price;
              //          this.form.total_extra_price = this.form.extra_price;

                        if(this.form.stock_ticket_id==1){
                            this.form.voucher_id = null;
                        }
                        if(this.form.stock_ticket_id==2){
                            this.form.stock_id = null;
                        }
                        if(this.form.stock_ticket_id==null){
                            this.form.stock_id = null;
                            this.form.voucher_id = null;
                        }
                        
                        console.log(this.form);
                this.form.post('/api/administracion/pasajes/guardar-pasaje')
                    .then(() => {
                        eventHub.$emit('reloadQuotationTicket');
                        this.$launchSuccess(`Éxito al ${this.edit ? 'editar': 'crear'} pasaje`);
                    })
                    .catch(() => this.$launchError(`Error al ${this.edit ? 'editar': 'crear'} pasaje`));
            },
            getVouchers (id) {
                   
                    if(this.form.isedit == 0){
                         axios.get('/api/administracion/pasajes/vouchers/'+id)
                        .then(({ data }) => this.vouchers = data.data)
                        .catch();
                    }else{
                        let t = this.$parent.quotation.tickets.find(t => t.id === this.ticketId);
                        if(t.voucher_id==null){
                            axios.get('/api/administracion/pasajes/vouchers/'+id)
                            .then(({ data }) => this.vouchers = data.data)
                            .catch();
                        }else{
                            axios.get('/api/administracion/pasajes/vouchersWithUsed/'+id+'/'+t.voucher_id+'/'+this.ticketId)
                            .then(({ data }) => this.vouchers = data.data,this.form.voucher_id = t.voucher_id)
                            .catch();
                        }
                    }
                 
           
            },
            getStocks (id) {

                if(this.form.isedit == 0){
                    axios.get('/api/administracion/pasajes/stocks/'+id)
                    .then(({ data }) => this.stocks = data.data)
                    .catch();
                }else{
                     let t = this.$parent.quotation.tickets.find(t => t.id === this.ticketId);
                    if(t.stock_ticket_id==null){
                        axios.get('/api/administracion/pasajes/stocks/'+id)
                        .then(({ data }) => this.stocks = data.data)
                        .catch();
                        
                    }else{
                        axios.get('/api/administracion/pasajes/stocksWithUsed/'+id+'/'+t.stock_ticket_id)
                        .then(({ data }) => this.stocks = data.data,this.form.stock_id = t.stock_ticket_id)
                        .catch();     
                    }
                }
                
            },
             calculate(){      
               

                     let real_total = 0;
                     let t = this.$parent.quotation.tickets.find(t => t.id === this.ticketId); 
   

                    if(this.form.penalty!==null && this.form.penalty!==''){
                        real_total = parseInt(real_total) + parseInt(this.form.penalty);
                    }
                    if(this.form.dearness!==null && this.form.dearness!==''){
                        real_total = parseInt(real_total) + parseInt(this.form.dearness);
                    }

                     if(t!=null){
                        if(t.temporal_extra_price!=null && t.temporal_extra_price!='NaN' && t.temporal_extra_price!=0){
                            real_total = real_total + parseInt(t.temporal_extra_price);
                        }
                     }else{
                         this.many = 0;
                     }
                                

                    
                     if(this.form.voucher_id==null && this.form.stock_id==null){                  
                        this.form.extra_price =  parseInt(real_total) + (parseInt(this.form.price) * parseInt(this.many));
                        this.form.total_price = parseInt(real_total) + (parseInt(this.form.price) * parseInt(this.many));
                        this.form.payment_price = 0;
                        this.form.unitary_extra_price = 0;
                        if(this.many>0){
                            this.form.unitary_extra_price = this.form.extra_price / this.many;
                        }        
                    }


                    if(this.vouchers!=null){
                            if(this.form.stock_ticket_id==2 && this.vouchers.length>0){
                                if(this.form.voucher_id!=null){
                                        let w = this.vouchers.find(w => w.id === this.form.voucher_id);
                                        let diff = (parseInt(this.form.price) * this.many) + parseInt(real_total);
                                        this.form.payment_price = w.price;
                                        this.form.total_price = diff;
                                        if(diff>w.price){
                                            this.form.extra_price = (diff-w.price);  
                                        }else{
                                            this.form.extra_price = 0;
                                        }                    
                                        this.form.unitary_extra_price =0;
                                        if(this.many>0){
                                            this.form.unitary_extra_price = this.form.extra_price / this.many;
                                        }
                                }
                            }    
                    }
                     
                    
                    if(this.stocks!=null){
                        if(this.form.stock_ticket_id==1 && this.stocks.length>0){
                            if(this.form.stock_id!=null){     
                                        let w = this.stocks.find(w => w.id === this.form.stock_id);                                    
                                        let diff = (parseInt(this.form.price) * this.many) + parseInt(real_total);
                                        this.form.payment_price = w.price;
                                        this.form.total_price = diff;
                                        if(diff>w.price){
                                            this.form.extra_price = (diff-w.price);  
                                        }else{
                                            this.form.extra_price = 0;
                                        }                    
                                        this.form.unitary_extra_price =0;
                                        if(this.many>0){
                                            this.form.unitary_extra_price = this.form.extra_price / this.many;
                                        }
                                                        
                                    
                            }                
                        }
                    }

                    if(this.form.stock_ticket_id==null){                 
                        this.form.extra_price = parseInt(real_total) + (parseInt(this.form.price)* this.many);
                        this.form.total_price = parseInt(real_total) + (parseInt(this.form.price) * this.many) ;
                        this.form.payment_price = 0;
                        this.form.unitary_extra_price =0;
                        if(this.many>0){
                            this.form.unitary_extra_price = this.form.extra_price / this.many;
                        }
                    }
                      
               
              
            },

        },
        watch: {
            ticketId () {
                if (this.edit) this.fillEditForm();
            },
            'form.airline_id' () {
                if (this.form.airline_id) {
                                this.getVouchers(this.form.airline_id);
                                this.getStocks(this.form.airline_id);
                            
                }else{
                    this.form.stock_ticket_id = null;
                    this.form.voucher_id = null;
                    this.form.stock_id = null;
                    this.vouchers = null;
                    this.stocks = null;
                
        
                }

                this.calculate();

            },
            'form.stock_ticket_id' () {
               // if (this.form.stock_ticket_id) this.form.price = 0;
            }
        }
    }
</script>

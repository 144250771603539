<template>
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <!-- header -->
            <div class="modal-header">
                <h5 class="modal-title">{{ edit ? 'Editar': 'Añadir' }} pasajexxx</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <!-- body -->
            <form @submit.prevent="saveTicket">
                <div class="modal-body container">
                    <div class="row">
                        <div class="col form-group">
                            <label>Aerolínea</label>
                            <select name="aerolínea" class="form-control" v-model="form.airline_id"
                                    :class="{ 'is-invalid': form.errors.has('airline_id') }">
                                <option :value="null" disabled>Escoja una aerolínea</option>
                                <option :value="a.id" v-for="a in airlines">{{ a.name }}</option>
                            </select>
                            <has-error :form="form" field="airline_id"/>
                        </div>

                        <div class="col form-group">
                            <label>Tarifa unitaria</label>
                            <input v-model="form.price" type="number" min="0" name="tarifa" class="form-control"
                                   :class="{ 'is-invalid': form.errors.has('price') }" :disabled="form.stock_ticket_id">
                            <has-error :form="form" field="price"/>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col form-group">
                            <label>Tramo</label>
                            <select name="tramo" class="form-control" v-model="form.flight_stretch_id"
                                    :class="{ 'is-invalid': form.errors.has('flight_stretch_id') }">
                                <option :value="null" disabled>Escoja un tramo</option>
                                <option :value="f.id" v-for="f in flightStretches">{{ f.name }}</option>
                            </select>
                            <has-error :form="form" field="flight_stretch_id"/>
                        </div>

                        <div class="col form-group">
                            <label>Fecha de vuelo</label>
                            <input v-model="form.flight_date" type="date" name="fecha de vuelo" class="form-control"
                                   :class="{ 'is-invalid': form.errors.has('flight_date') }">
                            <has-error :form="form" field="flight_date"/>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col form-group">
                            <label>Hora de partida</label>
                            <input v-model="form.departure_time" type="time" name="hora de partida" class="form-control"
                                   :class="{ 'is-invalid': form.errors.has('departure_time') }">
                            <has-error :form="form" field="departure_time"/>
                        </div>

                        <div class="col form-group">
                            <label>Hora de llegada</label>
                            <input v-model="form.arrival_time" type="time" name="hora de llegada" class="form-control"
                                   :class="{ 'is-invalid': form.errors.has('arrival_time') }">
                            <has-error :form="form" field="arrival_time"/>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col form-group">
                            <label>Aeropuerto de origen</label>
                            <select name="aeropuerto de origen" class="form-control" v-model="form.departure_airport_id"
                                    :class="{ 'is-invalid': form.errors.has('departure_airport_id') }">
                                <option :value="null" disabled>Escoja un aeropuerto</option>
                                <option :value="a.id" v-for="a in airports">{{ a.iata }} - {{ a.city }}</option>
                            </select>
                            <has-error :form="form" field="departure_airport_id"/>
                        </div>

                        <div class="col form-group">
                            <label>Aeropuerto de destino</label>
                            <select name="aeropuerto de destino" class="form-control" v-model="form.arrival_airport_id"
                                    :class="{ 'is-invalid': form.errors.has('arrival_airport_id') }">
                                <option :value="null" disabled>Escoja un aeropuerto</option>
                                <option :value="a.id" v-for="a in airports">{{ a.iata }} - {{ a.city }}</option>
                            </select>
                            <has-error :form="form" field="arrival_airport_id"/>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col form-group">
                            <label>Pasajes en stock</label>
                            <select name="stock" class="form-control" v-model="form.stock_ticket_id"
                                    :disabled="stocks.length === 0">
                                <option :value="null" disabled>Escoja un pasaje</option>
                                <option :value="s.id" v-for="s in stocks">
                                    {{ s.created_at | myDate }} - {{s.name}} {{s.last_name}} - [{{s.code}}]
                                </option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="submit" class="btn btn-primary float-right">Guardar</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    import eventHub from "../../../eventHub";

    export default {
        props: ['ticketId'],
        computed: {
            edit () {
                return this.ticketId !== null;
            }
        },
        created () {
            this.$Progress.start();
            this.getAirlines();
            this.getAirports();
            this.getFlightStretches();
            this.$Progress.finish();

            // si es edición, debe llenar el formulario
            if (this.edit) this.fillEditForm();

            // registra eventos
            eventHub.$on('cleanFlightForm', () => this.form.reset());
        },
        data () {
            return {
                airlines: [],
                airports: [],
                flightStretches: [],
                form: new Form({
                    id: null,
                    flight_stretch_id: null,
                    flight_date: null,
                    departure_time: null,
                    arrival_time: null,
                    departure_airport_id: null,
                    arrival_airport_id: null,
                    price: 0,
                    airline_id: null,
                    stock_ticket_id: null
                }),
                stocks: []
            }
        },
        destroyed() {
            eventHub.$off('cleanFlightForm');
        },
        methods: {
            fillEditForm () {
                let t = this.$parent.quotation.tickets.find(t => t.id === this.ticketId);
                const d = t.departure_time.split(':');
                const a = t.arrival_time.split(':');
                t.departure_time = `${d[0]}:${d[1]}`;
                t.arrival_time = `${a[0]}:${a[1]}`;
                t.id = this.ticketId;

                this.form.fill(t);
            },

            getAirlines () {
                axios.get('/api/logistica/aerolineas')
                    .then(({ data }) => this.airlines = data.data)
                    .catch(() => this.$launchError('Error al obtener aerolíneas'));
            },

            getAirports () {
                axios.get('/api/logistica/aeropuertos')
                    .then(({ data }) => this.airports = data.data)
                    .catch(() => this.$launchError('Error al obtener aeropuertos'));
            },

            getFlightStretches () {
                axios.get('/api/logistica/tramos')
                    .then(({ data }) => this.flightStretches = data.data)
                    .catch(() => this.$launchError('Error al obtener tramos'));
            },

            saveTicket () {
                this.form.post(`/api/logistica/boton-emergencia/cotizaciones/${this.$parent.id}/pasajes${this.edit ? '/' + this.ticketId: ''}`)
                    .then(() => {
                        eventHub.$emit('reloadQuotationTicket');
                        this.$launchSuccess(`Éxito al ${this.edit ? 'editar': 'crear'} pasaje`);
                    })
                    .catch(() => this.$launchError(`Error al ${this.edit ? 'editar': 'crear'} pasaje`));
            }
        },
        watch: {
            ticketId () {
                if (this.edit) this.fillEditForm();
            },
            'form.airline_id' () {
                if (this.form.airline_id) {
                    this.form.stock_ticket_id = null;
                    axios.get(`/api/logistica/aerolineas/${this.form.airline_id}/stock`)
                        .then(({ data }) => this.stocks = data.data)
                        .catch(() => this.$launchError('Error al obtener pasajes en stock'));
                }
            },
            'form.stock_ticket_id' () {
                if (this.form.stock_ticket_id) this.form.price = 0;
            }
        }
    }
</script>

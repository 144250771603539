var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-dialog modal-xl",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header align-items-center"},[_c('h5',{staticClass:"modal-title"},[_vm._v("Añadir tripulantes "+_vm._s(_vm.available ? 'disponibles' : 'en sala espera'))]),_vm._v(" "),_vm._m(0)]),_vm._v(" "),_c('div',{staticClass:"modal-body container"},[(!_vm.available)?_c('div',{staticClass:"alert alert-warning",attrs:{"role":"alert"}},[_vm._v("\n                    Si escoge a una persona de esta lista, tendrá documentos vencidos que deberán ser actualizados antes de poder embarcarse, favor tomar en consideración esta advertencia. Abreviatura : (Des) = Descanso,(Emb) = Embarcado,(Dis) = Disponible,(Vac) = Vacaciones.\n                ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"table-responsive p-0"},[_c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Búsqueda","single-line":"","hide-details":""},model:{value:(_vm.searchWorkers),callback:function ($$v) {_vm.searchWorkers=$$v},expression:"searchWorkers"}})],1),_vm._v(" "),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.datosWorkers,"search":_vm.searchWorkers,"loading":_vm.loading,"items-per-page":10,"no-results-text":"No se han encontrado tripulantes","no-data-text":"No existen tripulantes","loading-text":"Cargando... Espere","footer-props":{
           'items-per-page-text':'Tripulantes por página',
           'items-per-page-options': [10,15,20]
      }},scopedSlots:_vm._u([{key:"item.accion",fn:function(ref){
      var item = ref.item;
      var index = ref.index;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selected),expression:"selected"}],attrs:{"type":"checkbox"},domProps:{"value":item.id,"checked":Array.isArray(_vm.selected)?_vm._i(_vm.selected,item.id)>-1:(_vm.selected)},on:{"change":[function($event){var $$a=_vm.selected,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=item.id,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.selected=$$a.concat([$$v]))}else{$$i>-1&&(_vm.selected=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.selected=$$c}},function($event){return _vm.toggleSelection(item.id)}]}})]}},{key:"item.motivo",fn:function(ref){
      var item = ref.item;
      var index = ref.index;
return [_c('worker-reasons',{attrs:{"status":item.motivo}})]}},{key:"item.estado",fn:function(ref){
      var item = ref.item;
      var index = ref.index;
return [_c('worker-status',{attrs:{"status":item.estado}})]}},{key:"footer.page-text",fn:function(items){return [_vm._v(" "+_vm._s(items.pageStart)+" - "+_vm._s(items.pageStop)+" de "+_vm._s(items.itemsLength)+" ")]}}])})],1)],1),_vm._v(" "),(_vm.selectedData.length > 0)?_c('div',{staticClass:"tag-input"},_vm._l((_vm.selectedData),function(s){return _c('div',{staticClass:"tag-input__tag"},[_c('span',{on:{"click":function($event){return _vm.unselect(s.id)}}},[_vm._v("x")]),_vm._v("\n                        "+_vm._s(s.name)+"\n                    ")])}),0):_c('span',[_c('i',[_vm._v("Aún no se han escogido tripulantes")])]),_vm._v(" "),_c('div',{staticClass:"modal-footer"},[_c('span',{staticClass:"ml-3"},[_vm._v("\n                        "+_vm._s(_vm.$parent.validWorkersLength + _vm.selected.length)+" / "+_vm._s(_vm.$parent.crew.ship.capacity)+"\n                    ")]),_vm._v(" "),_c('button',{staticClass:"btn btn-secondary",attrs:{"type":"button","data-dismiss":"modal"}},[_vm._v("Cerrar")]),_vm._v(" "),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button","disabled":!_vm.canSave},on:{"click":_vm.saveWorkers}},[_vm._v("\n                        Guardar\n                    ")])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])}]

export { render, staticRenderFns }
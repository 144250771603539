<template>
<v-app>
  <section class="content">
      <div class="container-fluid">
          <div class="row">
              <div class="col-12">
                  <div class="card">
                      <loading v-if="loading"/>

                      <div class="card-header">
                          <div class="row align-items-center">
                              <h3 class="col card-title">Listado de tripulantes</h3>

                              <div class="col-6">
                                <!--  <form @submit.prevent="getWorkers">
                                      <input type="text" v-model="searchInput" class="form-control"
                                             placeholder="Ingrese nombre y/o apellido de tripulante">
                                  </form>-->
                              </div>

                              <div class="col-2">
                                  <button type="button" v-if="$gate.isSubcontratacion() || $gate.isGerente() || $gate.isAdmin() || $gate.isRrhh() || $gate.isJefeflota()" class="btn btn-sm btn-primary ml-2" @click="creationModal">
                                      <i class="fa fa-plus-square"></i>
                                      Nuevo tripulante
                                  </button>
                              </div>
                          </div>
                      </div>


<v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Búsqueda"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
  <v-data-table
      :headers="headers"
      :items="datos"
      :search="search"
       :loading="loading"
       :items-per-page="30"
       no-results-text="No se han encontrado tripulantes"
       no-data-text="No existen tripulantes"
      class="elevation-1"
        loading-text="Cargando... Espere"
         :footer-props="{
           'items-per-page-text':'Tripulantes por página',
           'items-per-page-options': [20, 50, 100, -1]
      }"
    >
 <template v-slot:item.accion="{ item, index }">

                                         

                                             <router-link :to="'/admin/trabajadores/' + item.id"
                                                       v-tooltip="'Ficha del tripulante'">
                                     
                                                 <v-icon small color="green">
                                            fa fa-info-circle
                                                </v-icon>
                                          </router-link>
                                          <span v-if="$gate.isSubcontratacion() || $gate.isRrhh() || $gate.isGerente() || $gate.isAdmin() || $gate.isJefeflota()">/</span>
                                          <router-link v-if="$gate.isSubcontratacion() || $gate.isRrhh() || $gate.isGerente() || $gate.isAdmin() || $gate.isJefeflota()" :to="`/admin/trabajadores/${item.id}/editar`"
                                                       v-tooltip="'Editar tripulante'">
                                 
                                                 <v-icon small style="color: #3490DC !important;">
                                            fa fa-edit
                                                </v-icon>
                                          </router-link>
                                          <span v-if="$gate.isSupervisor() || $gate.isGerente() || $gate.isAdmin()">/</span>
                                          <router-link  v-if="$gate.isSupervisor() || $gate.isGerente() || $gate.isAdmin()" :to="`/supervisor/trabajadores/${item.id}/calificar`"
                                                       v-tooltip="'Calificar tripulante'">
                                            
                                               <v-icon small color="yellow">
                                           fas fa-award
                                                </v-icon>
                                          </router-link>
                                          <span v-if="$gate.isSupervisor() || $gate.isGerente() || $gate.isAdmin() || $gate.isPrevencionista()">/</span>
                                          <router-link v-if="$gate.isSupervisor() || $gate.isGerente() || $gate.isAdmin() || $gate.isPrevencionista()" :to="`/bodega/trabajadores/${item.id}/entrega-epp`"
                                                       v-tooltip="'Entrega de EPP'">
                                        
                                               <v-icon small color="orange">
                                         fas fa-people-carry
                                                </v-icon>
                                          </router-link>
                                           <span v-if="$gate.isSubcontratacion() || $gate.isGerente() || $gate.isAdmin() || $gate.isPrevencionista()">/</span>
                                          <router-link v-if="$gate.isSubcontratacion() || $gate.isGerente() || $gate.isAdmin() || $gate.isPrevencionista()" :to="`/prevencionista/trabajadores/${item.id}/nueva-induccion`"
                                                       v-tooltip="'Nueva inducción'">
                                           
                                                <v-icon small style="color: #3490DC !important;">
                                         fa fa-graduation-cap
                                                </v-icon>
                                          </router-link>
                                          
                                          <span v-if="$gate.isGerente() || $gate.isAdmin() || $gate.isPrevencionista()">/</span>
                                          <router-link v-if="$gate.isGerente() || $gate.isAdmin() || $gate.isPrevencionista()" :to="`/prevencionista/trabajadores/${item.id}/salud`"
                                                       v-tooltip="'Salud'">
                                        
                                              <v-icon small color="green">
                                         fa fa-plus
                                                </v-icon>
                                          </router-link>

                                          <span v-if="($gate.isSubcontratacion() || $gate.isGerente() || $gate.isAdmin() || $gate.isRrhh()) && item.attribute_id!==1">/</span>
                                          <a href="#" v-if="($gate.isSubcontratacion() || $gate.isGerente() || $gate.isAdmin() || $gate.isRrhh()) && item.attribute_id!==1" @click.prevent="deleteWorker(item.id) "
                                             v-tooltip="'Eliminar tripulante'">
                             
                                              <v-icon small color="red">
                                         fa fa-trash 
                                                </v-icon>
                                          </a>

                                         
                                           <span v-if="($gate.isSubcontratacion() || $gate.isAdmin() || $gate.isRrhh() || $gate.isPrevencionista() || $gate.isSupervisor() || $gate.isJefeflota()) && item.send_email==0">/</span>
                                          <a href="#" v-if="($gate.isSubcontratacion() || $gate.isAdmin() || $gate.isRrhh() || $gate.isPrevencionista() || $gate.isSupervisor() || $gate.isJefeflota()) && item.send_email==0" @click.prevent="sendMail(item.id,index)"
                                             v-tooltip="'Enviar Email'">
                             
                                              <v-icon small color="blue">
                                         fa fa-send 
                                                </v-icon>
                                          </a>

                                                                                             
  </template>
  <template v-slot:footer.page-text="items"> {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }} </template>
</v-data-table>
</v-card> 

                      <!--
                      <div class="card-body table-responsive p-0">
                          <table class="table table-hover">
                              <thead>
                                  <tr>
                                      <th class="align-middle">Nombre</th>
                                      <th class="align-middle">Código</th>
                                      <th class="align-middle">Rut</th>
                                      <th class="align-middle">Edad</th>
                                      <th class="align-middle">Contacto</th>
                                      <th class="align-middle">Roles</th>
                                      <th class="align-middle">Acciones</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr v-for="t in workers.data" :key="t.id">
                                      <td class="align-middle">{{ t.name }} {{ t.last_name }}</td>
                                      <td class="align-middle">{{ t.code }}</td>
                                      <td class="align-middle">{{ t.rut }}</td>
                                      <td class="align-middle">{{ t.birthdate | age }} años ({{ t.birthdate | myDate }})</td>
                                      <td class="align-middle">{{ t.phone_number | dash }}</td>
                                      <td class="align-middle">{{ t.roles.map(r => r.name) | list }}</td>
                                      <td class="align-middle">
                                          <router-link :to="'/admin/trabajadores/' + t.id"
                                                       v-tooltip="'Ficha del tripulante'">
                                              <i class="fa fa-info-circle green"></i>
                                          </router-link>
                                          <span v-if="$gate.isSubcontratacion() || $gate.isRrhh() || $gate.isGerente() || $gate.isAdmin() || $gate.isJefeflota()">/</span>
                                          <router-link v-if="$gate.isSubcontratacion() || $gate.isRrhh() || $gate.isGerente() || $gate.isAdmin() || $gate.isJefeflota()" :to="`/admin/trabajadores/${t.id}/editar`"
                                                       v-tooltip="'Editar tripulante'">
                                              <i class="fa fa-edit blue"></i>
                                          </router-link>
                                          <span v-if="$gate.isSupervisor() || $gate.isGerente() || $gate.isAdmin()">/</span>
                                          <router-link  v-if="$gate.isSupervisor() || $gate.isGerente() || $gate.isAdmin()" :to="`/supervisor/trabajadores/${t.id}/calificar`"
                                                       v-tooltip="'Calificar tripulante'">
                                              <i class="fa fa-award yellow"></i>
                                          </router-link>
                                          <span v-if="$gate.isSupervisor() || $gate.isGerente() || $gate.isAdmin() || $gate.isPrevencionista()">/</span>
                                          <router-link v-if="$gate.isSupervisor() || $gate.isGerente() || $gate.isAdmin() || $gate.isPrevencionista()" :to="`/bodega/trabajadores/${t.id}/entrega-epp`"
                                                       v-tooltip="'Entrega de EPP'">
                                              <i class="fa fa-people-carry orange"></i>
                                          </router-link>
                                           <span v-if="$gate.isSubcontratacion() || $gate.isGerente() || $gate.isAdmin() || $gate.isPrevencionista()">/</span>
                                          <router-link v-if="$gate.isSubcontratacion() || $gate.isGerente() || $gate.isAdmin() || $gate.isPrevencionista()" :to="`/prevencionista/trabajadores/${t.id}/nueva-induccion`"
                                                       v-tooltip="'Nueva inducción'">
                                              <i class="fa fa-graduation-cap blue"></i>
                                          </router-link>
                                          <span v-if="$gate.isGerente() || $gate.isAdmin() || $gate.isPrevencionista()">/</span>
                                          <router-link v-if="$gate.isGerente() || $gate.isAdmin() || $gate.isPrevencionista()" :to="`/prevencionista/trabajadores/${t.id}/salud`"
                                                       v-tooltip="'Salud'">
                                              <i class="fa fa-plus green"></i>
                                          </router-link>

                                          <span v-if="($gate.isSubcontratacion() || $gate.isGerente() || $gate.isAdmin() || $gate.isRrhh()) && t.attribute_id!==1">/</span>
                                          <a href="#" v-if="($gate.isSubcontratacion() || $gate.isGerente() || $gate.isAdmin() || $gate.isRrhh()) && t.attribute_id!==1" @click.prevent="deleteWorker(t.id) "
                                             v-tooltip="'Eliminar tripulante'">
                                              <i class="fa fa-trash red"></i>
                                          </a>
                                      </td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                      <div class="card-footer">
                          <pagination :data="workers" @pagination-change-page="getWorkers"/>
                      </div>

                      -->
                  </div>
              </div>
          </div>

          <!-- modal -->
          <!-- se usa v-if para evitar carga extra de datos -->
          <div class="modal fade" id="form-modal" tabindex="-1" role="dialog" aria-labelledby="form-dialog"
               aria-hidden="true" v-if="showModal" data-backdrop="static" data-keyboard="false">
              <div class="modal-dialog" role="document">
                  <div class="modal-content">
                      <div class="modal-header">
                          <h5 class="modal-title">Crear nuevo tripulante</h5>
                          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                          </button>
                      </div>

                      <form @submit.prevent="createWorker()">
                          <div class="modal-body container">
                            <worker-form :form="form"/>
                          </div>

                          <div class="modal-footer">
                              <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                              <button type="submit" class="btn btn-primary">Crear</button>
                          </div>
                      </form>
                  </div>
              </div>
          </div>
      </div>
  </section>
  </v-app>
</template>

<script>
    import Loading from "./Common/Loading";
    import WorkerForm from "./Workers/Form";

    export default {
        components: { Loading, WorkerForm },
        created () {
            this.$Progress.start();
            this.getWorkers();
            this.$Progress.finish();
        },
        data () {
            return {
                form: new Form({
                    name: '',
                    last_name: '',
                    code: '',
                    rut: '',
                    birthdate: '',
                    phone_number: '',
                    attribute_id: null,
                    region_id: null,
                    commune_id: null,
                    marital_status_id: null
                }),
                loading: true,
                searchInput: null,
                showModal: false,
                workers: {},
                search:'',
                headers: [
                {
                    text: 'Nombre',
                    align: 'start',
                    value: 'nombre',
                },
                { text: 'Código', value: 'codigo' },
                { text: 'Rut', value: 'rut' },
                { text: 'Edad', value: 'edad' },
                { text: 'Contacto', value: 'telefono' },
                { text: 'Roles', value: 'cargo' },
                { text: 'Acciones', value: 'accion', sortable: false },
                ],
                datos:[],
            }
        },
        methods: {
            sendMail(worker_id,index){
                this.$Progress.start();
                this.loading = true;
                axios.post('/api/trabajadores/sendmail',{id:worker_id})
                    //.then(({ data }) => this.workers = data.data)
                    .then(data => {        
                       console.log(data);
                       this.getWorkers();
                       //this.datos[index].send_email = 1;
                       this.$launchSuccess('Se ha enviado el Email correctamente');
                       
                       this.$Progress.finish();
                     })
                    .catch(() => this.$launchError('Error al enviar email'))
                    .finally(() => {
                        this.$Progress.finish();
                        this.loading = false;
                    });
            },
            validaRut: function(rutCompleto) {
                rutCompleto = rutCompleto.replace("‐","-");
                if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test( rutCompleto ))
                    return false;
                var tmp     = rutCompleto.split('-');
                var digv    = tmp[1];
                var rut     = tmp[0];
                if ( digv == 'K' ) digv = 'k' ;

                return (this.dv(rut) == digv );
            },
            dv : function(T){
                var M=0,S=1;
                for(;T;T=Math.floor(T/10))
                    S=(S+T%10*(9-M++%6))%11;
                return S?S-1:'k';
            },
            getWorkers (page = 1) {
                this.$Progress.start();
                this.loading = true;

                axios.get('/api/trabajadores')
                    //.then(({ data }) => this.workers = data.data)
                    .then(data => {
                    this.datos = [];
                    this.workers = data.data;
                     console.log(this.workers);
                        for(let i = 0; i < this.workers.data.length; i++) {
                            var t = this.workers.data[i];
                            var nombre = this.$options.filters.capitalize(t.name)+" "+this.$options.filters.capitalize(t.last_name );  
                               var telefono = this.$options.filters.dash(t.phone_number);  
                                var edad = this.$options.filters.age(t.birthdate)+" años ("+this.$options.filters.myDate(t.birthdate)+")"; 
                                  var cargo = this.$options.filters.list(t.roles.map(r => r.name));   
                            this.datos.push({send_email:t.send_email,attribute_id:t.attribute_id,nombre:nombre,codigo:t.code,rut:t.rut,edad:edad,telefono:telefono,cargo:cargo,id:t.id,accion:true});
                        }
       
                       console.log(this.datos);
                       this.$Progress.finish();
                     })
                    .catch(() => this.$launchError('Error al obtener listado de tripulantes'))
                    .finally(() => {
                        this.$Progress.finish();
                        this.loading = false;
                    });
            },

            creationModal () {
                this.showModal = true;
                this.form.reset();
                this.$nextTick(() => $('#form-modal').modal('show'));
            },

            createWorker () {
                if(this.validaRut(this.form.rut)){
                    this.$Progress.start();

                this.form.post('/api/trabajadores')
                    .then(() => {
                        $('#form-modal').modal('hide');

                        this.$launchSuccess('El tripulante fue creado correctamente');
                        this.$Progress.finish();
                        this.getWorkers();
                    })
                    .catch(() => {
                        this.$Progress.fail();
                        this.$launchError('Error al crear tripulante');
                    });
                }else{

                    this.$launchError('Rut no válido');
                }

            },

            deleteWorker (id) {
                Swal.fire({
                    title: '¿Estás seguro que deseas eliminar el tripulante?',
                    text: 'No podrás revertir esta acción',
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Si',
                    cancelButtonText: 'No'
                }).then((result) => {
                    if (result.value) {
                        this.form.delete('/api/trabajadores/' + id).then(() => {
                            Swal.fire(
                                'Éxito',
                                'El tripulante fue eliminado correctamente',
                                'success'
                            );

                            this.getWorkers();
                        }).catch(() => Swal.fire(
                            'Error',
                            'Error al eliminar tripulante',
                            'warning'
                        ));
                    }
                });
            }
        }
    }
</script>

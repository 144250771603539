var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card",class:{
    'card-success': _vm.purchase.status,
    'card-danger': _vm.purchase.status === 0,
    'card-warning': _vm.purchase.status === null
}},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-title w-100"},[_c('a',{staticClass:"d-block w-100",attrs:{"data-toggle":"collapse","href":("#collapse-" + (_vm.purchase.id)),"aria-expanded":"true"}},[_vm._v("\n                "+_vm._s(_vm.title)+"\n            ")])])]),_vm._v(" "),_c('div',{staticClass:"collapse show",attrs:{"id":("collapse-" + (_vm.purchase.id)),"data-parent":"#accordion"}},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col"},[_c('span',[_c('strong',[_vm._v("Fecha compra:")]),_vm._v(" "+_vm._s(_vm._f("myDate")(_vm.purchase.purchase))+".\n                                    ")])]),_vm._v(" "),_c('div',{staticClass:"col"},[_c('span',[_c('strong',[_vm._v("Proveedor:")]),_vm._v(" "+_vm._s(_vm._f("dash")(_vm.purchase.supplier.name))+".\n                                    ")])]),_vm._v(" "),_c('div',{staticClass:"col"},[_c('span',[_c('strong',[_vm._v("Estado:")]),_vm._v(" "),_c('span',{staticClass:"badge",class:{
                                            'bg-success': _vm.purchase.status,
                                            'bg-danger': _vm.purchase.status === 0,
                                            'bg-warning': _vm.purchase.status === null
                                        }},[_vm._v("\n                                            "+_vm._s(_vm.purchase.status === null ? 'No validada': _vm.purchase.status === 1 ? 'Validada': 'Rechazada')+"\n                                        ")])])]),_vm._v(" "),_c('div',{staticClass:"col"},[_c('span',[_c('strong',[_vm._v("Fecha confirmación:")]),_vm._v(" "+_vm._s(_vm._f("myDate")(_vm.purchase.confirmed_at))+".\n                                    ")])])]),_vm._v(" "),(_vm.purchase.status === 0)?_c('div',{staticClass:"alert alert-danger",attrs:{"role":"alert"}},[_c('strong',[_vm._v("Motivo:")]),_vm._v(" "+_vm._s(_vm.purchase.rejection_reason)+".\n            ")]):_vm._e(),_vm._v(" "),_c('table',{staticClass:"table table-hover mb-4"},[_vm._m(0),_vm._v(" "),_c('tbody',_vm._l((_vm.purchase.warehouse_item_sizes),function(i){return _c('tr',[_c('td',{staticClass:"align-middle"},[_vm._v(_vm._s(i.size.item.name))]),_vm._v(" "),_c('td',{staticClass:"align-middle"},[_vm._v(_vm._s(i.size.name !== '' ? i.size.name : '-'))]),_vm._v(" "),_c('td',{staticClass:"align-middle"},[_vm._v(_vm._s(i.size.item.classification.name))]),_vm._v(" "),_c('td',{staticClass:"align-middle"},[_vm._v(_vm._s(i.quantity))]),_vm._v(" "),_c('td',{staticClass:"align-middle"},[_vm._v(_vm._s(i.size.item.measurement_unit.name))]),_vm._v(" "),_c('td',{staticClass:"align-middle"},[_c('span',[_vm._v("\n                                                    $"+_vm._s(_vm._f("dotNumber")((i.price ? i.price : i.size.item.price)))+"\n                                                    "),(i.price)?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Este nuevo precio reemplazará al anterior'),expression:"'Este nuevo precio reemplazará al anterior'"}],staticClass:"fa fa-exclamation-triangle red"}):_vm._e()])])])}),0)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{staticClass:"align-middle"},[_vm._v("Nombre")]),_vm._v(" "),_c('th',{staticClass:"align-middle"},[_vm._v("Talla")]),_vm._v(" "),_c('th',{staticClass:"align-middle"},[_vm._v("Clasificación")]),_vm._v(" "),_c('th',{staticClass:"align-middle"},[_vm._v("Cantidad")]),_vm._v(" "),_c('th',{staticClass:"align-middle"},[_vm._v("Unidad de medida")]),_vm._v(" "),_c('th',{staticClass:"align-middle"},[_vm._v("Precio")])])])}]

export { render, staticRenderFns }
<template>
  <section class="content">
      <div class="container-fluid">
          <div class="row">


              <div class="col-12">
                  <div class="card">
                      <loading v-if="loading"/>


                 

                    <!-- -->

                      <div class="card-header">
                          <div class="row align-items-center">
                              <h3 class="col card-title">Historial de Facturas</h3>

                              <div class="col-6">
                                 <!-- <form @submit.prevent="getInvoices">
                                      <input type="text" v-model="searchInput" class="form-control"
                                             placeholder="Ingrese busqueda por entrada, salida, proveedor, observacion, fecha recogida">
                                  </form>-->
                              </div>

                              <div class="col-2">
                           
                              </div>

                          </div>
                      </div>


<div style="height:1px !important;">
<v-app >

          <v-card >
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Búsqueda"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
  <v-data-table 
      :headers="headers"
      :items="datos"
      :search="search"
       :loading="loading"
       :items-per-page="30"
       no-results-text="No se han encontrado facturas"
       no-data-text="No existen facturas"
      class="elevation-1"
        loading-text="Cargando... Espere"
         :footer-props="{
           'items-per-page-text':'Facturas por página',
           'items-per-page-options': [20, 50, 100, -1]
      }"
    >
 <template v-slot:item.accion="{ item, index }">
 <router-link :to="`/factura/${item.id}`" v-tooltip="'Revisar'">
 <v-icon small color="gray" >
                                                            fa-file-text-o
                                                                </v-icon>
 </router-link>
                                      
                                        
                                         
  </template>
  <template v-slot:footer.page-text="items"> {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }} </template>
</v-data-table>
</v-card>



  </v-app>
</div>


                      <!--
                      <div class="card-body table-responsive p-0">
                          <table class="table table-hover">
                              <thead>
                                  <tr>
                                      <th class="align-middle">Proveedor</th>
                                      <th class="align-middle">Código</th>
                                      <th class="align-middle">Número de Factura</th>
                                      <th class="align-middle">Monto Facturado</th>
                                      <th class="align-middle">Items Facturados</th>
                                      <th class="align-middle">Revisar</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr v-for="t in transfers.data" >
                                      <td class="align-middle">{{ t.name }}</td>
                                      <td class="align-middle">{{ t.code }}</td>
                                      <td class="align-middle">{{ t.invoice_number }}</td>
                                      <td class="align-middle">${{ t.price | dotNumber }}</td>
                                      <td class="align-middle">{{ t.quantity}}</td>
                                      <td>
                                              
                                      <router-link :to="`/factura/${t.id}`" v-tooltip="'Revisar'"><i class="fa gray fa-file-invoice"/></router-link>
                                                  


                                      </td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                      <div class="card-footer">
                          <pagination :data="transfers" @pagination-change-page="getInvoices"/>
                      </div>
                      -->

                      
                  </div>
                   
              </div>

                             

          </div>
      </div>
  </section>
</template>

<script>
    import Loading from "./../Common/Loading";

    export default {
        components: { Loading},
        computed: {

        },
        created () {
            this.$Progress.start();
            this.getInvoices();
            this.$Progress.finish();
        },
        data () {
            return {
                loading: true,
                searchInput: null,
                transfers: { data:[]},
                 search: '',
                headers: [                 
                { text: 'Proveedor', align: 'start',sortable: false,value: 'proveedor'},
         
                { text: 'Código', value: 'codigo' },
                { text: 'Número de Factura', value: 'numero' },
                { text: 'Monto Facturado', value: 'monto' },
                { text: 'Items Facturados', value: 'items' },
                { text: 'Revisar', value: 'accion', sortable: false },
                ],
                datos:[], 
            }
        },
        methods: {
            getInvoices (page = 1) {
                this.$Progress.start();
                this.loading = true;

                axios.get('/api/administracion/facturacion/facturas')
                    //.then(({ data }) => this.transfers = data.data)
                     .then(data => {
                    this.datos = [];
                    this.transfers = data.data;
                     //console.log(this.transfers);
                     console.log(this.transfers.length);
                        for(let i = 0; i < this.transfers.data.length; i++) {
                            var t = this.transfers.data[i];
                            var valor = this.$options.filters.dotNumber(t.price );     
                            this.datos.push({proveedor:t.name,codigo:t.code,id:t.id,numero:t.invoice_number,monto:"$"+valor,items:t.quantity,accion:true});
                        }
                       console.log(this.datos);
                     })
                    .catch(() => this.$launchError('Error al obtener listado de facturas'))
                    .finally(() => {
                        this.$Progress.finish();
                        this.loading = false;
                    });
            },

        }
    }
</script>
<style>
  .v-application--wrap {
    min-height: 0vh !important;
  }
</style>
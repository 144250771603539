<template>
    <div>
        <table class="table table hover" v-if="!hasRequest || (hasRequest && epp.length > 0)">
            <thead>
                <tr>
                    <th class="align-middle">EPP</th>
                    <th class="align-middle" v-for="w in workers" v-if="w.deleted_at==null">{{ w.name }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="e in epp">
                    <td class="align-middle">{{ e.name }}</td>
                    <td class="align-middle" v-for="w in workers" v-if="w.deleted_at==null">
                        <select class="form-control" v-if="!hasRequest" v-model="workersEPPForm[e.id][w.id]">
                            <option :value="null">No solicita</option>
                            <option :value="s.id" v-for="s in e.sizes">{{ s.name === '' ? 'STD' : s.name }}</option>
                        </select>
                        <span v-else>
                            {{ getSize(e.id, w.id) }}
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>

        <div v-else>
            <span><i>No se añadieron entregas de EPP a la solicitud de abastecimiento.</i></span>
        </div>
    </div>
</template>

<script>
    export default {
        computed: {
            epp () {
                if (this.hasRequest) {
                    let m = this.supplyRequest.epp_deliveries.map(d => d.epp.item.id);

                    return this.$parent.epp.filter(e => m.includes(e.id));
                } else {
                    return this.$parent.epp;
                }
            },
            hasRequest () {
                return this.$parent.$parent.hasRequest;
            },
            supplyRequest () {
                return this.$parent.$parent.supplyRequest;
            },
            workers () {
                return this.$parent.workers;
            },
            workersEPPForm: {
                get () {
                    return this.$parent.workersEPPForm;
                },
                set (v) {
                    this.$parent.workersEPPForm = v;
                }
            }
        },
        methods: {
            getSize (eppId, workerId) {
                const e = this.supplyRequest.epp_deliveries
                    .find(d => d.epp.item.id === eppId && d.worker_id === workerId);

                return e ? (e.epp.name === '' ? 'STD': e.epp.name) : '-';
            }
        }
    }
</script>



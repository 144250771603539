<template>
    <div class="overlay">
        <i class="fa fa-spinner fa-spin"></i>
    </div>
</template>

<script>
    export default {}
</script>

<style scoped>
    i {
        font-size: 4em;
    }
</style>

<template>
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <!-- header -->
            <div class="modal-header">
                <h5 class="modal-title">{{ edit ? 'Editar': 'Añadir' }} Alojamiento</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <!-- body -->
            <form @submit.prevent="setAccommodation">
                <div class="modal-body container">
                    <div class="row">
                        <div class="col form-group">
                                   <label>Fecha Ingreso</label>
                                            <input type="date" class="form-control" v-model="form.entry_date">
                        </div>

                        <div class="col form-group">
                            <label>Fecha Salida</label>
                                            <input type="date" class="form-control" v-model="form.exit_date">
                            
                        </div>
                    </div>

                    <div class="row">
                        <div class="col form-group">
                           <label>Precio Total</label>
                                            <input type="number" class="form-control" min='0' v-model="form.price">
                             
                        </div>

                        <div class="col form-group">
                             <label>Proveedor</label>
                                            <select class="form-control" v-model="form.supplier_id"
                                                    :disabled="suppliers.length === 0">
                                                <option :value="null" disabled>Escoja un proveedor</option>
                                                <option :value="i.id" v-for="i in suppliers">{{ i.name }}</option>
                                            </select>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col form-group">
                             <label>Observacion</label>
                                            <input type="text" class="form-control" v-model="form.observation">
                        </div>

                        <div class="col form-group">
                            <label>Tramo</label>
                            <select name="tramo" class="form-control" v-model="form.journey_type"
                                    :class="{ 'is-invalid': form.errors.has('journey_type') }">
                                <option :value="null" disabled>Escoja un tramo</option>
                                <option :value="1">Ida</option>
                                <option :value="2">Vuelta</option>
                            </select>
                            <has-error :form="form" field="journey_type"/>
                        </div>
                    </div>

                  

                 
                  
                  
                </div>

                <div class="modal-footer">
                    <button type="submit" class="btn btn-primary float-right">Guardar</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    import eventHub from "../../../eventHub";

    export default {
        props: ['accommodationId'],
        computed: {
            edit () {
                return this.accommodationId !== null;
            }
        },
        created () {
            this.$Progress.start();
            this.getSuppliers();
            this.$Progress.finish();

            // si es edición, debe llenar el formulario
            console.log(this.edit);
            if (this.edit) this.fillEditForm();

            // registra eventos
            eventHub.$on('cleanAccommodationForm', () => this.form.reset());
        },
        data () {
            return {
                suppliers:[],
                 form: new Form({
                    entry_date: '',
                    exit_date: '',
                    price:'',
                    supplier_id: null,
                    observation:'',
                    id:null,
                    isedit:0,
                    accommodation_id:null,
                    journey_type:null
                }),
            }
        },
        destroyed() {
            eventHub.$off('cleanAccommodationForm');
        },
        methods: {
             getSuppliers () {
                this.loading = true;
                this.$Progress.start();

                axios.get('/api/bodega/proveedores/alojamiento')
                    .then(({ data }) => this.suppliers = data.data)
                    .catch(() => {
                        this.$launchError('Error al obtener listado de proveedores');
                        this.$Progress.fail();
                    })
                    .finally(() => {
                        this.loading = false;
                        this.$Progress.finish();
                    });
            },
             setAccommodation(){
                       if(this.form.supplier_id==null || this.form.entry_date==null  || this.form.exit_date==null || this.form.price=='') {
                            this.$launchError('Error, debe completar todos los datos y agregar personas');
                        }else{
                            Swal.fire({
                                    title: '¿Estás seguro que deseas guardar este Alojamiento?',
                                    text: 'No podrás revertir esta acción',
                                    showCancelButton: true,
                                    confirmButtonColor: '#d33',
                                    cancelButtonColor: '#3085d6',
                                    confirmButtonText: 'Si',
                                    cancelButtonText: 'No'
                                }).then((result) => {
                                    if (result.value) {
                                        this.form.accommodation_id = this.accommodationId;
                                        this.form.quotation_id = this.$parent.id;
                                        console.log(this.form);
                                        this.form.post('/api/administracion/personas/nuevo/alojamientoCrew')
                                        .then(() => {
                                            eventHub.$emit('reloadAccommodations');
                                            this.$launchSuccess('Se guardo el alojamiento correctamente');
                                            this.$Progress.finish();
                                        })
                                        .catch(() => {
                                            this.$Progress.fail();
                                            this.$launchError('Error al guardar alojamiento');
                                        });
                                        }
                                });   
                        }               
            },
            fillEditForm () {
                
                let t = this.$parent.quotation.accommodations.find(t => t.id === this.accommodationId);
                console.log(t);

                    this.form.exit_date = t.exit_date;
                    this.form.entry_date = t.entry_date;
                    this.form.price = t.price;
                    this.form.observation = t.observation;
                    this.form.supplier_id = t.supplier_id;
                    this.form.isedit = 1;
                    this.form.id = this.accommodationId;
                    this.form.journey_type =t.journey_type;
               // this.form.fill(t);
            }
        }
    }
</script>

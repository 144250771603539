<template>
 <v-app>
  <section class="content">
      <div class="container-fluid">
          <div class="row">
              <div class="col-12">
                  <div class="card">
                      <loading v-if="loading"/>

                      <div class="card-header">
                          <div class="row align-items-center">
                              <h3 class="col card-title">Lista de Alojamientos</h3>

                              <div class="col-6">
                                 <!-- <form @submit.prevent="getAccommodations">
                                      <input type="text" v-model="searchInput" class="form-control"
                                             placeholder="Ingrese busqueda por fecha subida, fecha bajada, proveedor, observacion">
                                  </form>-->
                              </div>

                              <div class="col-2">
                                

                                 <router-link to="nuevo-alojamiento" class="btn btn-sm btn-primary" style="color:#ffffff !important;">
                                    <i class="fa fa-plus-square"></i>
                                    Nuevo Alojamiento
                                </router-link>
                              </div>
                          </div>
                      </div>



 <v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Búsqueda"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
  <v-data-table
      :headers="headers"
      :items="datos"
      :search="search"
       :loading="loading"
       :items-per-page="30"
       no-results-text="No se han encontrado alojamientos"
       no-data-text="No existen alojamientos"
      class="elevation-1"
        loading-text="Cargando... Espere"
         :footer-props="{
           'items-per-page-text':'Alojamientos por página',
           'items-per-page-options': [20, 50, 100, -1]
      }"
    >



 <template v-slot:item.accion="{ item, index }">

 <!-- <a :href="`https://www.google.com/maps/search/?api=1&query=${start_latitude},${start_longitude}`">Ir al punto de inicio</a>-->

                                             <router-link :to="`/editar-alojamiento/${item.id}`" 
                                                       v-tooltip="'Editar Alojamiento'">
                                                <v-icon small style="color: #3490DC !important;">
                                                fa-edit
                                                </v-icon>
                                          </router-link>
                                                                        
  </template>
  <template v-slot:footer.page-text="items"> {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }} </template>
</v-data-table>
</v-card> 
                      <!--
                      <div class="card-body table-responsive p-0">
                          <table class="table table-hover">
                              <thead>
                                  <tr>
                                      <th class="align-middle">Reservas</th>
                                      <th class="align-middle">Fecha Subida</th>
                                      <th class="align-middle">Fecha Bajada</th>
                                      <th class="align-middle">Precio</th>
                                      <th class="align-middle">Proveedor</th>
                                      <th class="align-middle">Observacion</th>
                                      <th class="align-middle">Opciones</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr v-for="t in accommodations.data" :key="t.id">
                                      <td class="align-middle">{{ t.quantity }}</td>
                                      <td class="align-middle">{{ t.entry_date | myDate }}</td>
                                      <td class="align-middle">{{ t.exit_date }}</td>
                                      <td class="align-middle">{{ t.price | dash}}</td>
                                      <td class="align-middle">{{ t.supplier }}</td>
                                      <td class="align-middle">{{ t.observation }}</td>   
                                      <td>
                                        
                                       <router-link :to="`/editar-alojamiento/${t.id}`" 
                                                       v-tooltip="'Editar Alojamiento'">
                                              <i class="fa fa-edit blue"></i>
                                          </router-link>
                                      </td>             
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                      <div class="card-footer">
                          <pagination :data="accommodations" @pagination-change-page="getAccommodations"/>
                      </div>

                      -->
                  </div>
              </div>
          </div>

       
      </div>
  </section>
   </v-app>
</template>

<script>
    import Loading from "./Common/Loading";

    export default {
        components: { Loading},
        created () {
            this.$Progress.start();
            this.getAccommodations();
            this.$Progress.finish();
        },
        data () {
            return {
                loading: true,
                //start_latitude:'37.8114635',
                //start_longitude:'-122.2576912',
                searchInput: null,
                accommodations: {},
                search: '',
                headers: [
                {
                    text: 'Reservas',
                    align: 'start',
                    value: 'reservas',
                },
                { text: 'Fecha Subida', value: 'subida' },
                { text: 'Fecha Bajada', value: 'bajada' },
                { text: 'Precio Total', value: 'precio' },
                { text: 'Proveedor', value: 'proveedor' },
                { text: 'Observacion', value: 'observacion' },
                { text: 'Opciones', value: 'accion', sortable: false },
                ],
                datos:[],
            }
        },
        methods: {
            
            getAccommodations (page = 1) {
                this.$Progress.start();
                this.loading = true;

                axios.get('/api/administracion/alojamientos')
                   // .then(({ data }) => this.accommodations = data)
                    .then(data => {
                    this.accommodations = data.data;
                     console.log(this.accommodations);
                        for(let i = 0; i < this.accommodations.data.length; i++) {
                            var t = this.accommodations.data[i];
                            var precio = this.$options.filters.dash(t.price );  
                              var subida = this.$options.filters.myDate(t.entry_date);  
                              var bajada = this.$options.filters.myDate(t.exit_date);  
                            this.datos.push({reservas: t.quantity,subida:subida,bajada:bajada,precio:"$"+precio,proveedor:t.supplier,observacion:t.observation,id:t.id,accion:true});
                        }
                       console.log(this.datos);
                     })
                    .catch(() => this.$launchError('Error al obtener listado de alojamientos'))
                    .finally(() => {
                        this.$Progress.finish();
                        this.loading = false;
                    });
            },


            deleteWorker (id) {
                Swal.fire({
                    title: '¿Estás seguro que deseas eliminar esta persona?',
                    text: 'No podrás revertir esta acción',
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Si',
                    cancelButtonText: 'No'
                }).then((result) => {
                    if (result.value) {
                        this.form.delete('/api/administracion/personas/' + id).then(() => {
                            Swal.fire(
                                'Éxito',
                                'La persona fue eliminada correctamente',
                                'success'
                            );

                            this.getPersons();
                        }).catch(() => Swal.fire(
                            'Error',
                            'Error al eliminar persona',
                            'warning'
                        ));
                    }
                });
            }
        }
    }
</script>

<template>
    <span class="badge" :class="badgeClass">{{ status }}</span>
</template>

<script>
    export default {
        computed: {
            badgeClass () {
                switch (this.status) {
                    case 'Ok':
                        return 'bg-success';
                    case 'Observaciones':
                        return 'bg-warning';
                    case 'Error':
                        return 'bg-danger';
                }
            }
        },
        props: {
            status: {
                type: String,
                validator (v) {
                    return ['Ok', 'Observaciones', 'Error'].indexOf(v) !== -1;
                }
            }
        }
    }
</script>

<template>
    <div>
        <!-- breadcrumb -->
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Entrega de EPP</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link to="/">Inicio</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link to="/admin/trabajadores">Tripulantes</router-link>
                            </li>
                            <li class="breadcrumb-item active">Entrega de EPP</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>

        <!-- edición -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col">
                        <div class="card">
                            <loading v-if="loading"/>

                            <div class="card-body">
                                <!-- información -->
                                <div class="row mb-4">
                                    <div class="col">
                                        <span><strong>Nombres:</strong> {{ worker.name }}.</span>
                                    </div>
                                    <div class="col">
                                        <span><strong>Apellidos:</strong> {{ worker.last_name }}.</span>
                                    </div>
                                    <div class="col">
                                        <span><strong>Rut:</strong> {{ worker.rut }}.</span>
                                    </div>
                                    <div class="col">
                                        <span><strong>Última tripulacion:</strong> -.</span>
                                    </div>
                                </div>

                                <!-- selector -->
                                <form class="mb-5" @submit.prevent="saveEPPDelivery">
                                    <div class="row">
                                        <div class="col form-group">
                                            <label>Fecha de entrega</label>
                                            <input type="date" class="form-control" v-model="form.delivery"/>
                                        </div>

                                        <div class="col form-group">
                                            <label>EPP</label>
                                            <select class="form-control" v-model="form.epp_id">
                                                <option :value="null" disabled>Escoja un EPP</option>
                                                <option :value="e.id" v-for="e in validEPP">{{ e.name }}</option>
                                            </select>
                                        </div>

                                        <div class="col form-group">
                                            <label>Talla</label>
                                            <!-- deberían estar las tallas en bdd -->
                                            <select class="form-control" v-model="form.size_id"
                                                    :disabled="form.epp_id === null">
                                                <option :value="null" disabled>Escoja una talla</option>
                                                <option :value="s.id" v-for="s in sizes" :disabled="s.quantity==0">
                                                    {{ s.name === '' ? 'STD': s.name }}
                                                </option>
                                            </select>
                                        </div>

                                        <div class="col form-group">
                                            <label>Observación</label>
                                            <!-- deberían estar las tallas en bdd -->
                                            <input type="text" class="form-control" v-model="form.observations">
                                        </div>

                                        <div class="col">
                                            <label>
                                                Última entrega
                                                <i class="fa fa-spinner fa-spin ml-2" v-if="lastLoading"/>
                                            </label><br>
                                            <span v-if="form.epp_id === null || (form.epp_id !== null && lastDelivery === null)">
                                                -
                                            </span>
                                            <span v-else-if="lastDelivery">{{ lastDelivery | myDate }}</span>
                                            <span v-else>
                                                <i>No se ha hecho entrega de este EPP aún.</i>
                                            </span>
                                        </div>
                                    </div>

                                    <button type="submit" class="btn btn-primary float-right">Agregar</button>
                                </form>

                                <!-- entregas -->
                                <h4>Entregas</h4>

                                <span v-if="deliveries.length === 0">
                                    <i>No se han registrado entregas aún.</i>
                                </span>
                                <div class="row" v-else>
                                    <ul class="mt-2 mb-0">
                                        <li v-for="(d, i) in deliveries">
                                            <strong>EPP: </strong> {{ d.name }} - <strong>Talla:</strong> {{ d.size }} -
                                            <strong>Observación: </strong> {{ d.observations }}
                                            <i class="fa fa-times red hand-pointer" @click="deleteDelivery(i)"
                                               v-tooltip="'Eliminar'"/>
                                        </li>
                                    </ul>
                                </div>

                                <button class="btn btn-success float-right" :disabled="!canConfirm || submit"
                                        @click="confirmDelivery">
                                    <i class="fa fa-spinner fa-spin mr-2" v-if="submit"></i>
                                    {{ submit ? 'Guardando' : 'Guardar' }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Loading from "../Common/Loading";
    import moment from 'moment';

    export default {
        components: { Loading },
        computed: {
            id () {
                return this.$route.params.id;
            },
            canConfirm () {
                return this.deliveries.length > 0;
            },
            validEPP () {
                const ids = this.deliveries.map(d => d.id);

                // listado de epp omitiendo los ya entregados
                return this.epp.filter(e => !ids.includes(e.id));
            }
        },
        created () {
            this.getEPP();
            this.getWorker();
        },
        data () {
            return {
                deliveries: [],
                epp: [],
                form: {
                    epp_id: null,
                    delivery: moment().format('yyyy-MM-DD'),
                    size_id: null,
                    observations: null
                },
                lastDelivery: null,
                lastLoading: false,
                loading: true,
                sizes: [],
                submit: false,
                worker: {
                    id: null,
                    name: null,
                    last_name: null,
                    rut: null
                }
            }
        },
        methods: {
            confirmDelivery () {
                if (this.canConfirm) {
                    Swal.fire({
                        title: '¿Estás seguro que deseas continuar con la entrega de estos EPP?',
                        text: 'No podrás revertir esta acción',
                        showCancelButton: true,
                        confirmButtonColor: '#d33',
                        cancelButtonColor: '#3085d6',
                        confirmButtonText: 'Si',
                        cancelButtonText: 'No'
                    }).then((result) => {
                        if (result.value) {
                            this.submit = true;
                            this.$Progress.start();

                            axios.post(`/api/bodega/trabajadores/${this.id}/entrega-epp`, {
                                delivery: this.form.delivery,
                                deliveries: this.deliveries.map(d => {
                                    return {
                                        id: d.id,
                                        observations: d.observations
                                    }
                                })
                            })
                                .then(() => {
                                    this.resetForm();
                                    this.deliveries = [];
                                    this.$launchSuccess('Éxito al registrar entrega de EPP');
                                    this.$Progress.finish();
                                })
                                .catch(() => {
                                    this.$launchError('Error al registrar entrega de EPP');
                                    this.$Progress.fail();
                                })
                                .finally(() => this.submit = false);
                        }
                    });
                }
            },

            deleteDelivery (index) {
                this.deliveries.splice(index, 1);
            },

            getEPP () {
                axios.get('/api/bodega/epp')
                    .then(({ data }) => this.epp = data.data)
                    .catch(() => this.$launchError('Error al obtener listado de EPP'));
            },

            getEPPSizes () {
                axios.get(`/api/bodega/articulos/${this.form.epp_id}/tallas`)
                    .then(({ data }) => {
                        this.sizes = data.data;

                        // autoelige la talla "STD" si solo viene una
                        if (this.sizes.length === 1) this.form.size_id = this.sizes[0].id;
                        else this.form.size_id = null;
                    })
                    .catch(() => this.$launchError('Error al obtener listado de tallas de EPP'));
            },

            getLastDelivery () {
                this.lastLoading = true;
                this.$Progress.start();

                axios.get(`/api/bodega/trabajadores/${this.id}/entrega-epp/${this.form.epp_id}`)
                    .then(({ data }) => {
                        this.lastDelivery = data.data ? data.data.delivery: null;
                        this.$Progress.finish();
                    })
                    .catch(() => {
                        this.$launchError('Error al obtener última entrega de EPP');
                        this.$Progress.fail();
                    })
                    .finally(() => this.lastLoading = false);
            },

            getWorker () {
                this.loading = true;
                this.$Progress.start();

                axios.get(`/api/bodega/trabajadores/${this.id}/entrega-epp`)
                    .then(({ data }) => {
                        this.worker = data.data;
                        this.$Progress.finish();
                    })
                    .catch(() => {
                        this.$launchError('Error al obtener tripulante');
                        this.$Progress.fail();
                    })
                    .finally(() => this.loading = false);
            },

            resetForm () {
                this.form.epp_id = null;
                this.form.size_id = null;
                this.form.observations = null;
            },

            saveEPPDelivery () {
                
                // solo puede guardar si se ha marcado un epp válido
                if (!this.form.epp_id || !this.form.size_id ) {
                    // TODO: form errors
                    this.$launchError('Debe completar todos los campos antes de continuar con la entrega');
                } else {

                    if(!this.form.observations || this.form.observations === ''){
                        this.form.observations = "Sin Observación";
                    }

                    let s = this.sizes.find(s => s.id === this.form.size_id).name;
                    this.deliveries.push({
                        id: this.form.size_id,
                        name: this.epp.find(e => e.id === this.form.epp_id).name,
                        size: s === '' ? 'STD' : s,
                        observations: this.form.observations
                    });

                    this.resetForm();
                }
            }
        },
        watch: {
            'form.epp_id' () {
                if (this.form.epp_id !== null) {
                    this.getEPPSizes();
                    this.getLastDelivery();
                }
                else this.lastDelivery = null;
            }
        }
    }
</script>

<template>
<div>
      <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Detalles de Voucher</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link to="/">Inicio</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link to="/">Administracion</router-link>
                            </li>
                             <li class="breadcrumb-item">
                                <router-link to="/vouchers">Vouchers</router-link>
                            </li>
                            <li class="breadcrumb-item active">Detalles del Voucher</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>

  



             <!-- detalles -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col">
                        <div class="card">
                            <loading v-if="loading"/>

                            <div class="card-body">
                                <!-- información -->
                                <div class="row mb-4">
                                    <div class="col">
                                        <span>
                                            <strong>Monto Total:</strong> $ {{total | dotNumber}}
                                        </span>
                                    </div>
                                    <div class="col">
                                        <span>
                                            <strong>Monto Utilizado:</strong> $ {{ usado(total,total_used) | dotNumber}}
                                        </span>
                                    </div>
                                    <div class="col">
                                        <span>
                                            <strong>Disponible:</strong> $ {{ calculo(total,total_used) | dotNumber}}
                                            
                                        </span>
                                    </div>
                                    <div class="col">
                                        <span>
                                            <strong>Estado:</strong> {{ status(status)}}.
                                        </span>
                                    </div>
                                </div>

                     <div class="card-header">
                          <div class="row align-items-center">
                                <h3 class="col card-title">Pasajes que componen el Voucher</h3>
                          </div>
                     </div>
                    <table class="table table-hover">
                              <thead>
                                  <tr>
                                      <th class="align-middle">Aerolinea</th>
                                      <th class="align-middle">Código</th>
                                      <th class="align-middle">Monto</th>
                                      <th class="align-middle">OC</th>
                                      <th class="align-middle">Tramo</th>
                                      <th class="align-middle">F. Vuelo</th>        
                                      <th class="align-middle">A. Origen</th>
                                      <th class="align-middle">A. Destino</th>
                                      <th class="align-middle">Rut</th> 
                                      <th class="align-middle">Rol</th>
                                      <th class="align-middle">Tipo Voucher</th> 
                                      <th class="align-middle">Generado por</th> 
                                      <th class="align-middle">Fecha de Voucher</th> 
                                   
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr v-for="t in vouchers" >
                                      <td class="align-middle">{{ t.airline }}</td>
                                      <td class="align-middle">{{t.code}}</td>
                                      <td class="align-middle">$ {{ t.price | dotNumber}}</td>
                                      <td class="align-middle">{{ t.oc }}</td>
                                      <td class="align-middle">{{ t.stretch }}</td>
                                      <td class="align-middle">{{ t.flight_date }}</td>
                                      <td class="align-middle">{{ t.airport1 }}</td>
                                      <td class="align-middle">{{ t.airport2 }}</td>
                                      <td class="align-middle">{{t.rut}}</td>  
                                      <td class="align-middle">{{ t.role}}</td>  
                                      <td class="align-middle">{{ origintype(t.is_carry) }}</td>  
                                       <td class="align-middle">{{ t.user_name_to}} {{t.last_name_to}}</td>  
                                        <td class="align-middle">{{ t.date_to | myDate}}</td>   
                                                      
                                  </tr>
                              </tbody>
                          </table>


                     <div class="card-header" style="margin-top:30px;">
                          <div class="row align-items-center">
                                <h3 class="col card-title">Pasajes comprados con el Voucher</h3>
                          </div>
                     </div>
                    <table class="table table-hover">
                              <thead>
                                  <tr>
                                      <th class="align-middle">Aerolinea</th>
                                      <th class="align-middle">Código</th>
                                      <th class="align-middle">Tramo</th>
                                      <th class="align-middle">F. Vuelo</th>
                                      <th class="align-middle">A. Origen</th>
                                      <th class="align-middle">A. Destino</th>
                                      <th class="align-middle">Tripulacion</th>
                                      <th class="align-middle">Rut</th> 
                                      <th class="align-middle">Rol</th> 
                                       <th class="align-middle">Precio</th>
                                        <th class="align-middle">Multa</th>
                                          <th class="align-middle">Fecha Compra</th>
                                    
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr v-for="t in tickets" >
                                      <td class="align-middle">{{ t.airline }}</td>
                                      <td class="align-middle">{{ t.code }}</td>
                                      <td class="align-middle">{{ t.stretch }}</td>
                                      <td class="align-middle">{{ t.flight_date }}</td>
                                      <td class="align-middle">{{ t.airport1 }}</td>
                                      <td class="align-middle">{{ t.airport2 }}</td>
                                       <td class="align-middle">{{ t.crew }}</td>
                                       <td class="align-middle">{{t.rut}}</td> 
                                       <td class="align-middle">{{t.role}}</td> 
                                        <td class="align-middle">{{t.price | dotNumber}}</td> 
                                         <td class="align-middle">{{ t.penalty | dotNumber}}</td> 
                                         <!--  <td class="align-middle">$ {{ t.voucher_required | dotNumber}}</td>
                                           <td class="align-middle">$ {{ t.voucher_price | dotNumber}}</td>
                                             <td class="align-middle">$ {{ t.balance | dotNumber}}</td>-->
                                              <td class="align-middle">{{ t.created_at | myDate}}</td>
                         
                                  
                                  </tr>
                              </tbody>
                          </table>

                            
                            </div>
                        </div>
                    </div>
                </div>

           
            </div>
        </section>
       


  </div>
</template>

<script>
    import Loading from "./Common/Loading";

    export default {
        components: { Loading},
         computed: {
             id () {
                return this.$route.params.id;
            }
        },
        created () {
            this.$Progress.start();
            this.getTickets();
            this.getTicketsVoucher();
            this.$Progress.finish();
        },
        data () {
            return {
                loading: true,
                searchInput: null,
                tickets: [],
                vouchers:[],
                total:0,
                total_used:0
            }
        },
        methods: {
            calculo(total,total_usado){
                if(total_usado>total){
                    return 0;
                }
                return total - total_usado;
            },
            usado(total,total_usado){
                if(total_usado>total){
                    return total;
                }
                return total_usado;
            },
              origintype(plus){
                if(plus==1){
                    return "Maleta";
                }
                return "Pasaje";
            },
              plustype(plus){
                if(plus==1){
                    return "Si";
                }
                return "No";
            },
 
            status(plus){
                if(plus==1){
                    return "Activo";
                }
                return "Inactivo";
            },

            getData () {
                axios.get('/api/administracion/vouchers/detalle/'+this.id)
                    .then(({ data }) => this.tickets = data.data)
                    .catch(() => this.$launchError('Error al obtener listado de vouchers'))
                    .finally(() => {
                        this.$Progress.finish();
                        this.loading = false;
                        this.tickets.forEach(d => {
                                    console.log(d.price);
                                    this.total_used = parseInt(this.total_used) + parseInt(d.price);
                        });

                        /*for(let i = 0;i<=this.tickets.length;i++){
                            this.total_used = this.total_used + this.tickets[i].price;
                            console.log(this.tickets[i].price);
                        }*/
                    });
            },  
 
            getTickets () {
                axios.get('/api/administracion/vouchers/detalle/'+this.id)
                    .then(({ data }) => this.tickets = data.data)
                    .catch(() => this.$launchError('Error al obtener listado de vouchers'))
                    .finally(() => {
                        this.$Progress.finish();
                        this.loading = false;
                        this.tickets.forEach(d => {
                                    console.log(d.price);
                                    this.total_used = parseInt(this.total_used) + parseInt(d.price) + parseInt(d.penalty);
                        });

                        /*for(let i = 0;i<=this.tickets.length;i++){
                            this.total_used = this.total_used + this.tickets[i].price;
                            console.log(this.tickets[i].price);
                        }*/
                    });
            },   
            getTicketsVoucher () {
                axios.get('/api/administracion/vouchers/vouchersof/'+this.id)
                    .then(({ data }) => this.vouchers = data.data)
                    .catch(() => this.$launchError('Error al obtener listado de vouchers'))
                    .finally(() => {
                        this.$Progress.finish();
                        this.loading = false;
                        this.vouchers.forEach(d => {
                                    console.log(d.price);
                                    this.total = parseInt(this.total) + parseInt(d.price);
                        });
                        /*for(let i = 0;i<=this.vouchers.length;i++){
                            this.total = this.total + this.vouchers[i].price;
                            console.log(this.vouchers[i].price);
                        }*/
                    });
            },  
        }
    }
</script>

<template>
    <div>
        <div id="accordion" v-if="hasEmergencies">
            <emergency-details v-for="e in emergencies" :emergency="e" :key="e.id"/>
        </div>
        <span v-else><i>No se ha utilizado el botón de emergencia en esta tripulación.</i></span>
    </div>
</template>

<script>
import EmergencyDetails from "./Emergencies/EmergencyDetails";

export default {
    components: { EmergencyDetails },
    computed: {
        hasEmergencies() {
            return this.emergencies.length > 0;
        }
    },
    created() {
        this.getEmergencies();
    },
    data() {
        return {
            emergencies: []
        }
    },
    methods: {
        getEmergencies() {
            this.$Progress.start();
            this.$parent.loading = true;

            axios.get('/api/ficha-tripulacion/' + this.$parent.id + '/botones-emergencia')
                .then(response => {
                    this.emergencies = response.data.data.emergency_buttons;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$launchError('Error al obtener botón de emergencia');
                    this.$Progress.fail();
                })
                .then(() => this.$parent.loading = false);
        }
    }
}
</script>


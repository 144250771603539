
                        
            

            <template>
  <section class="content">
      <div class="container-fluid">
          <div class="row">


              <div class="col-12">
                  <div class="card">
                      <loading v-if="loading"/>


                    <div class="card-header">
                          <div class="row align-items-center">
                              <h3 class="col card-title">Facturas</h3>

                              <div class="col-6">
                                
                              </div>

                              <div class="col-2">
                                 <button class="btn btn-primary float-right" 
                                                
                                                @click="setInvoice(null)">
                                            Añadir Factura
                                        </button>
                              </div>
                              
                          </div>
                      </div>
                      <div class="card-body table-responsive p-0">
                          <table class="table table-hover" v-if="quotations.length>0">
                              <thead>
                                  <tr>
                                      <th>#</th>
                                                     <th>Cuenta Bancaria</th>
                                                    <th>Nº Factura</th>
                                                    <th>Orden de Compra</th>
                                                    <th>Fecha Orden de Compra</th>
                                                    <th>Proveedor</th>
                                                    <th>Tipo Proveedor</th>
                                                    <th>Acciones</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr v-for="(t,i) in quotations" >

                                                    <td class="align-middle">FT{{ t.index }}</td>
                                                    <td class="align-middle">{{ t.account_number }} - {{ t.account_name }}</td>
                                                    <td class="align-middle">{{ t.invoice_number }}</td>
                                                    <td class="align-middle">{{ t.purchase_order }}</td>
                                                     <td class="align-middle">{{ t.purchase_order_date }}</td>
                                                    <td class="align-middle">{{ t.supplier }}</td>
                                                    <td class="align-middle">{{ t.supplier_type }}</td>
                         
                                     <td>
                                     <span style="display:none;">{{t.transfers}}</span>
                                                        <a href="#" @click.prevent="setInvoice(t.index)"
                                                           v-tooltip="'Editar Factura'">
                                                            <i class="fa green fa-edit"/>
                                                        </a>
                                                    
                                                        <span >/</span>
                                                        <a href="#" @click.prevent="deleteInvoice(t.index)"
                                                           v-tooltip="'Eliminar factura'">
                                                            <i class="fa red fa-times"/>
                                                        </a>
                                       </td>
                                  </tr>
                              </tbody>
                          </table>
                           <p class="text-center mb-4" style="margin-top:20px;" v-else>
                                            <i>No se han agregado facturas aún.</i>
                            </p>
                      </div>
                      <div class="card-footer">
                          <pagination :data="transfers" @pagination-change-page="getTransfers"/>
                      </div>

                    <!-- -->

                      <div class="card-header">
                          <div class="row align-items-center">
                              <h3 class="col card-title">Alojamientos Facturables</h3>

                              <div class="col-6">
                                  
                              </div>

                              <div class="col-2">
                           <button type="button" v-if="can" class="btn btn-success float-right" @click="saveQuotation">
                                    Guardar
                                </button>
                              </div>

                          </div>
                      </div>

                      <div style="height:1px !important;">
<v-app >

          <v-card >
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Búsqueda"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
  <v-data-table 
      :headers="headers"
      :items="datos"
      :search="search"
       :loading="loading"
       :items-per-page="30"
       no-results-text="No se han encontrado alojamientos"
       no-data-text="No existen alojamientos"
      class="elevation-1"
        loading-text="Cargando... Espere"
         :footer-props="{
           'items-per-page-text':'Alojamientos por página',
           'items-per-page-options': [20, 50, 100, -1]
      }"
    >
 <template v-slot:item.accion="{ item, index }">



                                                         <a href="#" v-if="item.quantity>0" @click.prevent="associateInvoice(item.id,item.supplier_id,item.invoice_id)"
                                                           v-tooltip="'Asociar Factura a Alojamiento'">
                                                               <v-icon small color="green" v-if="item.invoice_id!=null">
                                                            fa-file-text-o
                                                                </v-icon>
                                                                 <v-icon small color="gray" v-else>
                                                            fa-file-text-o
                                                                </v-icon>
                                                        </a>

                                                        <span v-if="item.invoice_id!=null && item.quantity>0" >/</span>
                                                        <a href="#" v-if="item.invoice_id!=null && item.quantity>0" @click.prevent="dessasociateInvoice(item.invoice_id,item.id)"
                                                           v-tooltip="'Quitar Factura a Alojamiento'">
                                                             <v-icon small color="red">
                                                                fa-times
                                                                </v-icon>
                                                        </a>
                                         
  </template>
  <template v-slot:footer.page-text="items"> {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }} </template>
</v-data-table>
</v-card>



  </v-app>
</div>

                      <!--
                      <div class="card-body table-responsive p-0">
                          <table class="table table-hover">
                              <thead>
                                  <tr>
                                      <th class="align-middle">Reservas</th>
                                      <th class="align-middle">Fecha Subida</th>
                                      <th class="align-middle">Fecha Bajada</th>
                                      <th class="align-middle">Precio</th>
                                      <th class="align-middle">Proveedor</th>
                                      <th class="align-middle">Observacion</th>
                                      <th class="align-middle">Opciones</th>
                                  </tr>
                              </thead>
                              <tbody>
                                 
                                  <tr v-for="t in transfers.data"  >
                                      <td class="align-middle">{{ t.quantity }}</td>
                                      <td class="align-middle">{{ t.entry_date | myDate }}</td>
                                      <td class="align-middle">{{ t.exit_date }}</td>
                                      <td class="align-middle">{{ t.price | dash}}</td>
                                      <td class="align-middle">{{ t.supplier }}</td>
                                      <td class="align-middle">{{ t.observation }}</td> 
                                      <td>
                                                         <a href="#" v-if="t.quantity>0" @click.prevent="associateInvoice(t.id,t.supplier_id,t.invoice_id)"
                                                           v-tooltip="'Asociar Factura a Alojamiento'">
                                                            <i class="fa green fa-file-invoice" v-if="t.invoice_id!=null"/>
                                                            <i class="fa gray fa-file-invoice" v-else/>
                                                        </a>

                                                        <span v-if="t.invoice_id!=null && t.quantity>0" >/</span>
                                                        <a href="#" v-if="t.invoice_id!=null && t.quantity>0" @click.prevent="dessasociateInvoice(t.invoice_id,t.id)"
                                                           v-tooltip="'Quitar Factura a Alojamiento'">
                                                             <i class="fa red fa-times"/>
                                                        </a>
                                      </td>
                                  </tr>
                              </tbody>
                      </table>
                      </div>
                      <div class="card-footer">
                          <button type="button" v-if="can" class="btn btn-success float-right" @click="saveQuotation">
                                    Guardar
                                </button>

                          <pagination :data="transfers" @pagination-change-page="getTransfers"/>
                      </div>

                      -->

                      
                  </div>
                   
              </div>

                             



                        <div class="modal fade" id="invoice-modal" tabindex="-1" role="dialog"
                             aria-labelledby="form-dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                            <invoice-modal-extra :invoice-id="selectedInvoice" :supplier-type="supplierType" v-if="showInvoiceModal"/>
                        </div>


                        <div class="modal fade" id="associate-invoice-modal" tabindex="-1" role="dialog"
                             aria-labelledby="form-dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                            <associate-invoice-extra :supplier-id="selectedSupplierId" :associate-id="selectedAssociate" :associate-selected-id="selectedAssociateId"  v-if="showAssociateInvoiceModal"/>
                        </div>

          </div>
      </div>
  </section>
</template>

<script>
    import Loading from "./../Common/Loading";
    import InvoiceModalExtra from "./InvoiceModalExtra";
     import AssociateInvoiceExtra from "./AssociateInvoiceExtra";
     import eventHub from "../../eventHub";

    export default {
        components: { Loading,InvoiceModalExtra,AssociateInvoiceExtra},
        computed: {
            can () {
                    let many = 0
                    this.datos.forEach(w => { 
                                            if(w.quantity > 0){
                                                many = many+1;
                                            }
                    }); 
                    console.log(many);
                    if(many==0){
                        return false;
                    }
                    return true;
                    
            }
        },
        created () {
            this.$Progress.start();
            this.getTransfers();
            this.$Progress.finish();

             eventHub.$on('reloadAssociation', () => {
                $('#associate-invoice-modal').modal('hide');
            });

              this.$nextTick(() => $('#associate-invoice-modal').on('hidden.bs.modal', () => {
                     this.selectedAssociate= null;
                    this.selectedAssociateId= null;
                    this.selectedSupplierId= null;
                    this.showAssociateInvoiceModal = false;
                
            }));

            eventHub.$on('reloadinvoiceExtra', () => {
                $('#invoice-modal').modal('hide');
            });

             this.$nextTick(() => $('#invoice-modal').on('hidden.bs.modal', () => {
                this.selectedInvoice = null;
                this.showInvoiceModal = false;
                
            }));
        },
        data () {
            return {
                loading: true,
                searchInput: null,
                transfers: { data:[]},
                selectedInvoice:null,
                showInvoiceModal: false,
                supplierType:null,

                selectedSupplierId:null,
                selectedAssociate: null,
                selectedAssociateId:null,
                showAssociateInvoiceModal:false,

                test:"lo que sea",
                quotations:[],
                  search: '',
                headers: [
                {
                    text: 'Reservas',
                    align: 'start',
                    value: 'quantity',
                },
                { text: 'Fecha Subida', value: 'subida' },
                { text: 'Fecha Bajada', value: 'bajada' },
                { text: 'Precio Total', value: 'precio' },
                { text: 'Proveedor', value: 'proveedor' },
                { text: 'Observacion', value: 'observacion' },
                { text: 'Opciones', value: 'accion', sortable: false }
                ],
                datos:[], 

             
            }
        },
        methods: {
              quotation(plus){
                if(plus==1){
                    return "Si";
                }
                return "No";
            },
            
            getTransfers (page = 1) {
                this.$Progress.start();
                this.loading = true;

                axios.get('/api/administracion/alojamientos-facturables')
                   // .then(({ data }) => this.transfers = data)
                     .then(data => {
                         this.datos = [];
                    this.transfers = data.data;
                     console.log(this.transfers);
                        for(let i = 0; i < this.transfers.data.length; i++) {
                            var t = this.transfers.data[i];
                            var precio = this.$options.filters.dash(t.price );  
                              var subida = this.$options.filters.myDate(t.entry_date);  
                              var bajada = this.$options.filters.myDate(t.exit_date);  
                            this.datos.push({invoice_id:t.invoice_id,supplier_id:t.supplier_id,quantity: t.quantity,subida:subida,bajada:bajada,precio:"$"+precio,proveedor:t.supplier,observacion:t.observation,id:t.id,accion:true});
                        }
                       console.log(this.datos);
                     })
                    .catch(() => this.$launchError('Error al obtener listado de alojamientos'))
                    .finally(() => {
                        this.$Progress.finish();
                        this.loading = false;
                    });
            },

               setInvoice(id=null){
                    this.selectedInvoice= id;
                    this.showInvoiceModal = true;
                    this.supplierType = 4;
                    this.$nextTick(() => $('#invoice-modal').modal('show'));
            },
            associateInvoice(id,idsupplier,invoice_id){
                    this.selectedAssociate= id;
                    this.selectedAssociateId= invoice_id;
                    this.selectedSupplierId= idsupplier;
                    this.showAssociateInvoiceModal = true;
                    this.$nextTick(() => $('#associate-invoice-modal').modal('show'));
            },
            dessasociateInvoice(index, deletevalue){
             
                                     let j = this.quotations.find(w => w.index == index);
                                     console.log(j);

                                        var index = j.transfers.findIndex(x => x.id==deletevalue); 
                                         console.log(index);
                                        

                                        var elimina = this.transfers.data.find(x => x.id==deletevalue); 
                                         console.log(elimina);
                                        elimina.invoice_id = null;

                                          var eliminadatos = this.datos.find(x => x.id==deletevalue); 
                                          console.log(elimina);
                                          eliminadatos.invoice_id = null;
                                
                           j.transfers.splice(index, 1);
               
              
            },
            deleteInvoice(index){
                 Swal.fire({
                                title: '¿Estás seguro que deseas quitar la factura?',
                                text: 'Puedes agregar otra luego',
                                showCancelButton: true,
                                confirmButtonColor: '#d33',
                                cancelButtonColor: '#3085d6',
                                confirmButtonText: 'Si',
                                cancelButtonText: 'No'
                            }).then((result) => {
                                if (result.value) {
                                     let j = this.quotations.findIndex(w => w.index == index);
                                    this.transfers.data.forEach(w => { 
                                            if(w.invoice_id == index){
                                                w.invoice_id = null;
                                            }
                                    }); 
                                    this.datos.forEach(w => { 
                                            if(w.invoice_id == index){
                                                w.invoice_id = null;
                                            }
                                    }); 
                                    this.quotations.splice(j, 1);
                                }
                            });
              
            },
            saveQuotation(){
                    if(this.quotations.length>0){
                            this.$launchSuccess('Se guardo todo correctamente');

                                axios.post(`/api/administracion/facturacion/facturar`, {
                                    quotations: this.quotations,
                                    type:'accommodations'
                                })
                                    .then(() => {
                                        ///eventHub.$emit('reloadAccommodation');
                                        this.quotations.forEach(w => { 
                                            
                                                w.transfers = [];
                                            
                                         }); 

                                        this.getTransfers();
                                        this.$launchSuccess(`Éxito al asociar factura`);
                                    })
                                    .catch(() => this.$launchError(`Error al asociar factura`));    


                    }else{
                            this.$launchError('No hay nada que guardar');
                    }
            },


            deleteWorker (id) {
                Swal.fire({
                    title: '¿Estás seguro que deseas eliminar este traslado?',
                    text: 'No podrás revertir esta acción',
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Si',
                    cancelButtonText: 'No'
                }).then((result) => {
                    if (result.value) {
                        this.form.delete('/api/administracion/personas/' + id).then(() => {
                            Swal.fire(
                                'Éxito',
                                'La persona fue eliminada correctamente',
                                'success'
                            );

                            this.getPersons();
                        }).catch(() => Swal.fire(
                            'Error',
                            'Error al eliminar persona',
                            'warning'
                        ));
                    }
                });
            }
        }
    }
</script>

<style>
  .v-application--wrap {
    min-height: 0vh !important;
  }
</style>
<template>
    <div>
        <div class="card-body table-responsive p-0" v-if="hasInductions">
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th class="align-middle">Inducción</th>
                        <th class="align-middle">Fecha</th>
                        <th class="align-middle">Subido por</th>
                        <th class="align-middle">Descargar</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="i in inductions.data" :key="i.id">
                        <td class="align-middle">{{ i.induction.name }}</td>
                        <td class="align-middle">{{ i.induction_date | myDate }}</td>
                        <td class="align-middle">
                            {{ i.uploader.name }} {{ i.uploader.last_name }}
                        </td>
                        <td class="align-middle">
                            <a :href="downloadFileUrl(
                                `inductions-certificates/${$parent.id}/${i.certificate}`
                                )" target="_blank">
                                <i class="fas fa-file-download blue"></i>
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <pagination :data="inductions" @pagination-change-page="getInductions" v-if="hasInductions"/>
        <span v-else><i>No se han subido inducciones a este trabajador.</i></span>
    </div>
</template>

<script>
    export default {
        created () {
            this.getInductions();
        },
        computed: {
            hasInductions () {
                return this.inductions.data.length > 0;
            }
        },
        data () {
            return {
                inductions: { data: [] }
            }
        },
        methods: {
            getInductions (page = 1) {
                this.$Progress.start();
                this.$parent.loading = true;

                axios.get('/api/trabajadores/' + this.$parent.id + '/inducciones?page=' + page)
                    .then(({ data }) => {
                        this.inductions = data.data;
                        this.$Progress.finish();
                    })
                    .catch(() => {
                        this.$launchError('Error al obtener inducciones del trabajador');
                        this.$Progress.fail();
                    })
                    .finally(() => {
                        this.$Progress.finish();
                        this.$parent.loading = false;
                    });
            }
        }
    }
</script>

<template>
    <form id="certificates-container" @submit.prevent="saveCertificate()">
        <div class="row mb-4">
            <div class="col form-group">
                <label>Tipo</label>
                <select class="form-control" v-model="form.plans_id">
                    <option :value="null" disabled>Escoja tipo</option>
                    <option :value="c.id" v-for="c in plans">{{ c.name }}</option>
                </select>
            </div>

            <div class="col form-group">
                <label>Plano</label><br>
                <div class="custom-file">
                    <input id="certificate" type="file" class="custom-file-input" v-on:change="upload" accept="application/pdf" ref="imageUploader">
                    <label class="custom-file-label" for="certificate">{{ fileName ? fileName : 'Escoja un archivo' }}</label>
                </div>
            </div>
        </div>

        <button type="submit" class="btn btn-primary float-right" :disabled="submit">
            <i class="fa fa-spinner fa-spin mr-2" v-if="submit"></i>
            {{ submit ? 'Guardando' : 'Guardar' }}
        </button>
    </form>
</template>

<script>
    export default {
        created () {
            this.getPlans();
        },
        data () {
            return {
                plans: [],
                fileName:null,
                form: {
                    plans_id: null,
                    certificate: null
                },
                submit: false
            }
        },
        methods: {
            getPlans () {
                this.$parent.loading = true;
                this.$Progress.start();

                axios.get('/api/planos/' + this.$parent.id)
                    .then(({ data }) => {
                        this.plans = data.data;

                        this.$Progress.finish();
                    })
                    .catch(() => {
                        this.$Progress.fail();
                        this.$launchError('Error al obtener listado de planos');
                    })
                    .finally(() => this.$parent.loading = false);
            },

            upload (e) {
                this.form.certificate = e.target.files[0];
                this.fileName = e.target.files[0].name;
            },

            saveCertificate () {
                   Swal.fire({
                    title: '¿Estás seguro que deseas guardar este plano?',
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Si',
                    cancelButtonText: 'No'
                }).then((result) => {
                    if (result.isConfirmed==true) {
                        this.$Progress.start();
                        this.submit = true;
                        let formData = new FormData();
                        formData.append('plans_id', this.form.plans_id);
                        formData.append('certificate', this.form.certificate);

                        axios.post(`/api/embarcaciones/${this.$parent.id}/planos`, formData, {
                            headers: { 'Content-Type': 'multipart/form-data' }
                        })
                            .then(() => {
                                Swal.fire(
                                    'Éxito',
                                    'El documento fue registrado correctamente',
                                    'success'
                                );
                                this.form = {
                                    certificate_id: null,
                                    certificate: null
                                }
                                this.$Progress.finish();
                                this.fileName = null;
                                this.plans_id = null;
                                //this.form.reset();
                                this.submit =false;
                                
                                this.$refs.imageUploader.value = '';
                                
                            })
                            .catch(() => {
                                Swal.fire(
                                    'Error',
                                    'No se pudo guardar el plano',
                                    'warning'
                                )
                                this.$Progress.fail();
                                //this.form.reset();
                                this.submit = false;
                              
                            });
                      }
                    });


                //

/*
                this.submit = true;
                this.$Progress.start();

                let formData = new FormData();
                formData.append('plans_id', this.form.plans_id);
                formData.append('certificate', this.form.certificate);

                axios.post(`/api/embarcaciones/${this.$parent.id}/planos`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                })
                    .then(() => {
                        this.$Progress.finish();
                        this.$refs.imageUploader.value = '';
                        this.$launchSuccess('Éxito al guardar plano de embarcación');
                        this.form = {
                            certificate_id: null,
                            certificate: null
                        }
                    })
                    .catch(() => {
                        this.$Progress.fail();
                    })
                    .finally(() => this.submit = false);
                    */
            }
        }
    }
</script>

<template>
 <v-app>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <loading v-if="loading"/>

                        <!-- header -->
                        <div class="card-header">
                          <div class="row align-items-center">
                              <h3 class="col card-title">Atributos Pendientes</h3>

                              <div class="col-6">
                                 <!-- <form @submit.prevent="getCertificates">
                                      <input type="text" v-model="searchInput" class="form-control"
                                             placeholder="Ingrese nombre y/o apellido de tripulante">
                                  </form>-->
                              </div>

                              <div class="col-2">
                                 
                              </div>
                          </div>
                      </div>


<v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Búsqueda"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
  <v-data-table
      :headers="headers"
      :items="datos"
      :search="search"
       :loading="loading"
       :items-per-page="30"
       no-results-text="No se han encontrado atributos"
       no-data-text="No existen atributos"
      class="elevation-1"
        loading-text="Cargando... Espere"
         :footer-props="{
           'items-per-page-text':'Atributos por página',
           'items-per-page-options': [20, 50, 100, -1]
      }"
    >
  <template v-slot:footer.page-text="items"> {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }} </template>
</v-data-table>
</v-card> 
                        <!-- 
                        <div class="card-body table-responsive p-0">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th class="align-middle">Tripulante</th>
                                        <th class="align-middle">Atributo</th>
                                        <th class="align-middle">Fecha Actualización</th>
                                        <th class="align-middle">Embarcacion</th>
                                        <th class="align-middle">Cliente</th>
                                        <th class="align-middle">Ingreso Tardío</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="i in certificates.data">
                                        <td class="align-middle">{{ i.name | capitalize }} {{ i.last_name | capitalize }}</td>
                                        <td class="align-middle"> {{ i.attribute | capitalize }} </td>
                                        <td class="align-middle">{{ i.date | myDate  }}</td>
                                        <td class="align-middle">{{ i.ship }}</td>
                                        <td class="align-middle">{{ i.client }}</td>
                                        <td class="align-middle">{{ i.late_entry }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                      
                        <div class="card-footer">
                            <pagination :data="certificates" @pagination-change-page="getCertificates"/>
                        </div>
                        -->
                    </div>
                </div>
            </div>

            
        </div>
    </section>
 </v-app>
</template>

<script>
import Loading from "./Common/Loading";

export default {
    components: { Loading },
    created () {
        this.getCertificates();
    },
    data () {
        return {
            loading: true,
            certificates: {},
             searchInput: null,
             search: '',
                headers: [
                {
                    text: 'Tripulante',
                    align: 'start',
                    value: 'tripulante',
                },
                { text: 'Atributo', value: 'atributo' },
                { text: 'Fecha Actualización', value: 'fecha' },
                { text: 'Embarcacion', value: 'barco' },
                { text: 'Cliente', value: 'cliente' },
                { text: 'Ingreso Tardío', value: 'ingreso' },
                ],
                datos:[],
        }
    },
    methods: {
        getCertificates (page = 1) {
            this.$Progress.start();
            this.loading = true;


            axios.get('/api/pending-attributes')
                /*.then(({ data }) => {
                    this.certificates = data;
                    this.$Progress.finish();
                })*/
                .then(data => {
                    this.certificates = data.data;
                    this.datos = [];
                     console.log(this.certificates);
                        for(let i = 0; i < this.certificates.data.length; i++) {
                            var t = this.certificates.data[i];  
                            var nombre = this.$options.filters.capitalize(t.name)+ " "+this.$options.filters.capitalize(t.last_name);    
                            this.datos.push({tripulante:nombre,atributo:this.$options.filters.capitalize(t.attribute),fecha:this.$options.filters.myDate(t.date),barco:t.ship,cliente:t.client,ingreso:t.late_entry});
                        }
                       console.log(this.datos);
                     })
                .catch(() => {
                    this.$launchError('Error al obtener listado de atributos');
                    this.$Progress.fail();
                })
                .finally(() => this.loading = false);
        },


    }
}
</script>

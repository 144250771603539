<template>
    <div>
        <button class="btn btn-primary float-right mb-3" @click="addSize">
            Añadir
        </button>
        <table class="table">
            <thead>
                <tr>
                    <th class="align-middle">Talla</th>
                    <th class="align-middle">Cantidad</th>
                    <th class="align-middle">Acciones</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="s in sizes">
                    <td class="align-middle">{{ s.name | dash }}</td>
                    <td class="align-middle">
                        <input min="0" type="number" class="form-control form-control-xs" v-model="sizesForm[s.id].quantity">
                    </td>
                    <td class="align-middle">
                        <a href="#" @click="editSize(s.id)">
                            <i class="fa fa-edit blue"></i>
                        </a>
                        /
                        <a href="#" @click="deleteSize(s.id)">
                            <i class="fa fa-trash red"></i>
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>

        <button class="btn btn-primary float-right" @click="confirmQuantities" :disabled="!canConfirm">
            Confirmar cantidades
        </button>

        <!-- modal de trabajadores -->
        <div class="modal fade" id="form-modal" tabindex="-1" role="dialog" aria-labelledby="form-dialog"
             aria-hidden="true" data-backdrop="static" data-keyboard="false">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ modalEdition ? 'Editar': 'Crear nueva' }} talla</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <form @submit.prevent="saveSize">
                        <div class="modal-body container">
                            <div>
                                <div class="row">
                                    <div class="col form-group">
                                        <label>Nombre</label>
                                        <input v-model="form.name" type="text" name="nombre" class="form-control"
                                               :class="{ 'is-invalid': form.errors.has('name') }">
                                        <has-error :form="form" field="name"/>
                                    </div>

                                    <div class="col form-group">
                                        <label>Cantidad</label>
                                        <input v-model.number="form.quantity" min="0" type="number" name="cantidad"
                                               class="form-control" :class="{ 'is-invalid': form.errors.has('quantity') }">
                                        <has-error :form="form" field="quantity"/>
                                    </div>
                                </div>
                            </div>

                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                                <button type="submit" class="btn btn-primary">Guardar</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        computed: {
            canConfirm () {
                return true;
            }
        },
        created () {
            this.getSizes();
        },
        data () {
            return {
                form: new Form({
                    name: null,
                    quantity: 0
                }),
                modalEdition: false,
                sizes: [],
                sizesForm: {},
                toEditId: null
            }
        },
        methods: {
            addSize () {
                this.modalEdition = false;
                this.toEditId = null;
                this.form.reset();
                $('#form-modal').modal('show');
            },

            confirmQuantities () {
                if (this.canConfirm) {
                    let d = [];

                    Object.keys(this.sizesForm).forEach(s => d.push(this.sizesForm[s]));

                    axios.post(`/api/bodega/articulos/${this.$parent.id}/tallas/confirmar-cantidades`, {
                        quantities: d
                    })
                        .then(() => {
                            this.$launchSuccess('Éxito al guardar cantidades de artículos');
                            this.getSizes();
                        })
                        .catch(() => this.$launchError('Error al guardar cantidades de artículos'));
                }
            },

            deleteSize (id) {
                Swal.fire({
                    title: '¿Estás seguro que deseas eliminar esta talla?',
                    text: 'No podrás revertir esta acción',
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Si',
                    cancelButtonText: 'No'
                }).then((result) => {
                    if (result.value) {
                        axios.delete(`/api/bodega/articulos/${this.$parent.id}/tallas/${id}`)
                            .then(() => {
                                Swal.fire(
                                    'Éxito',
                                    'La talla fue eliminada correctamente',
                                    'success'
                                );

                                this.getSizes();
                            }).catch(() => Swal.fire(
                                'Error',
                                'No se pudo eliminar la talla',
                                'warning'
                            ));
                    }
                });
            },

            editSize (id) {
                this.modalEdition = true;
                this.toEditId = id;
                this.form.fill(this.sizes.find(s => s.id === id));
                $('#form-modal').modal('show');
            },

            getSizes () {
                this.$Progress.start();
                this.$parent.loading = true;

                axios.get(`/api/bodega/articulos/${this.$parent.id}/tallas`)
                    .then(({ data }) => {
                        this.$Progress.finish();
                        this.sizes = data.data;
                        this.sizes.forEach(s => this.sizesForm[s.id] = {
                            id: s.id,
                            quantity: s.quantity
                        });
                    })
                    .catch(() => {
                        this.$Progress.fail();
                        this.$launchError('Error al obtener listado de tallas');
                    })
                    .finally(() => this.$parent.loading = false);
            },

            saveSize () {
                this.$Progress.start();

                this.form.post(`/api/bodega/articulos/${this.$parent.id}/tallas${this.modalEdition ? `/${this.toEditId}`: ''}`)
                    .then(() => {
                        this.$Progress.finish();
                        this.$launchSuccess(`Éxito al ${this.modalEdition ? 'editar': 'crear'} talla`);
                        $('#form-modal').modal('hide');
                        this.getSizes();
                    })
                    .catch(() => {
                        this.$Progress.fail();
                        this.$launchError(`Error al ${this.modalEdition ? 'editar': 'crear'} talla`);
                    });
            }
        }
    }
</script>

<template>

    <div id="edit-crew-container">
        <!-- breadcrumb -->
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Editar tripulación</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link to="/">Inicio</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link to="/jefe-flota/tripulaciones">Tripulaciones</router-link>
                            </li>
                            <li class="breadcrumb-item active">Editar tripulación</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>

        <!-- edición -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col">
                        <div class="card">
                            <div class="card-body">
                                <!-- información -->
                                <div class="row mb-2">
                                    <div class="col">
                                        <span><strong>Cliente:</strong> {{ crew.client.name }}.</span>
                                    </div>
                                    <div class="col">
                                        <span><strong>Embarcación:</strong> {{ crew.ship.name }}.</span>
                                    </div>
                                    <div class="col">
                                        <span><strong>Estado tripulación:</strong> {{ crew.status.name }}.</span>
                                    </div>
                                </div>

                                <div class="row mb-4">
                                    <div class="col">
                                        <span><strong>Fecha bajada real:</strong> {{ crew.real_download_date | myDate }}.</span>
                                    </div>
                                    <div class="col">
                                        <span><strong>Fecha bajada protocolar:</strong> {{ crew.download_date | myDate }}.</span>
                                    </div>
                                    <div class="col">
                                        <span><strong>Fecha presentanción:</strong> {{ crew.upload_date | myDate }}.</span>
                                    </div>
                                </div>

                                <!-- título -->
                                <div class="row d-flex align-items-center">
                                    <div class="col-3">
                                        <h4 class="mb-0">Tripulantes</h4>
                                    </div>

                                    <div :class="{'col-4': canConfirm, 'col-7': !canConfirm}">
                                        <div class="progress">
                                            <div class="progress-bar progress-bar-striped" role="progressbar"
                                                 :class="progressClass"
                                                 :style="`width: ${completionPercentage}%`">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col" :class="{'d-flex align-items-center': canConfirm}">
                                        <span :class="{'float-left': !canConfirm}">
                                            {{ validWorkersLength }} / {{ crew.ship.capacity }}
                                        </span>

                                        <button class="btn btn-primary ml-auto" @click="addWorkers(true)"
                                                v-if="canConfirm && ($gate.isJefeflota() || $gate.isAdmin() || $gate.isGerente())" :disabled="!canAddWorkers">
                                            Añadir disponible
                                        </button>

                                        <button class="btn btn-primary ml-2" @click="addWorkers(false)"
                                                v-if="canConfirm && ($gate.isJefeflota() || $gate.isAdmin() || $gate.isGerente())" :disabled="!canAddWorkers">
                                            Añadir sala espera
                                        </button>

                                        <button class="btn btn-primary ml-2 float-right" @click="emergencyButtonAddWorker()"
                                                v-if="!canConfirm && ($gate.isJefeflota() || $gate.isAdmin() || $gate.isGerente())" :disabled="!canAddWorkersExtra">
                                            N. Ingreso
                                        </button>
                                    </div>


                                  



                                </div>

                                <!-- tabla de trabajadores -->
                                <div class="mt-4" :class="{'mb-3': canConfirm}">
                                    <div class="row" v-if="crew.workers.length === 0">
                                        <span class="text-center w-100">
                                            <i>Aún no se han asociado tripulantes a esta tripulación</i>
                                        </span>
                                    </div>
                                    <div v-else>
                                        <div class="table-responsive p-0">
                                            <table class="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th class="align-middle">Nombre</th>
                                                        <th class="align-middle">Rut</th>
                                                        <th class="align-middle">Rol</th>
                                                        <th class="align-middle">Alojamiento</th>
                                                        <th class="align-middle">Alimentación</th>
                                                        <th class="align-middle">Traslado</th>
                                                        <th class="align-middle">Última embarcación</th>
                                                        <th class="align-middle">Estado</th>
                                                        <th class="align-middle">Motivo</th>
                                                        <th class="align-middle">Acciones</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="w in workers" :class="{ 'table-warning': w.filtered, 'table-danger': w.with_emergency || w.worker.deleted_at!=null }">
                                                        
                                                        <td :class="{'stroked': w.filtered}"
                                                            class="align-middle">
                                                            {{ w.worker.name }} {{ w.worker.last_name }}
                                                        </td>
                                                        <td :class="{'stroked': w.filtered}" class="align-middle">
                                                            {{ w.worker.rut }}
                                                        </td>
                                                        <td :class="{'stroked': w.filtered}" class="align-middle">
                                                            {{ w.worker.roles.map(r => r.name) | list }}
                                                        </td>
                                                        <td class="align-middle">
                                                            <template v-if="w.filtered === 0 && w.worker.deleted_at==null">
                                                                <input type="checkbox" :value="w.id" v-model="lodgings"
                                                                       v-if="canConfirm">
                                                                <i class="fa" v-else
                                                                   :class="{'fa-check green': w.lodging, 'fa-times red': !w.lodging}"/>
                                                            </template>
                                                            <span v-else>-</span>
                                                        </td>
                                                        <td class="align-middle">
                                                            <template v-if="w.filtered === 0 && w.worker.deleted_at==null">
                                                                <input type="checkbox" :value="w.id" v-model="lodgingsFood"
                                                                       :disabled="!lodgings.includes(w.id)" v-if="canConfirm">
                                                                <i class="fa" v-else
                                                                   :class="{'fa-check green': w.lodging_food, 'fa-times red': !w.lodging_food}"/>
                                                            </template>
                                                            <span v-else>-</span>
                                                        </td>
                                                        <td class="align-middle">
                                                            <template v-if="w.filtered === 0 && w.worker.deleted_at==null">
                                                                <input type="checkbox" :value="w.id" v-model="relocations"
                                                                       v-if="canConfirm">
                                                                <i class="fa" v-else
                                                                   :class="{'fa-check green': w.relocation, 'fa-times red': !w.relocation}"/>
                                                            </template>
                                                            <span v-else>-</span>
                                                        </td>
                                                        <td class="align-middle">
                                                            {{ w.last_crew ? w.last_crew.name : '-' }}
                                                        </td>
                                                        <td class="align-middle"><worker-status :status="w.status.status"/></td>
                                                        <td class="align-middle">
                                                            <worker-reasons :status="w.status"
                                                                            v-if="w.status.status !== 'Ok'"/>
                                                            <span v-else>-</span>
                                                        </td>
                                                        <td class="align-middle">
                                                            <router-link v-if="($gate.isJefeflota() || $gate.isAdmin() || $gate.isGerente())" :to="'/admin/trabajadores/' + w.worker.id"
                                                                         v-tooltip="'Ficha del tripulante'">
                                                                <i class="fa fa-info-circle green"></i>
                                                            </router-link>
                                                            <span v-if="(canConfirm && w.filtered === 0 && ($gate.isJefeflota() || $gate.isAdmin() || $gate.isGerente())) && w.worker.deleted_at==null">/</span>
                                                            <a href="#" @click.prevent="deleteWorker(w.worker.id)"
                                                               v-if="(canConfirm && w.filtered === 0 && ($gate.isJefeflota() || $gate.isAdmin() || $gate.isGerente()))  && w.worker.deleted_at==null"
                                                               v-tooltip="'Eliminar tripulante'">
                                                                <i class="fa fa-trash red"></i>
                                                            </a>
                                                            <span v-if="(canPushEmergencyButton && w.filtered === 0 && w.with_emergency === 0 && ($gate.isJefeflota() || $gate.isAdmin() || $gate.isGerente())) && w.worker.deleted_at==null">/</span>
                                                            <a href="#" @click.prevent="emergencyButton(w.id)"
                                                               v-if="(canPushEmergencyButton && w.filtered === 0 && w.with_emergency === 0)  && w.worker.deleted_at==null"
                                                               v-tooltip="'Botón de emergencia'">
                                                                <i class="fa fa-exclamation-triangle red"></i>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <!--confirmar -->
                                <button type="button" class="btn btn-success float-right" @click="confirm"
                                        :disabled="validWorkersLength === 0" v-if="canConfirm && ($gate.isJefeflota() || $gate.isAdmin() || $gate.isGerente())">
                                    Confirmar
                                </button>

                                <!-- modal de trabajadores -->
                                <div class="modal fade" id="workers-modal" tabindex="-1" role="dialog"
                                     aria-labelledby="form-dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                                    <add-worker v-if="showWorkersModal && canConfirm" :available="addAvailable"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>

</template>

<script>
    import AddWorker from "./EditCrew/AddWorker";
    import WorkerReasons from "./EditCrew/WorkerReasons";
    import WorkerStatus from "./EditCrew/WorkerStatus";
    import eventHub from "../../eventHub";

    export default {
        components: { AddWorker, WorkerReasons, WorkerStatus },
        computed: {
            id () {
                return this.$route.params.id;
            },
            canAddWorkers () {
                return (this.validWorkersLength < this.crew.ship.capacity) && this.canConfirm;
            },
            canAddWorkersExtra () {
                return (this.validWorkersLength < this.crew.ship.capacity);
            },
            canConfirm () {
                return this.crew.status.name === 'Borrador';
            },
            canPushEmergencyButton () {
                // TODO: también puede ser 'En faena'
                return this.crew.status.name === 'En calendario';
            },
            completionPercentage () {
                return (this.validWorkersLength * 100) / this.crew.ship.capacity;
            },
            progressClass () {
                return this.completionPercentage < 100 ? 'bg-primary': 'bg-success';
            },
            validWorkersLength () {
       
                    return this.crew.workers.filter(w => w.filtered === 0 && w.with_emergency==0 && w.worker!=null).length;
                
                
            },
            workers () {
                let notFiltered = [];
                let emergency = [];
                let filtered = [];

                this.crew.workers.forEach(w => {
                    if (w.filtered === 1) filtered.push(w);
                    else if (w.with_emergency === 1) emergency.push(w);
                    else notFiltered.push(w);
                })

                return notFiltered.concat(filtered.concat(emergency));
            }
        },
        created () {
            this.getCrew();

            // registra evento de cerrar modal
            this.$nextTick(() => $('#workers-modal').on('hidden.bs.modal', () => this.showWorkersModal = false));

            // registra eventos
            eventHub.$on('workersSaved', () => {
                $('#workers-modal').modal('hide');
                this.getCrew();
            });
        },
        data () {
            return {
                addAvailable: true,
                crew: {
                    client: { name: null },
                    ship: { capacity: 0 },
                    status: { name: null },
                    workers: []
                },
                lodgings: [],
                lodgingsFood: [],
                relocations: [],
                showWorkersModal: false
            }
        },
        destroyed() {
            eventHub.$off('closeModal');
        },
        methods: {
            addWorkers (available) {
                if (this.canAddWorkers) {
                    this.addAvailable = available;
                    this.showWorkersModal = true;
                    this.$nextTick(() => $('#workers-modal').modal('show'));
                }
            },

            confirm () {
                if (this.canConfirm) {
                    Swal.fire({
                        title: '¿Estás seguro que desea confirmar esta tripulación?',
                        text: 'No podrás revertir esta acción',
                        showCancelButton: true,
                        confirmButtonColor: '#d33',
                        cancelButtonColor: '#3085d6',
                        confirmButtonText: 'Si',
                        cancelButtonText: 'No'
                    }).then((result) => {
                        if (result.value) {
                            axios.post(`/api/jefe-flota/tripulaciones/${this.id}/confirmar`, {
                                lodgings: this.lodgings,
                                lodgingsFood: this.lodgingsFood,
                                relocations: this.relocations,
                            })
                                .then(() => {
                                    
                                    // AGERGADO SOLICITUD ELIMINACION FILTRO SUPERVISOR
                                    axios.post(`/api/supervisor/tripulaciones/${this.id}/filtro-tripulacion`, {
                                        workers: []
                                    })
                                        .then(() => {
                                        }).catch();

                                    Swal.fire(
                                        'Éxito',
                                        'La tripulación fue confirmada correctamente',
                                        'success'
                                    );

                                    this.getCrew();
                                }).catch(() => Swal.fire(
                                'Error',
                                'No se pudo confimar la tripulación',
                                'warning'
                            ));
                        }
                    });
                }
            },

            deleteWorker (id) {
                const w = this.crew.workers.find(w => w.worker_id === id);

                if (w.filtered === 0) {
                    Swal.fire({
                        title: '¿Estás seguro que deseas quitar este tripulante de la tripulación?',
                        text: 'Podrás volver a añadirlo más tarde',
                        showCancelButton: true,
                        confirmButtonColor: '#d33',
                        cancelButtonColor: '#3085d6',
                        confirmButtonText: 'Si',
                        cancelButtonText: 'No'
                    }).then((result) => {
                        if (result.value) {
                            axios.delete(`/api/jefe-flota/tripulaciones/${this.id}/trabajadores/${id}`)
                                .then(() => {
                                    // debe eliminar su registro de los formularios
                                    // elimina alojamiento
                                    const lIndex = this.lodgings.findIndex(l => l === w.id);
                                    if (lIndex !== -1) this.lodgings.splice(lIndex, 1);

                                    // elimina alimentación
                                    const fIndex = this.lodgingsFood.findIndex(l => l === w.id);
                                    if (fIndex !== -1) this.lodgingsFood.splice(fIndex, 1);

                                    // elimina traslado
                                    const rIndex = this.relocations.findIndex(l => l === w.id);
                                    if (rIndex !== -1) this.relocations.splice(rIndex, 1);

                                    this.getCrew();
                                    Swal.fire(
                                        'Éxito',
                                        'El tripulante fue quitado correctamente',
                                        'success'
                                    );
                                })
                                .catch(() => Swal.fire(
                                    'Error',
                                    'Error al quitar tripulante',
                                    'warning'
                                ));
                        }
                    });
                }
            },

            emergencyButton (crewWorkerId) {
                if (this.canPushEmergencyButton) {
                    this.$router.push({ name: 'EmergencyButton', params: { crewWorkerId, id: this.id } });
                }
            },

            emergencyButtonAddWorker () {
                    this.$router.push({ name: 'EmergencyButtonAddWorker', params: { id: this.id } });      
            },

            getCrew () {
                this.$Progress.start();

                axios.get(`/api/jefe-flota/tripulaciones/${this.id}/editar`)
                    .then(({ data }) => {
                        this.crew = data.data;
                        this.$Progress.finish();

                        // si está en modo edición, podría completar el formulario de checks
                        // ej: si viene de filtro caso cliente
                        if (this.canConfirm) {
                            this.crew.workers.forEach(w => {
                                if (w.lodging) this.lodgings.push(w.id);
                                if (w.lodging_food) this.lodgingsFood.push(w.id);
                                if (w.relocation) this.relocations.push(w.id);
                            });
                        }
                    })
                    .catch(() => {
                        this.$launchError('Error al obtener datos de tripulación');
                        this.$Progress.fail();
                    });
            }
        },
        watch: {
            lodgings (n, o) {
                // si la cantidad es menor a la anterior significa que desactivó un check de alojamiento
                // por ende, debe eliminar su respectivo id en alimentación de haberlo tenido marcado
                if (n.length < o.length) {
                    // encuentra el id faltante para eliminarlo en alimentación
                    const id = o.filter(x => !n.includes(x))[0];
                    const index = this.lodgingsFood.findIndex(f => f === id);

                    // si encuentra un índice en alimentación, lo elimina inmediatamente
                    if (index !== -1) this.lodgingsFood.splice(index, 1);
                }
            }
        }
    }
</script>

<template>
    <div>
        <!-- breadcrumb -->
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Salud</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link to="/">Inicio</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link to="/admin/salud/trabajadores"> Salud Tripulantes</router-link>
                            </li>
                            <li class="breadcrumb-item active">Salud</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>

        <!-- ficha -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col">
                        <div class="card">
                            <loading v-if="loading"/>

                            <div class="card-header">
                                <ul class="nav nav-pills">
                                    <li class="nav-item" v-for="t in tabs">
                                        <a class="nav-link" :class="{'active': tab === t.component}"
                                           @click="tab = t.component">
                                            {{ t.title }}
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div class="card-body">
                                <component :is="tab"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Exam from "./WorkerHealth/Exam";
    import IMC from "./WorkerHealth/IMC";
    import Loading from "../Common/Loading";

    export default {
        components: { Exam, IMC, Loading },
        data () {
            return {
                loading: false,
                tab: 'IMC',
                tabs: [
                    { title: 'IMC', component: 'IMC' },
                    { title: 'Examen ocupacional', component: 'Exam' }
                ]
            }
        }
    }
</script>

<script>
    import FullCalendar from '@fullcalendar/vue'
    import dayGridPlugin from '@fullcalendar/daygrid';
    // import interactionPlugin from '@fullcalendar/interaction';

    export default {
        components: { FullCalendar },
        computed: {
            options () {
                return {
                    ...this.calendarOptions,
                    events: this.events
                }
            }
        },
        data () {
            return {
                calendarOptions: {
                    plugins: [ dayGridPlugin ],
                    initialView: 'dayGridMonth',
                    locale: 'es',
                    // permite mostrar el título como html
                    eventContent (event) {
                        return { html: event.event.title };
                    }
                }
            }
        },
        props: {
            events: {
                type: Array,
                default: []
            }
        }
    }
</script>
<template>
    <FullCalendar :options="options" />
</template>

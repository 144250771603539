<template>
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <!-- header -->
            <div class="modal-header">
                <h5 class="modal-title">{{ edit ? 'Editar': 'Añadir' }} Colacion</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <!-- body -->
            <form @submit.prevent="setSnack">
                <div class="modal-body container">
                    <div class="row">
                        <div class="col form-group">
                                   <label>Descripcion</label>
                                            <input type="text" class="form-control" v-model="form.description">
                        </div>


                    </div>

                    <div class="row">
                        <div class="col form-group">
                           <label>Precio Total</label>
                                            <input type="number" class="form-control" min='0' v-model="form.price">
                             
                        </div>

                          <div class="col form-group">
                            <label>Tramo</label>
                            <select name="tramo" class="form-control" v-model="form.journey_type"
                                    :class="{ 'is-invalid': form.errors.has('journey_type') }">
                                <option :value="null" disabled>Escoja un tramo</option>
                                <option :value="1">Ida</option>
                                <option :value="2">Vuelta</option>
                            </select>
                            <has-error :form="form" field="journey_type"/>
                        </div>

                    
                    </div>

                

                  

                 
                  
                  
                </div>

                <div class="modal-footer">
                    <button type="submit" class="btn btn-primary float-right">Guardar</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    import eventHub from "../../../eventHub";

    export default {
        props: ['snackId'],
        computed: {
            edit () {
                return this.snackId !== null;
            }
        },
        created () {
            this.$Progress.start();

            this.$Progress.finish();

            // si es edición, debe llenar el formulario
            console.log(this.edit);
            if (this.edit) this.fillEditForm();

            // registra eventos
            eventHub.$on('cleanSnackForm', () => this.form.reset());
        },
        data () {
            return {
                 form: new Form({
                    price:0,
                    description:'',
                    id:null,
                    isedit:0,
                    snack_id:null,
                    journey_type:null
                }),
            }
        },
        destroyed() {
            eventHub.$off('cleanSnackForm');
        },
        methods: {
             setSnack(){
                       if(this.form.description=='' || this.form.price==null  || this.form.price=='' ) {
                            this.$launchError('Error, debe completar todos los datos y agregar personas');
                        }else{
                            Swal.fire({
                                    title: '¿Estás seguro que deseas guardar esta Colación?',
                                    text: 'No podrás revertir esta acción',
                                    showCancelButton: true,
                                    confirmButtonColor: '#d33',
                                    cancelButtonColor: '#3085d6',
                                    confirmButtonText: 'Si',
                                    cancelButtonText: 'No'
                                }).then((result) => {
                                    if (result.value) {
                                        this.form.snack_id = this.snackId;
                                        this.form.quotation_id = this.$parent.id;
                                        console.log(this.form);
                                        this.form.post('/api/administracion/personas/nuevo/colacionCrew')
                                        .then(() => {
                                            eventHub.$emit('reloadSnacks');
                                            this.$launchSuccess('Se guardo la colación correctamente');
                                            this.$Progress.finish();
                                        })
                                        .catch(() => {
                                            this.$Progress.fail();
                                            this.$launchError('Error al guardar colación');
                                        });
                                        }
                                });   
                        }               
            },
            fillEditForm () {
                
                let t = this.$parent.quotation.snacks.find(t => t.id === this.snackId);
                console.log(t);

                    this.form.description = t.description;
                    this.form.price = t.price;
                    this.form.isedit = 1;
                    this.form.id = this.snackId;
                    this.form.journey_type =t.journey_type;
               // this.form.fill(t);
            }
        }
    }
</script>

<template>
    <div>
        <div class="card-body table-responsive p-0" v-if="hasHeadlines">
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th class="align-middle">Nombre</th>
                        <th class="align-middle">Rut</th>
                        <th class="align-middle">Roles</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="t in headlines" :key="t.id">
                        <td class="align-middle">{{ t.worker.name }} {{ t.worker.last_name }}</td>
                        <td class="align-middle">{{ t.worker.rut }}</td>
                        <td class="align-middle">{{ t.worker.roles.map(r => r.name) | list }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <span v-else><i>No se han asignado titulares a esta embarcación.</i></span>
    </div>
</template>

<script>
export default {
    created () {
        this.getHeadlines();
    },
    computed: {
        hasHeadlines () {
            return this.headlines.length > 0;
        }
    },
    data () {
        return {
            headlines: { data: [] }
        }
    },
    methods: {
        getHeadlines () {
            this.$Progress.start();
            this.$parent.loading = true;

            axios.get('/api/embarcaciones/' + this.$route.params.id + '/titulares')
                .then(({ data }) => {
                    this.headlines = data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$launchError('Error al obtener titulares de la embarcación');
                    this.$Progress.fail();
                })
                .finally(() => {
                    this.$Progress.finish();
                    this.$parent.loading = false;
                });
        }
    }
}
</script>

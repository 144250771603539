export default {
    methods: {
        $launchSuccess (message) {
            Toast.fire({
                icon: 'success',
                title: message ? message: 'La operación ha sido exitosa'
            });
        },

        $launchError (message) {
            Toast.fire({
                icon: 'error',
                title: message ? message: 'Ha ocurrido un error'
            });
        },

        $launchUnauthorized () {
            Toast.fire({
                icon: 'error',
                title: 'No tienes los permisos necesarios para realizar esta acción'
            });
        }
    }
}

<template>
   <v-app>
    <div>
        <!-- breadcrumb -->
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Nuevo Pasaje</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link to="/">Inicio</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link to="/">administracion</router-link>
                            </li>
                            <li class="breadcrumb-item active">Nuevo Pasaje</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>

        <!-- creación -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col">
                        <div class="card">
                            <loading v-if="loading"/>

                            <div class="card-body">
                                <!-- formulario -->
                                

                                <form >
                                    <div class="row">
                                   
                                        <div class="col form-group">
                                             <label>Aerolínea</label>
                                            <select name="aerolínea" class="form-control" v-model="form.airline_id"
                                                    :class="{ 'is-invalid': form.errors.has('airline_id') }">
                                                <option :value="null" disabled>Escoja una aerolínea</option>
                                                <option :value="a.id" v-for="a in airlines">{{ a.name }}</option>
                                            </select>
                                            <has-error :form="form" field="airline_id"/>
                                        </div>
                                 

                                
                                       <div class="col form-group">
                                            <label>Tarifa unitaria (x{{many}})</label>
                                            <input v-model="form.price" type="number" min="0" name="tarifa" class="form-control"
                                                :class="{ 'is-invalid': form.errors.has('price') }"  >
                                            <has-error :form="form" field="price"/>
                                        </div>
                                 

                                    
                                      <!--  <div class="col form-group">
                                            <label>Tarifa Plus</label>
                                            <select name="plus" class="form-control" v-model="form.tarifa_plus"
                                                    :class="{ 'is-invalid': form.errors.has('tarifa_plus') }">
                                                <option :value="0">No</option>
                                                <option :value="1">Si</option>     
                                            </select>
                                            <has-error :form="form" field="tarifa_plus"/>
                                        </div>-->
                                   

                                    
                                         <div class="col form-group">
                                            <label>Diferencial Tarifa Total(x{{many}})</label>
                                            <input v-model="form.extra_price" type="number" min="0" name="extra" class="form-control"
                                                >
                                            <has-error :form="form" field="extra"/>
                                        </div>
                                   

                                   
                                         <!--<div class="col form-group">
                                            <label>Monto Maleta</label>
                                            <input v-model="form.carry_price" type="number" min="0" name="carry" class="form-control"
                                                disabled>
                                            <has-error :form="form" field="carry"/>
                                        </div>-->

                                         <div class="col form-group">
                                             <label>Fecha de vuelo</label>
                                            <input v-model="form.flight_date" type="date" name="fecha de vuelo" class="form-control"
                                                :class="{ 'is-invalid': form.errors.has('flight_date') }">
                                            <has-error :form="form" field="flight_date"/>
                                        </div>
                                 

                                   
                                         <div class="col form-group">
                                            <label>Tramo</label>
                                            <select name="tramo" class="form-control" v-model="form.flight_stretch_id"
                                                    :class="{ 'is-invalid': form.errors.has('flight_stretch_id') }">
                                                <option :value="null" disabled>Escoja un tramo</option>
                                                <option :value="f.id" v-for="f in flightStretches">{{ f.name }}</option>
                                            </select>
                                            <has-error :form="form" field="flight_stretch_id"/>
                                        </div>


                                        <div class="col form-group">
                                             <label>Fecha de Vencimiento</label>
                                            <input v-model="form.expiration_date" type="date" name="fecha de vencimiento" class="form-control"
                                                :class="{ 'is-invalid': form.errors.has('expiration_date') }">
                                            <has-error :form="form" field="expiration_date"/>
                                        </div>
                                 

                                
                                    </div>
                                    
                                    <div class="row">
                                    
                                        
                                    

                                    
                                         <div class="col form-group">
                                              <label>Hora de partida</label>
                                                <input v-model="form.departure_time" type="time" name="hora de partida" class="form-control"
                                                    :class="{ 'is-invalid': form.errors.has('departure_time') }">
                                                <has-error :form="form" field="departure_time"/>
                                        </div>
                                   


                                      
                                         <div class="col form-group">
                                              <label>Hora de llegada</label>
                                                <input v-model="form.arrival_time" type="time" name="hora de llegada" class="form-control"
                                                    :class="{ 'is-invalid': form.errors.has('arrival_time') }">
                                                <has-error :form="form" field="arrival_time"/>
                                        </div>
                                  


                                 
                                         <div class="col form-group">
                                              <label>Aeropuerto de origen</label>
                                                <select name="aeropuerto de origen" class="form-control" v-model="form.departure_airport_id"
                                                        :class="{ 'is-invalid': form.errors.has('departure_airport_id') }">
                                                    <option :value="null" disabled>Escoja un aeropuerto</option>
                                                    <option :value="a.id" v-for="a in airports">{{ a.iata }} - {{ a.city }}</option>
                                                </select>
                                                <has-error :form="form" field="departure_airport_id"/>
                                        </div>
                                   

                            
                                         <div class="col form-group">
                                              <label>Aeropuerto de destino</label>
                                                <select name="aeropuerto de destino" class="form-control" v-model="form.arrival_airport_id"
                                                        :class="{ 'is-invalid': form.errors.has('arrival_airport_id') }">
                                                    <option :value="null" disabled>Escoja un aeropuerto</option>
                                                    <option :value="a.id" v-for="a in airports">{{ a.iata }} - {{ a.city }}</option>
                                                </select>
                                                <has-error :form="form" field="arrival_airport_id"/>
                                        </div>
                                   

                             
                                         <div class="col form-group">
                                              <label>Utilizar...</label>
                                                <select name="stock" class="form-control" v-model="form.stock_ticket_id"
                                                        :disabled="form.airline_id == null" >
                                                    <option :value='null'>Ninguno</option>
                                                    <option :value='1' :disabled='nostock==1'>
                                                        Pasaje en Stock
                                                    </option>
                                                     <option :value='2' :disabled='novoucher==1'>
                                                         Voucher
                                                    </option>
                                                </select>
                                        </div>

                                         <div class="col form-group" :hidden="form.stock_ticket_id!==1">
                                              <label>Pasajes en stock</label>
                                                <select name="stock" class="form-control" v-model="form.stock_id"
                                                        :disabled="stocks.length === 0" v-on:change="changePenalty()">
                                                    <option :value="null">Escoja un pasaje</option>
                                                    <option :value="s.id" v-for="s in stocks">
                                                        {{ s.code }} - {{ s.name }} - {{s.price | dotNumber}}
                                                    </option>
                                                </select>
                                        </div>

                                          <div class="col form-group" :hidden="form.stock_ticket_id!==2">
                                              <label>Vouchers</label>
                                                <select name="stock" class="form-control" v-model="form.voucher_id"
                                                        :disabled="vouchers.length === 0" v-on:change="changePenalty()">
                                                    <option :value="null">Escoja un Voucher</option>
                                                    <option :value="s.id" v-for="s in vouchers">
                                                        Monto :${{ s.price | dotNumber}} - Código :{{ s.code }} {{s.contain_type}}
                                                    </option>
                                                </select>
                                        </div>
                                    
                                        

                                                   <div class="col form-group">
                                                        <label>Orden de compra</label>
                                                        <input v-model="form.purchase_order" type="number" name="purchase_order" class="form-control"
                                                            >
                                                        <has-error :form="form" field="purchase_order"/>
                                                    </div>
                                            
                                        
                                                <div class="col form-group">
                                                    <label>Fecha Orden de compra</label>
                                                    <input type="date" v-model="form.purchase_order_date" name="purchase_order_date" class="form-control"
                                                      >
                                                       <has-error :form="form" field="purchase_order_date"/>
                                                </div>

                                
                                  </div>

                                </form>
                                 <div class="col mt-4">
                                 <button class="btn btn-primary float-left" @click="personModal()">
                                            Añadir Persona
                                </button>

                                  <button class="btn btn-primary float-left" style="margin-left:20px !important;" @click="workerModal()">
                                            Añadir Tripulante
                                </button>
                                </div>

                                  <div class="col mt-4" >
                               
                                </div>



                                 <div class="mt-4">
                                    <div class="row" v-if="selectedData.length === 0 && selectedDataWorkers.length === 0">
                                        <span class="text-center w-100">
                                            <i>Aún no se han asociado personas a este pasaje</i>
                                        </span>
                                    </div>
                                    <div v-else>
                                        <div class="table-responsive p-0">
                                            <table class="table table-hover"  style="margin-top:30px !important;">
                                            <thead>
                                                <tr>
                                                    <th class="align-middle">Nombre</th>
                                                    <th class="align-middle">Apellido</th>
                                                    <th class="align-middle">Rut</th>
                                                    
                                                    <th class="align-middle">Monto Maleta</th>
                                                    <th class="align-middle">Facturar Monto Maleta</th>
                                                    <th class="align-middle">Multa</th>
                                                    <th class="align-middle">Facturar Monto Multa</th>
                                                    <th class="align-middle">Monto Extra</th>
                                                    <th class="align-middle">Observacion</th>
                                                    <th class="align-middle">Tipo</th>
                                                    <th class="align-middle">Quitar de Lista</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="i in selectedData">
                                                    <td class="align-middle">{{ i.name }}</td>
                                                    <td class="align-middle">{{ i.last_name }}</td>
                                                    <td class="align-middle">{{ i.rut }}</td>
                                                    
                                                    <td class="align-middle"><input type="number" min="0" v-model="i.carry_price" class="form-control"/></td>
                                                    <td class="align-middle"><input type="checkbox" v-model="i.quotation" class="form-control"/></td>
                                                    <td class="align-middle"><input type="number" v-on:change="changePenalty()" v-on:keyup="changePenalty()" min="0" v-model="i.penalty" class="form-control"/></td>
                                                    <td class="align-middle"><input type="checkbox" v-model="i.penalty_quotation" class="form-control"/></td>
                                                    <td class="align-middle"><input type="number" min="0" v-model="i.extra_amount" class="form-control"/></td>
                                                    <td class="align-middle"><input type="text" v-model="i.observation" class="form-control"/></td>
                                                    <td class="align-middle">Normal</td>
                                                    <td class="align-middle">
                                                         <a href="#"  @click="unselect(i.id)"
                                                            v-tooltip="'Eliminar persona'">
                                                               <v-icon small color="red">
                                                                fas fa-trash
                                                                </v-icon>
                                                        </a>
                                                    </td>
                                                </tr>

                                                 <tr v-for="i in selectedDataWorkers">
                                                    <td class="align-middle">{{ i.name }}</td>
                                                    <td class="align-middle">{{ i.last_name }}</td>
                                                    <td class="align-middle">{{ i.rut }}</td>
                                                    <td class="align-middle"><input type="number" min="0" v-model="i.carry_price" class="form-control"/></td>
                                                    <td class="align-middle"><input type="checkbox" v-model="i.quotation" class="form-control"/></td>
                                                    <td class="align-middle"><input type="number" v-on:change="changePenalty()" v-on:keyup="changePenalty()" min="0" v-model="i.penalty" class="form-control"/></td>
                                                    <td class="align-middle"><input type="checkbox" v-model="i.penalty_quotation" class="form-control" /></td>
                                                    <td class="align-middle"><input type="number" min="0" v-model="i.extra_amount" class="form-control"/></td>
                                                    <td class="align-middle"><input type="text" v-model="i.observation" class="form-control"/></td>
                                                    <td class="align-middle">Tripulante</td>
                                                    <td class="align-middle">
                                                         <a href="#"  @click="unselectWorkers(i.id)"
                                                            v-tooltip="'Eliminar Tripulante'">
                                                              <v-icon small color="red">
                                                                fas fa-trash
                                                                </v-icon>
                                                        </a>
                                                    </td>
                                                    
                                                </tr>
                                            </tbody>
                                        </table>

                                        </div>
                                    </div>
                                </div>

                                 <div class="col mt-4">
                                        <button type="button" class="btn btn-success float-right" @click="setTicket()" style="color:#ffffff !important;">
                                            Guardar Pasaje
                                        </button>
                                    </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>




        <!-- inicio modal-->

         <div class="modal fade" id="form-modal-person" tabindex="-1" role="dialog" aria-labelledby="form-dialog"
               aria-hidden="true" v-if="showModal" data-backdrop="static" data-keyboard="false">
             
        


     <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <!-- header -->
            <div class="modal-header align-items-center">
                <h5 class="modal-title">Añadir Personas</h5>

                <!--<form class="ml-auto w-50" @submit.prevent="getPersons">
                    <input type="text" v-model="searchInput" class="form-control"
                           placeholder="Ingrese nombre, apellido o rut de persona">
                </form>-->

                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <!-- body -->
            <div class="modal-body container">
        
                <!-- tabla -->
                <div class="table-responsive p-0">

<v-card >
    <v-card-title>
      <v-text-field
        v-model="searchPersons"
        append-icon="mdi-magnify"
        label="Búsqueda"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
  <v-data-table 
      :headers="headers"
      :items="datosPersons"
      :search="searchPersons"
       :loading="loading"
       :items-per-page="2"
       no-results-text="No se han encontrado personas"
       no-data-text="No existen personas"
      class="elevation-1"
        loading-text="Cargando... Espere"
         :footer-props="{
           'items-per-page-text':'Personas por página',
           'items-per-page-options': [5,10,15]
      }"
    >
     <template v-slot:item.accion="{ item, index }">
           <input type="checkbox" v-model="selected" :value="item.id"
                                           @change="toggleSelection(item.id)">      
  </template>
  <template v-slot:footer.page-text="items"> {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }} </template>
</v-data-table>
</v-card>
                   <!-- <table class="table table-hover">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Nombre</th>
                                <th>Apellido</th>
                                <th>Rut</th>
                                
                            </tr>
                        </thead>
                        <tbody>
                        
                            <tr v-for="w in persons">
                                <td class="align-middle">
                                    <input type="checkbox" v-model="selected" :value="w.id"
                                           @change="toggleSelection(w.id)">
                                </td>
                                <td class="align-middle">
                                    <span>
                                        {{ w.name }} 
                                    </span>
                                </td>
                                 <td class="align-middle">
                                    <span>
                                       {{ w.last_name }}
                                    </span>
                                </td>
                                <td class="align-middle">{{ w.rut }}</td>

                               
                            </tr>
                        </tbody>
                    </table>

                 <pagination :data="persons" @pagination-change-page="getPersons"/>-->
                </div>

                <!-- selección -->
                <div class="tag-input" v-if="selectedData.length > 0">
                    <div v-for="s in selectedData" class="tag-input__tag">
                        <span @click="unselect(s.id)">x</span>
                        {{ s.name }} {{s.last_name}}
                    </div>
                </div>
                <span v-else><i>Aún no se han escogido personas</i></span>

                <!-- footer -->
                <div class="modal-footer">
                   
                    <button type="button" class="btn btn-primary" data-dismiss="modal" style="color:#ffffff !important;">
                        Aceptar
                    </button>
                </div>
            </div>
        </div>
    </div>
      </div>
        <!-- fin modal -->

          <!--modal tripulantes-->

         <div class="modal fade" id="form-modal-worker" tabindex="-1" role="dialog" aria-labelledby="form-dialog"
               aria-hidden="true" v-if="showModal" data-backdrop="static" data-keyboard="false">
             
        


     <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <!-- header -->
            <div class="modal-header align-items-center">
                <h5 class="modal-title">Añadir Tripulantes</h5>

                <!--<form class="ml-auto w-50" @submit.prevent="getWorkers">
                    <input type="text" v-model="searchInputWorker" class="form-control"
                           placeholder="Ingrese nombre, apellido o rut de persona">
                </form>-->

                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <!-- body -->
            <div class="modal-body container">
        <div class="table-responsive p-0">
<v-card >
    <v-card-title>
      <v-text-field
        v-model="searchWorkers"
        append-icon="mdi-magnify"
        label="Búsqueda"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
  <v-data-table 
      :headers="headers"
      :items="datosWorkers"
      :search="searchWorkers"
       :loading="loading"
       :items-per-page="2"
       no-results-text="No se han encontrado tripulantes"
       no-data-text="No existen tripulantes"
      class="elevation-1"
        loading-text="Cargando... Espere"
         :footer-props="{
           'items-per-page-text':'Tripulantes por página',
           'items-per-page-options': [10,15,20]
      }"
    >
     <template v-slot:item.accion="{ item, index }">
           <input type="checkbox" v-model="selectedWorkers" :value="item.id"
                                           @change="toggleSelectionWorkers(item.id)">      
  </template>
  <template v-slot:footer.page-text="items"> {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }} </template>
</v-data-table>
</v-card>
                <!-- tabla -->
                <!--

                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Nombre</th>
                                 <th>Apellido</th>
                                <th>Rut</th>
                            </tr>
                        </thead>
                        <tbody>
                    
                            <tr v-for="w in workers.data">
                                <td class="align-middle">
                                    <input type="checkbox" v-model="selectedWorkers" :value="w.id"
                                           @change="toggleSelectionWorkers(w.id)">
                                </td>
                                <td class="align-middle">
                                    <span>
                                        {{ w.name }} 
                                    </span>
                                </td>
                                 <td class="align-middle">
                                    <span>
                                       {{ w.last_name }}
                                    </span>
                                </td>
                                <td class="align-middle">{{ w.rut }}</td>
                            </tr>
                        </tbody>
                    </table>

                 <pagination :data="workers" @pagination-change-page="getWorkers"/>-->
                </div>

                <!-- selección -->
                <div class="tag-input" v-if="selectedDataWorkers.length > 0">
                    <div v-for="s in selectedDataWorkers" class="tag-input__tag">
                        <span @click="unselectWorkers(s.id)">x</span>
                        {{ s.name }} {{s.last_name}}
                    </div>
                </div>
                <span v-else><i>Aún no se han escogido tripulantes</i></span>

                <!-- footer -->
                <div class="modal-footer">
                   
                    <button type="button" class="btn btn-primary" data-dismiss="modal" style="color:#ffffff !important;">
                        Aceptar
                    </button>
                </div>
            </div>
        </div>
    </div>
      </div>
        <!--fin modal tripulantes--->

        
    </div>
       </v-app>
</template>

<script>
    import Loading from "./Common/Loading";
    import moment from "moment";

    export default {
        components: { Loading },
        created () {
          this.$Progress.start();
            this.getAirlines();
            this.getAirports();
            this.getFlightStretches();
            this.$Progress.finish();
           // this.form.induction_date = moment(new Date()).format('yyyy-MM-dd');
        },
        data () {
            return {
                loading: false,
                submit: false,
                suppliers:[],
                persons:{},
                many:0,
                workers:{},
                selected: [],
                selectedWorkers: [],
                selectedData: [],
                selectedDataWorkers: [],
                searchInput:'',
                searchInputWorker:'',
                showModal: false,
                airlines:{},
                airports:{},
                nostock:0,
                flightStretches:{},
                stocks:{},
                vouchers:{},
                novoucher:0,
                final_total_price:0,
                form: new Form({
                    purchase_order:null,
                    purchase_order_date:null,
                    id: null,
                    flight_stretch_id: null,
                    flight_date: null,
                    departure_time: null,
                    arrival_time: null,
                    departure_airport_id: null,
                    arrival_airport_id: null,
                    extra_price:0,
                    carry_price:0,
                    price: 0,
                    airline_id: null,
                    stock_ticket_id: null,
                    tarifa_plus:0,
                    selected:[],
                     selectedWorkers:[],
                    isedit:0,
                    stock_id:null,
                    voucher_id:null,
                    expiration_date:null,
                    passengers:0,
                    total_price:0,
                    total_extra_price:0,
                    payment_price:0,
                    real_total_price:0,
                }),
                searchWorkers:'',
                searchPersons:'',
                headers: [
                { text: 'Seleccionar', value: 'accion', sortable: false },
                { text: 'Nombre', align: 'start', value: 'nombre' },
                { text: 'Apellido', value: 'apellido' },
                { text: 'Rut', value: 'rut' },
                ],
                datosWorkers:[],
                datosPersons:[], 
            }
        },
        watch: {
            'form.airline_id' () {
                if (this.form.airline_id) {
                    this.form.stock_ticket_id = null;
                    this.getVouchers(this.form.airline_id);
                    this.getStocks(this.form.airline_id);
                    this.many = this.selectedDataWorkers.length + this.selectedData.length;
                    if (this.many==1 || this.many==0){
                        this.nostock = 0;
                    } else{
                        if(this.form.stock_ticket_id==1){
                            this.form.stock_ticket_id = null;
                        }
                        this.nostock = 1;
                    }

                    this.calculate();
                }
            },
           'selectedDataWorkers' () {
             this.many = this.selectedDataWorkers.length + this.selectedData.length;
                if (this.many==1 || this.many==0){
                    //this.form.stock_ticket_id = null;
                    this.nostock = 0;
                } else{
                    if(this.form.stock_ticket_id==1){
                        this.form.stock_ticket_id = null;
                    }
                    this.nostock = 1;
                }

                this.calculate();
            },
            'selectedData' () {
                 this.many = this.selectedDataWorkers.length + this.selectedData.length;
                if (this.many==1 || this.many==0){
                    //this.form.stock_ticket_id = null;
                    this.nostock = 0;
                } else{
                    if(this.form.stock_ticket_id==1){
                        this.form.stock_ticket_id = null;
                    }
                    this.nostock = 1;
                }

               this.calculate();
            },
             'form.voucher_id' () {
                 
                     this.calculate();
                
                
            },
            'form.stock_id' () {
               
                     this.calculate();
               
                
            },
            'form.stock_ticket_id' () {
                this.calculate();
            },
            'form.price' () {
                this.calculate();
            },
             'stocks' () {
              if(this.stocks.length==0){
                  this.nostock=1;
                  this.form.stock_ticket_id = null;
              }else{
                  this.many = this.selectedDataWorkers.length + this.selectedData.length;
                    if (this.many==1 || this.many==0){
                        this.nostock = 0;
                    } else{
                        if(this.form.stock_ticket_id==1){
                            this.form.stock_ticket_id = null;
                        }
                        this.nostock = 1;
                    }
                  this.calculate();
              }
            },
             'vouchers' () {
              if(this.vouchers.length==0){
                  this.novoucher=1;
                  this.form.stock_ticket_id = null;
              }else{
                    this.novoucher=0;
                    this.calculate();
              }
            },
            
            
        },
        methods: {
            changePenalty(){
                this.calculate();
            },
            calculate(){      
                
                    let real_total = 0;

                    this.selectedDataWorkers.forEach(d => {
                                  //  console.log(d.penalty);
                                    if(d.penalty!==null && d.penalty!==''){
                                       real_total = parseInt(real_total) + parseInt(d.penalty);
                                    }
                                    
                    });

                    this.selectedData.forEach(d => {
                                   // console.log(d.penalty);
                                    if(d.penalty!==null && d.penalty!==''){
                                       real_total = parseInt(real_total) + parseInt(d.penalty);
                                    }
                                    
                    });


                    this.many = this.selectedDataWorkers.length + this.selectedData.length;

                      if(this.form.voucher_id==null && this.form.stock_id==null){
                        let diff = 0;
                        this.form.extra_price =  parseInt(real_total);
                        this.final_total_price = parseInt(real_total) + (parseInt(this.form.price) * parseInt(this.many));
                        this.form.real_total_price = parseInt(real_total) + (parseInt(this.form.price) * parseInt(this.many)) ;
                        this.form.payment_price = 0;
                        console.log(3);
                        console.log(this.final_total_price+"//" +diff+"//"+this.many+"//"+real_total+"//"+this.form.extra_price+"//"+this.form.price);
                    }



                     if(this.form.stock_ticket_id==2 && this.vouchers.length>0){
                         let diff = 0;
                         if(this.form.voucher_id!=null){
                                let w = this.vouchers.find(w => w.id === this.form.voucher_id);
                                if(this.many>0){
                                 diff = (parseInt(this.form.price) * parseInt(this.many)) + parseInt(real_total);
                                this.form.payment_price = w.price;
                                if(diff>w.price){
                                    this.form.extra_price = (w.price-diff) *-1;
                                    this.final_total_price = diff;
                                    this.form.real_total_price = parseInt(this.form.extra_price);
                                    console.log(1.1);
                                    console.log(this.final_total_price+" //"+w.price+"//"+diff+"//"+this.many+"//"+real_total+"//"+this.form.extra_price+"//"+this.form.price);
                       
                              }else{
                                    this.form.extra_price = 0;
                                    this.final_total_price = diff;
                                    this.form.real_total_price = 0;
                                    console.log(1.2);
                                    console.log(this.final_total_price+" //"+w.price+"//"+diff+"//"+this.many+"//"+real_total+"//"+this.form.extra_price+"//"+this.form.price);
                       
                                }
                                } 
                                
                           }  
                    }

                    if(this.form.stock_ticket_id==1 && this.stocks.length>0){
                        let diff = 0;
                        if(this.form.stock_id!=null){                         
                                let w = this.stocks.find(w => w.id === this.form.stock_id);
                                if(this.many>0){
                                 diff = (parseInt(this.form.price) * parseInt(this.many)) + parseInt(real_total);
                                this.form.payment_price = w.price;
                                if(diff>w.price){
                                    this.form.extra_price = (w.price-diff) *-1;
                                    this.final_total_price = parseInt(this.form.extra_price) + (parseInt(this.form.price) * parseInt(this.many));
                                    this.form.real_total_price = parseInt(this.form.extra_price);
                                }else{
                                    this.form.extra_price = 0;
                                   // this.final_total_price = 0;

                                    this.final_total_price = diff;
                                    this.form.real_total_price = 0;

                                   // this.form.real_total_price = parseInt(this.form.extra_price) + parseInt(real_total) + (parseInt(this.form.price) * parseInt(this.many)) ;
                                }
                                } 
                                           console.log(2);
                         console.log(this.final_total_price+" //"+w.price+"//"+diff+"//"+this.many+"//"+real_total+"//"+this.form.extra_price+"//"+this.form.price);
                     
                         }             
                    }

                    if(this.form.stock_ticket_id==null){
                        let diff = 0;
                        this.form.extra_price =  parseInt(real_total);
                        this.final_total_price = parseInt(real_total) + (parseInt(this.form.price) * parseInt(this.many));
                        this.form.real_total_price = parseInt(real_total) + (parseInt(this.form.price) * parseInt(this.many)) ;
                        this.form.payment_price = 0;
                        console.log(3);
                        console.log(this.final_total_price+"//" +diff+"//"+this.many+"//"+real_total+"//"+this.form.extra_price+"//"+this.form.price);
                    }

        

               

            },
             unselect (id) {
                this.selected.splice(this.selected.findIndex(s => s === id), 1);
                this.selectedData.splice(this.selectedData.findIndex(s => s.id === id), 1);
                this.calculate();
            },
             unselectWorkers (id) {
                this.selectedWorkers.splice(this.selectedWorkers.findIndex(s => s === id), 1);
                this.selectedDataWorkers.splice(this.selectedDataWorkers.findIndex(s => s.id === id), 1);
                 this.calculate();
            },
            toggleSelectionWorkers (id) {
                if (this.selectedWorkers.includes(id)) {
                    let w = this.workers.data.find(w => w.id === id);

                    this.selectedDataWorkers.push({
                        id: id,
                        rut: w.rut,
                        last_name: this.$options.filters.capitalize(w.last_name),
                        name: this.$options.filters.capitalize(w.name),
                        carry_price:0,
                        quotation: false,
                        penalty_quotation: false,
                        observation:'',
                        penalty:0,
                        extra_amount:0
                    });
                    
                } else {
                    this.selectedDataWorkers.splice(this.selectedDataWorkers.findIndex(s => s.id === id), 1);
                }
            },
            toggleSelection (id) {
                if (this.selected.includes(id)) {
                    let w = this.persons.data.find(w => w.id === id);

                    this.selectedData.push({
                        id: id,
                        rut: w.rut,
                        last_name: this.$options.filters.capitalize(w.last_name),
                        name: this.$options.filters.capitalize(w.name),
                        carry_price: 0,
                        quotation: false,
                        penalty_quotation: false,
                        observation:'',
                        penalty:0,
                        extra_amount:0
                    });
                     
                } else {
                    this.selectedData.splice(this.selectedData.findIndex(s => s.id === id), 1);
                }
            },
             workerModal () {
                this.getWorkers();
                this.showModal = true;
                this.$nextTick(() => $('#form-modal-worker').modal('show'));
            },
            personModal () {
                this.getPersons();
                this.showModal = true;
                this.$nextTick(() => $('#form-modal-person').modal('show'));
            },
            getWorkers (page = 1) {
                this.$Progress.start();
                this.loading = true;

                axios.get('/api/administracion/workers')
                    //.then(({ data }) => this.workers = data.data)
                     .then(data => {
                    this.datosWorkers = [];
                    this.workers = data.data;
                     console.log(this.workers);
                        for(let i = 0; i < this.workers.data.length; i++) {
                            var t = this.workers.data[i];
                            var nombre = this.$options.filters.capitalize(t.name);  
                            var apellido = this.$options.filters.capitalize(t.last_name);  
                            this.datosWorkers.push({nombre:nombre,apellido:apellido,rut:t.rut,id:t.id,accion:true});
                        }
       
                       console.log(this.datosWorkers);
                       this.$Progress.finish();
                     })
                    .catch(() => this.$launchError('Error al obtener listado de tripulantes'))
                    .finally(() => {
                        this.$Progress.finish();
                        this.loading = false;
                    });
                   
            },
              setTicket(){
                  if(this.many==0){
                      this.$launchError('No puede guardar si no ha agregado personas o tripulantes');
                  }else{
                        if((this.form.stock_ticket_id==2 && this.form.voucher_id ==null) || (this.form.stock_ticket_id==1 &&  this.form.stock_id==null)){
                            this.$launchError('Error, debe elegir Pasaje en Stock o Voucher a utilizar');
                        }else{
                            Swal.fire({
                            title: '¿Estás seguro que deseas guardar este pasaje?',
                            text: 'No podrás revertir esta acción',
                            showCancelButton: true,
                            confirmButtonColor: '#d33',
                            cancelButtonColor: '#3085d6',
                            confirmButtonText: 'Si',
                            cancelButtonText: 'No'
                        }).then((result) => {
                            if (result.value) {
                                if(this.form.stock_ticket_id==1){
                                    this.form.voucher_id = null;
                                }
                                if(this.form.stock_ticket_id==2){
                                    this.form.stock_id = null;
                                }
                                if(this.form.stock_ticket_id==null){
                                    this.form.voucher_id = null;
                                    this.form.stock_id = null;
                                }
                                this.form.id = this.person_id;
                                this.form.have_stock = this.have_stock;
                                this.form.selected = this.selectedData;
                                this.form.selectedWorkers = this.selectedDataWorkers;
                                this.form.passengers = this.many;
                            // this.form.total_price = (this.many * this.form.price);
                            //this.form.total_extra_price = (this.form.extra_price/this.many);

                                this.form.total_price = this.final_total_price;
                                this.form.total_extra_price = (this.form.extra_price/this.many);

                        
                            //  console.log(this.form);
                                this.form.post('/api/administracion/personas/nuevo/pasaje')
                                .then(() => {
                                    //this.persons = [];
                                    this.selectedData= [];
                                    this.selected = [];
                                    //this.workers = [];
                                    this.selectedDataWorkers= [];
                                    this.selectedWorkers = [];
                                    this.form.reset();
                                    this.$launchSuccess('Se guardo el pasaje correctamente');
                                    this.$Progress.finish();
                                })
                                .catch(() => {
                                    this.$Progress.fail();
                                    this.$launchError('Error al guardar pasaje');
                                });
                                }
                            });
                        }   
                  }
                  
            },
            getPersons (page = 1) {
                this.$Progress.start();
                this.loading = true;

                axios.get('/api/administracion/personas')
                    //.then(({ data }) => this.persons = data.data)
                    .then(data => {
                    this.datosPersons = [];
                    this.persons = data.data;
                     console.log(this.persons);
                        for(let i = 0; i < this.persons.data.length; i++) {
                            var t = this.persons.data[i];
                            var nombre = this.$options.filters.capitalize(t.name);  
                            var apellido = this.$options.filters.capitalize(t.last_name);  
                            this.datosPersons.push({nombre:nombre,apellido:apellido,rut:t.rut,id:t.id,accion:true});
                        }
       
                       console.log(this.datosPersons);
                       this.$Progress.finish();
                     })
                    .catch(() => this.$launchError('Error al obtener listado de personas'))
                    .finally(() => {
                        this.$Progress.finish();
                        this.loading = false;
                    });
                   
            },
            getAirlines () {
                axios.get('/api/logistica/aerolineas')
                    .then(({ data }) => this.airlines = data.data)
                    .catch(() => this.$launchError('Error al obtener aerolíneas'));
            },

            getAirports () {
                axios.get('/api/logistica/aeropuertos')
                    .then(({ data }) => this.airports = data.data)
                    .catch(() => this.$launchError('Error al obtener aeropuertos'));
            },

            getFlightStretches () {
                axios.get('/api/logistica/tramos')
                    .then(({ data }) => this.flightStretches = data.data)
                    .catch(() => this.$launchError('Error al obtener tramos'));
            },
            setPaymentMode(){
                if(this.form.stock_ticket_id==1){
                    this.getStocks(this.form.airline_id);
                }
                if(this.form.stock_ticket_id==2){
                    this.getVouchers(this.form.airline_id);
                }
            },
             getVouchers (id) {
                axios.get('/api/administracion/pasajes/vouchers/'+id)
                    .then(({ data }) => this.vouchers = data.data)
                    .catch();
            },
            getStocks (id) {
                axios.get('/api/administracion/pasajes/stocks/'+id)
                    .then(({ data }) => this.stocks = data.data)
                    .catch();
            },
        }
    }
</script>
<style scoped>
.tag-input {
    width: 100%;
    border: 1px solid #eee;
    font-size: 0.9em;
    height: 50px;
    box-sizing: border-box;
    padding: 0 10px;
}

.tag-input__tag {
    height: 30px;
    float: left;
    margin-right: 10px;
    background-color: #eee;
    margin-top: 10px;
    line-height: 30px;
    padding: 0 5px;
    border-radius: 5px;
}

.tag-input__tag > span {
    cursor: pointer;
    opacity: 0.75;
}
</style>

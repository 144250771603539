<template>
    <div>
        <!-- breadcrumb -->
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Ficha de Persona</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link to="/">Inicio</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link to="/personas">Personas</router-link>
                            </li>
                            <li class="breadcrumb-item active">Ficha de Persona</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>

        <!-- ficha -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <!-- datos personales -->
                    <div class="col-4">
                        <div class="card">
                            <loading v-if="loading"/>

                            <personal-data/>
                        </div>
                    </div>

                    <!-- resto de la ficha -->
                    <div class="col-8">
                        <div class="card">
                            <loading v-if="loading"/>

                            <div class="card-header">
                                <ul class="nav nav-pills">
                                    <li class="nav-item" v-for="t in tabs">
                                        <a class="nav-link" :class="{'active': tab === t.component}"
                                           @click="tab = t.component">
                                            {{ t.title }}
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div class="card-body">
                                <component :is="tab"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
  //  import Tickets from "./PersonDetails/Contract";
    import Transfers from "./PersonDetails/Transfers";
    import Accommodations from "./PersonDetails/Accommodations";
    import Loading from "./Common/Loading";
    import PersonalData from "./PersonDetails/PersonalData";


    export default {
        components: {
            //Tickets,
            Transfers,
            Accommodations,
            Loading,
            PersonalData
        },
        computed: {
            id () {
                return this.$route.params.id;
            }
        },
        created () {
            this.getPersonDetails();
        },
        data () {
            return {
                loading: true,
                tab: 'Accommodations',
                tabs: [
                    //{ title: 'Pasajes', component: 'Tickets' },
                    { title: 'Traslados', component: 'Transfers' },
                    { title: 'Alojamientos', component: 'Accommodations' }
                ],
                person: {
                    name: null,
                    last_name: null,
                    email: null,
                    rut: null
                }
            }
        },
        methods: {
            getPersonDetails () {
                this.$Progress.start();
                this.loading = true;

                axios.get('/api/administracion/personas/' + this.id)
                    .then(({ data }) => {
                      //  console.log(data.data);
                       // console.log(data);
                        this.person = data.data;
                        this.$Progress.finish();
                    })
                    .catch(() => {
                        this.$launchError('Error al obtener ficha de persona');
                        this.$Progress.fail();
                    })
                    .finally(() => this.loading = false);
            }
        }
    }
</script>

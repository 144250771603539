import { render, staticRenderFns } from "./AddWorker.vue?vue&type=template&id=6fce2344&scoped=true&"
import script from "./AddWorker.vue?vue&type=script&lang=js&"
export * from "./AddWorker.vue?vue&type=script&lang=js&"
import style0 from "./AddWorker.vue?vue&type=style&index=0&id=6fce2344&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fce2344",
  null
  
)

export default component.exports
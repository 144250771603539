<template>
    <form @submit.prevent="editItem">
        <item-form :form="form" />

        <button type="submit" class="btn btn-primary float-right">Generar</button>
    </form>
</template>

<script>
    import ItemForm from "./Form";
    import moment from "moment";

    export default {
        components: { ItemForm },
        created () {
            this.form.d1 = moment(new Date()).format('yyyy')
        },
        data () {
            return {
                form: {
                    report_type: 7,
                    d1:null,
                    d2:null
                },
            }
        },
        methods: {
            
            editItem () {

                    if((this.form.report_type==5 && (this.form.d1==null || this.form.d2==null)) || (this.form.report_type==7 && this.form.d1==null) || (this.form.report_type==8 && this.form.d1==null)){
                        if(this.form.report_type==5)
                             this.$launchError('Debe seleccionar las fechas de inicio y fin');
                        if(this.form.report_type==7)
                             this.$launchError('Debe seleccionar mes y año');
                        if(this.form.report_type==8)
                             this.$launchError('Debe seleccionar año');
                    }else{
                        this.$Progress.start();
                        axios({
                                url: '/api/reports/generate/'+this.form.report_type+'/'+this.form.d1+'/'+this.form.d2,
                                method: 'get',
                                responseType: 'blob',
                            }).then((response) => {
                                this.$Progress.finish();
                                this.$launchSuccess('Informe generado');
                                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                                var fileLink = document.createElement('a');
                                fileLink.href = fileURL;
                                var contentDisposition = response.headers['content-disposition'];
                                var fileName = 'unknown';
                                if (contentDisposition) {
                                    fileName = (contentDisposition|| "").split("filename=")[1];
                                    console.log(contentDisposition);
                                    fileName = fileName.slice(1,-1);
                                }
                                fileLink.setAttribute('download', fileName);
                                document.body.appendChild(fileLink);
                                fileLink.click();
                            }).catch(() => {
                            this.$Progress.fail();
                            this.$launchError('Error al generar informe');
                        });
                    }

                    
            }         
        }
    }
</script>

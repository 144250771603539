<template>
    <form v-if="!loading">
        
        <div class="table-responsive" v-if="!hasRequest || (hasRequest && items.length > 0)">



<div >
<v-app >

          <v-card >
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Búsqueda"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
  <v-data-table 
      :headers="headers"
      :items="datos"
      :search="search"
       :loading="loading"
       :items-per-page="100"
       no-results-text="No se han encontrado articulos"
       no-data-text="No existen articulos"
      class="elevation-1"
        loading-text="Cargando... Espere"
         :footer-props="{
           'items-per-page-text':'Articulos por página',
           'items-per-page-options': [100, 200, 300, -1]
      }"
    >

 <template v-slot:item.size="{ item, index }">
                            
                         
                        <select class="form-control" v-model="warehouseItemsForm[item.id].size_id" v-if="!hasRequest">
                                <option :value="null">Escoja una talla</option>
                                <option :value="s.id" v-for="s in item.sizes">{{ s.name !== '' ? s.name: 'STD' }}</option>
                            </select>
                            <span v-else>{{ item.size !== '' ? item.size: 'STD'}}</span>
                           
     </template>


 <template v-slot:item.quantity="{ item, index }">
                            
                         

                             <input min="0" type="number" class="form-control" v-if="!hasRequest"
                                   v-model.number="warehouseItemsForm[item.id].quantity">
                            <span v-else>
                                {{ item.quantity }}
                            </span>
     </template>

       <template v-slot:item.observations="{ item, index }">
                            
                         

                             <input type="text" class="form-control" v-if="!hasRequest"
                                   v-model="warehouseItemsForm[item.id].observations">
                            <span v-else>
                                {{ item.observations | dash }}
                            </span>
     </template>

  <template v-slot:footer.page-text="items"> {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }} </template>
</v-data-table>
</v-card>



  </v-app>
</div>


 <tr v-for="i in items">
     </tr>
           
           <!--
           <table class="table table-hover" >
                <thead>
                    <tr>
                        <th class="align-middle">Artículo</th>
                        <th class="align-middle">Talla</th>
                        <th class="align-middle">Cantidad</th>
                        <th class="align-middle">Unidad de medida</th>
                        <th class="align-middle">Observaciones</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="i in items">
                        <td class="align-middle">{{ i.name }}</td>
                        <td class="align-middle">
                            <select class="form-control" v-model="warehouseItemsForm[i.id].size_id" v-if="!hasRequest">
                                <option :value="null">Escoja una talla</option>
                                <option :value="s.id" v-for="s in i.sizes">{{ s.name !== '' ? s.name: 'STD' }}</option>
                            </select>
                            <span v-else>{{ i.size !== '' ? i.size: 'STD'}}</span>
                        </td>
                        <td class="align-middle">
                            <input min="0" type="number" class="form-control" v-if="!hasRequest"
                                   v-model.number="warehouseItemsForm[i.id].quantity">
                            <span v-else>
                                {{ i.quantity }}
                            </span>
                        </td>
                        <td class="align-middle">
                            {{ i.measurement_unit.name }}
                        </td>
                        <td class="align-middle">
                            <input type="text" class="form-control" v-if="!hasRequest"
                                   v-model="warehouseItemsForm[i.id].observations">
                            <span v-else>
                                {{ i.observations | dash }}
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
            -->

            
        </div>

        <div v-else>
            <span><i>No se añadieron artículos de bodega a la solicitud de abastecimiento.</i></span>
        </div>
    </form>
</template>

<script>
    export default {
        computed: {
            hasRequest () {
                return this.$parent.hasRequest;
            },
            items () {
                if (this.hasRequest) {
                    var listo = this.supplyRequest.warehouse_items.map(i => {
                        return {
                            name: i.size.item.name,
                            size: i.size.name,
                            measurement_unit: i.size.item.measurement_unit,
                            quantity: i.quantity,
                            observations: i.observations
                        }
                    });
                    this.ready = false;
                    return listo;
                } else {
                    this.ready = true;
                    return this.warehouseItems;
                }
            },
            loading: {
                get () {
                    return this.$parent.loading;
                },
                set (v) {
                    this.$parent.loading = v;
                }
            },
            supplyRequest () {
                //this.ready = false;
                return this.$parent.supplyRequest;
            },
            warehouseItems: {
                get () {
                    return this.$parent.warehouseItems;
                },
                set (v) {
                    this.$parent.warehouseItems = v;
                }
            },
            warehouseItemsForm () {
                return this.$parent.warehouseItemsForm;
            }
        },
         data () {
            return {
                search: '',
                ready:null,
                headers: [                 
                { text: 'Articulo', value: 'articulo'},
                { text: 'Unidad de Medida', value: 'medida' },
                { text: 'Talla', value: 'size' },
                { text: 'Cantidad', value: 'quantity'},
                { text: 'Observacion', value: 'observations' },
                ],
                datos:[], 

            }
        },
         mounted () {
            
        },
         methods: {
             getItems(){

                 //  this.datos.push({articulo:"asddasd",medida:'',talla:'',cantidad:'',observacion:''});
                  //   this.datos.push({articulo:"grtgrg",medida:'',talla:'',cantidad:'',observacion:''});
                  //     this.datos.push({articulo:"fergerg",medida:'',talla:'',cantidad:'',observacion:''});
              //   console.log(this.$parent.supplyRequest);
                        /*for(let i = 0; i < this.items.length; i++) {
                            var t = this.items[i];
                            
                            this.datos.push({articulo:t.name,medida:'',talla:'',cantidad:'',observacion:''});
                        }*/
             }
         },
         watch: {
            ready() {
              console.log(this.items);
              this.datos = [];
              for(let i = 0; i < this.items.length; i++) {
                            var t = this.items[i];                  
                            this.datos.push({id:t.id,articulo:t.name,medida:t.measurement_unit.name ,size:t.size !== '' ? t.size: 'STD',quantity:t.quantity,observations:t.observations,sizes:t.sizes});
             }
            }
        },
    }
</script>
<style>
  .v-application--wrap {
    min-height: 0vh !important;
  }
</style>
<!--
<style lang="scss" scoped>
table.table {
    tr:nth-child(3), td:nth-child(3) {
        width: 3em;
    }
}
</style>-->

<template>
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <!-- header -->
            <div class="modal-header">
                <h5 class="modal-title">Compra de pasaje</h5>
            </div>

            <!-- body -->
            <div class="modal-body container">
                <!-- alerta -->
                <div class="alert alert-warning" role="alert">
                    Una vez registrada la compra del pasaje el tripulante será notificado, por lo cual esta acción <strong>no podrá ser revertida</strong>.
                </div>

                <!-- formulario -->
                <div class="row">
                    <div class="col form-group">
                        <label>Multa</label>
                        <input min="0" type="number" class="form-control" v-model.number="form.penalty">
                    </div>
                    <div class="col form-group">
                        <label>Afecto</label>
                        <input min="0" type="number" class="form-control" v-model.number="form.dearness">
                    </div>
                    <div class="col form-group">
                        <label>Total</label>
                        <input min="0" type="number" class="form-control" v-model="totalPrice" readonly>
                    </div>
                </div>

                <div class="row">
                    <div class="col form-group">
                        <label>Código de vuelo</label>
                        <input type="text" class="form-control" v-model="form.flight_code">
                    </div>
                </div>
            </div>

            <!-- footer -->
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                <button type="button" class="btn btn-primary" @click="saveTicket">Guardar</button>
            </div>
        </div>
    </div>
</template>

<script>
    import eventHub from "../../../eventHub";

    export default {
        computed: {
            ticketPrice () {
                return this.$parent.ticket.price;
            },
            totalPrice () {
                return this.ticketPrice + this.form.dearness + this.form.penalty;
            }
        },
        data () {
            return {
                form: new Form({
                    dearness: 0,
                    flight_code: null,
                    penalty: 0
                })
            }
        },
        methods: {
            saveTicket () {
                this.$Progress.start();

                this.form.post(`/api/logistica/pasajes/${this.$parent.selectedId}/comprar`)
                    .then(() => {
                        this.$Progress.finish();
                        this.form.reset();
                        this.$launchSuccess('Éxito al comprar pasaje');
                        eventHub.$emit('ticketBought');
                    })
                    .catch(() => {
                        this.$Progress.fail();
                        this.$launchError('Error al comprar pasaje');
                    });
            }
        }
    }
</script>

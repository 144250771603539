<template>
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <!-- header -->
            <div class="modal-header">
                <h5 class="modal-title">{{ title }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <!-- body -->
            <div class="modal-body container">
                <!-- alerta -->
            
                <!-- tabla -->
                
                <div class="table-responsive p-0">

                     
                              <table class="table table-hover" v-if="$parent.quotations!=null && $parent.quotations.length > 0">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Código</th>
                                                    <th>Cuenta Bancaria</th>
                                                    <th>Nº Factura</th>
                                                    <th>Orden de Compra</th>
                                                     <th>Fecha Orden de Compra</th>
                                                    <th>Proveedor</th>
                                                    <th>Tipo Proveedor</th>
                                             
                                                </tr>
                                            </thead>
                                            <tbody>

                                        
                                            
                                                <tr v-for="(t, i) in $parent.quotations" v-if="supplierId==t.supplier_id">
                                                  
                                                    <td>
                                                        <input :value="t.invoice_id" type="radio" v-model="onlyOneSelected" >
                                                   </td>
                                                    <td>FB{{ t.invoice_id }}</td>
                                                    <td>{{ t.account_number }} - {{ t.account_name }}</td>
                                                    <td>{{ t.invoice_number }}</td>
                                                    <td>{{ t.purchase_order }}</td>
                                                     <td>{{ t.purchase_order_date }}</td>
                                                    <td>{{ t.supplier }}</td>
                                                    <td>{{ t.supplier_type }}</td>
                                                </tr>
                                            </tbody>
                                        </table>



                    <p class="text-center mb-0" v-else>
                        <i>No hay facturas disponibles para asociar.</i>
                    </p>
                </div>
            </div>

            <!-- footer -->
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                <button type="button" class="btn btn-primary" @click="save">
                    Guardar
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import eventHub from "../../eventHub";

    export default {
        props: ['associateId', 'onlyOne','quotationId','associateSelectedId','supplierId','selectedName','selectedPurchase','selectedType'],
        computed: {
            title () {
                return 'Asociar Factura a '+this.selectedName;
            }
        },
        created () {
           // this.getWorkers();
           //this.supplier_id = this.transfer_supplier_id;
         
           this.fillForm();
            this.onlyOneSelected = this.associateSelectedId;
          
        },
        destroyed() {
          //  eventHub.$off('cleanSnackForm');
        },
        data () {
            return {
                supplier_id:null,
                onlyOneSelected: null,
                form: {}
            }
        },
        methods: {
            fillForm () {
                this.$parent.quotations.forEach(w => { 

                    this.form[w.index] = { 
                    id: w.index,
                    selected: false,
                    enabled: true
                }
     
                });
            },
            save(){

                console.log(this.onlyOneSelected);

               
                     axios.post(`/api/administracion/facturacion/compra-tripulacion/asociar-factura`, {
                        invoice_id: this.onlyOneSelected,
                        article_id:this.associateId,
                         purchase_id:this.selectedPurchase,
                         type:this.selectedType
                    })
                        .then(() => {
                            eventHub.$emit('reloadArticles');
                            this.$launchSuccess(`Éxito al asociar factura`);
                        })
                        .catch(() => this.$launchError(`Error al asociar factura`));   
            },

        }
    }
</script>

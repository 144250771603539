<template>
    <div>
        <!-- breadcrumb -->
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Nuevo Pasaje en Stock</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link to="/">Inicio</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link to="/">administracion</router-link>
                            </li>
                            <li class="breadcrumb-item active">Nuevo Pasaje en Stock</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>

        <!-- creación -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col">
                        <div class="card">
                            <loading v-if="loading"/>

                            <div class="card-body">
                                <!-- formulario -->
                                

                                <form >
                                    <div class="row">
                                   
                                        <div class="col form-group">
                                             <label>Aerolínea</label>
                                            <select name="aerolínea" class="form-control" v-model="form.airline_id"
                                                    :class="{ 'is-invalid': form.errors.has('airline_id') }">
                                                <option :value="null" disabled>Escoja una aerolínea</option>
                                                <option :value="a.id" v-for="a in airlines">{{ a.name }}</option>
                                            </select>
                                            <has-error :form="form" field="airline_id"/>
                                        </div>
                                 

                                
                                       <div class="col form-group">
                                            <label>Tarifa unitaria</label>
                                            <input v-model="form.price" type="number" min="0" name="tarifa" class="form-control"
                                                :class="{ 'is-invalid': form.errors.has('price') }" >
                                            <has-error :form="form" field="price"/>
                                        </div>
                                 

                                    
                                      <!--  <div class="col form-group">
                                            <label>Tarifa Plus</label>
                                            <select name="plus" class="form-control" v-model="form.tarifa_plus"
                                                    :class="{ 'is-invalid': form.errors.has('tarifa_plus') }">
                                                <option :value="0">No</option>
                                                <option :value="1">Si</option>     
                                            </select>
                                            <has-error :form="form" field="tarifa_plus"/>
                                        </div>-->
                                   

                                    
                                         <div class="col form-group">
                                            <label>Diferencial Tarifa Total</label>
                                            <input v-model="form.extra_price" type="number" min="0" name="extra" class="form-control"
                                                >
                                            <has-error :form="form" field="extra"/>
                                        </div>
                                   

                                   
                                         <div class="col form-group">
                                            <label>Monto Maleta</label>
                                            <input v-model="form.carry_price" type="number" min="0" name="carry" class="form-control"
                                                disabled>
                                            <has-error :form="form" field="carry"/>
                                        </div>
                                 

                                   
                                         <div class="col form-group">
                                            <label>Tramo</label>
                                            <select name="tramo" class="form-control" v-model="form.flight_stretch_id"
                                                    :class="{ 'is-invalid': form.errors.has('flight_stretch_id') }">
                                                <option :value="null" disabled>Escoja un tramo</option>
                                                <option :value="f.id" v-for="f in flightStretches">{{ f.name }}</option>
                                            </select>
                                            <has-error :form="form" field="flight_stretch_id"/>
                                        </div>
                                
                                    </div>
                                    
                                    <div class="row">

                                         
                                         <div class="col form-group">
                                             <label>Fecha de vuelo</label>
                                            <input v-model="form.flight_date" type="date" name="fecha de vuelo" class="form-control"
                                                :class="{ 'is-invalid': form.errors.has('flight_date') }">
                                            <has-error :form="form" field="flight_date"/>
                                        </div>
                                    

                                    
                                         <div class="col form-group">
                                              <label>Hora de partida</label>
                                                <input v-model="form.departure_time" type="time" name="hora de partida" class="form-control"
                                                    :class="{ 'is-invalid': form.errors.has('departure_time') }">
                                                <has-error :form="form" field="departure_time"/>
                                        </div>
                                   


                                      
                                         <div class="col form-group">
                                              <label>Hora de llegada</label>
                                                <input v-model="form.arrival_time" type="time" name="hora de llegada" class="form-control"
                                                    :class="{ 'is-invalid': form.errors.has('arrival_time') }">
                                                <has-error :form="form" field="arrival_time"/>
                                        </div>
                                  


                                 
                                         <div class="col form-group">
                                              <label>Aeropuerto de origen</label>
                                                <select name="aeropuerto de origen" class="form-control" v-model="form.departure_airport_id"
                                                        :class="{ 'is-invalid': form.errors.has('departure_airport_id') }">
                                                    <option :value="null" disabled>Escoja un aeropuerto</option>
                                                    <option :value="a.id" v-for="a in airports">{{ a.iata }} - {{ a.city }}</option>
                                                </select>
                                                <has-error :form="form" field="departure_airport_id"/>
                                        </div>
                                   

                            
                                         <div class="col form-group">
                                              <label>Aeropuerto de destino</label>
                                                <select name="aeropuerto de destino" class="form-control" v-model="form.arrival_airport_id"
                                                        :class="{ 'is-invalid': form.errors.has('arrival_airport_id') }">
                                                    <option :value="null" disabled>Escoja un aeropuerto</option>
                                                    <option :value="a.id" v-for="a in airports">{{ a.iata }} - {{ a.city }}</option>
                                                </select>
                                                <has-error :form="form" field="arrival_airport_id"/>
                                        </div>
                                   

                             
                                   

                                        
                                    
                                
                                  </div>
                                  <div class="row">
                                   
                                      <div class="col form-group">
                                            <label>Codigo de Vuelo</label>
                                            <input v-model="form.code" type="text" min="0" name="code" class="form-control"
                                             >
                                            <has-error :form="form" field="carry"/>
                                        </div>
                                  <!-- <div class="col form-group">
                                            <label>Multa</label>
                                            <input v-model="form.penalty" type="number" min="0" name="penalty" v-on:change="changePenalty()" v-on:keyup="changePenalty()" class="form-control"
                                              >
                                            <has-error :form="form" field="carry"/>
                                        </div>-->
                                 
                                    <div class="col form-group">
                                            <label>Afecto</label>
                                            <input v-model="form.dearness" type="number" min="0" name="dearness" v-on:change="changePenalty()" v-on:keyup="changePenalty()" class="form-control"
                                                >
                                            <has-error :form="form" field="carry"/>
                                        </div>
                                        
                                            <div class="col form-group">
                                        <label>Orden de compra</label>
                                        <input type="text" v-model="form.purchase_order" class="form-control"
                                               :class="{ 'is-invalid': form.errors.has('purchase_order') }">
                                        <has-error :form="form" field="purchase_order"/>
                                    </div>

                                    <div class="col form-group">
                                        <label>Fecha Orden de compra</label>
                                        <input type="date" v-model="form.purchase_order_date" class="form-control"
                                               :class="{ 'is-invalid': form.errors.has('purchase_order_date') }">
                                        <has-error :form="form" field="purchase_order_date"/>
                                    </div>

                                 
                                  </div>

                                </form>
              



                               

                                 <div class="col mt-4">
                                        <button type="button" class="btn btn-success float-right" style="color:#ffffff !important;" @click="setTicket()">
                                            Guardar Stock
                                        </button>
                                    </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>





        
    </div>
</template>

<script>
    import Loading from "./Common/Loading";
    import moment from "moment";

    export default {
        components: { Loading },
        created () {
          this.$Progress.start();
            this.getAirlines();
            this.getAirports();
            this.getFlightStretches();
            this.$Progress.finish();
           // this.form.induction_date = moment(new Date()).format('yyyy-MM-dd');
        },
        data () {
            return {
                loading: false,
                submit: false,
                searchInput:'',
                showModal: false,
                airlines:{},
                airports:{},
                flightStretches:{},
                stocks:{},
                form: new Form({
                    purchase_order:null,
                    purchase_order_date:null,
                    id: null,
                    flight_stretch_id: null,
                    flight_date: null,
                    departure_time: null,
                    arrival_time: null,
                    departure_airport_id: null,
                    arrival_airport_id: null,
                    extra_price:0,
                    carry_price:0,
                    price: 0,
                    airline_id: null,
                    stock_ticket_id: null,
                    tarifa_plus:0,
                    selected:[],
                    isedit:0,
                    penalty:null,
                    dearness:null,
                    code:null,
                    payment_price:0
                }),
            }
        },
        methods: {
            changePenalty(){
                this.calculate();
            },
             calculate(){      
                

                    let real_total = 0;

                

                    if(this.form.penalty!==null && this.form.penalty!==''){
                        real_total = parseFloat(real_total) + parseFloat(this.form.penalty);
                    }
                    if(this.form.dearness!==null && this.form.dearness!==''){
                        real_total = parseFloat(real_total) + parseFloat(this.form.dearness);
                    }


                    

                 


                  
                        this.form.extra_price = parseInt(real_total);
                   
               
                      
               
              
            },
              setTicket(){

                Swal.fire({
                    title: '¿Estás seguro que deseas guardar este pasaje?',
                    text: 'No podrás revertir esta acción',
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Si',
                    cancelButtonText: 'No'
                }).then((result) => {
                    if (result.value) {
                        this.form.post('/api/administracion/personas/pasajes/nuevo-a-stock')
                        .then(() => {
                            this.form.reset();
                            this.$launchSuccess('Se guardo el pasaje correctamente');
                            this.$Progress.finish();
                        })
                        .catch(() => {
                            this.$Progress.fail();
                            this.$launchError('Error al guardar pasaje');
                        });
                        }
                });
                
                
            },

            getAirlines () {
                axios.get('/api/logistica/aerolineas')
                    .then(({ data }) => this.airlines = data.data)
                    .catch(() => this.$launchError('Error al obtener aerolíneas'));
            },

            getAirports () {
                axios.get('/api/logistica/aeropuertos')
                    .then(({ data }) => this.airports = data.data)
                    .catch(() => this.$launchError('Error al obtener aeropuertos'));
            },

            getFlightStretches () {
                axios.get('/api/logistica/tramos')
                    .then(({ data }) => this.flightStretches = data.data)
                    .catch(() => this.$launchError('Error al obtener tramos'));
            },
        }
    }
</script>

<template>
    <div>
        <!-- breadcrumb -->
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Ficha de usuario</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link to="/">Inicio</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link to="/admin/usuarios">Usuarios</router-link>
                            </li>
                            <li class="breadcrumb-item active">Ficha usuario</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>

        <!-- ficha -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <!-- profile -->
                    <div class="col-4">
                        <div class="card card-primary sticky-top">
                            <loading v-if="loading"/>

                            <div class="card-body box-profile">
                                <div class="text-center" v-if="user.avatar">
                                    <img class="profile-user-img img-fluid img-circle"
                                         :src="downloadFileUrl(`user-avatar/${user.id}/${user.avatar}`)">
                                </div>

                                <h3 class="profile-username text-center">
                                    {{ user.name }} {{ user.last_name }}
                                </h3>

                                <p class="text-muted text-center">{{ user.rut }}</p>

                                <ul class="list-group list-group-unbordered mb-3">
                                    <li class="list-group-item">
                                        <b>Código</b> <a class="float-right">{{ user.code }}</a>
                                    </li>

                                    <li class="list-group-item">
                                        <b>Cargo</b>
                                        <a class="float-right">{{ user.position.name }}</a>
                                    </li>

                                    <li class="list-group-item">
                                        <b>Fecha nacimiento</b>
                                        <a class="float-right">
                                            {{ user.birthdate | myDate}}<i>({{ user.birthdate | age }} años)</i>
                                        </a>
                                    </li>

                                    <li class="list-group-item">
                                        <b>Contacto</b> <a class="float-right">{{ user.phone_number }}</a>
                                    </li>

                                    <li class="list-group-item">
                                        <b>Dirección</b> <a class="float-right">{{ user.address }}</a>
                                    </li>

                                    <li class="list-group-item">
                                        <b>Comuna</b> <a class="float-right">{{ user.commune.name }}</a>
                                    </li>

                                    <li class="list-group-item">
                                        <b>Región</b> <a class="float-right">{{ user.commune.region.name }}</a>
                                    </li>

                                    <li class="list-group-item">
                                        <b>Fecha registro</b>
                                        <a class="float-right">{{ user.created_at | myDate }}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <!-- content -->
                    <div class="col">
                        <div class="card">
                            <loading v-if="loading"/>

                            <div class="card-body">
                                <!-- contrato -->
                                <h4>Contrato</h4>

                                <ul v-if="user.contract">
                                    <li>
                                        <strong>Tipo :</strong>
                                        {{ user.contract.contract_type.name | capitalize }}.
                                    </li>
                                    <li>
                                        <strong>Fecha contrato :</strong>
                                        {{ user.contract.date | myDate }}.
                                    </li>
                                    <li>
                                        <strong>Antigüedad :</strong>
                                        {{ user.contract.date | antiquity }} años.
                                    </li>
                                    <li>
                                        <strong>AFP :</strong>
                                        {{ user.contract.afp.name | capitalize }}.
                                    </li>
                                    <li>
                                        <strong>Previsión de salud :</strong>
                                        {{ user.contract.health_forecast.name | capitalize }}.
                                    </li>
                                    <li>
                                        <strong>Copia de carnet :</strong>
                                        <a :href="`/storage/user-identity-card-copies/${user.contract.identity_card_copy}`"
                                           target="_blank">
                                            Descargar <i class="fas fa-file-download"></i>.
                                        </a>
                                    </li>
                                </ul>
                                <p v-else>
                                    <i>Este usuario aún no tiene contrato.</i>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Loading from "./Common/Loading";

    export default {
        components: { Loading },
        created() {
            this.$Progress.start();
            this.getUserDetails();
            this.$Progress.finish();
        },
        data () {
            return {
                loading: true,
                user: {
                    name: null,
                    last_name: null,
                    code: null,
                    avatar: null,
                    rut: null,
                    birthdate: null,
                    age: null,
                    phone_number: null,
                    created_at: null,
                    position: { name: null },
                    commune: {
                        name: null,
                        region: {
                            name: null
                        }
                    },
                    contract: {
                        contract_type: {},
                        afp: {},
                        health_forecast: {},
                        identity_card_copy: null
                    }
                }
            }
        },
        methods: {
            getUserDetails () {
                axios.get('/api/usuarios/' + this.$route.params.id)
                    .then(({ data }) => this.user = data.data)
                    .catch(() => this.$launchError('Error al obtener ficha de usuario'))
                    .finally(() => this.loading = false);
            }
        }
    }
</script>

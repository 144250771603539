<template>
    <div>
        <div class="row">
            <div class="col form-group">
                <label>Nombres</label>
                <input v-model="form.name" type="text" name="nombres" class="form-control"
                       :class="{ 'is-invalid': form.errors.has('name') }">
                <has-error :form="form" field="name"/>
            </div>

            <div class="col form-group">
                <label>Apellidos</label>
                <input v-model="form.last_name" type="text" name="apellidos"
                       class="form-control" :class="{ 'is-invalid': form.errors.has('last_name') }">
                <has-error :form="form" field="last_name"/>
            </div>
        </div>

        <div class="row">
            <div class="col form-group">
                <label>Código</label>
                <input v-model="form.code" type="text" name="código"
                       class="form-control" :class="{ 'is-invalid': form.errors.has('code') }">
                <has-error :form="form" field="code"/>
            </div>

            <div class="col form-group">
                <label>Rut</label>
                <input v-model="form.rut" type="text" name="rut" placeholder="Sin puntos y con guión" maxlength = "10" minlength="9"
                       class="form-control" :class="{ 'is-invalid': form.errors.has('rut') }">
                <has-error :form="form" field="rut"/>
            </div>
        </div>

        <div class="row">
            <div class="col form-group">
                <label>Fecha nacimiento</label>
                <input v-model="form.birthdate" type="date" name="fecha de nacimiento"
                       class="form-control" :class="{ 'is-invalid': form.errors.has('birthdate') }">
                <has-error :form="form" field="birthdate"/>
            </div>

            <div class="col form-group">
                <label>Contacto</label>
                <input v-model="form.phone_number" type="text" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" maxlength = "9" @keypress="isNumber($event)" name="contacto"
                       class="form-control" :class="{ 'is-invalid': form.errors.has('phone_number') }">
                <has-error :form="form" field="phone_number"/>
            </div>
        </div>

        <div class="row">
            <div class="col form-group">
                <label>Región</label>
                <select v-model="form.region_id" class="form-control" name="región" @change="form.commune_id = null"
                        :class="{ 'is-invalid': form.errors.has('region_id') }">
                    <option :value="null" disabled>Escoja una región</option>
                    <option :value="r.id" v-for="r in regions">{{ r.name | capitalize }}</option>
                </select>
                <has-error :form="form" field="region_id"/>
            </div>

            <div class="col form-group">
                <label>Comuna</label>
                <select v-model="form.commune_id" class="form-control" name="comuna" :disabled="communes.length === 0"
                        :class="{ 'is-invalid': form.errors.has('commune_id') }">
                    <option :value="null" disabled>Escoja una comuna</option>
                    <option :value="c.id" v-for="c in communes">{{ c.name | capitalize }}</option>
                </select>
                <has-error :form="form" field="commune_id"/>
            </div>
        </div>

        <div class="row">
            <div class="col form-group">
                <label>Dirección</label>
                <input v-model="form.address" type="text" name="dirección"
                       class="form-control" :class="{ 'is-invalid': form.errors.has('address') }">
                <has-error :form="form" field="address"/>
            </div>

            <div class="col form-group">
                <label>Estado civil</label>
                <select v-model="form.marital_status_id" class="form-control" name="estado civil"
                        :class="{ 'is-invalid': form.errors.has('marital_status_id') }">
                    <option :value="null" disabled>Escoja un estado civil</option>
                    <option :value="m.id" v-for="m in maritalStatuses">{{ m.name | capitalize }}</option>
                </select>
                <has-error :form="form" field="marital_status_id"/>
            </div>
        </div>

        <div class="row">
            <div class="col form-group">
                <label>Atributo{{ isEdition ? '' : ' inicial' }}</label>
                <select v-model="form.attribute_id" class="form-control" name="atributo" :class="{ 'is-invalid': form.errors.has('attribute_id') }">
                    <option :value="null" disabled>Sin atributo{{ isEdition ? '' : ' inicial' }}</option>
                    <option :value="a.id" v-for="a in attributes">{{ a.name | capitalize }}</option>
                </select>
                 <has-error :form="form" field="attribute_id"/>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            isEdition: {
                type: Boolean,
                default: false
            },
            form: Object
        },
        created () {
            this.$Progress.start();
            this.getAttributes();
            this.getRegions();
            this.getMaritalStatuses();
            this.$Progress.finish();
        },
        data () {
            return {
                attributes: [],
                communes: [],
                maritalStatuses: [],
                regions: []
            }
        },
        methods: {
            isNumber: function(evt) {
                evt = (evt) ? evt : window.event;
                var charCode = (evt.which) ? evt.which : evt.keyCode;
                if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                    evt.preventDefault();;
                } else {
                    return true;
                }
            },
            getAttributes () {
                axios.get('/api/atributos')
                    .then(({ data }) => this.attributes = data.data)
                    .catch(() => this.$launchError('Error al obtener listado de atributos'));
            },

            getRegions () {
                axios.get('/api/regiones')
                    .then(({ data }) => this.regions = data.data)
                    .catch(() => this.$launchError('Error al obtener listado de regiones'));
            },

            getMaritalStatuses () {
                axios.get('/api/estados-civiles')
                    .then(({ data }) => this.maritalStatuses = data.data)
                    .catch(() => this.$launchError('Error al obtener listado de estados civiles'));
            },

            getCommunes () {
                axios.get('/api/regiones/' + this.form.region_id + '/comunas')
                    .then(({ data }) => this.communes = data.data.communes)
                    .catch(() => this.$launchError('Error al obtener listado de comunas'));
            }
        },
        watch: {
            'form.region_id' () {
                if (this.form.region_id) this.getCommunes();
            }
        }
    }
</script>

<template>
    <div>
        <div class="card-body table-responsive p-0" v-if="hasDocuments">
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th class="align-middle">Documento</th>
                        <th class="align-middle">Fecha vencimiento</th>
                        <th class="align-middle">Fecha registro</th>
                        <th class="align-middle">Subido por</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="t in documents.data" :key="t.id">
                        <td class="align-middle">{{ t.type.name }}</td>
                        <td class="align-middle">{{ t.expiration_date | myDate }}</td>
                        <td class="align-middle">{{ t.created_at | myDate }}</td>
                        <td class="align-middle">
                            {{ t.uploader.name }} {{ t.uploader.last_name }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <pagination :data="documents" @pagination-change-page="getDocuments" v-if="hasDocuments"/>
        <span v-else><i>No se han subido documentos a este trabajador.</i></span>
    </div>
</template>

<script>
    export default {
        created () {
            this.getDocuments();
        },
        computed: {
            hasDocuments () {
                return this.documents.data.length > 0;
            }
        },
        data () {
            return {
                documents: { data: [] }
            }
        },
        methods: {
            getDocuments (page = 1) {
                this.$Progress.start();
                this.$parent.loading = true;

                axios.get('/api/trabajadores/' + this.$route.params.id + '/documentos?page=' + page)
                    .then(({ data }) => {
                        this.documents = data.data;
                        this.$Progress.finish();
                    })
                    .catch(() => {
                        this.$launchError('Error al obtener documentos del trabajador');
                        this.$Progress.fail();
                    })
                    .finally(() => {
                        this.$Progress.finish();
                        this.$parent.loading = false;
                    });
            }
        }
    }
</script>

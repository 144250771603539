<template>
    <div>
        <div class="row">
            <div class="col form-group">
                <label>Nombre</label>
                <input v-model="form.name" type="text" name="nombre" class="form-control"
                       :class="{ 'is-invalid': form.errors.has('name') }">
                <has-error :form="form" field="name"/>
            </div>

            <div class="col form-group">
                <label>Valor</label>
                <input v-model="form.price" min="0" type="number" name="valor"
                       class="form-control" :class="{ 'is-invalid': form.errors.has('price') }">
                <has-error :form="form" field="price"/>
            </div>
        </div>

        <div class="row">
            <div class="col form-group">
                <label>Stock crítico</label>
                <input v-model.number="form.critical_stock" min="0" type="number" name="stock crítico" class="form-control"
                       :class="{ 'is-invalid': form.errors.has('critical_stock') }">
                <has-error :form="form" field="critical_stock"/>
            </div>

            <div class="col form-group">
                <label>Tiempo de respuesta (días)</label>
                <input v-model.number="form.response_time" min="0" type="number" name="tiempo de respuesta"
                       class="form-control" :class="{ 'is-invalid': form.errors.has('response_time') }">
                <has-error :form="form" field="response_time"/>
            </div>
        </div>

        <div class="row">
            <div class="col form-group">
                <label>Clasificación</label>
                <select v-model="form.classification_id" type="text" name="clasificación" class="form-control"
                        :class="{ 'is-invalid': form.errors.has('classification_id') }"
                        :disabled="classifications.length === 0">
                    <option :value="null" disabled>Escoja una clasificación</option>
                    <option :value="c.id" v-for="c in classifications">{{ c.name }}</option>
                </select>
                <has-error :form="form" field="classification_id"/>
            </div>

            <div class="col form-group">
                <label>Unidad de medida</label>
                <select v-model="form.measurement_unit_id" type="text" name="unidad de medida" class="form-control"
                        :class="{ 'is-invalid': form.errors.has('measurement_unit_id') }"
                        :disabled="measurements.length === 0">
                    <option :value="null" disabled>Escoja una unidad de medida</option>
                    <option :value="m.id" v-for="m in measurements">{{ m.name }}</option>
                </select>
                <has-error :form="form" field="measurement_unit_id"/>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            classifications: {
                type: Array,
                default () {
                    return [];
                }
            },
            form: Object,
            measurements: {
                type: Array,
                default () {
                    return [];
                }
            }
        }
    }
</script>

<template>
    <div>
        <!-- breadcrumb -->
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Editar usuario</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link to="/">Inicio</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link to="/admin/usuarios">Usuarios</router-link>
                            </li>
                            <li class="breadcrumb-item active">Editar usuario</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>

        <!-- edición -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col">
                        <div class="card">
                            <loading v-if="loading"/>

                            <div class="card-header">
                                <ul class="nav nav-pills">
                                    <li class="nav-item" v-for="t in tabs">
                                        <a class="nav-link" :class="{'active': tab === t.component}"
                                           @click="tab = t.component">
                                            {{ t.title }}
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div class="card-body">
                                <component :is="tab"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Contract from "./EditUser/Contract";
    import Password from "./EditUser/Password";
    import Loading from "./Common/Loading";
    import PersonalData from "./EditUser/PersonalData";
    import Photo from "./EditUser/Photo";
    import eventHub from "../eventHub";

    export default {
        components: { Contract, Loading, PersonalData, Photo, Password },
        computed: {
            id () {
                return this.$route.params.id;
            }
        },
        created () {
            this.getUser();

            eventHub.$on('getUser', () => this.getUser())
        },
        data () {
            return {
                loading: true,
                user: {
                    id: '',
                    name: '',
                    last_name: '',
                    code: '',
                    rut: '',
                    email: '',
                    birthdate: '',
                    phone_number: '',
                    position: { name: null },
                    commune: {
                        name: null,
                        region: {
                            name: null
                        }
                    }
                },
                tab: 'PersonalData',
                tabs: [
                    { title: 'Datos personales', component: 'PersonalData' },
                    { title: 'Contrato', component: 'Contract' },
                    { title: 'Foto', component: 'Photo' },
                ]
            }
        },
        destroyed () {
            eventHub.$off('getUser');
        },
        methods: {
            getUser () {
                this.$Progress.start();
                this.loading = true;

                axios.get('/api/usuarios/' + this.id)
                    .then(({ data }) => {
                        this.user = data.data;
                        if((this.user.id == this.$user.user_data().id) || this.$user.user_data().position.name=="Administrador"){
                            this.tabs.push({ title: 'Cambiar Contraseña', component: 'Password' });
                        }

                        this.$Progress.finish();
                    })
                    .catch(() => {
                        this.$launchError('Error al obtener datos del usuario');
                        this.$Progress.fail();
                    })
                    .finally(() => this.loading = false);
            }
        }
    }
</script>

<template>
    <div>
       <div class="modal-body container">
                    <div class="row">
                        <div class="col form-group">
                            <label>Aerolínea</label>
                            <select name="aerolínea" class="form-control" v-model="form.airline_id"
                                    :class="{ 'is-invalid': form.errors.has('airline_id') }">
                                <option :value="null" disabled>Escoja una aerolínea</option>
                                <option :value="a.id" v-for="a in airlines">{{ a.name }}</option>
                            </select>
                            <has-error :form="form" field="airline_id"/>
                        </div>

                        <div class="col form-group">
                            <label>Tarifa unitaria</label>
                            <input v-model="form.price" type="number" min="0" name="tarifa" class="form-control"
                                   :class="{ 'is-invalid': form.errors.has('price') }" :disabled="form.stock_ticket_id">
                            <has-error :form="form" field="price"/>
                        </div>
                    </div>

                    <div class="row">
                        <!--<div class="col form-group">
                            <label>Tarifa Plus</label>
                            <select name="plus" class="form-control" v-model="form.tarifa_plus"
                                    :class="{ 'is-invalid': form.errors.has('tarifa_plus') }">
                                <option :value="0">No</option>
                                <option :value="1">Si</option>     
                            </select>
                            <has-error :form="form" field="tarifa_plus"/>
                        </div>-->

                        <div class="col form-group">
                            <label>Monto Extra</label>
                            <input v-model="form.extra_price" type="number" min="0" name="extra" class="form-control"
                                 >
                            <has-error :form="form" field="extra"/>
                        </div>

                        <div class="col form-group">
                            <label>Monto Maleta</label>
                            <input v-model="form.carry_price" type="number" min="0" name="carry" class="form-control"
                                 >
                            <has-error :form="form" field="carry"/>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col form-group">
                            <label>Tramo</label>
                            <select name="tramo" class="form-control" v-model="form.flight_stretch_id"
                                    :class="{ 'is-invalid': form.errors.has('flight_stretch_id') }">
                                <option :value="null" disabled>Escoja un tramo</option>
                                <option :value="f.id" v-for="f in flightStretches">{{ f.name }}</option>
                            </select>
                            <has-error :form="form" field="flight_stretch_id"/>
                        </div>

                        <div class="col form-group">
                            <label>Fecha de vuelo</label>
                            <input v-model="form.flight_date" type="date" name="fecha de vuelo" class="form-control"
                                   :class="{ 'is-invalid': form.errors.has('flight_date') }">
                            <has-error :form="form" field="flight_date"/>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col form-group">
                            <label>Hora de partida</label>
                            <input v-model="form.departure_time" type="time" name="hora de partida" class="form-control"
                                   :class="{ 'is-invalid': form.errors.has('departure_time') }">
                            <has-error :form="form" field="departure_time"/>
                        </div>

                        <div class="col form-group">
                            <label>Hora de llegada</label>
                            <input v-model="form.arrival_time" type="time" name="hora de llegada" class="form-control"
                                   :class="{ 'is-invalid': form.errors.has('arrival_time') }">
                            <has-error :form="form" field="arrival_time"/>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col form-group">
                            <label>Aeropuerto de origen</label>
                            <select name="aeropuerto de origen" class="form-control" v-model="form.departure_airport_id"
                                    :class="{ 'is-invalid': form.errors.has('departure_airport_id') }">
                                <option :value="null" disabled>Escoja un aeropuerto</option>
                                <option :value="a.id" v-for="a in airports">{{ a.iata }} - {{ a.city }}</option>
                            </select>
                            <has-error :form="form" field="departure_airport_id"/>
                        </div>

                        <div class="col form-group">
                            <label>Aeropuerto de destino</label>
                            <select name="aeropuerto de destino" class="form-control" v-model="form.arrival_airport_id"
                                    :class="{ 'is-invalid': form.errors.has('arrival_airport_id') }">
                                <option :value="null" disabled>Escoja un aeropuerto</option>
                                <option :value="a.id" v-for="a in airports">{{ a.iata }} - {{ a.city }}</option>
                            </select>
                            <has-error :form="form" field="arrival_airport_id"/>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col form-group">
                            <label>Pasajes en stock</label>
                            <select name="stock" class="form-control" v-model="form.stock_ticket_id"
                                    :disabled="stocks.length === 0">
                                <option :value="null">Escoja un pasaje</option>
                                <option :value="s.id" v-for="s in stocks">
                                    {{ s.created_at | myDate }} - {{s.name}} {{s.last_name}} - [{{s.code}}]
                                </option>
                            </select>
                        </div>
                    </div>


                </div>

            
    </div>
</template>

<script>

    import Loading from "../Common/Loading";

    export default {
        components: { Loading },
        props: {
            form: Object
        },
        computed: {

        },
        created () {
            this.$Progress.start();
            this.getAirlines();
            this.getAirports();
            this.getFlightStretches();
            this.$Progress.finish();
        },
        data () {
            return {
                airlines:{},
                airports:{},
                flightStretches:{},
                stocks:{}
            }
        },
        watch: {
            'form.airline_id' () {
                if (this.form.airline_id) {
                    this.form.stock_ticket_id = null;
                    axios.get(`/api/logistica/aerolineas/${this.form.airline_id}/stock`)
                        .then(({ data }) => this.stocks = data.data)
                        .catch(() => this.$launchError('Error al obtener pasajes en stock'));
                }
            },
            'form.stock_ticket_id' () {
                if (this.form.stock_ticket_id) this.form.price = 0;
            }
        },
        methods: {
            getAirlines () {
                axios.get('/api/logistica/aerolineas')
                    .then(({ data }) => this.airlines = data.data)
                    .catch(() => this.$launchError('Error al obtener aerolíneas'));
            },

            getAirports () {
                axios.get('/api/logistica/aeropuertos')
                    .then(({ data }) => this.airports = data.data)
                    .catch(() => this.$launchError('Error al obtener aeropuertos'));
            },

            getFlightStretches () {
                axios.get('/api/logistica/tramos')
                    .then(({ data }) => this.flightStretches = data.data)
                    .catch(() => this.$launchError('Error al obtener tramos'));
            },

        }
    }
</script>

<template>
    <div>
        <!-- breadcrumb -->
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Ficha de tripulante</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link to="/">Inicio</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link to="/admin/trabajadores">Tripulantes</router-link>
                            </li>
                            <li class="breadcrumb-item active">Ficha tripulante</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>

        <!-- ficha -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <!-- datos personales -->
                    <div class="col-4">
                        <div class="card">
                            <loading v-if="loading"/>

                            <personal-data/>
                        </div>
                    </div>

                    <!-- resto de la ficha -->
                    <div class="col-8">
                        <div class="card">
                            <loading v-if="loading"/>

                            <div class="card-header">
                                <ul class="nav nav-pills">
                                    <li class="nav-item" v-for="t in tabs">
                                        <a class="nav-link" :class="{'active': tab === t.component}"
                                           @click="tab = t.component">
                                            {{ t.title }}
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div class="card-body">
                                <component :is="tab"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Contract from "./WorkerDetails/Contract";
    import Documents from "./WorkerDetails/Documents";
    import ExtraDocuments from "./WorkerDetails/ExtraDocuments";
    import EPPDeliveries from "./WorkerDetails/EPPDeliveries";
    import IMC from "./WorkerDetails/IMC";
    import Inductions from "./WorkerDetails/Inductions";
    import Loading from "./Common/Loading";
    import OccupationalExams from "./WorkerDetails/OccupationalExams";
    import PersonalData from "./WorkerDetails/PersonalData";
    import Qualifications from "./WorkerDetails/Qualifications";

    export default {
        components: {
            Contract,
            Documents,
            ExtraDocuments,
            EPPDeliveries,
            IMC,
            Inductions,
            Loading,
            OccupationalExams,
            PersonalData,
            Qualifications
        },
        computed: {
            id () {
                return this.$route.params.id;
            }
        },
        created () {
            this.getWorkerDetails();
        },
        data () {
            return {
                loading: true,
                tab: 'Contract',
                tabs: [
                    { title: 'Contrato', component: 'Contract' },
                    { title: 'Documentos Trabajo', component: 'Documents' },
                    { title: 'Documentos Extra', component: 'ExtraDocuments' },
                    { title: 'Calificaciones', component: 'Qualifications' },
                    { title: 'Inducciones', component: 'Inductions' },
                    { title: 'Entrega de EPP', component: 'EPPDeliveries' },
                    { title: 'IMC', component: 'IMC' },
                    { title: 'Examen ocupacional', component: 'OccupationalExams' }
                ],
                worker: {
                    avatar: null,
                    name: null,
                    last_name: null,
                    code: null,
                    rut: null,
                    birthdate: null,
                    age: null,
                    phone_number: null,
                    created_at: null,
                    roles: [],
                    expirations: {},
                    qualifications: [],
                    commune: {
                        name: null,
                        region: {
                            name: null
                        }
                    },
                    contract: {
                        salary: 0,
                        contract_type: {},
                        afp: {},
                        health_forecast: {},
                        identity_card_copy: null,
                        boarding_license: null
                    }
                }
            }
        },
        methods: {
            getWorkerDetails () {
                this.$Progress.start();
                this.loading = true;

                axios.get('/api/trabajadores/' + this.id)
                    .then(({ data }) => {
                        this.worker = data.data;
                        this.$Progress.finish();
                    })
                    .catch(() => {
                        this.$launchError('Error al obtener ficha de tripulante');
                        this.$Progress.fail();
                    })
                    .finally(() => this.loading = false);
            }
        }
    }
</script>

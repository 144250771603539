<template>
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <!-- header -->
            <div class="modal-header">
                <h5 class="modal-title">Traslado de tripulante</h5>
            </div>

            <!-- body -->
            <div class="modal-body container">
                <!-- alerta -->
                <div class="alert alert-warning" role="alert" v-if="!canSubmit">
                    La información que aquí aparece es de <strong>solo lectura</strong>.
                </div>

                <form @submit="saveRelocate">
                    <div class="row">
                        <div class="col form-group">
                            <label>Fecha y hora de recogida</label><br>
                            <input v-model="form.pickup" type="datetime-local" class="form-control"
                                   :class="{ 'is-invalid': form.errors.has('pickup') }" v-if="canSubmit">
                            <has-error :form="form" field="pickup" v-if="canSubmit"/>
                            <span v-else>{{ form.pickup | fullDateTime | capitalize }}</span>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col form-group">
                            <label>Salida</label>
                            <input v-model="form.departure" type="text" class="form-control" :readonly="!canSubmit"
                                   :class="{ 'is-invalid': form.errors.has('departure') }">
                            <has-error :form="form" field="departure"/>
                        </div>

                        <div class="col form-group">
                            <label>Destino</label>
                            <input v-model="form.arrival" type="text" class="form-control" :readonly="!canSubmit"
                                   :class="{ 'is-invalid': form.errors.has('arrival') }">
                            <has-error :form="form" field="arrival"/>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col form-group">
                            <label>Valor</label>
                            <input v-model.number="form.price" min="0" type="number" class="form-control" :readonly="!canSubmit"
                                   :class="{ 'is-invalid': form.errors.has('price') }">
                            <has-error :form="form" field="price"/>
                        </div>

                        <div class="col form-group">
                            <label>Proveedor</label>
                            <select v-model="form.relocation_provider_id" class="form-control" :readonly="!canSubmit"
                                    :disabled="!canSubmit"
                                    :class="{ 'is-invalid': form.errors.has('relocation_provider_id') }">
                                <option :value="null" disabled>Escoja un proveedor</option>
                                <option :value="p.id" v-for="p in providers">{{ p.name }}</option>
                            </select>
                            <has-error :form="form" field="relocation_provider_id"/>
                        </div>
                    </div>
                </form>
            </div>

            <!-- footer -->
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                <button type="button" class="btn btn-primary" @click="saveRelocate" v-if="canSubmit">
                    Guardar
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import eventHub from "../../../eventHub";
import moment from "moment";

export default {
    computed: {
        id () {
            return this.$parent.relocateCrewWorkerId;
        },
        canSubmit () {
            return this.$parent.canModify;
        },
        providers () {
            return this.$parent.relocateProviders;
        }
    },
    created () {
        const r = this.$parent.quotation.crew.workers.find(w => w.id === this.id).relocation_data;

        // si existe traslado, abre el modal en modo edición
        if (r) {
            r.pickup = moment(r.pickup).format('yyyy-MM-DDThh:mm');
            this.form.fill(r);
        }
    },
    data () {
        return {
            form: new Form({
                arrival: null,
                departure: null,
                pickup: null,
                relocation_provider_id: null,
                price: 0
            })
        }
    },
    methods: {
        saveRelocate () {
            if (this.canSubmit) {
                this.$Progress.start();

                this.form.post(`/api/logistica/${this.id}/traslado`)
                    .then(() => {
                        eventHub.$emit('relocateSaved');
                        this.$launchSuccess('Éxito al guardar traslado de tripulante');
                    })
                    .catch(() => this.$launchError('Error al guardar traslado de tripulante'))
                    .finally(() => this.$Progress.finish());
            }
        }
    }
}
</script>

<template>
 <v-app>
  <section class="content">
      <div class="container-fluid">
          <div class="row">
              <div class="col-12">
                  <div class="card">
                      <loading v-if="loading"/>

                      <div class="card-header">
                          <div class="row align-items-center">
                              <h3 class="col card-title">Lista de Pasajes</h3>

                              <div class="col-6">
                                  
                              </div>

                              <div class="col-2">
                                

                                 <router-link to="nuevo-pasaje" class="btn btn-sm btn-primary" style="color:#ffffff !important;">
                                    <i class="fa fa-plus-square"></i>
                                    Nuevo Pasaje
                                </router-link>
                              </div>
                          </div>
                      </div>

 <v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Búsqueda"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
  <v-data-table
      :headers="headers"
      :items="datos"
      :search="search"
       :loading="loading"
       :items-per-page="30"
       no-results-text="No se han encontrado pasajes"
       no-data-text="No existen pasajes"
      class="elevation-1"
        loading-text="Cargando... Espere"
         :footer-props="{
           'items-per-page-text':'Pasajes por página',
           'items-per-page-options': [20, 50, 100, -1]
      }"
    >
 <template v-slot:item.accion="{ item, index }">

                                            <router-link :to="`/editar-pasaje/${item.id}`" 
                                                       v-tooltip="'Editar Pasaje'">
                                               <v-icon small style="color: #3490DC !important;">
                                                fa-edit
                                                </v-icon>
                                          </router-link>
  </template>
  <template v-slot:footer.page-text="items"> {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }} </template>
</v-data-table>
</v-card> 
                    <!--
                      <div class="card-body table-responsive p-0">
                          <table class="table table-hover">
                              <thead>
                                  <tr>
                                      <th class="align-middle">Pasajeros</th>
                                      <th class="align-middle">Aerolinea</th>
                                      <th class="align-middle">Código</th>
                                      <th class="align-middle">T. Unitaria</th>
                                      <th class="align-middle">T. Total</th>
                                      <th class="align-middle">Plus</th>
                                      <th class="align-middle">Tramo</th>
                                      <th class="align-middle">F. Vuelo</th>
                                      <th class="align-middle">H. Partida</th>
                                      <th class="align-middle">H. Llegada</th>
                                      <th class="align-middle">A. Origen</th>
                                      <th class="align-middle">A. Destino</th>
                                      <th class="align-middle">M. Maleta</th>
                                      <th class="align-middle">T. Extra</th>
                                      <th class="align-middle">Tripulacion</th>
                                      <th class="align-middle">Opciones</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr v-for="t in tickets.data" :key="t.id">
                                      <td class="align-middle">{{ t.passengers }}</td>
                                      <td class="align-middle">{{ t.airline }}</td>
                                      <td class="align-middle">{{ t.code }}</td>
                                      <td class="align-middle">{{ t.price | dotNumber}}</td>
                                      <td class="align-middle">{{ t.total_price | dotNumber}}</td>
                                      <td class="align-middle">{{ plustype(t.plus) }}</td>
                                      <td class="align-middle">{{ t.stretch }}</td>
                                      <td class="align-middle">{{ t.flight_date }}</td>
                                      <td class="align-middle">{{ t.departure_time }}</td>
                                      <td class="align-middle">{{ t.arrival_time }}</td>
                                      <td class="align-middle">{{ t.airport1 }}</td>
                                      <td class="align-middle">{{ t.airport2 }}</td>
                                      <td class="align-middle">{{ t.carry | dotNumber}}</td>
                                      <td class="align-middle">{{ t.extra_price | dotNumber}}</td>
                                       <td class="align-middle">{{ t.crew }}</td>
                                       <td>
                                       <router-link :to="`/editar-pasaje/${t.id}`" 
                                                       v-tooltip="'Editar Traslado'">
                                              <i class="fa fa-edit blue"></i>
                                          </router-link>
                                      </td>
                                      
                                  
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                      <div class="card-footer">
                          <pagination :data="tickets" @pagination-change-page="getTickets"/>
                      </div>

                        -->

                  </div>
              </div>
          </div>

       
      </div>
  </section>
   </v-app>
</template>

<script>
    import Loading from "./Common/Loading";

    export default {
        components: { Loading},
        created () {
            this.$Progress.start();
            this.getTickets();
            this.$Progress.finish();
        },
        data () {
            return {
                loading: true,
                searchInput: null,
                tickets: { },
                search: '',
                headers: [                 
                { text: 'Pasajeros', align: 'start',sortable: false,value: 'pasajeros'},
                { text: 'Aerolinea', value: 'aerolinea' },
                { text: 'Código', value: 'codigo' },
                { text: 'T. Unitaria', value: 'unitaria' },
                { text: 'T. Total', value: 'total' },
                { text: 'OC', value: 'plus' },
                { text: 'Tramo', value: 'tramo' },
                { text: 'F. Vuelo', value: 'vuelo' },
                { text: 'H. Partida', value: 'partida' },
                { text: 'H. Llegada', value: 'llegada' },
                { text: 'A. Origen', value: 'origen' },
                { text: 'A. Destino', value: 'destino' },
                { text: 'M. Maleta', value: 'maleta' },
                { text: 'T. Extra', value: 'extra' },
                { text: 'Tripulacion', value: 'tripulacion' },
                { text: 'Opciones', value: 'accion', sortable: false },
                ],
                datos:[], 

             
                                      
                                    
                                    
                                    
                               
                          
            }
        },
        methods: {
            plustype(plus){
                if(plus==1){
                    return "Si";
                }
                return "No";
            },
            getTickets (page = 1) {
                this.$Progress.start();
                this.loading = true;

                axios.get('/api/administracion/pasajes')
                    //.then(({ data }) => this.tickets = data)
                     .then(data => {
                    this.tickets = data.data;
                     console.log(this.tickets);
                     console.log(this.tickets.data.length);
                        for(let i = 0; i < this.tickets.data.length; i++) {
                            var t = this.tickets.data[i];
                            var valor = this.$options.filters.dotNumber(t.price );
                            var total = this.$options.filters.dotNumber(t.total_price );
                            var maleta = this.$options.filters.dotNumber(t.carry );
                            var extra = this.$options.filters.dotNumber(t.extra_price );         
                            this.datos.push({id:t.id,tripulacion:t.crew,extra:extra,maleta:maleta,destino:t.airport2,origen:t.airport1 ,llegada:t.arrival_time,partida:t.departure_time,pasajeros:t.passengers,aerolinea:t.airline,codigo:t.code,unitaria:"$"+valor,total:"$"+total,plus:t.oc,tramo:t.stretch,vuelo:t.flight_date,accion:true});
                        }
                       console.log(this.datos);
                     })
                    .catch(() => this.$launchError('Error al obtener listado de pasajes'))
                    .finally(() => {
                        this.$Progress.finish();
                        this.loading = false;
                    });
            },


            deleteWorker (id) {
                Swal.fire({
                    title: '¿Estás seguro que deseas eliminar este pasaje?',
                    text: 'No podrás revertir esta acción',
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Si',
                    cancelButtonText: 'No'
                }).then((result) => {
                    if (result.value) {
                        this.form.delete('/api/administracion/personas/' + id).then(() => {
                            Swal.fire(
                                'Éxito',
                                'El pasaje fue eliminado correctamente',
                                'success'
                            );

                            this.getPersons();
                        }).catch(() => Swal.fire(
                            'Error',
                            'Error al eliminar pasaje',
                            'warning'
                        ));
                    }
                });
            }
        }
    }
</script>

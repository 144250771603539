<template>
    <form @submit.prevent="editWorker">
        <worker-form :form="form" :is-edition="true"/>

        <button type="submit" class="btn btn-primary float-right" :disabled="submit">
            <i class="fa fa-spinner fa-spin mr-2" v-if="submit"></i>
            {{ submit ? 'Guardando' : 'Guardar' }}
        </button>
    </form>
</template>

<script>
    import Form from "vform/src";
    import WorkerForm from "../Workers/Form";
    import eventHub from "../../eventHub";

    export default {
        components: { WorkerForm },
        created () {
            this.$Progress.start();
            this.getWorker();
            this.$Progress.finish();
        },
        data () {
            return {
                form: new Form({
                    id: '',
                    name: '',
                    last_name: '',
                    code: '',
                    address: '',
                    rut: '',
                    birthdate: '',
                    phone_number: '',
                    attribute_id: null,
                    region_id: null,
                    commune_id: null,
                    marital_status_id: null
                }),
                submit: false
            }
        },
        methods: {
            validaRut: function(rutCompleto) {
                rutCompleto = rutCompleto.replace("‐","-");
                if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test( rutCompleto ))
                    return false;
                var tmp     = rutCompleto.split('-');
                var digv    = tmp[1];
                var rut     = tmp[0];
                if ( digv == 'K' ) digv = 'k' ;

                return (this.dv(rut) == digv );
            },
            dv : function(T){
                var M=0,S=1;
                for(;T;T=Math.floor(T/10))
                    S=(S+T%10*(9-M++%6))%11;
                return S?S-1:'k';
            },
            editWorker () {

            if(this.validaRut(this.form.rut)){
                this.submit = true;
                this.$Progress.start();

                this.form.put('/api/trabajadores/' + this.$parent.id)
                    .then(() => {
                        this.$Progress.finish();
                        this.$launchSuccess('Éxito al editar tripulante');
                        eventHub.$emit('getWorker');
                    })
                    .catch(() => {
                        this.$Progress.fail();
                        this.$launchError('Error al editar tripulante');
                    })
                    .finally(() => this.submit = false);
                }else{
                    this.$launchError('Rut no válido');
                }

            },

            getWorker () {
                this.$nextTick(() => {
                    this.form.fill(this.$parent.worker);
                    this.form.region_id = this.$parent.worker.commune.region_id;
                });
            }
        },
        watch: {
            '$parent.worker' () {
                this.getWorker();
            }
        }
    }
</script>

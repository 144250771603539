<template>
    <div class="card">
        <div class="card-header">
            <h4 class="card-title w-100">
                <a class="d-block w-100" data-toggle="collapse" :href="`#collapse-${emergency.id}`"
                   aria-expanded="true">
                    {{ title }}
                </a>
            </h4>
        </div>
        <div :id="`collapse-${emergency.id}`" class="collapse show" data-parent="#accordion" style="">
            <div class="card-body">
                <!-- motivo de emergencia -->
                <div class="alert alert-danger mb-4" role="alert">
                    <strong>Motivo:</strong> {{ emergency.reason }}.
                </div>

                <span v-if="emergency.replacement_crew_worker_id === null"><i>No se ha solicitado reemplazo.</i></span>
                <div v-else>
                    <h4 class="mt-4 mb-4">Reemplazo</h4>
                    <table class="table">
                        <thead>
                            <tr>
                                <th class="align-middle">Nombre</th>
                                <th class="align-middle">Rut</th>
                                <th class="align-middle">Roles</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="align-middle">
                                    {{ replacement.name }} {{ replacement.last_name }}
                                </td>
                                <td class="align-middle">{{ replacement.rut }}</td>
                                <td class="align-middle">{{ replacement.roles.map(r => r.name) | list }}</td>
                            </tr>
                        </tbody>
                    </table>

                    <h4 class="mt-4 mb-4">Pasajes</h4>
                    <span v-if="tickets.length === 0">No se han creado pasajes aún.</span>
                    <div class="table-responsive" v-else>
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Aerolínea</th>
                                    <th>Tramo</th>
                                    <th>Ruta</th>
                                    <th>Fecha</th>
                                    <th>Hora salida</th>
                                    <th>Hora llegada</th>
                                    <th>Tarifa</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(t, i) in emergency.quotation.tickets">
                                    <td>{{ i + 1 }}</td>
                                    <td>{{ t.airline.name }}</td>
                                    <td>{{ t.flight_stretch.name }}</td>
                                    <td>
                                        {{ t.departure_airport.iata }} -
                                        {{ t.arrival_airport.iata }}
                                    </td>
                                    <td>{{ t.flight_date | myDate }}</td>
                                    <td>{{ t.departure_time | myHour }}</td>
                                    <td>{{ t.arrival_time | myHour }}</td>
                                    <td>
                                        ${{ t.price | dotNumber }}
                                        <strong v-if="t.tickets.length > 0">
                                            (Total ${{ t.price * t.tickets.length | dotNumber }})
                                        </strong>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <h4 class="mt-4 mb-4">Traslado</h4>
                    <span v-if="emergency.replacement.relocation === 0">No se ha solicitado traslado.</span>
                    <div v-else>
                        <span v-if="emergency.replacement.lodging_data === null">
                            No se ha registrado información de traslado.
                        </span>
                        <div v-else>
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th class="align-middle">Fecha y hora recogida</th>
                                        <th class="align-middle">Salida</th>
                                        <th class="align-middle">Destino</th>
                                        <th class="align-middle">Valor</th>
                                        <th class="align-middle">Proveedor</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="align-middle">{{ relocation.pickup | fullDateTime | capitalize }}</td>
                                        <td class="align-middle">{{ relocation.departure }}</td>
                                        <td class="align-middle">{{ relocation.arrival }}</td>
                                        <td class="align-middle">${{ relocation.price | dotNumber }}</td>
                                        <td class="align-middle">{{ relocation.relocation_provider.name }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <h4 class="mt-4 mb-4">Alojamiento</h4>
                    <span v-if="emergency.replacement.lodging === 0">No se ha solicitado alojamiento.</span>
                    <div v-else>
                        <span v-if="emergency.replacement.lodging_data === null">
                            No se ha registrado información de alojamiento.
                        </span>
                        <div v-else>
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th class="align-middle">Fecha ingreso</th>
                                        <th class="align-middle">Fecha salida</th>
                                        <th class="align-middle">Tarifa</th>
                                        <th class="align-middle">Alimentación</th>
                                        <th class="align-middle">Proveedor</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="align-middle">{{ lodging.arrival_date | myDate }}</td>
                                        <td class="align-middle">{{ lodging.departure_date | myDate }}</td>
                                        <td class="align-middle">${{ lodging.price | dotNumber }}</td>
                                        <td class="align-middle">
                                            {{ emergency.replacement.lodging_food ? 'Si' : 'No' }}
                                        </td>
                                        <td class="align-middle">{{ lodging.lodging_provider.name }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        computed: {
            lodging () {
                if (this.emergency.replacement.lodging_data) {
                    return this.emergency.replacement.lodging_data;
                }
            },
            relocation () {
                if (this.emergency.replacement.relocation_data) {
                    return this.emergency.replacement.relocation_data;
                }
            },
            replacement () {
                return this.emergency.replacement.worker;
            },
            tickets () {
                return this.emergency.quotation.tickets;
            },
            title () {
                return this.myDate(this.emergency.created_at);
            }
        },
        props: {
            emergency: Object
        }
    }
</script>

<template>
    <div>
        <!-- breadcrumb -->
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Calendario Trip</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link to="/">Inicio</router-link>
                            </li>
                            <li class="breadcrumb-item active">Calendario Trip</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>

        <!-- calendario -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col">
                        <div class="card">
                            <loading v-if="loading"/>

                            <div class="card-header">
                                <calendar :events="phantomCrews" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Calendar from "./Common/Calendar";
    import Loading from "./Common/Loading";
    import moment from 'moment';

    export default {
        components: { Calendar, Loading },
        computed: {
            events () {
                let e = [];

                this.crews.forEach(c => {
                    let t = `<a href="admin/tripulaciones/${c.id}" style="color: white; text-decoration: none;">${c.ship.name} - ${c.client.name}.<br>Tripulantes: ${c.workers_count}.</a><br>`;

                    e.push({
                        title: `${t}<a href="admin/tripulaciones/${c.id}" style="color: white; text-decoration: none;">Subida: ${moment(c.upload_date).format('DD-MM-YYYY')}</a>.`,
                        start: c.upload_date,
                        backgroundColor: '#28A745',
                        borderColor: '#28A745'
                    });
                    e.push({
                        title: `${t}<a href="admin/tripulaciones/${c.id}" style="color: white; text-decoration: none;">Bajada: ${moment(c.real_download_date).format('DD-MM-YYYY')}</a>.`,
                        start: c.real_download_date,
                        backgroundColor: '#007BFF'
                    });
                });          
                return e;
            },
            phantomCrews () {
                let e = [];

                this.crewsLast.forEach(d => {
                   
                       var originaldate = new Date(moment(d.real_download_date).format('YYYY-MM-DD'));
                        var newdate = new Date(moment(d.real_download_date).format('YYYY-MM-DD'));
                        var newdate2 = new Date(moment(d.real_download_date).format('YYYY-MM-DD'));
                         
                    for(var i=0;i<12;i++){
                        if(i==0){
                             newdate.setDate(originaldate.getDate() + 25); //24
                             newdate2.setDate(originaldate.getDate() + 1); //21
                        }else{
                             var nuevafecha = new Date(moment(newdate).format('YYYY-MM-DD'));

                           //  newdate.setDate(nuevafecha.getDate() + 26); //24
                            // newdate2.setDate(nuevafecha.getDate() + 2); //21
                             newdate2 = this.addDays(nuevafecha,1);
                             newdate = this.addDays(nuevafecha,25);
                        }
                      

                        let t = `<a style="color: white; text-decoration: none;">${d.ship.name} -  Temp.${i+1}.</a><br>`;
                        e.push({
                            title: `${t}<a style="color: white; text-decoration: none;">Subida: ${moment(newdate2).format('DD-MM-YYYY')}</a>.`,
                            start: moment(newdate2).format('YYYY-MM-DD'),
                            backgroundColor: '#FF5733',
                            borderColor: '#FF5733'
                        });
                        e.push({
                            title: `${t}<a style="color: white; text-decoration: none;">Bajada: ${moment(newdate).format('DD-MM-YYYY')}</a>.`,
                            start: moment(newdate).format('YYYY-MM-DD'),
                            backgroundColor: '#FF3333',
                            borderColor: '#FF3333'
                        });

                        
                    }
                    
                    
                });     



                  this.crews.forEach(c => {
                    let t = `<a href="admin/tripulaciones/${c.id}" style="color: white; text-decoration: none;">${c.ship.name} - ${c.client.name}.<br>Tripulantes: ${c.workers_count}.</a><br>`;

                    e.push({
                        title: `${t}<a href="admin/tripulaciones/${c.id}" style="color: white; text-decoration: none;">Subida: ${moment(c.upload_date).format('DD-MM-YYYY')}</a>.`,
                        start: c.upload_date,
                        backgroundColor: '#28A745',
                        borderColor: '#28A745'
                    });
                    e.push({
                        title: `${t}<a href="admin/tripulaciones/${c.id}" style="color: white; text-decoration: none;">Bajada: ${moment(c.real_download_date).format('DD-MM-YYYY')}</a>.`,
                        start: c.real_download_date,
                        backgroundColor: '#007BFF'
                    });
                });  
                return e;
            }
        },
        created () {
            this.getCrews();
            this.getLastCrews();
        },
        data () {
            return {
                crews: [],
                crewsLast: [],
                loading: true
            }
        },
        methods: {
             addDays(thedate,n){
                var t = new Date(thedate);
                t.setDate(t.getDate() + n); 
                var month = "0"+(t.getMonth()+1);
                var date = "0"+t.getDate();
                month = month.slice(-2);
                date = date.slice(-2);
                //var date = date +"/"+month +"/"+t.getFullYear();
                var date = t.getFullYear()+"-"+month +"-"+date;
                return date;
            },
            handleDateClick(){
                alert("texto");
            },
            getCrews () {
                this.$Progress.start();

                axios.get('/api/calendario-trip/tripulaciones')
                    .then(({ data }) => {
                        this.$Progress.finish();
                        this.crews = data.data;
                    })
                    .catch(() => {
                        this.$Progress.fail();
                        this.$launchError('Error al obtener listado de tripulaciones');
                    })
                    .finally(() => this.loading = false);
            },
            getLastCrews () {
                this.$Progress.start();

                axios.get('/api/calendario-trip/ultimas-tripulaciones')
                    .then(({ data }) => {
                        this.$Progress.finish();
                        this.crewsLast = data.data;
                        console.log(this.crewsLast);
                    })
                    .catch(() => {
                        this.$Progress.fail();
                        this.$launchError('Error al obtener listado de tripulaciones');
                    })
                    .finally(() => this.loading = false);
            }
        }
    }
</script>

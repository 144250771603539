<template>
 <v-app>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <loading v-if="loading"/>

                        <!-- header -->
                        <div class="card-header">
                            <h3 class="card-title">Listado de artículos</h3>

                            <div class="card-tools">
                                <button type="button" class="btn btn-sm btn-primary" @click="creationModal">
                                    <i class="fa fa-plus-square"></i>
                                    Nuevo artículo
                                </button>
                            </div>
                        </div>


 <v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Búsqueda"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
  <v-data-table
      :headers="headers"
      :items="datos"
      :search="search"
       :loading="loading"
       :items-per-page="30"
       no-results-text="No se han encontrado articulos"
       no-data-text="No existen articulos"
      class="elevation-1"
        loading-text="Cargando... Espere"
         :footer-props="{
           'items-per-page-text':'Articulos por página',
           'items-per-page-options': [20, 50, 100, -1]
      }"
    >
 <template v-slot:item.accion="{ item, index }">


                                          <router-link :to="'/bodega/articulos/' + item.id + '/editar'">
                                                 <v-icon small style="color: #3490DC !important;">
                                                fa-edit
                                                </v-icon>
                                            </router-link>
                                            /
                                            <a href="#" @click="deleteItem(item.id)">
                                                 <v-icon small color="red">
                                                fa-trash
                                                </v-icon>
                                            </a>
                                                                        
  </template>
  <template v-slot:footer.page-text="items"> {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }} </template>
</v-data-table>
</v-card> 

                        <!-- 
                        <div class="card-body table-responsive p-0">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th class="align-middle">Nombre</th>
                                        <th class="align-middle">Clasificación</th>
                                        <th class="align-middle">Tallas</th>
                                        <th class="align-middle">Unidad de medida</th>
                                        <th class="align-middle">Valor</th>
                                        <th class="align-middle">Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="i in warehouseItems.data">
                                        <td class="align-middle">{{ i.name }}</td>
                                        <td class="align-middle">{{ i.classification.name }}</td>
                                        <td class="align-middle">
                                            <span v-tooltip="formatTooltip(i.sizes)">
                                                {{ formatSizes(i.sizes) | list }}
                                            </span>
                                        </td>
                                        <td class="align-middle">{{ i.measurement_unit.name }}</td>
                                        <td class="align-middle">
                                            <span>${{ i.price | dotNumber }}</span>
                                        </td>
                                        <td class="align-middle">
                                            <router-link :to="'/bodega/articulos/' + i.id + '/editar'">
                                                <i class="fa fa-edit blue"></i>
                                            </router-link>
                                            /
                                            <a href="#" @click="deleteItem(i.id)">
                                                <i class="fa fa-trash red"></i>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        
                        <div class="card-footer">
                            <pagination :data="warehouseItems" @pagination-change-page="getItems"/>
                        </div>
                        -->
                    </div>
                </div>
            </div>

            <!-- Modal -->
            <div class="modal fade" id="form-modal" tabindex="-1" role="dialog" aria-labelledby="form-dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Crear nuevo artículo</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <form @submit.prevent="createItem">
                            <div class="modal-body container">
                                <item-form :form="form" :classifications="itemClassifications"
                                           :measurements="measurementUnits"/>

                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                                    <button type="submit" class="btn btn-primary">Crear</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
     </v-app>
</template>

<script>
    import ItemForm from "./Form";
    import Loading from "../Common/Loading";

    export default {
        components: { ItemForm, Loading },
        created () {
            this.getItems();
        },
        data () {
            return {
                form: new Form({
                    name: null,
                    price: 0,
                    critical_stock: 0,
                    response_time: 0,
                    classification_id: null,
                    measurement_unit_id: null
                }),
                itemClassifications: [],
                loading: true,
                measurementUnits: [],
                warehouseItems: {},
                 search: '',
                headers: [
                {
                    text: 'Nombre',
                    align: 'start',
                    value: 'nombre',
                },
                { text: 'Clasificación', value: 'clasificacion' },
                { text: 'Tallas', value: 'talla' },
                { text: 'Unidad de medida', value: 'unidad' },
                { text: 'Valor', value: 'valor' },

                { text: 'Acciones', value: 'accion', sortable: false },
                ],
                datos:[],
            }
        },
        methods: {
            createItem () {
                this.$Progress.start();

                this.form.post('/api/bodega/articulos')
                    .then(() => {
                        this.$Progress.finish();
                        this.$launchSuccess('Éxito al crear artículo');
                        $('#form-modal').modal('hide');
                        this.getItems();
                    })
                    .catch(() => {
                        this.$Progress.fail();
                        this.$launchError('Error al crear artículo');
                    });
            },

            creationModal () {
                this.getClassifications();
                this.getMeasurements();
                this.form.reset();
                $('#form-modal').modal('show');
            },

            deleteItem (id) {
                Swal.fire({
                    title: '¿Estás seguro que deseas eliminar el artículo?',
                    text: 'No podrás revertir esta acción',
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Si',
                    cancelButtonText: 'No'
                }).then((result) => {
                    if (result.value) {
                        axios.delete('/api/bodega/articulos/' + id).then(() => {
                            Swal.fire(
                                'Éxito',
                                'El artículo fue eliminado correctamente',
                                'success'
                            );

                            this.getItems();
                        }).catch(() => Swal.fire(
                            'Error',
                            'No se pudo eliminar el artículo',
                            'warning'
                        ));
                    }
                });
            },

            formatSizes (sizes) {
                return sizes.map(s => {
                    if (s.name === '') {
                        return '-';
                    } else {
                        return s.name;
                    }
                });
            },

            formatTooltip (sizes) {
                let str = '';

                sizes.forEach(s => {
                    str += `<strong>${s.name}</strong>: ${s.quantity}<br>`;
                });

                return str;
            },

            getClassifications () {
                if (this.itemClassifications.length === 0) {
                    axios.get('/api/bodega/clasificaciones-articulos')
                        .then(({ data }) => this.itemClassifications = data.data)
                        .catch(() => this.$launchError('Error al obtener listado de clasificaciones de artículos'));
                }
            },

            getItems () {
                this.$Progress.start();
                this.loading = true;

                axios.get('/api/bodega/articulos')
                    /*.then(({ data }) => {
                        this.warehouseItems = data.data;
                        this.$Progress.finish();
                    })*/
                    .then(data => {
                    this.datos = [];
                    this.warehouseItems = data.data;
                     console.log(this.warehouseItems);
                        for(let i = 0; i < this.warehouseItems.data.length; i++) {
                            var t = this.warehouseItems.data[i];
                            var precio = this.$options.filters.dotNumber(t.price );  
                            var tallas = this.$options.filters.list(this.formatSizes(t.sizes));  
                            this.datos.push({nombre: t.name,clasificacion:t.classification.name,talla:tallas,unidad:t.measurement_unit.name,valor:"$"+precio,id:t.id,accion:true});
                        }
                       console.log(this.datos);
                       this.$Progress.finish();
                     })
                    .catch(() => {
                        this.$launchError('Error al obtener listado de artículos');
                        this.$Progress.fail();
                    })
                    .finally(() => this.loading = false);
            },

            getMeasurements () {
                if (this.measurementUnits.length === 0) {
                    axios.get('/api/bodega/unidades-medida')
                        .then(({ data }) => this.measurementUnits = data.data)
                        .catch(() => this.$launchError('Error al obtener listado de unidades de medidas'));
                }
            }
        }
    }
</script>

<template>
    <div>
        <!-- breadcrumb -->
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Crear cotización</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link to="/">Inicio</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link to="/logistica/boton-emergencia">Botón emergencia</router-link>
                            </li>
                            <li class="breadcrumb-item active">Crear cotización</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>

        <!-- creación -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col">
                        <div class="card">
                            <loading v-if="loading"/>

                            <div class="card-body">
                                <!-- mensaje de cotización enviada -->
                                <div class="alert alert-success" role="alert" v-if="!canModify">
                                    Esta cotización fue enviada el día {{ quotation.entered_at | myDate }}.
                                </div>

                                <!-- pasajes -->
                                <div class="row d-flex align-items-center mb-3">
                                    <div class="col-10">
                                        <h4 class="mb-0">Pasajes</h4>
                                    </div>

                                    <div class="col">
                                        <button class="btn btn-primary float-right" v-if="canModify"
                                                :disabled="canConfirm"
                                                @click="setTicket(null)">
                                            Añadir
                                        </button>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col table-responsive p-0">
                                        <table class="table table-hover" v-if="quotation.tickets.length > 0">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Aerolínea</th>
                                                    <th>Tramo</th>
                                                    <th>Ruta</th>
                                                    <th>Fecha</th>
                                                    <th>Hora salida</th>
                                                    <th>Hora llegada</th>
                                                    <th>Tarifa</th>
                                                    <th v-if="canModify">Acciones</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(t, i) in quotation.tickets">
                                                    <td>{{ i + 1 }}</td>
                                                    <td>{{ t.airline.name }}</td>
                                                    <td>{{ t.flight_stretch.name }}</td>
                                                    <td>
                                                        {{ t.departure_airport.iata }} -
                                                        {{ t.arrival_airport.iata }}
                                                    </td>
                                                    <td>{{ t.flight_date | myDate }}</td>
                                                    <td>{{ t.departure_time | myHour }}</td>
                                                    <td>{{ t.arrival_time | myHour }}</td>
                                                    <td>
                                                        ${{ t.price | dotNumber }}
                                                        <strong v-if="t.tickets.length > 0">
                                                            (Total ${{ t.price * t.tickets.length | dotNumber }})
                                                        </strong>
                                                    </td>
                                                    <td v-if="canModify">
                                                        <a href="#" @click.prevent="setTicket(t.id)"
                                                           v-tooltip="'Editar pasaje'">
                                                            <i class="fa green fa-edit"/>
                                                        </a>
                                                        <a href="#" @click.prevent="deleteTicket(t.id)"
                                                           v-tooltip="'Eliminar pasaje'">
                                                            <i class="fa red fa-times"/>
                                                        </a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <p class="text-center mb-4" v-else>
                                            <i>No se han agregado pasajes aún.</i>
                                        </p>
                                    </div>
                                </div>

                                <!-- trabajadores -->
                                <h4 class="mb-3">Tripulante</h4>

                                <div class="row">
                                    <div class="col table-responsive p-0">
                                        <table class="table table-hover" v-if="!loading">
                                            <thead>
                                                <tr>
                                                    <th class="align-middle">Nombre</th>
                                                    <th class="align-middle">Rut</th>
                                                    <th class="align-middle">Rol</th>
                                                    <th class="align-middle">Alojamiento</th>
                                                    <th class="align-middle">Traslado</th>
                                                    <th class="align-middle">Observaciones</th>
                                                    <th class="align-middle">Acciones</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td class="align-middle">
                                                        {{ r.worker.name }} {{ r.worker.last_name }}
                                                    </td>
                                                    <td class="align-middle">{{ r.worker.rut }}</td>
                                                    <td class="align-middle">{{ r.worker.roles.map(r => r.name) | list }}</td>
                                                    <td class="align-middle">
                                                        <div v-if="r.lodging">
                                                            <i class="fa" :class="{
                                                                    'fa-check green': r.lodging_data,
                                                                    'fa-times red': !r.lodging_data
                                                                }" v-tooltip="r.lodging_data ? tooltip.lodging: tooltip.notLodging"/>
                                                            <span v-if="r.lodging_food"
                                                                  v-tooltip="'Solicita alimentación'">
                                                                    + alim.
                                                                </span>
                                                        </div>
                                                        <span v-else>No solicitado</span>
                                                    </td>
                                                    <td class="align-middle">
                                                        <div v-if="r.relocation">
                                                            <i class="fa" :class="{
                                                                    'fa-check green': r.relocation_data,
                                                                    'fa-times red': !r.relocation_data
                                                                }" v-tooltip="r.relocation_data ? tooltip.relocation: tooltip.notRelocation"/>
                                                        </div>
                                                        <span v-else>No solicitado</span>
                                                    </td>
                                                    <td class="align-middle">
                                                        <input type="text" class="form-control" v-if="canModify"
                                                               v-model="observations">
                                                        <span v-else>
                                                            {{ r.observations | dash }}
                                                        </span>
                                                    </td>
                                                    <td class="align-middle">
                                                        <router-link :to="'/admin/trabajadores/' + r.worker.id"
                                                                     v-tooltip="'Ficha del tripulante'">
                                                            <i class="fa fa-info-circle green"></i>
                                                        </router-link>

                                                        <span v-if="r.lodging">/</span>
                                                        <a href="#" @click="setLodging(r.id)" v-tooltip="'Alojamiento'"
                                                           v-if="r.lodging">
                                                            <i class="fa orange fa-bed"/>
                                                        </a>

                                                        <span v-if="r.relocation">/</span>
                                                        <a href="#" @click="setRelocation(r.id)" v-tooltip="'Traslado'"
                                                           v-if="r.relocation">
                                                            <i class="fa blue fa-bus-alt"/>
                                                        </a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <!--confirmar -->
                                <button type="button" class="btn btn-success float-right" @click="saveQuotation"
                                        :disabled="!canConfirm" v-if="canModify">
                                    Confirmar
                                </button>
                            </div>
                        </div>

                        <!-- modal de añadir/modificar vuelo -->
                        <div class="modal fade" id="flight-modal" tabindex="-1" role="dialog"
                             aria-labelledby="form-dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                            <ticket-modal :ticket-id="selectedTicket" v-if="showFlightModal"/>
                        </div>

                        <!-- modal para configurar alojamiento y alimentación -->
                        <div class="modal fade" id="lodging-modal" tabindex="-1" role="dialog"
                             aria-labelledby="form-dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                            <lodging v-if="showLodgingModal"/>
                        </div>

                        <!-- modal para configurar traslado -->
                        <div class="modal fade" id="relocation-modal" tabindex="-1" role="dialog"
                             aria-labelledby="form-dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                            <relocation v-if="showRelocateModal"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import Loading from "../Common/Loading";
import Lodging from "./EmergencyQuotation/Lodging";
import Relocation from "./EmergencyQuotation/Relocation";
import TicketModal from "./EmergencyQuotation/TicketModal";
import eventHub from "../../eventHub";

export default {
    components: { Loading, Lodging, Relocation, TicketModal },
    computed: {
        id () {
            return this.$route.params.id;
        },
        canConfirm () {
            let going = false;
            let returning = false;

            going = this.quotation.tickets.find(q => q.flight_stretch.name === 'Ida') !== undefined;
            returning = this.quotation.tickets.find(q => q.flight_stretch.name === 'Vuelta') !== undefined;

            return going && returning;
        },
        canModify () {
            return this.quotation.entered === 0;
        },
        quotation () {
            return this.emergencyButton.quotation;
        },
        r () {
            return this.emergencyButton.replacement;
        }
    },
    created () {
        this.$Progress.start();
        this.getQuotation();
        this.$Progress.finish();

        // registra evento de cerrar modal
        this.$nextTick(() => $('#flight-modal').on('hidden.bs.modal', () => {
            this.selectedTicket = null;
            eventHub.$emit('cleanFlightForm');
        }));
        this.$nextTick(() => $('#lodging-modal').on('hidden.bs.modal', () => {
            this.lodgingCrewWorkerId = null;
            this.showLodgingModal = false;
        }));
        this.$nextTick(() => $('#relocation-modal').on('hidden.bs.modal', () => {
            this.relocateCrewWorkerId = null;
            this.showRelocateModal = false;
        }));

        // registra eventos de aplicación
        eventHub.$on('reloadQuotationTicket', () => {
            $('#flight-modal').modal('hide');
            this.$nextTick(() => this.getQuotation());
        });

        eventHub.$on('reloadQuotationWorkers', () => {
            $('#flight-worker-modal').modal('hide');
            this.$nextTick(() => this.getQuotation());
        });

        eventHub.$on('lodgingSaved', () => {
            $('#lodging-modal').modal('hide');
            this.$nextTick(() => this.getQuotation());
        });

        eventHub.$on('relocateSaved', () => {
            $('#relocation-modal').modal('hide');
            this.$nextTick(() => this.getQuotation());
        });
    },
    data () {
        return {
            loading: true,
            lodgingCrewWorkerId: null,
            lodgingProviders: [],
            observations: null,
            emergencyButton: {
                quotation: { tickets: [] },
                entered: null,
                entered_at: null,
                replacement: {
                    worker: {
                        name: null,
                        last_name: null,
                        rut: null,
                        roles: []
                    }
                }
            },
            relocateCrewWorkerId: null,
            relocateProviders: [],
            selectedTicket: null,
            showFlightModal: false,
            showLodgingModal: false,
            showRelocateModal: false,
            tooltip: {
                lodging: 'Alojamiento asignado',
                notLodging: 'No se ha asignado el alojamiento solicitado',
                relocation: 'Traslado asignado',
                notRelocation: 'No se ha asignado el traslado solicitado',
            }
        }
    },
    destroyed() {
        eventHub.$off(['reloadQuotationTicket', 'reloadQuotationWorkers', 'lodgingSaved']);
    },
    methods: {
        deleteTicket (id) {
            if (this.canModify) {
                Swal.fire({
                    title: '¿Estás seguro que deseas eliminar este pasaje?',
                    text: 'No podrás revertir esta acción',
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Si',
                    cancelButtonText: 'No'
                }).then((result) => {
                    if (result.value) {
                        axios.delete(`/api/logistica/cotizaciones/${this.id}/pasajes/${id}`)
                            .then(() => {
                                this.getQuotation();
                                Swal.fire(
                                    'Éxito',
                                    'El pasaje fue eliminado correctamente',
                                    'success'
                                );
                            })
                            .catch(() => Swal.fire(
                                'Error',
                                'Error al eliminar pasaje',
                                'warning'
                            ));
                    }
                });
            }
        },

        getQuotation () {
            this.loading = true;
            this.$Progress.start();

            axios.get(`/api/logistica/boton-emergencia/${this.id}/cotizacion`)
                .then(({ data }) => {
                    this.emergencyButton = data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$launchError('Error al obtener información de cotización');
                    this.$Progress.fail();
                })
                .finally(() => this.loading = false);
        },

        getLodgingProviders () {
            if (this.lodgingProviders.length === 0) {
                axios.get('/api/bodega/proveedores/alojamiento')
                    .then(({ data }) => this.lodgingProviders = data.data)
                    .catch(() => this.$launchError('Error al obtener listado de proveedores de alojamiento'));
            }
        },

        getRelocateProviders () {
            if (this.relocateProviders.length === 0) {
                axios.get('/api/bodega/proveedores/traslado')
                    .then(({ data }) => this.relocateProviders = data.data)
                    .catch(() => this.$launchError('Error al obtener listado de proveedores de traslado'));
            }
        },

        getTicketNumber (crewWorkerId, stretch) {
            let index = null;

            this.quotation.tickets.forEach((t, i) => {
                if (index === null && t.flight_stretch.name === stretch) {
                    t.tickets.forEach(w => {
                        if (w.crew_worker_id === crewWorkerId) index = i;
                    });
                }
            });

            return index + 1;
        },

        saveQuotation () {
            if (this.canConfirm && this.canModify) {
                Swal.fire({
                    title: '¿Estás seguro que deseas guardar esta cotización?',
                    text: 'No podrás revertir esta acción',
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Si',
                    cancelButtonText: 'No'
                }).then((result) => {
                    if (result.value) {
                        axios.post(`/api/logistica/boton-emergencia/${this.id}/cotizacion`, {
                            observations: this.observations
                        })
                            .then(() => {
                                this.getQuotation();
                                Swal.fire(
                                    'Éxito',
                                    'La cotización fue guardada correctamente',
                                    'success'
                                );
                            })
                            .catch(() => Swal.fire(
                                'Error',
                                'Error al guardar cotización',
                                'warning'
                            ));
                    }
                });
            }
        },

        setLodging (crewWorkerId) {
            this.getLodgingProviders();
            this.lodgingCrewWorkerId = crewWorkerId;
            this.showLodgingModal = true;
            this.$nextTick(() => $('#lodging-modal').modal('show'));
        },

        setRelocation (crewWorkerId) {
            this.getRelocateProviders();
            this.relocateCrewWorkerId = crewWorkerId;
            this.showRelocateModal = true;
            this.$nextTick(() => $('#relocation-modal').modal('show'));
        },

        setTicket (id = null) {
            if (this.canModify) {
                this.selectedTicket = id;
                this.showFlightModal = true;
                this.$nextTick(() => $('#flight-modal').modal('show'));
            }
        }
    }
}
</script>

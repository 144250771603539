<template>
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <!-- header -->
            <div class="modal-header">
                <h5 class="modal-title">{{ title }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <!-- body -->
            <div class="modal-body container">
                <!-- alerta -->
            
                <!-- tabla -->
                
                <div class="table-responsive p-0">
                    <table class="table table-hover" v-if="theworkers.length>0">
                        <thead>
                            <tr>
                                <th class="align-middle"></th>
                                <th class="align-middle">Nombre</th>
                                <th class="align-middle">Rut</th>
                                <th class="align-middle">Rol</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="w in theworkers" :class="{ 'table-danger': w.with_emergency }" v-if="w.deleted_at==null">
                                <td class="align-middle">
                                    <input :value="w.id" type="checkbox" v-model="form[w.id].selected"
                                           :disabled="form[w.id].enabled==false">
                                  
                                </td>
                                <td class="align-middle">{{ w.name }} {{ w.last_name}}</td>
                                <td class="align-middle">{{ w.rut }}</td>
                                <td class="align-middle">{{ w.roles}}</td>
                            </tr>
                        </tbody>
                    </table>

                    <p class="text-center mb-0" v-else>
                        <i>No hay tripulantes disponibles para asociar a esta colación.</i>
                    </p>
                </div>
            </div>

            <!-- footer -->
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                <button type="button" class="btn btn-primary" @click="save">
                    Guardar
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import eventHub from "../../../eventHub";

    export default {
        props: ['snackId','crewId'],
        computed: {
            title () {
                 return 'Asociar tripulantes a colación CB'+this.snackId;
            },

        },
        created () {
            this.getWorkers();
        },
        data () {
            return {
                theworkers:{},

                form: {}
            }
        },
        methods: {
            fillForm () {
                this.theworkers.forEach(w => { if(w.used == 1){
                    this.form[w.id] = { 
                    id: w.id,
                    selected: true,
                    enabled: true
                }
                }if(w.used==0){
                    this.form[w.id] = { 
                    id: w.id,
                    selected: true,
                    enabled:false
                }
                }
                if(w.used==2){
                    this.form[w.id] = { 
                    id: w.id,
                    selected: false,
                    enabled:true
                }}
                });
            },
            save(){
                let w = [];

                    Object.keys(this.form).forEach(k => {
                        let i = this.form[k];

                         w.push({ worker_id: i.id,selected:i.selected,enabled:i.enabled });
                    });
                    console.log(w);
                    axios.post(`/api/administracion/colacion/asociar-trabajadores`, {
                        snack_id: this.snackId,
                        workers: w
                    })
                        .then(() => {
                            eventHub.$emit('reloadSnackWorkers');
                            this.$launchSuccess(`Éxito al asociar colación`);
                        })
                        .catch(() => this.$launchError(`Error al asociar colación`));
            },

             getWorkers () {
                axios.get('/api/administracion/tripulantes/colacion/'+this.snackId+'/'+this.crewId)
                    .then(({ data }) => {
                        console.log(data);
                        this.theworkers = data;
                        this.fillForm();
                        this.$Progress.finish();
                    })
                    .catch(() => {
                        this.$launchError('Error al obtener información de cotización');
                        this.$Progress.fail();
                    });
            },
        },
        watch: {
           /* workers () {
                this.form = {};
                this.fillForm();
            }*/
        }
    }
</script>

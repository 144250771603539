<template>
    <form @submit.prevent="saveRoles">
        <div class="row">
            <div class="col-4" v-for="r in roles">
                <input :id="r.name" type="checkbox" :value="r.id" v-model="workerRoles">
                <label :for="r.name">{{ r.name }}</label>
            </div>
        </div>

        <button type="submit" class="btn btn-primary float-right" :disabled="submit">
            <i class="fa fa-spinner fa-spin mr-2" v-if="submit"></i>
            {{ submit ? 'Guardando' : 'Guardar' }}
        </button>
    </form>
</template>

<script>
    export default {
        created () {
            this.$Progress.start();
            this.getRoles();
            this.getWorkerRoles();
            this.$Progress.finish();
        },
        data () {
            return {
                roles: [],
                submit: false,
                workerRoles: []
            }
        },
        methods: {
            saveRoles () {
                this.submit = true;
                this.$Progress.start();

                axios.post(`/api/trabajadores/${this.$parent.id}/roles`, {
                    roles: this.workerRoles
                })
                    .then(() => {
                        this.$Progress.finish();
                        this.$launchSuccess('Éxito al asociar roles');
                    })
                    .catch(() => {
                        this.$Progress.fail();
                        this.$launchError('Error al asociar roles');
                    })
                    .finally(() => this.submit = false);
            },

            getRoles () {
                this.$parent.loading = true;

                axios.get('/api/roles').then(({ data }) => this.roles = data.data)
                    .catch(() => this.$launchError('Error al obtener listado de roles'));
            },

            getWorkerRoles () {
                axios.get(`/api/trabajadores/${this.$parent.id}/roles`)
                    .then(({ data }) => this.workerRoles = data.data)
                    .catch(() => this.$launchError('Error al obtener roles asociados al tripulante'))
                    .finally(() => this.$parent.loading = false);
            }
        }
    }
</script>

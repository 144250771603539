<template>
    <div>
        <!-- breadcrumb -->
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Detalles de cotización</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link to="/">Inicio</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link to="/operaciones/cotizaciones">Cotizaciones</router-link>
                            </li>
                            <li class="breadcrumb-item active">Detalles de cotización</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>

        <!-- detalles -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col">
                        <div class="card">
                            <loading v-if="loading"/>

                            <div class="card-body">
                                <!-- información cotización -->
                                <div class="row mb-4">
                                    <div class="col">
                                        <span>
                                            <strong>Tarifa total:</strong> ${{ totalPrice | dotNumber }}.
                                        </span>
                                    </div>
                                    <div class="col">
                                        <span>
                                            <strong>Fecha creación:</strong> {{ quotation.entered_at | myDate }}.
                                        </span>
                                    </div>
                                    <div class="col">
                                        <span>
                                            <strong>Estado:</strong>
                                            <span class="badge" :class="{
                                                'bg-success': quotation.status,
                                                'bg-danger': quotation.status === 0,
                                                'bg-warning': quotation.status === null
                                            }" v-if="!loading">
                                                {{ quotation.status === null ? 'No validada': quotation.status === 1 ? 'Validada': 'Rechazada' }}
                                            </span>
                                        </span>
                                    </div>
                                    <div class="col">
                                        <span>
                                            <strong>Fecha confirmación:</strong> {{ quotation.confirmed_at | myDate }}.
                                        </span>
                                    </div>
                                </div>

                                <!-- información tripulación -->
                                <div class="row mb-4">
                                    <div class="col">
                                        <span>
                                            <strong>Cliente:</strong> {{ quotation.crew.client.name }}.
                                        </span>
                                    </div>
                                    <div class="col">
                                        <span>
                                            <strong>Embarcación:</strong> {{ quotation.crew.ship.name }}.
                                        </span>
                                    </div>
                                    <div class="col">
                                        <span>
                                            <strong>Fecha presentación:</strong> {{ quotation.crew.upload_date | myDate }}.
                                        </span>
                                    </div>
                                    <div class="col">
                                        <span>
                                            <strong>Fecha bajada real:</strong> {{ quotation.crew.real_download_date | myDate }}.
                                        </span>
                                    </div>
                                    <div class="col">
                                        <span>
                                            <strong>Fecha bajada protocolar:</strong> {{ quotation.crew.download_date | myDate }}.
                                        </span>
                                    </div>
                                </div>

                                <!-- motivo de rechazo -->
                                <div class="alert alert-danger mb-4" role="alert" v-if="isConfirmed && !quotation.status">
                                    <strong>Motivo:</strong> {{ quotation.rejection_reason }}.
                                </div>

                                <!-- pasajes -->
                                <h4 class="mb-4">Pasajes</h4>

                                <div class="row">
                                    <div class="col table-responsive p-0">
                                        <table class="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Aerolínea</th>
                                                    <th>Tramo</th>
                                                    <th>Ruta</th>
                                                    <th>Fecha</th>
                                                    <th>Hora salida</th>
                                                    <th>Hora llegada</th>
                                                    <th>Tarifa</th>
                                                    <th>Cantidad</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(t, i) in quotation.tickets">
                                                    <td>{{ i + 1 }}</td>
                                                    <td>{{ t.airline.name }}</td>
                                                    <td>{{ t.flight_stretch.name }}</td>
                                                    <td>
                                                        {{ t.departure_airport.iata }} -
                                                        {{ t.arrival_airport.iata }}
                                                    </td>
                                                    <td>{{ t.flight_date | myDate }}</td>
                                                    <td>{{ t.departure_time | myHour }}</td>
                                                    <td>{{ t.arrival_time | myHour }}</td>
                                                    <td>
                                                        ${{ t.price | dotNumber }}
                                                        <strong v-if="t.tickets.length > 0">
                                                            (Total ${{ t.price * t.tickets.length | dotNumber }})
                                                        </strong>
                                                    </td>
                                                    <td>{{ t.tickets.length }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <!-- trabajadores -->
                                <h4 class="mb-4">Trabajadores</h4>

                                <div class="row">
                                    <div class="col table-responsive p-0">
                                        <table class="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th class="align-middle">Nombre</th>
                                                    <th class="align-middle">Rut</th>
                                                    <th class="align-middle">Rol</th>
                                                    <th class="align-middle" v-if="someLodging">Alojamiento</th>
                                                    <th class="align-middle" v-if="someRelocation">Traslado</th>
                                                    <th class="align-middle">Pasaje ida</th>
                                                    <th class="align-middle">Pasaje vuelta</th>
                                                    <th class="align-middle">Observaciones</th>
                                                    <th class="align-middle">Acciones</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="w in quotation.crew.workers" v-if="w.is_replacement==0">
                                                    <td class="align-middle">
                                                        {{ w.worker.name }} {{ w.worker.last_name }}
                                                    </td>
                                                    <td class="align-middle">{{ w.worker.rut }}</td>
                                                    <td class="align-middle">
                                                        {{ w.worker.roles.map(r => r.name) | list }}
                                                    </td>
                                                    <td class="align-middle" v-if="someLodging">
                                                        <div v-if="w.lodging && w.lodging_data">
                                                            <span>Hay alojamiento</span>
                                                        </div>
                                                        <span v-else>-</span>
                                                    </td>
                                                    <td class="align-middle" v-if="someRelocation">
                                                        <div v-if="w.lodging && w.relocation_data">
                                                            <span>Hay traslado</span>
                                                        </div>
                                                        <span v-else>-</span>
                                                    </td>
                                                    <td class="align-middle">
                                                        #{{ getTicketNumber(w.id, 'Ida') }}
                                                    </td>
                                                    <td class="align-middle">
                                                        #{{ getTicketNumber(w.id, 'Vuelta') }}
                                                    </td>
                                                    <td class="align-middle">
                                                        {{ w.observations ? w.observations: '-' }}
                                                    </td>
                                                    <td class="align-middle">
                                                        <router-link :to="'/admin/trabajadores/' + w.worker.id"
                                                                     v-tooltip="'Ficha del trabajador'">
                                                            <i class="fa fa-info-circle green"></i>
                                                        </router-link>

                                                        <span v-if="w.lodging">/</span>
                                                        <a href="#" @click="viewLodging(w.id)" v-tooltip="'Alojamiento'"
                                                           v-if="w.lodging">
                                                            <i class="fa orange fa-bed"/>
                                                        </a>

                                                        <span v-if="w.relocation">/</span>
                                                        <a href="#" @click="viewRelocation(w.id)" v-tooltip="'Traslado'"
                                                           v-if="w.relocation">
                                                            <i class="fa blue fa-bus-alt"/>
                                                        </a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <button class="btn btn-outline-danger" @click="openRejectModal" v-if="!isConfirmed">
                                    Rechazar
                                </button>

                                <button class="btn btn-primary float-right" @click="validateQuotation"
                                        v-if="!isConfirmed">
                                    Validar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- modal rechazo -->
                <div class="modal fade" id="reject-modal" tabindex="-1" role="dialog" aria-labelledby="form-dialog"
                     aria-hidden="true" v-if="showRejectModal" data-backdrop="static" data-keyboard="false">
                    <div class="modal-dialog" role="document">
                        <reject-quotation/>
                    </div>
                </div>

                <!-- modal alojamiento -->
                <div class="modal fade" id="lodging-modal" tabindex="-1" role="dialog" aria-labelledby="form-dialog"
                     aria-hidden="true" v-if="showLodgingModal" data-backdrop="static" data-keyboard="false">
                    <div class="modal-dialog" role="document">
                        <lodging/>
                    </div>
                </div>

                <!-- modal traslado -->
                <div class="modal fade" id="relocation-modal" tabindex="-1" role="dialog" aria-labelledby="form-dialog"
                     aria-hidden="true" v-if="showRelocationModal" data-backdrop="static" data-keyboard="false">
                    <div class="modal-dialog" role="document">
                        <relocation/>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Loading from "../Common/Loading";
    import Lodging from "./QuotationDetails/Lodging";
    import RejectQuotation from "./QuotationDetails/RejectQuotation";
    import Relocation from "./QuotationDetails/Relocation";
    import eventHub from "../../eventHub";

    export default {
        components: {Lodging, Loading, RejectQuotation, Relocation },
        computed: {
            id () {
                return this.$route.params.id;
            },
            isConfirmed () {
                return this.quotation.status !== null;
            },
            // índica si existe al menos un trabajador con alojamiento, así se oculta la respectiva columna en la tabla
            someLodging () {
                return this.quotation.crew.workers.filter(w => w.lodging).length > 0;
            },
            // índica si existe al menos un trabajador con traslado, así se oculta la respectiva columna en la tabla
            someRelocation () {
                return this.quotation.crew.workers.filter(w => w.relocation).length > 0;
            },
            totalPrice () {
                if (this.quotation.crew.workers.length > 0) {
                    return this.quotation.tickets.map(w => w.price * w.tickets.length).reduce((a, b) => a + b, 0);
                } else return 0;
            }
        },
        created () {
            this.getQuotation();

            eventHub.$on('quotationRejected', () => {
                $('#reject-modal').modal('hide');
                this.getQuotation();
            });
        },
        data () {
            return {
                loading: false,
                quotation: {
                    crew: {
                        client: { name: null },
                        download_date: null,
                        ship: { name: null },
                        upload_date: null,
                        workers: []
                    },
                    entered_at: null,
                    status: null,
                    validated_at: null,
                    tickets: []
                },
                lodgingCrewWorkerId: null,
                relocationCrewWorkerId: null,
                showLodgingModal: false,
                showRejectModal: false,
                showRelocationModal: false,
            }
        },
        destroyed() {
            eventHub.$off('quotationRejected');
        },
        methods: {
            formatRouteTooltip (departure, arrival) {
                return `${departure} - ${arrival}`;
            },

            getTicketNumber (crewWorkerId, stretch) {
                let index = null;

                this.quotation.tickets.forEach((t, i) => {
                    if (index === null && t.flight_stretch.name === stretch) {
                        t.tickets.forEach(w => {
                            if (w.crew_worker_id === crewWorkerId) index = i;
                        });
                    }
                });

                return index + 1;
            },

            getQuotation () {
                this.loading = true;
                this.$Progress.start();

                axios.get('/api/operaciones/cotizaciones/' + this.id)
                    .then(({ data }) => {
                        this.quotation = data.data;
                        this.$Progress.finish();
                    })
                    .catch(() => {
                        this.$launchError('Error al obtener detalle de cotización');
                        this.$Progress.fail();
                    })
                    .finally(() => this.loading = false);
            },

            openRejectModal () {
                if (!this.isConfirmed) {
                    this.showRejectModal = true;
                    this.$nextTick(() => $('#reject-modal').modal('show'));
                }
            },

            validateQuotation () {
                if (!this.isConfirmed) {
                    Swal.fire({
                        title: '¿Estás seguro que deseas validar esta cotización?',
                        text: 'No podrás revertir esta acción',
                        showCancelButton: true,
                        confirmButtonColor: '#d33',
                        cancelButtonColor: '#3085d6',
                        confirmButtonText: 'Si',
                        cancelButtonText: 'No'
                    }).then((result) => {
                        if (result.value) {
                            axios.post(`/api/operaciones/cotizaciones/${this.id}/validar`)
                                .then(() => {
                                    this.$launchSuccess('Éxito al validar cotización');
                                    this.getQuotation();
                                })
                                .catch(() => this.$launchError('Error al validar cotización'));
                        }
                    });
                }
            },

            viewLodging (crewWorkerId) {
                this.lodgingCrewWorkerId = crewWorkerId;
                this.showLodgingModal = true;
                this.$nextTick(() => $('#lodging-modal').modal('show'));
            },

            viewRelocation (crewWorkerId) {
                this.relocationCrewWorkerId = crewWorkerId;
                this.showRelocationModal = true;
                this.$nextTick(() => $('#relocation-modal').modal('show'));
            }
        }
    }
</script>

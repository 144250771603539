<template>
    <form>
          <div class="form-row">
            <div class="form-group col-md-4">
            
            </div>
            <div class="form-group col-md-4" v-if="!hasRequest">
            <label style="text-align:center;">Viveres para <input v-model.number="provisions_quantity" type="number" 
                          min="0" class="form-control"/> personas</label>
            
            </div>
            <div class="form-group col-md-4" v-else>
                <span>Viveres para {{provisions_quantity}} persona(s)</span>            
            </div>
             <div class="form-group col-md-4">
          
            </div>
        </div>

           
    </form>
</template>

<script>
    export default {
        computed: {
            hasRequest () {
                return this.$parent.hasRequest;
            },
            provisions_quantity: {
                get () {
                    return this.$parent.provisions_quantity;
                },
                set (v) {
                   return this.$parent.provisions_quantity = v;
                }
            }
        },
        data () {
            return {
               
            }
        },
        created () {
           // this.getWorkers();
            //this.getSuppliers();
            
        },
        methods: {
            /*getWorkers () {
                axios.get(`/api/supervisor/${this.$parent.id}/trabajadores/`)
                    .then(({ data }) => this.$parent.provisions_quantity = (data.data).length)
                    .catch(() => this.$launchError());
            },*/
           /* getSuppliers () {

                    axios.get('/api/bodega/proveedores')
                        .then(({ data }) => this.suppliers = data.data)
                        .catch(() => this.$launchError('Error al obtener proveedores'));

            },*/


        }
    }
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c('form',[(!_vm.hasRequest || (_vm.hasRequest && _vm.items.length > 0))?_c('div',{staticClass:"table-responsive"},[_c('div',[_c('v-app',[_c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Búsqueda","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_vm._v(" "),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.datos,"search":_vm.search,"loading":_vm.loading,"items-per-page":100,"no-results-text":"No se han encontrado articulos","no-data-text":"No existen articulos","loading-text":"Cargando... Espere","footer-props":{
           'items-per-page-text':'Articulos por página',
           'items-per-page-options': [100, 200, 300, -1]
      }},scopedSlots:_vm._u([{key:"item.size",fn:function(ref){
      var item = ref.item;
      var index = ref.index;
return [(!_vm.hasRequest)?_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.warehouseItemsForm[item.id].size_id),expression:"warehouseItemsForm[item.id].size_id"}],staticClass:"form-control",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.warehouseItemsForm[item.id], "size_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{domProps:{"value":null}},[_vm._v("Escoja una talla")]),_vm._v(" "),_vm._l((item.sizes),function(s){return _c('option',{domProps:{"value":s.id}},[_vm._v(_vm._s(s.name !== '' ? s.name: 'STD'))])})],2):_c('span',[_vm._v(_vm._s(item.size !== '' ? item.size: 'STD'))])]}},{key:"item.quantity",fn:function(ref){
      var item = ref.item;
      var index = ref.index;
return [(!_vm.hasRequest)?_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.warehouseItemsForm[item.id].quantity),expression:"warehouseItemsForm[item.id].quantity",modifiers:{"number":true}}],staticClass:"form-control",attrs:{"min":"0","type":"number"},domProps:{"value":(_vm.warehouseItemsForm[item.id].quantity)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.warehouseItemsForm[item.id], "quantity", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}}):_c('span',[_vm._v("\n                                "+_vm._s(item.quantity)+"\n                            ")])]}},{key:"item.observations",fn:function(ref){
      var item = ref.item;
      var index = ref.index;
return [(!_vm.hasRequest)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.warehouseItemsForm[item.id].observations),expression:"warehouseItemsForm[item.id].observations"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.warehouseItemsForm[item.id].observations)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.warehouseItemsForm[item.id], "observations", $event.target.value)}}}):_c('span',[_vm._v("\n                                "+_vm._s(_vm._f("dash")(item.observations))+"\n                            ")])]}},{key:"footer.page-text",fn:function(items){return [_vm._v(" "+_vm._s(items.pageStart)+" - "+_vm._s(items.pageStop)+" de "+_vm._s(items.itemsLength)+" ")]}}],null,false,3665683819)})],1)],1)],1),_vm._v(" "),_vm._l((_vm.items),function(i){return _c('tr')})],2):_c('div',[_vm._m(0)])]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('i',[_vm._v("No se añadieron artículos de bodega a la solicitud de abastecimiento.")])])}]

export { render, staticRenderFns }
<template>
    <div>
        <!-- breadcrumb -->
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Solicitud de abastecimiento</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link to="/">Inicio</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link to="/supervisor/tripulaciones">Tripulaciones</router-link>
                            </li>
                            <li class="breadcrumb-item active">Solicitud de abastecimiento</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>

        <!-- edición -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col">
                        <div class="card">
                            <loading v-if="loading"/>

                            <div class="card-header">
                                <ul class="nav nav-pills">
                                    <li class="nav-item" v-for="t in tabs">
                                        <a class="nav-link" :class="{'active': tab === t.component}"
                                           @click="tab = t.component">
                                            {{ t.title }}
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div class="card-body">
                                <component :is="tab"/>

                                <button class="btn btn-success float-right" v-if="!hasRequest" @click="saveRequest">
                                    Guardar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import EPP from "./SupplyRequest/EPP";
    import Loading from "../Common/Loading";
    import WarehouseItems from "./SupplyRequest/WarehouseItems";
    import Viveres from "./SupplyRequest/Viveres";

    export default {
        components: { EPP, Loading, WarehouseItems,Viveres },
        computed: {
            id () {
                return this.$route.params.id;
            },
            hasRequest () {
                return this.supplyRequest !== null;
            }
        },
        created () {
            this.getSupplyRequest(true);
            this.getWorkers();
        },
        data () {
            return {
                epp: [],
                loading: true,
                supplyRequest: null,
                tab: 'WarehouseItems',
                tabs: [
                    { title: 'Artículos de bodega', component: 'WarehouseItems' },
                    { title: 'EPP', component: 'EPP' },
                    { title: 'Viveres', component: 'Viveres' }
                ],
                warehouseItems: [],
                warehouseItemsForm: {},
                workers: [],
                workersEPPForm: {},
                provisions_quantity:0
            }
        },
        methods: {
            getWorkers () {
                axios.get(`/api/supervisor/${this.$route.params.id}/trabajadores/`)
                    .then(({ data }) => this.provisions_quantity = data.data.filter(w => w.deleted_at == null).length)
                    .catch(() => this.$launchError());
            },
            getSupplyRequest (getItems = false) {
                this.loading = true;

                axios.get(`/api/supervisor/tripulaciones/${this.$route.params.id}/solicitud-abastecimiento`)
                    .then(({ data }) => this.supplyRequest = data.data)
                    .catch(() => this.$launchError('Error al obtener solicitud de abastecimiento'))
                    .finally(() => {
                        if (getItems && !this.hasRequest) {
                            this.getWarehouseItems();
                        } else {
                            this.loading = false
                        }
                    });
            },

            getWarehouseItems () {
                if (!this.hasRequest && this.warehouseItems.length === 0) {
                    this.loading = true;

                    axios.get('/api/supervisor/articulos')
                        .then(({ data }) => {
                            this.warehouseItems = data.data;
                            this.warehouseItems.forEach(i => {
                                let size_id = null;

                                if (i.sizes.length === 1) {
                                    size_id = i.sizes[0].id;
                                }

                                this.warehouseItemsForm[i.id] = {
                                    id: i.id,
                                    quantity: 0,
                                    observations: null,
                                    size_id
                                }
                            });
                        })
                        .catch(() => this.$launchError('Error al obtener artículos'))
                        .finally(() => this.loading = false);
                }
            },

            saveRequest () {
                if (!this.hasRequest) {
                    let form = {
                        items: [],
                        epp: [],
                        quantity:null
                    };

                    // obtiene los ítemes de bodega
                    Object.keys(this.warehouseItemsForm).forEach(i => {
                        const item = this.warehouseItemsForm[i];

                        if (item.quantity > 0) form.items.push({
                            quantity: item.quantity,
                            observations: item.observations,
                            id: item.size_id
                        });
                    });

                    // obtiene las entregas de epp
                    Object.keys(this.workersEPPForm).forEach(i => {
                        const delivery = this.workersEPPForm[i];

                        Object.keys(delivery).forEach(j => {
                            if (delivery[j] !== null) form.epp.push({
                                id: delivery[j],
                                worker_id: j
                            })
                        })
                    });

                    form.quantity = this.provisions_quantity;
                   // console.log(form);

                    if (form.quantity > 0) {
                        Swal.fire({
                            title: '¿Estás seguro que deseas crear esta solicitud?',
                            text: 'No podrás revertir esta acción',
                            showCancelButton: true,
                            confirmButtonColor: '#d33',
                            cancelButtonColor: '#3085d6',
                            confirmButtonText: 'Si',
                            cancelButtonText: 'No'
                        }).then((result) => {
                            if (result.value) {
                                axios.post(`/api/supervisor/tripulaciones/${this.$route.params.id}/solicitud-abastecimiento`, form)
                                    .then(() => {
                                        Swal.fire(
                                            'Éxito',
                                            'Éxito al crear solicitud de abastecimiento',
                                            'success'
                                        );
                                        this.getSupplyRequest();
                                    })
                                    .catch(() => Swal.fire(
                                        'Error',
                                        'Error al crear solicitud de abastecimiento',
                                        'warning'
                                    ));
                            }
                        });
                    } else {
                        this.$launchError('Debe añadir al menos un artículo o una entrega de EPP');
                    }
                }
            }
        }
    }
</script>

<template>
    <div id="edit-crew-container">
        <!-- breadcrumb -->
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Generar compra</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link to="/">Inicio</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link to="/bodega/solicitudes-abastecimiento">Solicitudes abastecimiento</router-link>
                            </li>
                            <li class="breadcrumb-item active">Generar compra</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>

        <!-- edición -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col">
                        <div class="card">
                            <loading v-if="loading"/>

                            <div class="card-body">
                                <!-- información -->
                                <div class="row mb-4">
                                    <div class="col">
                                        <span><strong>Cliente:</strong> {{ crew.client.name }}.</span>
                                    </div>
                                    <div class="col">
                                        <span><strong>Embarcación:</strong> {{ crew.ship.name }}.</span>
                                    </div>
                                    <div class="col">
                                        <span><strong>Fecha presentanción:</strong> {{ crew.upload_date | myDate }}.</span>
                                    </div>
                                    <div class="col">
                                        <span><strong>Fecha bajada real:</strong> {{ crew.real_download_date | myDate }}.</span>
                                    </div>
                                    <div class="col">
                                        <span><strong>Fecha bajada protocolar:</strong> {{ crew.download_date | myDate }}.</span>
                                    </div>
                                </div>

                                <h4 class="mb-4" v-if="hasItems">Viveres para {{crew.provisions.workers_quantity}} persona(s)</h4>

                                <div class="row" :class="{'mb-2': !canConfirm, 'mb-4': canConfirm}">
                                    <div class="col form-group">
                                        <label>Fecha de compra{{ canConfirm ? '': ':' }}</label>
                                        <input type="date" class="form-control" v-model="form.groceries_date"
                                               v-if="canConfirm">
                                        <span v-else>{{ crew.provisions.date | myDate }}.</span>
                                    </div>

                                    <div class="col form-group">
                                        <label>Proveedor{{ canConfirm ? '': ':' }}</label>
                                        <select class="form-control" v-model="form.groceries_supplier_id" v-if="canConfirm"
                                                :disabled="suppliers_alimentacion.length === 0">
                                            <option :value="null" disabled>Escoja un proveedor</option>
                                            <option :value="s.id" v-for="s in suppliers_alimentacion">{{ s.name }}</option>
                                        </select>
                                        <span v-else>{{ crew.provisions.supplier }}.</span>
                                    </div>

                                    <div class="col form-group">
                                        <label>Monto</label>
                                        <input type="number" min='0' class="form-control" v-model="form.groceries_price"
                                               v-if="canConfirm">
                                        <span v-else>${{ crew.provisions.price | dotNumber}}.</span>
                                    </div>

                                       <div class="col form-group">
                                        <label>Observación</label>
                                        <input type="text" class="form-control" v-model="form.groceries_observation"
                                               v-if="canConfirm">
                                        <span v-else>{{ crew.provisions.observation }}.</span>
                                    </div>
                                </div>

                                <!-- artículos -->
                                <h4 class="mb-4" v-if="hasItems">Artículos de bodega</h4>

                                <div class="table-responsive" v-if="hasItems">
                                    <table class="table table-hover">
                                        <thead>
                                            <tr>
                                                <th class="align-middle">Artículo</th>
                                                <th class="align-middle">Talla</th>
                                                <th class="align-middle">Cantidad</th>
                                                <th class="align-middle">Unidad de medida</th>
                                                <th class="align-middle">Observaciones</th>
                                                <th class="align-middle">Valor</th>
                                                 
                                                  <th class="align-middle">Acción</th>
                                                <th class="align-middle">Proveedor</th>
                                                <th class="align-middle">Fecha Compra</th>
                                               
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="i in warehouseItems">
                                                <td class="align-middle">{{ i.size.item.name }}</td>
                                                <td class="align-middle">{{ i.size.name === '' ? '-': i.size.name }}</td>
                                                <td class="align-middle">
                                                    {{ i.quantity | dotNumber }}
                                                </td>
                                                <td class="align-middle">
                                                    {{ i.size.item.measurement_unit.name }}
                                                </td>
                                                <td class="align-middle">
                                                    {{ i.observations | dash }}
                                                </td>
                                                <td class="align-middle">
                                                    ${{ form.warehouse_items[i.id].price | dotNumber }}
                                                    <strong v-if="i.quantity > 1">
                                                        (Total ${{ form.warehouse_items[i.id].price * i.quantity | dotNumber }})
                                                    </strong>
                                                </td>

                                                <td class="align-middle">
                                                    <p class="mb-0" @click="openWarehouseItemModal(i.id,i.quantity)" v-if="canConfirm">
                                                        <span>
                                                            {{ form.warehouse_items[i.id].action === 'purchase' ? 'Incluir en compra': 'Utilizar stock' }}
                                                        </span>
                                                        <i class="fa fa-edit green" v-tooltip="'Cambiar'"></i>
                                                    </p>
                                                    <span v-else>
                                                        {{ i.action === 'purchase' ? 'Incluir en compra': 'Utilizar stock' }}
                                                    </span>
                                                </td>

                                               

                                                <td class="align-middle">

                                                     <select class="form-control"
                                                                :disabled="suppliers.length === 0" v-model="form.warehouse_items[i.id].supplier_id" v-if="form.warehouse_items[i.id].action === 'purchase' && canConfirm">
                                                            <option :value="null" disabled>Escoja un proveedor</option>
                                                            <option :value="i.id" v-for="i in suppliers">{{ i.name }}</option>
                                                        </select>

                                                               <select class="form-control"
                                                                 v-else disabled>
                                                            <option :value="null" selected>Escoja un proveedor</option>
                                                          
                                                        </select>
                                                    

                                                </td>

                                                 <td class="align-middle">
                                                     <input type="date" class="form-control" v-model="form.warehouse_items[i.id].date" v-if="form.warehouse_items[i.id].action === 'purchase' && canConfirm"
                                                          >
                                                      <input type="date" class="form-control"
                                                          v-else disabled>
                                                </td>
                                                
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <!-- epp -->
                                <h4 class="mb-4" v-if="hasEPP">Entrega de EPP</h4>

                                <div class="table-responsive" v-if="hasEPP">
                                    <table class="table table-hover">
                                        <thead>
                                            <tr>
                                                <th class="align-middle">Nombre</th>
                                                <th class="align-middle">Rut</th>
                                                <th class="align-middle">Roles</th>
                                                <th class="align-middle">EPP</th>
                                                <th class="align-middle">Talla</th>
                                                <th class="align-middle">Valor</th>
                                                
                                                
                                                <th class="align-middle">Acción</th>
                                                <th class="align-middle">Proveedor</th>
                                                <th class="align-middle">Fecha Compra</th>
                                                
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="i in crew.supply_request.epp_deliveries">
                                                <td class="align-middle">{{ i.worker.name }} {{ i.worker.last_name }}</td>
                                                <td class="align-middle">{{ i.worker.rut }}</td>
                                                <td class="align-middle">{{ i.worker.roles.map(r => r.name) | list }}</td>
                                                <td class="align-middle">{{ i.epp.item.name }}</td>
                                                <td class="align-middle">{{ i.epp.name === '' ? 'STD' : i.epp.name }}</td>
                                                <td class="align-middle">
                                                    ${{ form.epp_deliveries[i.id].price | dotNumber }}
                                                </td>


                                                
                                                <td class="align-middle">
                                                    <p class="mb-0" @click="openEPPDeliveriesModal(i.id)" v-if="canConfirm">
                                                        <span>
                                                            {{ form.epp_deliveries[i.id].action === 'purchase' ? 'Incluir en compra': 'Utilizar stock' }}
                                                        </span>
                                                        <i class="fa fa-edit green" v-tooltip="'Cambiar'"></i>
                                                    </p>
                                                    <span v-else>
                                                        {{ i.action === 'purchase' ? 'Incluir en compra': 'Utilizar stock' }}
                                                    </span>
                                                </td>

                                                <td>

                                                      
                                                        <select class="form-control"
                                                                :disabled="suppliers.length === 0" v-model="form.epp_deliveries[i.id].supplier_id" v-if="form.epp_deliveries[i.id].action === 'purchase' && canConfirm">
                                                            <option :value="null" disabled>Escoja un proveedor</option>
                                                            <option :value="i.id" v-for="i in suppliers">{{ i.name }}</option>
                                                        </select>

                                                          <select class="form-control"
                                                                 v-else disabled>
                                                            <option :value="null" selected>Escoja un proveedor</option>
                                                           
                                                        </select>

                                                </td>

                                                <td class="align-middle">
                                                     <input type="date" class="form-control" v-model="form.epp_deliveries[i.id].date"
                                                          v-if="form.epp_deliveries[i.id].action === 'purchase' && canConfirm">

                                                     <input type="date" class="form-control" 
                                                          v-else disabled>

                                                   
                                                </td>

                                                


                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <button class="btn btn-primary float-right" @click="confirmPurchase" v-if="canConfirm">
                                    Confirmar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- modal acciones items -->
                <div class="modal fade" id="item-actions-modal" tabindex="-1" role="dialog" aria-labelledby="form-dialog"
                     aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <item-actions :item-purchase-id="selectedItemPurchaseId" :quantity="quantity" v-if="showItemActionsModal"/>
                </div>

                <!-- modal acciones EPP -->
                <div class="modal fade" id="epp-actions-modal" tabindex="-1" role="dialog" aria-labelledby="form-dialog"
                     aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <e-p-p-actions :e-p-p-delivery-id="selectedEPPDeliveryId" v-if="showEPPActionsModal"/>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import EPPActions from "./PurchaseSupplyRequest/EPPActions";
    import ItemActions from "./PurchaseSupplyRequest/ItemActions";
    import Loading from "../Common/Loading";
    import moment from "moment";

    export default {
        components: { EPPActions, ItemActions, Loading },
        created () {
            this.getCrew();
            this.getSuppliers();
             this.getSuppliersFood();

            // registra evento de cerrar modal
            this.$nextTick(() => $('#epp-actions-modal').on('hidden.bs.modal', () => {
                // actualiza el render ya que no se hacía hasta abrir nuevamente el modal
                this.$forceUpdate();
            }));

            this.$nextTick(() => $('#item-actions-modal').on('hidden.bs.modal', () => {
                // actualiza el render ya que no se hacía hasta abrir nuevamente el modal
                this.$forceUpdate();
            }));
        },
        computed: {
            canConfirm () {
                return this.crew.supply_request.entered_purchase === null;
            },
            hasEPP () {
                return this.crew.supply_request.epp_deliveries.length > 0;
            },
            hasItems () {
                return this.warehouseItems.length > 0;
            },
            warehouseItems () {
                return this.crew.supply_request.warehouse_items;
            }
        },
        data () {
            return {
                crew: {
                    client: { name: null },
                    ship: { name: null },
                    supply_request: {
                        epp_deliveries: [],
                        entered_purchase: null,
                        warehouse_items: []
                    }
                },
                form: {
                    purchase: moment().format('yyyy-MM-DD'),
                    supplier_id: {},
                    epp_deliveries: {},
                    warehouse_items: {},
                    groceries_date:null,
                    groceries_supplier_id:null,
                    groceries_price:0,
                    groceries_observation:''

                },
                loading: true,
                selectedEPPDeliveryId: null,
                selectedItemPurchaseId: null,
                showEPPActionsModal: false,
                showItemActionsModal: false,
                suppliers: [],
                suppliers_alimentacion:[],
                quantity:null,
            }
        },
        methods: {
            confirmPurchase () {
                if (this.canConfirm) {


                    if((this.form.groceries_date==null || this.form.groceries_supplier_id==null) && this.crew.provisions.workers_quantity>0){
                        this.$launchError('Debe completar la sección de viveres');
                    }else{
                    
                    let form = { provisions_id:this.crew.provisions.id,date: this.form.groceries_date, supplier_id: this.form.groceries_supplier_id, total_price: this.form.groceries_price, observation: this.form.groceries_observation };


                                        // ordena los artículos de bodega
                                let i = [];
                                let doit = 1;

                                Object.keys(this.form.warehouse_items).forEach(w => {
                                    const item = this.form.warehouse_items[w];

                                    let purchase_date = null;
                                    let purchase_supplier = null;
                                    if(item.action==='purchase'){
                                        if(item.date==undefined || item.supplier_id==undefined){
                                            doit = 0;
                                        }
                                        purchase_date = item.date;
                                        purchase_supplier = item.supplier_id;
                                    }

                                    i.push({
                                        id: w,
                                        action: item.action,
                                        price: item.price,
                                        date: purchase_date,
                                        supplier_id:purchase_supplier
                                    });
                                });

                                form.warehouse_items = i;

                                // ordena los epp
                                let e = [];

                                Object.keys(this.form.epp_deliveries).forEach(d => {
                                    const epp = this.form.epp_deliveries[d];

                                    let purchase_date = null;
                                    let purchase_supplier = null;
                                    if(epp.action==='purchase'){
                                         if(epp.date==undefined || epp.supplier_id==undefined){
                                            doit = 0;
                                        }
                                        purchase_date = epp.date;
                                        purchase_supplier = epp.supplier_id;
                                    }

                                    e.push({
                                        id: d,
                                        action: epp.action,
                                        price: epp.price,
                                        date: purchase_date,
                                        supplier_id:purchase_supplier
                                    });
                                });

                                form.epp_deliveries = e;

                              console.log(form);

                                if(doit==0){
                                     this.$launchError('Debe completar todos los proveedores y fechas de compra');
                                }else{
                                 Swal.fire({
                                    title: '¿Estás seguro que deseas genera esta compra?',
                                    text: 'No podrás revertir esta acción',
                                    showCancelButton: true,
                                    confirmButtonColor: '#d33',
                                    cancelButtonColor: '#3085d6',
                                    confirmButtonText: 'Si',
                                    cancelButtonText: 'No'
                                }).then((result) => {
                                    if (result.value) {
                                        axios.post(`/api/bodega/tripulaciones/${this.$route.params.id}/comprar-solicitud-abastecimiento`, form)
                                            .then(() => {
                                                Swal.fire(
                                                    'Éxito',
                                                    'La compra fue generada correctamente',
                                                    'success'
                                                );

                                                this.getCrew();
                                            }).catch(() => Swal.fire(
                                                'Error',
                                                'Error al generar compra',
                                                'warning'
                                            ));
                                    }
                                });
                                }

                            
                                
                    }

                }
            },

            getCrew () {
                this.$Progress.start();
                this.loading = true;

                axios.get(`/api/bodega/tripulaciones/${this.$route.params.id}`)
                    .then(({ data }) => {
                        this.crew = data.data;
                        this.$Progress.finish();

                        // genera el formulario para presentación de acción
                        this.crew.supply_request.epp_deliveries.forEach(d => {

                            let theaction = 'purchase';
                            if(d.action!=null){
                                theaction = d.action;
                            }

                            this.form.epp_deliveries[d.id] = {
                                action: theaction,
                                price: this.canConfirm ? d.epp.item.price : d.price,
                                warehouseQuantity: d.epp.quantity,
                                warehouse_item_size_id: d.warehouse_item_size_id,
                                supplier_id: d.supplier_id,
                                date: d.purchase_date,
                            };
                        });


                        this.crew.supply_request.warehouse_items.forEach(i => {

                            let theaction = 'purchase';
                            if(i.action!=null){
                                theaction = i.action;
                            }

                            this.form.warehouse_items[i.id] = {
                                action: theaction,
                                price: this.canConfirm ? i.size.item.price : i.price,
                                warehouseQuantity: i.size.quantity,
                                supplier_id: i.supplier_id,
                                date: i.purchase_date,
                            };
                        });


                        console.log(this.crew.provisions);
                        this.form.groceries_date = this.crew.provisions.date;
                        this.form.groceries_supplier_id = this.crew.provisions.supplier_id;
                        this.form.groceries_price = this.crew.provisions.price;
                        this.form.groceries_observation = this.crew.provisions.observation;

                        if (this.canConfirm){
                            this.getSuppliers();
                        }
                         this.getSuppliersFood();
                        
                    })
                    .catch(() => {
                        this.$launchError('Error al obtener datos de tripulación');
                        this.$Progress.fail();
                    })
                    .finally(() => this.loading = false);
            },

            openEPPDeliveriesModal (EPPDeliveryId) {
                this.selectedEPPDeliveryId = EPPDeliveryId;
                this.showEPPActionsModal = true;
                this.$nextTick(() => $('#epp-actions-modal').modal('show'));
            },

            openWarehouseItemModal (itemPurchaseId,quantity) {
                this.selectedItemPurchaseId = itemPurchaseId;
                this.quantity = quantity;
                this.showItemActionsModal = true;
                this.$nextTick(() => $('#item-actions-modal').modal('show'));
            },
             getSuppliers () {
                axios.get('/api/administracion/facturacion/proveedores/2')
                    .then(({ data }) => this.suppliers = data.data)
                    .catch(() => {
                        this.$launchError('Error al obtener listado de proveedores de bodega');
            
                    })
                    .finally(() => {
                  
                        
                    
                    });
                
            },
             getSuppliersFood () {
                axios.get('/api/administracion/facturacion/proveedores/1')
                    .then(({ data }) => this.suppliers_alimentacion = data.data)
                    .catch(() => {
                        this.$launchError('Error al obtener listado de proveedores de viveres');
            
                    })
                    .finally(() => {
                  
                        
                    
                    });
                
            },
        }
    }
</script>

<template>
<v-app>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <!-- header -->
                        <div class="card-header">
                            <h3 class="card-title">Compras</h3>

                            <div class="card-tools">
                                <router-link to="/bodega/compras/nueva" class="btn btn-sm btn-primary">
                                    <i class="fa fa-plus-square"></i>
                                    Nueva compra
                                </router-link>
                            </div>
                        </div>

<v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Búsqueda"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
  <v-data-table
      :headers="headers"
      :items="datos"
      :search="search"
       :loading="loading"
       :items-per-page="30"
       no-results-text="No se han encontrado compras"
       no-data-text="No existen compras"
      class="elevation-1"
        loading-text="Cargando... Espere"
         :footer-props="{
           'items-per-page-text':'Compras por página',
           'items-per-page-options': [20, 50, 100, -1]
      }"
    >
 <template v-slot:item.accion="{ item, index }">

                                              
                                         <router-link :to="`/bodega/compras/${item.id}/facturar`"
                                                         v-tooltip="'Facturar compra'">
                                     
                                                 <v-icon small style="color: #3490DC !important;">
                                               fas fa-file-alt
                                                </v-icon>
                                            </router-link>
                                                                                             
  </template>
  <template v-slot:footer.page-text="items"> {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }} </template>
</v-data-table>
</v-card> 


                        <!-- 
                        <div class="card-body table-responsive p-0">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th class="align-middle">Proveedor</th>
                                        <th class="align-middle">Sol. abastecimiento</th>
                                        <th class="align-middle">Fecha compra</th>
                                        <th class="align-middle">Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="p in purchases.data" :key="p.id">
                                        <td class="align-middle">{{ p.supplier.name }}</td>
                                        <td class="align-middle">{{ p.supply_request ? 'Si': 'No' }}</td>
                                        <td class="align-middle">{{ p.purchase | myDate }}</td>
                                        <td class="align-middle">
                                            <router-link :to="`/bodega/compras/${p.id}/facturar`"
                                                         v-tooltip="'Facturar compra'">
                                                <i class="fas fa-file-alt blue"></i>
                                            </router-link>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                      
                        <div class="card-footer">
                            <pagination :data="purchases" @pagination-change-page="getPurchases"/>
                        </div>
                        -->
                    </div>
                </div>
            </div>
        </div>
    </section>
    </v-app>
</template>

<script>
    export default {
        created () {
            this.$Progress.start();
            this.getPurchases();
            this.$Progress.finish();
        },
        data () {
            return {
                purchases: {},
                loading:false,
                search:'',
                 headers: [
                {
                    text: 'Proveedor',
                    align: 'start',
                    value: 'proveedor',
                },
                { text: 'Sol. abastecimiento', value: 'solicitud' },
                { text: 'Fecha compra', value: 'fecha' },
                { text: 'Acciones', value: 'accion', sortable: false },
                ],
                datos:[],
            }
        },
        methods: {
            getPurchases (page = 1) {
                this.$Progress.start();
                this.loading = true;

                axios.get('/api/bodega/compras')
                    /*.then(({ data }) => {
                        this.purchases = data.data;
                        this.$Progress.finish();
                    })*/
                    .then(data => {
                    this.datos = [];
                    this.purchases = data.data;
                     console.log(this.purchases);
                        for(let i = 0; i < this.purchases.data.length; i++) {
                            var t = this.purchases.data[i];
                            //var protocolar = this.$options.filters.capitalize(t.ship_type.name ); 
                            var proveedor = this.$options.filters.capitalize(t.supplier.name);  
                            var fecha = this.$options.filters.myDate(t.purchase);  
                            var solicitud = t.supply_request ? 'Si': 'No';
                            this.datos.push({proveedor:proveedor,solicitud:solicitud,fecha:fecha,id:t.id,accion:true});
                        }
                       console.log(this.datos);
                       this.loading =false;
                       this.$Progress.finish();
                     })
                    .catch(() => {
                        this.$Progress.fail();
                        this.$launchError('Error al obtener listado de compras');
                    });

            }
        }
    }
</script>

<template>
<div>
          <div class="row">
           <!-- <div class="col form-group">
                <label>Contraseña Actual</label>
                <input v-model="oldPass" type="password" name="Ingrese Contraseña Actual" class="form-control" :class="{ 'is-invalid': errorOld }">
                <div style="color:red;" v-if="errorOld">{{ errorForOld}}</div>
            </div>-->

            <div class="col form-group">
                <label>Nueva Contraseña</label>
                <input v-model="newPass" type="password" name="Ingrese Contraseña nueva" :class="{ 'is-invalid': errorNew }"
                       class="form-control">
                <div style="color:red;" v-if="errorNew">Contraseña nueva es requerida</div>
            </div>

             <div class="col form-group">
                <label>Repita Nueva Contraseña</label>
                <input v-model="repeatPass" type="password" name="Repita Contraseña Nueva" :class="{ 'is-invalid': errorRepeat }"
                       class="form-control">
                <div style="color:red;" v-if="errorRepeat"> {{ errorForRepeat}}</div>
            </div>
        </div>
        <button type="submit" class="btn btn-primary float-right" @click="changePass">Guardar</button>
</div>
</template>

<script>
    import eventHub from "../../eventHub";

    export default {
        created () {
            this.$Progress.start();
            this.getUser();
            this.$Progress.finish();
        },
        data () {
            return {
                oldPass: '',
                newPass: '',
                repeatPass: '',
                errorRepeat: false,
                errorNew: false,
                errorOld: false,
                errorForRepeat: 'Repetir Contraseña es requerida',
                errorForOld: "Contraseña actual es requerida"
            }
        },
        methods: {
            changePass () {
                if(this.newPass!='' && this.repeatPass!=''){
                        if(this.newPass==this.repeatPass){
                            this.errorRepeat = false
                           this.$Progress.start();
                            axios.post('/api/usuarios/updatePass', {
                                user_id: this.$parent.id,
                                new_pass: this.newPass
                            })
                            .then(response => {
                                console.log(response);
                                if(response.data==1){
                                    this.errorForOld = "Contraseña actual es requerida"
                                    this.errorOld = false
                                    this.errorNew = false
                                    this.$launchSuccess('Éxito al actualizar contraseña');
                                }
                                if(response.data==2){
                                    this.errorOld = false
                                    this.$launchSuccess('Ha ocurrido un error al intentar cambiar la contraseña');
                                }
                                this.$Progress.finish();
                            })
                            .catch(() => {
                                this.$Progress.fail();
                                this.$launchError('Error al cambiar contraseña');
                            });
                        }else{
                            this.errorNew = false
                            this.errorForRepeat = "Las contraseñas no coinciden"
                            this.errorRepeat = true
                            this.$launchError('Las Contraseñas no coinciden');
                        }

                    }else{
                        if(this.repeatPass!=''){
                                this.errorRepeat = false
                        }else{
                            this.errorForRepeat = 'Repetir Contraseña es requerida'
                            this.errorRepeat = true
                        }

                         if(this.newPass!=''){
                            this.errorNew = false
                        }else{
                            this.errorNew = true
                        }
                        this.$launchError('Complete todos los datos');
                    }
                    // if( this.oldPass!=null && this.newPass!=null && this.repeatPass!=null){
                    //     if(this.newPass==this.repeatPass){
                    //         this.errorRepeat = false
                    //        this.$Progress.start();
                    //         axios.post('/api/usuarios/updatePass', {
                    //             user_id: this.$parent.id,
                    //             old_pass: this.oldPass,
                    //             new_pass: this.newPass
                    //         })
                    //         .then(response => {
                    //             console.log(response);
                    //             if(response.data==0){
                    //                 this.errorForOld = "La contraseña actual no es válida";
                    //                 this.errorOld = true
                    //                 this.$$launchError('Error al actualizar contraseña');
                    //             }
                    //             if(response.data==1){
                    //                 this.errorForOld = "Contraseña actual es requerida"
                    //                 this.errorOld = false
                    //                 this.$launchSuccess('Éxito al actualizar contraseña');
                    //             }
                    //             if(response.data==2){
                    //                 this.errorOld = false
                    //                 this.$launchSuccess('Ha ocurrido un error al intentar cambiar la contraseña');
                    //             }
                    //             this.$Progress.finish();
                    //         })
                    //         .catch(() => {
                    //             this.$Progress.fail();
                    //             this.$launchError('Error al cambiar contraseña');
                    //         });
                    //     }else{
                    //         this.errorForOld = "Contraseña actual es requerida"
                    //         this.errorNew = false
                    //         this.errorOld = false
                    //         this.errorForRepeat = "Las contraseñas no coinciden"
                    //         this.errorRepeat = true
                    //         this.$launchError('Las Contraseñas no coinciden');
                    //     }

                    // }else{
                    //     if(this.oldPass!=null){
                    //             this.errorOld = false
                    //     }else{
                    //         this.errorForOld = "Contraseña actual es requerida"
                    //         this.errorOld = true
                    //     }

                    //     if(this.repeatPass!=null){
                    //             this.errorRepeat = false
                    //     }else{
                    //         this.errorForRepeat = 'Repetir Contraseña es requerida'
                    //         this.errorRepeat = true
                    //     }

                    //      if(this.newPass!=null){
                    //             this.errorNew = false
                    //     }else{
                    //         this.errorNew = true
                    //     }
                    //     this.$launchError('Complete todos los datos');
                    // }
                    
            },

            getUser () {
                this.$nextTick(() => {
                   // this.form.fill(this.$parent.user);
                   // this.form.region_id = this.$parent.user.commune.region_id;
                });
            }
        },
        watch: {
            '$parent.user' () {
                this.getUser();
            }
        }
    }
</script>

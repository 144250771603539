<template>
 <v-app>
  <section class="content">
      <div class="container-fluid">
          <div class="row">
              <div class="col-12">
                  <div class="card">
                      <loading v-if="loading"/>

                      <div class="card-header">
                          <div class="row align-items-center">
                              <h3 class="col card-title">Lista de Vouchers</h3>

                            <!--  <div class="col-6">
                                  <form @submit.prevent="getTickets">
                                      <input type="text" v-model="searchInput" class="form-control"
                                             placeholder="Ingrese busqueda por entrada, salida, proveedor, observacion, fecha recogida">
                                  </form>
                              </div>

                              <div class="col-2">
                            
                              </div>-->
                          </div>
                      </div>


     <v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Búsqueda"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
  <v-data-table
      :headers="headers"
      :items="datos"
      :search="search"
       :loading="loading"
       :items-per-page="30"
       no-results-text="No se han encontrado vouchers"
       no-data-text="No existen vouchers"
      class="elevation-1"
        loading-text="Cargando... Espere"
         :footer-props="{
           'items-per-page-text':'Vouchers por página',
           'items-per-page-options': [20, 50, 100, -1]
      }"
    >
 <template v-slot:item.accion="{ item, index }">
                  <router-link :to="`/detalle-voucher/${item.id}`" 
                                                       v-tooltip="'Revisar uso'">
                                           
                                                <v-icon small style="color: #3490DC !important;">
                                                fa-edit
                                                </v-icon>
                                          </router-link>                                                       
  </template>
  <template v-slot:footer.page-text="items"> {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }} </template>
</v-data-table>
</v-card> 


                     <!-- <div class="card-body table-responsive p-0">
                          <table class="table table-hover">
                              <thead>
                                  <tr>
                                      <th class="align-middle">Aerolinea</th>
                                      <th class="align-middle">Código Vuelo</th>
                                      <th class="align-middle">Monto Total</th>   
                                      <th class="align-middle">Monto Utilizado</th>
                                      <th class="align-middle">Detalles</th>
            
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr v-for="t in tickets.data" :key="t.id">
                                      <td class="align-middle">{{ t.airline }}</td>
                                      <td class="align-middle">{{ t.code }}</td>    
                                      <td class="align-middle">{{ t.price | dotNumber}}</td>    
                                       <td class="align-middle">{{ t.price_used }}</td>
                                       <td>
                                       <router-link :to="`/detalle-voucher/${t.id}`" 
                                                       v-tooltip="'Revisar uso'">
                                              <i class="fa fa-edit blue"></i>
                                          </router-link>
                                      </td> 

                                  </tr>
                              </tbody>
                          </table>
                      </div>
                      <div class="card-footer">
                          <pagination :data="tickets" @pagination-change-page="getTickets"/>
                      </div>-->



                  </div>
              </div>
          </div>

       
      </div>
  </section>
   </v-app>
</template>

<script>
    import Loading from "./Common/Loading";

    export default {
        components: { Loading},
         computed: {
        hasOneRowOpen() {
        for(let item of this.items){
            if(item['_showDetails']){
            return true;
            }
        }
        }
        },
        created () {
            this.$Progress.start();
            this.getTickets();
            this.$Progress.finish();
        },
        data () {
            return {
                loading: true,
                searchInput: null,
                tickets: {},
                 search: '',
                headers: [
                {
                    text: 'Aerolinea',
                    align: 'start',
                    sortable: false,
                    value: 'aerolinea',
                },
                { text: 'Código Vuelo', value: 'codigo' },
                { text: 'Monto Total', value: 'total' },
                { text: 'Monto Utilizado', value: 'usado' },
                { text: 'Detalles', value: 'accion', sortable: false },
                ],
                datos:[],
            }
        },
        methods: {
            getTickets (page = 1) {
                this.$Progress.start();
                this.loading = true;

                axios.get('/api/administracion/vouchers')
                    .then(data => {
                    this.tickets = data.data;
                     console.log(this.tickets);
                        for(let i = 0; i < this.tickets.data.length; i++) {
                            var eldato = this.tickets.data[i];
                            this.datos.push({aerolinea:eldato.airline,codigo:eldato.code,total:"$"+ this.$options.filters.dotNumber(eldato.price),usado:"$"+ this.$options.filters.dotNumber(eldato.price_used),accion:true,id:eldato.id});
                        }
                       console.log(this.datos);
                     })
                    .catch(() => this.$launchError('Error al obtener listado de vouchers'))
                    .finally(() => {
                        this.$Progress.finish();
                        this.loading = false;
                    });
            },   
        }
    }
</script>

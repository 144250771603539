<template>
    <div>
        <!-- breadcrumb -->
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Ficha de tripulación</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link to="/">Inicio</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link to="/jefe-flota/tripulaciones">Tripulaciones</router-link>
                            </li>
                            <li class="breadcrumb-item active">Ficha tripulación</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>

        <!-- ficha -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <!-- resumen -->
                    <div class="col-4">
                        <div class="card">
                            <loading v-if="loading"/>

                            <resume/>
                        </div>
                    </div>

                    <!-- resto de la ficha -->
                    <div class="col-8">
                        <div class="card">
                            <loading v-if="loading"/>

                            <div class="card-header">
                                <ul class="nav nav-pills">
                                    <li class="nav-item" v-for="t in tabs">
                                        <a class="nav-link" :class="{'active': tab === t.component}"
                                           @click="tab = t.component">
                                            {{ t.title }}
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div class="card-body">
                                <component :is="tab"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import CrewFilter from "./CrewDetails/CrewFilter";
import Emergencies from "./CrewDetails/Emergencies";
import Loading from "./Common/Loading";
import Logistics from "./CrewDetails/Logistics";
import Purchases from "./CrewDetails/Purchases";
import Resume from "./CrewDetails/Resume";
import SupplyRequest from "./CrewDetails/SupplyRequest";
import Workers from "./CrewDetails/Workers";

export default {
    components: { CrewFilter, Emergencies, Loading, Logistics, Purchases, Resume, SupplyRequest, Workers },
    computed: {
        id () {
            return this.$route.params.id;
        }
    },
    created () {
        this.getCrewDetails();
    },
    data () {
        return {
            loading: true,
            tab: 'Workers',
            tabs: [
                { title: 'Tripulantes', component: 'Workers' },
                { title: 'Filtros', component: 'CrewFilter' },
                { title: 'Logística', component: 'Logistics' },
                { title: 'Sol. abastecimiento', component: 'SupplyRequest' },
                { title: 'Compras', component: 'Purchases' },
                { title: 'Emergencias', component: 'Emergencies' },
            ],
            crew: {
                client: { name: null },
                ship: { name: null },
                status: { name: null }
            }
        }
    },
    methods: {
        getCrewDetails () {
            this.$Progress.start();
            this.loading = true;

            axios.get('/api/ficha-tripulacion/' + this.id + '/detalles')
                .then(({ data }) => {
                    this.crew = data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$launchError('Error al obtener ficha de tripulación');
                    this.$Progress.fail();
                })
                .finally(() => this.loading = false);
        }
    }
}
</script>

<template>
    <div>
                                 <div class="row">
                                        <div class="col form-group">
                                            <label>Documento</label>
                                            <select class="form-control" v-model="form.document_id"
                                                    >
                                                <option :value="null" disabled>Escoja tipo de documento</option>
                                                <option :value="5">Manual EPP</option>
                                                <option :value="4">Seguro de vida</option>
                                            </select>
                                        </div>
                                 </div>
                                <div class="row" v-if="form.document_id==4">

                                        <div class="col form-group">
                                            <label>Fecha caducidad</label>
                                            <input type="date" class="form-control" v-model="form.expiration_date">
                                        </div>
                                </div>

                                <div class="row">

                                        <div class="col form-group">
                                            <label>Observación</label>
                                            <input type="text" class="form-control" v-model="form.observation">
                                        </div>
                                </div>

                                <div class="row">
                                        <div class="col form-group">
                                            <label>Archivo</label><br>
                                            <div class="custom-file">
                                                <input id="certificate" type="file" class="custom-file-input"
                                                       @change.prevent="upload" accept="application/pdf">
                                                <label class="custom-file-label" for="certificate">
                                                    {{ fileName ? fileName : 'Escoja un archivo' }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
    </div>

</template>

<script>
    export default {
        props: {
            form: Object
        }
    }
</script>

<template>
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Acciones de entrega de EPP</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div>
                <div class="modal-body container">
                    <div class="row">
                        <div class="col form-group">
                            <input id="epp-compra" type="radio" value="purchase" v-model="form.action" v-on:change="suma()">
                            <label class="ml-2" for="epp-compra">Incluír en compra</label>
                        </div>

                        <div class="col form-group">
                            <input id="epp-bodega" type="radio" value="warehouse" v-model="form.action" v-on:change="resta()" :disabled="form.warehouseQuantity==0">
                            <label class="ml-2" for="epp-bodega">Utilizar stock bodega ({{ form.warehouseQuantity }})</label>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col form-group">
                            <label>Valor</label>
                            <input min="0" type="number" class="form-control" v-model="form.price" :disabled="!purchase">
                        </div>
                    </div>

                    <div class="alert alert-warning" role="alert" v-if="purchase">
                        Si se modifica el valor original del EPP, este se convertirá en el nuevo valor del mismo.
                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" data-dismiss="modal">
                            Guardar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['EPPDeliveryId'],
        computed: {
            // indica si se ha decidido incluír en la compra
            purchase () {
                return this.form.action === 'purchase';
            }
        },
        created () {
            this.loadForm();
        },
        data () {
            return {
                form: {
                    action: null
                },
                formdos:{
                    action:null
                },
            }
        },
        methods: {
            loadForm () {
                this.form = this.$parent.form.epp_deliveries[this.EPPDeliveryId];
                this.formdos = this.$parent.form.epp_deliveries;
            },
            suma(){
                for (var prop in this.$parent.form.epp_deliveries) {
                    if ( this.$parent.form.epp_deliveries.hasOwnProperty(prop) ) {
                        if(this.$parent.form.epp_deliveries[this.EPPDeliveryId].warehouse_item_size_id==this.$parent.form.epp_deliveries[prop].warehouse_item_size_id){
                            this.$parent.form.epp_deliveries[prop].warehouseQuantity =this.$parent.form.epp_deliveries[prop].warehouseQuantity+1;
                        }
                    }
                }
              //  this.$parent.form.epp_deliveries[this.EPPDeliveryId].warehouseQuantity = this.$parent.form.epp_deliveries[this.EPPDeliveryId].warehouseQuantity+1;
            },
            resta(){
                for (var prop in this.$parent.form.epp_deliveries) {
                    if ( this.$parent.form.epp_deliveries.hasOwnProperty(prop) ) {
                        if(this.$parent.form.epp_deliveries[this.EPPDeliveryId].warehouse_item_size_id==this.$parent.form.epp_deliveries[prop].warehouse_item_size_id){
                            this.$parent.form.epp_deliveries[prop].warehouseQuantity =this.$parent.form.epp_deliveries[prop].warehouseQuantity-1;
                        }
                    }
                }
                // for(let j = 0; j < this.$parent.form.epp_deliveries.length ; j ++){
                    //console.log(this.formdos);
               // }
                //this.$parent.form.epp_deliveries[this.EPPDeliveryId].warehouseQuantity = this.$parent.form.epp_deliveries[this.EPPDeliveryId].warehouseQuantity-1;
            },
        },
        watch: {
            EPPDeliveryId () {
                this.loadForm();
            }
        }
    }
</script>

<template>
    <div>
        <button class="btn btn-primary float-right" :class="{'mb-4': headlines.length > 0}" @click="addWorker">
            Añadir
        </button>
        <span v-if="headlines.length === 0">
            <i>No se han registrado titulares aún</i>
        </span>
        <table class="table" v-else>
            <thead>
                <tr>
                    <th class="align-middle">Nombre</th>
                    <th class="align-middle">Rut</th>
                    <th class="align-middle">Rol</th>
                    <th class="align-middle">Acciones</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="h in headlines">
                    <td class="align-middle">{{ h.worker.name }} {{ h.worker.last_name }}</td>
                    <td class="align-middle">{{ h.worker.rut }}</td>
                    <td class="align-middle">{{ h.worker.roles.map(r => r.name) | list }}</td>
                    <td class="align-middle">
                        <router-link :to="'/admin/trabajadores/' + h.worker.id">
                            <i class="fa fa-info-circle green"></i>
                        </router-link>
                        /
                        <a href="#" @click="deleteHeadline(h.worker.id)">
                            <i class="fa fa-trash red"></i>
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>

        <!-- modal de trabajadores -->
        <div class="modal fade" id="workers-modal" tabindex="-1" role="dialog" aria-labelledby="form-dialog"
             aria-hidden="true" data-backdrop="static" data-keyboard="false">
            <add-worker v-if="showModal"/>
        </div>
    </div>
</template>

<script>
    import AddWorker from "./Headlines/AddWorker";
    import eventHub from "../../eventHub";

    export default {
        components: { AddWorker },
        created () {
            this.$Progress.start();
            this.getHeadlines();
            this.$Progress.finish();

            // registra evento de cerrar modal
            this.$nextTick(() => $('#workers-modal').on('hidden.bs.modal', () => this.showModal = false));

            // registra eventos
            eventHub.$on('workersSaved', () => {
                $('#workers-modal').modal('hide');
                this.getHeadlines();
            });
        },
        data () {
            return {
                headlines: [],
                showModal: false
            }
        },
        methods: {
            addWorker () {
                this.showModal = true;
                this.$nextTick(() => $('#workers-modal').modal('show'));
            },

            deleteHeadline (id) {
                Swal.fire({
                    title: '¿Estás seguro que deseas eliminar este titular?',
                    text: 'Podrás volver a añadirlo más tarde',
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Si',
                    cancelButtonText: 'No'
                }).then((result) => {
                    if (result.value) {
                        axios.delete(`/api/embarcaciones/${this.$parent.id}/titulares/${id}`)
                            .then(() => {
                                this.getHeadlines();
                                Swal.fire(
                                    'Éxito',
                                    'El titular fue eliminado correctamente',
                                    'success'
                                );
                            })
                            .catch(() => Swal.fire(
                                'Error',
                                'Error al eliminar titular',
                                'warning'
                            ));
                    }
                });
            },

            getHeadlines () {
                this.$parent.loading = true;

                axios.get(`/api/embarcaciones/${this.$parent.id}/titulares`)
                    .then(({ data }) => this.headlines = data.data)
                    .catch(() => this.$launchError('Error al obtener listado de titulares de la embarcación'))
                    .finally(() => this.$parent.loading = false);
            }
        }
    }
</script>

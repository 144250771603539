<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <loading v-if="loading"/>

                        <!-- header -->
                        <div class="card-header">
                            <h3 class="card-title">Listado de cotizaciones</h3>
                        </div>

                        <!-- body -->
                        <div class="card-body table-responsive p-0">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th class="align-middle">Fecha creación</th>
                                        <th class="align-middle">Fecha confirmación</th>
                                        <th class="align-middle">Estado</th>
                                        <th class="align-middle">Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="q in quotations.data" :key="q.id">
                                        <td class="align-middle">{{ q.created_at | myDate }}</td>
                                        <td class="align-middle">{{ q.confirmed_at | myDate }}</td>
                                        <td class="align-middle">
                                            <span class="badge" :class="{
                                                'bg-success': q.status,
                                                'bg-danger': q.status === 0,
                                                'bg-warning': q.status === null
                                            }">
                                                {{ q.status === null ? 'No validada': q.status === 1 ? 'Validada': 'Rechazada' }}
                                            </span>
                                        </td>
                                        <td class="align-middle">
                                            <router-link :to="`/operaciones/cotizaciones/${q.id}`"
                                                         v-tooltip="'Ver detalles'">
                                                <i class="fas fa-info-circle green"></i>
                                            </router-link>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <!-- footer -->
                        <div class="card-footer">
                            <pagination :data="quotations" @pagination-change-page="getQuotations"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import Loading from '../Common/Loading';

    export default {
        components: { Loading },
        created () {
            this.$Progress.start();
            this.getQuotations();
            this.$Progress.finish();
        },
        data () {
            return {
                quotations: {},
                loading: true
            }
        },
        methods: {
            getQuotations (page = 1) {
                this.$Progress.start();

                axios.get('/api/operaciones/cotizaciones?page=' + page)
                    .then(({ data }) => {
                        this.quotations = data.data;
                        this.$Progress.finish();
                    })
                    .catch(() => {
                        this.$Progress.fail();
                        this.$launchError('Error al obtener listado de cotizaciones');
                    })
                    .finally(() => this.loading = false);
            }
        }
    }
</script>

<template>
    <div>
        <!-- breadcrumb -->
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Editar Pasaje</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link to="/">Inicio</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link to="/">administracion</router-link>
                            </li>
                            <li class="breadcrumb-item active">Editar Pasaje</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>

        <!-- creación -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col">
                        <div class="card">
                            <loading v-if="loading"/>

                            <div class="card-body">
                                <!-- formulario -->
                                

                                <form >
                                    <div class="row">
                                   
                                        <div class="col form-group">
                                             <label>Aerolínea</label>
                                            <select name="aerolínea" class="form-control" @change="changeAirline($event)" v-model="form.airline_id"
                                                    :class="{ 'is-invalid': form.errors.has('airline_id') }" :disabled='have_stock==1 || is_stock==1 || quoted==1'>
                                                <option :value="null" disabled>Escoja una aerolínea</option>
                                                <option :value="a.id" v-for="a in airlines">{{ a.name }}</option>
                                            </select>
                                            <has-error :form="form" field="airline_id"/>
                                        </div>
                                 

                                
                                       <div class="col form-group">
                                                <label>Tarifa unitaria (x{{many}})</label>
                                            <input v-model="form.price" type="number" min="0" name="tarifa" class="form-control"
                                                :class="{ 'is-invalid': form.errors.has('price') }" :disabled='have_stock==1 || is_stock==1 || quoted==1' >
                                            <has-error :form="form" field="price"/>
                                        </div>
                                 

                                    
                                       <!-- <div class="col form-group">
                                            <label>Tarifa Plus</label>
                                            <select name="plus" class="form-control" v-model="form.tarifa_plus"
                                                    :class="{ 'is-invalid': form.errors.has('tarifa_plus') }" :disabled='have_stock==1 || is_stock==1'>
                                                <option :value="0">No</option>
                                                <option :value="1">Si</option>     
                                            </select>
                                            <has-error :form="form" field="tarifa_plus"/>
                                        </div>-->
                                   

                                    
                                         <div class="col form-group">
                                           <label>Diferencial Tarifa Total (x{{many}})</label>
                                            <input v-model="form.extra_price" type="number" min="0" name="extra" class="form-control"
                                                :disabled='have_stock==1 || is_stock==1 || quoted==1'>
                                            <has-error :form="form" field="extra"/>
                                        </div>
                                   

                                   
                                         <!--<div class="col form-group">
                                            <label>Monto Maleta</label>
                                            <input v-model="form.carry_price" type="number" min="0" name="carry" class="form-control"
                                                disabled>
                                            <has-error :form="form" field="carry"/>
                                        </div>-->
                                 
                                         <div class="col form-group">
                                             <label>Fecha de vuelo</label>
                                            <input v-model="form.flight_date" type="date" name="fecha de vuelo" class="form-control"
                                                :class="{ 'is-invalid': form.errors.has('flight_date') }" :disabled='have_stock==1 || is_stock==1 || quoted==1'>
                                            <has-error :form="form" field="flight_date"/>
                                        </div>
                                    

                                   
                                         <div class="col form-group">
                                            <label>Tramo</label>
                                            <select name="tramo" class="form-control" v-model="form.flight_stretch_id"
                                                    :class="{ 'is-invalid': form.errors.has('flight_stretch_id') }" :disabled='have_stock==1 || is_stock==1 || quoted==1'>
                                                <option :value="null" disabled>Escoja un tramo</option>
                                                <option :value="f.id" v-for="f in flightStretches">{{ f.name }}</option>
                                            </select>
                                            <has-error :form="form" field="flight_stretch_id"/>
                                        </div>


                                          <div class="col form-group">
                                             <label>Fecha de Vencimiento</label>
                                            <input v-model="form.expiration_date" type="date" name="fecha de vencimiento" class="form-control"
                                                :class="{ 'is-invalid': form.errors.has('expiration_date') }" :disabled='have_stock==1 || is_stock==1 || quoted==1'>
                                            <has-error :form="form" field="expiration_date"/>
                                        </div>
                                
                                    </div>
                                    
                                    <div class="row">
                                    
                                        

                                    
                                         <div class="col form-group">
                                              <label>Hora de partida</label>
                                                <input v-model="form.departure_time" type="time" name="hora de partida" class="form-control"
                                                    :class="{ 'is-invalid': form.errors.has('departure_time') }" :disabled='have_stock==1 || is_stock==1 || quoted==1'>
                                                <has-error :form="form" field="departure_time"/>
                                        </div>
                                   


                                      
                                         <div class="col form-group">
                                              <label>Hora de llegada</label>
                                                <input v-model="form.arrival_time" type="time" name="hora de llegada" class="form-control"
                                                    :class="{ 'is-invalid': form.errors.has('arrival_time') }" :disabled='have_stock==1 || is_stock==1 || quoted==1'>
                                                <has-error :form="form" field="arrival_time"/>
                                        </div>
                                  


                                 
                                         <div class="col form-group">
                                              <label>Aeropuerto de origen</label>
                                                <select name="aeropuerto de origen" class="form-control" v-model="form.departure_airport_id"
                                                        :class="{ 'is-invalid': form.errors.has('departure_airport_id') }" :disabled='have_stock==1 || is_stock==1 || quoted==1'>
                                                    <option :value="null" disabled>Escoja un aeropuerto</option>
                                                    <option :value="a.id" v-for="a in airports">{{ a.iata }} - {{ a.city }}</option>
                                                </select>
                                                <has-error :form="form" field="departure_airport_id"/>
                                        </div>
                                   

                            
                                         <div class="col form-group">
                                              <label>Aeropuerto de destino</label>
                                                <select name="aeropuerto de destino" class="form-control" v-model="form.arrival_airport_id"
                                                        :class="{ 'is-invalid': form.errors.has('arrival_airport_id') }" :disabled='have_stock==1 || is_stock==1 || quoted==1'>
                                                    <option :value="null" disabled>Escoja un aeropuerto</option>
                                                    <option :value="a.id" v-for="a in airports">{{ a.iata }} - {{ a.city }}</option>
                                                </select>
                                                <has-error :form="form" field="arrival_airport_id"/>
                                        </div>
                                   

                                          <div class="col form-group">
                                              <label>Utilizar...</label>
                                                <select name="stock" class="form-control" v-model="form.stock_ticket_id"
                                                        :disabled="form.airline_id == null || have_stock==1 || is_stock==1 || quoted==1" v-on:change="changePenalty()">
                                                    <option :value='null'>Ninguno</option>
                                                    <option :value='1' :disabled='stocks.length==0'>
                                                        Pasaje en Stock
                                                    </option>
                                                     <option :value='2' :disabled='vouchers.length==0'>
                                                         Voucher
                                                    </option>
                                                </select>
                                        </div>
                                       
                                         <div class="col form-group" :hidden="form.stock_ticket_id!==1">
                                              <label>Pasajes en stock</label>
                                                <select name="stock" class="form-control" v-model="form.stock_id"
                                                        :disabled="stocks.length === 0 || have_stock==1 || is_stock==1 || quoted==1" v-on:change="changePenalty()">
                                                    <option :value="null">Escoja un pasaje</option>
                                                    <option :value="s.id" v-for="s in stocks">
                                                        {{ s.code }} - {{ s.name }} - {{s.price | dotNumber}}
                                                    </option>
                                                </select>
                                        </div>


                                            <div class="col form-group" :hidden="form.stock_ticket_id!==2">
                                              <label>Vouchers</label>
                                                <select name="stock" class="form-control" v-model="form.voucher_id"
                                                        :disabled="vouchers.length === 0 || have_stock==1 || is_stock==1 || quoted==1" v-on:change="changePenalty()">
                                                    <option :value="null">Escoja un Voucher</option>
                                                    <option :value="s.id" v-for="s in vouchers">
                                                        Monto :${{ s.price | dotNumber}} - Código :{{ s.code }} {{s.contain_type}}
                                                    </option>
                                                </select>
                                        </div>
                                
                                  </div>

                                  <div class="row">
                                        <div class="col form-group">        
                                           </div>
                                      <div class="col form-group">
                                            <label>Codigo de Vuelo</label>
                                            <input v-model="form.code" v-on:change="changeEdit()" v-on:keyup="changeEdit()" type="text" min="0" name="code" class="form-control"
                                                :disabled='have_stock==1 || is_stock==1 || quoted==1'>
                                            <has-error :form="form" field="carry"/>
                                        </div>
                                   <!-- <div class="col form-group">
                                            <label>Multa</label>
                                            <input v-model="form.penalty" v-on:change="changePenalty()" v-on:keyup="changePenalty()" type="number" min="0" name="penalty" class="form-control"
                                                :disabled='have_stock==1 || is_stock==1'>
                                            <has-error :form="form" field="carry"/>
                                        </div>-->
                                        <div class="col form-group">
                                            <label>Afecto</label>
                                            <input v-model="form.dearness" v-on:change="changePenalty()" v-on:keyup="changePenalty()" type="number" min="0" name="dearness" class="form-control"
                                                :disabled='have_stock==1 || is_stock==1 || quoted==1'>
                                            <has-error :form="form" field="carry"/>
                                        </div>

                                           <div class="col form-group">
                                                        <label>Orden de compra</label>
                                                        <input v-model="form.purchase_order" :disabled='have_stock==1 || is_stock==1 || quoted==1' type="number" name="purchase_order" class="form-control"
                                                            >
                                                        <has-error :form="form" field="purchase_order"/>
                                                    </div>
                                            
                                        
                                                <div class="col form-group">
                                                    <label>Fecha Orden de compra</label>
                                                    <input type="date" v-model="form.purchase_order_date" :disabled='have_stock==1 || is_stock==1 || quoted==1' name="purchase_order_date" class="form-control"
                                                      >
                                                       <has-error :form="form" field="purchase_order_date"/>
                                                </div>
                                                
                                 
                                   
                                            <div class="col form-group">        
                                           </div>
                                 
                                  </div>

                                </form>
                       


                                 <div class="col mt-4" v-if='editable==1'>
                                 <button class="btn btn-primary float-left"  @click="personModal()">
                                            Añadir Persona
                                </button>
                                <button class="btn btn-primary float-left" style="margin-left:20px !important;" @click="workerModal()">
                                            Añadir Tripulante
                                </button>
                                </div>

                                  <div class="col mt-4"  v-if='editable==1'>
                                 
                                </div>



                                 <div class="mt-4">
                                    <div class="row" v-if="selectedData.length === 0 && selectedDataWorkers.length === 0">
                                        <span class="text-center w-100">
                                            <i>Aún no se han asociado personas o tripulantes a este pasaje</i>
                                        </span>
                                    </div>
                                    <div v-else>
                                        <div class="table-responsive p-0">
                                            <table class="table table-hover"  style="margin-top:30px !important;">
                                            <thead>
                                                <tr>
                                                    <th class="align-middle">Nombre</th>
                                                    <th class="align-middle">Apellido</th>
                                                    <th class="align-middle">Rut</th>
                                                    <th class="align-middle">Monto Maleta</th>
                                                    <th class="align-middle">Facturar Monto Maleta</th>
                                                    <th class="align-middle">Multa</th>
                                                    <th class="align-middle">Facturar Monto Multa</th>
                                                    <th class="align-middle">Monto Extra</th>
                                                    <th class="align-middle">Observacion</th>
                                                    <th class="align-middle">Quitar</th>
                                                    <th class="align-middle">Stock</th>
                                                    <th class="align-middle" v-if="form.ticket_status_id==3">Perdido</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(i,index) in selectedData">
                                                    <td class="align-middle">{{ i.name }}</td>
                                                    <td class="align-middle">{{ i.last_name }}</td>
                                                    <td class="align-middle">{{ i.rut }}</td>
                                                    <td class="align-middle"><input type="number" min="0" v-model="i.carry_price" :disabled='i.stock==1 || quoted==1' class="form-control"/></td>
                                                    <td class="align-middle"><input type="checkbox"  v-model="i.quotation" :disabled='i.stock==1 || quoted==1' class="form-control"/></td>
                                                    <td class="align-middle"><input type="number" v-on:change="changePenalty()"  v-on:keyup="changePenalty()" min="0" v-model="i.penalty" :disabled='i.stock==1 || quoted==1' class="form-control"/></td>
                                                    <td class="align-middle"><input type="checkbox"  v-model="i.penalty_quotation" :disabled='i.stock==1 || quoted==1' class="form-control"/></td>
                                                    <td class="align-middle"><input type="number" v-model="i.extra_price" :disabled='i.stock==1 || quoted==1' class="form-control"/></td>
                                                    <td class="align-middle"><input type="text" v-model="i.observation" :disabled='i.stock==1 || quoted==1' class="form-control"/></td>
                                                    <td class="align-middle">
                                                          
                                                         <a href="#"  @click="unselect(i.id)"
                                                            v-tooltip="'Eliminar persona'" v-if='editable==1 && i.stock==0  && i.ticket_status_id!=5  || quoted==0'>
                                                            <i class="fa fa-trash red"></i>
                                                        </a>
                                                    </td>


                                                     <td class="align-middle" v-if="i.stock==0 && i.ticket_status_id!=5">
                                                         <a href="#"  @click="toStock(i.person_ticket_id,index,i.id,i.carry_price)"
                                                            v-tooltip="'Enviar Pasaje a Stock'" >Pasar a Stock
                                                            <i class="fa fa-trash red"></i>
                                                        </a>
                                                    </td>
                                                    <td class="align-middle" v-else-if="i.ticket_status_id!=5">
                                                            <a href="#"  @click="removestock(i.person_ticket_id,index)"
                                                            v-tooltip="'Quitar de Stock'" v-if="i.iseditable==1">
                                                            En Stock
                                                            <i class="fa fa-trash red"></i>
                                                            </a>

                                                            <a href="#" 
                                                            v-tooltip="'En Stock'" v-else>
                                                            En Stock
                                                            </a>
                                                    </td>
                                                    <td v-else></td>



                                                    <td class="align-middle" v-if="i.ticket_status_id==3 && form.ticket_status_id==3">
                                                         <a href="#"  @click="toLost(i.person_ticket_id,index,i.id,i.carry_price)"
                                                            v-tooltip="'Marcar como Perdido'" >Pasar a Perdido
                                                            <i class="fa fa-trash red"></i>
                                                        </a>
                                                    </td>
                                                    <td class="align-middle" v-else-if="form.ticket_status_id==3">
                                                            <a href="#"  @click="removelost(i.person_ticket_id,index)"
                                                            v-tooltip="'Desmarcar como perdido'" v-if="i.editable_lost==1">
                                                            Perdido
                                                            <i class="fa fa-trash red"></i>
                                                            </a>

                                                            <a href="#" 
                                                            v-tooltip="'Perdido'" v-else>
                                                           Perdido
                                                            </a>
                                                    </td>




                                                </tr>



                                                 <tr v-for="(i,index) in selectedDataWorkers">
                                                    <td class="align-middle">{{ i.name }}</td>
                                                    <td class="align-middle">{{ i.last_name }}</td>
                                                    <td class="align-middle">{{ i.rut }}</td>
                                                    <td class="align-middle"><input type="number" min="0" v-model="i.carry_price" :disabled='i.stock==1 || quoted==1' class="form-control"/></td>
                                                    <td class="align-middle"><input type="checkbox"  v-model="i.quotation" :disabled='i.stock==1 || quoted==1' class="form-control"/></td>
                                                    <td class="align-middle"><input type="number" v-on:change="changePenalty()" v-on:keyup="changePenalty()" min="0" v-model="i.penalty" :disabled='i.stock==1 || quoted==1' class="form-control"/></td>
                                                    <td class="align-middle"><input type="checkbox"  v-model="i.penalty_quotation" :disabled='i.stock==1 || quoted==1' class="form-control"/></td>
                                                    <td class="align-middle"><input type="number" v-model="i.extra_price" :disabled='i.stock==1 || quoted==1' class="form-control"/></td>
                                                    <td class="align-middle"><input type="text" v-model="i.observation" :disabled='i.stock==1 || quoted==1' class="form-control"/></td>
                                                    <td class="align-middle">
                                                     
                                                         <a href="#"  @click="unselectWorkers(i.id)"
                                                            v-tooltip="'Eliminar tripulante'" v-if='editable==1 && i.stock==0 && i.ticket_status_id!=5 || quoted==0'>
                                                            <i class="fa fa-trash red"></i>
                                                        </a>
                                                    
                                                        
                                                    </td>
                                                     <td class="align-middle" v-if="i.stock==0  && i.ticket_status_id!=5">
                                                         <a href="#"  @click="toStockWorkers(i.worker_ticket_id,index,i.id,i.carry_price)"
                                                            v-tooltip="'Enviar Pasaje a Stock'" >Pasar a Stock
                                                            <i class="fa fa-trash red"></i>
                                                        </a>
                                                    </td>
                                                    <td class="align-middle" v-else-if="i.ticket_status_id!=5">
                                                            <a href="#"  @click="removestockWorkers(i.worker_ticket_id,index)"
                                                            v-tooltip="'Quitar de Stock'" v-if="i.iseditable==1">
                                                            En Stock
                                                            <i class="fa fa-trash red"></i>
                                                            </a>

                                                            <a href="#" 
                                                            v-tooltip="'En Stock'" v-else>
                                                            En Stock
                                                            </a>
                                                    </td>
                                                    <td v-else></td>

                                                   
                                                      <td class="align-middle" v-if="i.ticket_status_id==3 && form.ticket_status_id==3">
                                                         <a href="#"  @click="toLostWorkers(i.worker_ticket_id,index,i.id,i.carry_price)"
                                                            v-tooltip="'Marcar como Perdido'" >Pasar a Perdido
                                                            <i class="fa fa-trash red"></i>
                                                        </a>
                                                    </td>
                                                    <td class="align-middle" v-else-if="form.ticket_status_id==3">
                                                              <a href="#" @click="removelostWorkers(i.worker_ticket_id,index)"
                                                            v-tooltip="'Desmarcar como Perdido'" v-if="i.editable_lost==1">
                                                            Perdido
                                                            <i class="fa fa-trash red"></i>
                                                            </a>

                                                            <a href="#" 
                                                            v-tooltip="'Perdido'" v-else>
                                                            Perdido
                                                            </a>
                                                    </td>




                                                </tr>
                                            </tbody>
                                        </table>

                                        </div>
                                    </div>
                                </div>

                                 <div class="col mt-4">
                                        <button type="button" class="btn btn-success float-right" @click="setTicket()" v-if="is_stock==0">
                                            Actualizar Pasaje
                                        </button>
                                    </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>




        <!-- inicio modal-->

         <div class="modal fade" id="form-modal-person" tabindex="-1" role="dialog" aria-labelledby="form-dialog"
               aria-hidden="true" v-if="showModal" data-backdrop="static" data-keyboard="false">
             
        


     <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <!-- header -->
            <div class="modal-header align-items-center">
                <h5 class="modal-title">Añadir Personas</h5>

                <form class="ml-auto w-50" @submit.prevent="getPersons">
                    <input type="text" v-model="searchInput" class="form-control"
                           placeholder="Ingrese nombre, apellido o rut de persona">
                </form>

                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <!-- body -->
            <div class="modal-body container">
        
                <!-- tabla -->
                <div class="table-responsive p-0">
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Nombre</th>
                                <th>Apellido</th>
                                <th>Rut</th>
                                
                            </tr>
                        </thead>
                        <tbody>
                        
                            <tr v-for="w in persons.data">
                                <td class="align-middle">
                                    <input type="checkbox" v-model="selected" :value="w.id"
                                           @change="toggleSelection(w.id)">
                                </td>
                                <td class="align-middle">
                                    <span>
                                        {{ w.name }} 
                                    </span>
                                </td>
                                 <td class="align-middle">
                                    <span>
                                       {{ w.last_name }}
                                    </span>
                                </td>
                                <td class="align-middle">{{ w.rut }}</td>

                               
                            </tr>
                        </tbody>
                    </table>

                 <pagination :data="persons" @pagination-change-page="getPersons"/>
                </div>

                <!-- selección -->
                <div class="tag-input" v-if="selectedData.length > 0">
                    <div v-for="s in selectedData" class="tag-input__tag">
                        <span @click="unselect(s.id)">x</span>
                        {{ s.name }}
                    </div>
                </div>
                <span v-else><i>Aún no se han escogido personas</i></span>

                <!-- footer -->
                <div class="modal-footer">
                   
                    <button type="button" class="btn btn-primary" data-dismiss="modal">
                        Aceptar
                    </button>
                </div>
            </div>
        </div>
    </div>
      </div>
        <!-- fin modal -->


  <!--modal tripulantes-->

         <div class="modal fade" id="form-modal-worker" tabindex="-1" role="dialog" aria-labelledby="form-dialog"
               aria-hidden="true" v-if="showModal" data-backdrop="static" data-keyboard="false">
             
        


     <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <!-- header -->
            <div class="modal-header align-items-center">
                <h5 class="modal-title">Añadir Tripulantes</h5>

                <form class="ml-auto w-50" @submit.prevent="getWorkers">
                    <input type="text" v-model="searchInputWorker" class="form-control"
                           placeholder="Ingrese nombre, apellido o rut de persona">
                </form>

                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <!-- body -->
            <div class="modal-body container">
        
                <!-- tabla -->
                <div class="table-responsive p-0">
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Nombre</th>
                                 <th>Apellido</th>
                                <th>Rut</th>
                            </tr>
                        </thead>
                        <tbody>
                    
                            <tr v-for="w in workers.data">
                                <td class="align-middle">
                                    <input type="checkbox" v-model="selectedWorkers" :value="w.id"
                                           @change="toggleSelectionWorkers(w.id)">
                                </td>
                                <td class="align-middle">
                                    <span>
                                        {{ w.name }} 
                                    </span>
                                </td>
                                 <td class="align-middle">
                                    <span>
                                       {{ w.last_name }}
                                    </span>
                                </td>
                                <td class="align-middle">{{ w.rut }}</td>
                            </tr>
                        </tbody>
                    </table>

                 <pagination :data="workers" @pagination-change-page="getWorkers"/>
                </div>

                <!-- selección -->
                <div class="tag-input" v-if="selectedDataWorkers.length > 0">
                    <div v-for="s in selectedDataWorkers" class="tag-input__tag">
                        <span @click="unselectWorkers(s.id)">x</span>
                        {{ s.name }}
                    </div>
                </div>
                <span v-else><i>Aún no se han escogido tripulantes</i></span>

                <!-- footer -->
                <div class="modal-footer">
                   
                    <button type="button" class="btn btn-primary" data-dismiss="modal">
                        Aceptar
                    </button>
                </div>
            </div>
        </div>
    </div>
      </div>
        <!--fin modal tripulantes--->

        
    </div>
</template>

<script>
    import Loading from "./Common/Loading";
    import moment from "moment";

    export default {
        components: { Loading },
        created () {
          this.$Progress.start();
            //this.getWorkers();
            //this.getPersons();
            this.getAirlines();
            this.getAirports();
            this.getFlightStretches();
            this.getData();
            this.$Progress.finish();
           // this.form.induction_date = moment(new Date()).format('yyyy-MM-dd');
        },
        computed: {
            id () {
                return this.$route.params.id;
            }
        },
        data () {
            return {
                loading: false,
                submit: false,
                suppliers:[],
                persons:{},
                 many:0,
                workers:{},
                selectedWorkers: [],
                selected: [],
                tostock: [],
                tostockWorkers: [],
                tolost: [],
                tolostWorkers: [],
                selectedData: [],
                selectedDataWorkers: [],
                searchInput:'',
                searchInputWorker:'',
                showModal: false,
                airlines:{},
                airports:{},
                flightStretches:{},
                stocks:{},
                datos:null,
                have_stock:false,
                 quoted:false,
                is_stock:false,
                stocks:{},
                vouchers:{},
                novoucher:0,
                nostock:0,
                loaded:0,
                editable:1,
                original_payment_type:null,
                original_voucher_id:null,
                original_stock_id:null,
                original_airline_id:null,
                final_total_price:0,
                
                formStock:new Form({
                    person_ticket_id:null,
                    ticket_id:null
                }),
                form: new Form({
                    purchase_order:null,
                    purchase_order_date:null,
                    id: null,
                    flight_stretch_id: null,
                    flight_date: null,
                    departure_time: null,
                    arrival_time: null,
                    departure_airport_id: null,
                    arrival_airport_id: null,
                    extra_price:0,
                    carry_price:0,
                    price: 0,
                    tostock:null,
                    tostockWorkers:null,
                    tolost:null,
                    tolostWorkers:null,
                    airline_id: null,
                    stock_ticket_id: null,
                    tarifa_plus:0,
                    selected:[],
                    selectedWorkers:[],
                    isedit:1,
                    ticket_id:null,
                    penalty:0,
                    dearness:0,
                    stock_id:null,
                    voucher_id:null,
                    code:null,
                    expiration_date:null,
                    passengers:0,
                    total_price:0,
                    total_extra_price:0,
                    payment_price:0,
                    real_total_price:0,
                    ticket_status_id:null
                }),
            }
        },
         watch: {
           /*'selectedDataWorkers.penalty' () {
            
                this.calculate();
            },*/
            /*
            'selectedData' () {
                 this.many = this.selectedDataWorkers.length + this.selectedData.length;
                if (this.many==1 || this.many==0){
                    //this.form.stock_ticket_id = null;
                    this.nostock = 0;
                } else{
                    if(this.form.stock_ticket_id==1){
                        this.form.stock_ticket_id = null;
                    }
                    this.nostock = 1;
                }

               this.calculate();
            },*/

             'form.voucher_id' () {
                
                     
                            this.calculate();
                              
                     
            },
            'form.stock_id' () {
                
                    
                        this.calculate();
                    
                  
            },
            'form.stock_ticket_id' () {
                if(this.form.stock_ticket_id==1){
                    this.form.voucher_id = null;
                }
                if(this.form.stock_ticket_id==2){
                    this.form.stock_id = null;
                }

                if(this.form.stock_ticket_id==null){
                    this.form.stock_id = null;
                    this.form.voucher_id = null;
                }

                this.calculate();
            },
            'form.price' () {
                this.calculate();
            },
            'stocks' () {
                if(this.loaded == 1 && this.form.stock_ticket_id == 1){
                     if(this.stocks.length==0){
                        this.nostock=1;
                        this.form.stock_ticket_id = null;
                    }else{
                        this.many = this.selectedDataWorkers.length + this.selectedData.length;
                            if (this.many==1 || this.many==0){
                                this.nostock = 0;
                            } else{
                                if(this.form.stock_ticket_id==1){
                                    this.form.stock_ticket_id = null;
                                }
                                this.nostock = 1;
                            }
                        this.calculate();
                    }
                }
            },
            'vouchers' () {
                if(this.loaded==1 && this.form.stock_ticket_id == 2){
                    if(this.vouchers.length==0){
                    this.novoucher=1;
                    this.form.stock_ticket_id = null;
                    }else{
                            this.novoucher=0;
                            this.calculate();
                    }
                }
            },
            
            
        },
        methods: {
            changeVoucherId(){
               /*  if(this.loaded == 1){
                         if(this.form.voucher_id==null){
                     this.form.extra_price = 0;
                 }else{
                     this.calculate();
                 }
                }*/
              
            },
            changeStockId(){
          /* if(this.loaded == 1){
                        if(this.form.stock_id==null){
                     this.form.extra_price = 0;
                 }else{
                     this.calculate();
                 }
                }*/
            },
            changeStockTicketId(){
                /*if(this.loaded == 1){
                    this.calculate();
                }*/
               
            },
            changePrice(){
                 /*if(this.loaded == 1){
                    this.calculate();
                }*/
            },
            changeStocks(){
                /*  if(this.loaded == 1){
                      if(this.stocks.length==0){
                            this.nostock=1;
                            this.form.stock_ticket_id = null;
                        }else{
                            this.many = this.selectedDataWorkers.length + this.selectedData.length;
                                if (this.many==1 || this.many==0){
                                    this.nostock = 0;
                                } else{
                                    if(this.form.stock_ticket_id==1){
                                        this.form.stock_ticket_id = null;
                                    }
                                    this.nostock = 1;
                                }
                            this.calculate();
                        }
                }*7
            },
            changeVouchers(){
                /*if(this.loaded == 1){
                      if(this.vouchers.length==0){
                            this.novoucher=1;
                            this.form.stock_ticket_id = null;
                        }else{
                                this.novoucher=0;
                                this.calculate();
                        }
                }*/
            },
           /* changeAirline:function(event){
                alert(event.target.value);
                },*/
            changePenalty(){
                this.calculate();
            },
            changeEdit(){
                /*if(this.form.code!='' && this.form.code!=null){
                    this.editable = 0;
                }else{
                    this.editable = 1;
                }*/
            },
            changeAirline(event){
                    if (event.target.value!==null) {

                    if(this.form.airline_id==this.original_airline_id){
                        this.datos.payment_type = this.original_payment_type;
                        this.datos.voucher_id = this.original_voucher_id;
                        this.datos.stock_ticket_id = this.original_stock_id;

                    }else{

                        this.form.stock_ticket_id = null;
                        this.datos.voucher_id = null;
                        this.datos.stock_ticket_id = null;
                    }
                    
                    this.nostock = 1;
                    this.novoucher = 1;
                    this.getVouchers(event.target.value);
                    this.getStocks(event.target.value);

                   }
        
            },
            calculate(){      
                if(this.loaded == 1){

                    let real_total = 0;

                    this.selectedDataWorkers.forEach(d => {
                                   // console.log(d.penalty);
                                    if(d.penalty!==null && d.penalty!==''){
                                       real_total = parseInt(real_total) + parseInt(d.penalty);
                                    }
                                    
                    });

                    this.selectedData.forEach(d => {
                                    //console.log(d.penalty);
                                    if(d.penalty!==null && d.penalty!==''){
                                       real_total = parseInt(real_total) + parseInt(d.penalty);
                                    }
                                    
                    });

                    if(this.form.penalty!==null && this.form.penalty!==''){
                        real_total = parseInt(real_total) + parseInt(this.form.penalty);
                    }
                    if(this.form.dearness!==null && this.form.dearness!==''){
                        real_total = parseInt(real_total) + parseInt(this.form.dearness);
                    }

                     this.many = this.selectedDataWorkers.length + this.selectedData.length;

                    console.log(this.form.voucher_id);
                    console.log(this.form.stock_id);

                     if(this.form.voucher_id==null && this.form.stock_id==null){
                        this.form.extra_price =  parseInt(real_total);
                        this.final_total_price = parseInt(real_total) + (parseInt(this.form.price) * parseInt(this.many));
                        this.form.real_total_price = parseInt(real_total) + (parseInt(this.form.price) * parseInt(this.many)) ;
                        this.form.payment_price = 0;
                    }


                     if(this.form.stock_ticket_id==2 && this.vouchers.length>0){
                         if(this.form.voucher_id!=null){
                                let w = this.vouchers.find(w => w.id === this.form.voucher_id);
                                if(this.many>0){
                                let diff = (parseInt(this.form.price) * parseInt(this.many)) + parseInt(real_total);
                                this.form.payment_price = w.price;
                                if(diff>w.price){
                                    this.form.extra_price = (w.price-diff) *-1;
                                    this.final_total_price =  parseInt(this.form.extra_price) + (parseInt(this.form.price) * parseInt(this.many));
                                    this.form.real_total_price = parseInt(this.form.extra_price);
                                }else{
                                    this.form.extra_price = 0;
                                    this.form.real_total_price = parseInt(this.form.extra_price) + parseInt(real_total) + (parseInt(this.form.price) * parseInt(this.many)) ;
                                }
                                } 
                         }    
                    }

                    if(this.form.stock_ticket_id==1 && this.stocks.length>0){
                        if(this.form.stock_id!=null){                         
                                let w = this.stocks.find(w => w.id === this.form.stock_id);
                                if(this.many>0){
                                let diff = (parseInt(this.form.price) * parseInt(this.many)) + parseInt(real_total);
                                this.form.payment_price = w.price;
                                if(diff>w.price){
                                    this.form.extra_price = (w.price-diff) *-1;
                                    this.final_total_price = parseInt(this.form.extra_price) + (parseInt(this.form.price) * parseInt(this.many));
                                    this.form.real_total_price = parseInt(this.form.extra_price);
                                }else{
                                    this.form.extra_price = 0;
                                    this.form.real_total_price = parseInt(this.form.extra_price) + parseInt(real_total) + (parseInt(this.form.price) * parseInt(this.many)) ;
                                }
                                } 
                        }                
                    }

                    if(this.form.stock_ticket_id==null){
                        this.form.extra_price =  parseInt(real_total);
                        this.final_total_price = parseInt(real_total) + (parseInt(this.form.price) * parseInt(this.many));
                        this.form.real_total_price = parseInt(real_total) + (parseInt(this.form.price) * parseInt(this.many)) ;
                        this.form.payment_price = 0;
                    }
                }        
               
              
            },
             unselect (id) {
                this.selected.splice(this.selected.findIndex(s => s === id), 1);
                this.selectedData.splice(this.selectedData.findIndex(s => s.id === id), 1);
                this.calculate();
            },
            unselectWorkers (id) {
                this.selectedWorkers.splice(this.selectedWorkers.findIndex(s => s === id), 1);
                this.selectedDataWorkers.splice(this.selectedDataWorkers.findIndex(s => s.id === id), 1);
                this.calculate();
            },
             removestock (id,index) {
                this.tostock.splice(this.tostock.findIndex(s => s.person_ticket_id === id), 1);
                this.have_stock = 0;
                this.selectedData[index].stock = 0;
               
            },
             removelost (id,index) {
                this.tolost.splice(this.tolost.findIndex(s => s.person_ticket_id === id), 1);
                this.selectedData[index].ticket_status_id = 3;
               
            },
            removestockWorkers (id,index) {
                this.tostockWorkers.splice(this.tostockWorkers.findIndex(s => s.worker_ticket_id === id), 1);
                this.have_stock = 0;
                this.selectedDataWorkers[index].stock = 0;
               
            },

             removelostWorkers (id,index) {
                this.tolostWorkers.splice(this.tolostWorkers.findIndex(s => s.worker_ticket_id === id), 1);
                this.selectedDataWorkers[index].ticket_status_id = 3;
               
            },
            toggleSelection (id) {
                if (this.selected.includes(id)) {
                    let w = this.persons.data.find(w => w.id === id);

                    this.selectedData.push({
                        id: id,
                        rut: w.rut,
                        last_name: w.last_name,
                        name: w.name,
                        carry_price: 0,
                        stock:0,
                        iseditable:1,
                        quotation:false,
                        penalty_quotation:false,
                        extra_price:0,
                        observation:'',
                        penalty:0
                    });
                } else {
                    this.selectedData.splice(this.selectedData.findIndex(s => s.id === id), 1);
                }
            },
              toggleSelectionWorkers (id) {
                if (this.selectedWorkers.includes(id)) {
                    let w = this.workers.data.find(w => w.id === id);

                    this.selectedDataWorkers.push({
                        id: id,
                        rut: w.rut,
                        last_name: w.last_name,
                        name: w.name,
                        carry_price: 0,
                        stock:0,
                        iseditable:1,
                         quotation:false,
                         penalty_quotation:false,
                        extra_price:0,
                        observation:'',
                        penalty:0
                    });
                } else {
                    this.selectedDataWorkers.splice(this.selectedDataWorkers.findIndex(s => s.id === id), 1);
                }
            },
            personModal () {
                this.getPersons();
                this.showModal = true;
                this.$nextTick(() => $('#form-modal-person').modal('show'));
            },
              setTicket(){
                 if(this.many==0){
                      this.$launchError('No puede guardar si no ha agregado personas o tripulantes');
                  }else{
                         Swal.fire({
                    title: '¿Estás seguro que deseas guardar este pasaje?',
                    text: 'Los cambios a Stock y Eliminacion no pueden ser revertidos',
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Si',
                    cancelButtonText: 'No'
                }).then((result) => {
                    if (result.value) {
                        this.form.ticket_id = this.id;
                        this.form.selected = this.selectedData;
                        this.form.selectedWorkers = this.selectedDataWorkers;
                        this.form.tostock = this.tostock;
                        this.form.tostockWorkers = this.tostockWorkers;

                        this.form.tolost = this.tolost;
                        this.form.tolostWorkers = this.tolostWorkers;
                        //console.log(this.form.toStockWorkers);
                        // console.log(this.form.tostock);
                        this.form.passengers = this.many;
                        this.form.total_price = this.final_total_price;
                        this.form.total_extra_price = (this.form.extra_price/this.many);

                        if(this.form.stock_ticket_id==1){
                            this.form.voucher_id = null;
                        }
                        if(this.form.stock_ticket_id==2){
                            this.form.stock_id = null;
                        }
                        if(this.form.stock_ticket_id==null){
                            this.form.stock_id = null;
                            this.form.voucher_id = null;
                        }
                            console.log(this.form);
                         this.form.post('/api/administracion/personas/nuevo/pasaje')
                        .then((data) => {
                            //this.persons = [];
                            //this.selectedData= [];
                            //this.selected = [];
                            //this.form.reset();

                             this.tostock.forEach(d => {
                                  let t = this.selectedData.findIndex(w => w.id === d.id);
                                  this.selectedData[t].iseditable = 0;
                                  
                              
                             });

                             this.tostockWorkers.forEach(d => {
                                  let t = this.selectedDataWorkers.findIndex(w => w.id === d.id);
                                  this.selectedDataWorkers[t].iseditable = 0;
                                 
                              
                             });


                             this.tolost.forEach(d => {
                                  let t = this.selectedData.findIndex(w => w.id === d.id);
                                  this.selectedData[t].editable_lost = 0;
                              
                             });

                             this.tolostWorkers.forEach(d => {
                                  let t = this.selectedDataWorkers.findIndex(w => w.id === d.id);
                                  this.selectedDataWorkers[t].editable_lost = 0;
                                 
                              
                             });

                               if(this.form.code!='' && this.form.code!=null){
                                    this.editable = 0;
                                }else{
                                    this.editable = 1;
                                }
                  
                            this.have_stock = data.data.data.have_stock;
                            this.is_stock = data.data.data.is_stock;
                            this.$launchSuccess('Se guardo el pasaje correctamente');
                            this.$Progress.finish();
                            //window.location.href = '/api/editar-pasaje/'+this.id;
                            
                        })
                        .catch(() => {
                            this.$Progress.fail();
                            this.$launchError('Error al guardar pasaje');
                        });
                        }
                    });  
                  }     
            },
            toStock(person_ticket_id,index,id,carry_price){
                if(this.form.code==null  || this.form.code==''){
                    this.$launchError('Debe agregar codigo de vuelo para pasar a stock');
                }else{
                    if (this.tostock.includes(id)) {
                  
                    } else {
                        this.tostock.push({person_ticket_id:person_ticket_id,id:id,carry_price:carry_price});
                        this.have_stock = 1;
                        this.selectedData[index].stock = 1;
                    }
                }
                
            },
            toLost(person_ticket_id,index,id,carry_price){
                if(this.form.code==null  || this.form.code==''){
                    this.$launchError('Debe agregar codigo de vuelo para pasar a stock');
                }else{
                    if (this.tolost.includes(id)) {
                  
                    } else {
                        this.tolost.push({person_ticket_id:person_ticket_id,id:id,carry_price:carry_price});
                        this.selectedData[index].ticket_status_id = 5;
                       // this.selectedData[index].stock = 1;
                    }
                }
                
            },
           
            toStockWorkers(worker_ticket_id,index,id,carry_price){
                if(this.form.code==null || this.form.code==''){
                    this.$launchError('Debe agregar codigo de vuelo para pasar a stock');
                }else{
                    if (this.tostockWorkers.includes(id)) {
                  
                    } else {
                        this.tostockWorkers.push({worker_ticket_id:worker_ticket_id,id:id,carry_price:carry_price});
                        this.have_stock = 1;
                        this.selectedDataWorkers[index].stock = 1;
                    }
                }
                
            },
             toLostWorkers(worker_ticket_id,index,id,carry_price){
                if(this.form.code==null || this.form.code==''){
                    this.$launchError('Debe agregar codigo de vuelo para marcar como perdido');
                }else{
                    if (this.tolostWorkers.includes(id)) {
                  
                    } else {
                        this.tolostWorkers.push({worker_ticket_id:worker_ticket_id,id:id,carry_price:carry_price});
                         this.selectedDataWorkers[index].ticket_status_id = 5;
                        //this.have_stock = 1;
                        //this.selectedDataWorkers[index].lost = 1;
                    }
                }
                
            },
             workerModal () {
                this.getWorkers();
                this.showModal = true;
                this.$nextTick(() => $('#form-modal-worker').modal('show'));
            },
             getWorkers (page = 1) {
                this.$Progress.start();
                this.loading = true;

                axios.get('/api/administracion/workers?page=' + page + (this.searchInputWorker ? `&search=${this.searchInputWorker}`: ''))
                    .then(({ data }) => this.workers = data.data)
                    .catch(() => this.$launchError('Error al obtener listado de tripulantes'))
                    .finally(() => {
                        this.$Progress.finish();
                        this.loading = false;
                    });
                   
            },
            setStock(person_ticket_id,index){
                Swal.fire({
                    title: '¿Estás seguro que desea enviar este pasaje a stock?',
                    text: 'No podrás revertir esta acción',
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Si',
                    cancelButtonText: 'No'
                }).then((result) => {
                    if (result.value) {
                        this.formStock.person_ticket_id = person_ticket_id;
                        this.formStock.ticket_id = this.id;
                        this.formStock.post('/api/administracion/personas/pasajes/a-stock')
                        .then((data) => {
           
                            this.is_stock = data.data.data;
                            this.have_stock = 1;
                            this.selectedData[index].stock = 1;
                            this.$launchSuccess('Se guardo el pasaje correctamente');
                            this.$Progress.finish();
                        })
                        .catch(() => {
                            this.$Progress.fail();
                            this.$launchError('Error al guardar pasaje');
                        });
                        }
                });     
            },
            getData(){
                this.loading = true;
                this.$Progress.start();

                axios.get('/api/administracion/find-pasaje/'+this.id)
                    .then(({ data }) => this.datos = data.data)
                    .catch(() => {
                        this.$launchError('Error al obtener traslado');
                        this.$Progress.fail();
                    })
                    .finally(() => {
                        this.loading = false;
                        this.$Progress.finish();

                        console.log(this.datos);

                    this.form.airline_id= this.datos.airline;
                    // console.log(this.datos.payment_type);   
                    this.form.stock_ticket_id =this.datos.payment_type;
                    this.original_payment_type = this.datos.payment_type;
                    this.original_voucher_id = this.datos.voucher_id;
                    this.original_stock_id = this.datos.stock_ticket_id;
                    this.original_airline_id = this.datos.airline;
                    this.getVouchers(this.form.airline_id);
                    this.getStocks(this.form.airline_id);
                   
                    this.form.flight_stretch_id= this.datos.stretch;
                    this.form.flight_date= this.datos.flight_date;
                    this.form.departure_time= this.datos.departure_time;
                    this.form.arrival_time= this.datos.arrival_time;
                    this.form.departure_airport_id= this.datos.departure_airport_id;
                    this.form.arrival_airport_id= this.datos.arrival_airport_id;
                    this.form.extra_price= this.datos.extra_price;
                    this.form.carry_price= 0;
                    this.form.ticket_status_id= this.datos.ticket_status_id;
                    this.form.price= this.datos.price;           
                    this.form.tarifa_plus= this.datos.plus;
                    this.have_stock = this.datos.have_stock;
                    this.is_stock = this.datos.is_stock;
                    this.form.dearness = this.datos.dearness;
                    this.form.penalty = this.datos.penalty;
                    this.form.code = this.datos.code;
                    this.form.expiration_date = this.datos.expiration_date;
                    this.form.purchase_order = this.datos.oc;
                    this.form.purchase_order_date = this.datos.oc_date;
                    if(this.datos.code!=null && this.datos.code!=''){
                        this.editable = 0;
                    }

                    console.log(this.form);
                    
                    if(this.datos.invoice_id!=null){
                        this.quoted = 1;
                    }

                    
         
                  /* if(this.datos.payment_type==1 || this.datos.payment_type ==2){
                        this.setPaymentMode();
                   }else{
                   
                   }*/
                     
                           
                    this.many = this.datos.passengers;


                    this.datos.persons.forEach(d => {
                                this.selected.push(d.id); 
                                let editable = 1;
                                let editable_lost = 1;
                                if(d.stock==1){
                                    editable = 0;
                                }
                                if(d.ticket_status_id==5){
                                    editable_lost = 0;
                                }
                                this.selectedData.push({id:d.id,
                                    name:d.name,
                                    last_name:d.last_name,
                                    rut:d.rut,
                                    carry_price:d.carry_price,
                                    person_ticket_id:d.person_ticket_id,
                                    stock:d.stock,
                                    iseditable:editable,
                                    quotation:d.quotation,
                                     penalty_quotation:d.penalty_quotation,
                                    extra_price:d.extra_price,
                                    observation:d.observation,
                                    penalty:d.penalty,
                                    ticket_status_id:d.ticket_status_id,
                                    editable_lost: editable_lost
                                    });
                            });
                        
                     this.datos.workers.forEach(d => {
                                this.selectedWorkers.push(d.id); 
                                let editable = 1;
                                let editable_lost = 1;
                                if(d.stock==1){
                                    editable = 0;
                                }
                                if(d.ticket_status_id==5){
                                    editable_lost = 0;
                                }
                                this.selectedDataWorkers.push({
                                    id:d.id,
                                    name:d.name,
                                    last_name:d.last_name,
                                    rut:d.rut,
                                    carry_price:d.carry_price,
                                    worker_ticket_id:d.worker_ticket_id,
                                    stock:d.stock,
                                    iseditable:editable,
                                    quotation:d.quotation,
                                    penalty_quotation:d.penalty_quotation,
                                    extra_price:d.extra_price,
                                    observation:d.observation,
                                    penalty:d.penalty,
                                    ticket_status_id:d.ticket_status_id,
                                    editable_lost: editable_lost
                                    });
                            });

                                this.loaded =1;
                                this.calculate();
                         
                    });
            },

        
            getPersons (page = 1) {
                this.$Progress.start();
                this.loading = true;

                axios.get('/api/administracion/personas?page=' + page + (this.searchInput ? `&search=${this.searchInput}`: ''))
                    .then(({ data }) => this.persons = data.data)
                    .catch(() => this.$launchError('Error al obtener listado de personas'))
                    .finally(() => {
                        this.$Progress.finish();
                        this.loading = false;
                    });
                   
            },
            getAirlines () {
                axios.get('/api/logistica/aerolineas')
                    .then(({ data }) => this.airlines = data.data)
                    .catch(() => this.$launchError('Error al obtener aerolíneas'));
            },

            getAirports () {
                axios.get('/api/logistica/aeropuertos')
                    .then(({ data }) => this.airports = data.data)
                    .catch(() => this.$launchError('Error al obtener aeropuertos'));
            },

            getFlightStretches () {
                axios.get('/api/logistica/tramos')
                    .then(({ data }) => this.flightStretches = data.data)
                    .catch(() => this.$launchError('Error al obtener tramos'));
            },
            setPaymentMode(){
                if(this.form.stock_ticket_id==1){
                    this.getStocks(this.form.airline_id);
                    
                }
                if(this.form.stock_ticket_id==2){
                    this.getVouchers(this.form.airline_id);
                }
            },

              getVouchers (id) {
                  if(this.datos.voucher_id==null){
                    axios.get('/api/administracion/pasajes/vouchers/'+id)
                    .then(({ data }) => this.vouchers = data.data)
                    .catch();
                  }else{
                    axios.get('/api/administracion/pasajes/vouchersWithUsed/'+id+'/'+this.datos.voucher_id+'/'+this.id)
                    .then(({ data }) => this.vouchers = data.data,this.form.voucher_id = this.datos.voucher_id)
                    .catch();
                  }
           

                    
            },
            getStocks (id) {

                if(this.datos.stock_ticket_id==null){
                    axios.get('/api/administracion/pasajes/stocks/'+id)
                    .then(({ data }) => this.stocks = data.data)
                    .catch();
                    
                }else{
                    axios.get('/api/administracion/pasajes/stocksWithUsed/'+id+'/'+this.datos.stock_ticket_id)
                    .then(({ data }) => this.stocks = data.data,this.form.stock_id = this.datos.stock_ticket_id)
                    .catch();
                    
                }

             
                    
            },
        }
    }
</script>

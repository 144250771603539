<template>
    <div>
        <div class="row">
            <div class="col form-group">
                <label>Clasificación</label>
                <select v-model="form.report_type" type="text" name="clasificación" class="form-control">
                    <option :value="null" disabled>Seleccione un informe</option>
                    <option :value="0" >Documentos Vencidos y Faltantes</option>
                    <option :value="1" >Documentos por vencer próximo mes</option>
                    <option :value="2" >Atributos de tripulantes</option>
                    <option :value="3" >Base de datos personal</option><!-- antiguedad laboral -->
                    <option :value="4" >Tripulantes Disponibles</option>
                    <option :value="5" >Historial Compras de Pasajes</option>
                     <option :value="6" >Licencias y Permisos</option>
                     <option :value="7" >Relevos</option>
                     <!-- <option :value="8" >Observaciones Revistas</option> -->
                </select>
            </div>

             <div class="col form-group"  v-if="form.report_type==5">
            <div class="row">
            <div class="col form-group">
                <label>Fecha Inicio</label>
                 <input v-model="form.d1" type="date" name="fecha de Inicio" class="form-control">
            </div>
            <div class="col form-group">
                <label>Fecha Fin</label>
                 <input v-model="form.d2" type="date" name="fecha de Fin" class="form-control">
            </div>
             </div>
             </div>

            <div class="col form-group" v-if="form.report_type==7">
            <div class="row" >
            <div class="col form-group">
                <label>Mes y Año (Seleccione cualquier dia del mes)</label>
                 <input v-model="form.d1" type="date" name="fecha de Inicio" class="form-control">
            </div>
            </div>
            </div>


             <div class="col form-group" v-if="form.report_type==8">
            <div class="row" >
            <div class="col form-group">
                <label>Seleccione Año</label>
                 <select v-model="form.d1" type="text" name="anio" class="form-control">
                    <option :value="null" disabled>Seleccione un año</option>
                    <option :value="2021" >2021</option>
                    <option :value="2022" >2022</option>
                    <option :value="2023" >2023</option>
                    <option :value="2024" >2024</option>
                    <option :value="2025" >2025</option>
                    <option :value="2026" >2026</option>
                    <option :value="2027" >2027</option>
                    <option :value="2028" >2028</option>
                    <option :value="2029" >2029</option>
                    <option :value="2030" >2030</option>
                </select>
            </div>
            </div>
            </div>

        </div>



      

      
    </div>
</template>

<script>
    export default {
        props: {
            form: Object,
        }
    }
</script>

<template>
    <div>
        <!-- breadcrumb -->
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Crear cotización</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link to="/">Inicio</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link to="/logistica/tripulaciones">Tripulaciones</router-link>
                            </li>
                            <li class="breadcrumb-item active">Crear cotización</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>

        <!-- creación -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col">
                        <div class="card">
                            <loading v-if="loading"/>

                            <div class="card-body">
                                <!-- mensaje de cotización enviada -->
                                <div class="alert alert-success" role="alert" v-if="!canModify">
                                    Esta cotización fue enviada el día {{ quotation.entered_at | myDate }}.
                                </div>


                        <!-- colaciones -->
                                <div class="row d-flex align-items-center mb-3">
                                    <div class="col-10">
                                        <h4 class="mb-0">Colaciones</h4>
                                    </div>

                                    <div class="col">
                                        <button class="btn btn-primary float-right" v-if="isclosed==0"
                                                :disabled="canConfirm"
                                                @click="setSnack(null)">
                                            Añadir
                                        </button>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col table-responsive p-0">
                                        <table class="table table-hover" v-if="quotation.snacks.length > 0">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Código</th>
                                                    <th>Reservas</th>
                                                    <th>Descripcion</th>
                                                      <th>Tramo</th>
                                                    <th>Monto Total</th>
                                                    <th v-if="isclosed==0">Acciones</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(t, i) in quotation.snacks">
                                                    <td>{{ i + 1 }}</td>
                                                    <td>CB{{ t.id }}</td>
                                                    <td>{{ t.quantity }}</td>
                                                    <td>{{ t.description }}</td>
                                                     <td>{{ journey(t.journey_type) }}</td>
                                                    <td>{{ t.price}}</td>

                                                    

                                                    <td v-if="isclosed==0 && t.invoice_id==null">
                                                        <a href="#" @click.prevent="setSnack(t.id)"
                                                           v-tooltip="'Editar colación'">
                                                            <i class="fa green fa-edit"/>
                                                        </a>
                                                       
                                                        <a href="#" @click.prevent="associateSnackWorkers(t.id)"
                                                           v-tooltip="'Asignar colación a tripulantes'">
                                                            <i class="fa blue fa-user" v-if="!canConfirm"/>
                                                        </a>
                                                        <span v-if="!canConfirm">/</span>
                                                        <a href="#" @click.prevent="deleteSnack(t.id)"
                                                           v-tooltip="'Eliminar colación'">
                                                            <i class="fa red fa-times"/>
                                                        </a>
                                                    </td>
                                                    
                                                </tr>
                                            </tbody>
                                        </table>

                                        <p class="text-center mb-4" v-else>
                                            <i>No se han agregado colaciones aún.</i>
                                        </p>
                                    </div>
                                </div>
                                <!-- fin colacion -->

                                   <!-- alojamiento -->
                                <div class="row d-flex align-items-center mb-3">
                                    <div class="col-10">
                                        <h4 class="mb-0">Alojamientos</h4>
                                    </div>

                                    <div class="col">
                                        <button class="btn btn-primary float-right" v-if="isclosed==0"
                                                :disabled="canConfirm"
                                                @click="setAccomodation(null)">
                                            Añadir
                                        </button>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col table-responsive p-0">
                                        <table class="table table-hover" v-if="quotation.accommodations.length > 0">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                     <th>Código</th>
                                                    <th>Reservas</th>
                                                    <th>Fecha Subida</th>
                                                    <th>Fecha Bajada</th>
                                                    <th>Precio Total</th>
                                                    <th>Proveedor</th>
                                                    <th>Tramo</th>
                                                    <th>Observacion</th>
                                                    <th>Acciones</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(t, i) in quotation.accommodations">
                                                    <td>{{ i + 1 }}</td>
                                                    <td>AB{{ t.id }}</td>
                                                    <td>{{ t.quantity }}</td>
                                                    <td>{{ t.entry_date | myDate}}</td>
                                                    <td>{{ t.exit_date }}</td>
                                                    <td>{{ t.price | dash }}</td>
                                                    <td>{{ t.supplier }}</td>
                                                    <td>{{ journey(t.journey_type) }}</td>
                                                    <td>{{  t.observation}}</td>
                                                    <td v-if="isclosed==0 && t.invoice_id==null">
                                                        <a href="#" @click.prevent="setAccomodation(t.id)"
                                                           v-tooltip="'Editar Alojamiento'">
                                                            <i class="fa green fa-edit"/>
                                                        </a>
                                                        /
                                                      
                                                        <a href="#" @click.prevent="associateAccommodationWorkers(t.id)" 
                                                           v-tooltip="'Asignar stock a trabajador'">
                                                            <i class="fa blue fa-user"/>
                                                        </a>
                                                        <span >/</span>
                                                        <a href="#" @click.prevent="deleteAccommodation(t.id)"
                                                           v-tooltip="'Eliminar Alojamiento'">
                                                            <i class="fa red fa-times"/>
                                                        </a>
                                                    </td>
                                                    <td v-else-if="t.invoice_id!=null">Facturado</td>
                                                    <td v-else-if="t.invoice_id==null">Sin Facturar</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <p class="text-center mb-4" v-else>
                                            <i>No se han agregado alojamientos aún.</i>
                                        </p>
                                    </div>
                                </div>
                                <!-- fin alojamiento -->

                                   <!-- traslados -->
                                <div class="row d-flex align-items-center mb-3">
                                    <div class="col-10">
                                        <h4 class="mb-0">Traslados</h4>
                                    </div>

                                    <div class="col">
                                        <button class="btn btn-primary float-right" v-if="isclosed==0"
                                                :disabled="canConfirm"
                                                @click="setTransfer(null)">
                                            Añadir
                                        </button>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col table-responsive p-0">
                                        <table class="table table-hover" v-if="quotation.transfers.length > 0">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                     <th>Código</th>
                                                    <th>Reservas</th>
                                                    <th>Fecha Recogida</th>
                                                    <th>Entrada</th>
                                                    <th>Salida</th>
                                                    <th>Precio Total</th>
                                                    <th>Proveedor</th>
                                                    <th>Tramo</th>
                                                    <th>Observacion</th>
                                                    <th>Facturar</th>
                                                    <th>Acciones</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(t, i) in quotation.transfers">
                                                    <td>{{ i + 1 }}</td>
                                                     <td>TB{{ t.id }}</td>
                                                    <td>{{ t.quantity }}</td>
                                                    <td>{{ t.upload_date | myDate }}</td>
                                                    <td>{{ t.entry_text }}</td>
                                                    <td>{{ t.exit_text }}</td>
                                                    <td>{{ t.price | dash }}</td>
                                                    <td>{{ t.supplier }}</td>
                                                    <td>{{ journey(t.journey_type) }}</td>
                                                    <td>{{ t.observation}}</td>
                                                    <td>{{ quotationtype(t.quotation) }}</td>
                                                    <td v-if="isclosed==0 && t.invoice_id==null">
                                                        <a href="#" @click.prevent="setTransfer(t.id)"
                                                           v-tooltip="'Editar traslado'">
                                                            <i class="fa green fa-edit"/>
                                                        </a>
                                                        /
                                                      
                                                        <a href="#" @click.prevent="associateTransferWorkers(t.id)"
                                                           v-tooltip="'Asignar traslado a tripulante'">
                                                            <i class="fa blue fa-user"/>
                                                        </a>
                                                        <span v-if="!canConfirm">/</span>
                                                        <a href="#" @click.prevent="deleteTransfer(t.id)"
                                                           v-tooltip="'Eliminar traslado'">
                                                            <i class="fa red fa-times"/>
                                                        </a>
                                                    </td>
                                                    <td v-else-if="t.invoice_id!=null">Facturado</td>
                                                    <td v-else-if="t.invoice_id==null">Sin Facturar</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                       <p class="text-center mb-4" v-else>
                                            <i>No se han agregado traslados aún.</i>
                                        </p>
                                    </div>
                                </div>
                                <!-- fin trasaldos-->

                                <!-- pasajes -->
                                <div class="row d-flex align-items-center mb-3">
                                    <div class="col-10">
                                        <h4 class="mb-0">Pasajes</h4>
                                    </div>

                                    <div class="col">
                                        <button class="btn btn-primary float-right" v-if="isclosed==0"
                                                
                                                @click="setTicket(null)">
                                            Añadir
                                        </button>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col table-responsive p-0">
                                        <table class="table table-hover" v-if="quotation.tickets.length > 0">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                     <th>Código</th>
                                                    <th>Aerolínea</th>
                                                     <th>Código Vuelo</th>
                                                    <th>Tramo</th>
                                                    <th>Ruta</th>
                                                    <th>Fecha</th>
                                                    <th>Hora salida</th>
                                                    <th>Hora llegada</th>   
                                                    <th>Utilizó..</th>    
                                                    <th>Tarifa</th>
                                                    <th>Cantidad</th>
                                                    <th>Tarifa Total</th> 
                                                    <!--<th>Multa</th>-->
                                                    <th>Afecto</th>
                                                    <th>Diferencial Tarifa</th>
                                                   <!-- <th>Diferencial Tarifa Unitario</th>-->
                                                    <th>OC</th>
                                                    <th>Vencimiento</th>
                                                   
                                                    
                                                    <th>Acciones</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(t, i) in quotation.tickets">
                                                    <td>{{ i + 1 }}</td>
                                                    <td>PJ{{t.id }}</td>
                                                    <td>{{ t.airline.name }}</td>
                                                      <td>{{t.code}}</td>
                                                    <td>{{ t.flight_stretch.name }}</td>
                                                    <td>
                                                        {{ t.departure_airport.iata }} -
                                                        {{ t.arrival_airport.iata }}
                                                    </td>
                                                    <td>{{ t.flight_date | myDate }}</td>
                                                    <td>{{ t.departure_time | myHour }}</td>
                                                    <td>{{ t.arrival_time | myHour }}</td>
                                                     <td v-if="t.payment_type!=null">{{ paytype(t.payment_type) }}(${{t.payment_use_price| dotNumber}})</td>
                                                     <td v-else> Normal </td>
                                                    <td>
                                                        ${{ t.price | dotNumber }}
                                                    </td>
                                                    <td>{{ t.passengers }}</td>
                                                    <td>${{ t.price * t.passengers | dotNumber }}</td>
                                                   <!-- <td>$ {{t.penalty| dotNumber}}</td>-->
                                                    <td>$ {{t.dearness| dotNumber}}</td>
                                                    
                                                     <td>$ {{t.extra_price| dotNumber}}</td>
                                                    <!-- <td>$ {{t.unitary_extra_price| dotNumber}}</td>-->
                                                     <td>{{t.oc}}</td>
                                                    <td>{{t.expiration_date}}</td>
                                                   
                                                      
                                                    
                                                   
                                                    <td v-if="isclosed==0 && t.invoice_id==null">
                                                        <a href="#" @click.prevent="setTicket(t.id)"
                                                           v-tooltip="'Editar pasaje'">
                                                            <i class="fa green fa-edit"/>
                                                        </a>
                                                        /
                                                        <a href="#" @click.prevent="associateWorkers(t.id)"
                                                           v-if="t.stock_ticket_id === null"
                                                           v-tooltip="'Asociar trabajadores'">
                                                            <i class="fa blue fa-users" />
                                                        </a>
                                                        <a href="#" @click.prevent="associateWorkers(t.id, true)" v-else
                                                           v-tooltip="'Asignar stock a trabajador'">
                                                            <i class="fa blue fa-user" />
                                                        </a>
                                                        <span v-if="!canConfirm">/</span>
                                                        <a href="#" @click.prevent="deleteTicket(t.id)"
                                                           v-tooltip="'Eliminar pasaje'">
                                                            <i class="fa red fa-times"/>
                                                        </a>
                                                    </td>
                                                    <td v-else-if="t.invoice_id!=null">Facturado</td>
                                                    <td v-else-if="t.invoice_id==null">Sin Facturar</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <p class="text-center mb-4" v-else>
                                            <i>No se han agregado pasajes aún.</i>
                                        </p>
                                    </div>
                                </div>

                                <!-- trabajadores -->
                                <h4 class="mb-3">Tripulantes</h4>

                                <div class="row">
                                    <div class="col table-responsive p-0">
                                        <table class="table table-hover" v-if="!loading">
                                            <thead>
                                                <tr>
                                                    <th class="align-middle">Nombre</th>
                                                   
                                                    <th class="align-middle">Rol</th>
                                                    <th class="align-middle">Aloj</th>
                                                    <th class="align-middle">Col</th>
                                                    <th class="align-middle">Tras</th>
                                                    <th class="align-middle" >Pasajes</th>
                                                 <!--   <th class="align-middle">Observaciones</th>-->
                                                    <th class="align-middle">$ Maleta Ida</th>
                                                    <th class="align-middle">Facturar Maleta Ida</th>
                                                    <th class="align-middle">Multa Ida</th>
                                                    <th class="align-middle">Facturar Multa Ida</th>
                                                    <th class="align-middle">$ Extra Ida</th>
                                                    <th class="align-middle">Obs. Ida</th>
                                                    <th class="align-middle">$ Maleta Vuelta</th>
                                                    <th class="align-middle">Facturar Maleta Vuelta</th>
                                                    <th class="align-middle">Multa Vuelta</th>
                                                    <th class="align-middle">Facturar Multa Vuelta</th>
                                                    <th class="align-middle">$ Extra Vuelta</th>
                                                    <th class="align-middle">Obs. Vuelta</th>
                                                    <th class="align-middle">SMS Ida</th>
                                                    <th class="align-middle">SMS Vuelta</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr v-for="(w,index)  in workers" :class="{ 'table-warning': w.filtered, 'table-danger': w.with_emergency || w.worker.deleted_at!=null }">
                                                   
                                                    <td  align="center" class="align-middle">

                                                         <router-link :to="'/admin/trabajadores/' + w.worker.id"
                                                                     v-tooltip="'Ficha del tripulante'">
                                                            {{ w.worker.name }} {{ w.worker.last_name }}
                                                            <br>
                                                            {{ w.worker.rut }}
                                                        </router-link>
                                                        
                                                    </td>
                                                   
                                                    <td  align="center" class="align-middle">{{ w.worker.roles.map(r => r.name) | list }}</td>
                                                    <td align="center"  class="align-middle">
 
                                                        <span v-if="w.lodging_id!=0 && w.worker.deleted_at==null" v-tooltip="'Alojamiento Ida'">AB{{ w.lodging_id }}
                                                        <a href="#" v-if="isclosed==0 && w.invoice_id_ida==null  && w.worker.deleted_at==null" @click.prevent="deleteAccommodationWorker(w.worker.id,w.lodging_id)"
                                                           v-tooltip="'Quitar Alojamiento Ida'">
                                                            <i class="fa red fa-times"/>
                                                        </a>
                                                        </span>
                                                        <span v-if="w.lodging_id_vuelta!=0  && w.worker.deleted_at==null" v-tooltip="'Alojamiento Vuelta'">AB{{ w.lodging_id_vuelta}}
                                                            <a href="#" v-if="isclosed==0 && w.invoice_id_vuelta==null  && w.worker.deleted_at==null" @click.prevent="deleteAccommodationWorker(w.worker.id,w.lodging_id_vuelta)"
                                                           v-tooltip="'Quitar Alojamiento Vuelta'">
                                                            <i class="fa red fa-times"/>
                                                        </a>
                                                        </span>
                                                        <span v-if="w.lodging_id==0 && w.lodging_id_vuelta==0  && w.worker.deleted_at==null">
                                                            <span v-if="w.lodging">Si</span>
                                                            <span v-else>No</span>
                                                        </span>

                                                    </td>
                                                    <td align="center"  class="align-middle">
                                                         

                                                        <span v-if="w.snack_id!=0  && w.worker.deleted_at==null" v-tooltip="'Colación Ida'">CB{{ w.snack_id }}
                                                        <a href="#" v-if="isclosed==0  && w.worker.deleted_at==null" @click.prevent="deleteSnackWorker(w.worker.id,w.snack_id)"
                                                           v-tooltip="'Quitar Colación Ida'">
                                                            <i class="fa red fa-times"/>
                                                        </a>
                                                        </span>
                                                        <span v-if="w.snack_id_vuelta!=0 && w.worker.deleted_at==null" v-tooltip="'Colación Vuelta'">CB{{ w.snack_id_vuelta}}
                                                            <a href="#" v-if="isclosed==0 && w.worker.deleted_at==null" @click.prevent="deleteSnackWorker(w.worker.id,w.snack_id_vuelta)"
                                                           v-tooltip="'Quitar Colación Vuelta'">
                                                            <i class="fa red fa-times"/>
                                                        </a>
                                                        </span>
                                                        <span v-if="w.snack_id==0 && w.snack_id_vuelta==0 && w.worker.deleted_at==null">
                                                            <span v-if="w.lodging_food">Si</span>
                                                            <span v-else>No</span>
                                                        </span>


                                                    </td>
                                                    <td align="center"  class="align-middle">
                                                            <span v-if="w.relocation_id!=0 && w.worker.deleted_at==null" v-tooltip="'Traslado Ida'">TB{{ w.relocation_id }}
                                                        <a href="#" v-if="isclosed==0 && w.invoice_id_ida==null && w.worker.deleted_at==null" @click.prevent="deleteTransferWorker(w.worker.id,w.relocation_id)"
                                                           v-tooltip="'Quitar Traslado Ida'">
                                                            <i class="fa red fa-times"/>
                                                        </a>
                                                        </span>
                                                        <span v-if="w.relocation_id_vuelta!=0 && w.worker.deleted_at==null" v-tooltip="'Traslado Vuelta'">TB{{ w.relocation_id_vuelta}}
                                                            <a href="#" v-if="isclosed==0 && w.invoice_id_vuelta==null && w.worker.deleted_at==null" @click.prevent="deleteTransferWorker(w.worker.id,w.relocation_id_vuelta)"
                                                           v-tooltip="'Quitar Traslado Vuelta'">
                                                            <i class="fa red fa-times"/>
                                                        </a>
                                                        </span>
                                                        <span v-if="w.relocation_id==0 && w.relocation_id_vuelta==0 && w.worker.deleted_at==null">
                                                            <span v-if="w.relocation">Si</span>
                                                            <span v-else>No</span>
                                                        </span>

                                                     
                                                    </td>
                                                    <!-- pasajes -->
                                                    <td align="center"  class="align-middle" v-if="canModify">
                                                        <span v-if="w.ticketida!=null && w.worker.deleted_at==null" v-tooltip="'Pasaje Ida'">PJ{{ w.ticketida.ticket_group_id }}

                                                        <a href="#" v-if="isclosed==0 && w.ticketida.ticket_invoice_id==null && w.worker.deleted_at==null" @click.prevent="deleteWorkerTicket(w.worker.id,w.ticketida.ticket_group_id)"
                                                           v-tooltip="'Quitar Pasaje Ida'">
                                                            <i class="fa red fa-times"/>
                                                        </a>

                                                        </span>
                                                        <span v-if="w.ticketvuelta!=null && w.worker.deleted_at==null" v-tooltip="'Pasaje Vuelta'">PJ{{ w.ticketvuelta.ticket_group_id }}

                                                            <a href="#" v-if="isclosed==0 && w.ticketvuelta.ticket_invoice_id==null && w.worker.deleted_at==null" @click.prevent="deleteWorkerTicket(w.worker.id,w.ticketvuelta.ticket_group_id)"
                                                           v-tooltip="'Quitar Pasaje Vuelta'">
                                                            <i class="fa red fa-times"/>
                                                        </a>

                                                        </span>
                                                       
                                                    </td>
                                      
                                                      <!--ida-->
                                                    
                                     

                                                    <!-- maleta-->
                                                     <td  align="center" class="align-middle" v-if="isclosed==1">
                                                         <span v-if="w.ticketida!=null && w.worker.deleted_at==null">
                                                            <span v-if="w.ticketida.carry_price!='' && w.ticketida.carry_price!=null && w.worker.deleted_at==null">{{w.ticketida.carry_price}}</span>
                                                            <span v-else> 0 </span>
                                                        </span>
                                                         <span v-else> 0 </span>
                                                     </td>
                                                    <td align="center" class="align-middle" v-else-if="isclosed==0 && w.ticketida!=null">
                                                        <input type="number" v-model='w.ticketida.carry_price' v-if="w.ticketida.invoice_id==null && w.ticketida.qclone==1 && w.worker.deleted_at==null" class="form-control">
                                                         <input type="number" v-model='w.ticketida.carry_price' v-else-if="w.ticketida.ticket_invoice_id==null && w.ticketida.qclone==0 && w.worker.deleted_at==null"   class="form-control">
                                                         <span v-else-if="(w.ticketida.invoice_id!=null && w.ticketida.qclone==1) || (w.ticketida.ticket_invoice_id!=null && w.ticketida.qclone==0) && w.worker.deleted_at==null">
                                                            <span v-if="w.ticketida.carry_price!='' && w.ticketida.carry_price!=null && w.worker.deleted_at==null">{{w.ticketida.carry_price}}</span>
                                                            <span v-else> 0 </span>
                                                        </span>
                                                    </td>
                                                    <td  align="center" class="align-middle" v-else-if="isclosed==0 && w.ticketida==null">
                                                        <input type="number" class="form-control" disabled>
                                                    </td>
                                                    <td  align="center" class="align-middle" v-else> 0 </td>
                                                   

                                                      
                                                     <!-- check -->
                                                    <td align="center" class="align-middle" v-if="isclosed==1">
                                                         <span v-if="w.ticketida!=null">
                                                            {{plustype(w.ticketida.quotation)}}
                                                        </span>
                                                        <span v-else> - </span>
                                                    </td>
                                                    <td align="center" class="align-middle" v-else-if="isclosed==0 && w.ticketida!=null">
                                                        <input type="checkbox" v-model='w.ticketida.quotation' v-if="w.ticketida.invoice_id==null && w.ticketida.qclone==1"  class="form-control">
                                                        <input type="checkbox" v-model='w.ticketida.quotation' v-else-if="w.ticketida.ticket_invoice_id==null && w.ticketida.qclone==0"  class="form-control">    
                                                        <span v-else-if="(w.ticketida.invoice_id!=null && w.ticketida.qclone==1) || (w.ticketida.ticket_invoice_id!=null && w.ticketida.qclone==0)">
                                                            {{plustype(w.ticketida.quotation)}}
                                                        </span>
                                                    </td>
                                                    <td align="center" class="align-middle" v-else-if="isclosed==0 && w.ticketida==null">
                                                       -
                                                    </td>
                                                    <td align="center" class="align-middle" v-else> - </td>
                                                    <!--fin check-->
                                                     <!-- fin maleta -->
                                                 
                                                     <!--inicio penalty-->
                                                     <td  align="center" class="align-middle" v-if="isclosed==1">
                                                          <span v-if="w.ticketida!=null">
                                                            <span v-if="w.ticketida.penalty!='' && w.ticketida.penalty!=null">{{w.ticketida.penalty}}</span>
                                                            <span v-else> 0 </span>
                                                        </span>
                                                         <span v-else> 0 </span>
                                                     </td>
                                                    <td align="center" class="align-middle" v-else-if="isclosed==0 && w.ticketida!=null">
                                                        <input type="number" v-model='w.ticketida.penalty' v-if="w.ticketida.ticket_invoice_id==null"  class="form-control">
                                                        <span v-if="w.ticketida.ticket_invoice_id!=null">
                                                            <span v-if="w.ticketida.penalty!='' && w.ticketida.penalty!=null">{{w.ticketida.penalty}}</span>
                                                            <span v-else> 0 </span>
                                                        </span>
                                                    </td>
                                                    <td  align="center" class="align-middle" v-else-if="isclosed==0 && w.ticketida==null">
                                                        <input type="number" class="form-control" disabled>
                                                    </td>
                                                    <td  align="center" class="align-middle" v-else> 0 </td>


                                                           
                                                     <!-- check -->
                                                    <td align="center" class="align-middle" v-if="isclosed==1">
                                                         <span v-if="w.ticketida!=null">
                                                            {{plustype(w.ticketida.penalty_quotation)}}
                                                        </span>
                                                        <span v-else> - </span>
                                                    </td>
                                                    <td align="center" class="align-middle" v-else-if="isclosed==0 && w.ticketida!=null">
                                                        <input type="checkbox" v-model='w.ticketida.penalty_quotation' v-if="w.ticketida.invoice_id==null && w.ticketida.qclone==1"  class="form-control">
                                                        <input type="checkbox" v-model='w.ticketida.penalty_quotation' v-else-if="w.ticketida.ticket_invoice_id==null && w.ticketida.qclone==0"  class="form-control">    
                                                        <span v-else-if="(w.ticketida.invoice_id!=null && w.ticketida.qclone==1) || (w.ticketida.ticket_invoice_id!=null && w.ticketida.qclone==0)">
                                                            {{plustype(w.ticketida.penalty_quotation)}}
                                                        </span>
                                                    </td>
                                                    <td align="center" class="align-middle" v-else-if="isclosed==0 && w.ticketida==null">
                                                       -
                                                    </td>
                                                    <td align="center" class="align-middle" v-else> - </td>
                                                    <!--fin check-->

                                                    <!--penalty-->      


                                                     <!--inicio extra price-->
                                                    <td  align="center" class="align-middle" v-if="isclosed==1">
                                                         <span v-if="w.ticketida!=null">
                                                            <span v-if="w.ticketida.extra_price!='' && w.ticketida.extra_price!=null">{{w.ticketida.extra_price}}</span>
                                                            <span v-else> 0 </span>
                                                        </span>
                                                         <span v-else> 0 </span>
                                                    </td>
                                                    <td align="center" class="align-middle" v-else-if="isclosed==0 && w.ticketida!=null">
                                                        <input type="number" v-model='w.ticketida.extra_price' v-if="w.ticketida.ticket_invoice_id==null"  class="form-control">
                                                        <span v-if="w.ticketida.ticket_invoice_id!=null">
                                                            <span v-if="w.ticketida.extra_price!='' && w.ticketida.extra_price!=null">{{w.ticketida.extra_price}}</span>
                                                            <span v-else> 0 </span>
                                                        </span>
                                                    </td>
                                                    <td  align="center" class="align-middle" v-else-if="isclosed==0 && w.ticketida==null">
                                                        <input type="number" class="form-control" disabled>
                                                    </td>
                                                    <td  align="center" class="align-middle" v-else> 0 </td>
                                                    <!--extra price-->
                                                   
                                                    <!--inicio observacion-->
                                                    <td  align="center" class="align-middle" v-if="isclosed==1">
                                                         <span v-if="w.ticketida!=null">
                                                            <span v-if="w.ticketida.observation!='' && w.ticketida.observation!=null">{{w.ticketida.observation}}</span>
                                                            <span v-else> - </span>
                                                        </span>
                                                         <span v-else> - </span>
                                                    </td>
                                                    <td align="center" class="align-middle" v-else-if="isclosed==0 && w.ticketida!=null">
                                                        <input type="text" v-model='w.ticketida.observation' v-if="w.ticketida.ticket_invoice_id==null"  class="form-control">
                                                        <span v-if="w.ticketida.ticket_invoice_id!=null">
                                                            <span v-if="w.ticketida.observation!='' && w.ticketida.observation!=null">{{w.ticketida.observation}}</span>
                                                            <span v-else> - </span>
                                                        </span>
                                                    </td>
                                                    <td  align="center" class="align-middle" v-else-if="isclosed==0 && w.ticketida==null">
                                                        <input type="text" class="form-control" disabled>
                                                    </td>
                                                    <td  align="center" class="align-middle" v-else> - </td>
                                                    <!--observacion-->


                                                        <!--fin ida-->





                                                        <!--vuelta-->

                                                      <!-- maleta-->
                                                     <td  align="center" class="align-middle" v-if="isclosed==1">
                                                         <span v-if="w.ticketvuelta!=null">
                                                            <span v-if="w.ticketvuelta.carry_price!='' && w.ticketvuelta.carry_price!=null">{{w.ticketvuelta.carry_price}}</span>
                                                            <span v-else> 0 </span>
                                                        </span>
                                                         <span v-else> 0 </span>
                                                     </td>
                                                    <td align="center" class="align-middle" v-else-if="isclosed==0 && w.ticketvuelta!=null">
                                                        <input type="number" v-model='w.ticketvuelta.carry_price' v-if="w.ticketvuelta.invoice_id==null && w.ticketvuelta.qclone==1" class="form-control">
                                                         <input type="number" v-model='w.ticketvuelta.carry_price' v-else-if="w.ticketvuelta.ticket_invoice_id==null && w.ticketvuelta.qclone==0"   class="form-control">
                                                         <span v-else-if="(w.ticketvuelta.invoice_id!=null && w.ticketvuelta.qclone==1) || (w.ticketvuelta.ticket_invoice_id!=null && w.ticketvuelta.qclone==0)">
                                                            <span v-if="w.ticketvuelta.carry_price!='' && w.ticketvuelta.carry_price!=null">{{w.ticketvuelta.carry_price}}</span>
                                                            <span v-else> 0 </span>
                                                        </span>
                                                    </td>
                                                    <td  align="center" class="align-middle" v-else-if="isclosed==0 && w.ticketvuelta==null">
                                                        <input type="number" class="form-control" disabled>
                                                    </td>
                                                    <td  align="center" class="align-middle" v-else> 0 </td>
                                                    <!-- fin maleta -->

                                                      
                                                     <!-- check -->
                                                    <td align="center" class="align-middle" v-if="isclosed==1">
                                                         <span v-if="w.ticketvuelta!=null">
                                                            {{plustype(w.ticketvuelta.quotation)}}
                                                        </span>
                                                        <span v-else> - </span>
                                                    </td>
                                                    <td align="center" class="align-middle" v-else-if="isclosed==0 && w.ticketvuelta!=null">
                                                        <input type="checkbox" v-model='w.ticketvuelta.quotation' v-if="w.ticketvuelta.invoice_id==null && w.ticketvuelta.qclone==1"  class="form-control">
                                                        <input type="checkbox" v-model='w.ticketvuelta.quotation' v-else-if="w.ticketvuelta.ticket_invoice_id==null && w.ticketvuelta.qclone==0"  class="form-control">    
                                                        <span v-else-if="(w.ticketvuelta.invoice_id!=null && w.ticketvuelta.qclone==1) || (w.ticketvuelta.ticket_invoice_id!=null && w.ticketvuelta.qclone==0)">
                                                            {{plustype(w.ticketvuelta.quotation)}}
                                                        </span>
                                                    </td>
                                                    <td align="center" class="align-middle" v-else-if="isclosed==0 && w.ticketvuelta==null">
                                                       -
                                                    </td>
                                                    <td align="center" class="align-middle" v-else> - </td>
                                                    <!--fin check-->


                                                     <!-- inicio penalty -->
                                                     <td  align="center" class="align-middle" v-if="isclosed==1">
                                                          <span v-if="w.ticketvuelta!=null">
                                                            <span v-if="w.ticketvuelta.penalty!='' && w.ticketvuelta.penalty!=null">{{w.ticketvuelta.penalty}}</span>
                                                            <span v-else> 0 </span>
                                                        </span>
                                                         <span v-else> 0 </span>
                                                     </td>
                                                    <td align="center" class="align-middle" v-else-if="isclosed==0 && w.ticketvuelta!=null">
                                                        <input type="text" v-model='w.ticketvuelta.penalty' v-if="w.ticketvuelta.ticket_invoice_id==null"  class="form-control">
                                                        <span v-if="w.ticketvuelta.ticket_invoice_id!=null">
                                                            <span v-if="w.ticketvuelta.penalty!='' && w.ticketvuelta.penalty!=null">{{w.ticketvuelta.penalty}}</span>
                                                            <span v-else> 0 </span>
                                                        </span>
                                                    </td>
                                                    <td  align="center" class="align-middle" v-else-if="isclosed==0 && w.ticketvuelta==null">
                                                        <input type="text" class="form-control" disabled>
                                                    </td>
                                                    <td  align="center" class="align-middle" v-else> 0</td>

                                                        <!-- check -->
                                                    <td align="center" class="align-middle" v-if="isclosed==1">
                                                         <span v-if="w.ticketvuelta!=null">
                                                            {{plustype(w.ticketvuelta.penalty_quotation)}}
                                                        </span>
                                                        <span v-else> - </span>
                                                    </td>
                                                    <td align="center" class="align-middle" v-else-if="isclosed==0 && w.ticketvuelta!=null">
                                                        <input type="checkbox" v-model='w.ticketvuelta.penalty_quotation' v-if="w.ticketvuelta.invoice_id==null && w.ticketvuelta.qclone==1"  class="form-control">
                                                        <input type="checkbox" v-model='w.ticketvuelta.penalty_quotation' v-else-if="w.ticketvuelta.ticket_invoice_id==null && w.ticketvuelta.qclone==0"  class="form-control">    
                                                        <span v-else-if="(w.ticketvuelta.invoice_id!=null && w.ticketvuelta.qclone==1) || (w.ticketvuelta.ticket_invoice_id!=null && w.ticketvuelta.qclone==0)">
                                                            {{plustype(w.ticketvuelta.penalty_quotation)}}
                                                        </span>
                                                    </td>
                                                    <td align="center" class="align-middle" v-else-if="isclosed==0 && w.ticketvuelta==null">
                                                       -
                                                    </td>
                                                    <td align="center" class="align-middle" v-else> - </td>
                                                    <!--fin check-->
                                                    <!-- extra penalty-->


                                                     <!-- inicio extra price -->
                                                     <td  align="center" class="align-middle" v-if="isclosed==1">
                                                         <span v-if="w.ticketvuelta!=null">
                                                            <span v-if="w.ticketvuelta.extra_price!='' && w.ticketvuelta.extra_price!=null">{{w.ticketvuelta.extra_price}}</span>
                                                            <span v-else> 0 </span>
                                                        </span>
                                                         <span v-else> 0 </span>
                                                     </td>
                                                    <td align="center" class="align-middle" v-else-if="isclosed==0 && w.ticketvuelta!=null">
                                                        <input type="text" v-model='w.ticketvuelta.extra_price' v-if="w.ticketvuelta.ticket_invoice_id==null"  class="form-control">
                                                        <span v-if="w.ticketvuelta.ticket_invoice_id!=null">
                                                            <span v-if="w.ticketvuelta.extra_price!='' && w.ticketvuelta.extra_price!=null">{{w.ticketvuelta.extra_price}}</span>
                                                            <span v-else> 0 </span>
                                                        </span>
                                                    </td>
                                                    <td  align="center" class="align-middle" v-else-if="isclosed==0 && w.ticketvuelta==null">
                                                        <input type="text" class="form-control" disabled>
                                                    </td>
                                                    <td  align="center" class="align-middle" v-else> 0 </td>
                                                    <!-- extra price-->


                                               
                                                    <!-- inicio observacion -->
                                                     <td  align="center" class="align-middle" v-if="isclosed==1">
                                                         <span v-if="w.ticketvuelta!=null">
                                                            <span v-if="w.ticketvuelta.observation!='' && w.ticketvuelta.observation!=null">{{w.ticketvuelta.observation}}</span>
                                                            <span v-else> - </span>
                                                        </span>
                                                         <span v-else> - </span>
                                                     </td>
                                                    <td align="center" class="align-middle" v-else-if="isclosed==0 && w.ticketvuelta!=null">
                                                        <input type="text" v-model='w.ticketvuelta.observation' v-if="w.ticketvuelta.ticket_invoice_id==null"  class="form-control">
                                                        <span v-if="w.ticketvuelta.ticket_invoice_id!=null">
                                                            <span v-if="w.ticketvuelta.observation!='' && w.ticketvuelta.observation!=null">{{w.ticketvuelta.observation}}</span>
                                                            <span v-else> - </span>
                                                        </span>
                                                    </td>
                                                    <td  align="center" class="align-middle" v-else-if="isclosed==0 && w.ticketvuelta==null">
                                                        <input type="text" class="form-control" disabled>
                                                    </td>
                                                    <td  align="center" class="align-middle" v-else> - </td>
                                                    <!-- observacion-->



                                                   <!-- <td class="align-middle"><input type="number" min='0' v-model='w.ticketvuelta.carry_price'  :disabled="w.ticketvuelta==null" class="form-control"></td>
                                                    <td class="align-middle"><input type="checkbox" v-model='w.ticketvuelta.quotation'  :disabled="w.ticketvuelta==null" /></td>
                                                    <td class="align-middle"><input type="number" min='0' v-model='w.ticketvuelta.penalty'  :disabled="w.ticketvuelta==null"  class="form-control"></td>
                                                    <td class="align-middle"><input type="number" min='0' v-model='w.ticketvuelta.extra_price'  :disabled="w.ticketvuelta==null"  class="form-control"></td>
                                                    <td class="align-middle"><input type="text"  v-model='w.ticketvuelta.observation'  :disabled="w.ticketvuelta==null"  class="form-control"></td>-->
                                                    <!-- fin pasajes -->
                                                   <!-- <td class="align-middle">
                                                        <input type="text" class="form-control" v-if="canModify"
                                                               v-model="observations[w.id]">
                                                        <span v-else>
                                                            {{ observations[w.id] ? observations[w.id]: '-' }}
                                                        </span>
                                                    </td>-->
                                                    <td  align="center" class="align-middle" v-if="w.worker.deleted_at==null && w.with_emergency==0">
                                                       
                                                  
                                                          <a href="#" @click="sendSms(w.ticketida,index,1,w.worker_id)" v-if="w.sms_go==0"
                                                                     v-tooltip="'Enviar SMS Ida'">
                                                            
                                                            <v-icon large color="gray">
                                                                fas fa-sms
                                                            </v-icon>
                                                        </a>
                                                         <!-- <i class="fas fa-sms green" style="font-size:40px;" v-else v-tooltip="'SMS Ida Enviado'"></i>-->
                                                          <v-icon large color="green"  v-else v-tooltip="'SMS Ida Enviado'">
                                                                fas fa-sms
                                                            </v-icon>
                                                    </td>
                                                    <td v-else></td>

                                                    <td  align="center" class="align-middle"  v-if="w.worker.deleted_at==null &&  w.with_emergency==0">
                                                       
                                                                                        
                                                          <a href="#" @click="sendSms(w.ticketvuelta,index,2,w.worker_id)" v-if="w.sms_back==0 && (w.relocation_id_vuelta!=0 || w.lodging_id_vuelta!=0 || w.ticketvuelta!=null)"
                                                                     v-tooltip="'Enviar SMS Vuelta'">
                                                           <!-- <i class="fas fa-sms gray" style="font-size:40px;"></i>-->
                                                             <v-icon large color="gray">
                                                                fas fa-sms
                                                            </v-icon>
                                                        </a>
                                                      
                                                                 
                                                           <!-- <i class="fas fa-sms green" style="font-size:40px;" v-else-if="w.sms_back==1" v-tooltip="'SMS Vuelta Enviado'"></i>-->
                                                             <v-icon large color="green"  v-else v-tooltip="'SMS Vuelta Enviado'" >
                                                                fas fa-sms
                                                            </v-icon>
                                                       
                                                    </td>
                                                    <td v-else></td>
                                                  
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <!--confirmar -->
                                <div class="col form-group">
                                <button type="button" class="btn btn-success float-right" @click="saveQuotation" v-if="isclosed==0"
                                        >
                                    Guardar
                                </button>
                                </div>
                            </div>
                        </div>

                        <!-- modal de añadir/modificar vuelo -->
                        <div class="modal fade" id="flight-modal" tabindex="-1" role="dialog"
                             aria-labelledby="form-dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                            <ticket-modal :ticket-id="selectedTicket" v-if="showFlightModal" />
                        </div>

                         <!-- modal de añadir/modificar traslado -->
                        <div class="modal fade" id="transfer-modal" tabindex="-1" role="dialog"
                             aria-labelledby="form-dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                            <transfer-modal :transfer-id="selectedTransfer" v-if="showTransferModal"/>
                        </div>

                        <!-- modal de añadir/modificar alojamiento -->
                        <div class="modal fade" id="accommodation-modal" tabindex="-1" role="dialog"
                             aria-labelledby="form-dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                            <accommodation-modal :accommodation-id="selectedAccommodation" v-if="showAccommodationModal"/>
                        </div>

                        <!-- modal de añadir/modificar colacion -->
                        <div class="modal fade" id="snack-modal" tabindex="-1" role="dialog"
                             aria-labelledby="form-dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                            <snack-modal :snack-id="selectedSnack" v-if="showSnackModal"/>
                        </div>

                        <!-- modal para asociar trabajadores a un pasaje -->
                        <div class="modal fade" id="flight-worker-modal" tabindex="-1" role="dialog"
                             aria-labelledby="form-dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                            <ticket-worker-modal :ticket-id="selectedTicket" :only-one="selectedTicketOnlyOne" :crew-id="id"
                                                 v-if="showFlightWorkerModal"/>
                        </div>

                         <!-- modal para asociar trabajadores a un traslado -->
                        <div class="modal fade" id="transfer-worker-modal" tabindex="-1" role="dialog"
                             aria-labelledby="form-dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                            <transfer-worker-modal :transfer-id="selectedTransfer" :crew-id="id"
                                                 v-if="showTransferWorkerModal"/>
                        </div>

                         <!-- modal para asociar trabajadores a un alojamiento -->
                        <div class="modal fade" id="accommodation-worker-modal" tabindex="-1" role="dialog"
                             aria-labelledby="form-dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                            <accommodation-worker-modal :accommodation-id="selectedAccommodation" :crew-id="id"
                                                 v-if="showAccommodationWorkerModal"/>
                        </div>

                         <!-- modal para asociar trabajadores a un colacion -->
                        <div class="modal fade" id="snack-worker-modal" tabindex="-1" role="dialog"
                             aria-labelledby="form-dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                            <snack-worker-modal :snack-id="selectedSnack" :crew-id="id"
                                                 v-if="showSnackWorkerModal"/>
                        </div>


                        <!-- modal para configurar alojamiento y alimentación -->
                        <div class="modal fade" id="lodging-modal" tabindex="-1" role="dialog"
                             aria-labelledby="form-dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                            <lodging v-if="showLodgingModal"/>
                        </div>

                        <!-- modal para configurar traslado -->
                        <div class="modal fade" id="relocation-modal" tabindex="-1" role="dialog"
                             aria-labelledby="form-dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                            <relocation v-if="showRelocateModal"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Loading from "../Common/Loading";
    import Lodging from "./Quotation/Lodging";
    import Relocation from "./Quotation/Relocation";
    import TicketModal from "./Quotation/TicketModal";
    import TransferModal from "./Quotation/TransferModal";
    import SnackModal from "./Quotation/SnackModal";
    import AccommodationModal from "./Quotation/AccommodationModal";
    import TicketWorkerModal from "./Quotation/TicketWorkerModal";
    import TransferWorkerModal from "./Quotation/TransferWorkerModal";
    import AccommodationWorkerModal from "./Quotation/AccommodationWorkerModal";
    import SnackWorkerModal from "./Quotation/SnackWorkerModal";
    import eventHub from "../../eventHub";

    export default {
        components: { Loading, Lodging, Relocation, TicketModal, TicketWorkerModal,TransferModal,AccommodationModal,SnackModal,TransferWorkerModal,AccommodationWorkerModal,SnackWorkerModal },
        computed: {
            id () {
                return this.$route.params.id;
            },
            canConfirm () {
                let can = true;

                this.workers.forEach(w => {
                    if (can) {
                        // verifica si requiere alojamiento y si lo posee
                        if (w.lodging && !w.lodging_data) can = false;

                        // verifica si requiere traslado y si lo posee
                        if (w.relocation && !w.relocation_data) can = false;

                        // verifica si tiene pasaje de ida
                        if (this.goingTickets.findIndex(t => t.crew_worker_id === w.id) === -1) can = false;

                        // verifica si tiene pasaje de vuelta
                        if (this.returnTickets.findIndex(t => t.crew_worker_id === w.id) === -1) can = false;
                    }
                });

                return can;
            },
            canModify () {
                return this.quotation.entered === 0;
            },

            isclosed () {
                return this.quotation.closed;
            },
            
            goingTickets () {
                let tickets = [];

                this.quotation.tickets.filter(t => t.flight_stretch.name === 'Ida').forEach(t => {
                    if (t.tickets.length > 0) t.tickets.forEach(t => tickets.push(t));
                });

                return tickets;
            },

            returnTickets () {
                const tickets = [];

                this.quotation.tickets.filter(t => t.flight_stretch.name === 'Vuelta').forEach(t => {
                    if (t.tickets.length > 0) t.tickets.forEach(t => tickets.push(t));
                });

                return tickets;
            },
            workers () {
               // return this.quotation.crew.workers.filter(w => w.is_replacement === 0);
                return this.quotation.crew.workers;
            }

        },
        mounted(){
       
        },
        created () {
            this.$Progress.start();
            this.getQuotation();
           
            this.$Progress.finish();

            // registra evento de cerrar modal
            this.$nextTick(() => $('#flight-modal').on('hidden.bs.modal', () => {
                this.selectedTicket = null;
                this.showFlightModal = false;
                eventHub.$emit('cleanFlightForm');
            }));
            this.$nextTick(() => $('#transfer-modal').on('hidden.bs.modal', () => {
                this.selectedTransfer = null;
                this.showTransferModal = false;
                eventHub.$emit('cleanTransferForm');
            }));
            this.$nextTick(() => $('#accommodation-modal').on('hidden.bs.modal', () => {
                this.selectedAccommodation = null;
                this.showAccommodationModal = false;
                eventHub.$emit('cleanAccommodationForm');
            }));
             this.$nextTick(() => $('#snack-modal').on('hidden.bs.modal', () => {
                this.selectedSnack = null;
                this.showSnackModal = false;
                eventHub.$emit('cleanAccommodationForm');
            }));
            this.$nextTick(() => $('#flight-worker-modal').on('hidden.bs.modal', () => {
                this.selectedTicket = null;
                this.showFlightWorkerModal = false;
            }));
            this.$nextTick(() => $('#transfer-worker-modal').on('hidden.bs.modal', () => {
                this.selectedTransfer = null;
                this.showTransferWorkerModal = false;
            }));
            this.$nextTick(() => $('#accommodation-worker-modal').on('hidden.bs.modal', () => {
                this.selectedAccommodation = null;
                this.showAccommodationWorkerModal = false;
            }));
            this.$nextTick(() => $('#snack-worker-modal').on('hidden.bs.modal', () => {
                this.selectedSnack = null;
                this.showSnackWorkerModal = false;
            }));
            this.$nextTick(() => $('#lodging-modal').on('hidden.bs.modal', () => {
                this.lodgingCrewWorkerId = null;
                this.showLodgingModal = false;
            }));
            this.$nextTick(() => $('#relocation-modal').on('hidden.bs.modal', () => {
                this.relocateCrewWorkerId = null;
                this.showRelocateModal = false;
            }));

            // registra eventos de aplicación
            eventHub.$on('reloadQuotationTicket', () => {
                $('#flight-modal').modal('hide');
                this.$nextTick(() => this.getQuotation());
            });

             // registra eventos de aplicación
            eventHub.$on('reloadTransfers', () => {
                $('#transfer-modal').modal('hide');
                this.$nextTick(() => this.getQuotation());
            });

            // registra eventos de aplicación
            eventHub.$on('reloadAccommodations', () => {
                $('#accommodation-modal').modal('hide');
                this.$nextTick(() => this.getQuotation());
            });

             // registra eventos de aplicación
            eventHub.$on('reloadSnacks', () => {
                $('#snack-modal').modal('hide');
                this.$nextTick(() => this.getQuotation());
            });

            eventHub.$on('reloadTransferWorkers', () => {
                $('#transfer-worker-modal').modal('hide');
                this.$nextTick(() => this.getQuotation());
            });

             eventHub.$on('reloadAccommodationWorkers', () => {
                $('#accommodation-worker-modal').modal('hide');
                this.$nextTick(() => this.getQuotation());
            });

             eventHub.$on('reloadSnackWorkers', () => {
                $('#snack-worker-modal').modal('hide');
                this.$nextTick(() => this.getQuotation());
            });

            eventHub.$on('reloadQuotationWorkers', () => {
                $('#flight-worker-modal').modal('hide');
                this.$nextTick(() => this.getQuotation());
            });

            eventHub.$on('lodgingSaved', () => {
                $('#lodging-modal').modal('hide');
                this.$nextTick(() => this.getQuotation());
            });

            eventHub.$on('relocateSaved', () => {
                $('#relocation-modal').modal('hide');
                this.$nextTick(() => this.getQuotation());
            });

            this.loadCalc();

        },
        data () {
            return {
                loading: true,
                lodgingCrewWorkerId: null,
                lodgingProviders: [],
                observations: null,
                carry1: null,
                carry2: null,
                quotate1: null,
                quotate2: null,
                penalty1: null,
                penalty2: null,
                extra1: null,
                extra2: null,
                obs1: null,
                obs2: null,
                quotation: {
                    entered: null,
                    entered_at: null,
                    crew: { workers: [] },
                    tickets: [],
                    transfers:[],
                    accommodations:[],
                    snacks:[]
                },
                relocateCrewWorkerId: null,
                relocateProviders: [],
                selectedTicket: null,
                selectedTransfer: null,
                selectedAccommodation: null,
                selectedSnack: null,
                selectedTicketOnlyOne: false,
                showFlightModal: false,
                showTransferModal: false,
                showSnackModal: false,
                showAccommodationModal: false,
                showFlightWorkerModal: false,
                showTransferWorkerModal: false,
                showAccommodationWorkerModal: false,
                showSnackWorkerModal: false,
                showLodgingModal: false,
                showRelocateModal: false,
                tooltip: {
                    lodging: 'Alojamiento asignado',
                    notLodging: 'No se ha asignado el alojamiento solicitado',
                    relocation: 'Traslado asignado',
                    notRelocation: 'No se ha asignado el traslado solicitado',
                }
            }
        },
        destroyed() {
            eventHub.$off(['reloadQuotationTicket', 'reloadQuotationWorkers', 'lodgingSaved']);
        },
        methods: {

            sendSms (ticket,index,tipo,worker){
                 Swal.fire({
                        title: '¿Estás seguro que quieres enviar SMS?',
                        text: 'Solo puede enviar el sms 1 vez por tripulante',
                        showCancelButton: true,
                        confirmButtonColor: '#d33',
                        cancelButtonColor: '#3085d6',
                        confirmButtonText: 'Si',
                        cancelButtonText: 'No'
                    }).then((result) => {
                        if (result.value) {
                            axios.post(`/api/administracion/send-sms`,{
                               worker:worker,crew_id: this.id,type:tipo
                            })
                                .then(() => {         
                                  this.$launchSuccess('Sms enviado exitosamente');  
                                  if(tipo==1){
                                    this.workers[index].sms_go = 1;
                                  }
                                  if(tipo==2){
                                      this.workers[index].sms_back = 1;
                                  }
                                })
                                .catch(() => Swal.fire(
                                    'Error',
                                    'Error al enviar sms',
                                    'warning'
                                ));
                        }
                    });
            },
            journey(plus){
                if(plus==1){
                    return "Ida";
                }
                return "Vuelta";
            },
               plustype(plus){
                if(plus==1){
                    return "Si";
                }
                return "No";
            },
            paytype(plus){
                if(plus==1){
                    return "Stock";
                }
                if(plus==2){
                    return "Voucher";
                }
                return " - ";
            },
             quotationtype(plus){
                if(plus==1){
                    return "Si";
                }
                return "No";
            },
            associateWorkers (id, onlyOne = false) {
                if (!this.canConfirm && this.canModify) {
                    this.saveTemp();
                    this.selectedTicket = id;
                    this.selectedTicketOnlyOne = onlyOne;
                    this.showFlightWorkerModal = true;
                    this.$nextTick(() => $('#flight-worker-modal').modal('show'));
                }
            },


             associateTransferWorkers (id) {
               this.saveTemp();
                    this.selectedTransfer = id;
                    this.showTransferWorkerModal = true;
                    this.$nextTick(() => $('#transfer-worker-modal').modal('show'));
                
            },

            associateAccommodationWorkers (id) {
               this.saveTemp();
                    this.selectedAccommodation = id;
                    this.showAccommodationWorkerModal = true;
                    this.$nextTick(() => $('#accommodation-worker-modal').modal('show'));
                
            },

            associateSnackWorkers (id) {
               this.saveTemp();
                    this.selectedSnack = id;
                    this.showSnackWorkerModal = true;
                    this.$nextTick(() => $('#snack-worker-modal').modal('show'));
                
            },


            deleteTicket (id) {
                if (this.canModify) {
                    this.saveTemp();
                    Swal.fire({
                        title: '¿Estás seguro que deseas eliminar este pasaje?',
                        text: 'No podrás revertir esta acción',
                        showCancelButton: true,
                        confirmButtonColor: '#d33',
                        cancelButtonColor: '#3085d6',
                        confirmButtonText: 'Si',
                        cancelButtonText: 'No'
                    }).then((result) => {
                        if (result.value) {
                            axios.delete(`/api/logistica/cotizaciones/${this.id}/pasajes/${id}`)
                                .then(() => {
                                    this.getQuotation();
                                    Swal.fire(
                                        'Éxito',
                                        'El pasaje fue eliminado correctamente',
                                        'success'
                                    );
                                })
                                .catch(() => Swal.fire(
                                    'Error',
                                    'Error al eliminar pasaje',
                                    'warning'
                                ));
                        }
                    });
                }
            },
             deleteWorkerTicket (worker,ticket) {
                if (this.canModify) {
                    this.saveTemp();
                    Swal.fire({
                        title: '¿Estás seguro que deseas eliminar este pasaje?',
                        text: 'No podrás revertir esta acción',
                        showCancelButton: true,
                        confirmButtonColor: '#d33',
                        cancelButtonColor: '#3085d6',
                        confirmButtonText: 'Si',
                        cancelButtonText: 'No'
                    }).then((result) => {
                        if (result.value) {

                            let j = this.quotation.crew.workers.find(s => s.worker_id === worker);
                            let t = this.quotation.tickets.find(s => s.id === ticket);
                        
                            t.passengers = parseInt(t.passengers) -1;
                            if(t.flight_stretch_id==1){
                            j.ticketida = null;
                            }
                            if(t.flight_stretch_id==2){
                            j.ticketvuelta = null;
                            }
                            this.loadCalc();                  
                            console.log(this.quotation.tickets);
                            axios.post(`/api/administracion/pasajes/quitar-pasaje`,{
                                worker_id: worker,ticket_id:ticket, tickets : this.quotation.tickets
                            })
                                .then(() => {
                                    
                                    this.getQuotation();
                                   // this.loadCalc();
                                   // console.log(this.quotation.tickets);
                                   // this.saveTemp();
                                    Swal.fire(
                                        'Éxito',
                                        'El pasaje fue eliminado correctamente',
                                        'success'
                                    );
                                })
                                .catch(() => Swal.fire(
                                    'Error',
                                    'Error al eliminar pasaje',
                                    'warning'
                                ));
                        }
                    });
                }
            },

             deleteTransferWorker (worker,transfer) {
                if (this.canModify) {
                    this.saveTemp();
                    Swal.fire({
                        title: '¿Estás seguro que deseas eliminar este traslado?',
                        text: 'No podrás revertir esta acción',
                        showCancelButton: true,
                        confirmButtonColor: '#d33',
                        cancelButtonColor: '#3085d6',
                        confirmButtonText: 'Si',
                        cancelButtonText: 'No'
                    }).then((result) => {
                        if (result.value) {
                            axios.post(`/api/administracion/traslados/quitar-traslado`,{
                                worker_id: worker,transfer_id:transfer
                            })
                                .then(() => {         
                                    this.getQuotation();
                                    Swal.fire(
                                        'Éxito',
                                        'El traslado fue eliminado correctamente',
                                        'success'
                                    );
                                })
                                .catch(() => Swal.fire(
                                    'Error',
                                    'Error al eliminar traslado',
                                    'warning'
                                ));
                        }
                    });
                }
            },
             deleteSnackWorker (worker,snack) {
                if (this.canModify) {
                    this.saveTemp();
                    Swal.fire({
                        title: '¿Estás seguro que deseas eliminar esta colación?',
                        text: 'No podrás revertir esta acción',
                        showCancelButton: true,
                        confirmButtonColor: '#d33',
                        cancelButtonColor: '#3085d6',
                        confirmButtonText: 'Si',
                        cancelButtonText: 'No'
                    }).then((result) => {
                        if (result.value) {
                            axios.post(`/api/administracion/colaciones/quitar-colacion`,{
                                worker_id: worker,snack_id:snack
                            })
                                .then(() => {         
                                    this.getQuotation();
                                    Swal.fire(
                                        'Éxito',
                                        'La colación fue eliminada correctamente',
                                        'success'
                                    );
                                })
                                .catch(() => Swal.fire(
                                    'Error',
                                    'Error al eliminar colación',
                                    'warning'
                                ));
                        }
                    });
                }
            },
            deleteAccommodationWorker (worker,accommodation) {
                if (this.canModify) {
                    this.saveTemp();
                    Swal.fire({
                        title: '¿Estás seguro que deseas eliminar este alojamiento?',
                        text: 'No podrás revertir esta acción',
                        showCancelButton: true,
                        confirmButtonColor: '#d33',
                        cancelButtonColor: '#3085d6',
                        confirmButtonText: 'Si',
                        cancelButtonText: 'No'
                    }).then((result) => {
                        if (result.value) {
                            axios.post(`/api/administracion/alojamientos/quitar-alojamiento`,{
                                worker_id: worker,accommodation_id:accommodation
                            })
                                .then(() => {         
                                    this.getQuotation();
                                    Swal.fire(
                                        'Éxito',
                                        'El alojamiento fue eliminado correctamente',
                                        'success'
                                    );
                                })
                                .catch(() => Swal.fire(
                                    'Error',
                                    'Error al eliminar alojamiento',
                                    'warning'
                                ));
                        }
                    });
                }
            },

             deleteTransfer (id) {
                if (this.canModify) {
                    this.saveTemp();
                    Swal.fire({
                        title: '¿Estás seguro que deseas eliminar este traslado?',
                        text: 'No podrás revertir esta acción',
                        showCancelButton: true,
                        confirmButtonColor: '#d33',
                        cancelButtonColor: '#3085d6',
                        confirmButtonText: 'Si',
                        cancelButtonText: 'No'
                    }).then((result) => {
                        if (result.value) {
                            axios.delete(`/api/administracion/cotizaciones/traslado/${id}`)
                                .then(() => {
                                    this.getQuotation();
                                    Swal.fire(
                                        'Éxito',
                                        'El traslado fue eliminado correctamente',
                                        'success'
                                    );
                                })
                                .catch(() => Swal.fire(
                                    'Error',
                                    'Error al eliminar traslado',
                                    'warning'
                                ));
                        }
                    });
                }
            },
             deleteAccommodation (id) {
                if (this.canModify) {
                    this.saveTemp();
                    Swal.fire({
                        title: '¿Estás seguro que deseas eliminar este alojamiento?',
                        text: 'No podrás revertir esta acción',
                        showCancelButton: true,
                        confirmButtonColor: '#d33',
                        cancelButtonColor: '#3085d6',
                        confirmButtonText: 'Si',
                        cancelButtonText: 'No'
                    }).then((result) => {
                        if (result.value) {
                            axios.delete(`/api/administracion/cotizaciones/alojamiento/${id}`)
                                .then(() => {
                                    this.getQuotation();
                                    Swal.fire(
                                        'Éxito',
                                        'El alojamiento fue eliminado correctamente',
                                        'success'
                                    );
                                })
                                .catch(() => Swal.fire(
                                    'Error',
                                    'Error al eliminar alojamiento',
                                    'warning'
                                ));
                        }
                    });
                }
            },

               deleteSnack (id) {
                if (this.canModify) {
                    this.saveTemp();
                    Swal.fire({
                        title: '¿Estás seguro que deseas eliminar esta Colación?',
                        text: 'No podrás revertir esta acción',
                        showCancelButton: true,
                        confirmButtonColor: '#d33',
                        cancelButtonColor: '#3085d6',
                        confirmButtonText: 'Si',
                        cancelButtonText: 'No'
                    }).then((result) => {
                        if (result.value) {
                            axios.delete(`/api/administracion/cotizaciones/colacion/${id}`)
                                .then(() => {
                                    this.getQuotation();
                                    Swal.fire(
                                        'Éxito',
                                        'La Colación fue eliminado correctamente',
                                        'success'
                                    );
                                })
                                .catch(() => Swal.fire(
                                    'Error',
                                    'Error al eliminar Colación',
                                    'warning'
                                ));
                        }
                    });
                }
            },


     

            getQuotation () {
                this.loading = true;
                this.$Progress.start();

                axios.get(`/api/logistica/cotizaciones/${this.id}`)
                    .then(({ data }) => {
                        this.quotation = data.data;
                        this.$Progress.finish();
                        this.loadCalc();

                        // genera el "formulario" de observaciones solo si no tiene datos de antemano
                        
                    })
                    .catch(() => {
                        this.$launchError('Error al obtener información de cotización');
                        this.$Progress.fail();
                    })
                    .finally(() => this.loading = false);
            },

            getLodgingProviders () {
                if (this.lodgingProviders.length === 0) {
                    axios.get('/api/bodega/proveedores/alojamiento')
                        .then(({ data }) => this.lodgingProviders = data.data)
                        .catch(() => this.$launchError('Error al obtener listado de proveedores de alojamiento'));
                }
            },

            getRelocateProviders () {
                if (this.relocateProviders.length === 0) {
                    axios.get('/api/bodega/proveedores/traslado')
                        .then(({ data }) => this.relocateProviders = data.data)
                        .catch(() => this.$launchError('Error al obtener listado de proveedores de traslado'));
                }
            },

            getTicketNumber (crewWorkerId, stretch) {
                let index = null;

                this.quotation.tickets.forEach((t, i) => {
                    if (index === null && t.flight_stretch.name === stretch) {
                        t.tickets.forEach(w => {
                            if (w.crew_worker_id === crewWorkerId) index = i;
                        });
                    }
                });

                return index + 1;
            },

            hasTicket (crewWorkerId) {
                let has = false;

                this.quotation.tickets.forEach(t => {
                    if (!has) {
                        if (t.tickets.findIndex(_t => _t.crew_worker_id === crewWorkerId) !== -1) has = true;
                    }
                })

                return has;
            },

            saveQuotation () {           
                    Swal.fire({
                        title: '¿Estás seguro que deseas guardar los datos de los tripulantes?',
                        text: 'Puedes modificarlos mas tarde',
                        showCancelButton: true,
                        confirmButtonColor: '#d33',
                        cancelButtonColor: '#3085d6',
                        confirmButtonText: 'Si',
                        cancelButtonText: 'No'
                    }).then((result) => {
                        if (result.value) {
                           
                            console.log(this.quotation.crew.workers);
                            axios.post(`/api/administracion/pasajes/actualiza-tripulantes`, {
                                workers: this.workers, tickets : this.quotation.tickets
                            })
                                .then(() => {
                                    this.getQuotation();
                                    Swal.fire(
                                        'Éxito',
                                        'La cotización fue guardada correctamente',
                                        'success'
                                    );
                                })
                                .catch(() => Swal.fire(
                                    'Error',
                                    'Error al guardar cotización',
                                    'warning'
                                ));
                        }
                    });
                
            },
            saveTemp(){
                            axios.post(`/api/administracion/pasajes/actualiza-tripulantes`, {
                                workers: this.workers,
                                tickets: this.quotation.tickets
                            });


                           this.loadCalc();

                            
            },


            setLodging (crewWorkerId) {
                this.saveTemp();
                this.getLodgingProviders();
                this.lodgingCrewWorkerId = crewWorkerId;
                this.showLodgingModal = true;
                this.$nextTick(() => $('#lodging-modal').modal('show'));
            },

            setRelocation (crewWorkerId) {
                this.saveTemp();
                this.getRelocateProviders();
                this.relocateCrewWorkerId = crewWorkerId;
                this.showRelocateModal = true;
                this.$nextTick(() => $('#relocation-modal').modal('show'));
            },

            setTicket (id = null) {
                if (this.canModify) {
                    this.saveTemp();
                    this.selectedTicket = id;
                    this.showFlightModal = true;
                    this.$nextTick(() => $('#flight-modal').modal('show'));
                }
            },

              setTransfer (id = null) {
                //  console.log(id);
                if (this.canModify) {
                    this.saveTemp();
                    this.selectedTransfer = id;
                    this.showTransferModal = true;
                    this.$nextTick(() => $('#transfer-modal').modal('show'));
                }
            },

             setSnack (id = null) {
                //  console.log(id);
                if (this.canModify) {
                    this.saveTemp();
                    this.selectedSnack= id;
                    this.showSnackModal = true;
                    this.$nextTick(() => $('#snack-modal').modal('show'));
                }
            },

            setAccomodation(id = null) {
                if (this.canModify) {
                    this.saveTemp();
                    this.selectedAccommodation = id;
                    this.showAccommodationModal = true;
                    this.$nextTick(() => $('#accommodation-modal').modal('show'));
                }
            },

            loadCalc(){
                
                 this.quotation.tickets.forEach((t, i) => {
                     //console.log(t);
                     if(t.flight_stretch_id==1){
                        this.newcalc(t.id,'ida',0);   
                     }
                     if(t.flight_stretch_id==2){
                        this.newcalc(t.id,'vuelta',0);
                     }

                       
                           
                   });
            },

            newcalc(ticketid,tipo,editado){
                   let elticket = this.quotation.tickets.findIndex(t => t.id === ticketid);
                   let value = 0;
                   let suma = 0;
                   if(this.quotation.tickets[elticket].payment_type==null){
                         this.workers.forEach((t, i) => {
                            if(tipo=='ida' && t.ticketida!=null){
                                if(t.ticketida.ticket_group_id === ticketid){
                                    if(t.ticketida.penalty!='' && t.ticketida.penalty!=null){
                                        suma = parseInt(suma) + parseInt(t.ticketida.penalty);  
                                    }else{
                                        suma = parseInt(suma) + 0;  
                                    }        
                                    }
                            }
                            if(tipo=='vuelta' && t.ticketvuelta!=null){
                                if(t.ticketvuelta.ticket_group_id === ticketid){
                                    if(t.ticketvuelta.penalty!='' && t.ticketvuelta.penalty!=null){
                                        suma = parseInt(suma) + parseInt(t.ticketvuelta.penalty);  
                                    }else{
                                        suma = parseInt(suma) + 0;  
                                    }          
                                    }
                            }       
                        });
                        this.quotation.tickets[elticket].temporal_extra_price = parseInt(suma);
                        //(this.quotation.tickets[elticket].clone_extra_price +" // "+value+" // "+suma);
                        this.quotation.tickets[elticket].extra_price = parseInt(suma) + parseInt(this.quotation.tickets[elticket].dearness) + parseInt(this.quotation.tickets[elticket].penalty) + (parseInt( this.quotation.tickets[elticket].price)*parseInt( this.quotation.tickets[elticket].passengers));
                        if(parseInt(this.quotation.tickets[elticket].passengers) >0){
                            this.quotation.tickets[elticket].unitary_extra_price = parseInt(this.quotation.tickets[elticket].extra_price) / parseInt(this.quotation.tickets[elticket].passengers) ;
                        }else{
                            this.quotation.tickets[elticket].unitary_extra_price = 0 ;
                        }          
                        this.quotation.tickets[elticket].edited = editado;
                        this.quotation.tickets[elticket].total_price = this.quotation.tickets[elticket].extra_price; 
                   }

                   if(this.quotation.tickets[elticket].payment_type==1){
                         this.workers.forEach((t, i) => {                      
                            if(tipo=='ida' && t.ticketida!=null){
                                if(t.ticketida.ticket_group_id === ticketid){
                                    if(t.ticketida.penalty!='' && t.ticketida.penalty!=null){
                                        suma = parseInt(suma) + parseInt(t.ticketida.penalty);  
                                    }else{
                                        suma = parseInt(suma) + 0;  
                                    }        
                                    }
                            }
                            if(tipo=='vuelta' && t.ticketvuelta!=null){
                                if(t.ticketvuelta.ticket_group_id === ticketid){
                                    if(t.ticketvuelta.penalty!='' && t.ticketvuelta.penalty!=null){
                                        suma = parseInt(suma) + parseInt(t.ticketvuelta.penalty);  
                                    }else{
                                        suma = parseInt(suma) + 0;  
                                    }          
                                    }
                            }  
                        });
                        this.quotation.tickets[elticket].temporal_extra_price = parseInt(suma);
                        let sumatoria = (parseInt(suma) + parseInt(this.quotation.tickets[elticket].dearness) + parseInt(this.quotation.tickets[elticket].penalty) + (parseInt(this.quotation.tickets[elticket].price)*parseInt(this.quotation.tickets[elticket].passengers)))
                        if(parseInt(sumatoria) > parseInt(this.quotation.tickets[elticket].payment_use_price)){
                              this.quotation.tickets[elticket].extra_price = parseInt(sumatoria) - parseInt(this.quotation.tickets[elticket].payment_use_price) ;
                              if(parseInt(this.quotation.tickets[elticket].passengers)>0){
                                  this.quotation.tickets[elticket].unitary_extra_price = parseInt(this.quotation.tickets[elticket].extra_price);
                              }else{
                                this.quotation.tickets[elticket].unitary_extra_price = 0;
                              }                 
                        }else{
                              this.quotation.tickets[elticket].extra_price = 0;
                              this.quotation.tickets[elticket].unitary_extra_price =  0;    
                        }
                        this.quotation.tickets[elticket].total_price = parseInt(sumatoria);  
                        this.quotation.tickets[elticket].edited = editado;
                   }

                   if(this.quotation.tickets[elticket].payment_type==2){
                       this.workers.forEach((t, i) => {                      
                            if(tipo=='ida' && t.ticketida!=null){
                                if(t.ticketida.ticket_group_id === ticketid){
                                        if(t.ticketida.penalty!='' && t.ticketida.penalty!=null){
                                            suma = parseInt(suma) + parseInt(t.ticketida.penalty);  
                                        }else{
                                            suma = parseInt(suma) + 0;  
                                        }        
                                }
                            }
                            if(tipo=='vuelta' && t.ticketvuelta!=null){
                                if(t.ticketvuelta.ticket_group_id === ticketid){
                                        if(t.ticketvuelta.penalty!='' && t.ticketvuelta.penalty!=null){
                                            suma = parseInt(suma) + parseInt(t.ticketvuelta.penalty);  
                                        }else{
                                            suma = parseInt(suma) + 0;  
                                        }          
                                }
                            }  
                        });
                        this.quotation.tickets[elticket].temporal_extra_price = parseInt(suma);
                        let sumatoria = (parseInt(suma) + parseInt(this.quotation.tickets[elticket].dearness) + parseInt(this.quotation.tickets[elticket].penalty) + (parseInt(this.quotation.tickets[elticket].price) * parseInt( this.quotation.tickets[elticket].passengers)))
                        if(parseInt(sumatoria) > parseInt(this.quotation.tickets[elticket].payment_use_price)){
                              this.quotation.tickets[elticket].extra_price = parseInt(sumatoria) - parseInt(this.quotation.tickets[elticket].payment_use_price) ;
                              if(parseInt(this.quotation.tickets[elticket].passengers)>0){
                                  this.quotation.tickets[elticket].unitary_extra_price = parseInt(this.quotation.tickets[elticket].extra_price) / parseInt(this.quotation.tickets[elticket].passengers) ;
                              }else{
                                this.quotation.tickets[elticket].unitary_extra_price = 0;
                              }
                        }else{
                                this.quotation.tickets[elticket].extra_price = 0;
                                this.quotation.tickets[elticket].unitary_extra_price = 0;
                        }
                        this.quotation.tickets[elticket].total_price = parseInt(sumatoria);  
                        this.quotation.tickets[elticket].edited = editado;
                   }
                  
            },

               onlyTemporal(ticketid,tipo){
                   let elticket = this.quotation.tickets.findIndex(t => t.id === ticketid);
                   let value = 0;
                   let suma = 0;
                   this.workers.forEach((t, i) => {
                       if(tipo=='ida'){
                           if(t.ticketida.ticket_group_id === ticketid){
                               if(t.ticketida.penalty!='' && t.ticketida.penalty!=null){
                                   suma = parseInt(suma) + parseInt(t.ticketida.penalty);  
                               }else{
                                   suma = parseInt(suma) + 0;  
                               }        
                            }
                       }
                       if(tipo=='vuelta'){
                           if(t.ticketvuelta.ticket_group_id === ticketid){
                              if(t.ticketvuelta.penalty!='' && t.ticketvuelta.penalty!=null){
                                   suma = parseInt(suma) + parseInt(t.ticketvuelta.penalty);  
                               }else{
                                   suma = parseInt(suma) + 0;  
                               }          
                            }
                       }       
                   });
                   this.quotation.tickets[elticket].temporal_extra_price = parseInt(suma);
                  
            },

            totalTickets (crewWorkerId) {
                let c = 0;

                // verifica si tiene pasaje de ida
                if (this.goingTickets.findIndex(t => t.crew_worker_id === crewWorkerId) !== -1) c++;

                // verifica si tiene pasaje de vuelta
                if (this.returnTickets.findIndex(t => t.crew_worker_id === crewWorkerId) !== -1) c++;

                return c;
            }
        }
    }
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('section',{staticClass:"content"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[(_vm.loading)?_c('loading'):_vm._e(),_vm._v(" "),_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"row align-items-center"},[_c('h3',{staticClass:"col card-title"},[_vm._v("Sala de espera")]),_vm._v(" "),_c('div',{staticClass:"col"})])]),_vm._v(" "),_c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Búsqueda","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_vm._v(" "),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.datos,"search":_vm.search,"loading":_vm.loading,"items-per-page":30,"no-results-text":"No se han encontrado tripulantes","no-data-text":"No existen tripulantes","loading-text":"Cargando... Espere","footer-props":{
           'items-per-page-text':'Tripulantes por página',
           'items-per-page-options': [20, 50, 100, -1]
      }},scopedSlots:_vm._u([{key:"item.accion",fn:function(ref){
      var item = ref.item;
      var index = ref.index;
return [_c('router-link',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Ficha de Tripulante'),expression:"'Ficha de Tripulante'"}],attrs:{"to":'/admin/trabajadores/' + item.id}},[_c('v-icon',{attrs:{"small":"","color":"green"}},[_vm._v("\n                                               fa fa-info-circle\n                                                ")])],1)]}},{key:"item.motivo",fn:function(ref){
      var item = ref.item;
      var index = ref.index;
return [_c('worker-reasons',{attrs:{"status":item.motivo}})]}},{key:"item.estado",fn:function(ref){
      var item = ref.item;
      var index = ref.index;
return [_c('worker-status',{attrs:{"status":item.estado}})]}},{key:"footer.page-text",fn:function(items){return [_vm._v(" "+_vm._s(items.pageStart)+" - "+_vm._s(items.pageStop)+" de "+_vm._s(items.itemsLength)+" ")]}}])})],1)],1)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }
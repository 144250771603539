export default [
    {
        path: '/',
        redirect: '/reports'
    },
    { path: '/admin/usuarios', component: require('./components/Users.vue').default },
    { path: '/admin/usuarios/:id', component: require('./components/UserDetails.vue').default },
    { path: '/admin/usuarios/:id/editar', component: require('./components/EditUser.vue').default },
    { path: '/admin/embarcaciones', component: require('./components/Ships.vue').default },
    { path: '/admin/embarcaciones/:id', component: require('./components/ShipDetails.vue').default },
    { path: '/admin/embarcaciones/:id/editar', component: require('./components/EditShip.vue').default },
    { path: '/admin/embarcaciones/:id/revista', component: require('./components/NewReview.vue').default },
    { path: '/admin/embarcaciones/:id/carena', component: require('./components/NewCareen.vue').default },
    { path: '/admin/trabajadores', component: require('./components/Workers.vue').default },
    { path: '/admin/trabajadores/:id', component: require('./components/WorkerDetails.vue').default },
    { path: '/admin/trabajadores/:id/editar', component: require('./components/EditWorker.vue').default },
    { path: '/admin/tripulaciones/:id', component: require('./components/CrewDetails.vue').default },
    { path: '/admin/salud/trabajadores', component: require('./components/WorkersHealth.vue').default },

    // exclusivo jefe de flota
    { path: '/jefe-flota/tripulaciones', component: require('./components/FleetChief/Crews.vue').default },
    {
        path: '/jefe-flota/tripulaciones/:id/editar',
        component: require('./components/FleetChief/EditCrew.vue').default
    },
    {
        name: 'EmergencyButton',
        path: '/jefe-flota/tripulaciones/:id/boton-emergencia/:crewWorkerId',
        component: require('./components/FleetChief/EmergencyButton.vue').default
    },
    {
        name: 'EmergencyButtonAddWorker',
        path: '/jefe-flota/tripulaciones/:id/boton-emergencia',
        component: require('./components/FleetChief/EmergencyButtonAddWorker.vue').default
    },

    // exclusivo supervisor
    { path: '/supervisor/tripulaciones', component: require('./components/Supervisor/Crews.vue').default },
    {
        path: '/supervisor/tripulaciones/:id/filtro-tripulacion',
        component: require('./components/Supervisor/CrewFilter.vue').default
    },
    {
        path: '/supervisor/tripulaciones/:id/solicitud-abastecimiento',
        component: require('./components/Supervisor/SupplyRequest.vue').default
    },
    { path: '/supervisor/trabajadores/:id/calificar', component: require('./components/Supervisor/QualifyWorker.vue').default },

    // exclusivo jefe/gerente de operaciones
    { path: '/operaciones/compras', component: require('./components/Operations/Purchases.vue').default },
    { path: '/operaciones/compras-tripulacion', component: require('./components/Operations/PurchasesCrew.vue').default },
    { path: '/operaciones/compras/:id', component: require('./components/Operations/PurchaseDetails.vue').default },
    { path: '/operaciones/compras-tripulacion/:id', component: require('./components/Operations/PurchaseCrewDetails.vue').default },
    { path: '/operaciones/cotizaciones', component: require('./components/Operations/Quotations.vue').default },
    {
        path: '/operaciones/cotizaciones/:id',
        component: require('./components/Operations/QuotationDetails.vue').default
    },

    // exclusivo logística
    { path: '/logistica/tripulaciones', component: require('./components/Logistics/Crews.vue').default },
    { path: '/logistica/cotizacion/:id', component: require('./components/Logistics/CreateQuotation.vue').default },
    {
        path: '/logistica/cotizacion/:id/facturar',
        component: require('./components/Logistics/InvoiceQuotation.vue').default
    },
    {
        path: '/logistica/boton-emergencia/cotizacion/:id/facturar',
        component: require('./components/Logistics/InvoiceEmergencyQuotation.vue').default
    },
    { path: '/logistica/pasajes', component: require('./components/Logistics/Tickets.vue').default },
    {
        path: '/logistica/pasajes/:id',
        component: require('./components/Logistics/TicketDetails.vue').default
    },
    { path: '/logistica/boton-emergencia', component: require('./components/Logistics/EmergencyButtons.vue').default },
    {
        path: '/logistica/boton-emergencia/:id',
        component: require('./components/Logistics/EmergencyQuotation.vue').default
    },
    // documentos de empresa
    { path: '/documents', component: require('./components/CompanyDocuments/CompanyDocuments').default },
    // exclusivo prevencionista
    { path: '/prevencionista/epp', component: require('./components/RiskPreventionist/EPP').default },
    { path: '/prevencionista/inducciones', component: require('./components/RiskPreventionist/Inductions').default },
    {
        path: '/prevencionista/trabajadores/:id/nueva-induccion',
        component: require('./components/RiskPreventionist/NewInduction').default
    },
    {
        path: '/prevencionista/trabajadores/:id/salud',
        component: require('./components/RiskPreventionist/WorkerHealth').default
    },

    // exclusivo bodega
    { path: '/bodega/articulos', component: require('./components/Warehouse/Items').default },
    { path: '/bodega/articulos/:id/editar', component: require('./components/Warehouse/EditItem').default },
    { path: '/bodega/solicitudes-abastecimiento', component: require('./components/Warehouse/SupplyRequests').default },
    {
        path: '/bodega/solicitudes-abastecimiento/:id',
        component: require('./components/Warehouse/PurchaseSupplyRequest').default
    },
    { path: '/bodega/compras', component: require('./components/Warehouse/Purchases').default },
    { path: '/bodega/compras-tripulacion', component: require('./components/Warehouse/PurchasesCrew').default },
    { path: '/bodega/compras/nueva', component: require('./components/Warehouse/NewPurchase').default },
    { path: '/bodega/compras/:id/facturar', component: require('./components/Warehouse/InvoicePurchase').default },
    { path: '/bodega/compras-tripulacion/:id/facturar', component: require('./components/Warehouse/InvoiceCrewPurchase').default },
    {
        path: '/bodega/trabajadores/:id/entrega-epp',
        component: require('./components/Warehouse/EPPDelivery').default
    },

    // atributos pendientes
    { path: '/pending-attributes', component: require('./components/PendingAttributes').default },

    // exclusivo mantenimiento
    { path: '/mantenimiento/certificaciones', component: require('./components/Maintenance/Certificates').default },

    // trip calendar
    { path: '/calendario-trip', component: require('./components/TripCalendar').default },

    // sala de espera
    { path: '/sala-espera', component: require('./components/WaitingRoom').default },

    { path: '/reports', component: require('./components/Reports').default },

    //administracion
    { path: '/pasajes', component: require('./components/Tickets').default },
    { path: '/vouchers', component: require('./components/Vouchers').default },
    { path: '/detalle-voucher/:id', component: require('./components/VoucherDetails').default },
    { path: '/nuevo-pasaje', component: require('./components/NewTicket').default },
    { path: '/nuevo-stock-pasaje', component: require('./components/NewStockTicket').default },
    { path: '/stock-de-pasajes', component: require('./components/StockTickets').default },
    { path: '/pasajes-perdidos', component: require('./components/LostTickets').default },
    { path: '/editar-pasaje/:id', component: require('./components/EditTicket').default },
    { path: '/facturar-pasaje/:id', component: require('./components/Quotation/QuotationTicketExternal').default },
    { path: '/alojamientos', component: require('./components/Accommodations').default },
    { path: '/nuevo-alojamiento', component: require('./components/NewAccommodation').default },
    { path: '/editar-alojamiento/:id', component: require('./components/EditAccommodation').default },
    { path: '/traslados', component: require('./components/Tranfers').default },
    { path: '/nuevo-traslado', component: require('./components/NewTransfer').default },
    { path: '/editar-traslado/:id', component: require('./components/EditTransfer').default },
    { path: '/personas', component: require('./components/Persons').default },
    { path: '/administracion/ficha-personas/:id', component: require('./components/PersonDetails.vue').default },

    { path: '/facturacion/tripulaciones', component: require('./components/Quotation/Crews.vue').default },
    { path: '/facturacion/cotizacion/:id', component: require('./components/Quotation/Quotation.vue').default },
    { path: '/facturacion/pasajes', component: require('./components/Quotation/Tickets.vue').default },
    { path: '/facturacion/traslados', component: require('./components/Quotation/Tranfers.vue').default },
    { path: '/facturacion/alojamientos', component: require('./components/Quotation/Accomodations.vue').default },
    { path: '/facturas', component: require('./components/Quotation/Invoices.vue').default },
    { path: '/factura/:id', component: require('./components/Quotation/InvoiceDetails.vue').default },

    // not found
    { path: '*', component: require('./components/NotFound.vue').default }
]

<template>
    <form @submit.prevent="editItem">
        <item-form :form="form" :classifications="classifications" :measurements="measurementUnits"/>

        <button type="submit" class="btn btn-primary float-right">Guardar</button>
    </form>
</template>

<script>
    import ItemForm from "../Form";

    export default {
        components: { ItemForm },
        created () {
            this.getClassifications();
            this.getItem();
            this.getMeasurements();
        },
        data () {
            return {
                classifications: [],
                form: new Form({
                    name: null,
                    price: null,
                    critical_stock: 0,
                    response_time: 0,
                    classification_id: null,
                    measurement_unit_id: null
                }),
                measurementUnits: [],
                warehouseItem: {}
            }
        },
        methods: {
            editItem () {
                this.$Progress.start();

                this.form.post('/api/bodega/articulos/' + this.$parent.id)
                    .then(() => {
                        this.$Progress.finish();
                        this.$launchSuccess('Éxito al guardar artículo');
                    })
                    .catch(() => {
                        this.$Progress.fail();
                        this.$launchError('Error al guardar artículo');
                    });
            },

            getClassifications () {
                axios.get('/api/bodega/clasificaciones-articulos')
                    .then(({ data }) => this.classifications = data.data)
                    .catch(() => this.$launchError('Error al obtener listado de clasificaciones de artículos'));
            },

            getItem () {
                this.$parent.loading = true;

                axios.get('/api/bodega/articulos/' + this.$parent.id)
                    .then(({ data }) => this.warehouseItem = data.data)
                    .catch(() => this.$launchError('Error al obtener artículo'))
                    .finally(() => this.$parent.loading = false);
            },

            getMeasurements () {
                axios.get('/api/bodega/unidades-medida')
                    .then(({ data }) => this.measurementUnits = data.data)
                    .catch(() => this.$launchError('Error al obtener listado de unidades de medida'));
            }
        },
        watch: {
            warehouseItem () {
                this.form.fill(this.warehouseItem);
            }
        }
    }
</script>

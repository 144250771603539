<template>
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <!-- header -->
            <div class="modal-header">
                <h5 class="modal-title">Alojamiento de tripulante</h5>
            </div>

            <!-- body -->
            <div class="modal-body container">
                <!-- alerta -->
                <div class="alert alert-warning" role="alert" v-if="hasFood && canSubmit">
                    Considera que la solicitud de alojamiento incluye alimentación.
                </div>

                <div class="alert alert-warning" role="alert" v-if="!canSubmit">
                    La información que aquí aparece es de <strong>solo lectura</strong>.
                </div>

                <!-- formulario -->
                <form @submit="saveLodging">
                    <div class="row">
                        <div class="col form-group">
                            <label>Fecha ingreso</label>
                            <input v-model="form.arrival_date" type="date" class="form-control" :readonly="!canSubmit"
                                   :class="{ 'is-invalid': form.errors.has('arrival_date') }">
                            <has-error :form="form" field="arrival_date"/>
                        </div>

                        <div class="col form-group">
                            <label>Fecha salida</label>
                            <input v-model="form.departure_date" type="date" class="form-control" :readonly="!canSubmit"
                                   :class="{ 'is-invalid': form.errors.has('departure_date') }">
                            <has-error :form="form" field="departure_date"/>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col form-group">
                            <label>Tarifa</label>
                            <input v-model.number="form.price" type="number" min="0" class="form-control" :readonly="!canSubmit"
                                   :class="{ 'is-invalid': form.errors.has('price') }">
                            <has-error :form="form" field="price"/>
                        </div>

                        <div class="col form-group">
                            <label>Proveedor</label>
                            <select v-model="form.lodging_provider_id" class="form-control" :readonly="!canSubmit"
                                    :disabled="!canSubmit"
                                    :class="{ 'is-invalid': form.errors.has('lodging_provider_id') }">
                                <option :value="null" disabled>Escoja un proveedor</option>
                                <option :value="p.id" v-for="p in providers">{{ p.name }}</option>
                            </select>
                            <has-error :form="form" field="lodging_provider_id"/>
                        </div>
                    </div>
                </form>
            </div>

            <!-- footer -->
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                <button type="button" class="btn btn-primary" @click="saveLodging" v-if="canSubmit">
                    Guardar
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import Switches from 'vue-switches';
    import eventHub from "../../../eventHub";

    export default {
        components: { Switches },
        computed: {
            id () {
                return this.$parent.lodgingCrewWorkerId;
            },
            canSubmit () {
                return this.$parent.canModify;
            },
            hasFood () {
                return this.$parent.r.lodging_food;
            },
            providers () {
                return this.$parent.lodgingProviders;
            }
        },
        created () {
            const l = this.$parent.r.lodging_data;

            // si existe alojamiento, abre el modal en modo edición
            if (l) this.form.fill(l);
        },
        data () {
            return {
                form: new Form({
                    arrival_date: null,
                    departure_date: null,
                    food: false,
                    lodging_provider_id: null,
                    price: 0
                })
            }
        },
        methods: {
            saveLodging () {
                if (this.canSubmit) {
                    this.$Progress.start();

                    this.form.post(`/api/logistica/${this.id}/alojamiento`)
                        .then(() => {
                            eventHub.$emit('lodgingSaved');
                            this.$launchSuccess('Éxito al guardar alojamiento de tripulante');
                        })
                        .catch(() => this.$launchError('Error al guardar alojamiento de tripulante'))
                        .finally(() => this.$Progress.finish());
                }
            }
        }
    }
</script>

<template>
 <v-app>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <loading v-if="loading"/>

                        <!-- header -->
                        <div class="card-header">
                            <h3 class="card-title">Listado de tripulaciones</h3>
                        </div>

                         <v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Búsqueda"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
  <v-data-table
      :headers="headers"
      :items="datos"
      :search="search"
       :loading="loading"
       :items-per-page="30"
       no-results-text="No se han encontrado tripulaciones"
       no-data-text="No existen tripulaciones"
      class="elevation-1"
        loading-text="Cargando... Espere"
         :footer-props="{
           'items-per-page-text':'Tripulaciones por página',
           'items-per-page-options': [20, 50, 100, -1]
      }"
    >
 <template v-slot:item.accion="{ item, index }">

                                         <router-link :to="`/facturacion/cotizacion/${item.ghost_quotation.id}`"
                                                         v-tooltip="'Facturar cotización'">
                                                  <v-icon small style="color: #3490DC !important;">
                                                fa-file-alt
                                                </v-icon>
                                            </router-link>
                                                      
  </template>
  <template v-slot:footer.page-text="items"> {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }} </template>
</v-data-table>
</v-card> 


                        <!-- body -->
                        <!--<div class="card-body table-responsive p-0">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th class="align-middle">Cliente</th>
                                        <th class="align-middle">Embarcación</th>
                                        <th class="align-middle">Fecha presentanción</th>
                                        <th class="align-middle">Fecha bajada real</th>
                                        <th class="align-middle">Fecha bajada protocolar</th>
                                        <th class="align-middle">Estado</th>
                                        <th class="align-middle">Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="c in crews.data" :key="c.id">
                                        <td class="align-middle">{{ c.client.name }}</td>
                                        <td class="align-middle">{{ c.ship.name }}</td>
                                        <td class="align-middle">{{ c.upload_date | myDate }}</td>
                                        <td class="align-middle">{{ c.real_download_date | myDate }}</td>
                                        <td class="align-middle">{{ c.download_date | myDate }}</td>
                                        <td class="align-middle">{{ c.status.name }}</td>
                                        <td class="align-middle">

                                            <router-link :to="`/facturacion/cotizacion/${c.ghost_quotation.id}`"
                                                         v-tooltip="'Facturar cotización'">
                                                <i class="fas fa-file-alt blue"></i>
                                            </router-link>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>-->

                        <!-- footer -->
                        <!--<div class="card-footer">
                            <pagination :data="crews" @pagination-change-page="getCrews"/>
                        </div>-->
                    </div>
                </div>
            </div>
        </div>
    </section>
    </v-app>
</template>

<script>
    import Loading from '../Common/Loading';
import Viveres from '../Supervisor/SupplyRequest/Viveres.vue';

    export default {
        components: { Loading,Viveres },
        created () {
            this.$Progress.start();
            this.getCrews();
            this.$Progress.finish();
        },
        data () {
            return {
                crews: {},
                loading: true,
                search: '',
                headers: [
                {
                    text: 'Cliente',
                    align: 'start',
                    sortable: false,
                    value: 'cliente',
                },
                { text: 'Embarcación', value: 'barco' },
                { text: 'Fecha de Presentacion', value: 'presentacion' },
                { text: 'Fecha bajada real', value: 'real' },
                { text: 'Fecha bajada protocolar', value: 'protocolar' },
                { text: 'Estado', value: 'estado' },
                { text: 'Acciones', value: 'accion', sortable: false },
                ],
                datos:[],
            }
        },
        methods: {
            getCrews (page = 1) {
                this.$Progress.start();

                     axios.get('/api/logistica/tripulaciones')
                    .then(data => {
                    this.crews = data.data;
                     console.log(this.crews);
                        for(let i = 0; i < this.crews.data.length; i++) {
                            var eldato = this.crews.data[i];
                            this.datos.push({cliente:eldato.client.name,barco:eldato.ship.name,presentacion:this.$options.filters.myDate(eldato.upload_date),real:this.$options.filters.myDate(eldato.real_download_date),protocolar:this.$options.filters.myDate(eldato.download_date),estado:eldato.status.name,ghost_quotation:eldato.ghost_quotation,quotation:eldato.quotation,accion:true});
                        }
                       console.log(this.datos);
                     })
                    .catch(() => this.$launchError('Error al obtener listado de tripulaciones'))
                    .finally(() => this.loading = false);

                this.$Progress.finish();
            }
        }
    }
</script>

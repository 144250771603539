<template>
    <div>
        <h4 class="mt-4 mb-4">Artículos de bodega</h4>

        <div class="table-responsive" v-if="hasItems">
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th class="align-middle">Artículo</th>
                        <th class="align-middle">Talla</th>
                        <th class="align-middle">Cantidad</th>
                        <th class="align-middle">Unidad de medida</th>
                        <th class="align-middle">Observaciones</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="i in items">
                        <td class="align-middle">{{ i.size.item.name }}</td>
                        <td class="align-middle">{{ i.size.name !== '' ? i.size.name : 'STD'}}</td>
                        <td class="align-middle">{{ i.quantity }}</td>
                        <td class="align-middle">{{ i.size.item.measurement_unit.name }}</td>
                        <td class="align-middle">{{ i.observations | dash }}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div v-else>
            <span><i>No se añadieron artículos de bodega a la solicitud de abastecimiento.</i></span>
        </div>
    </div>
</template>

<script>
    export default {
        computed: {
            hasItems () {
                return this.items.length > 0;
            },
            items () {
                return this.$parent.supplyRequest.warehouse_items;
            }
        }
    }
</script>

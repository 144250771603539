/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue');

import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import 'font-awesome/css/font-awesome.min.css'
Vue.use(Vuetify)

import moment from 'moment';
moment.locale('es');

import { Form, HasError, AlertError } from 'vform';
window.Form = Form;

import Gate from "./gate";
Vue.prototype.$gate = new Gate(window.position);

import User from "./user";
Vue.prototype.$user = new User(window.user);

import Swal from 'sweetalert2';

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })
window.Swal = Swal;
window.Toast = Toast;

import VueProgressBar from 'vue-progressbar'
Vue.use(VueProgressBar, {
    color: 'rgb(253, 198, 44)',
    failedColor: 'red',
    height: '3px'
});

import VTooltip from 'v-tooltip';
Vue.use(VTooltip);

Vue.component(HasError.name, HasError)
Vue.component(AlertError.name, AlertError)

/**
 * Routes imports and assigning
 */
import VueRouter from 'vue-router';
Vue.use(VueRouter);
import routes from './routes';

const router = new VueRouter({
    mode: 'history',
    routes
});
// Routes End


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */


// Components
Vue.component('pagination', require('laravel-vue-pagination'));
Vue.component('dashboard', require('./components/Dashboard.vue'));

Vue.component(
    'passport-clients',
    require('./components/passport/Clients.vue').default
);

Vue.component(
    'passport-authorized-clients',
    require('./components/passport/AuthorizedClients.vue').default
);

Vue.component(
    'passport-personal-access-tokens',
    require('./components/passport/PersonalAccessTokens.vue').default
);

Vue.component(
    'not-found',
    require('./components/NotFound.vue').default
);

// Filter Section

Vue.filter('fullDate',function(date){
    return date ? moment(date).format('dddd LL') : '-';
});

Vue.filter('fullDateTime',function(date){
    return date ? moment(date).format('dddd LL [a las] h:mm:ss a') : '-';
});

Vue.filter('myDate',function(date){
    return date ? moment(date).format('DD/MM/yyyy') : '-';
});

Vue.filter('myHour', (h) => {
    if (!h) return '-';
    else {
        let _ = h.split(':')

        return `${_[0]}:${_[1]}`;
    }
});

Vue.filter('antiquity',function(date){
    const today = moment(new Date());

    return today.diff(moment(date), 'years');
});

Vue.filter('until',function(created){
    created = moment(created);

    return created.diff(moment(new Date()), 'days');
});

Vue.filter('age',function(date){
    const today = moment(new Date());

    return today.diff(moment(date), 'years');
});

Vue.filter('qualification-classification',function(a){
    if (a <= 7 && a >= 6.5) {
        return 'A';
    } else if (a <= 6.4  && a >= 6.0) {
        return 'B';
    } else if (a <= 5.9  && a >= 5.5) {
        return 'C';
    } else if (a <= 5.4  && a >= 5.0) {
        return 'D';
    } else if (a <= 4.9  && a >= 4.0) {
        return 'E';
    } else {
        return 'F';
    }
});

Vue.filter('capitalize', (v) => {
    if (v) return v.charAt(0).toUpperCase() + v.slice(1);
});

Vue.filter('dash', (v) => {
    return v === '' || !v ? '-': v;
});

Vue.filter('list',function(arr){
    let str = '-';

    arr.forEach((v, i) => {
        if (i === 0) {
            str = v;
        } else {
            str += `, ${v}`;
        }
    });

    return str;
});

Vue.filter('dotNumber', s => {
    let str = '';
    s = s.toString().split('').reverse().join('');
    let i = s.length;
    while(i>0) str += ((i%3===0&&i!==s.length)?'.':'')+s.substring(i--,i);
    return str;
});

Vue.filter('yesno', value => (value ? '<i class="fas fa-check green"></i>' : '<i class="fas fa-times red"></i>'));

// end Filter

// mixins
import messages from "./messages";
Vue.mixin(messages);

Vue.mixin({
    methods: {
        downloadFileUrl(slug) {
            const path = require('path');

            return '//' + path.join(process.env.MIX_DO_SPACES_DOWNLOAD_URL, slug);
        },
        myDate (date) {
            return date ? moment(date).format('DD/MM/yyyy') : '-';
        }
    }
});

const app = new Vue({
    el: '#app',
    router,
    vuetify: new Vuetify({
        icons: {
          iconfont: 'fa4',
        },
        theme: {
            options: { customProperties: true },
          },
      }),
});

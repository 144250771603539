<template>
    <div>
        <div class="card-body table-responsive p-0" v-if="hasReviews">
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th class="align-middle">Fecha revista</th>
                        <th class="align-middle">Observaciones</th>
                        <th class="align-middle">Subido por</th>
                        <th class="align-middle">Descargar</th>
                    </tr>
                </thead>
                <tbody>
                <tr v-for="r in reviews.data" :key="r.id">
                    <td class="align-middle">{{ r.review_date | myDate }}</td>
                    <td class="align-middle">{{ r.observations | dash }}</td>
                    <td class="align-middle">{{ r.uploader.name }} {{ r.uploader.last_name }}</td>
                    <td class="align-middle">
                        <a :href="downloadFileUrl(
                                `ship-reviews/${r.certificate}`
                                )" target="_blank">
                            <i class="fas fa-file-download blue"></i>
                        </a>
                    </td>
                </tr>
                </tbody>
            </table>
            <pagination :data="reviews" @pagination-change-page="getReviews" v-if="hasReviews"/>
        </div>
        <span v-else><i>No se han subido revistas a esta embarcación.</i></span>
    </div>
</template>

<script>
export default {
    created () {
        this.getReviews();
    },
    computed: {
        hasReviews () {
            return this.reviews.data.length > 0;
        }
    },
    data () {
        return {
            reviews: { data: [] }
        }
    },
    methods: {
        getReviews () {
            this.$Progress.start();
            this.$parent.loading = true;

            axios.get('/api/embarcaciones/' + this.$route.params.id + '/revista')
                .then(({ data }) => {
                    this.reviews = data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$launchError('Error al obtener revistas de la embarcación');
                    this.$Progress.fail();
                })
                .finally(() => {
                    this.$Progress.finish();
                    this.$parent.loading = false;
                });
        }
    }
}
</script>

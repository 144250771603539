<template>
    <div>
        <div class="card-body table-responsive p-0" v-if="hasPlans">
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th class="align-middle">Tipo</th>
                        <th class="align-middle">Fecha subida</th>
                        <th class="align-middle">Subido por</th>
                        <th class="align-middle">Descargar</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="c in plans" :key="c.id">
                        <td class="align-middle">{{ c.type}}</td>
                        <td class="align-middle">{{ c.date | myDate }}</td>
                        <td class="align-middle">{{ c.uploader_name }} {{ c.uploader_last_name }}</td>
                        <td class="align-middle">
                            <a :href="downloadFileUrl(
                                `ship-plans/${c.filename}`
                                )" target="_blank">
                                <i class="fas fa-file-download blue"></i>
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <span v-else><i>No se han subido planos a esta embarcación.</i></span>
    </div>
</template>

<script>
export default {
    created () {
        this.getPlans();
    },
    computed: {
        hasPlans () {
            return this.plans.length > 0;
        }
    },
    data () {
        return {
            plans: { data: [] }
        }
    },
    methods: {
        getPlans () {
            this.$Progress.start();
            this.$parent.loading = true;

            axios.get('/api/embarcaciones/' + this.$route.params.id + '/planos')
                .then(({ data }) => {
                    this.plans = data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$launchError('Error al obtener planos de la embarcación');
                    this.$Progress.fail();
                })
                .finally(() => {
                    this.$Progress.finish();
                    this.$parent.loading = false;
                });
        }
    }
}
</script>

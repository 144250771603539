<template>
    <div>
        <h4 class="mb-4" v-if="hasQualifications">Calificación</h4>
        <div v-if="hasQualifications">
            <table class="table">
                <thead>
                    <tr>
                        <th class="align-middle" v-for="q in general.qualifications">{{ q.qualifier }}</th>
                        <th class="align-middle">Promedio</th>
                        <th class="align-middle">Calificación</th>
                        <th class="align-middle">Comentarios</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="align-middle" v-for="q in general.qualifications">{{ q.qualification }}</td>
                        <td class="align-middle">{{ general.average }}</td>
                        <td class="align-middle">
                            <span :style="`color: ${qualificationColor}`">
                                {{ qualification }}
                            </span>
                        </td>
                        <td class="align-middle">Evaluado por {{ general.evaluated_by}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <h4 class="mb-4 mt-4" v-if="hasQualifications">Listado</h4>

        <div class="card-body table-responsive p-0" v-if="hasQualifications">
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th class="align-middle">Calificado por</th>
                        <th class="align-middle">Año</th>
                        <th class="align-middle">Semestre</th>
                        <th class="align-middle">Promedio</th>
                        <th class="align-middle">Fecha calificación</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="q in qualifications.data" :key="q.id">
                        <td class="align-middle">
                            {{ q.qualifier.name }} {{ q.qualifier.last_name }}
                        </td>
                        <td class="align-middle">{{ q.year }}</td>
                        <td class="align-middle">{{ q.half }}</td>
                        <td class="align-middle">{{ q.average }}</td>
                        <td class="align-middle">{{ q.qualification_date | myDate }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <pagination :data="qualifications" @pagination-change-page="getQualifications" v-if="hasQualifications"/>
        <span v-else><i>No se han registrado evaluaciones de este trabajador.</i></span>
    </div>
</template>

<script>
export default {
    created () {
        this.getQualifications();
    },
    computed: {
        hasQualifications () {
            return this.qualifications.data.length > 0;
        },
        qualification () {
            let a = this.general.average;
            if (a <= 7 && a >= 6.5) {
                return 'A';
            } else if (a <= 6.4 && a >= 6.0) {
                return 'B';
            } else if (a <= 5.9 && a >= 5.5) {
                return 'C';
            } else if (a <= 5.4 && a >= 5.0) {
                return 'D';
            } else if (a <= 4.9 && a >= 4.0) {
                return 'E';
            } else {
                return 'F';
            }
        },
        qualificationColor () {
            let c = '';
            let a = this.general.average;

            if (a <= 7 && a >= 6.5) {
                c = '#48D1CC';
            } else if (a <= 6.4 && a >= 6.0) {
                c = '#008000';
            } else if (a <= 5.9 && a >= 5.5) {
                c = '#90EE90';
            } else if (a <= 5.4 && a >= 5.0) {
                return '#FFFF00';
            } else if (a <= 4.9 && a >= 4.0) {
                return '#FF8C00';
            } else {
                return '#FF0000';
            }

            return c;
        }
    },
    data () {
        return {
            general: {},
            qualifications: { data: [] }
        }
    },
    methods: {
        getQualifications (page = 1) {
            this.$Progress.start();
            this.$parent.loading = true;

            axios.get('/api/trabajadores/' + this.$route.params.id + '/calificaciones?page=' + page)
                .then(({ data }) => {
                    this.general = data.data.general_qualification;
                    this.qualifications = data.data.qualifications;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$launchError('Error al obtener calificaciones del trabajador');
                    this.$Progress.fail();
                })
                .finally(() => {
                    this.$Progress.finish();
                    this.$parent.loading = false;
                });
        }
    }
}
</script>

<template>
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <!-- header -->
            <div class="modal-header">
                <h5 class="modal-title">{{ title }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <!-- body -->
            <div class="modal-body container">
                <!-- alerta -->
            
                <!-- tabla -->
                
                <div class="table-responsive p-0">

                     
                              <table class="table table-hover" v-if="$parent.quotations!=null && $parent.quotations.length > 0">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Código</th>
                                                    <th>Cuenta Bancaria</th>
                                                    <th>Nº Factura</th>
                                                    <!--<th>Orden de Compra</th>
                                                     <th>Fecha Orden de Compra</th>-->
                                                    <th>Proveedor</th>
                                                    <th>Tipo Proveedor</th>
                                             
                                                </tr>
                                            </thead>
                                            <tbody>

                              
                                            
                                                <tr v-for="(t, i) in $parent.quotations" >
                                                    <td>
                                                        <input :value="t.index" type="radio" v-model="onlyOneSelected" >
                                                   </td>
                                                    <td>FE{{ t.index }}</td>
                                                    <td>{{ t.account_number }} - {{ t.account_name }}</td>
                                                    <td>{{ t.invoice_number }}</td>
                                                    <!--<td>{{ t.purchase_order }}</td>
                                                     <td>{{ t.purchase_order_date }}</td>-->
                                                    <td>{{ t.supplier }}</td>
                                                    <td>Pasaje</td>
                                                </tr>
                                            </tbody>
                                        </table>



                    <p class="text-center mb-0" v-else>
                        <i>No hay facturas disponibles para asociar.</i>
                    </p>
                </div>
            </div>

            <!-- footer -->
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                <button type="button" class="btn btn-primary" @click="save">
                    Guardar
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import eventHub from "../../eventHub";

    export default {
        props: ['associateId', 'onlyOne','quotationId','associateSelectedId','supplierId','ticketId','associationType','personType','personId'],
        computed: {
            title () {
                return 'Asociar Factura a '+this.associationType;
            }
        },
        created () {
           // this.getWorkers();
           //this.supplier_id = this.transfer_supplier_id;
         
           
            this.onlyOneSelected = this.associateSelectedId;

            this.fillForm();
          
        },
        destroyed() {
          //  eventHub.$off('cleanSnackForm');
        },
        data () {
            return {
                supplier_id:null,
                onlyOneSelected: null,
                form: {}
            }
        },
        methods: {
            fillForm () {
                this.$parent.quotations.forEach(w => { 

                    this.form[w.index] = { 
                    id: w.index,
                    selected: false,
                    enabled: true
                }
               /* if(w.used == 1){
                    this.form[w.id] = { 
                    id: w.id,
                    selected: false,
                    enabled: true
                }
                }if(w.used==0){
                    this.form[w.id] = { 
                    id: w.id,
                    selected: true,
                    enabled:false
                }
                }
                if(w.used==2){
                    this.form[w.id] = { 
                    id: w.id,
                    selected: false,
                    enabled:true
                }}*/
                });
            },
            save(){
               // console.log(this.$parent.transfers.data);
               // let t = this.$parent.transfers.data.find(w => w.id == this.associateId);
               // t.invoice_id = this.onlyOneSelected;

               // let k = this.$parent.datos.find(w => w.id == this.associateId);
               // k.invoice_id = this.onlyOneSelected;
                

               // let j = this.$parent.quotations.find(w => w.index == this.onlyOneSelected);

               /* var index = j.transfers.findIndex(x => x.id==this.associateId); 
                index === -1 ? j.transfers.push({
                    id:this.associateId
                }) : console.log("object already exists")*/
               /* j.transfers.push({
                    id:this.associateId
                })*/
                //console.log(j);

                 axios.post(`/api/administracion/facturacion/externa/asociar-factura`, {
                        invoice_id: this.onlyOneSelected,
                        id: this.ticketId,
                        type: this.associationType,
                        person_id:this.personId,
                        person_type:this.personType
                    })
                        .then(() => {
                            eventHub.$emit('reloadAssociation');
                            this.$launchSuccess(`Éxito al asociar factura`);
                        })
                        .catch(() => this.$launchError(`Error al asociar factura`));  

            

               
                //console.log(t);
                //eventHub.$emit('reloadAccommodation');
                         
            },

        }
    }
</script>

<template>
    <div>
        <div class="card-body table-responsive p-0" v-if="hasDeliveries">
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th class="align-middle">EPP</th>
                        <th class="align-middle">Talla</th>
                        <th class="align-middle">Observaciones</th>
                        <th class="align-middle">Fecha entrega</th>
                        <th class="align-middle">Subido por</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="d in deliveries.data" :key="d.id">
                        <td class="align-middle">{{ d.size.item.name }}</td>
                        <td class="align-middle">{{ d.size.name }}</td>
                        <td class="align-middle">{{ d.observations }}</td>
                        <td class="align-middle">
                            {{ d.group.delivery | myDate }}
                        </td>
                        <td class="align-middle">
                            {{ d.group.uploader.name }} {{ d.group.uploader.last_name }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <pagination :data="deliveries" @pagination-change-page="getDeliveries" v-if="hasDeliveries"/>
        <span v-else><i>No se han relizado entregas de EPP a este trabajador.</i></span>
    </div>
</template>

<script>
    export default {
        created () {
            this.getDeliveries();
        },
        computed: {
            hasDeliveries () {
                return this.deliveries.data.length > 0;
            }
        },
        data () {
            return {
                deliveries: { data: [] }
            }
        },
        methods: {
            getDeliveries (page = 1) {
                this.$Progress.start();
                this.$parent.loading = true;

                axios.get('/api/trabajadores/' + this.$route.params.id + '/entregas-epp?page=' + page)
                    .then(({ data }) => {
                        this.deliveries = data.data;
                        this.$Progress.finish();
                    })
                    .catch(() => {
                        this.$launchError('Error al obtener entregas de EPP del trabajador');
                        this.$Progress.fail();
                    })
                    .finally(() => {
                        this.$Progress.finish();
                        this.$parent.loading = false;
                    });
            }
        }
    }
</script>

<template>
    <div class="card" :class="{
        'card-success': purchase.status,
        'card-danger': purchase.status === 0,
        'card-warning': purchase.status === null
    }">
        <div class="card-header">
            <h4 class="card-title w-100">
                <a class="d-block w-100" data-toggle="collapse" :href="`#collapse-${purchase.id}`"
                   aria-expanded="true">
                    {{ title }}
                </a>
            </h4>
        </div>
        <div :id="`collapse-${purchase.id}`" class="collapse show" data-parent="#accordion" style="">
            <div class="card-body">
                <!-- información -->
                <div class="row mb-4">
                    <div class="col">
                                        <span>
                                            <strong>Fecha compra:</strong> {{ purchase.purchase | myDate }}.
                                        </span>
                    </div>
                    <div class="col">
                                        <span>
                                            <strong>Proveedor:</strong> {{ purchase.supplier.name | dash }}.
                                        </span>
                    </div>
                    <div class="col">
                                        <span>
                                            <strong>Estado:</strong>
                                            <span class="badge" :class="{
                                                'bg-success': purchase.status,
                                                'bg-danger': purchase.status === 0,
                                                'bg-warning': purchase.status === null
                                            }">
                                                {{ purchase.status === null ? 'No validada': purchase.status === 1 ? 'Validada': 'Rechazada' }}
                                            </span>
                                        </span>
                    </div>
                    <div class="col">
                                        <span>
                                            <strong>Fecha confirmación:</strong> {{ purchase.confirmed_at | myDate }}.
                                        </span>
                    </div>
                </div>

                <!-- motivo de rechazo -->
                <div class="alert alert-danger" role="alert" v-if="purchase.status === 0">
                    <strong>Motivo:</strong> {{ purchase.rejection_reason }}.
                </div>

                <!-- artículos -->
                <table class="table table-hover mb-4">
                    <thead>
                        <tr>
                            <th class="align-middle">Nombre</th>
                            <th class="align-middle">Talla</th>
                            <th class="align-middle">Clasificación</th>
                            <th class="align-middle">Cantidad</th>
                            <th class="align-middle">Unidad de medida</th>
                            <th class="align-middle">Precio</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="i in purchase.warehouse_item_sizes">
                            <td class="align-middle">{{ i.size.item.name }}</td>
                            <td class="align-middle">{{ i.size.name !== '' ? i.size.name : '-' }}</td>
                            <td class="align-middle">{{ i.size.item.classification.name }}</td>
                            <td class="align-middle">{{ i.quantity }}</td>
                            <td class="align-middle">{{ i.size.item.measurement_unit.name }}</td>
                            <td class="align-middle">
                                                    <span>
                                                        ${{ (i.price ? i.price : i.size.item.price) | dotNumber }}
                                                        <i class="fa fa-exclamation-triangle red"
                                                           v-tooltip="'Este nuevo precio reemplazará al anterior'"
                                                           v-if="i.price"></i>
                                                    </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        computed: {
            title () {
                let e = '';

                if (this.purchase.status === null) {
                    e = 'No validada';
                } else if (!this.purchase.status) {
                    e = 'Rechazada';
                } else if (this.purchase.status) {
                    e = 'Validada';
                }

                return `${this.myDate(this.purchase.purchase)} - ${e}.`;
            }
        },
        props: {
            purchase: Object
        }
    }
</script>

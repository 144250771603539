<template>
    <div class="card-body box-profile">
        <div class="text-center" v-if="worker.avatar">
            <img class="profile-user-img img-fluid img-circle"
                 :src="downloadFileUrl(`worker-avatar/${worker.id}/${worker.avatar}`)">
        </div>

        <h3 class="profile-username text-center">
            {{ worker.name }} {{ worker.last_name }}
        </h3>

        <p class="text-muted text-center">{{ worker.rut }}</p>

        <ul class="list-group list-group-unbordered mb-3">
            <li class="list-group-item">
                <b>Código</b> <a class="float-right">{{ worker.code }}</a>
            </li>

            <li class="list-group-item">
                <b>Atributo</b>
                <a class="float-right">{{ worker.attribute ? worker.attribute.name : '-' }}</a>
            </li>

            <li class="list-group-item">
                <b>Roles</b>
                <a class="float-right">{{ worker.roles.map(r => r.name) | list }}</a>
            </li>

            <li class="list-group-item">
                <b>Fecha nacimiento</b>
                <a class="float-right">
                    {{ worker.birthdate | myDate}}<i>({{ worker.birthdate | age }} años)</i>
                </a>
            </li>

            <li class="list-group-item">
                <b>Contacto</b> <a class="float-right">{{ worker.phone_number }}</a>
            </li>

            <li class="list-group-item">
                <b>Dirección</b> <a class="float-right">{{ worker.address }}</a>
            </li>

            <li class="list-group-item">
                <b>Comuna</b> <a class="float-right">{{ worker.commune.name }}</a>
            </li>

            <li class="list-group-item">
                <b>Región</b> <a class="float-right">{{ worker.commune.region.name }}</a>
            </li>

            <li class="list-group-item">
                <b>Fecha registro</b>
                <a class="float-right">{{ worker.created_at | myDate }}</a>
            </li>

            <li class="list-group-item">
                <b>Última tripulación</b>
                <a class="float-right">{{ worker.last_crew ? worker.last_crew.name: '-' }}</a>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        computed: {
            worker () {
                return this.$parent.worker;
            }
        }
    }
</script>

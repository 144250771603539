<template>
 <v-app>
  <section class="content">
      <div class="container-fluid">
          <div class="row">
              <div class="col-12">
                  <div class="card">
                      <loading v-if="loading"/>

                      <div class="card-header">
                          <div class="row align-items-center">
                              <h3 class="col card-title">Listado de personas</h3>

                              <div class="col-6">
                                 <!-- <form @submit.prevent="getPersons">
                                      <input type="text" v-model="searchInput" class="form-control"
                                             placeholder="Ingrese nombre y/o apellido de tripulante">
                                  </form>-->
                              </div>

                              <div class="col-2">
                                  <button type="button" class="btn btn-sm btn-primary ml-2" @click="creationModal" style="color:#ffffff !important;">
                                      <i class="fa fa-plus-square"></i>
                                      Nueva Persona
                                  </button>
                              </div>
                          </div>
                      </div>


<v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Búsqueda"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
  <v-data-table
      :headers="headers"
      :items="datos"
      :search="search"
       :loading="loading"
       :items-per-page="30"
       no-results-text="No se han encontrado personas"
       no-data-text="No existen personas"
      class="elevation-1"
        loading-text="Cargando... Espere"
         :footer-props="{
           'items-per-page-text':'Personas por página',
           'items-per-page-options': [20, 50, 100, -1]
      }"
    >
 <template v-slot:item.accion="{ item, index }">
                         <router-link :to="'/administracion/ficha-personas/' + item.id"
                                                       v-tooltip="'Ficha de la Persona'">
                                              
                                              <v-icon small color="green">
                                                fa-info-circle
                                                </v-icon>
                                          </router-link>

                                        <span>/</span>
                                          <a href="#"  @click.prevent="editModal(item)"
                                             v-tooltip="'Editar persona'">
                                              <v-icon small style="color: #3490DC !important;">
                                                fa-edit
                                                </v-icon>
                                          </a>

                                          
                                          <a hidden href="#"  @click.prevent="accomodationModal(item)"
                                             v-tooltip="'Comprar Alojamiento'">
                                           
                                              <v-icon small style="color: #3490DC !important;">
                                                fa-house-user
                                                </v-icon>
                                          </a>

                                          
                                          <a hidden href="#"  @click.prevent="ticketModal(item)"
                                             v-tooltip="'Comprar Pasaje'">
                                           
                                               <v-icon small style="color: #3490DC !important;">
                                                fa-ticket-alt
                                                </v-icon>
                                          </a>

                                          
                                          <a  hidden href="#"  @click.prevent="transferModal(item)"
                                             v-tooltip="'Comprar Traslado'">
                                             
                                               <v-icon small style="color: #3490DC !important;">
                                                fa-bus
                                                </v-icon>
                                          </a>


                                          <span>/</span>
                                          <a href="#"  @click.prevent="deleteWorker(item.id)"
                                             v-tooltip="'Eliminar persona'">
                                                <v-icon small color="red">
                                                fa-trash
                                                </v-icon>
                                           
                                          </a>                                              
  </template>
  <template v-slot:footer.page-text="items"> {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }} </template>
</v-data-table>
</v-card> 

                      <!--
                      <div class="card-body table-responsive p-0">
                          <table class="table table-hover">
                              <thead>
                                  <tr>
                                      <th class="align-middle">Nombre</th>
                                      <th class="align-middle">Rut</th>
                                      <th class="align-middle">Cargo</th>
                                      <th class="align-middle">Telefono</th>
                                      <th class="align-middle">Email</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr v-for="t in workers.data" :key="t.id">
                                      <td class="align-middle">{{ t.name }} {{ t.last_name }}</td>
                                      <td class="align-middle">{{ t.rut }}</td>
                                       <td class="align-middle">{{ t.role }}</td>
                                        <td class="align-middle">{{ t.phone }}</td>
                                      <td class="align-middle">{{ t.email }}</td>
                                      <td class="align-middle">

                                          <router-link :to="'/administracion/ficha-personas/' + t.id"
                                                       v-tooltip="'Ficha de la Persona'">
                                              <i class="fa fa-info-circle green"></i>
                                          </router-link>

                                        <span>/</span>
                                          <a href="#"  @click.prevent="editModal(t)"
                                             v-tooltip="'Editar persona'">
                                              <i class="fa fa-edit blue"></i>
                                          </a>

                                          
                                          <a hidden href="#"  @click.prevent="accomodationModal(t)"
                                             v-tooltip="'Comprar Alojamiento'">
                                              <i class="fa fa-house-user blue"></i>
                                          </a>

                                          
                                          <a hidden href="#"  @click.prevent="ticketModal(t)"
                                             v-tooltip="'Comprar Pasaje'">
                                              <i class="fa fa-ticket-alt blue"></i>
                                          </a>

                                          
                                          <a  hidden href="#"  @click.prevent="transferModal(t)"
                                             v-tooltip="'Comprar Traslado'">
                                              <i class="fa fa-bus blue"></i>
                                          </a>


                                          <span>/</span>
                                          <a href="#"  @click.prevent="deleteWorker(t.id)"
                                             v-tooltip="'Eliminar persona'">
                                              <i class="fa fa-trash red"></i>
                                          </a>
                                      </td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                      <div class="card-footer">
                          <pagination :data="workers" @pagination-change-page="getPersons"/>
                      </div>

                      -->
                  </div>
              </div>
          </div>

          <!-- modal -->
          <!-- se usa v-if para evitar carga extra de datos -->
          <div class="modal fade" id="form-modal" tabindex="-1" role="dialog" aria-labelledby="form-dialog"
               aria-hidden="true" v-if="showModal" data-backdrop="static" data-keyboard="false">
              <div class="modal-dialog" role="document">
                  <div class="modal-content">
                      <div class="modal-header">
                          <h5 class="modal-title">{{isEdition ? 'Actualizar Persona' : 'Crear Nueva Persona'}}</h5>
                          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                          </button>
                      </div>

                      <form @submit.prevent="setPerson()">
                          <div class="modal-body container">
                            <person-form :form="form"/>
                          </div>

                          <div class="modal-footer">
                              <button type="button" class="btn btn-secondary" data-dismiss="modal" style="color:#ffffff !important;">Cerrar</button>
                              <button type="submit" class="btn btn-primary" style="color:#ffffff !important;" >{{isEdition ? 'Actualizar' : 'Crear'}}</button>
                          </div>
                      </form>
                  </div>
              </div>
          </div>
          <!-- fin modal edicion y creacion nuevo-->


        <!--inicio modal nuevo alojamiento-->
        <div class="modal fade" id="form-modal-accommodation" tabindex="-1" role="dialog" aria-labelledby="form-dialog"
               aria-hidden="true" v-if="showModal" data-backdrop="static" data-keyboard="false">
              <div class="modal-dialog" role="document">
                  <div class="modal-content">
                      <div class="modal-header">
                          <h5 class="modal-title">Nuevo Alojamiento para {{name}}</h5>
                          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                          </button>
                      </div>

                      <form @submit.prevent="setAccommodation()">
                          <div class="modal-body container">
                            <accommodation-form :form="formAccommodation"/>
                          </div>

                          <div class="modal-footer">
                              <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                              <button type="submit" class="btn btn-primary" >Guardar</button>
                          </div>
                      </form>
                  </div>
              </div>
          </div>
        <!--fin modal nuevo alojamiento-->


        <!--inicio modal nuevo pasaje-->
        <div class="modal fade" id="form-modal-ticket" tabindex="-1" role="dialog" aria-labelledby="form-dialog"
               aria-hidden="true" v-if="showModal" data-backdrop="static" data-keyboard="false">
              <div class="modal-dialog" role="document">
                  <div class="modal-content">
                      <div class="modal-header">
                          <h5 class="modal-title">Nuevo Pasaje para {{name}}</h5>
                          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                          </button>
                      </div>

                      <form @submit.prevent="setTicket()">
                          <div class="modal-body container">
                            <ticket-form :form="formTicket"/>
                          </div>

                          <div class="modal-footer">
                              <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                              <button type="submit" class="btn btn-primary" >Guardar</button>
                          </div>
                      </form>
                  </div>
              </div>
          </div>
        <!--fin modal nuevo pasaje-->


        <!--inicio modal nuevo traslado-->
        <div class="modal fade" id="form-modal-transfer" tabindex="-1" role="dialog" aria-labelledby="form-dialog"
               aria-hidden="true" v-if="showModal" data-backdrop="static" data-keyboard="false">
              <div class="modal-dialog" role="document">
                  <div class="modal-content">
                      <div class="modal-header">
                          <h5 class="modal-title">Nuevo Traslado para {{name}}</h5>
                          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                          </button>
                      </div>

                      <form @submit.prevent="setTransfer()">
                          <div class="modal-body container">
                            <transfer-form :form="formTransfer"/>
                          </div>

                          <div class="modal-footer">
                              <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                              <button type="submit" class="btn btn-primary" >Guardar</button>
                          </div>
                      </form>
                  </div>
              </div>
          </div>
        <!--fin modal nuevo traslado-->

      </div>
  </section>
   </v-app>
</template>

<script>
    import Loading from "./Common/Loading";
    import PersonForm from "./Persons/Form";
    import AccommodationForm from "./Persons/Accommodation-Form";
    import TicketForm from "./Persons/Ticket-Form";
    import TransferForm from "./Persons/Transfer-Form";

    export default {
        components: { Loading, PersonForm,AccommodationForm,TicketForm,TransferForm },
        created () {
            this.$Progress.start();
            this.getPersons();
            this.$Progress.finish();
        },
        data () {
            return {
                form: new Form({
                    name: '',
                    last_name: '',
                    email: '',
                    rut: '',
                    id:null,
                    edit:false,
                    role:'',
                    phone:''
                }),
                formAccommodation: new Form({
                    entry_date: '',
                    exit_date: '',
                    price:'',
                    supplier_id: null,
                    observation:'',
                    id:null
                }),
                formTransfer: new Form({
                    upload_date: '',
                    entry_text: '',
                    exit_text: '',
                    price:'',
                    supplier_id: null,
                    observation:'',
                    no_facturar:false,
                    id:null
                }),
                formTicket: new Form({
                    id: null,
                    flight_stretch_id: null,
                    flight_date: null,
                    departure_time: null,
                    arrival_time: null,
                    departure_airport_id: null,
                    arrival_airport_id: null,
                    extra_price:0,
                    carry_price:0,
                    price: 0,
                    airline_id: null,
                    stock_ticket_id: null,
                    tarifa_plus:0
                }),
                person_id:null,
                name:null,
                isEdition:false,
                loading: true,
                searchInput: null,
                showModal: false,
                workers: {},
                search: '',
                headers: [
                {
                    text: 'Nombre',
                    align: 'start',
                    value: 'name',
                },
                { text: 'Rut', value: 'rut' },
                { text: 'Cargo', value: 'role' },
                { text: 'Telefono', value: 'phone' },
                { text: 'Email', value: 'email' },
                { text: 'Opciones', value: 'accion', sortable: false },
                ],
                datos:[],
            }
        },
        methods: {
            validaRut: function(rutCompleto) {
                rutCompleto = rutCompleto.replace("‐","-");
                if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test( rutCompleto ))
                    return false;
                var tmp     = rutCompleto.split('-');
                var digv    = tmp[1];
                var rut     = tmp[0];
                if ( digv == 'K' ) digv = 'k' ;

                return (this.dv(rut) == digv );
            },
            dv : function(T){
                var M=0,S=1;
                for(;T;T=Math.floor(T/10))
                    S=(S+T%10*(9-M++%6))%11;
                return S?S-1:'k';
            },
            setAccommodation(){

                Swal.fire({
                    title: '¿Estás seguro que deseas guardar este alojamiento?',
                    text: 'No podrás revertir esta acción',
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Si',
                    cancelButtonText: 'No'
                }).then((result) => {
                    if (result.value) {
                        this.formAccommodation.id = this.person_id;
                        this.formAccommodation.post('/api/administracion/personas/'+this.person_id+'/alojamientos')
                        .then(() => {
                            $('#form-modal-accommodation').modal('hide');
                            this.formAccommodation.reset();
                            this.$launchSuccess('Se guardo el alojamiento correctamente');
                            this.$Progress.finish();
                        })
                        .catch(() => {
                            this.$Progress.fail();
                            this.$launchError('Error al guardar alojamiento');
                        });
                        }
                });
                
                
            },
            setTransfer(){

                Swal.fire({
                    title: '¿Estás seguro que deseas guardar este alojamiento?',
                    text: 'No podrás revertir esta acción',
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Si',
                    cancelButtonText: 'No'
                }).then((result) => {
                    if (result.value) {
                        this.formTransfer.id = this.person_id;
                        this.formTransfer.post('/api/administracion/personas/'+this.person_id+'/traslados')
                        .then(() => {
                            $('#form-modal-transfer').modal('hide');

                            this.formTransfer.reset();
                            this.$launchSuccess('Se guardo el traslado correctamente');
                            this.$Progress.finish();
                        })
                        .catch(() => {
                            this.$Progress.fail();
                            this.$launchError('Error al guardar traslado');
                        });
                        }
                });
                
                
            },
            setTicket(){

                Swal.fire({
                    title: '¿Estás seguro que deseas guardar este pasaje?',
                    text: 'No podrás revertir esta acción',
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Si',
                    cancelButtonText: 'No'
                }).then((result) => {
                    if (result.value) {
                        this.formTicket.id = this.person_id;
                        this.formTicket.post('/api/administracion/personas/'+this.person_id+'/pasajes')
                        .then(() => {
                            $('#form-modal-ticket').modal('hide');

                            this.formTicket.reset();
                            this.$launchSuccess('Se guardo el pasaje correctamente');
                            this.$Progress.finish();
                        })
                        .catch(() => {
                            this.$Progress.fail();
                            this.$launchError('Error al guardar pasaje');
                        });
                        }
                });
                
                
            },
            getPersons (page = 1) {
                this.$Progress.start();
                this.loading = true;

                axios.get('/api/administracion/personas')
                    //.then(({ data }) => this.workers = data.data)
                    .then(data => {
                    this.workers = data.data;
                    this.datos = [];
                     console.log(this.workers);
                        for(let i = 0; i < this.workers.data.length; i++) {
                            var t = this.workers.data[i];     
                            this.datos.push({last_name:t.last_name,name:t.name+" "+t.last_name,rut:t.rut,role:t.role,phone:t.phone,email:t.email,id:t.id,accion:true});
                        }
                       console.log(this.datos);
                     })
                    .catch(() => this.$launchError('Error al obtener listado de personas'))
                    .finally(() => {
                        this.$Progress.finish();
                        this.loading = false;
                    });
            },

            creationModal () {
                this.isEdition = false;
                this.showModal = true;
                this.form.reset();
                this.$nextTick(() => $('#form-modal').modal('show'));
            },

             accomodationModal (person) {
                this.isEdition = false;
                this.showModal = true;
                this.setName(person);
                this.form.reset();
                this.$nextTick(() => $('#form-modal-accommodation').modal('show'));
            },
            setName(person){
                this.name = person.name+ " "+person.last_name;
                this.person_id = person.id;
            },

             ticketModal (person) {
                this.isEdition = false;
                this.showModal = true;
                this.setName(person);
                this.form.reset();
                this.$nextTick(() => $('#form-modal-ticket').modal('show'));
            },
            
             transferModal (person) {
                this.isEdition = false;
                this.showModal = true;
                this.setName(person);
                this.form.reset();
                this.$nextTick(() => $('#form-modal-transfer').modal('show'));
            },
            
             editModal (person) {
                this.form.name = person.name;
                this.form.last_name = person.last_name;
                this.form.rut = person.rut;
                this.form.email = person.email;
                this.form.id = person.id;
                this.form.role = person.role;
                this.form.phone = person.phone;
                this.isEdition = true;
                this.showModal = true;
                //this.form.reset();
                this.$nextTick(() => $('#form-modal').modal('show'));
            },
            setPerson(){
                if(this.isEdition){
                    this.editPerson();
                }else{
                    this.createPerson();
                }
            },
            editPerson(){
                if(this.validaRut(this.form.rut)){
                    this.$Progress.start();

                this.form.post('/api/administracion/personas/'+this.form.id)
                    .then(() => {
                        $('#form-modal').modal('hide');
                        this.form.reset();
                        this.$launchSuccess('La persona fue editada correctamente');
                        this.$Progress.finish();
                        this.getPersons();
                    })
                    .catch(() => {
                        this.$Progress.fail();
                        this.$launchError('Error al editar persona');
                    });
                }else{

                    this.$launchError('Rut no válido');
                }
            },
            createPerson () {
                if(this.validaRut(this.form.rut)){
                    this.$Progress.start();

                this.form.post('/api/administracion/personas')
                    .then(() => {
                        $('#form-modal').modal('hide');

                        this.$launchSuccess('La persona fue creada correctamente');
                        this.form.reset();
                        this.$Progress.finish();
                        this.getPersons();
                    })
                    .catch(() => {
                        this.$Progress.fail();
                        this.$launchError('Error al crear persona');
                    });
                }else{

                    this.$launchError('Rut no válido');
                }

            },

            deleteWorker (id) {
                Swal.fire({
                    title: '¿Estás seguro que deseas eliminar esta persona?',
                    text: 'No podrás revertir esta acción',
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Si',
                    cancelButtonText: 'No'
                }).then((result) => {
                    if (result.value) {
                        this.form.delete('/api/administracion/personas/' + id).then(() => {
                            Swal.fire(
                                'Éxito',
                                'La persona fue eliminada correctamente',
                                'success'
                            );

                            this.getPersons();
                        }).catch(() => Swal.fire(
                            'Error',
                            'Error al eliminar persona',
                            'warning'
                        ));
                    }
                });
            }
        }
    }
</script>

<template>
    <div id="edit-ship-container">
        <!-- breadcrumb -->
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Nueva compra</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link to="/">Inicio</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link to="/bodega/compras">Compras</router-link>
                            </li>
                            <li class="breadcrumb-item active">Nueva compra</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>

        <!-- edición -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col">
                        <div class="card">
                            <loading v-if="loading"/>

                            <div class="card-body">
                                <!-- selector -->
                                <form class="mb-5" @submit.prevent="savePurchase">
                                    <div class="row">
                                        <div class="col form-group">
                                            <label>Fecha de compra</label>
                                            <input type="date" class="form-control" v-model="form.purchase"/>
                                        </div>

                                        <div class="col form-group">
                                            <label>Proveedor</label>
                                            <select class="form-control" v-model="form.supplier_id">
                                                <option :value="null" disabled>Escoja un proveedor</option>
                                                <option :value="s.id" v-for="s in suppliers">{{ s.name }}</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col form-group">
                                            <label>Artículo</label>
                                            <select class="form-control" v-model="form.item_id" @change="changeItem">
                                                <option :value="null" disabled>Escoja un artículo</option>
                                                <option :value="w.id" v-for="w in validItems">{{ w.name }}</option>
                                            </select>
                                        </div>

                                        <div class="col form-group">
                                            <label>Talla</label>
                                            <select class="form-control" v-model="form.size_id"
                                                    :disabled="sizes.length === 0">
                                                <option :value="null" disabled>Escoja una talla</option>
                                                <option :value="s.id" v-for="s in sizes">{{ s.name }}</option>
                                            </select>
                                        </div>

                                        <div class="col form-group">
                                            <label>Cantidad</label>
                                            <input min="0" type="number" class="form-control" v-model.number="form.quantity"/>
                                        </div>

                                        <div class="col form-group">
                                            <label>Precio</label>
                                            <input min="0" type="number" class="form-control" v-model.number="form.price"/>
                                        </div>
                                    </div>

                                    <button type="submit" class="btn btn-primary float-right">
                                        Agregar
                                    </button>
                                </form>

                                <!-- artículos -->
                                <h4>Artículos</h4>

                                <span v-if="purchases.length === 0">
                                    <i>No se han agregado artículos aún.</i>
                                </span>
                                <div class="row" v-else>
                                    <ul class="mt-2 mb-0" style="margin-left:30px !important;">
                                        <li v-for="(p, i) in purchases">
                                            <strong>Artículo: </strong> {{ p.name }} ({{ p.size }}) -
                                            <strong>Cantidad:</strong> {{ p.quantity }} -
                                            <strong>Precio:</strong> ${{ p.price | dotNumber }}
                                            <i class="fa fa-times red hand-pointer" @click="deletePurchase(i)"
                                               v-tooltip="'Eliminar'"/>
                                        </li>
                                    </ul>
                                </div>

                                <button class="btn btn-success float-right" @click="confirm">
                                    Guardar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Loading from "../Common/Loading";
    import moment from "moment";

    export default {
        components: { Loading },
        computed: {
            validItems () {
                const ids = this.purchases.map(p => p.id);

                // listado de artículos omitiendo los ya agregados
                return this.warehouseItems.filter(i => !ids.includes(i.id));
            }
        },
        created () {
            this.getSuppliers();
            this.getWarehouseItems();
        },
        data () {
            return {
                form: {
                    item_id: null,
                    size_id: null,
                    purchase: moment().format('yyyy-MM-DD'),
                    quantity: 0,
                    price: null,
                    supplier_id: null
                },
                loading: true,
                purchases: [],
                sizes: [],
                suppliers: [],
                warehouseItems: [],
                warehouseItemsForm: {}
            }
        },
        methods: {
            changeItem () {
                const i = this.warehouseItems.find(i => i.id === this.form.item_id);
                this.sizes = i.sizes;
                this.form.price = i.price;
                this.form.size_id = null;
            },

            confirm () {
                Swal.fire({
                    title: '¿Estás seguro que deseas ingresar esta compra?',
                    text: 'No podrás revertir esta acción',
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Si',
                    cancelButtonText: 'No'
                }).then((result) => {
                    if (result.value) {
                        axios.post('/api/bodega/compras', {
                            supplier_id: this.form.supplier_id,
                            purchase: this.form.purchase,
                            items: this.purchases.map(p => {
                                return {
                                    size_id: p.id,
                                    quantity: p.quantity,
                                    price: p.price,
                                    purchase_date:p.purchase_date,
                                    supplier_id:p.supplier_id
                                }
                            })
                        })
                            .then(() => {
                                Swal.fire(
                                    'Éxito',
                                    'La compra fue ingresada correctamente',
                                    'success'
                                );

                                this.resetForm();
                                this.purchases = [];
                            }).catch(() => Swal.fire(
                                'Error',
                                'No se pudo ingresar la compra',
                                'warning'
                            ));
                    }
                });
            },

            deletePurchase (i) {
                this.purchases.splice(i, 1);
            },

            getSuppliers () {
                axios.get('/api/bodega/proveedores/bodega')
                    .then(({ data }) => this.suppliers = data.data)
                    .catch(() => this.$launchError('Error al obtener proveedores'));
            },

            getWarehouseItems () {
                this.loading = true;

                axios.get('/api/bodega/articulos-tallas')
                    .then(({ data }) => {
                        this.warehouseItems = data.data;
                        this.warehouseItems.forEach(i => this.warehouseItemsForm[i.id] = {
                            id: i.id,
                            quantity: 0
                        });
                    })
                    .catch(() => this.$launchError('Error al obtener artículos'))
                    .finally(() => this.loading = false);
            },

            resetForm () {
                this.form.item_id = null;
                this.form.size_id = null;
                this.form.quantity = 0;
                this.form.price = null;
                this.sizes = [];
            },

            savePurchase () {
                // solo puede guardar si se ha marcado un epp válido
                if (!this.form.item_id && !this.form.size_id && !this.form.quantity) {
                    // TODO: errores en formularios
                    this.$launchError('Debe completar todos los campos antes de continuar con la compra');
                } else {
                    this.purchases.push({
                        id: this.form.size_id,
                        name: this.warehouseItems.find(w => w.id === this.form.item_id).name,
                        size: this.sizes.find(s => s.id === this.form.size_id).name,
                        quantity: this.form.quantity,
                        price: this.form.price,
                        purchase_date:this.form.purchase,
                        supplier_id:this.form.supplier_id
                    });

                    this.resetForm();
                }
            }
        }
    }
</script>

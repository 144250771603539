<template>
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <!-- header -->
            <div class="modal-header">
                <h5 class="modal-title">{{ edit ? 'Editar': 'Añadir' }} Factura</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <!-- body -->
            <form @submit.prevent="setTransfer">
                <div class="modal-body container">

                      <div class="row">
                        <div class="col form-group">
                              <label>Cuenta Bancaria</label>
                                            <select class="form-control" v-model="form.account_id"
                                                    :disabled="accounts.length === 0">
                                                <option :value="null" disabled>Escoja una cuenta</option>
                                                <option :value="i.id" v-for="i in accounts">{{ i.account_number }} - {{ i.account_name }}</option>
                                            </select>
                        </div>
                    </div>


                    <div class="row">
                          
                          <div class="col form-group">
                            <label>Número de factura</label>
                            <input type="number" class="form-control" min='0' v-model="form.invoice_number">
                            
                        </div>
                   
                </div>

                     <div class="row">
                    <div class="col form-group">
                            <label>Tipo Proveedor</label>
                                            <select class="form-control" v-model="form.supplier_type" v-on:change="getSuppliers()"
                                                    >
                                                <option :value="null" disabled>Escoja tipo de proveedor</option>
                                                <option :value="1">Alimento</option>
                                                 <option :value="2">Bodega</option>
                                                  <option :value="3">Traslado</option>
                                                   <option :value="4">Alojamiento</option>
                                                    <option :value="5">Pasaje</option>
                                            </select>
                            
                        </div>
                        </div>

                        <div class="row">
                    <div class="col form-group" v-if="form.supplier_type!=null">
                              <label>Proveedor</label>
                                            <select class="form-control" v-model="form.supplier_id"
                                                    :disabled="suppliers.length === 0">
                                                <option :value="null" disabled>Escoja un proveedor</option>
                                                <option :value="i.id" v-for="i in suppliers">{{ i.name }}</option>
                                            </select>
                            
                        </div>
                        </div>



                 <div class="row" v-if="form.supplier_type!=5 && form.supplier_type!=null">
                           <div class="col form-group">
                            <label>Fecha Orden de Compra</label>
                            <input type="date" class="form-control" v-model="form.purchase_order_date">
                            
                        </div>

                       
                   
                </div>
                    <div class="row" v-if="form.supplier_type!=5 && form.supplier_type!=null">
                 

                         <div class="col form-group">
                            <label>Orden de Compra</label>
                            <input type="number" class="form-control" min='0' v-model="form.purchase_order">
                            
                        </div>


                        </div>

                   

                  <!--  <div class="row" v-if="form.supplier_type==5">
                        <div class="col form-group">
                              <label>Aerolinea</label>
                                            <select class="form-control" v-model="form.airline_id"
                                                    :disabled="suppliers.length === 0">
                                                <option :value="null" disabled>Escoja un proveedor</option>
                                                <option :value="i.id" v-for="i in suppliers">{{ i.name }}</option>
                                            </select>
                        </div>
                    </div>-->



                     

                

                 
                  
                  
                </div>

                <div class="modal-footer">
                    <button type="submit" class="btn btn-primary float-right" @click="saveInvoice">Guardar</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    import eventHub from "../../eventHub";

    export default {
        props: ['invoiceId'],
        computed: {
            edit () {
                return this.invoiceId !== null;
            }
        },
        created () {
            this.$Progress.start();
            this.getBank();
            this.$Progress.finish();

            // si es edición, debe llenar el formulario
           // console.log(this.edit);
            if (this.invoiceId !=null){
                this.fillEditForm();
            } 

            // registra eventos
         //   eventHub.$on('cleanTransferForm', () => this.form.reset());
        },
        data () {
            return {
                suppliers:[],
                 accounts:[],
                form: new Form({
                    supplier_type:null,
                    supplier_id:null,
                    airline_id:null,
                    account_id:null,
                    purchase_order:'',
                    purchase_order_date:null,
                    invoice_number:'',
                    quotation_id:null,
                    isedit:0,
                    invoice_id:null
                    /*upload_date: '',
                    entry_text: '',
                    exit_text: '',
                    price:'',
                    supplier_id: null,
                    observation:'',
                    no_facturar:false,
                    id:null,
                    selected:[],
                    selectedWorkers:[],
                    isedit:0,
                    journey_type:null*/
                })
            }
        },
        destroyed() {
           // eventHub.$off('cleanTransferForm');
        },
        methods: {
             getSuppliers () {
                 if(this.form.supplier_type!=null){
                     
                 
                this.loading = true;
                this.$Progress.start();
                

                axios.get('/api/administracion/facturacion/proveedores/'+this.form.supplier_type)
                    .then(({ data }) => this.suppliers = data.data)
                    .catch(() => {
                        this.$launchError('Error al obtener listado de proveedores');
                        this.$Progress.fail();
                    })
                    .finally(() => {
                        this.loading = false;
                        this.$Progress.finish();
                      
                            this.form.supplier_id = null;
                    
                    });
                }
            },

              getSuppliersLoad () {
                 if(this.form.supplier_type!=null){
                     
                 
                this.loading = true;
                this.$Progress.start();
                

                axios.get('/api/administracion/facturacion/proveedores/'+this.form.supplier_type)
                    .then(({ data }) => this.suppliers = data.data)
                    .catch(() => {
                        this.$launchError('Error al obtener listado de proveedores');
                        this.$Progress.fail();
                    })
                    .finally(() => {
                        this.loading = false;
                        this.$Progress.finish();
                        
                     
                    });
                }
            },
            getBank () {
         
                     
                 
                this.loading = true;
                this.$Progress.start();
                

                axios.get('/api/administracion/facturacion/cuentas-banco')
                    .then(({ data }) => this.accounts = data.data)
                    .catch(() => {
                        this.$launchError('Error al obtener listado de cuentas bancarias');
                        this.$Progress.fail();
                    })
                    .finally(() => {
                        this.loading = false;
                        this.$Progress.finish();
                    });
                
            },
             saveInvoice(){
                 var pasa = false;
                 if(this.form.supplier_type!=5){
                     if(this.form.purchase_order_date==null || this.form.purchase_order=='' || this.form.invoice_number=='' || this.form.account_id==null || this.form.supplier_id==null  ) {
                            this.$launchError('Error, debe completar todos los datos');ç
                            
                    }else{
                        pasa = true;
                    }
                 }else{
                      if(this.form.invoice_number=='' || this.form.account_id==null || this.form.supplier_id==null  ) {
                            this.$launchError('Error, debe completar todos los datos');ç
                        }else{
                             pasa = true;
                             this.form.purchase_order = null;
                             this.form.purchase_order_date = null;
                        }
                 }

                 if(pasa == true){
                        Swal.fire({
                                    title: '¿Estás seguro que deseas guardar esta factura?',
                                    text: 'Puedes editarla mas tarte',
                                    showCancelButton: true,
                                    confirmButtonColor: '#d33',
                                    cancelButtonColor: '#3085d6',
                                    confirmButtonText: 'Si',
                                    cancelButtonText: 'No'
                                }).then((result) => {
                                    if (result.value) {
                                        this.form.quotation_id = this.$parent.id;
                                        console.log(this.form);
                                        this.form.post('/api/administracion/facturacion/nueva-factura')
                                        .then(() => {
                                            eventHub.$emit('reloadInvoice');
                                            this.$launchSuccess('Se guardo la factura correctamente');
                                            this.$Progress.finish();
                                        })
                                        .catch(() => {
                                            this.$Progress.fail();
                                            this.$launchError('Error al guardar factura');
                                        });
                                        }
                                });   
                 }
                        
                         
                                    
            },
            fillEditForm () {
                
                let t = this.$parent.quotation.quotations.find(t => t.id === this.invoiceId);
                console.log(t);
                this.form.supplier_type = t.supplier_type_id;
                this.getSuppliersLoad(); 
                this.form.supplier_id = t.supplier_id;
                this.form.airline_id = t.airline_id;
                this.form.account_id = t.account_id;
                this.form.purchase_order = t.purchase_order;
                this.form.purchase_order_date = t.purchase_order_date;
                this.form.invoice_number = t.invoice_number;
                this.form.invoice_id = t.id;
                this.form.isedit = 1;
               // this.form.fill(t);
            }
        }
    }
</script>

<template>
    <form @submit.prevent="editShip">
        <ship-form :is-edition="true" :form="form" :ship-types="shipTypes" :supervisors="supervisors"/>

        <button type="submit" class="btn btn-primary float-right">Guardar</button>
    </form>
</template>

<script>
    import Form from "vform/src";
    import ShipForm from '../Ships/Form';

    export default {
        components: { ShipForm },
        created () {
            this.$Progress.start();
            this.getShip();
            this.getSupervisors();
            this.getShipTypes();
            this.$Progress.finish();
        },
        data () {
            return {
                form: new Form({
                    id : '',
                    name: '',
                    code: '',
                    plate: '',
                    call_identifier: '',
                    capacity: '',
                    engines: '',
                    generators: '',
                    supervisor_id: null,
                    type_id: null
                }),
                shipTypes: [],
                supervisors: []
            }
        },
        methods: {
            editShip () {
                this.$Progress.start();

                this.form.put('/api/embarcaciones/' + this.form.id)
                    .then(() => {
                        this.$launchSuccess('Éxito al editar embarcación');
                        this.$Progress.finish();
                    })
                    .catch(() => {
                        this.$launchError('Error al editar embarcación');
                        this.$Progress.fail();
                    });
            },

            getShip () {
                this.$Progress.start();
                this.$parent.loading = true;

                axios.get('/api/embarcaciones/' + this.$parent.id)
                    .then(({ data }) => {
                        this.form.fill(data.data);
                        this.$Progress.finish();
                    })
                    .catch(() => {
                        this.$Progress.fail();
                        this.$launchError('Error al obtener especificaciones de la embarcación');
                    })
                    .finally(() => this.$parent.loading = false);
            },

            getSupervisors () {
                axios.get('/api/supervisores').then(({ data }) => this.supervisors = data.data)
                    .catch(() => this.$launchError('Error al obtener listado de supervisores'));
            },

            getShipTypes () {
                axios.get('/api/tipos-embarcaciones').then(({ data }) => this.shipTypes = data.data)
                    .catch(() => this.$launchError('Error al obtener listado de tipos de embarcaciones'));
            }
        }
    }
</script>

<template>
<v-app>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <loading v-if="loading"/>

                        <div class="card-header">
                            <div class="row align-items-center">
                                <h3 class="col card-title">Sala de espera</h3>

                                <div class="col">
                                    <!--<form @submit.prevent="getWorkers">
                                        <input type="text" v-model="searchInput" class="form-control"
                                               placeholder="Ingrese nombre y/o apellido de tripulante">
                                    </form>-->
                                </div>
                            </div>
                        </div>

<v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Búsqueda"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
  <v-data-table
      :headers="headers"
      :items="datos"
      :search="search"
       :loading="loading"
       :items-per-page="30"
       no-results-text="No se han encontrado tripulantes"
       no-data-text="No existen tripulantes"
      class="elevation-1"
        loading-text="Cargando... Espere"
         :footer-props="{
           'items-per-page-text':'Tripulantes por página',
           'items-per-page-options': [20, 50, 100, -1]
      }"
    >
 <template v-slot:item.accion="{ item, index }">

                    <router-link :to="'/admin/trabajadores/' + item.id" v-tooltip="'Ficha de Tripulante'">
                                               
                                                <v-icon small color="green">
                                               fa fa-info-circle
                                                </v-icon>
                     </router-link>                           
    
                                                                        
  </template>
   <template v-slot:item.motivo="{ item, index }">
             <worker-reasons :status="item.motivo"/>
  </template>
  <template v-slot:item.estado="{ item, index }">

               <worker-status :status="item.estado"/>
  </template>
  <template v-slot:footer.page-text="items"> {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }} </template>
</v-data-table>
</v-card> 

                        <!--
                        <div class="card-body table-responsive p-0">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th class="align-middle">Nombre</th>
                                        <th class="align-middle">Rut</th>
                                        <th class="align-middle">Edad</th>
                                        <th class="align-middle">Rol</th>
                                        <th class="align-middle">Estado</th>
                                        <th class="align-middle">Motivo</th>
                                        <th class="align-middle">Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="w in workers.data">
                                        <td class="align-middle">{{ w.name }} {{ w.last_name }} {{w.attribute_name}}</td>
                                        <td class="align-middle">{{ w.rut }}</td>
                                        <td class="align-middle">{{ w.birthdate | age }}</td>
                                        <td class="align-middle">{{ w.roles.map(r => r.name) | list }}</td>
                                        <td class="align-middle">
                                            <worker-status :status="w.status.status"/>
                                        </td>
                                        <td class="align-middle">
                                            <worker-reasons :status="w.status"/>
                                        </td>
                                        <td class="align-middle">
                                            <router-link :to="'/admin/trabajadores/' + w.id">
                                                <i class="fa fa-info-circle green"></i>
                                            </router-link>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="card-footer">
                            <pagination :data="workers" @pagination-change-page="getWorkers"/>
                        </div>
                        -->

                    </div>
                </div>
            </div>
        </div>
    </section>
    </v-app>
</template>

<script>
    import Loading from "./Common/Loading";
    import WorkerReasons from "./FleetChief/EditCrew/WorkerReasons";
    import WorkerStatus from "./FleetChief/EditCrew/WorkerStatus";

    export default {
        components: { Loading, WorkerReasons, WorkerStatus },
        created () {
            this.getWorkers();
        },
        data () {
            return {
                loading: true,
                searchInput: null,
                workers: {},
                search:'',
                 headers: [
                {
                    text: 'Nombre',
                    align: 'start',
                    value: 'nombre',
                },
                { text: 'Rut', value: 'rut' },
                { text: 'Edad', value: 'edad' },
                { text: 'Rol', value: 'rol' },
                { text: 'Atributo', value: 'atributo' },
                { text: 'Estado', value: 'estado' },
                { text: 'Motivo', value: 'motivo' },
                { text: 'Acciones', value: 'accion', sortable: false },
                ],
                datos:[],
            }
        },
        methods: {
            getWorkers(page = 1) {
                this.loading = true;
                this.$Progress.start();

                axios.get('/api/sala-espera')
                    /*.then(({ data }) => {
                        this.workers = data.data;
                        this.$Progress.finish();
                    })*/
                     .then(data => {
                    this.datos = [];
                    this.workers = data.data;
                     console.log(this.workers);
                        for(let i = 0; i < this.workers.data.length; i++) {
                            var t = this.workers.data[i];
                            var edad = this.$options.filters.age(t.birthdate ); 
                            var roles = this.$options.filters.list(t.roles.map(r => r.name));  
                            this.datos.push({nombre:t.name+" "+t.last_name,rut:t.rut,edad:edad,rol:roles,atributo:t.attribute_name,estado:t.status.status,motivo:t.status,id:t.id,accion:true});
                        }
                       console.log(this.datos);
                       this.$Progress.finish();
                     })
                    .catch(() => {
                        this.$Progress.fail();
                        this.$launchError('Error al obtener listado de trabajadores');
                    })
                    .finally(() => this.loading = false);
            }
        }
    }
</script>

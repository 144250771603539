<template>
    <form @submit.prevent="saveSpecifications">
        <h4 class="mb-4">Características generales</h4>

        <div class="row">
            <div class="col form-group">
                <label>Armador</label>
                <input v-model="form.shipowner" type="text" class="form-control">
            </div>
        </div>

        <div class="row">
            <div class="col form-group">
                <label>Puerto de matrícula</label>
                <input v-model="form.plate_port" type="text" class="form-control">
            </div>

            <div class="col form-group">
                <label>Tonelaje de registro grueso T.R.G.</label>
                <input v-model.number="form.trg" type="number" min="0" step="any" class="form-control">
            </div>
        </div>

        <div class="row">
            <div class="col form-group">
                <label>Bollard pull (ton)</label>
                <input v-model.number="form.bollard_pull" step="any" min="0" type="number" class="form-control">
            </div>

            <div class="col form-group">
                <label>Desplazamiento liviano (ton)</label>
                <input v-model.number="form.light_displacement" step="any" min="0" type="number" class="form-control">
            </div>
        </div>

        <h4 class="mt-4 mb-4">Características de construcción</h4>

        <div class="row">
            <div class="col form-group">
                <label>Año de construcción</label>
                <input v-model.number="form.year_of_construction" min="0" type="number" class="form-control">
            </div>

            <div class="col form-group">
                <label>País</label>
                <input v-model="form.country" type="text" class="form-control">
            </div>
        </div>

        <div class="row">
            <div class="col form-group">
                <label>Astilleros</label>
                <input v-model="form.shipyards" type="text" class="form-control">
            </div>

            <div class="col form-group">
                <label>Eslora total (mts)</label>
                <input v-model.number="form.total_length" step="any" min="0" type="number" class="form-control">
            </div>
        </div>

        <div class="row">
            <div class="col form-group">
                <label>Manga (mts)</label>
                <input v-model.number="form.sleeve" step="any" min="0" type="number" class="form-control">
            </div>

            <div class="col form-group">
                <label>Puntal a cubierta principal (mts)</label>
                <input v-model.number="form.strut_to_main_deck" step="any" min="0" type="number" class="form-control">
            </div>
        </div>

        <div class="row">
            <div class="col form-group">
                <label>Capacidad estanque combustible (lts)</label>
                <input v-model.number="form.fuel_tank_capacity" step="any" min="0" type="number" class="form-control">
            </div>

            <div class="col form-group">
                <label>Capacidad estanque agua dulce (mts)</label>
                <input v-model.number="form.fresh_water_pond_capacity" step="any" min="0" type="number" class="form-control">
            </div>
        </div>

        <div class="row">
            <div class="col form-group">
                <label>Observaciones</label>
                <input v-model="form.observations" type="text" class="form-control">
            </div>
        </div>

        <h4 class="mt-4 mb-4">Propulsión</h4>

        <div class="row">
            <div class="col form-group">
                <label>Motor principal</label>
                <input v-model="form.main_engine" type="text" class="form-control">
            </div>

            <div class="col form-group">
                <label>Potencia</label>
                <input v-model="form.power" type="text" class="form-control">
            </div>
        </div>

        <div class="row">
            <div class="col form-group">
                <label>Serie motor</label>
                <input v-model="form.motor_series" type="text" class="form-control">
            </div>

            <div class="col form-group">
                <label>Caja de transmisión</label>
                <input v-model="form.transmission_box" type="text" class="form-control">
            </div>
        </div>

        <div class="row">
            <div class="col form-group">
                <label>Serie caja de transmisión</label>
                <input v-model="form.transmission_box_series" type="text" class="form-control">
            </div>

            <div class="col form-group">
                <label>Relación</label>
                <input v-model="form.relation" type="text" class="form-control">
            </div>
        </div>

        <div class="row">
            <div class="col form-group">
                <label>Velocidad de crucero (nudos)</label>
                <input v-model.number="form.cruising_speed" min="0" type="number" step="any" class="form-control">
            </div>

            <div class="col form-group">
                <label>Consumo de combustible (litros / hora)</label>
                <input v-model.number="form.fuel_consumption" min="0" type="number" step="any" class="form-control">
            </div>
        </div>

        <div class="row">
            <div class="col form-group">
                <label>Tipo de hélice</label>
                <input v-model="form.propeller_type" type="text" class="form-control">
            </div>
        </div>

        <button type="submit" class="btn btn-primary float-right" :disabled="submit">
            {{ submit ? 'Guardando' : 'Guardar' }}
        </button>
    </form>
</template>

<script>
    export default {
        created() {
            this.getSpecifications();
        },
        data () {
            return {
                form: {
                    shipowner: null,
                    plate_port: null,
                    trg: null,
                    bollard_pull: null,
                    light_displacement: null,
                    year_of_construction: null,
                    country: null,
                    shipyards: null,
                    total_length: null,
                    sleeve: null,
                    strut_to_main_deck: null,
                    fuel_tank_capacity: null,
                    fresh_water_pond_capacity: null,
                    observations: null,
                    main_engine: null,
                    power: null,
                    motor_series: null,
                    transmission_box: null,
                    transmission_box_series: null,
                    relation: null,
                    cruising_speed: null,
                    fuel_consumption: null,
                    propeller_type: null
                },
                submit: false
            }
        },
        methods: {
            getSpecifications () {
                this.$parent.loading = true;
                this.$Progress.start();

                axios.get(`/api/embarcaciones/${this.$route.params.id}/especificaciones-tecnicas`)
                    .then(response => {
                        if (response.data) this.form = response.data.data;
                        this.$Progress.finish();
                    })
                    .catch(() => {
                        this.$launchError('Error al obtener especificaciones técnicas');
                        this.$Progress.fail();
                    })
                    .finally(() => this.$parent.loading = false);
            },

            saveSpecifications () {
                this.$Progress.start();
                this.submit = true;

                axios.post(`/api/embarcaciones/${this.$route.params.id}/especificaciones-tecnicas`, this.form)
                    .then(() => {
                        this.$launchSuccess('Éxito al guardar especificaciones técnicas');
                        this.$Progress.finish();
                    })
                    .catch(() => {
                        this.$launchError('Error al guardar especificaciones técnicas');
                        this.$Progress.fail();
                    })
                    .finally(() => this.submit = false);
            }
        }
    }
</script>

<template>
    <!-- TODO: utiliza este id? -->
    <div id="edit-crew-container">
        <!-- breadcrumb -->
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Filtro tripulación</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link to="/">Inicio</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link to="/supervisor/tripulaciones">Tripulaciones</router-link>
                            </li>
                            <li class="breadcrumb-item active">Filtro tripulación</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>

        <!-- edición -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col">
                        <div class="card">
                            <div class="card-body">
                                <!-- información -->
                                <div class="row mb-2">
                                    <div class="col">
                                        <span><strong>Cliente:</strong> {{ crew.client.name }}.</span>
                                    </div>
                                    <div class="col">
                                        <span><strong>Embarcación:</strong> {{ crew.ship.name }}.</span>
                                    </div>
                                    <div class="col">
                                        <span><strong>Estado tripulación:</strong> {{ crew.status.name }}.</span>
                                    </div>
                                </div>

                                <div class="row mb-4">
                                    <div class="col">
                                        <span><strong>Fecha bajada real:</strong> {{ crew.real_download_date | myDate }}.</span>
                                    </div>
                                    <div class="col">
                                        <span><strong>Fecha bajada protocolar:</strong> {{ crew.download_date | myDate }}.</span>
                                    </div>
                                    <div class="col">
                                        <span><strong>Fecha presentanción:</strong> {{ crew.upload_date | myDate }}.</span>
                                    </div>
                                </div>

                                <!-- alerta -->
                                <div class="alert alert-warning" role="alert" v-if="canConfirm">
                                    Al desmarcar un tripulante, este no será incluído en la tripulación.
                                </div>

                                <!-- título -->
                                <h4 class="mb-0">Tripulantes</h4>

                                <!-- tabla de trabajadores -->
                                <div class="mt-3 mb-3">
                                    <div class="row" v-if="crew.workers.length === 0">
                                        <span class="text-center w-100">
                                            <i>Aún no se han asociado tripulantes a esta tripulación</i>
                                        </span>
                                    </div>
                                    <div v-else>
                                        <div class="table-responsive p-0">
                                            <table class="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th class="align-middle" v-if="canConfirm"></th>
                                                        <th class="align-middle" >Nombre</th>
                                                        <th class="align-middle" >Rut</th>
                                                        <th class="align-middle" >Rol</th>
                                                        <th class="align-middle" >Motivo filtro</th>
                                                        <th class="align-middle" >Acciones</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr :class="{ 'table-danger': w.filtered === 1 || w.worker.deleted_at!=null }"
                                                        v-for="w in orderedWorkers" >
                                                        <td class="align-middle" v-if="canConfirm">
                                                            <input type="checkbox" v-if="w.filtered === 0 && w.worker.deleted_at==null"
                                                                   :value="w.worker.id" v-model="selected">
                                                            <span v-else>-</span>
                                                        </td>
                                                        <td :class="{'stroked': !selected.includes(w.worker.id)}"
                                                            class="align-middle">
                                                            {{ w.worker.name }} {{ w.worker.last_name }}
                                                        </td>
                                                        <td :class="{'stroked': !selected.includes(w.worker.id)}"
                                                            class="align-middle">{{ w.worker.rut }}</td>
                                                        <td :class="{'stroked': !selected.includes(w.worker.id)}"
                                                            class="align-middle">
                                                            {{ w.worker.roles.map(r => r.name) | list }}
                                                        </td>
                                                        <td class="align-middle">
                                                            <span v-if="!canConfirm">
                                                                {{ w.filtered === 1 ? w.filter_reason: '-' }}
                                                            </span>
                                                            <input type="text" class="form-control" :readonly="!canConfirm"
                                                                   v-else-if="w.filtered === 0  && w.worker.deleted_at==null"
                                                                   v-model="reasons[w.worker.id]"
                                                                   :disabled="selected.includes(w.worker.id)">
                                                            <span v-else>
                                                                {{ w.filter_reason | dash }}
                                                            </span>
                                                        </td>
                                                        <td class="align-middle">
                                                            <router-link v-if="w.worker.deleted_at==null" :to="'/admin/trabajadores/' + w.worker.id">
                                                                <i class="fa fa-info-circle blue"></i>
                                                            </router-link>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <!--confirmar -->
                                <button type="button" class="btn btn-success float-right" @click="confirm"
                                        v-if="canConfirm">
                                    Confirmar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        computed: {
            id () {
                return this.$route.params.id;
            },
            canConfirm () {

                // TODO: considerar que todos los filtrados deban tener motivo

                return this.crew.status.name === 'Filtro tripulación';
            },
            orderedWorkers () {
                let notFiltered = [];
                let filtered = [];

                this.crew.workers.forEach(w => {
                    if (w.filtered === 1) filtered.push(w);
                    else notFiltered.push(w);
                })

                return notFiltered.concat(filtered);
            }
        },
        created () {
            this.getCrew();
        },
        data () {
            return {
                crew: {
                    client: { name: null },
                    ship: { capacity: 0 },
                    status: { name: null },
                    workers: []
                },
                reasons: {},
                selected: []
            }
        },
        methods: {
            confirm () {
                if (this.canConfirm) {
                    Swal.fire({
                        title: '¿Estás seguro que desea confirmar esta tripulación?',
                        text: 'No podrás revertir esta acción',
                        showCancelButton: true,
                        confirmButtonColor: '#d33',
                        cancelButtonColor: '#3085d6',
                        confirmButtonText: 'Si',
                        cancelButtonText: 'No'
                    }).then((result) => {
                        if (result.value) {
                            axios.post(`/api/supervisor/tripulaciones/${this.id}/filtro-tripulacion`, {
                                workers: this.crew.workers
                                    .filter(w => w.worker!=null && !this.selected.includes(w.worker.id) && w.filtered === 0 )
                                    .map(w => {
                                        return {
                                            id: w.worker.id,
                                            reason: this.reasons[w.worker.id]
                                        }
                                    })
                            })
                                .then(() => {
                                    Swal.fire(
                                        'Éxito',
                                        'La tripulación fue confirmada correctamente',
                                        'success'
                                    );

                                    this.selected = [];
                                    this.getCrew();
                                }).catch(() => Swal.fire(
                                'Error',
                                'No se pudo confimar la tripulación',
                                'warning'
                            ));
                        }
                    });
                }
            },

            getCrew () {
                this.$Progress.start();

                axios.get(`/api/supervisor/tripulaciones/${this.id}`)
                    .then(({ data }) => {
                        this.crew = data.data;
                        this.$Progress.finish();

                        // completa las selecciones y añade motivos al formulario
                        //console.log(data.data.workers.length);

                       // if(this.crew.workers.length>1){
                            this.selected = this.crew.workers.filter(w => w.filtered === 0 && w.worker!=null).map(w => {
                                this.reasons[w.worker.id] = '';
                                return w.worker.id;
                            });
                        /*}else{
                            this.reasons[this.crew.workers.worker.id] = null;
                            return this.crew.workers.worker.id;                    
                        }*/
                        

                    })
                    .catch(() => {
                        this.$launchError('Error al obtener datos de tripulación');
                        this.$Progress.fail();
                    });
            }
        }
    }
</script>

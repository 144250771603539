<template>
    <div>
        <!-- breadcrumb -->
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Facturar compra - Tripulación</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link to="/">Inicio</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link to="/bodega/compras">Compras</router-link>
                            </li>
                            <li class="breadcrumb-item active">Facturar compra</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>

        <!-- creación -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col">
                        <div class="card">
                            <loading v-if="loading"/>

                            <div class="card-body">
                                <!-- formulario -->
                          
                              
                        

                           <!-- -->
                               <div class="row d-flex align-items-center mb-3">
                                    <div class="col-10">
                                        <h4 class="mb-0">Facturas</h4>
                                    </div>

                                    <div class="col">
                                        <button class="btn btn-primary float-right" v-if="purchase.closed==0"
                                                
                                                @click="setInvoice(null)">
                                            Añadir
                                        </button>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col table-responsive p-0">

                                      
                                        <table class="table table-hover" v-if="quotations!=null && quotations.length > 0">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Código</th>
                                                     <th>Cuenta Bancaria</th>
                                                    <th>Nº Factura</th>
                                                    <th>Orden de Compra</th>
                                                     <th>Fecha Orden de Compra</th>
                                                    <th>Proveedor</th>
                                                    <th>Tipo Proveedor</th>
                                                

                                                    <th v-if="purchase.closed==0">Acciones</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(t, i) in quotations">
                                                    <td>{{ i + 1 }}</td>
                                                    <td>FB{{ t.invoice_id }}</td>
                                                    <td>{{ t.account_number }} - {{ t.account_name }}</td>
                                                    <td>{{ t.invoice_number }}</td>
                                                    <td>{{ t.purchase_order }}</td>
                                                    <td>{{ t.purchase_order_date }}</td>
                                                    <td>{{ t.supplier }}</td>
                                                    <td v-if="t.supplier_type_id==1">Alimentos</td>
                                                    <td v-else>Bodega</td>
                       
                                                  
                                                   
                                                    <td v-if="purchase.closed==0">
                                                        <a href="#" @click.prevent="setInvoice(t.invoice_id)"
                                                           v-tooltip="'Editar Factura'">
                                                            <i class="fa green fa-edit"/>
                                                        </a>
                                                    
                                                        <span >/</span>
                                                        <a href="#" @click.prevent="deleteInvoice(t.invoice_id,t.purchase_id,purchase.provisions.id)"
                                                           v-tooltip="'Eliminar factura'">
                                                            <i class="fa red fa-times"/>
                                                        </a>
                                                    </td>
                                                
                                                </tr>
                                            </tbody>
                                        </table>

                                        <p class="text-center mb-4" v-else>
                                            <i>No se han agregado facturas aún.</i>
                                        </p>
                                    </div>
                                </div>
                           <!-- -->

                                <!-- artículos -->
                                <div class="row d-flex align-items-center mb-3">
                                    <div class="col-10">
                                        <h4 class="mb-0">Viveres</h4>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col table-responsive p-0">
                                  <table class="table table-hover mb-4">
                                            <thead>
                                                <tr>
                                                    <th class="align-middle">Proveedor</th>
                                                    <th class="align-middle">Fecha Compra</th>
                                                    <th class="align-middle">Cantidad</th>
                                                    <th class="align-middle">Precio</th>
                                                    <th class="align-middle">Observacion</th>
                                                    <th class="align-middle">Facturar</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-if="purchase.provisions!=null">
                                                    <td class="align-middle">{{ purchase.provisions.supplier }}</td>
                                                    <td class="align-middle">{{ purchase.provisions.date | myDate }}</td>
                                                    <td class="align-middle">{{ purchase.provisions.workers_quantity }}</td>
                                                    <td class="align-middle">${{ purchase.provisions.price | dotNumber }}</td>
                                                    <td class="align-middle">{{ purchase.provisions.observation }}</td>
                                                      <td class="align-middle" v-if="purchase.closed==0">
                                                        <a href="#" @click.prevent="associateInvoice(purchase.provisions.id,purchase.provisions.supplier_id,purchase.provisions.invoice_id,'Viveres',purchase.provisions.workers_quantity,null,1)"
                                                           v-tooltip="'Asociar Factura'">
                                                            <i class="fa green fa-file-invoice" v-if="purchase.provisions.invoice_id!=null"/>
                                                            <i class="fa gray fa-file-invoice" v-else/>
                                                        </a>

                                                        <span v-if="purchase.provisions.invoice_id!=null" >/</span>
                                                        <a href="#" v-if="purchase.provisions.invoice_id!=null" @click.prevent="dessasociateInvoice(purchase.provisions.id,purchase.id,1)"
                                                           v-tooltip="'Quitar Factura'">
                                                             <i class="fa red fa-times"/>
                                                        </a>
                                                    </td>
                                                     <td class="align-middle" v-else>Facturado con FB{{purchase.provisions.invoice_id}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                  <div class="row d-flex align-items-center mb-3">
                                        <div class="col-10">
                                            <h4 class="mb-0">Articulos</h4>
                                        </div>
                                    </div>


                                <div class="row">
                                    <div class="col table-responsive p-0">
                                        <table class="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th class="align-middle">Nombre</th>
                                                    <th class="align-middle">Talla</th>
                                                    <th class="align-middle">Clasificación</th>
                                                    <th class="align-middle">Cantidad</th>
                                                    <th class="align-middle">Unidad de medida</th>
                                                    <th class="align-middle">Precio</th>
                                                    <th class="align-middle">Proveedor</th>
                                                    <th class="align-middle">Fecha Compra</th>
                                                    <th class="align-middle">Asociar Factura</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="i in purchase.warehouse_item_sizes">
                                                 
                                                    <td class="align-middle">{{ i.size.item.name }}</td>
                                                    <td class="align-middle">{{ i.size.name !== '' ? i.size.name : '-' }}</td>
                                                    <td class="align-middle">{{ i.size.item.classification.name }}</td>
                                                    <td class="align-middle">{{ i.quantity }}</td>
                                                    <td class="align-middle">{{ i.size.item.measurement_unit.name }}</td>
                                                    <td class="align-middle">
                                                        ${{ (i.price ? i.price : i.size.item.price) | dotNumber }}
                                                    </td>
                                                     <td class="align-middle">
                                                        {{ i.supplier_name }}
                                                    </td>
                                                     <td class="align-middle">
                                                        {{ i.purchase_date | myDate }}
                                                    </td>
                                                    <td class="align-middle" v-if="purchase.closed==0">

                                                          <a href="#" @click.prevent="associateInvoice(i.id,i.supplier_id,i.invoice_id,i.size.item.name,i.quantity,i.purchase_id,2)"
                                                           v-tooltip="'Asociar Factura'">
                                                            <i class="fa green fa-file-invoice" v-if="i.invoice_id!=null"/>
                                                            <i class="fa gray fa-file-invoice" v-else/>
                                                        </a>

                                                        <span v-if="i.invoice_id!=null" >/</span>
                                                        <a href="#" v-if="i.invoice_id!=null" @click.prevent="dessasociateInvoice(i.id,i.purchase_id,2)"
                                                           v-tooltip="'Quitar Factura'">
                                                             <i class="fa red fa-times"/>
                                                        </a>


                                                       
                                                    </td>
                                                    <td v-else class="align-middle" >Facturado con FB{{i.invoice_id}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>


                         
                          <button type="button"  v-if="purchase.quoted==1 && purchase.closed==0" class="btn btn-success float-right" @click="saveQuotate">
                                    Facturado
                                </button>

                        
                

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


                        <div class="modal fade" id="invoice-modal" tabindex="-1" role="dialog"
                              aria-labelledby="form-dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                            <invoice-modal-extra :invoice-id="selectedInvoice" :supplier-type="supplierType" v-if="showInvoiceModal"/>
                        </div>

                          <div class="modal fade" id="associate-invoice-modal" tabindex="-1" role="dialog"
                             aria-labelledby="form-dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                            <associate-invoice-extra :selected-type="selectedType" :selected-purchase="selectedPurchase" :selected-name="selectedName" :supplier-id="selectedSupplierId" :associate-id="selectedAssociate" :associate-selected-id="selectedAssociateId"  v-if="showAssociateInvoiceModal"/>
                           </div>

    </div>
</template>

<script>
    import Loading from "../Common/Loading";
    import InvoiceModalExtra from "./InvoiceModalExtra";
     import AssociateInvoiceExtra from "./AssociateInvoiceExtra";
     import eventHub from "../../eventHub";

    export default {
        components: { Loading,InvoiceModalExtra,AssociateInvoiceExtra },
        computed: {
            hasInvoice () {
                return this.purchase.invoice !== null;
            },
            id () {
                return this.$route.params.id;
            }
        },
        created () {
            this.getPurchase();
            this.getInvoices();

            eventHub.$on('reloadArticles', () => {
                $('#associate-invoice-modal').modal('hide');
                 this.$nextTick(() => this.getPurchase());
            });

             eventHub.$on('reloadInvoice', () => {
                $('#invoice-modal').modal('hide');
                 this.$nextTick(() => this.getInvoices());
            });

              this.$nextTick(() => $('#associate-invoice-modal').on('hidden.bs.modal', () => {
                    this.selectedAssociate= null;
                    this.selectedAssociateId= null;
                    this.selectedSupplierId= null;
                    this.selectedName = null;
                    this.selectedPurchase = null;
                    this.selectedType = null;
                    this.showAssociateInvoiceModal = false;      
            }));

             this.$nextTick(() => $('#invoice-modal').on('hidden.bs.modal', () => {
                    this.selectedInvoice= null;
                    this.showInvoiceModal = false;
                   
            }));
        },
        data () {
            return {
                quotations:[],
                selectedInvoice:null,
                showInvoiceModal: false,
                supplierType:2,
                bankAccounts: [],
                selectedAssociate:null,
                selectedAssociateId:null,
                selectedSupplierId:null,
                selectedName:null,
                selectedPurchase:null,
                selectedType:null,
                showAssociateInvoiceModal:false,
                form: new Form({
                    bank_account_id: null,
                    bill_number: null,
                    purchase_order: null,
                    purchase_order_date: null
                }),
                loading: true,
                purchase: {
                    invoice: {
                        bank_account: {
                            account_name: null,
                            account_number: null
                        },
                        bill_number: null,
                        created_at: null,
                        purchase_order: null,
                        purchase_order_date: null
                    },
                    warehouse_item_sizes: [],
                    provisions:null
                }
            }
        },
        methods: {
            deleteInvoice(invoice_id,purchase_id,provisions_id){
                    Swal.fire({
                                    title: '¿Estás seguro que deseas eliminar esta factura?',
                                    text: 'Puedes agergar otra mas tarte',
                                    showCancelButton: true,
                                    confirmButtonColor: '#d33',
                                    cancelButtonColor: '#3085d6',
                                    confirmButtonText: 'Si',
                                    cancelButtonText: 'No'
                                }).then((result) => {
                                    if (result.value) {
                                        this.form.purchase_id = purchase_id;
                                        this.form.invoice_id = invoice_id;
                                        this.form.provisions_id = provisions_id;
                                        console.log(this.form);
                                        this.form.post('/api/administracion/facturacion/compra-tripulacion/elimina-factura')
                                        .then(() => {
                                           this.getInvoices();
                                            this.getPurchase();
                                            this.$launchSuccess('Se elimino la factura correctamente');
                                            this.$Progress.finish();
                                        })
                                        .catch(() => {
                                            this.$Progress.fail();
                                            this.$launchError('Error al eliminar factura');
                                        });
                                        }
                                });   
            },
            dessasociateInvoice(id,purchase_id,type){
                 Swal.fire({
                                    title: '¿Estás seguro que deseas quitar esta factura?',
                                    text: 'Puedes agergar otra mas tarte',
                                    showCancelButton: true,
                                    confirmButtonColor: '#d33',
                                    cancelButtonColor: '#3085d6',
                                    confirmButtonText: 'Si',
                                    cancelButtonText: 'No'
                                }).then((result) => {
                                    if (result.value) {
                                        this.form.id = id;
                                        this.form.purchase_id = purchase_id;
                                        this.form.type = type;
                                        console.log(this.form);
                                        this.form.post('/api/administracion/facturacion/compra-tripulacion/quitar-factura')
                                        .then(() => {
                                           this.getPurchase();
                                            this.$launchSuccess('Se guardo la factura correctamente');
                                            this.$Progress.finish();
                                        })
                                        .catch(() => {
                                            this.$Progress.fail();
                                            this.$launchError('Error al guardar factura');
                                        });
                                        }
                                });   
            },
             saveQuotate(){
                 Swal.fire({
                                    title: '¿Estás seguro que deseas poner esta compra como facturada?',
                                    text: 'Se quitará de la lista',
                                    showCancelButton: true,
                                    confirmButtonColor: '#d33',
                                    cancelButtonColor: '#3085d6',
                                    confirmButtonText: 'Si',
                                    cancelButtonText: 'No'
                                }).then((result) => {
                                    if (result.value) {
                                        this.form.purchase_id = this.id;
                                      
                                        this.form.post('/api/administracion/facturacion/compra-tripulacion/facturado')
                                        .then(() => {
                                           this.getPurchase();
                                            this.$launchSuccess('Se guardo la factura correctamente');
                                            this.$Progress.finish();
                                        })
                                        .catch(() => {
                                            this.$Progress.fail();
                                            this.$launchError('Error al guardar factura');
                                        });
                                        }
                                });   
            },
            associateInvoice(id,idsupplier,invoice_id,name,quantity,purchase,tipo){
                console.log(id);
                    this.selectedAssociate= id;
                    this.selectedAssociateId= invoice_id;
                    this.selectedSupplierId= idsupplier;
                    this.selectedName = name+" x "+quantity;
                    this.selectedPurchase = this.id;
                    this.selectedType = tipo;
                    this.showAssociateInvoiceModal = true;
                    this.$nextTick(() => $('#associate-invoice-modal').modal('show'));
            },
            setInvoice(id=null){
                    this.selectedInvoice= id;
                    this.showInvoiceModal = true;
                    this.supplierType = 2;
                    this.$nextTick(() => $('#invoice-modal').modal('show'));
            },
            getInvoices () {
                this.loading = true;
                this.$Progress.start();

                axios.get(`/api/administracion/facturacion/facturas-compra/${this.id}`)
                    .then(({ data }) => {
                        this.quotations = data;
                        this.$Progress.finish();
                        // genera el "formulario" de observaciones solo si no tiene datos de antemano       
                    })
                    .catch(() => {
                        this.$launchError('Error al obtener información de cotización');
                        this.$Progress.fail();
                    })
                    .finally(() => this.loading = false);
            },
            getPurchase () {
                this.$Progress.start();

                axios.get('/api/bodega/compras-tripulacion/' + this.id)
                    .then(({ data }) => {
                        this.purchase = data.data;
                        this.$Progress.finish();
                        
                    })
                    .catch(() => {
                        this.$Progress.fail();
                        this.$launchError('Error al obtener compra');
                    })
                    .finally(() => this.loading = false)
            }
        }
    }
</script>

<template>
<v-app>
  <section class="content">
      <div class="container-fluid">
          <div class="row">
              <div class="col-12">
                  <div class="card">
                      <loading v-if="loading"/>

                      <div class="card-header">
                          <h3 class="card-title">Listado de usuarios</h3>

                          <div class="card-tools">

                              <button type="button" class="btn btn-sm btn-primary" @click="creationModal">
                                  <i class="fa fa-plus-square"></i>
                                  Nuevo usuario
                              </button>
                          </div>
                      </div>
<v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Búsqueda"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
  <v-data-table
      :headers="headers"
      :items="datos"
      :search="search"
       :loading="loading"
       :items-per-page="30"
       no-results-text="No se han encontrado usuarios"
       no-data-text="No existen usuarios"
      class="elevation-1"
        loading-text="Cargando... Espere"
         :footer-props="{
           'items-per-page-text':'Usuarios por página',
           'items-per-page-options': [20, 50, 100, -1]
      }"
    >
 <template v-slot:item.accion="{ item, index }">

                                              <router-link :to="'/admin/usuarios/' + item.id">
                                               <v-icon small color="green">
                                             fa fa-info-circle
                                                </v-icon>
                                          </router-link>
                                          /
                                          <router-link :to="`/admin/usuarios/${item.id}/editar`">
                                              <v-icon small style="color: #3490DC !important;">
                                            fa fa-edit
                                                </v-icon>
                                          </router-link>
                                          /
                                          <a href="#" @click="deleteUser(item.id)">
                                               <v-icon small color="red">
                                            fa fa-trash
                                                </v-icon>
                                          </a>

                                                                                             
  </template>
  <template v-slot:footer.page-text="items"> {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }} </template>
</v-data-table>
</v-card> 
                    <!--
                      <div class="card-body table-responsive p-0">
                          <table class="table table-hover">
                              <thead>
                                  <tr>
                                      <th class="align-middle">Nombre</th>
                                      <th class="align-middle">Código</th>
                                      <th class="align-middle">Rut</th>
                                      <th class="align-middle">Correo</th>
                                      <th class="align-middle">N° telefónico</th>
                                      <th class="align-middle">Cargo</th>
                                      <th class="align-middle">Acciones</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr v-for="u in users.data" :key="u.id">
                                      <td class="text-capitalize align-middle">
                                          {{ u.name }} {{ u.last_name }}
                                      </td>
                                      <td class="align-middle">{{ u.code }}</td>
                                      <td class="align-middle">{{ u.rut }}</td>
                                      <td class="align-middle">{{ u.email }}</td>
                                      <td class="align-middle">{{ u.phone_number }}</td>
                                      <td class="align-middle">{{ u.position.name }}</td>
                                      <td class="align-middle">
                                          <router-link :to="'/admin/usuarios/' + u.id">
                                              <i class="fa fa-info-circle green"></i>
                                          </router-link>
                                          /
                                          <router-link :to="`/admin/usuarios/${u.id}/editar`">
                                              <i class="fa fa-edit blue"></i>
                                          </router-link>
                                          /
                                          <a href="#" @click="deleteUser(u.id)">
                                              <i class="fa fa-trash red"></i>
                                          </a>
                                      </td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>

                      <div class="card-footer">
                          <pagination :data="users" @pagination-change-page="getUsers"/>
                      </div>
                      -->
                  </div>
              </div>
          </div>

          <!-- modal -->
          <!-- se usa v-if para evitar carga extra de datos -->
          <div class="modal fade" id="form-modal" tabindex="-1" role="dialog" aria-labelledby="form-dialog"
               aria-hidden="true" v-if="showModal" data-backdrop="static" data-keyboard="false">
              <div class="modal-dialog" role="document">
                  <div class="modal-content">
                      <div class="modal-header">
                          <h5 class="modal-title">Crear nuevo usuario</h5>
                          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                          </button>
                      </div>

                      <form @submit.prevent="createUser()">
                          <div class="modal-body container">
                              <user-form :form="form"/>
                          </div>

                          <div class="modal-footer">
                              <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                              <button type="submit" class="btn btn-primary">Crear</button>
                          </div>
                      </form>
                  </div>
              </div>
          </div>
      </div>
  </section>
  </v-app>
</template>

<script>
    import Loading from "./Common/Loading";
    import UserForm from "./Users/Form";

    export default {
        components: { Loading, UserForm },
        created() {
            this.$Progress.start();
            this.getUsers();
            this.$Progress.finish();
        },
        data () {
            return {
                form: new Form({
                    name: null,
                    last_name: null,
                    code: null,
                    rut: null,
                    birthdate: null,
                    email: null,
                    address: null,
                    phone_number: null,
                    position_id: null,
                    region_id: null,
                    commune_id: null,
                    marital_status_id: null
                }),
                loading: true,
                showModal: false,
                users : {},
                search:'',
                headers: [
                {
                    text: 'Nombre',
                    align: 'start',
                    value: 'nombre',
                },
                { text: 'Código', value: 'codigo' },
                { text: 'Rut', value: 'rut' },
                { text: 'Correo', value: 'correo' },
                { text: 'N° telefónico', value: 'telefono' },
                { text: 'Cargo', value: 'cargo' },
                { text: 'Acciones', value: 'accion', sortable: false },
                ],
                datos:[],
            }
        },
        methods: {

             validaRut: function(rutCompleto) {
                rutCompleto = rutCompleto.replace("‐","-");
                if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test( rutCompleto ))
                    return false;
                var tmp     = rutCompleto.split('-');
                var digv    = tmp[1];
                var rut     = tmp[0];
                if ( digv == 'K' ) digv = 'k' ;

                return (this.dv(rut) == digv );
            },
            dv : function(T){
                var M=0,S=1;
                for(;T;T=Math.floor(T/10))
                    S=(S+T%10*(9-M++%6))%11;
                return S?S-1:'k';
            },
            getUsers (page = 1) {
                this.$Progress.start();

                axios.get('/api/usuarios')
                    /*.then(({ data }) => {
                        this.users = data.data;
                        this.$Progress.finish();
                    })*/
                    .then(data => {
                    this.datos = [];
                    this.users = data.data;
                     console.log(this.users);
                        for(let i = 0; i < this.users.data.length; i++) {
                            var t = this.users.data[i];
                            var nombre = this.$options.filters.capitalize(t.name)+" "+this.$options.filters.capitalize(t.last_name );  
                            this.datos.push({nombre:nombre,codigo:t.code,rut:t.rut,correo:t.email,telefono:t.phone_number,cargo:t.position.name,id:t.id,accion:true});
                        }
       
                       console.log(this.datos);
                       this.$Progress.finish();
                     })
                    .catch(() => {
                        this.$launchError('Error al obtener listado de usuarios');
                        this.$Progress.fail();
                    })
                    .finally(() => this.loading = false);
            },

            creationModal () {
                this.form.reset();
                this.showModal = true;
                this.$nextTick(() => $('#form-modal').modal('show'));
            },

            createUser () {
                if(this.validaRut(this.form.rut)){
                    this.$Progress.start();

                this.form.post('/api/usuarios')
                    .then(() => {
                        $('#form-modal').modal('hide');
                        this.$launchSuccess('El usuario fue creado correctamente');
                        this.$Progress.finish();
                        this.getUsers();
                    })
                    .catch(() => {
                        this.$Progress.fail();
                        this.$launchError('Error al crear usuario')
                    });
                }else{
                    this.$launchError('Rut no válido');
                }

            },

            deleteUser (id) {
                Swal.fire({
                    title: '¿Estás seguro que deseas eliminar el usuario?',
                    text: 'No podrás revertir esta acción',
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Si',
                    cancelButtonText: 'No'
                }).then((result) => {
                    if (result.value) {
                        this.form.delete('/api/usuarios/' + id).then(() => {
                            Swal.fire(
                                'Éxito',
                                'El usuario fue eliminado correctamente',
                                'success'
                            );

                            this.getUsers();
                        }).catch(() => Swal.fire(
                            'Error',
                            'Error al eliminar usuario',
                            'warning'
                        ));
                    }
                });
            }
        }
    }
</script>

<template>
 <v-app>
    <div>
        <!-- breadcrumb -->
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Nuevo Traslado</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link to="/">Inicio</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link to="/">administracion</router-link>
                            </li>
                            <li class="breadcrumb-item active">Nuevo Traslado</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>

        <!-- creación -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col">
                        <div class="card">
                            <loading v-if="loading"/>

                            <div class="card-body">
                                <!-- formulario -->
                                

                                <form class="row mb-3">

                                    <div class="col form-group">
                                        <div class="col form-group">
                                            <label>Fecha Recogida</label>
                                            <input type="date" class="form-control" v-model="form.upload_date">
                                        </div>
                                    </div>

                                    <div class="col form-group">
                                       <div class="col form-group">
                                            <label>Ingreso</label>
                                            <input type="text" class="form-control" v-model="form.entry_text">
                                        </div>
                                    </div>

                                    <div class="col form-group">
                                        <div class="col form-group">
                                            <label>Salida</label>
                                            <input type="text" class="form-control" v-model="form.exit_text">
                                        </div>
                                    </div>

                                     <div class="col form-group">
                                         <div class="col form-group">
                                            <label>Precio Total</label>
                                            <input type="number" class="form-control" min='0' v-model="form.price">
                                        </div>
                                    </div>

                                    <div class="col form-group">
                                         <div class="col form-group">
                                            <label>Proveedor</label>
                                            <select class="form-control" v-model="form.supplier_id"
                                                    :disabled="suppliers.length === 0">
                                                <option :value="null" disabled>Escoja un proveedor</option>
                                                <option :value="i.id" v-for="i in suppliers">{{ i.name }}</option>
                                            </select>
                                        </div>
                                    </div>

                                     <div class="col form-group">
                                         <div class="col form-group">
                                            <label>Observacion</label>
                                            <input type="text" class="form-control" v-model="form.observation">
                                        </div>
                                    </div>

                                    <div class="col form-group">
                                         <div class="col form-group">
                                             <input id="nofacturar" type="checkbox" v-model="form.no_facturar" style="margin-top:40px !important;">
                                            <label for="nofacturar">Facturar</label>
                                        </div>
                                    </div>

                                
                                  

                                </form>
                                 <div class="col mt-4">
                                 <button class="btn btn-primary float-left" @click="personModal()">
                                            Añadir Persona
                                </button>
                                 <button class="btn btn-primary float-left" style="margin-left:20px !important;" @click="workerModal()">
                                            Añadir Tripulante
                                </button>
                                </div>
                                
                                <div class="col mt-4" >
                                
                                </div>



                                 <div class="mt-4">
                                    <div class="row" v-if="selectedData.length === 0 && selectedDataWorkers.length === 0">
                                        <span class="text-center w-100">
                                            <i>Aún no se han asociado personas o tripulantes a este traslado</i>
                                        </span>
                                    </div>
                                    <div v-else>
                                        <div class="table-responsive p-0">
                                            <table class="table table-hover"  style="margin-top:30px !important;">
                                            <thead>
                                                <tr>
                                                    <th class="align-middle">Nombre</th>
                                                    <th class="align-middle">Apellido</th>
                                                    <th class="align-middle">Rut</th>
                                                    <th class="align-middle">Tipo</th>
                                                    <th class="align-middle">Quitar</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="i in selectedData">
                                                    <td class="align-middle">{{ i.name }}</td>
                                                    <td class="align-middle">{{ i.last_name }}</td>
                                                    <td class="align-middle">{{ i.rut }}</td>
                                                    <td class="align-middle">Normal</td>
                                                    <td class="align-middle">
                                                         <a href="#"  @click="unselect(i.id)"
                                                            v-tooltip="'Eliminar persona'">
                                                             <v-icon small color="red">
                                                                fas fa-trash
                                                                </v-icon>
                                                        </a>
                                                    </td>
                                                    
                                                </tr>

                                                <tr v-for="i in selectedDataWorkers">
                                                    <td class="align-middle">{{ i.name }}</td>
                                                    <td class="align-middle">{{ i.last_name }}</td>
                                                    <td class="align-middle">{{ i.rut }}</td>
                                                    <td class="align-middle">Tripulante</td>
                                                    <td class="align-middle">
                                                         <a href="#"  @click="unselectWorkers(i.id)"
                                                            v-tooltip="'Eliminar Tripulante'">
                                                        
                                                             <v-icon small color="red">
                                                                fas fa-trash
                                                                </v-icon>
                                                        </a>
                                                    </td>
                                                    
                                                </tr>
                                            </tbody>
                                        </table>

                                        </div>
                                    </div>
                                </div>

                                 <div class="col mt-4">
                                        <button type="button" class="btn btn-success float-right" @click="setTransfer()">
                                            Guardar Traslado
                                        </button>
                                    </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>




        <!-- inicio modal-->

         <div class="modal fade" id="form-modal-person" tabindex="-1" role="dialog" aria-labelledby="form-dialog"
               aria-hidden="true" v-if="showModal" data-backdrop="static" data-keyboard="false">
             
        


     <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <!-- header -->
            <div class="modal-header align-items-center">
                <h5 class="modal-title">Añadir Personas</h5>

                <!--<form class="ml-auto w-50" @submit.prevent="getPersons">
                    <input type="text" v-model="searchInput" class="form-control"
                           placeholder="Ingrese nombre, apellido o rut de persona">
                </form>-->

                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <!-- body -->
            <div class="modal-body container">
        
                <!-- tabla -->
                <div class="table-responsive">

<v-card >
    <v-card-title>
      <v-text-field
        v-model="searchPersons"
        append-icon="mdi-magnify"
        label="Búsqueda"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
  <v-data-table 
      :headers="headers"
      :items="datosPersons"
      :search="searchPersons"
       :loading="loading"
       :items-per-page="2"
       no-results-text="No se han encontrado personas"
       no-data-text="No existen personas"
      class="elevation-1"
        loading-text="Cargando... Espere"
         :footer-props="{
           'items-per-page-text':'Personas por página',
           'items-per-page-options': [5,10,15]
      }"
    >
     <template v-slot:item.accion="{ item, index }">
           <input type="checkbox" v-model="selected" :value="item.id"
                                           @change="toggleSelection(item.id)">      
  </template>
  <template v-slot:footer.page-text="items"> {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }} </template>
</v-data-table>
</v-card>


                    <!--
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Nombre</th>
                                <th>Apellido</th>
                                <th>Rut</th>
                            </tr>
                        </thead>
                        <tbody>
                        
                            <tr v-for="w in persons.data">
                                <td class="align-middle">
                                    <input type="checkbox" v-model="selected" :value="w.id"
                                           @change="toggleSelection(w.id)">
                                </td>
                                <td class="align-middle">
                                    <span>
                                        {{ w.name }} 
                                    </span>
                                </td>
                                 <td class="align-middle">
                                    <span>
                                       {{ w.last_name }}
                                    </span>
                                </td>
                                <td class="align-middle">{{ w.rut }}</td>
                            </tr>
                        </tbody>
                    </table>

                 <pagination :data="persons" @pagination-change-page="getPersons"/>-->
                </div>

                <!-- selección -->
                <div class="tag-input" v-if="selectedData.length > 0">
                    <div v-for="s in selectedData" class="tag-input__tag">
                        <span @click="unselect(s.id)">x</span>
                        {{ s.name }} {{s.last_name}}
                    </div>
                </div>
                <span v-else><i>Aún no se han escogido personas</i></span>

                <!-- footer -->
                <div class="modal-footer">
                   
                    <button type="button" class="btn btn-primary" data-dismiss="modal">
                        Aceptar
                    </button>
                </div>
            </div>
        </div>
    </div>
      </div>
        <!-- fin modal -->

        


        <!--modal tripulantes-->

         <div class="modal fade" id="form-modal-worker" tabindex="-1" role="dialog" aria-labelledby="form-dialog"
               aria-hidden="true" v-if="showModal" data-backdrop="static" data-keyboard="false">
             
        


     <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <!-- header -->
            <div class="modal-header align-items-center">
                <h5 class="modal-title">Añadir Tripulantes</h5>

                <!--<form class="ml-auto w-50" @submit.prevent="getWorkers">
                    <input type="text" v-model="searchInputWorker" class="form-control"
                           placeholder="Ingrese nombre, apellido o rut de persona">
                </form>-->

                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <!-- body -->
            <div class="modal-body container">
        
                <!-- tabla -->
                <div class="table-responsive p-0">
<v-card >
    <v-card-title>
      <v-text-field
        v-model="searchWorkers"
        append-icon="mdi-magnify"
        label="Búsqueda"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
  <v-data-table 
      :headers="headers"
      :items="datosWorkers"
      :search="searchWorkers"
       :loading="loading"
       :items-per-page="2"
       no-results-text="No se han encontrado tripulantes"
       no-data-text="No existen tripulantes"
      class="elevation-1"
        loading-text="Cargando... Espere"
         :footer-props="{
           'items-per-page-text':'Tripulantes por página',
           'items-per-page-options': [10,15,20]
      }"
    >
     <template v-slot:item.accion="{ item, index }">
           <input type="checkbox" v-model="selectedWorkers" :value="item.id"
                                           @change="toggleSelectionWorkers(item.id)">      
  </template>
  <template v-slot:footer.page-text="items"> {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }} </template>
</v-data-table>
</v-card>

                    <!--<table class="table table-hover">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Nombre</th>
                                 <th>Apellido</th>
                                <th>Rut</th>
                            </tr>
                        </thead>
                        <tbody>
                    
                            <tr v-for="w in workers.data">
                                <td class="align-middle">
                                    <input type="checkbox" v-model="selectedWorkers" :value="w.id"
                                           @change="toggleSelectionWorkers(w.id)">
                                </td>
                                <td class="align-middle">
                                    <span>
                                        {{ w.name }} 
                                    </span>
                                </td>
                                 <td class="align-middle">
                                    <span>
                                       {{ w.last_name }}
                                    </span>
                                </td>
                                <td class="align-middle">{{ w.rut }}</td>
                            </tr>
                        </tbody>
                    </table>

                 <pagination :data="workers" @pagination-change-page="getWorkers"/>-->
                </div>

                <!-- selección -->
                <div class="tag-input" v-if="selectedDataWorkers.length > 0">
                    <div v-for="s in selectedDataWorkers" class="tag-input__tag">
                        <span @click="unselectWorkers(s.id)">x</span>
                        {{ s.name }} {{s.last_name}}
                    </div>
                </div>
                <span v-else><i>Aún no se han escogido tripulantes</i></span>

                <!-- footer -->
                <div class="modal-footer">
                   
                    <button type="button" class="btn btn-primary" data-dismiss="modal">
                        Aceptar
                    </button>
                </div>
            </div>
        </div>
    </div>
      </div>
        <!--fin modal tripulantes--->
    </div>
    </v-app>
</template>

<script>
    import Loading from "./Common/Loading";
    import moment from "moment";

    export default {
        components: { Loading },
        created () {
            this.getSuppliers();
           // this.form.induction_date = moment(new Date()).format('yyyy-MM-dd');
        },
        data () {
            return {
                loading: false,
                submit: false,
                suppliers:[],
                persons:{},
                workers:{},
                selected: [],
                selectedWorkers: [],
                selectedData: [],
                selectedDataWorkers: [],
                searchInput:'',
                searchInputWorker:'',
                showModal: false,
                form: new Form({
                    upload_date: '',
                    entry_text: '',
                    exit_text: '',
                    price:'',
                    supplier_id: null,
                    observation:'',
                    no_facturar:false,
                    id:null,
                    selected:[],
                    selectedWorkers:[],
                    isedit:0
                }),
                searchWorkers:'',
                searchPersons:'',
                headers: [
                { text: 'Seleccionar', value: 'accion', sortable: false },
                { text: 'Nombre', align: 'start', value: 'nombre' },
                { text: 'Apellido', value: 'apellido' },
                { text: 'Rut', value: 'rut' },
                ],
                datosWorkers:[],
                datosPersons:[],        
            }
        },
        methods: {
            toggleSelection (id) {
                if (this.selected.includes(id)) {
                    let w = this.persons.data.find(w => w.id === id);

                    this.selectedData.push({
                        id: id,
                        rut: w.rut,
                        last_name: this.$options.filters.capitalize(w.last_name),
                        name: this.$options.filters.capitalize(w.name)
                    });
                } else {
                    this.selectedData.splice(this.selectedData.findIndex(s => s.id === id), 1);
                }
            },
            toggleSelectionWorkers (id) {
                if (this.selectedWorkers.includes(id)) {
                    let w = this.workers.data.find(w => w.id === id);

                    this.selectedDataWorkers.push({
                        id: id,
                        rut: w.rut,
                        last_name: this.$options.filters.capitalize(w.last_name),
                        name: this.$options.filters.capitalize(w.name)
                    });
                } else {
                    this.selectedDataWorkers.splice(this.selectedDataWorkers.findIndex(s => s.id === id), 1);
                }
            },
            personModal () {
                this.getPersons();
                this.showModal = true;
                this.$nextTick(() => $('#form-modal-person').modal('show'));
            },
            workerModal () {
                this.getWorkers();
                this.showModal = true;
                this.$nextTick(() => $('#form-modal-worker').modal('show'));
            },
              setTransfer(){
                   let many = this.selectedDataWorkers.length + this.selectedData.length;
                  if(many==0){
                        this.$launchError('No puede guardar si no ha agregado personas o tripulantes');
                  }else{
                            if(this.form.supplier_id==null || this.form.upload_date=='' || this.form.price=='') {
                                this.$launchError('Error, debe completar todos los datos y agregar personas');
                            }else{
                                Swal.fire({
                                        title: '¿Estás seguro que deseas guardar este traslado?',
                                        text: 'No podrás revertir esta acción',
                                        showCancelButton: true,
                                        confirmButtonColor: '#d33',
                                        cancelButtonColor: '#3085d6',
                                        confirmButtonText: 'Si',
                                        cancelButtonText: 'No'
                                    }).then((result) => {
                                        if (result.value) {
                                            this.form.selected = this.selectedData;
                                            this.form.selectedWorkers = this.selectedDataWorkers;
                                            this.form.post('/api/administracion/personas/nuevo/traslado')
                                            .then(() => {
                                                this.form.reset();
                                                this.persons = [];
                                                this.selectedData= [];
                                                this.selected = [];
                                                this.workers = [];
                                                this.selectedDataWorkers= [];
                                                this.selectedWorkers = [];
                                                this.$launchSuccess('Se guardo el traslado correctamente');
                                                this.$Progress.finish();
                                            })
                                            .catch(() => {
                                                this.$Progress.fail();
                                                this.$launchError('Error al guardar traslado');
                                            });
                                            }
                                    });   
                            } 
                  }
                  
            },
            unselect (id) {
                this.selected.splice(this.selected.findIndex(s => s === id), 1);
                this.selectedData.splice(this.selectedData.findIndex(s => s.id === id), 1);
            },
             unselectWorkers (id) {
                this.selectedWorkers.splice(this.selectedWorkers.findIndex(s => s === id), 1);
                this.selectedDataWorkers.splice(this.selectedDataWorkers.findIndex(s => s.id === id), 1);
            },
            getPersons (page = 1) {
                this.$Progress.start();
                this.loading = true;

                axios.get('/api/administracion/personas')
                    //.then(({ data }) => this.persons = data.data)
                    .then(data => {
                    this.datosPersons = [];
                    this.persons = data.data;
                     console.log(this.persons);
                        for(let i = 0; i < this.persons.data.length; i++) {
                            var t = this.persons.data[i];
                            var nombre = this.$options.filters.capitalize(t.name);  
                            var apellido = this.$options.filters.capitalize(t.last_name);  
                            this.datosPersons.push({nombre:nombre,apellido:apellido,rut:t.rut,id:t.id,accion:true});
                        }
       
                       console.log(this.datosPersons);
                       this.$Progress.finish();
                     })
                    .catch(() => this.$launchError('Error al obtener listado de personas'))
                    .finally(() => {
                        this.$Progress.finish();
                        this.loading = false;
                    });
                   
            },
            getWorkers (page = 1) {
                this.$Progress.start();
                this.loading = true;

                axios.get('/api/administracion/workers')
                    //.then(({ data }) => this.workers = data.data)
                    .then(data => {
                    this.datosWorkers = [];
                    this.workers = data.data;
                     console.log(this.workers);
                        for(let i = 0; i < this.workers.data.length; i++) {
                            var t = this.workers.data[i];
                            var nombre = this.$options.filters.capitalize(t.name);  
                            var apellido = this.$options.filters.capitalize(t.last_name);  
                            this.datosWorkers.push({nombre:nombre,apellido:apellido,rut:t.rut,id:t.id,accion:true});
                        }
       
                       console.log(this.datosWorkers);
                       this.$Progress.finish();
                     })
                    .catch(() => this.$launchError('Error al obtener listado de tripulantes'))
                    .finally(() => {
                        this.$Progress.finish();
                        this.loading = false;
                    });
                   
            },
            getSuppliers () {
                this.loading = true;
                this.$Progress.start();

                axios.get('/api/bodega/proveedores/traslado')
                    .then(({ data }) => this.suppliers = data.data)
                    .catch(() => {
                        this.$launchError('Error al obtener listado de proveedores');
                        this.$Progress.fail();
                    })
                    .finally(() => {
                        this.loading = false;
                        this.$Progress.finish();
                    });
            },
        }
    }
</script>
<!--<style>
  .v-application--wrap {
    min-height: 0vh !important;
  }
</style>-->

<style scoped>
.tag-input {
    width: 100%;
    border: 1px solid #eee;
    font-size: 0.9em;
    height: 50px;
    box-sizing: border-box;
    padding: 0 10px;
}

.tag-input__tag {
    height: 30px;
    float: left;
    margin-right: 10px;
    background-color: #eee;
    margin-top: 10px;
    line-height: 30px;
    padding: 0 5px;
    border-radius: 5px;
}

.tag-input__tag > span {
    cursor: pointer;
    opacity: 0.75;
}
</style>

<template>
 <v-app>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <loading v-if="loading"/>

                        <!-- header -->
                        <div class="card-header">
                            <h3 class="card-title">Listado de compras</h3>
                        </div>


                     <v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Búsqueda"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
  <v-data-table
      :headers="headers"
      :items="datos"
      :search="search"
       :loading="loading"
       :items-per-page="30"
       no-results-text="No se han encontrado compras"
       no-data-text="No existen compras"
      class="elevation-1"
        loading-text="Cargando... Espere"
         :footer-props="{
           'items-per-page-text':'Compras por página',
           'items-per-page-options': [20, 50, 100, -1]
      }"
    >
     <template v-slot:item.estado="{ item, index }">

                            
                                            <span class="badge" :class="{
                                                'bg-success': item.status,
                                                'bg-danger': item.status === 0,
                                                'bg-warning': item.status === null
                                            }">
                                                {{ item.status === null ? 'No validada': item.status === 1 ? 'Validada': 'Rechazada' }}
                                            </span>
                                                      
  </template>
 <template v-slot:item.accion="{ item, index }">

                            

                                             <router-link :to="`/operaciones/compras/${item.id}`"
                                                         v-tooltip="'Ver detalles'">
                                       
                                                     <v-icon small color="green">
                                             fas fa-info-circle
                                                </v-icon>
                                            </router-link>
                                                      
  </template>
  <template v-slot:footer.page-text="items"> {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }} </template>
</v-data-table>
</v-card> 

                        <!-- 
                        <div class="card-body table-responsive p-0">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th class="align-middle">Proveedor</th>
                                        <th class="align-middle">Sol. abastecimiento</th>
                                        <th class="align-middle">Fecha compra</th>
                                        <th class="align-middle">Fecha confirmación</th>
                                        <th class="align-middle">Estado</th>
                                        <th class="align-middle">Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="p in purchases.data">
                                        <td class="align-middle">{{ p.supplier.name }}</td>
                                        <td class="align-middle">{{ p.supply_request ? 'Si': 'No' }}</td>
                                        <td class="align-middle">{{ p.purchase | myDate }}</td>
                                        <td class="align-middle">{{ p.confirmed_at | myDate }}</td>
                                        <td class="align-middle">
                                            <span class="badge" :class="{
                                                'bg-success': p.status,
                                                'bg-danger': p.status === 0,
                                                'bg-warning': p.status === null
                                            }">
                                                {{ p.status === null ? 'No validada': p.status === 1 ? 'Validada': 'Rechazada' }}
                                            </span>
                                        </td>
                                        <td class="align-middle">
                                            <router-link :to="`/operaciones/compras/${p.id}`"
                                                         v-tooltip="'Ver detalles'">
                                                <i class="fas fa-info-circle green"></i>
                                            </router-link>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                      
                        <div class="card-footer">
                            <pagination :data="purchases" @pagination-change-page="getPurchases"/>
                        </div>
                        -->
                    </div>
                </div>
            </div>
        </div>
    </section>
     </v-app>
</template>

<script>
    import Loading from "../Common/Loading";

    export default {
        components: { Loading },
        created () {
            this.getPurchases();
        },
        data () {
            return {
                loading: true,
                purchases: {},
                search: '',
                headers: [
                {
                    text: 'Proveedor',
                    align: 'start',
                    sortable: false,
                    value: 'proveedor',
                },
                { text: 'Sol. abastecimiento', value: 'solicitud' },
                { text: 'Fecha compra', value: 'compra' },
                { text: 'Fecha confirmación', value: 'confirmacion' },
                { text: 'Estado', value: 'estado' },
                { text: 'Acciones', value: 'accion', sortable: false },
                ],
                datos:[],
            }
        },
        methods: {
            getPurchases (page = 1) {
                this.$Progress.start();
                this.loading = true;

                axios.get('/api/operaciones/compras')
                    /*.then(({ data }) => {
                        this.purchases = data.data;
                        this.$Progress.finish();
                    })*/
                     .then(data => {
                    this.purchases = data.data;
                     console.log(this.purchases);
                        for(let i = 0; i < this.purchases.data.length; i++) {
                            var t = this.purchases.data[i];
                            this.datos.push({status:t.status,id:t.id,accion:true,proveedor:t.supplier.name,solicitud:t.supply_request ? 'Si': 'No',compra:this.$options.filters.myDate(t.purchase),confirmacion:this.$options.filters.myDate(t.confirmed_at)});
                        }

                       console.log(this.datos);
                     })
                    .catch(() => {
                        this.$Progress.fail();
                        this.$launchError('Error al obtener listado de compras');
                    })
                    .finally(() => this.loading = false);
            }
        }
    }
</script>

<template>
    <div>
        <!-- breadcrumb -->
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Detalle de Factura</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link to="/">Inicio</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link to="/logistica/tripulaciones">Tripulaciones</router-link>
                            </li>
                            <li class="breadcrumb-item active">Detalle de Factura</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>

        <!-- creación -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col">
                        <div class="card">
                            <loading v-if="loading"/>

                            <div class="card-body">

                                 <div class="row mb-4">
                                      <div class="col">
                                        <span><strong>Proveedor:</strong> {{ invoice.name }} </span>
                                    </div>
                                     <div class="col">
                                        <span><strong>Código Proveedor:</strong> {{ invoice.code }} </span>
                                    </div>
                                     <div class="col">
                                        <span><strong>Numero de Factura:</strong> {{ invoice.invoice_number }}</span>
                                    </div>
                                    <div class="col">
                                        <span><strong>Precio Facturado:</strong> ${{ invoice.price | dotNumber}}.</span>
                                    </div>
                                    <div class="col">
                                        <span><strong>Cantidad de Items:</strong> {{ invoice.quantity }}.</span>
                                    </div>
                                </div>
                                <!-- mensaje de cotización enviada -->
                        


                                      <!-- pasajes -->
                                 
                           
                                <h4 class="mb-3" v-if="invoice.tickets!=null">Pasajes</h4>
                                <div class="row" v-if="invoice.tickets!=null">
                                    <div class="col table-responsive p-0">
                                        <table class="table table-hover" v-if="!loading">
                                            <thead>
                                                <tr>
                                                    <th class="align-middle">Facturó</th>
                                                    <th class="align-middle">Fecha Facturacion</th>
                                                    <th class="align-middle">Nombre</th>
                                                    <th class="align-middle">Rut</th>
                                                    <th class="align-middle">Código Vuelo</th>
                                                    <th class="align-middle">Código Pasaje</th>
                                                    <th class="align-middle">Aerolinea</th>  
                                                    <th class="align-middle">Tramo</th>    
                                                     <th class="align-middle">Aeropuerto Salida</th>    
                                                      <th class="align-middle">Aeropuerto Destino</th>     
                                                     <th class="align-middle">Orden de Compra</th> 
                                                     <th class="align-middle">Fecha Orden de Compra</th>                                         
                                                    <th class="align-middle">Tarifa</th>
                                                   <!-- <th class="align-middle">Maleta</th>-->
                                                    <th class="align-middle">Uso..</th>
                                                    <th class="align-middle">Tarifa Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="i in invoice.tickets" >
                                                    <td class="align-middle">{{i.invoice_user}}</td>
                                                    <td class="align-middle">{{i.invoice_date}}</td>
                                                    <td class="align-middle">{{i.worker_name}} {{i.worker_last_name}}</td>
                                                     <td class="align-middle">{{i.rut}}</td>
                                                      <td class="align-middle">{{i.code}}</td>
                                                         <td class="align-middle">PJ{{i.id}}</td>
                                                      <td class="align-middle">{{i.airline}}</td>
                                                     <td class="align-middle">{{i.stretch}}</td>
                                                     <td class="align-middle">{{i.airport1}}</td>
                                                      <td class="align-middle">{{i.airport2}}</td>
                                                       <td class="align-middle">{{i.purchase_order }}</td> 
                                                       <td class="align-middle">{{i.purchase_order_date }}</td>   
                                                  <!--  <td class="align-middle">$ {{i.price | dotNumber }}</td>-->

                                                     <td class="align-middle" v-if="i.payment_type==2">$ {{ i.real_price | dotNumber}}</td>
                                                     <td class="align-middle" v-else>$ {{ i.price | dotNumber}}</td>

                                                   <!-- <td class="align-middle" v-if="i.quotation==1">$ 0</td>
                                                    <td class="align-middle" v-else>$ {{i.carry_price | dotNumber}}</td>-->

                                                     <td class="align-middle" v-if="i.payment_type==1">Stock ({{i.price + i.payment_use_price | dotNumber}} - {{i.payment_use_price | dotNumber}})</td>
                                                     <td class="align-middle" v-else-if="i.payment_type==2">Voucher CU-{{i.voucher}} (${{i.payment_current}})</td>
                                                     <td class="align-middle" v-else>Normal</td>

                                                   <!-- <td class="align-middle" v-if="i.quotation==1">$ {{i.price | dotNumber}} 23232323</td>
                                                    <td class="align-middle" v-else>$ {{i.carry_price + i.price | dotNumber}} 56565656</td>-->
                                      
                                                   <!--  <td class="align-middle" v-if="i.payment_type==1">$ {{ (i.price - i.payment_use_price) | dotNumber}}</td>-->
                                                    
                                                     <td class="align-middle">$ {{ i.price | dotNumber}}</td>
                                                 

                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <!-- fin pasajes -->

                                <!-- afecto y multa pasaje-->
                                   <h4 class="mb-3" v-if="invoice.extra!=null">Afectos Pasaje</h4>
                                <div class="row" v-if="invoice.extra!=null">
                                    <div class="col table-responsive p-0">
                                        <table class="table table-hover" v-if="!loading">
                                            <thead>
                                                <tr>
                                                    <th class="align-middle">Facturó</th>
                                                    <th class="align-middle">Fecha Facturacion</th>
                                                    <th class="align-middle">Código Vuelo</th>
                                                    <th class="align-middle">Código Pasaje</th>
                                                    <th class="align-middle">Aerolinea</th>  
                                                    <th class="align-middle">Tramo</th>    
                                                    <th class="align-middle">Aeropuerto Salida</th>    
                                                    <th class="align-middle">Aeropuerto Destino</th>     
                                                    <th class="align-middle">Orden de Compra</th>
                                                    <th class="align-middle">Fecha Orden de Compra</th>                                         
                                                   
                                                    <th class="align-middle">Afecto</th>
                                            
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="i in invoice.extra" >
                                                    <td class="align-middle">{{i.invoice_user}}</td>
                                                    <td class="align-middle">{{i.invoice_date}}</td>
                                                    <td class="align-middle">{{i.code}}</td>
                                                    <td class="align-middle">PJ{{i.id}}</td>
                                                     <td class="align-middle">{{i.airline}}</td>
                                                     <td class="align-middle">{{i.stretch}}</td>
                                                     <td class="align-middle">{{i.airport1}}</td>
                                                    <td class="align-middle">{{i.airport2}}</td>
                                                    <td class="align-middle">{{i.purchase_order }}</td> 
                                                    <td class="align-middle">{{i.purchase_order_date }}</td> 
                                             
                                                    <td class="align-middle">$ {{i.dearness }}</td> 
                                                    
                                                
                                      
                                                   
                                      
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <!-- fin afecto y multa pasaje-->
                             

                                      <!-- compras -->
                         
                                <h4 class="mb-3" v-if="purchases.length>0">Compras</h4>
                                <div class="row" v-if="purchases.length>0">
                                    <div class="col table-responsive p-0">
                                        <table class="table table-hover" v-if="!loading">
                                            <thead>
                                                <tr>
                                                    <th class="align-middle">Facturó</th>
                                                    <th class="align-middle">Fecha Facturacion</th>
                                                    <th class="align-middle">Cantidad</th>
                                                    <th class="align-middle">Item</th>
                                                    <th class="align-middle">Medida</th>
                                                    <th class="align-middle">Fecha Compra</th>  
                                                    <th class="align-middle">Tripulación</th>  
                                                      <th class="align-middle">Orden de compra</th> 
                                                      <th class="align-middle">Fecha Orden de compra</th>                                         
                                                    <th class="align-middle">Precio Unitario</th>
                                                    <th class="align-middle">Precio Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="i in purchases" >
                                                    <td class="align-middle">{{i.invoice_user_name}}</td>
                                                    <td class="align-middle">{{i.invoice_date}}</td>
                                                    <td class="align-middle">{{i.quantity}}</td>
                                                     <td class="align-middle">{{i.item_name}}</td>
                                                      <td class="align-middle">{{i.item_size}}</td>
                                                      <td class="align-middle" v-if="i.purchase_date!=null">{{i.purchase_date | myDate}}</td>
                                                      <td class="align-middle" v-else>-</td>
                                                     <td class="align-middle">{{i.crew}}</td>
                                                  <td class="align-middle">{{i.purchase_order }}</td> 
                                                   <td class="align-middle">{{i.purchase_order_date }}</td> 
                                                    <td class="align-middle">$ {{i.price | dotNumber}}</td>
                                                    
                                                    <td class="align-middle">$ {{ (i.price * i.quantity) | dotNumber}}</td>
                                      
                                                   
                                      
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <!-- fin compras -->

                                       <!-- viveres -->
                           
                                <h4 class="mb-3" v-if="invoice.provisions!=null">Viveres</h4>
                                <div class="row" v-if="invoice.provisions!=null">
                                    <div class="col table-responsive p-0">
                                        <table class="table table-hover" v-if="!loading">
                                            <thead>
                                                <tr>
                                                    <th class="align-middle">Facturó</th>
                                                    <th class="align-middle">Fecha Facturacion</th>
                                                    <th class="align-middle">Cantidad de Personas</th>
                                                    <th class="align-middle">Fecha Compra</th>  
                                                         <th class="align-middle">Observacion</th>       
                                                    <th class="align-middle">Tripulación</th>     
                                                     <th class="align-middle">Orden de Compra</th>                                       
                                                      <th class="align-middle">Fecha Orden de Compra</th>  
                                                    <th class="align-middle">Precio</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="i in invoice.provisions" >
                                                    <td class="align-middle">{{i.invoice_user}}</td>
                                                     <td class="align-middle">{{i.invoice_date}}</td>
                                                     <td class="align-middle">{{i.workers_quantity}}</td>
                                                     <td class="align-middle">{{i.date | myDate}}</td>
                                                      <td class="align-middle">{{i.observation}}</td>
                                                       <td class="align-middle">{{i.crew }}</td>   
                                                       <td class="align-middle">{{i.purchase_order }}</td>  
                                                       <td class="align-middle">{{i.purchase_order_date }}</td>   
                                                    <td class="align-middle">$ {{i.price | dotNumber}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <!-- fin viceres -->

                                <!-- Alojamientos -->
                                <h4 class="mb-3" v-if="invoice.accommodations!=null">Alojamientos</h4>
                                <div class="row" v-if="invoice.accommodations!=null">
                                    <div class="col table-responsive p-0">
                                        <table class="table table-hover" v-if="!loading">
                                            <thead>
                                                <tr>
                                                    <th class="align-middle">Facturó</th>
                                                    <th class="align-middle">Fecha Facturacion</th>
                                                     <th class="align-middle">Codigo</th>
                                                    <th class="align-middle">Usuario</th>
                                                    <th class="align-middle">Tipo Usuario</th>
                                                    <th class="align-middle">Tramo</th>   
                                                    <th class="align-middle">Tripulación</th>   
                                                    <th class="align-middle">Fecha Entrada</th>   
                                                    <th class="align-middle">Fecha Salida</th>    
                                                    <th class="align-middle">Observacion</th>     
                                                     <th class="align-middle">Orden de Compra</th>         
                                                      <th class="align-middle">Fecha Orden de Compra</th> 
                                                    <th class="align-middle">Precio</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="i in invoice.accommodations">
                                                    <td class="align-middle">{{i.invoice_user}}</td>
                                                     <td class="align-middle">{{i.invoice_date}}</td>
                                                     <td class="align-middle">AB{{i.accommodation_id}}</td>
                                                     <td class="align-middle">{{i.name}} {{i.last_name}}</td>
                                                      <td class="align-middle">{{i.user_type}}</td>
                                                      <td class="align-middle">{{i.stretch }}</td>
                                                       <td class="align-middle">{{i.crew }}</td>
                                                       <td class="align-middle">{{i.entry_date }}</td>
                                                       <td class="align-middle">{{i.exit_date }}</td>
                                                        <td class="align-middle">{{i.observation }}</td>
                                                        <td class="align-middle">{{i.purchase_order }}</td> 
                                                        <td class="align-middle">{{i.purchase_order_date }}</td>   
                                                    <td class="align-middle"><strong>$ {{ (i.price) | dotNumber}}</strong></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <!-- fin alojamientos -->

                                  <!-- traslados -->
                                <h4 class="mb-3" v-if="invoice.transfers!=null">Traslados</h4>
                                <div class="row" v-if="invoice.transfers!=null">
                                    <div class="col table-responsive p-0">
                                        <table class="table table-hover" v-if="!loading">
                                            <thead>
                                                <tr>
                                                    <th class="align-middle">Facturó</th>
                                                    <th class="align-middle">Fecha Facturacion</th>
                                                    <th class="align-middle">Codigo</th>
                                                    <th class="align-middle">Usuario</th>
                                                    <th class="align-middle">Tipo Usuario</th>
                                                    <th class="align-middle">Tramo</th>   
                                                    <th class="align-middle">Tripulación</th>   
                                                    <th class="align-middle">Fecha Entrada</th>   
                                                    <th class="align-middle">Fecha Salida</th>    
                                                    <th class="align-middle">Observacion</th>        
                                                     <th class="align-middle">Orden de Compra</th>      
                                                      <th class="align-middle">Fecha Orden de Compra</th>   
                                                    <th class="align-middle">Precio</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="i in invoice.transfers" >
                                                    <td class="align-middle">{{i.invoice_user}}</td>
                                                     <td class="align-middle">{{i.invoice_date}}</td>
                                                     <td class="align-middle">TB{{i.transfer_id}}</td>
                                                     <td class="align-middle">{{i.name}} {{i.last_name}}</td>
                                                      <td class="align-middle">{{i.user_type}}</td>
                                                      <td class="align-middle">{{i.stretch }}</td>
                                                       <td class="align-middle">{{i.crew }}</td>
                                                       <td class="align-middle">{{i.entry_text }}</td>
                                                       <td class="align-middle">{{i.exit_text }}</td>
                                                        <td class="align-middle">{{i.observation }}</td>
                                                        <td class="align-middle">{{i.purchase_order }}</td>   
                                                        <td class="align-middle">{{i.purchase_order_date }}</td> 
                                                    <td class="align-middle"><strong>$ {{i.price | dotNumber}}</strong></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <!-- fin traslados -->


                                  <!-- pasajes
                                <h4 class="mb-3" v-if="invoice.tickets.length>0">Traslados</h4>
                                <div class="row" v-if="invoice.tickets.length>0">
                                    <div class="col table-responsive p-0">
                                        <table class="table table-hover" v-if="!loading">
                                            <thead>
                                                <tr>
                                                    <th class="align-middle">Facturó</th>
                                                    <th class="align-middle">Fecha Facturacion</th>
                                                    <th class="align-middle">Código</th>
                                                    <th class="align-middle">Aerolínea</th>
                                                    <th class="align-middle">Código Vuelo</th>
                                                    <th class="align-middle">Tramo</th>
                                                    <th class="align-middle">Ruta</th>
                                                    <th class="align-middle">Fecha</th>
                                                    <th class="align-middle">Utilizó..</th>    
                                                    <th class="align-middle">Plus</th>
                                                    <th class="align-middle">Vencimiento</th> 
                                                    <th class="align-middle">Precio</th> 
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="i in invoice.tickets" >
                                                    <td class="align-middle">{{i.invoice_user}}</td>
                                                     <td class="align-middle">{{i.invoice_date}}</td>
                                                     <td class="align-middle">AB{{i.accommodation_id}}</td>
                                                     <td class="align-middle">{{i.name}} {{i.last_name}}</td>
                                                      <td class="align-middle">{{i.user_type}}</td>
                                                      <td class="align-middle">{{i.stretch }}</td>
                                                       <td class="align-middle">{{i.crew }}</td>
                                                       <td class="align-middle">{{i.entry_date }}</td>
                                                       <td class="align-middle">{{i.exit_date }}</td>
                                                        <td class="align-middle">{{i.observation }}</td>
                                                    <td class="align-middle">$ {{i.price | dotNumber}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                fin pasajes -->



                                   <!-- MALETAS -->
                           
                                <h4 class="mb-3" v-if="invoice.carry!=null">Maletas</h4>
                                <div class="row" v-if="invoice.carry!=null">
                                    <div class="col table-responsive p-0">
                                        <table class="table table-hover" v-if="!loading">
                                            <thead>
                                                <tr>
                                                    <th class="align-middle">Facturó</th>
                                                    <th class="align-middle">Fecha Facturacion</th>
                                                    <th class="align-middle">Codigo Pasaje</th>
                                                    <th class="align-middle">Usuario</th>
                                                    <th class="align-middle">Tipo Usuario</th>
                                                   
                                                    <th class="align-middle">Tripulación</th>   
                                                    <th class="align-middle">Orden de Compra</th>
                                                    <th class="align-middle">Fecha Orden de Compra</th>
                                                    <th class="align-middle">Precio</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="i in invoice.carry" >
                                                    <td class="align-middle">{{i.invoice_user}}</td>
                                                     <td class="align-middle">{{i.invoice_date}}</td>
                                                     <td class="align-middle">PJ{{i.ticket_group_id}}</td>
                                                     <td class="align-middle">{{i.name}} {{i.last_name}}</td>
                                                      <td class="align-middle">{{i.user_type}}</td>
                                                       <td class="align-middle">{{i.crew }}</td>   
                                                         <td class="align-middle">{{i.purchase_order }}</td>   
                                                         <td class="align-middle">{{i.purchase_order_date }}</td>   
                                                    <td class="align-middle">$ {{i.carry_price | dotNumber}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <!-- fin maletas -->


                                    <!-- multa pasaje-->
                                <h4 class="mb-3" v-if="invoice.penaltys!=null">Multas</h4>
                                <div class="row" v-if="invoice.penaltys!=null">
                                    <div class="col table-responsive p-0">
                                        <table class="table table-hover" v-if="!loading">
                                            <thead>
                                                <tr>
                                                     <th class="align-middle">Facturó</th>
                                                    <th class="align-middle">Fecha Facturacion</th>
                                                    <th class="align-middle">Codigo Pasaje</th>
                                                    <th class="align-middle">Usuario</th>
                                                    <th class="align-middle">Tipo Usuario</th>
                                                   
                                                    <th class="align-middle">Tripulación</th>   
                                                    <th class="align-middle">Orden de Compra</th>
                                                    <th class="align-middle">Fecha Orden de Compra</th>
                                                    <th class="align-middle">Precio</th>
                                        
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="i in invoice.penaltys" >
                                                     <td class="align-middle">{{i.invoice_user}}</td>
                                                     <td class="align-middle">{{i.invoice_date}}</td>
                                                     <td class="align-middle">PJ{{i.ticket_group_id}}</td>
                                                     <td class="align-middle">{{i.name}} {{i.last_name}}</td>
                                                      <td class="align-middle">{{i.user_type}}</td>
                                                       <td class="align-middle">{{i.crew }}</td>   
                                                         <td class="align-middle">{{i.purchase_order }}</td>   
                                                         <td class="align-middle">{{i.purchase_order_date }}</td>   
                                                    <td class="align-middle" v-if="i.payment_type==2">Valor : ${{i.real_price | dotNumber}} - Voucher CU-{{i.voucher}} (${{i.payment_current}}) - Precio Final : $ {{i.penalty | dotNumber}}</td>
                                                    <td class="align-middle" v-else>$ {{i.penalty | dotNumber}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <!-- fin multa pasaje-->
      
                            </div>
                        </div>


               
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Loading from "../Common/Loading";

    export default {
        components: { Loading},
        computed: {
            id () {
                return this.$route.params.id;
            },

        },
        mounted(){
       
        },
        created () {
            this.$Progress.start();
            this.getQuotation();    
            this.$Progress.finish();
        },
        data () {
            return {
                loading: true,
                invoice:[],
                purchases:[]
            }
        },
        destroyed() {
           // eventHub.$off(['reloadQuotationTicket', 'reloadQuotationWorkers', 'lodgingSaved']);
        },
        methods: {
            journey(plus){
                if(plus==1){
                    return "Ida";
                }
                return "Vuelta";
            },
               plustype(plus){
                if(plus==1){
                    return "Si";
                }
                return "No";
            },
            paytype(plus){
                if(plus==1){
                    return "Stock";
                }
                if(plus==2){
                    return "Voucher";
                }
                return " - ";
            },
             quotationtype(plus){
                if(plus==1){
                    return "Si";
                }
                return "No";
            },
            getQuotation () {
                this.loading = true;
                this.$Progress.start();

                axios.get(`/api/administracion/factura/detalle/${this.id}`)
                    .then(({ data }) => {
                        this.invoice = data.data;
                        if(this.invoice.purchases!=null){
                             this.invoice.purchases.forEach(d => {
                     
                         
                            console.log(d);
                            
                            Array.from(d.warehouse_item_sizes).forEach(element => 
                                    this.purchases.push({purchase_order_date:element.purchase_order_date,purchase_order:element.purchase_order,crew:d.crew,purchase_date:element.purchase_date,item_name:element.size.item.name,item_size:element.size.name,quantity:element.quantity,price:element.price,invoice_date:element.invoice_date,invoice_user_name:element.invoice_user_name,invoice_date:element.invoice_date})
                            );

                            console.log(this.purchases);

                      
                        });
                        }


                           if(this.invoice.tickets!=null){
                             this.invoice.tickets.forEach(d => {
                                        if(d.quotation==1){
                                         
                                        }
                             });
                           }
                       
                        this.$Progress.finish();
                        // genera el "formulario" de observaciones solo si no tiene datos de antemano       
                    })
                    .catch(() => {
                        this.$launchError('Error al obtener información de cotización');
                        this.$Progress.fail();
                    })
                    .finally(() => this.loading = false);
            },
        }
    }
</script>

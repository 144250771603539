<template>
<v-app>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <!-- header -->
                        <div class="card-header">
                            <h3 class="card-title">Listado de Tripulaciones</h3>

                            <div class="card-tools">
                                <button style="color:#ffffff !important;" v-if="$gate.isJefeflota() || $gate.isAdmin() || $gate.isGerente() " type="button" class="btn btn-sm btn-primary" @click="modalCreacion">
                                    <i class="fa fa-plus-square"></i>
                                    Nueva tripulación
                                </button>
                            </div>
                        </div>



  <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Búsqueda"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
  <v-data-table
      :headers="headers"
      :items="datos"
      :search="search"
       :loading="loading"
       :items-per-page="20"
       no-results-text="No se han encontrado tripulaciones"
       no-data-text="No existen tripulaciones"
      class="elevation-1"
        loading-text="Cargando... Espere"
         :footer-props="{
           'items-per-page-text':'Tripulaciones por página',
           'items-per-page-options': [20, 50, 100, -1]
      }"
    >
 <template v-slot:item.accion="{ item, index }">

                                             <v-icon v-if="$gate.isJefeflota() || $gate.isAdmin()" small color="orange" @click="modalCambiofecha(item, index)" v-tooltip="'Cambiar fecha de presentación'">
                                                fa-calendar
                                                </v-icon>
                                                <span v-if="$gate.isJefeflota() || $gate.isAdmin()">/</span>
                                            <router-link v-if="$gate.isJefeflota() || $gate.isAdmin() || $gate.isGerente()" :to="'/admin/tripulaciones/' + item.id"
                                                         v-tooltip="'Ficha de tripulación'">
                                               
                                                  <v-icon small color="green">
                                                fa-info-circle
                                                </v-icon>
                                            </router-link>
                                            <span v-if="$gate.isJefeflota() || $gate.isAdmin() || $gate.isGerente()" >/</span>
                                            <router-link :to="'/jefe-flota/tripulaciones/' + item.id + '/editar'"
                                                         v-tooltip="'Editar'">
                                               <v-icon small style="color: #3490DC !important;">
                                                fa-edit
                                                </v-icon>
                                            </router-link>
                                            <span v-if="item.estado === 'Borrador' && ($gate.isJefeflota() || $gate.isAdmin() || $gate.isGerente() )">/</span>
                                            <a  @click="deleteCrew(item.id)" href="#"
                                               v-if="item.estado === 'Borrador' && ($gate.isJefeflota() || $gate.isAdmin() || $gate.isGerente()) " v-tooltip="'Eliminar'">
                                              
                                                <v-icon small color="red">
                                                fa-trash
                                                </v-icon>
                                            </a>
                                                      
  </template>
  <template v-slot:footer.page-text="items"> {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }} </template>
</v-data-table>
</v-card> 
                        <!-- 
                        <div class="card-body table-responsive p-0">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th class="align-middle">Cliente</th>
                                        <th class="align-middle">Embarcación</th>
                                        <th class="align-middle">Fecha presentanción</th>
                                        <th class="align-middle">Fecha bajada real</th>
                                        <th class="align-middle">Fecha bajada protocolar</th>
                                        <th class="align-middle">Dias Extra</th>
                                        <th class="align-middle">Estado</th>
                                        <th class="align-middle">Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="c in crews.data" :key="c.id">
                                        <td class="align-middle">{{ c.client.name }}</td>
                                        <td class="align-middle">{{ c.ship.name }}</td>
                                        <td class="align-middle">{{ c.upload_date | myDate }}</td>
                                        <td class="align-middle">{{ c.real_download_date | myDate }}</td>
                                        <td class="align-middle">{{ c.download_date | myDate }}</td>
                                        <td class="align-middle">{{ c.extra_days }}</td>
                                        <td class="align-middle">{{ c.status.name }}</td>
                                        <td class="align-middle">
                                            <router-link v-if="$gate.isJefeflota() || $gate.isAdmin() || $gate.isGerente()" :to="'/admin/tripulaciones/' + c.id"
                                                         v-tooltip="'Ficha de tripulación'">
                                                <i class="fa fa-info-circle green"></i>
                                            </router-link>
                                            <span v-if="$gate.isJefeflota() || $gate.isAdmin() || $gate.isGerente()" >/</span>
                                            <router-link :to="'/jefe-flota/tripulaciones/' + c.id + '/editar'"
                                                         v-tooltip="'Editar'">
                                                <i class="fa fa-edit blue"></i>
                                            </router-link>
                                            <span v-if="c.status.name === 'Borrador' && ($gate.isJefeflota() || $gate.isAdmin() || $gate.isGerente() )">/</span>
                                            <a  @click="deleteCrew(c.id)" href="#"
                                               v-if="c.status.name === 'Borrador' && ($gate.isJefeflota() || $gate.isAdmin() || $gate.isGerente()) " v-tooltip="'Eliminar'">
                                                <i class="fa fa-trash red"></i>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                     
                        <div class="card-footer">
                            <pagination :data="crews" @pagination-change-page="getCrews"/>
                        </div>
                        -->



                    </div>
                </div>
            </div>


               <!-- modal cambio fecha-->
               <div class="modal fade" id="cambio-fecha-modal" tabindex="-1" role="dialog" aria-labelledby="form-dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Cambiar Fecha de Presentanción</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                       

                        <form @submit.prevent="updateUploadDate">
                            <div class="modal-body container">
                                <span>Embarcación: {{formupdate.barco}}</span><br>
                                <span>Cliente: {{formupdate.cliente}}</span><br>
                                <span>Fecha de Presentacion Actual: {{formupdate.current_date}}</span><br>
                                <span>Fecha Bajada Protocolar: {{formupdate.current_download_date}}</span><br>
                                <span>Fecha Real: {{formupdate.current_real_download_date}}</span><br>
                                <span>Dias Extra: {{formupdate.current_extra_days}}</span><br>
                                <div class="row" style="margin-top:20px;">
                                    <div class="col form-group">
                                        <label>Nueva Fecha de presentanción</label>
                                        <input type="date" class="form-control" v-model="formupdate.new_upload_date"
                                                 @change="changeUploadDateUpdate"
                                               :class="{ 'is-invalid': form.errors.has('new_upload_date') }">
                                        <has-error :form="formupdate" field="new_upload_date"/>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col form-group">
                                        <label>Nueva Fecha Bajada Real</label>
                                        <input type="date" class="form-control" v-model="formupdate.new_real_download_date"
                                               :class="{ 'is-invalid': form.errors.has('new_real_download_date') }">
                                        <has-error :form="formupdate" field="new_real_download_date"/>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col form-group">
                                        <label>Dias Extra</label>
                                        <input type="number" class="form-control" v-model="formupdate.new_extra_days"
                                               :class="{ 'is-invalid': form.errors.has('new_extra_days') }">
                                        <has-error :form="formupdate" field="new_extra_days"/>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col form-group">
                                        <label>Motivo:</label>
                                        <input type="text" maxlength="80" class="form-control" v-model="formupdate.reason"
                                               :class="{ 'is-invalid': form.errors.has('reason') }">
                                        <has-error :form="formupdate" field="reason"/>
                                    </div>
                                </div>

                                <span v-if="downloadDateUpload">
                                    <strong>Nueva Fecha bajada protocolar :</strong> {{ downloadDateUpload | fullDate | capitalize }}.
                                </span>

                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-dismiss="modal" style="color:#ffffff !important;">Cerrar</button>
                                    <button type="submit" class="btn btn-primary" style="color:#ffffff !important;">Guardar</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <!-- fin modal cambio fecha -->

            <!-- modal -->
            <div class="modal fade" id="form-modal" tabindex="-1" role="dialog" aria-labelledby="form-dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Crear nueva tripulación</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <form @submit.prevent="createCrew">
                            <div class="modal-body container">
                                <div class="row">
                                    <div class="col form-group">
                                        <label>Cliente</label>
                                        <select class="form-control" v-model="form.client_id" name="cliente"
                                                :class="{ 'is-invalid': form.errors.has('client_id') }">
                                            <option :value="null" disabled>Escoja un cliente</option>
                                            <option :value="c.id" v-for="c in clients">{{ c.name }}</option>
                                        </select>
                                        <has-error :form="form" field="client_id"/>
                                    </div>

                                    <div class="col form-group">
                                        <label>Embarcación</label>
                                        <select class="form-control" v-model="form.ship_id" name="embarcación"
                                                :class="{ 'is-invalid': form.errors.has('ship_id') }">
                                            <option :value="null" disabled>Escoja una embarcación</option>
                                            <option :value="s.id" v-for="s in ships">{{ s.name }}</option>
                                        </select>
                                        <has-error :form="form" field="ship_id"/>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col form-group">
                                        <label>Fecha presentanción</label>
                                        <input type="date" class="form-control" v-model="form.upload_date"
                                               @change="changeUploadDate"
                                               :class="{ 'is-invalid': form.errors.has('upload_date') }">
                                        <has-error :form="form" field="upload_date"/>
                                    </div>

                                    <div class="col form-group">
                                        <label>Fecha bajada real</label>
                                        <input type="date" class="form-control" v-model="form.real_download_date"
                                               :class="{ 'is-invalid': form.errors.has('real_download_date') }">
                                        <has-error :form="form" field="real_download_date"/>
                                    </div>
                                </div>
                                

                                <div class="row">
                                    <div class="col form-group">
                                        <label>Observaciones</label>
                                        <input type="text" maxlength="80" class="form-control" v-model="form.observations"
                                               :class="{ 'is-invalid': form.errors.has('observations') }">
                                        <has-error :form="form" field="observations"/>
                                    </div>
                                </div>

                                <span v-if="downloadDate">
                                    <strong>Fecha bajada protocolar :</strong> {{ downloadDate | fullDate | capitalize }}.
                                </span>

                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-dismiss="modal" style="color:#ffffff !important;">Cerrar</button>
                                    <button type="submit" class="btn btn-primary" style="color:#ffffff !important;">Crear</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </v-app>
</template>

<script>
    import moment from 'moment';

    export default {
        created () {
            this.$Progress.start();
            this.getCrews();
            this.$Progress.finish();
        },
        data () {
            return {
                loading: false,
                clients: [],
                crews: {},
                downloadDate: null,
                downloadDateUpload: null,
                form: new Form({
                    client_id: null,
                    ship_id: null,
                    upload_date: null,
                    real_download_date: null,
                    observations: null
                }),
                formupdate: new Form({
                    crew_id: null,
                    index: null,
                    new_upload_date: null,
                    reason: null,
                    current_date: null,
                    cliente: '',
                    barco: '',
                    new_extra_days: 0,
                    new_download_date: null,
                    new_real_download_date: null,
                    current_download_date: '',
                    current_real_download_date: '',
                    current_extra_days: '',
                }),
                ships: [],
                 search: '',
                headers: [
                {
                    text: 'Cliente',
                    align: 'start',
                    sortable: false,
                    value: 'cliente',
                },
                { text: 'Embarcación', value: 'barco' },
                { text: 'Fecha de Presentacion', value: 'presentacion' },
                { text: 'Fecha bajada real', value: 'real' },
                { text: 'Fecha bajada protocolar', value: 'protocolar' },
                 { text: 'Dias Extra', value: 'extra' },
                { text: 'Estado', value: 'estado' },
                { text: 'Acciones', value: 'accion', sortable: false },
                ],
                datos:[],
            }
        },
        methods: {
            changeUploadDate () {
                let t = moment(this.form.upload_date).add(24, 'd');
                this.form.real_download_date = t.add(0, 'd').format('yyyy-MM-DD');
                this.downloadDate = t.add(0, 'd').format('MM/DD/yyyy');
                this.form.download_date = this.downloadDate;
            },
            changeUploadDateUpdate () {
                let t = moment(this.formupdate.new_upload_date).add(24, 'd');
                this.formupdate.new_real_download_date = t.add(0, 'd').format('yyyy-MM-DD');
                this.downloadDateUpload = t.add(0, 'd').format('MM/DD/yyyy');
                this.formupdate.new_download_date = this.downloadDateUpload;
            },
            deleteCrew (id) {
                if (this.crews.data.find(c => c.id === id && c.status.name === 'Borrador') !== undefined) {
                    Swal.fire({
                        title: '¿Estás seguro que desea eliminar esta tripulación?',
                        text: 'No podrás revertir esta acción',
                        showCancelButton: true,
                        confirmButtonColor: '#d33',
                        cancelButtonColor: '#3085d6',
                        confirmButtonText: 'Si',
                        cancelButtonText: 'No'
                    }).then((result) => {
                        if (result.value) {
                            axios.delete(`/api/jefe-flota/tripulaciones/${id}/eliminar`)
                                .then(() => {
                                    Swal.fire(
                                        'Éxito',
                                        'La tripulación fue eliminada correctamente',
                                        'success'
                                    );

                                    this.getCrews();
                                }).catch(() => Swal.fire(
                                'Error',
                                'No se pudo eliminar la tripulación',
                                'warning'
                            ));
                        }
                    });
                }
            },

            getCrews (page = 1) {
                this.$Progress.start();
                this.loading = true;

                axios.get('/api/jefe-flota/tripulaciones' )
                    //.then(({ data }) => this.crews = data.data)
                    .then(data => {
                    this.crews = data.data;
                        for(let i = 0; i < this.crews.data.length; i++) {
                           
                            var eldato = this.crews.data[i];      
                            if( eldato.client != null && eldato.ship != null && eldato.status != null){
                                        this.datos.push({id:eldato.id,extra:eldato.extra_days,cliente:eldato.client.name,barco:eldato.ship.name,presentacion:this.$options.filters.myDate(eldato.upload_date),real:this.$options.filters.myDate(eldato.real_download_date),protocolar:this.$options.filters.myDate(eldato.download_date),estado:eldato.status.name,accion:true});
                    
                            }
                        }
                       this.loading = false;
                     })
                    .catch((error) => {
                        this.loading = false;
                        this.$launchError('Error al obtener listado de tripulaciones')
                    });

                this.$Progress.finish();
            },

            createCrew () {
                this.$Progress.start();

                this.form.post('/api/jefe-flota/tripulaciones')
                    .then(({ data }) => {
                        $('#form-modal').modal('hide');
                        this.$launchSuccess('La tripulación fue creada correctamente');
                        this.$Progress.finish();
                        this.$router.push({ path: `/jefe-flota/tripulaciones/${data.data.id}/editar` });
                    })
                    .catch(() => {
                        this.$Progress.fail();
                        this.$launchError('Error al crear tripulación');
                    });
            },

            updateUploadDate () {
                if (this.formupdate.new_upload_date === null || this.formupdate.new_real_download_date === null || this.formupdate.reason === null) {
                    return this.$launchError('Complete todos los campos para actualizar la fecha de presentación');
                }

                Swal.fire({
                        title: '¿Estás seguro que deseas actualizar las fechas?',
                        text: 'No podrás revertir esta acción y esto afectará a la tripulación',
                        showCancelButton: true,
                        confirmButtonColor: '#d33',
                        cancelButtonColor: '#3085d6',
                        confirmButtonText: 'Si',
                        cancelButtonText: 'No'
                    }).then((result) => {
                        if (result.value) {
                            this.$Progress.start();
                            this.formupdate.post('/api/jefe-flota/tripulaciones/update-upload-date')
                                .then(({ data }) => {                      
                                    this.datos[this.formupdate.index].presentacion = this.$options.filters.myDate(data.data.upload_date) 
                                    this.datos[this.formupdate.index].real = this.$options.filters.myDate(data.data.real_download_date) 
                                    this.datos[this.formupdate.index].protocolar = this.$options.filters.myDate(data.data.download_date) 
                                    this.datos[this.formupdate.index].extra = data.data.extra_days 
                                    this.$Progress.finish();
                                    this.formupdate.reset();
                                    $('#cambio-fecha-modal').modal('hide');
                                    this.$launchSuccess('Fecha de Presentación actualizada');
                                })
                                .catch(() => {
                                    this.$Progress.fail();
                                    this.$launchError('Error al actualizar fecha de Presentacion');
                                });
                        }
                    });
            },

            getClients () {
               if (this.clients.length === 0) {
                   axios.get('/api/clientes').then(({ data }) => this.clients = data.data)
                       .catch(() => this.$launchError('Error al obtener listado de clientes'));
               }
            },

            getShips () {
                if (this.ships.length === 0) {
                    axios.get('/api/embarcaciones').then(({ data }) => this.ships = data.data)
                        .catch(() => this.$launchError('Error al obtener listado de embarcaciones'));
                }
            },

            modalCreacion () {
                this.form.reset();
                this.downloadDate = null;
                this.getClients();
                this.getShips();
                $('#form-modal').modal('show');
            },
            modalCambiofecha (item, index) {
                this.formupdate.current_extra_days = item.extra
                this.formupdate.current_real_download_date = item.real
                this.formupdate.current_download_date = item.protocolar
                this.formupdate.current_date = item.presentacion
                this.formupdate.barco = item.barco
                this.formupdate.cliente = item.cliente
                this.formupdate.crew_id = item.id
                this.formupdate.index = index
                $('#cambio-fecha-modal').modal('show');
            }
        },
        watch: {
            /*'form.real_download_date' () {
                this.form.download_date = moment(this.form.real_download_date)
                    .add(0, 'd')
                    .format('MM/DD/yyyy');
                this.downloadDate = this.form.download_date
            }*/
        }
    }
</script>

<template>
    <div>
        <form @submit.prevent="saveAvatar">
            <div class="row">
                <div class="col">
                    <label>Foto actual</label><br>

                    <img class="profile-user-img img-fluid img-circle"
                         :src="downloadFileUrl(`user-avatar/${user.id}/${user.avatar}`)" v-if="user.avatar">
                </div>

                <div class="col">
                    <label>-</label><br>

                    <div class="custom-file">
                        <input id="avatar" type="file" class="custom-file-input" accept="image/jpg,jpeg,png"
                               @change.prevent="uploadAvatar">
                        <label class="custom-file-label" for="avatar">
                            {{ fileName ? fileName : 'Escoja un archivo' }}
                        </label>
                    </div>
                </div>

                <div class="col">
                    <label>Foto nueva</label><br>

                    <img class="profile-user-img img-fluid img-circle" :src="avatar" alt="Nueva foto" v-if="avatar">
                    <span v-else>No se ha subido foto aún</span>
                </div>
            </div>

            <button type="submit" class="btn btn-primary float-right" :disabled="submit">
                <i class="fa fa-spinner fa-spin mr-2" v-if="submit"></i>
                {{ submit ? 'Guardando' : 'Guardar' }}
            </button>
        </form>
    </div>
</template>

<script>
    export default {
        computed: {
            id () {
                return this.$route.params.id;
            },
            user () {
                if (this.$parent.user) {
                    return this.$parent.user;
                }
            }
        },
        data () {
            return {
                avatar: null,
                fileName: null,
                file: null,
                submit: false
            }
        },
        methods: {
            saveAvatar () {
                this.$Progress.start();
                this.submit = true;
                let formData = new FormData();
                formData.append('avatar', this.file);

                axios.post(`/api/usuarios/${this.id}/foto`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                })
                    .then(({ data }) => {
                        this.$Progress.finish();
                        this.$launchSuccess('Éxito al cambiar foto de usuario');
                        this.user.avatar = data.data.file;
                    })
                    .catch(() => {
                        this.$launchError('Error al cambiar foto de usuario');
                        this.$Progress.fail();
                    })
                    .finally(() => this.submit = false);
            },

            uploadAvatar (e) {
                this.file = e.target.files[0];
                this.fileName = this.file.name;
                const reader = new FileReader();
                reader.readAsDataURL(this.file);
                reader.onload = e => this.avatar = e.target.result;
            }
        }
    }
</script>

<template>
    <div>
        <!-- breadcrumb -->
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Detalles de compra</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link to="/">Inicio</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link to="/operaciones/compras">Compras</router-link>
                            </li>
                            <li class="breadcrumb-item active">Detalles de compra</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>

        <!-- detalles -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col">
                        <div class="card">
                            <loading v-if="loading"/>

                            <div class="card-body">


                                 <div class="row mb-4">
                                    <div class="col">
                                        <span><strong>Cliente:</strong> {{ purchase.crew.client }}.</span>
                                    </div>
                                    <div class="col">
                                        <span><strong>Embarcación:</strong> {{ purchase.crew.ship }}.</span>
                                    </div>
                                    <div class="col">
                                        <span><strong>Fecha presentanción:</strong> {{ purchase.crew.upload_date | myDate }}.</span>
                                    </div>
                                    <div class="col">
                                        <span><strong>Fecha bajada real:</strong> {{ purchase.crew.real_download_date | myDate }}.</span>
                                    </div>
                                    <div class="col">
                                        <span><strong>Fecha bajada protocolar:</strong> {{ purchase.crew.download_date | myDate }}.</span>
                                    </div>

                                     <div class="col">
                                        <span>
                                            <strong>Estado:</strong>
                                            <span class="badge" :class="{
                                                'bg-success': purchase.status,
                                                'bg-danger': purchase.status === 0,
                                                'bg-warning': purchase.status === null
                                            }" v-if="!loading">
                                                {{ purchase.status === null ? 'No validada': purchase.status === 1 ? 'Validada': 'Rechazada' }}
                                            </span>
                                        </span>
                                    </div>
                                    <div class="col">
                                        <span>
                                            <strong>Fecha confirmación:</strong> {{ purchase.confirmed_at | myDate }}.
                                        </span>
                                    </div>


                                </div>
                                <!-- información -->
                              

                                <!-- motivo de rechazo -->
                                <div class="alert alert-danger" role="alert" v-if="isConfirmed && !purchase.status">
                                    <strong>Motivo:</strong> {{ purchase.rejection_reason }}.
                                </div>

                                <h3>Viveres</h3>
                                 <table class="table table-hover mb-4">
                                    <thead>
                                        <tr>
                                            <th class="align-middle">Proveedor</th>
                                            <th class="align-middle">Fecha Compra</th>
                                            <th class="align-middle">Cantidad</th>
                                   
                                            <th class="align-middle">Precio</th>
                                            <th class="align-middle">Observacion</th>
                                            
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="align-middle">{{ purchase.provisions.supplier }}</td>
                                            <td class="align-middle">{{ purchase.provisions.date | myDate }}</td>
                                            <td class="align-middle">{{ purchase.provisions.workers_quantity }}</td>
                                            <td class="align-middle">{{ purchase.provisions.price }}</td>
                                            <td class="align-middle">{{ purchase.provisions.observation }}</td>
                                        </tr>
                                    </tbody>
                                </table>


                                <h3>Articulos</h3>
                                <!-- artículos -->
                                <table class="table table-hover mb-4">
                                    <thead>
                                        <tr>
                                            <th class="align-middle">Nombre</th>
                                            <th class="align-middle">Talla</th>
                                            <th class="align-middle">Clasificación</th>
                                            <th class="align-middle">Proveedor</th>
                                            <th class="align-middle">Fecha Compra</th>
                                            <th class="align-middle">Cantidad</th>
                                            <th class="align-middle">Unidad de medida</th>
                                            <th class="align-middle">Precio</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="i in purchase.warehouse_item_sizes">
                                            <td class="align-middle">{{ i.size.item.name }}</td>
                                            <td class="align-middle">{{ i.size.name !== '' ? i.size.name : '-' }}</td>
                                            <td class="align-middle">{{ i.size.item.classification.name }}</td>
                                            <td class="align-middle">{{ i.supplier_name }}</td>
                                            <td class="align-middle">{{ i.purchase_date }}</td>
                                            <td class="align-middle">{{ i.quantity }}</td>
                                            <td class="align-middle">{{ i.size.item.measurement_unit.name }}</td>
                                            <td class="align-middle">
                                                <span>
                                                    ${{ (i.price ? i.price : i.size.item.price) | dotNumber }}
                                                    <i class="fa fa-exclamation-triangle red"
                                                       v-tooltip="'Este nuevo precio reemplazará al anterior'"
                                                       v-if="i.price"></i>
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <button class="btn btn-outline-danger" @click="openRejectModal" v-if="!isConfirmed">
                                    Rechazar
                                </button>

                                <button class="btn btn-primary float-right" @click="validateQuotation"
                                        v-if="!isConfirmed">
                                    Validar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- modal rechazo -->
                <div class="modal fade" id="reject-modal" tabindex="-1" role="dialog" aria-labelledby="form-dialog"
                     aria-hidden="true" v-if="showRejectModal" data-backdrop="static" data-keyboard="false">
                    <div class="modal-dialog" role="document">
                        <reject-purchase/>
                    </div>
                </div>

                

            </div>
        </section>
    </div>
</template>

<script>
    import Loading from "../Common/Loading";
    import RejectPurchase from "./PurchaseDetails/RejectPurchase";
    import eventHub from "../../eventHub";


    export default {
        components: { Loading, RejectPurchase },
        computed: {
            id () {
                return this.$route.params.id;
            },
            isConfirmed () {
                return this.purchase.status !== null;
            }
        },
        created () {
            this.getPurchase();

            eventHub.$on('purchaseRejected', () => {
                $('#reject-modal').modal('hide');
                this.getPurchase();
            });
        },
        data () {
            return {
                loading: false,
                purchase: {
                    purchase: null,
                    status: null,
                    confirmed_at: null,
                    rejection_reason: null,
                    supplier: { name: null },
                    warehouse_item_sizes: []
                },
                showRejectModal: false
            }
        },
        methods: {
            getPurchase () {
                this.loading = true;
                this.$Progress.start();

                axios.get('/api/operaciones/compras-tripulacion/' + this.id)
                    .then(({ data }) => {
                        this.purchase = data.data;
                        this.$Progress.finish();
                    })
                    .catch(() => {
                        this.$launchError('Error al obtener detalle de compra');
                        this.$Progress.fail();
                    })
                    .finally(() => this.loading = false);
            },

            openRejectModal () {
                if (!this.isConfirmed) {
                    this.showRejectModal = true;
                    this.$nextTick(() => $('#reject-modal').modal('show'));
                }
            },

            validateQuotation () {
                if (!this.isConfirmed) {
                    Swal.fire({
                        title: '¿Estás seguro que deseas validar esta compra?',
                        text: 'No podrás revertir esta acción',
                        showCancelButton: true,
                        confirmButtonColor: '#d33',
                        cancelButtonColor: '#3085d6',
                        confirmButtonText: 'Si',
                        cancelButtonText: 'No'
                    }).then((result) => {
                        if (result.value) {
                            axios.post(`/api/operaciones/compras/${this.id}/validar`)
                                .then(() => {
                                    this.$launchSuccess('Éxito al validar compra');
                                    this.getPurchase();
                                })
                                .catch(() => this.$launchError('Error al validar compra'));
                        }
                    });
                }
            }
        }
    }
</script>

<template>
    <div>
        <!-- breadcrumb -->
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Nueva carena</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link to="/">Inicio</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link to="/admin/embarcaciones">Embarcaciones</router-link>
                            </li>
                            <li class="breadcrumb-item active">Nueva carena</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>

        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col">
                        <div class="card">
                            <div class="card-body">
                                <form @submit.prevent="saveCareen">
                                    <div class="row">
                                        <div class="col form-group">
                                            <label>Fecha de carena</label>
                                            <input type="date" class="form-control" v-model="form.careen_date">
                                        </div>

                                        <div class="col form-group">
                                            <label>Observaciones</label><br>
                                            <input type="text" class="form-control" v-model="form.observations">
                                        </div>

                                        <div class="col form-group">
                                            <label>Certificado</label><br>
                                            <div class="custom-file">
                                                <input id="certificate" type="file" class="custom-file-input"
                                                       @change.prevent="upload" accept="application/pdf">
                                                <label class="custom-file-label" for="certificate">
                                                    {{ fileName ? fileName : 'Escoja un archivo' }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <button type="submit" class="btn btn-primary float-right" :disabled="submit">
                                        <i class="fa fa-spinner fa-spin mr-2" v-if="submit"></i>
                                        {{ submit ? 'Guardando' : 'Guardar' }}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    data () {
        return {
            certificate: null,
            fileName: null,
            form: {
                careen_date: null,
                observations: null
            },
            submit: false
        }
    },
    methods: {
        saveCareen () {
            if (!this.certificate || !this.form.careen_date) {
                this.$launchError('Debe completar todos los campos');
            } else {
                Swal.fire({
                    title: '¿Estás seguro que deseas guardar esta carena?',
                    text: 'No podrás revertir esta acción',
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Si',
                    cancelButtonText: 'No'
                }).then((result) => {
                    if (result.value) {
                        this.$Progress.start();
                        this.submit = true;

                        let formData = new FormData();
                        formData.append('certificate', this.certificate);
                        formData.append('observations', this.form.observations);
                        formData.append('careen_date', this.form.careen_date);

                        axios.post(`/api/embarcaciones/${this.$route.params.id}/carena`, formData, {
                            headers: { 'Content-Type': 'multipart/form-data' }
                        })
                            .then(() => {
                                Swal.fire(
                                    'Éxito',
                                    'La carena fue registrada correctamente',
                                    'success'
                                );
                                this.form.observations = null;
                                this.form.careen_date = null;
                                this.certificate = null;
                                this.fileName = null;
                                this.$Progress.finish();
                            })
                            .catch(() => {
                                Swal.fire(
                                    'Error',
                                    'No se pudo registrar la carena',
                                    'warning'
                                )
                                this.$Progress.fail();
                            })
                            .finally(() => this.submit = false);
                    }
                });
            }
        },

        upload (e) {
            const f = e.target.files[0];

            this.certificate = f;
            this.fileName = f.name;
        }
    }
}
</script>

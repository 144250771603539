<template>
    <p class="mb-0">
        <span v-tooltip="tooltipContent">{{ main }}<span v-if="extra > 0"> (+{{ extra }})</span></span>
    </p>
</template>

<script>
    export default {
        computed: {
            extra () {
                return (this.status.reasons.expirations.length + this.status.reasons.inductions.length) - 1;
            },
            main () {
                if (this.status.reasons.expirations.length > 0) {
                    return this.status.reasons.expirations[0];
                } else if (this.status.reasons.inductions.length > 0) {
                    return this.status.reasons.inductions[0];
                } else return '-';
            },
            tooltipContent () {
                if (this.extra > 0) {
                    let str = '';

                    this.status.reasons.expirations.concat(this.status.reasons.inductions).forEach((e, i) => {
                        if (i < 5) {
                            str += e + '.</br>';
                        } else if (i === 5) {
                            str += `${this.extra - 5} más`;
                        }
                    });

                    return str;
                } else return null;
            }
        },
        props: {
            status: {
                type: Object
            }
        }
    }
</script>

export default class User {
    constructor (user) {
        this.user = user;
    }

    user_data () {
        return this.user;
    }





}


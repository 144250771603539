<template>
    <div>
        <div class="card-body table-responsive p-0" v-if="hasCareens">
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th class="align-middle">Fecha carena</th>
                        <th class="align-middle">Observaciones</th>
                        <th class="align-middle">Subido por</th>
                        <th class="align-middle">Descargar</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="c in careens.data" :key="c.id">
                        <td class="align-middle">{{ c.careen_date | myDate }}</td>
                        <td class="align-middle">{{ c.observations | dash }}</td>
                        <td class="align-middle">{{ c.uploader.name }} {{ c.uploader.last_name }}</td>
                        <td class="align-middle">
                            <a :href="downloadFileUrl(
                                    `ship-careens/${c.certificate}`
                                    )" target="_blank">
                                <i class="fas fa-file-download blue"></i>
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
            <pagination :data="careens" @pagination-change-page="getCareens" v-if="hasCareens"/>
        </div>
        <span v-else><i>No se han subido carenas a esta embarcación.</i></span>
    </div>
</template>

<script>
export default {
    created () {
        this.getCareens();
    },
    computed: {
        hasCareens () {
            return this.careens.data.length > 0;
        }
    },
    data () {
        return {
            careens: { data: [] }
        }
    },
    methods: {
        getCareens () {
            this.$Progress.start();
            this.$parent.loading = true;

            axios.get('/api/embarcaciones/' + this.$route.params.id + '/carena')
                .then(({ data }) => {
                    this.careens = data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$launchError('Error al obtener carenas de la embarcación');
                    this.$Progress.fail();
                })
                .finally(() => {
                    this.$Progress.finish();
                    this.$parent.loading = false;
                });
        }
    }
}
</script>

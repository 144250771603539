<template>
    <div>
        <div v-if="hasSupplyRequest">
            <e-p-p/>
            <warehouse-items/>
        </div>
        <span v-else><i>No se ha creado solicitud de abastecimiento para esta tripulación.</i></span>
    </div>
</template>

<script>
    import EPP from "./SupplyRequest/EPP";
    import WarehouseItems from "./SupplyRequest/WarehouseItems";

    export default {
        created () {
            this.getSupplyRequest();
        },
        components: { EPP, WarehouseItems },
        computed: {
            hasSupplyRequest () {
                return this.supplyRequest !== null;
            }
        },
        data () {
            return {
                supplyRequest: null
            }
        },
        methods: {
            getSupplyRequest () {
                this.$Progress.start();
                this.$parent.loading = true;

                axios.get('/api/ficha-tripulacion/' + this.$parent.id + '/solicitud-abastecimiento')
                    .then(response => {
                        this.workers = response.data.data.workers;
                        this.supplyRequest = response.data.data.supply_request;
                        this.$Progress.finish();
                    })
                    .catch(() => {
                        this.$launchError('Error al obtener solicitud de abastecimiento');
                        this.$Progress.fail();
                    })
                    .then(() => this.$parent.loading = false);
            }
        }
    }
</script>

<template>
    <div>
        <div class="card-body table-responsive p-0" v-if="hasTransfers">
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th class="align-middle">Fecha Recogida</th>
                        <th class="align-middle">Entrada</th>
                        <th class="align-middle">Salida</th>
                        <th class="align-middle">Precio</th>
                        <th class="align-middle">Observacion</th>
                        <th class="align-middle">Proveedor</th>
                        <th class="align-middle">Facturar</th>
                        <th class="align-middle">Creado</th>
                        
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="e in exams.data" :key="e.id">
                        <td class="align-middle">{{ e.upload_date | myDate }}</td>
                        <td class="align-middle">{{ e.entry_text }}</td>
                        <td class="align-middle">{{ e.exit_text }}</td>
                        <td class="align-middle">{{ e.price | dash }}</td>
                        <td class="align-middle">{{ e.observation }}</td>
                        <td class="align-middle">{{ e.supplier }}</td>
                        <td class="align-middle">{{ hasQuotation(e.quotation) }}</td>
                        <td class="align-middle">{{ e.created_at | myDate}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <pagination :data="exams" @pagination-change-page="getTransfers" v-if="hasTransfers"/>
        <span v-else><i>No existen traslados para esta persona.</i></span>
    </div>
</template>

<script>
export default {
    created () {
        this.getTransfers();
    },
    computed: {
        hasTransfers () {
            return this.exams.data.length > 0;
        }
    },
    data () {
        return {
            exams:{ data: [] }
        }
    },
    methods: {
        hasQuotation(quotation){
            if(quotation===1){
                return "Si";
            }
            return "No";
        },
        getTransfers (page = 1) {
            this.$Progress.start();
            this.$parent.loading = true;

            axios.get('/api/administracion/personas/' + this.$route.params.id + '/traslados?page=' + page)
                .then(({ data }) => {
                    this.exams.data = data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$launchError('Error al obtener traslados de esta persona');
                    this.$Progress.fail();
                })
                .finally(() => {
                    this.$Progress.finish();
                    this.$parent.loading = false;
                });
        }
    }
}
</script>

<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <loading v-if="loading"/>

                        <!-- header -->
                        <div class="card-header">
                            <h3 class="card-title">Listado de tripulaciones con emergencia</h3>
                        </div>

                        <!-- body -->
                        <div class="card-body table-responsive p-0">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th class="align-middle">Cliente</th>
                                        <th class="align-middle">Embarcación</th>
                                        <th class="align-middle">Fecha presentanción</th>
                                        <th class="align-middle">Fecha bajada real</th>
                                        <th class="align-middle">Fecha bajada protocolar</th>
                                        <th class="align-middle">Estado</th>
                                        <th class="align-middle">Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="c in emergencyButtons.data" :key="c.id">
                                        <td class="align-middle">{{ c.crew.client.name }}</td>
                                        <td class="align-middle">{{ c.crew.ship.name }}</td>
                                        <td class="align-middle">{{ c.crew.upload_date | myDate }}</td>
                                        <td class="align-middle">{{ c.crew.real_download_date | myDate }}</td>
                                        <td class="align-middle">{{ c.crew.download_date | myDate }}</td>
                                        <td class="align-middle">{{ c.crew.status.name }}</td>
                                        <td class="align-middle">
                                            <router-link :to="`/logistica/boton-emergencia/${c.id}`" v-if="c.quotation.entered==0"
                                                         v-tooltip="'Crear cotización'">
                                                <i class="fas fa-cart-arrow-down green"></i>
                                            </router-link>
                                              <router-link :disabled="true" :to="`/logistica/boton-emergencia/cotizacion/${c.quotation.id}/facturar`"
                                                         v-tooltip="'Facturar cotización'" v-else >
                                                <i class="fas fa-file-alt blue"></i>
                                            </router-link>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <!-- footer -->
                        <div class="card-footer">
                            <pagination :data="emergencyButtons" @pagination-change-page="getEmergencyButtons"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Loading from '../Common/Loading';

export default {
    components: { Loading },
    created () {
        this.$Progress.start();
        this.getEmergencyButtons();
        this.$Progress.finish();
    },
    data () {
        return {
            emergencyButtons: {},
            loading: true
        }
    },
    methods: {
        getEmergencyButtons (page = 1) {
            this.loading = true;
            this.$Progress.start();

            axios.get('/api/logistica/boton-emergencia?page=' + page)
                .then(({ data }) => {
                    this.emergencyButtons = data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$launchError('Error al obtener listado de tripulaciones');
                    this.$Progress.fail();
                })
                .finally(() => this.loading = false);
        }
    }
}
</script>

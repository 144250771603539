import { render, staticRenderFns } from "./Headlines.vue?vue&type=template&id=feb18d12&"
import script from "./Headlines.vue?vue&type=script&lang=js&"
export * from "./Headlines.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
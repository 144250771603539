<template>
    <div>
        <div id="accordion" v-if="quotations.tripulantes.length > 0">
           <quotation-details :quotation="quotations"/>
        </div>
        <span v-else><i>No se han creado cotizaciones para esta tripulación.</i></span>
    </div>
</template>

<script>
    import Lodging from "./Logistics/Lodging";
    import QuotationDetails from "./Logistics/QuotationDetails";
    import Relocation from "./Logistics/Relocation";

    export default {
        components: { Lodging, QuotationDetails, Relocation },
        computed: {
            hasQuotations () {
                return this.quotations.length > 0;
            }
        },
        created () {
            this.getQuotations();
        },
        data () {
            return {
                lodgingCrewWorkerId: null,
                lodgingQuotationId: null,
                relocationCrewWorkerId: null,
                relocationQuotationId: null,
                quotations: [],
                showLodgingModal: false,
                showRelocationModal: false,
                alojamientos:false,
                traslados:false,
                tripulantes:false,
                tickets:false
            }
        },
        methods: {
            getQuotations () {
                this.$Progress.start();
                this.$parent.loading = true;

                axios.get('/api/ficha-tripulacion/' + this.$parent.id + '/cotizaciones')
                    .then(response => {
                       // this.quotations = response.data.data.quotations;
                        console.log(response.data.data);
                         
                        this.quotations = response.data.data;
                        if(this.quotations.tickets>0){
                            tickets = true;
                        }
                        if(this.quotations.alojamientos>0){
                            alojamientos = true;
                        }
                        if(this.quotations.traslados>0){
                            traslados = true;
                        }
                        if(this.quotations.tripulantes>0){
                            tripulantes = true;
                        }
                        //console.log(this.quotations);
                        this.$Progress.finish();
                    })
                    .catch(() => {
                        //this.$launchError('Error al obtener cotizaciones');
                        this.$Progress.fail();
                    })
                    .then(() => this.$parent.loading = false);
            },

            viewLodging (quotationId, crewWorkerId) {
                this.lodgingQuotationId = quotationId;
                this.lodgingCrewWorkerId = crewWorkerId;
                this.showLodgingModal = true;
                this.$nextTick(() => $('#lodging-modal').modal('show'));
            },

            viewRelocation (quotationId, crewWorkerId) {
                this.relocationQuotationId = quotationId;
                this.relocationCrewWorkerId = crewWorkerId;
                this.showRelocationModal = true;
                this.$nextTick(() => $('#relocation-modal').modal('show'));
            }
        }
    }
</script>


<template>
    <div>
        <!-- breadcrumb -->
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Detalles pasaje</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link to="/">Inicio</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link to="/logistica/pasajes">Pasajes</router-link>
                            </li>
                            <li class="breadcrumb-item active">Detalles pasaje</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>

        <!-- creación -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col">
                        <div class="card">
                            <loading v-if="loading"/>

                            <!-- tabla de pasajes -->
                            <div class="card-body">
                                <table class="table table-hover">
                                    <thead>
                                        <tr>
                                            <th class="align-middle">Trabajador</th>
                                            <th class="align-middle">RUT</th>
                                            <th class="align-middle">Condición</th>
                                            <th class="align-middle">Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="t in ticket.tickets">
                                            <td class="align-middle">
                                                {{ t.crew_worker.worker.name }} {{ t.crew_worker.worker.last_name }}
                                            </td>
                                            <td class="align-middle">{{ t.crew_worker.worker.rut }}</td>
                                            <td class="align-middle">{{ t.ticket_status.name }}</td>
                                            <td class="align-middle">
                                                <a href="#" @click.prevent="buy(t.id)"
                                                   v-if="t.ticket_status.name === 'No comprado'"
                                                   v-tooltip="'Comprar pasaje'">
                                                    <i class="fa fa-dollar-sign green"></i>
                                                </a>
                                                <a href="#" @click.prevent="toStock(t.id)"
                                                   v-if="t.ticket_status.name === 'No comprado' || t.ticket_status.name === 'Por volar'"
                                                   v-tooltip="'Enviar a stock'">
                                                    <i class="fa fa-ticket-alt blue"></i>
                                                </a>
                                                <span v-else>-</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <!--
                            <div class="card-body table-responsive">
                                <h4 class="ml-4">Resumen</h4>

                                <table class="table table-hover">
                                    <thead>
                                        <tr>
                                            <th class="align-middle">N° cuenta</th>
                                            <th class="align-middle">Nombre cta</th>
                                            <th class="align-middle">Fecha OC</th>
                                            <th class="align-middle">OC</th>
                                            <th class="align-middle">N° factura</th>
                                            <th class="align-middle">Tarifa</th>
                                            <th class="align-middle">Multa</th>
                                            <th class="align-middle">Afecto</th>
                                            <th class="align-middle">Total</th>
                                            <th class="align-middle">C.C.</th>
                                            <th class="align-middle">C.C. cargo</th>
                                            <th class="align-middle">Pasajero</th>
                                            <th class="align-middle">RUT</th>
                                            <th class="align-middle">Cargo</th>
                                            <th class="align-middle">Tramo</th>
                                            <th class="align-middle">Línea</th>
                                            <th class="align-middle">Código</th>
                                            <th class="align-middle">Ruta</th>
                                            <th class="align-middle">Fecha vuelo</th>
                                            <th class="align-middle">Salida</th>
                                            <th class="align-middle">Llegada</th>
                                            <th class="align-middle">Uso real</th>
                                            <th class="align-middle">C.C. final</th>
                                            <th class="align-middle">Condición</th>
                                            <th class="align-middle">Fecha sol.</th>
                                            <th class="align-middle">Observación</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="t in ticket.tickets">
                                            <td class="align-middle">-</td>
                                            <td class="align-middle">-</td>
                                            <td class="align-middle">-</td>
                                            <td class="align-middle">-</td>
                                            <td class="align-middle">-</td>
                                            <td class="align-middle">-</td>
                                            <td class="align-middle">-</td>
                                            <td class="align-middle">-</td>
                                            <td class="align-middle">-</td>
                                            <td class="align-middle">-</td>
                                            <td class="align-middle">-</td>
                                            <td class="align-middle">-</td>
                                            <td class="align-middle">-</td>
                                            <td class="align-middle">-</td>
                                            <td class="align-middle">-</td>
                                            <td class="align-middle">-</td>
                                            <td class="align-middle">-</td>
                                            <td class="align-middle">-</td>
                                            <td class="align-middle">-</td>
                                            <td class="align-middle">-</td>
                                            <td class="align-middle">-</td>
                                            <td class="align-middle">-</td>
                                            <td class="align-middle">-</td>
                                            <td class="align-middle">-</td>
                                            <td class="align-middle">-</td>
                                            <td class="align-middle">-</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            -->
                        </div>
                    </div>
                </div>

                <!-- modal de comprar pasaje -->
                <div class="modal fade" id="buy-ticket-modal" tabindex="-1" role="dialog" aria-labelledby="form-dialog"
                     aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <buy-ticket v-if="showTicketModal"/>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import BuyTicket from "./TicketDetails/BuyTicket";
    import Loading from "../Common/Loading";
    import eventHub from "../../eventHub";

    export default {
        components: { BuyTicket, Loading },
        computed: {
            id () {
                return this.$route.params.id;
            }
        },
        created () {
            this.getDetails();
            eventHub.$on('ticketBought', () => {
                $('#buy-ticket-modal').modal('hide');
                this.getDetails();
            });
        },
        data () {
            return {
                loading: true,
                showTicketModal: false,
                selectedId: null,
                ticket: { tickets: [] }
            }
        },
        methods: {
            buy (id) {
                this.selectedId = id;
                this.showTicketModal = true;
                this.$nextTick(() => $('#buy-ticket-modal').modal('show'));
            },

            getDetails () {
                this.loading = true;
                this.$Progress.start();

                axios.get('/api/logistica/pasajes/' + this.id)
                    .then(({ data }) => {
                        this.ticket = data.data;
                        this.$Progress.finish();
                    })
                    .catch(() => {
                        this.$Progress.fail();
                        this.$launchError('Error al obtener detalles del pasaje');
                    })
                    .finally(() => this.loading = false);
            },

            toStock (id) {
                Swal.fire({
                    title: '¿Estás seguro que deseas enviar este pasaje a stock?',
                    text: 'No podrás revertir esta acción',
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Si',
                    cancelButtonText: 'No'
                }).then((result) => {
                    if (result.value) {
                        axios.get('/api/logistica/pasajes/' + id + '/stock').then(() => {
                            Swal.fire(
                                'Éxito',
                                'El pasaje fue enviado a stock correctamente',
                                'success'
                            );

                            this.getDetails();
                        }).catch(() => Swal.fire(
                            'Error',
                            'No se pudo enviar a stock el pasaje',
                            'warning'
                        ));
                    }
                });
            }
        }
    }
</script>

<template>
    <table class="table table hover">
        <thead>
            <tr>
                <th class="align-middle">Nombre</th>
                <th class="align-middle">Rut</th>
                <th class="align-middle">Rol</th>
                <th class="align-middle">Última embarcación</th>
                <th class="align-middle">Acciones</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="w in workers" :class="{ 'table-danger': w.deleted_at!=null }">
                <td class="align-middle">{{ w.name }} {{ w.last_name }}</td>
                <td class="align-middle">{{ w.rut }}</td>
                <td class="align-middle">{{ w.roles.map(r => r.name) | list }}</td>
                <td class="align-middle">{{ w.last_crew ? w.last_crew.name : '-' }}</td>
                <td class="align-middle">
                    <router-link :to="'/admin/trabajadores/' + w.id">
                        <i class="fa fa-info-circle green"></i>
                    </router-link>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
    export default {
        computed: {
            workers () {
                return this.$parent.workers;
            }
        }
    }
</script>

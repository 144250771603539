<template>

    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <!-- header -->
            <div class="modal-header align-items-center">
                <h5 class="modal-title">Añadir tripulantes {{ available ? 'disponibles' : 'en sala espera' }}</h5>

               <!-- <form class="ml-auto w-50" @submit.prevent="getWorkers">
                    <input type="text" v-model="searchInput" class="form-control"
                           placeholder="Ingrese nombre y/o apellido de tripulante">
                </form>-->

                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <!-- body -->
            <div class="modal-body container">
                <!-- mensaje -->
                <div class="alert alert-warning" role="alert" v-if="!available">
                    Si escoge a una persona de esta lista, tendrá documentos vencidos que deberán ser actualizados antes de poder embarcarse, favor tomar en consideración esta advertencia. Abreviatura : (Des) = Descanso,(Emb) = Embarcado,(Dis) = Disponible,(Vac) = Vacaciones.
                </div>

                <!-- tabla -->
                <div class="table-responsive p-0">

<v-card >
    <v-card-title>
      <v-text-field
        v-model="searchWorkers"
        append-icon="mdi-magnify"
        label="Búsqueda"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
  <v-data-table 
      :headers="headers"
      :items="datosWorkers"
      :search="searchWorkers"
       :loading="loading"
       :items-per-page="10"
       no-results-text="No se han encontrado tripulantes"
       no-data-text="No existen tripulantes"
      class="elevation-1"
        loading-text="Cargando... Espere"
         :footer-props="{
           'items-per-page-text':'Tripulantes por página',
           'items-per-page-options': [10,15,20]
      }"
    >
     <template v-slot:item.accion="{ item, index }">
           <input type="checkbox" v-model="selected" :value="item.id"
                                           @change="toggleSelection(item.id)">      
  </template>

   <template v-slot:item.motivo="{ item, index }">
             <worker-reasons :status="item.motivo"/>
  </template>
  <template v-slot:item.estado="{ item, index }">

               <worker-status :status="item.estado"/>
  </template>


  <template v-slot:footer.page-text="items"> {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }} </template>
</v-data-table>
</v-card>
                   <!-- <table class="table table-hover">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Nombre</th>
                                <th>Rut</th>
                                <th>Rol</th>
                                <th>Última embarcación</th>
                                <th>Estado</th>
                                <th>Motivo</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="w in workers.data">
                                <td class="align-middle">
                                    <input type="checkbox" v-model="selected" :value="w.id"
                                           @change="toggleSelection(w.id)">
                                </td>
                                <td class="align-middle">
                                    <span>
                                        <i class="fas fa-star yellow" v-tooltip="'Titular'" v-if="w.headline_count"/>
                                        {{ w.name }} {{ w.last_name }} <span>{{w.attribute}}</span>
                                    </span>
                                </td>
                                <td class="align-middle">{{ w.rut }}</td>
                                <td class="align-middle">{{ w.roles.map(r => r.name) | list }}</td>
                                <td class="align-middle">{{ w.last_crew ? w.last_crew.name : '-' }}</td>
                                <td class="align-middle"><worker-status :status="w.status.status"/></td>
                                <td class="align-middle"><worker-reasons :status="w.status"/></td>
                            </tr>
                        </tbody>
                    </table>

                    <pagination :data="workers" @pagination-change-page="getWorkers"/>-->
                </div>

                <!-- selección -->
                <div class="tag-input" v-if="selectedData.length > 0">
                    <div v-for="s in selectedData" class="tag-input__tag">
                        <span @click="unselect(s.id)">x</span>
                        {{ s.name }}
                    </div>
                </div>
                <span v-else><i>Aún no se han escogido tripulantes</i></span>

                <!-- footer -->
                <div class="modal-footer">
                    <!-- TODO: color -->
                    <span class="ml-3">
                        {{ $parent.validWorkersLength + selected.length }} / {{ $parent.crew.ship.capacity }}
                    </span>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                    <button type="button" class="btn btn-primary" @click="saveWorkers" :disabled="!canSave">
                        Guardar
                    </button>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import WorkerReasons from "./WorkerReasons";
    import WorkerStatus from "./WorkerStatus";
    import eventHub from "../../../eventHub";

    export default {
        props: {
            available: Boolean
        },
        components: { WorkerReasons, WorkerStatus },
        created () {
            this.getWorkers();
        },
        computed: {
            canSave () {
                return (this.$parent.validWorkersLength + this.selected.length) <= this.$parent.crew.ship.capacity;
            }
        },
        data () {
            return {
                searchInput: null,
                selected: [],
                selectedData: [],
                workers: {},
                searchWorkers:'',
                loading:true,
                loadingtable:false,
                headers: [
                { text: 'Elegir', value: 'accion', sortable: false },
                { text: 'Nombre', align: 'start', value: 'nombre' },
                { text: 'Atributo', value: 'atributo' },
                { text: 'Rut', value: 'rut' },
                { text: 'Rol', value: 'rol' },
       
                { text: 'Estado', value: 'estado' },
                { text: 'Motivo', value: 'motivo' },
                ],
                datosWorkers:[], 
                firstWorkers:{}
            }
        },
        methods: {
            getWorkers (page = 1) {
                //this.$Progress.start();
               // this.loadingtable = true;

                axios.get(`/api/jefe-flota/tripulaciones/${this.$parent.id}/trabajadores-${this.available ? 'disponibles': 'espera'}`)
                    //.then(({ data }) => this.workers = data.data)
                    .then(data => {
                     this.firstWorkers = data.data;

                      for(var i in this.firstWorkers.data){
                          var t = this.firstWorkers.data[i];
                          var roles = this.$options.filters.list(t.roles.map(r => r.name)); 
                          this.datosWorkers.push({nombre:t.name+" "+t.last_name,rut:t.rut,rol:roles,atributo:t.attribute,estado:t.status.status,motivo:t.status,id:t.id,accion:true});
                
                      }
                          
                       
                                    // this.datosWorkers.push(this.workers.data [i].name);
                     console.log(this.datosWorkers);
                    
                       /* for(let i = 0; i < this.workers.length; i++) {
                            var t = this.workers.data[i];
                            var edad = this.$options.filters.age(t.birthdate ); 
                            var roles = this.$options.filters.list(t.roles.map(r => r.name));  
                            this.datosWorkers.push({nombre:t.name+" "+t.last_name,rut:t.rut,edad:edad,rol:roles,atributo:t.attribute,estado:t.status.status,motivo:t.status,id:t.id,accion:true});
                        }
                       console.log(this.workers.data.length);*/
                       this.loading = false;
                      // this.$Progress.finish();
                     })
                    .catch(() => this.$launchError('Error al obtener listado de tripulantes'),this.loading = false)
                    .finally(() => /*this.$Progress.finish(),*/this.loading = false);

                    //console.log(this.workers);
            },

            saveWorkers () {
                if (this.selected.length > 0 && this.canSave) {
                    this.$Progress.start();

                    axios.post(`/api/jefe-flota/tripulaciones/${this.$parent.id}/trabajadores`, {
                        workers: this.selected
                    })
                        .then(() => {
                            eventHub.$emit('workersSaved');
                            this.$launchSuccess('Éxito al guardar tripulantes en tripulación');
                        })
                        .catch(() => this.$launchError('Error al guardar tripulantes en tripulación'))
                        .finally(() => this.$Progress.finish());
                } else {
                    this.$launchError('Debe escoger al menos un tripulante antes de guardar');
                }
            },

            toggleSelection (id) {
                if (this.selected.includes(id)) {
                    let w = this.datosWorkers.find(w => w.id === id);

                    this.selectedData.push({
                        id: id,
                        name: `${w.nombre}`
                    });
                } else {
                    this.selectedData.splice(this.selectedData.findIndex(s => s.id === id), 1);
                }
            },

            unselect (id) {
                this.selected.splice(this.selected.findIndex(s => s === id), 1);
                this.selectedData.splice(this.selectedData.findIndex(s => s.id === id), 1);
            }
        },
   
        
    }
</script>

<!-- TODO: pasar a componente -->
<style scoped>
.tag-input {
    width: 100%;
    border: 1px solid #eee;
    font-size: 0.9em;
    height: 50px;
    box-sizing: border-box;
    padding: 0 10px;
}

.tag-input__tag {
    height: 30px;
    float: left;
    margin-right: 10px;
    background-color: #eee;
    margin-top: 10px;
    line-height: 30px;
    padding: 0 5px;
    border-radius: 5px;
}

.tag-input__tag > span {
    cursor: pointer;
    opacity: 0.75;
}
</style>

<template>
    <div>
        <div class="card-body table-responsive p-0" v-if="hasWorkers">
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th class="align-middle">Nombre</th>
                        <th class="align-middle">Rut</th>
                        <th class="align-middle">Roles</th>
                        <th class="align-middle">Motivo filtro</th>
                        <th class="align-middle">Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="w in workers" :key="w.id">
                        <td class="align-middle">{{ w.worker.name }} {{ w.worker.last_name }}</td>
                        <td class="align-middle">{{ w.worker.rut }}</td>
                        <td class="align-middle">{{ w.worker.roles.map(r => r.name) | list }}</td>
                        <td class="align-middle">
                            {{ w.filter_reason | dash }}
                        </td>
                        <td class="align-middle">
                            <router-link :to="'/admin/trabajadores/' + w.worker.id"
                                         v-tooltip="'Ficha del tripulante'">
                                <i class="fa fa-info-circle green"></i>
                            </router-link>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <span v-else><i>No hay tripulantes filtrados en esta tripulación.</i></span>
    </div>
</template>

<script>
export default {
    computed: {
        hasWorkers () {
            return this.workers.length > 0;
        }
    },
    created () {
        this.getWorkers();
    },
    data () {
        return {
            workers: []
        }
    },
    methods: {
        getWorkers () {
            this.$Progress.start();
            this.$parent.loading = true;

            axios.get('/api/ficha-tripulacion/' + this.$parent.id + '/filtros')
                .then(response => {
                    this.workers = response.data.data.filtered_workers;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$launchError('Error al obtener tripulantes');
                    this.$Progress.fail();
                })
                .then(() => this.$parent.loading = false);
        }
    }
}
</script>

<template>
    <form id="certificates-container" @submit.prevent="saveCertificate()">
        <div class="row mb-4">
            <div class="col form-group">
                <label>Certificación</label>
                <select class="form-control" v-model="form.certificate_id">
                    <option :value="null" disabled>Escoja un certificado</option>
                    <option :value="c.id" v-for="c in certificates">{{ c.name }}</option>
                </select>
            </div>

            <div class="col form-group">
                <label>Emisión</label>
                <input type="date" class="form-control" v-model="form.emission">
            </div>

            <div class="col form-group">
                <label>Expiración</label>
                <input type="date" class="form-control" v-model="form.expiration">
            </div>

            <div class="col form-group">
                <label>Certificado</label><br>
                <div class="custom-file">
                    <input id="certificate" type="file" class="custom-file-input" @change.prevent="upload">
                    <label class="custom-file-label" for="certificate">Escoja un archivo</label>
                </div>
            </div>
        </div>

        <button type="submit" class="btn btn-primary float-right" :disabled="submit">
            <i class="fa fa-spinner fa-spin mr-2" v-if="submit"></i>
            {{ submit ? 'Guardando' : 'Guardar' }}
        </button>
    </form>
</template>

<script>
    export default {
        created () {
            this.getCertificates();
        },
        data () {
            return {
                certificates: [],
                form: {
                    certificate_id: null,
                    emission: null,
                    expiration: null,
                    certificate: null
                },
                submit: false
            }
        },
        methods: {
            getCertificates () {
                this.$parent.loading = true;
                this.$Progress.start();

                axios.get('/api/certificados/' + this.$parent.id)
                    .then(({ data }) => {
                        this.certificates = data.data;
                        this.$Progress.finish();
                    })
                    .catch(() => {
                        this.$Progress.fail();
                        this.$launchError('Error al obtener listado de certificados');
                    })
                    .finally(() => this.$parent.loading = false);
            },

            upload (e) {
                this.form.certificate = e.target.files[0];
            },

            saveCertificate () {
                this.submit = true;
                this.$Progress.start();

                let formData = new FormData();
                formData.append('certificate_id', this.form.certificate_id);
                formData.append('emission', this.form.emission);
                formData.append('expiration', this.form.expiration);
                formData.append('certificate', this.form.certificate);

                axios.post(`/api/embarcaciones/${this.$parent.id}/certificados`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                })
                    .then(() => {
                        this.$Progress.finish();
                        this.$launchSuccess('Éxito al guardar certificado de embarcación');
                        this.form = {
                            certificate_id: null,
                            emission: null,
                            expiration: null,
                            certificate: null
                        }
                    })
                    .catch(() => {
                        this.$Progress.fail();
                        this.$launchError('Error al guardar certificado de embarcación');
                    })
                    .finally(() => this.submit = false);
            }
        }
    }
</script>

<template>
    <div>
       
          <div class="modal-body container">
                                <div class="row">
                                        <div class="col form-group">
                                            <label>Fecha Entrada</label>
                                            <input type="date" class="form-control" v-model="form.entry_date">
                                        </div>
                                </div>
                                <div class="row">
                                        <div class="col form-group">
                                            <label>Fecha Salida</label>
                                            <input type="date" class="form-control" v-model="form.exit_date">
                                        </div>
                                </div>
                                <div class="row">
                                        <div class="col form-group">
                                            <label>Precio</label>
                                            <input type="number" class="form-control" min='0' v-model="form.price">
                                        </div>
                                </div>   
                                <div class="row">
                                        <div class="col form-group">
                                            <label>Proveedor</label>
                                            <select class="form-control" v-model="form.supplier_id"
                                                    :disabled="suppliers.length === 0">
                                                <option :value="null" disabled>Escoja un proveedor</option>
                                                <option :value="i.id" v-for="i in suppliers">{{ i.name }}</option>
                                            </select>
                                        </div>
                                </div>
                                <div class="row">
                                        <div class="col form-group">
                                            <label>Observacion</label>
                                            <input type="text" class="form-control" v-model="form.observation">
                                        </div>
                                </div>
         

                                 
       </div>
    </div>
</template>

<script>
    import Loading from "../Common/Loading";
    import moment from "moment";

    export default {
        components: { Loading },
        props: {
            form: Object
        },
        created () {
            this.getSuppliers();
           // this.form.induction_date = moment(new Date()).format('yyyy-MM-dd');
        },
        data () {
            return {
                loading: false,
                submit: false,
                suppliers:[]
            }
        },
        methods: {
            getSuppliers () {
                this.loading = true;
                this.$Progress.start();

                axios.get('/api/bodega/proveedores/alojamiento')
                    .then(({ data }) => this.suppliers = data.data)
                    .catch(() => {
                        this.$launchError('Error al obtener listado de proveedores');
                        this.$Progress.fail();
                    })
                    .finally(() => {
                        this.loading = false;
                        this.$Progress.finish();
                    });
            },
        }
    }
</script>

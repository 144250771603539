<template>
    <div>
        <div class="row">
            <div class="col form-group">
                <label for="year">Año</label>
                <select name="year" id="year" class="form-control" v-model="selectedYear" @change="getIMC">
                    <option :value="y" v-for="y in years">{{ y }}</option>
                </select>
            </div>
        </div>

        <div class="row">
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th class="align-middle">Mes</th>
                        <th class="align-middle">Estatura</th>
                        <th class="align-middle">Peso</th>
                        <th class="align-middle">IMC</th>
                        <th class="align-middle">Subido por</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="m in months">
                        <td class="align-middle">{{ m.name }}</td>
                        <td class="align-middle">{{ imc[m.index] ? imc[m.index].height : '-' }}</td>
                        <td class="align-middle">{{ imc[m.index] ? imc[m.index].weight : '-' }}</td>
                        <td class="align-middle">{{ imc[m.index] ? imc[m.index].imc : '-' }}</td>
                        <td class="align-middle">
                            {{ imc[m.index] ? `${imc[m.index].uploader.name} ${imc[m.index].uploader.last_name}` : '-' }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    export default {
        computed: {
            imc () {
                let e = {};
                let actually = this.imcData.map(i => parseInt(i.month));
                let diff = this.months.map(m => m.index).filter(m => !actually.includes(m));

                this.imcData.forEach(i => e[i.month] = i);

                diff.forEach(d => e[d] = null);

                return e;
            }
        },
        created () {
            this.getIMC();
        },
        data () {
            return {
                imcData: [],
                months: [
                    { index: 0, name: 'Enero' },
                    { index: 1, name: 'Febrero' },
                    { index: 2, name: 'Marzo' },
                    { index: 3, name: 'Abril' },
                    { index: 4, name: 'Mayo' },
                    { index: 5, name: 'Junio' },
                    { index: 6, name: 'Julio' },
                    { index: 7, name: 'Agosto' },
                    { index: 8, name: 'Septiembre' },
                    { index: 9, name: 'Octubre' },
                    { index: 10, name: 'Noviembre' },
                    { index: 11, name: 'Diciembre' }
                ],
                selectedYear: 2021,
                years: [2020, 2021, 2022]
            }
        },
        methods: {
            getIMC () {
                this.$Progress.start();

                axios.get(`/api/trabajadores/${this.$parent.id}/imc/${this.selectedYear}`)
                    .then(({ data }) => {
                        this.imcData = data.data;
                        this.$Progress.finish();
                    })
                    .catch(() => {
                        this.$Progress.fail();
                        this.$launchError('Error al obtener datos de IMC');
                    })
            }
        }
    }
</script>

<template>
    <div class="card-body box-profile">
        <ul class="list-group list-group-unbordered mb-3">
            <li class="list-group-item">
                <b>Fecha creación</b> <a class="float-right">{{ crew.created_at | myDate }}</a>
            </li>

            <li class="list-group-item">
                <b>Cliente</b> <a class="float-right">{{ crew.client.name }}</a>
            </li>

            <li class="list-group-item">
                <b>Embarcación</b> <a class="float-right">{{ crew.ship.name }}</a>
            </li>

            <li class="list-group-item">
                <b>Fecha presentación</b> <a class="float-right">{{ crew.upload_date | myDate }}</a>
            </li>

            <li class="list-group-item">
                <b>Fecha bajada real</b> <a class="float-right">{{ crew.real_download_date | myDate }}</a>
            </li>

            <li class="list-group-item">
                <b>Fecha bajada protocolar</b> <a class="float-right">{{ crew.download_date | myDate }}</a>
            </li>

            <li class="list-group-item">
                <b>Estado</b> <a class="float-right">{{ crew.status.name }}</a>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        computed: {
            crew () {
                return this.$parent.crew;
            }
        }
    }
</script>

<template>

  <section class="content">
      <div class="container-fluid">
          <div class="row">
              <div class="col-12">
                  <div class="card">
                      <loading v-if="loading"/>

                      <div class="card-header">
                          <div class="row align-items-center">
                              <h3 class="col card-title">Lista de Traslados</h3>

                              <div class="col-6">
                                 <!-- <form @submit.prevent="getTransfers">
                                      <input type="text" v-model="searchInput" class="form-control"
                                             placeholder="Ingrese busqueda por entrada, salida, proveedor, observacion, fecha recogida">
                                  </form>-->
                              </div>

                              <div class="col-2">
                                

                                 <router-link to="nuevo-traslado" class="btn btn-sm btn-primary" style="color: #ffffff !important;">
                                    <i class="fa fa-plus-square"></i>
                                    Nuevo Traslado
                                </router-link>
                              </div>
                          </div>
                      </div>


  <div style="height:1px !important;">
 <v-app>
 <v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Búsqueda"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
  <v-data-table
      :headers="headers"
      :items="datos"
      :search="search"
       :loading="loading"
       :items-per-page="30"
       no-results-text="No se han encontrado traslados"
       no-data-text="No existen traslados"
      class="elevation-1"
        loading-text="Cargando... Espere"
         :footer-props="{
           'items-per-page-text':'Traslados por página',
           'items-per-page-options': [20, 50, 100, -1]
      }"
    >
 <template v-slot:item.accion="{ item, index }">

                                         <router-link :to="`/editar-traslado/${item.id}`" 
                                                       v-tooltip="'Editar Traslado'">
                                               <v-icon small style="color: #3490DC !important;">
                                                fa-edit
                                                </v-icon>
                                          </router-link>
                          
  </template>
  <template v-slot:footer.page-text="items"> {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }} </template>
</v-data-table>
</v-card> 
 </v-app>
</div>
                        <!--
                      <div class="card-body table-responsive p-0">
                          <table class="table table-hover">
                              <thead>
                                  <tr>
                                      <th class="align-middle">Reservas</th>
                                      <th class="align-middle">Fecha Recogida</th>
                                      <th class="align-middle">Entrada</th>
                                      <th class="align-middle">Salida</th>
                                      <th class="align-middle">Precio</th>
                                      <th class="align-middle">Proveedor</th>
                                      <th class="align-middle">Observacion</th>
                                      <th class="align-middle">Facturar</th>
                                      <th class="align-middle">Opciones</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr v-for="t in transfers.data" :key="t.id">
                                      <td class="align-middle">{{ t.quantity }}</td>
                                      <td class="align-middle">{{ t.upload_date | myDate }}</td>
                                      <td class="align-middle">{{ t.entry_text }}</td>
                                      <td class="align-middle">{{ t.exit_text }}</td>
                                      <td class="align-middle">{{ t.price | dash}}</td>
                                      <td class="align-middle">{{ t.supplier }}</td>
                                      <td class="align-middle">{{ t.observation }}</td>
                                      <td class="align-middle">{{ quotation(t.quotation) }}</td>
                                      <td>
                                       <router-link :to="`/editar-traslado/${t.id}`" 
                                                       v-tooltip="'Editar Traslado'">
                                              <i class="fa fa-edit blue"></i>
                                          </router-link>
                                      </td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                      <div class="card-footer">
                          <pagination :data="transfers" @pagination-change-page="getTransfers"/>
                      </div>

                      -->
                  </div>
              </div>
          </div>

       
      </div>
  </section>
   
</template>

<script>
    import Loading from "./Common/Loading";

    export default {
        components: { Loading},
        created () {
            this.$Progress.start();
            this.getTransfers();
            this.$Progress.finish();
        },
        data () {
            return {
                loading: true,
                searchInput: null,
                transfers: {},
                search: '',
                headers: [
                {
                    text: 'Reservas',
                    align: 'start',
                    value: 'reservas',
                },
                { text: 'Fecha Recogida', value: 'subida' },
                { text: 'Entrada', value: 'bajada' },
                { text: 'Salida', value: 'salida' },
                { text: 'Precio Total', value: 'precio' },
                { text: 'Proveedor', value: 'proveedor' },
                { text: 'Observacion', value: 'observacion' },
                { text: 'Facturar', value: 'facturar' },
                { text: 'Opciones', value: 'accion', sortable: false },
                ],
                datos:[],                   
            }
        },
        methods: {
              quotation(plus){
                if(plus==1){
                    return "Si";
                }
                return "No";
            },
            
            getTransfers (page = 1) {
                this.$Progress.start();
                this.loading = true;

                axios.get('/api/administracion/traslados')
                    //.then(({ data }) => this.transfers = data)
                    .then(data => {
                    this.transfers = data.data;
                     console.log(this.transfers);
                        for(let i = 0; i < this.transfers.data.length; i++) {
                            var t = this.transfers.data[i];
                            var precio = this.$options.filters.dotNumber(this.$options.filters.dash(t.price));  
                              var subida = this.$options.filters.myDate(t.upload_date);  
                              //var bajada = this.$options.filters.myDate(t.exit_date);  
                            this.datos.push({reservas:t.quantity,subida:subida,bajada:t.entry_text,salida:t.exit_text,precio:"$"+precio,proveedor:t.supplier,observacion:t.observation,facturar:this.quotation(t.quotation),id:t.id,accion:true});
                        }
                       console.log(this.datos);
                     })
                    .catch(() => this.$launchError('Error al obtener listado de traslados'))
                    .finally(() => {
                        this.$Progress.finish();
                        this.loading = false;
                    });
            },


            deleteWorker (id) {
                Swal.fire({
                    title: '¿Estás seguro que deseas eliminar este traslado?',
                    text: 'No podrás revertir esta acción',
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Si',
                    cancelButtonText: 'No'
                }).then((result) => {
                    if (result.value) {
                        this.form.delete('/api/administracion/personas/' + id).then(() => {
                            Swal.fire(
                                'Éxito',
                                'La persona fue eliminada correctamente',
                                'success'
                            );

                            this.getPersons();
                        }).catch(() => Swal.fire(
                            'Error',
                            'Error al eliminar persona',
                            'warning'
                        ));
                    }
                });
            }
        }
    }
</script>
<style>
  .v-application--wrap {
    min-height: 0vh !important;
  }
</style>
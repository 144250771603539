<template>
    <form @submit.prevent="saveContract">
        <div class="row">
            <div class="col form-group">
                <label>Fecha de contrato</label>
                <input v-model="form.date" type="date" name="fecha contrato" class="form-control"
                       :class="{ 'is-invalid': form.errors.has('date') }">
                <has-error :form="form" field="date"></has-error>
            </div>

            <div class="col form-group">
                <label>Sueldo</label>
                <input v-model.number="form.salary" type="number" min="0" name="sueldo" class="form-control"
                       :class="{ 'is-invalid': form.errors.has('salary') }">
                <has-error :form="form" field="salary"></has-error>
            </div>
        </div>

        <div class="row">
            <div class="col form-group">
                <label>AFP</label>
                <select v-model="form.afp_id" name="AFP" class="form-control"
                       :class="{ 'is-invalid': form.errors.has('afp_id') }">
                    <option :value="null" disabled>Escoja una AFP</option>
                    <option :value="a.id" v-for="a in afps">{{ a.name }}</option>
                </select>
                <has-error :form="form" field="afp_id"></has-error>
            </div>

            <div class="col form-group">
                <label>Previsión de salud</label>
                <select v-model="form.health_forecast_id" name="AFP" class="form-control"
                        :class="{ 'is-invalid': form.errors.has('health_forecast_id') }">
                    <option :value="null" disabled>Escoja una previsión de salud</option>
                    <option :value="h.id" v-for="h in healthForecasts">{{ h.name }}</option>
                </select>
                <has-error :form="form" field="health_forecast_id"></has-error>
            </div>
        </div>

        <div class="row">
            <div class="col form-group">
                <label>Tipo de contrato</label>
                <select v-model="form.contract_type_id" type="date" name="tipo contrato" class="form-control"
                        :class="{ 'is-invalid': form.errors.has('contract_type_id') }">
                    <option :value="null" disabled>Escoja un tipo de contrato</option>
                    <option :value="t.id" v-for="t in contractTypes">{{ t.name | capitalize }}</option>
                </select>
                <has-error :form="form" field="contract_type_id"></has-error>
            </div>

            <div class="col form-group">
                <label>Copia de carnet</label>
                <div class="custom-file">
                    <input id="identity-card" type="file" class="custom-file-input"
                           @change.prevent="uploadIdentityCard">
                    <label class="custom-file-label" for="identity-card">
                        {{ filesName.identityCard ? filesName.identityCard : 'Escoja un archivo' }}
                    </label>
                </div>
                <has-error :form="form" field="identity_card_copy"/>
            </div>
        </div>

        <div class="row">
            <div class="col-6 form-group">
                <label>Matrícula de embarque</label>
                <div class="custom-file">
                    <input id="boarding-license" type="file" class="custom-file-input"
                           @change.prevent="uploadBoardingLicense">
                    <label class="custom-file-label" for="boarding-license">
                        {{ filesName.boardingLicense ? filesName.boardingLicense : 'Escoja un archivo' }}
                    </label>
                </div>
                <has-error :form="form" field="boarding_license"/>
            </div>

           
             <div class="col-6 form-group" v-if="send_email==false || send_email==null">
                <label>Dias Rojos:</label>
                <div class="custom-file">
                        <select  v-model="form.red_day" class="form-control">
                            <option value='0'>No</option>
                            <option value='1'>Si</option>
                        </select>
                </div>
            </div>
        </div>






        <div class="float-right">
            <button type="submit" class="btn btn-primary" :disabled="submit">
                <i class="fa fa-spinner fa-spin mr-2" v-if="submit"></i>
                {{ submit ? 'Guardando' : 'Guardar' }}
            </button>
        </div>
    </form>
</template>

<script>
    export default {
        created () {
            this.$Progress.start();
            this.getContract();
            this.getAfps();
            this.getHealthForecasts();
            this.getContractTypes();
            this.$Progress.finish();
        },
        data () {
            return {
                send_email:null,
                afps: [],
                healthForecasts: [],
                contractTypes: [],
                filesName: {
                    identityCard: null,
                    boardingLicense: null
                },
                files: {
                    identityCard: null,
                    boardingLicense: null
                },
                form: new Form({
                    date: null,
                    salary: null,
                    afp_id: null,
                    health_forecast_id: null,
                    contract_type_id: null,
                    red_day:0
                }),
                original: {
                    boarding_license: null,
                    identity_card_copy: null
                },
                submit: false
            }
        },
        methods: {
            getContract () {
                this.$parent.loading = true;

                axios.get(`/api/trabajadores/${this.$parent.id}/contrato`)
                    .then(({ data }) => {
                        if (data.data) {
                            this.original = JSON.parse(JSON.stringify(data.data));
                            this.form.fill(data.data);
                            this.filesName.identityCard = data.data.identity_card_copy;
                            this.filesName.boardingLicense = data.data.boarding_license;
                            this.send_email = data.data.send_email;
                            console.log(data);

                        }
                    })
                    .catch(() => this.$launchError('Error al obtener contrato de tripulante'));
            },

            getAfps () {
                axios.get('/api/afps').then(({ data }) => this.afps = data.data)
                    .catch(() => this.$launchError('Error al obtener listado de AFP'));
            },

            getHealthForecasts () {
                axios.get('/api/previsiones-salud').then(({ data }) => this.healthForecasts = data.data)
                    .catch(() => this.$launchError('Error al obtener listado de previsiones de salud'));
            },

            getContractTypes () {
                axios.get('/api/tipos-contrato').then(({ data }) => this.contractTypes = data.data)
                    .catch(() => this.$launchError('Error al obtener listado de tipos de contrato'))
                    .finally(() => this.$parent.loading = false);
            },

            saveContract () {
                Swal.fire({
                    title: '¿Estás seguro que deseas modificar el contrato?',
                    text: 'No podrás revertir esta acción',
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Si',
                    cancelButtonText: 'No'
                }).then((result) => {
                    if (result.value) {
                        this.$Progress.start();
                        this.submit = true;

                        // TODO: debe verificar lo que es distinto, y solo modificar eso
                        let formData = new FormData();

                        // verifica si añade fecha a los cambios
                        if (this.form.date !== null && (this.form.date !== this.original.date)) {
                            formData.append('date', this.form.date);
                        }

                        // verifica si añade sueldo a los cambios
                        if (this.form.salary !== null && (this.form.salary !== this.original.salary)) {
                            formData.append('salary', this.form.salary);
                        }

                        // verifica si añade afp a los cambios
                        if (this.form.afp_id !== null && (this.form.afp_id !== this.original.afp_id)) {
                            formData.append('afp_id', this.form.afp_id);
                        }

                        // verifica si añade previsión de salud a los cambios
                        if (this.form.health_forecast_id !== null && (this.form.health_forecast_id !== this.original.health_forecast_id)) {
                            formData.append('health_forecast_id', this.form.health_forecast_id);
                        }

                        // verifica si añade tipo de contrato a los cambios
                        if (this.form.contract_type_id !== null && (this.form.contract_type_id !== this.original.contract_type_id)) {
                            formData.append('contract_type_id', this.form.contract_type_id);
                        }

                        // verifica si añade copia de carne a los cambios
                        if (this.filesName.identityCard !== this.original.identity_card_copy) {
                            formData.append('identity_card_copy', this.files.identityCard);
                        }

                        // verifica si añade matrícula de embarque a los cambios
                        if (this.filesName.boardingLicense !== this.original.boarding_license) {
                            formData.append('boarding_license', this.files.boardingLicense);
                        }

                        formData.append('red_day', this.form.red_day);

                        axios.post(`/api/trabajadores/${this.$parent.id}/contrato`, formData)
                            .then(() => {
                                Swal.fire(
                                    'Éxito',
                                    'Éxito al guardar contrato de tripulante',
                                    'success'
                                );
                                this.$Progress.finish();
                            })
                            .catch(() => {
                                Swal.fire(
                                    'Error',
                                    'Error al guardar contrato de tripulante',
                                    'warning'
                                );
                                this.$Progress.fail();
                            })
                            .finally(() => this.submit = false);
                    }
                });
            },

            uploadIdentityCard (e) {
                const f = e.target.files[0];
                this.filesName.identityCard = f.name;
                this.files.identityCard = f;
            },

            uploadBoardingLicense (e) {
                const f = e.target.files[0];
                this.filesName.boardingLicense = f.name;
                this.files.boardingLicense = f;
            }
        }
    }
</script>

<template>
<v-app>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <loading v-if="loading"/>

                        <!-- header -->
                        <div class="card-header">
                            <h3 class="card-title">Listado de Documentos</h3>

                            <div class="card-tools">
                                <button type="button" class="btn btn-sm btn-primary" @click="creationModal">
                                    <i class="fa fa-plus-square"></i>
                                    Nuevo Documento
                                </button>
                            </div>
                        </div>

 <v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Búsqueda"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
  <v-data-table
      :headers="headers"
      :items="datos"
      :search="search"
       :loading="loading"
       :items-per-page="30"
       no-results-text="No se han encontrado documentos"
       no-data-text="No existen documentos"
      class="elevation-1"
        loading-text="Cargando... Espere"
         :footer-props="{
           'items-per-page-text':'Documentos por página',
           'items-per-page-options': [20, 50, 100, -1]
      }"
    >
 <template v-slot:item.accion="{ item, index }">

                                            <a :href="downloadFileUrl(
                                                `company-documents/${item.filename}`
                                                )" target="_blank">
                                            
                                                   <v-icon small style="color: #3490DC !important;">
                                                fas fa-file-download
                                                </v-icon>
                                            </a>

                                                                        
  </template>
  <template v-slot:footer.page-text="items"> {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }} </template>
</v-data-table>
</v-card> 
                        <!-- 
                        <div class="card-body table-responsive p-0">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                       
                                        <th class="align-middle">Tipo</th>
                                        <th class="align-middle">Observación</th>
                                        <th class="align-middle">Fecha Subida</th>
                                        <th class="align-middle">Fecha Caducidad</th>
                                        <th class="align-middle">Subido Por</th>
                                        <th class="align-middle">Descargar</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="i in documents">
                                        <td class="align-middle">{{ i.type }}</td>
                                        <td class="align-middle">{{ i.observation }}</td>
                                        <td class="align-middle">{{ i.created_at | myDate }}</td>
                                        <td class="align-middle">{{ i.expiration_date }}</td>
                                        <td class="align-middle">{{ i.uploader_name }} {{ i.uploader_last_name}}</td>
                                        <td class="align-middle">
                                            <a :href="downloadFileUrl(
                                                `company-documents/${i.filename}`
                                                )" target="_blank">
                                                <i class="fas fa-file-download blue"></i>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>-->
                        <!-- footer -->
                        <!--<div class="card-footer">
                            <pagination :data="documents" @pagination-change-page="getDocuments"/>
                        </div>-->
                    </div>
                </div>
            </div>

            <!-- Modal -->
            <div class="modal fade" id="form-modal" tabindex="-1" role="dialog" aria-labelledby="form-dialog"
                 aria-hidden="true" data-backdrop="static" data-keyboard="false">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">{{ editionModalId ? 'Editar': 'Crear nuevo' }} documento</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <form>
                            <div class="modal-body container">
                                <div class="row">
                                        <div class="col form-group">
                                            <label>Documento</label>
                                            <select class="form-control" v-model="form.document_id"
                                                    >
                                                <option :value="null" disabled>Escoja tipo de documento</option>
                                                <option :value="2">Manual EPP</option>
                                                <option :value="1">Seguro de vida</option>
                                            </select>
                                        </div>
                                 </div>
                                <div class="row" v-if="form.document_id==1">

                                        <div class="col form-group">
                                            <label>Fecha caducidad</label>
                                            <input type="date" class="form-control" v-model="form.expiration_date">
                                        </div>
                                </div>

                                <div class="row">

                                        <div class="col form-group">
                                            <label>Observación</label>
                                            <input type="text" class="form-control" v-model="form.observation">
                                        </div>
                                </div>

                                <div class="row">
                                        <div class="col form-group">
                                            <label>Archivo</label><br>
                                            <div class="custom-file">
                                                <input id="certificate" type="file" class="custom-file-input"
                                                       v-on:change="upload" accept="application/pdf" ref="imageUploader">
                                                <label class="custom-file-label" for="certificate">
                                                    {{ fileName ? fileName : 'Escoja un archivo' }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                                    <button type="button" class="btn btn-primary" @click.prevent="createDocument" :disabled="submit===true">
                                        Guardar
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </v-app>
</template>

<script>
    import InductionForm from "./Form";
    import Loading from "../Common/Loading";

    export default {
        components: { Loading ,InductionForm},
        created () {
            this.getDocuments();
        },
        data () {
            return {
                file: null,
                editionModalId: null,
                documents:[],
                certificate:null,
                fileName:null,
                form: new Form({ document_id: null, expiration_date: null,observation:null }),
                loading: false,
                submit:false,
                  search: '',
                headers: [
                {
                    text: 'Tipo',
                    align: 'start',
                    value: 'tipo',
                },
                { text: 'Observación', value: 'observacion' },
                { text: 'Fecha Subida', value: 'subida' },
                { text: 'Fecha Caducidad', value: 'caducidad' },
                { text: 'Subido Por', value: 'subidopor' },

                { text: 'Descargar', value: 'accion', sortable: false },
                ],
                datos:[],
            }
        },
        methods: {
             createDocument() {
                 if(this.certificate!=null){
                        Swal.fire({
                    title: '¿Estás seguro que deseas guardar este documento?',
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Si',
                    cancelButtonText: 'No'
                }).then((result) => {
                    if (result.isConfirmed==true) {
                        this.$Progress.start();
                        this.submit = true;
                        let formData = new FormData();
                        formData.append('document_id', this.form.document_id);
                        formData.append('expiration_date', this.form.expiration_date);
                        formData.append('fileName', this.fileName);
                        formData.append('certificate', this.certificate);
                        formData.append('observation', this.form.observation);

                        axios.post(`/api/company/documents`, formData, {
                            headers: { 'Content-Type': 'multipart/form-data' }
                        })
                            .then(() => {
                                Swal.fire(
                                    'Éxito',
                                    'El documento fue registrado correctamente',
                                    'success'
                                );
                                this.getDocuments();
                                this.form.document_id = null;
                                this.form.expiration_date = null;
                                this.fileName = null;
                                this.form.observation = null;
                                this.certificate = null;
                                this.file = null;
                                this.$Progress.finish();
                                this.form.reset();
                                $('#form-modal').modal('hide');
                                this.submit =false;
                                
                                this.$refs.imageUploader.value = '';
                                
                            })
                            .catch(() => {
                                Swal.fire(
                                    'Error',
                                    'No se pudo registrar el documento',
                                    'warning'
                                )
                                this.$Progress.fail();
                                this.form.reset();
                                this.submit = false;
                                $('#form-modal').modal('hide');
                            });
                      }
                    });
                 }else{
                     Swal.fire(
                                    'Error',
                                    'Debes seleccionar un certificado',
                                    'warning'
                                )
                 }
             
            },

            creationModal () {
                this.editionModalId = null;
                this.form.reset();
                $('#form-modal').modal('show');
            },

            getDocuments (page = 1) {
                this.$Progress.start();
                this.loading = true;

                axios.get('/api/company/documents')
                   /* .then(({ data }) => {
                        this.documents = data.data;
                        this.$Progress.finish();
                    })*/
                    .then(data => {
                    this.datos = [];
                    this.documents = data.data;
                     console.log(this.documents);
                        for(let i = 0; i < this.documents.data.length; i++) {
                            var t = this.documents.data[i];
                            var fecha = this.$options.filters.myDate(t.created_at ); 
                            var caducidad = this.$options.filters.myDate(t.expiration_date );  
                            var subido = this.$options.filters.capitalize(t.uploader_name)+" "+this.$options.filters.capitalize(t.uploader_last_name);  
                            var obs = "Sin Observación";
                            if(t.observation!="null" && t.observation!=""){
                                obs = t.observation;
                            }
                            this.datos.push({tipo: t.type,observacion:obs,subida:fecha,caducidad:caducidad,subidopor:subido,filename:t.filename,id:t.id,accion:true});
                        }
                       console.log(this.datos);
                       this.$Progress.finish();
                     })
                    .catch(() => {
                        this.$Progress.finish();
                        this.$launchError('Error al obtener listado de documentos');
                    })
                    .finally(() => this.loading = false);
            },

             upload (e) {
                const f = e.target.files[0];
                this.certificate = f;
                this.fileName = f.name;
            },

            
        }
    }
</script>

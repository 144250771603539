<template>
    <div>
        <ul v-if="contract">
            <li>
                <strong>Tipo :</strong>
                {{ !contract.contract_type ? '-' : contract.contract_type.name | capitalize }}.
            </li>
            <li>
                <strong>Fecha contrato :</strong>
                {{ contract.date | myDate }}.
            </li>
            <li>
                <strong>Antigüedad :</strong>
                <span v-if="contract.date">
                    {{ contract.date | antiquity }} años.
                </span>
                <span v-else>-</span>
            </li>
            <li>
                <strong>Sueldo :</strong>
                <span v-if="contract.salary !== null">
                    ${{ contract.salary | dotNumber }}.
                </span>
                <span v-else>-</span>
            </li>
            <li>
                <strong>AFP :</strong>
                {{ !contract.afp ? '-' : contract.afp.name | capitalize }}.
            </li>
            <li>
                <strong>Previsión de salud :</strong>
                {{ !contract.health_forecast ? '-' : contract.health_forecast.name | capitalize }}.
            </li>
            <li>
                <strong>Copia de carnet :</strong>
                <a :href="downloadFileUrl(`/identity-card-copies/${contract.identity_card_copy}`)"
                   target="_blank" v-if="contract.identity_card_copy">
                    Descargar <i class="fas fa-file-download"></i>.
                </a>
                <i v-else>Aún no se ha subido una copia de carnet.</i>
            </li>
            <li>
                <strong>Matrícula de embarque :</strong>
                <a :href="downloadFileUrl(`/boarding-licenses/${contract.boarding_license}`)"
                   target="_blank" v-if="contract.boarding_license">
                    Descargar <i class="fas fa-file-download"></i>.
                </a>
                <i v-else>Aún no se ha subido una matrícula de embarque.</i>
            </li>
        </ul>
        <p v-else>
            <i>Este tripulante aún no tiene contrato.</i>
        </p>
    </div>
</template>

<script>
    export default {
        computed: {
            contract () {
                return this.$parent.worker.contract;
            }
        }
    }
</script>

<template>
 <v-app>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <!-- header -->
                        <div class="card-header">
                            <h3 class="card-title">Listado de tripulaciones</h3>
                        </div>


                       <v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Búsqueda"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
  <v-data-table
      :headers="headers"
      :items="datos"
      :search="search"
       :loading="loading"
       :items-per-page="30"
       no-results-text="No se han encontrado tripulaciones"
       no-data-text="No existen tripulaciones"
      class="elevation-1"
        loading-text="Cargando... Espere"
         :footer-props="{
           'items-per-page-text':'Tripulaciones por página',
           'items-per-page-options': [20, 50, 100, -1]
      }"
    >
 <template v-slot:item.accion="{ item, index }">

                                            <router-link :to="`/supervisor/tripulaciones/${item.id}/filtro-tripulacion`"
                                                         v-if="item.status.name === 'Filtro tripulación'">
                                              
                                                <v-icon small style="color: #3490DC !important;" v-tooltip="'Filtro tripulación'" >
                                                fa fa-filter
                                                </v-icon>
                                            </router-link>
                                            <router-link :to="`/supervisor/tripulaciones/${item.id}/solicitud-abastecimiento`"
                                                         v-else-if="item.status.name === 'En calendario'">
                                                         <v-icon small style="color: #3490DC !important;" v-tooltip="'Solicitud de abastecimiento'" >
                                               far fa-file-alt
                                                </v-icon>
                                            </router-link>
                                                      
  </template>
  <template v-slot:footer.page-text="items"> {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }} </template>
</v-data-table>
</v-card> 

                        <!-- 
                        <div class="card-body table-responsive p-0">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th class="align-middle">Cliente</th>
                                        <th class="align-middle">Embarcación</th>
                                        <th class="align-middle">Fecha presentanción</th>
                                        <th class="align-middle">Fecha bajada real</th>
                                        <th class="align-middle">Fecha bajada protocolar</th>
                                        <th class="align-middle">Estado</th>
                                        <th class="align-middle">Acciones</th>
                                    </tr>
                                </thead>
                                <tbody v-if="$gate.isAdmin() || $gate.isGerente()">
                                    <tr v-for="c in crews.data" :key="c.id">
                                        <td class="align-middle">{{ c.client.name }}</td>
                                        <td class="align-middle">{{ c.ship.name }}</td>
                                        <td class="align-middle">{{ c.upload_date | myDate }}</td>
                                        <td class="align-middle">{{ c.real_download_date | myDate }}</td>
                                        <td class="align-middle">{{ c.download_date | myDate }}</td>
                                        <td class="align-middle">{{ c.status.name }}</td>
                                        <td class="align-middle">
                                            <router-link :to="`/supervisor/tripulaciones/${c.id}/filtro-tripulacion`"
                                                         v-if="c.status.name === 'Filtro tripulación'">
                                                <i class="fa fa-filter blue" v-tooltip="'Filtro tripulación'"/>
                                            </router-link>
                                            <router-link :to="`/supervisor/tripulaciones/${c.id}/solicitud-abastecimiento`"
                                                         v-else-if="c.status.name === 'En calendario'">
                                                <i class="far fa-file-alt blue"
                                                   v-tooltip="'Solicitud de abastecimiento'"/>
                                            </router-link>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else="(c.ship.supervisor_id === $user.user_data().id) && $gate.isSupervisor()">
                                    <tr v-for="c in crews.data" :key="c.id" v-if="(c.ship.supervisor_id === $user.user_data().id) && $gate.isSupervisor()">
                                        <td class="align-middle">{{ c.client.name }}</td>
                                        <td class="align-middle">{{ c.ship.name }}</td>
                                        <td class="align-middle">{{ c.upload_date | myDate }}</td>
                                        <td class="align-middle">{{ c.real_download_date | myDate }}</td>
                                        <td class="align-middle">{{ c.download_date | myDate }}</td>
                                        <td class="align-middle">{{ c.status.name }}</td>
                                        <td class="align-middle">
                                            <router-link :to="`/supervisor/tripulaciones/${c.id}/filtro-tripulacion`"
                                                         v-if="c.status.name === 'Filtro tripulación'">
                                                <i class="fa fa-filter blue" v-tooltip="'Filtro tripulación'"/>
                                            </router-link>
                                            <router-link :to="`/supervisor/tripulaciones/${c.id}/solicitud-abastecimiento`"
                                                         v-else-if="c.status.name === 'En calendario'">
                                                <i class="far fa-file-alt blue"
                                                   v-tooltip="'Solicitud de abastecimiento'"/>
                                            </router-link>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                     
                        <div class="card-footer">
                            <pagination :data="crews" @pagination-change-page="obtenerTripulaciones"></pagination>
                        </div>

                        -->
                    </div>
                </div>
            </div>
        </div>
    </section>
     </v-app>
</template>

<script>
    export default {
        created () {
            this.$Progress.start();
            this.obtenerTripulaciones();
            this.$Progress.finish();
        },
        data () {
            return {
                crews: {},
                loading:false,
                 search: '',
                headers: [
                {
                    text: 'Cliente',
                    align: 'start',
                    sortable: false,
                    value: 'cliente',
                },
                { text: 'Embarcación', value: 'barco' },
                { text: 'Fecha de Presentacion', value: 'presentacion' },
                { text: 'Fecha bajada real', value: 'real' },
                { text: 'Fecha bajada protocolar', value: 'protocolar' },
                { text: 'Estado', value: 'estado' },
                { text: 'Acciones', value: 'accion', sortable: false },
                ],
                datos:[],
            }
        },
        methods: {
            obtenerTripulaciones (page = 1) {
                this.$Progress.start();

                axios.get('/api/supervisor/tripulaciones')
                   // .then(({ data }) => this.crews = data.data)
                    .then(data => {
                    this.crews = data.data;
                     console.log(this.crews);
                        for(let i = 0; i < this.crews.data.length; i++) {
                            var eldato = this.crews.data[i];
                            if(this.$gate.isAdmin() || this.$gate.isGerente()){
                                     this.datos.push({id:eldato.id,status:eldato.status,cliente:eldato.client.name,barco:eldato.ship.name,presentacion:this.$options.filters.myDate(eldato.upload_date),real:this.$options.filters.myDate(eldato.real_download_date),protocolar:this.$options.filters.myDate(eldato.download_date),estado:eldato.status.name,ghost_quotation:eldato.ghost_quotation,quotation:eldato.quotation,accion:true});
                            }else if((eldato.ship.supervisor_id === this.$user.user_data().id) && this.$gate.isSupervisor()){
                                if((eldato.ship.supervisor_id === this.$user.user_data().id) && this.$gate.isSupervisor()){
                                    this.datos.push({id:eldato.id,status:eldato.status,cliente:eldato.client.name,barco:eldato.ship.name,presentacion:this.$options.filters.myDate(eldato.upload_date),real:this.$options.filters.myDate(eldato.real_download_date),protocolar:this.$options.filters.myDate(eldato.download_date),estado:eldato.status.name,ghost_quotation:eldato.ghost_quotation,quotation:eldato.quotation,accion:true});
                          
                                }
                            }
                        }
                       console.log(this.datos);
                     })
                    .catch(() => this.$launchError('Error al obtener listado de tripulaciones'));

                this.$Progress.finish();
            }
        }
    }
</script>

<template>
    <div>
        <!-- fecha -->
        <div class="row">
            <div class="col form-group">
                <label for="date">Fecha examen</label>
                <input type="date" class="form-control" id="date" v-model="form.exam_date">
            </div>
        </div>

        <!-- condición -->
        <div class="row">
            <div class="col form-group">
                <input type="radio" id="apto" :value="true" v-model="form.condition">
                <label for="apto">Apto</label>
            </div>

            <div class="col form-group">
                <input type="radio" id="contraindicado" :value="false" v-model="form.condition">
                <label for="contraindicado">Contraindicado</label>
            </div>
        </div>

        <!-- formulario si es apto -->
        <div v-if="form.condition">
            <div class="row">
                <div class="col form-group">
                    <label>Fecha expiración</label>
                    <input type="date" class="form-control" v-model="form.expiration_date">
                </div>

                <div class="col form-group">
                    <label>Certificado</label>
                    <div class="custom-file">
                        <input id="boarding-license" type="file" class="custom-file-input"
                               @change.prevent="uploadCertificate">
                        <label class="custom-file-label" for="boarding-license">
                            {{ fileName ? fileName : 'Escoja un archivo' }}
                        </label>
                    </div>
                </div>
            </div>
        </div>

        <!-- formulario si está contraindicado -->
        <div>
            <div class="row">
                <div class="col form-group">
                    <label>Observaciones</label>
                    <textarea class="form-control" v-model="form.observations"></textarea>
                </div>
            </div>
        </div>

        <button class="btn btn-primary float-right" :disabled="submit" @click="saveOccupationalExam">
            <i class="fa fa-spinner fa-spin mr-2" v-if="submit"></i>
            {{ submit ? 'Guardando' : 'Guardar' }}
        </button>
    </div>
</template>

<script>
import moment from 'moment';

export default {
    computed: {
        id () {
            return this.$route.params.id;
        }
    },
    data () {
        return {
            certificate: null,
            fileName: null,
            form: {
                condition: true,
                exam_date: moment(new Date()).format('yyyy-MM-DD'),
                expiration_date: null,
                observations: null
            },
            submit: false
        }
    },
    methods: {
        saveOccupationalExam () {
            Swal.fire({
                title: '¿Estás seguro que deseas guardar este examen ocupacional?',
                text: 'No podrás revertir esta acción',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Si',
                cancelButtonText: 'No'
            }).then((result) => {
                if (result.value) {
                    this.submit = true;
                    this.$Progress.start();
                    let form = new FormData();

                    form.append('condition', this.form.condition == true);
                    form.append('exam_date', this.form.exam_date);

                    // si tiene la condición en true
                    if (this.form.condition == true) {
                        form.append('expiration_date', this.form.expiration_date);
                        form.append('certificate', this.certificate);
                    }  
                    
                    form.append('observations', this.form.observations);
                    axios.post(`/api/prevencionista/trabajadores/${this.id}/examen-ocupacional`, form)
                        .then(() => {
                            Swal.fire(
                                'Éxito',
                                'El examen ocupacional fue guardado exitosamente',
                                'success'
                            );

                            this.$Progress.finish();
                            this.fileName=null;
                            this.certificate=null;
                            this.form = {
                                condition: true,
                                exam_date: moment(new Date()).format('yyyy-MM-DD'),
                                expiration_date: null,
                                observations: null
                            };
                        })
                        .catch(() => {
                            Swal.fire(
                                'Error',
                                'No se pudo guardar el examen ocupacional',
                                'warning'
                            );
                            this.$Progress.fail();
                        })
                        .finally(() => this.submit = false);
                }
            });
        },

        uploadCertificate (e) {
            const f = e.target.files[0];
            this.fileName = f.name;
            this.certificate = f;
        }
    }
}
</script>


var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('section',{staticClass:"content"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[(_vm.loading)?_c('loading'):_vm._e(),_vm._v(" "),_c('div',{staticClass:"card-header"},[_c('h3',{staticClass:"card-title"},[_vm._v("Listado de compras")])]),_vm._v(" "),_c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Búsqueda","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_vm._v(" "),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.datos,"search":_vm.search,"loading":_vm.loading,"items-per-page":30,"no-results-text":"No se han encontrado compras","no-data-text":"No existen compras","loading-text":"Cargando... Espere","footer-props":{
           'items-per-page-text':'Compras por página',
           'items-per-page-options': [20, 50, 100, -1]
      }},scopedSlots:_vm._u([{key:"item.estado",fn:function(ref){
      var item = ref.item;
      var index = ref.index;
return [_c('span',{staticClass:"badge",class:{
                                                'bg-success': item.status,
                                                'bg-danger': item.status === 0,
                                                'bg-warning': item.status === null
                                            }},[_vm._v("\n                                                "+_vm._s(item.status === null ? 'No validada': item.status === 1 ? 'Validada': 'Rechazada')+"\n                                            ")])]}},{key:"item.accion",fn:function(ref){
                                            var item = ref.item;
                                            var index = ref.index;
return [_c('router-link',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Ver detalles'),expression:"'Ver detalles'"}],attrs:{"to":("/operaciones/compras/" + (item.id))}},[_c('v-icon',{attrs:{"small":"","color":"green"}},[_vm._v("\n                                             fas fa-info-circle\n                                                ")])],1)]}},{key:"footer.page-text",fn:function(items){return [_vm._v(" "+_vm._s(items.pageStart)+" - "+_vm._s(items.pageStop)+" de "+_vm._s(items.itemsLength)+" ")]}}])})],1)],1)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div>
        <!-- breadcrumb -->
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Facturar compra</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link to="/">Inicio</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link to="/bodega/compras">Compras</router-link>
                            </li>
                            <li class="breadcrumb-item active">Facturar compra</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>

        <!-- creación -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col">
                        <div class="card">
                            <loading v-if="loading"/>

                            <div class="card-body">
                                <!-- formulario -->
                                <div class="row mb-3" v-if="hasInvoice">
                                    <div class="col">
                                        <span>
                                            <strong>Fecha de factura:</strong> {{ purchase.invoice.created_at | myDate }}.
                                        </span>
                                    </div>
                                    <div class="col">
                                        <span>
                                            <strong>Cuenta de gastos:</strong>
                                            {{ purchase.invoice.account_number }} -
                                            {{ purchase.invoice.account_name }}.
                                        </span>
                                    </div>
                                    <div class="col">
                                        <span>
                                            <strong>Orden de compra:</strong> {{ purchase.invoice.purchase_order }}.
                                        </span>
                                    </div>
                                    <div class="col">
                                        <span>
                                            <strong>Fecha Orden de compra:</strong> {{ purchase.invoice.purchase_order_date }}.
                                        </span>
                                    </div>
                                    <div class="col">
                                        <span>
                                            <strong>Número de factura:</strong> {{ purchase.invoice.bill_number }}.
                                        </span>
                                    </div>
                                </div>

                                <form class="row mb-3" @submit.prevent="confirmPurchase" v-else>
                                    <div class="col form-group">
                                        <label>Cuenta de gastos</label>
                                        <select class="form-control" v-model="form.bank_account_id"
                                                :class="{ 'is-invalid': form.errors.has('bank_account_id') }">
                                            <option :value="null" disabled>Escoja una cuenta de gastos</option>
                                            <option :value="a.id" v-for="a in bankAccounts">
                                                {{ a.account_number }} - {{ a.account_name }}
                                            </option>
                                        </select>
                                        <has-error :form="form" field="bank_account_id"/>
                                    </div>

                                    <div class="col form-group">
                                        <label>Orden de compra</label>
                                        <input type="text" v-model="form.purchase_order" class="form-control"
                                               :class="{ 'is-invalid': form.errors.has('purchase_order') }">
                                        <has-error :form="form" field="purchase_order"/>
                                    </div>

                                       <div class="col form-group">
                                        <label>Fecha Orden de compra</label>
                                        <input type="date" v-model="form.purchase_order_date" class="form-control"
                                               :class="{ 'is-invalid': form.errors.has('purchase_order_date') }">
                                        <has-error :form="form" field="purchase_order_date"/>
                                    </div>

                                    <div class="col form-group">
                                        <label>Número de factura</label>
                                        <input type="text" v-model="form.bill_number" class="form-control"
                                               :class="{ 'is-invalid': form.errors.has('bill_number') }">
                                        <has-error :form="form" field="bill_number"/>
                                    </div>

                                    <div class="col mt-4">
                                        <button type="submit" class="btn btn-primary float-right">
                                            Confirmar
                                        </button>
                                    </div>
                                </form>

                                <!-- artículos -->
                                <div class="row">
                                    <div class="col table-responsive p-0">
                                        <table class="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th class="align-middle">Nombre</th>
                                                    <th class="align-middle">Talla</th>
                                                    <th class="align-middle">Clasificación</th>
                                                    <th class="align-middle">Cantidad</th>
                                                    <th class="align-middle">Unidad de medida</th>
                                                    <th class="align-middle">Precio</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="i in purchase.warehouse_item_sizes">
                                                    <td class="align-middle">{{ i.size.item.name }}</td>
                                                    <td class="align-middle">{{ i.size.name !== '' ? i.size.name : '-' }}</td>
                                                    <td class="align-middle">{{ i.size.item.classification.name }}</td>
                                                    <td class="align-middle">{{ i.quantity }}</td>
                                                    <td class="align-middle">{{ i.size.item.measurement_unit.name }}</td>
                                                    <td class="align-middle">
                                                        ${{ (i.price ? i.price : i.size.item.price) | dotNumber }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Loading from "../Common/Loading";

    export default {
        components: { Loading },
        computed: {
            hasInvoice () {
                return this.purchase.invoice !== null;
            },
            id () {
                return this.$route.params.id;
            }
        },
        created () {
            this.getPurchase();
        },
        data () {
            return {
                bankAccounts: [],
                form: new Form({
                    bank_account_id: null,
                    bill_number: null,
                    purchase_order: null,
                    purchase_order_date: null
                }),
                loading: true,
                purchase: {
                    invoice: {
                        account_name: null,
                        account_number: null,
                        bill_number: null,
                        created_at: null,
                        purchase_order: null,
                        purchase_order_date: null
                    },
                    warehouse_item_sizes: []
                }
            }
        },
        methods: {
            confirmPurchase () {

               

                if (!this.hasInvoice) {

                     if(this.form.bank_account_id==null || this.form.bill_number==null || this.form.purchase_order==null || this.form.purchase_order_date==null){
                          this.$launchError('Debe completar todos los campos para facturar');
                    }else{
                             Swal.fire({
                                title: '¿Estás seguro que deseas facturar esta compra?',
                                text: 'No podrás revertir esta acción',
                                showCancelButton: true,
                                confirmButtonColor: '#d33',
                                cancelButtonColor: '#3085d6',
                                confirmButtonText: 'Si',
                                cancelButtonText: 'No'
                            }).then((result) => {
                                if (result.value) {
                                    this.form.post(`/api/bodega/compras/${this.id}/facturar`)
                                        .then(() => {
                                            this.$launchSuccess('La compra fue facturada correctamente');
                                            this.getPurchase();
                                        }).catch(() => 
                                     this.$launchSuccess('No se pudo facturar la compra'));
                                }
                            });
                    }
                   
                }
            },

            getBankAccounts () {
                axios.get('/api/bodega/cuentas-bancarias')
                    .then(({ data }) => this.bankAccounts = data.data)
                    .catch(() => this.$launchError('Error al obtener listado de cuentas bancarias'));
            },

            getPurchase () {
                this.$Progress.start();

                axios.get('/api/bodega/compras/' + this.id)
                    .then(({ data }) => {
                        this.purchase = data.data;
                        this.$Progress.finish();
                        if (!this.hasInvoice) this.getBankAccounts();
                    })
                    .catch(() => {
                        this.$Progress.fail();
                        this.$launchError('Error al obtener compra');
                    })
                    .finally(() => this.loading = false)
            }
        }
    }
</script>

<template>
    <div>
        <!-- breadcrumb -->
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Reportes</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link to="/">Inicio</router-link>
                            </li>
                          
                            <li class="breadcrumb-item active">Reportes</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>

        <!-- edición -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col">
                        <div class="card">
                          

                            <div class="card-header">
                                <ul class="nav nav-pills">
                                    <li class="nav-item" v-for="t in tabs">
                                        <a class="nav-link" :class="{'active': tab === t.component}"
                                           @click="tab = t.component">
                                            {{ t.title }}
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div class="card-body">
                                <component :is="tab"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Data from "./Reports/Data";
   

    export default {
        components: { Data },
        computed: {
            id () {
                return this.$route.params.id;
            }
        },
        data () {
            return {
                tab: 'Data',
                tabs: [
                    { title: 'Seleccione Reporte', component: 'Data' }
                ]
            }
        }
    }
</script>

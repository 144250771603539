<template>
    <div>
        <div class="card-body table-responsive p-0" v-if="hasWorkers">
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th class="align-middle">Nombre</th>
                        <th class="align-middle">Rut</th>
                        <th class="align-middle">Roles</th>
                        <th class="align-middle">Situación</th>
                        <th class="align-middle">Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="w in workersList" :key="w.id"
                        :class="{ 'table-warning': w.filtered, 'table-danger': w.with_emergency }">
                        <td class="align-middle">{{ w.worker.name }} {{ w.worker.last_name }}</td>
                        <td class="align-middle">{{ w.worker.rut }}</td>
                        <td class="align-middle">{{ w.worker.roles.map(r => r.name) | list }}</td>
                        <td class="align-middle">{{ workerSituation(w.id) }}</td>
                        <td class="align-middle">
                            <router-link :to="'/admin/trabajadores/' + w.worker.id"
                                         v-tooltip="'Ficha del tripulante'">
                                <i class="fa fa-info-circle green"></i>
                            </router-link>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <span v-else><i>No se han asignado tripulantes a esta tripulación.</i></span>
    </div>
</template>

<script>
    export default {
        computed: {
            hasWorkers () {
                return this.workers.length > 0;
            },
            workersList () {
                let notFiltered = [];
                let emergency = [];
                let filtered = [];

                this.workers.forEach(w => {
                    if (w.filtered === 1) filtered.push(w);
                    else if (w.with_emergency === 1) emergency.push(w);
                    else notFiltered.push(w);
                })

                return notFiltered.concat(filtered.concat(emergency));
            }
        },
        created () {
            this.getWorkers();
        },
        data () {
            return {
                workers: []
            }
        },
        methods: {
            getWorkers () {
                this.$Progress.start();
                this.$parent.loading = true;

                axios.get('/api/ficha-tripulacion/' + this.$parent.id + '/tripulantes')
                    .then(response => {
                        this.workers = response.data.data.full_workers;
                        this.$Progress.finish();
                    })
                    .catch(() => {
                        this.$launchError('Error al obtener tripulantes');
                        this.$Progress.fail();
                    })
                    .then(() => this.$parent.loading = false);
            },

            workerSituation (crewWorkerId) {
                const w = this.workers.find(w => w.id === crewWorkerId);

                if (!w.filtered && !w.with_emergency) {
                    return 'Ok';
                } else if (w.filtered) {
                    return 'Filtrado';
                } else if (w.with_emergency) {
                    return 'Emergencia';
                } else if (w.is_replacement) {
                    return 'Reemplazo';
                }
            }
        }
    }
</script>

<template>
    <form @submit.prevent="saveIMC">
        <!-- formulario de imc -->
        <div class="row">
            <div class="col form-group">
                <label for="year">Año</label>
                <select id="year" class="form-control" v-model="form.year">
                    <option :value="2020">2020</option>
                    <option :value="2021">2021</option>
                    <option :value="2022">2022</option>
                </select>
            </div>

            <div class="col form-group">
                <label for="month">Mes</label>
                <select id="month" class="form-control" v-model="form.month">
                    <option :value="m.index" v-for="m in months">{{ m.name }}</option>
                </select>
            </div>

            <div class="col form-group">
                <label for="height">Talla</label>
                <input min="0" type="number" step="any" id="height" class="form-control" v-model.number="form.height">
            </div>

            <div class="col form-group">
                <label for="weight">Peso</label>
                <input min="0" type="number" step="any" id="weight" class="form-control" v-model.number="form.weight">
            </div>

            <div class="col form-group">
                <label>IMC</label>
                <input min="0" type="number" class="form-control" readonly :value="imc">
            </div>
        </div>

        <div class="row">
            <div class="col">
                <button class="btn btn-primary float-right" type="submit" :disabled="submit">
                    <i class="fa fa-spinner fa-spin mr-2" v-if="submit"></i>
                    {{ submit ? 'Guardando' : 'Guardar' }}
                </button>
            </div>
        </div>
    </form>
</template>

<script>
    export default {
        computed: {
            id () {
                return this.$route.params.id;
            },
            imc () {
                if (this.form.height !== null && this.form.weight !== null) {
                    return (this.form.weight / (this.form.height * this.form.height)).toFixed(1);
                }

                return 0;
            }
        },
        data () {
            return {
                form: new Form({
                    year: 2021,
                    month: (new Date()).getMonth(),
                    height: null,
                    weight: null,
                    imc: null
                }),
                months: [
                    { index: 0, name: 'Enero' },
                    { index: 1, name: 'Febrero' },
                    { index: 2, name: 'Marzo' },
                    { index: 3, name: 'Abril' },
                    { index: 4, name: 'Mayo' },
                    { index: 5, name: 'Junio' },
                    { index: 6, name: 'Julio' },
                    { index: 7, name: 'Agosto' },
                    { index: 8, name: 'Septiembre' },
                    { index: 9, name: 'Octubre' },
                    { index: 10, name: 'Noviembre' },
                    { index: 11, name: 'Diciembre' }
                ],
                selectedYear: 2021,
                submit: false
            }
        },
        methods: {
            saveIMC () {
                Swal.fire({
                    title: '¿Estás seguro que deseas guardar este registro de IMC?',
                    text: 'No podrás revertir esta acción',
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Si',
                    cancelButtonText: 'No'
                }).then((result) => {
                    if (result.value) {
                        this.submit = true;
                        this.$Progress.start();

                        this.form.imc = this.imc;
                        this.form.post(`/api/prevencionista/trabajadores/${this.id}/imc`).then(() => {
                            Swal.fire(
                                'Éxito',
                                'El registro de IMC fue guardado exitosamente',
                                'success'
                            );

                            this.$Progress.finish();
                            this.form.reset();
                        }).catch(() => {
                            Swal.fire(
                                'Error',
                                'No se pudo guardar el registro de IMC',
                                'warning'
                            );
                            this.$Progress.fail();
                        })
                        .finally(() => this.submit = false);
                    }
                });
            }
        }
    }
</script>


<template>
 <v-app>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <loading v-if="loading"/>

                        <!-- header -->
                        <div class="card-header">
                            <h3 class="card-title">Listado de compras para tripulaciones</h3>
                        </div>

              <v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Búsqueda"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
  <v-data-table
      :headers="headers"
      :items="datos"
      :search="search"
       :loading="loading"
       :items-per-page="30"
       no-results-text="No se han encontrado compras"
       no-data-text="No existen compras"
      class="elevation-1"
        loading-text="Cargando... Espere"
         :footer-props="{
           'items-per-page-text':'Compras por página',
           'items-per-page-options': [20, 50, 100, -1]
      }"
    >
     <template v-slot:item.estado="{ item, index }">

                            
                                            <span class="badge" :class="{
                                                'bg-success': item.status,
                                                'bg-danger': item.status === 0,
                                                'bg-warning': item.status === null
                                            }">
                                                {{ item.status === null ? 'No validada': item.status === 1 ? 'Validada': 'Rechazada' }}
                                            </span>
                                                      
  </template>

<template v-slot:item.accion="{ item, index }">

                            

                                             <router-link :to="`/operaciones/compras-tripulacion/${item.id}`"
                                                         v-tooltip="'Ver detalles'">
                                       
                                                     <v-icon small color="green">
                                             fas fa-info-circle
                                                </v-icon>
                                            </router-link>
                                                      
  </template>
  <template v-slot:footer.page-text="items"> {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }} </template>
</v-data-table>
</v-card> 

                        <!-- 
                        <div class="card-body table-responsive p-0">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th class="align-middle">Embarcación</th>
                                        <th class="align-middle">Cliente</th>
                                        <th class="align-middle">Fecha Presentacion</th>
                                        <th class="align-middle">Fecha Bajada Real</th>
                                        <th class="align-middle">Fecha Bajada Protocolar</th>
                                        <th class="align-middle">Fecha confirmación</th>
                                        <th class="align-middle">Estado</th>
                                        <th class="align-middle">Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="p in purchases.data">
                                         <td class="align-middle">{{ p.ship }}</td>
                                        <td class="align-middle">{{ p.client }}</td>
                                        <td class="align-middle">{{ p.upload_date | myDate }}</td>
                                        <td class="align-middle">{{ p.real_download_date | myDate }}</td>
                                        <td class="align-middle">{{ p.download_date | myDate }}</td>
                                        <td class="align-middle">{{ p.confirmed_at | myDate }}</td>
                                        <td class="align-middle">
                                            <span class="badge" :class="{
                                                'bg-success': p.status,
                                                'bg-danger': p.status === 0,
                                                'bg-warning': p.status === null
                                            }">
                                                {{ p.status === null ? 'No validada': p.status === 1 ? 'Validada': 'Rechazada' }}
                                            </span>
                                        </td>
                                        <td class="align-middle">
                                            <router-link :to="`/operaciones/compras-tripulacion/${p.id}`"
                                                         v-tooltip="'Ver detalles'">
                                                <i class="fas fa-info-circle green"></i>
                                            </router-link>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                       
                        <div class="card-footer">
                            <pagination :data="purchases" @pagination-change-page="getPurchases"/>
                        </div>

                        -->
                    </div>
                </div>
            </div>
        </div>
    </section>
     </v-app>
</template>

<script>
    import Loading from "../Common/Loading";

    export default {
        components: { Loading },
        created () {
            this.getPurchases();
        },
        data () {
            return {
                loading: true,
                purchases: { },
                search: '',
                headers: [{ text: 'Embarcación', value: 'barco' },
                {
                    text: 'Cliente',
                    align: 'start',
                    sortable: false,
                    value: 'cliente',
                },
                
                { text: 'Fecha de Presentacion', value: 'presentacion' },
                { text: 'Fecha bajada real', value: 'real' },
                { text: 'Fecha bajada protocolar', value: 'protocolar' },
                { text: 'Fecha Confirmación', value: 'confirmacion' },
                { text: 'Estado', value: 'estado' },
                { text: 'Acciones', value: 'accion', sortable: false },
                ],
                datos:[],
            }
        },
        methods: {
            getPurchases (page = 1) {
                this.$Progress.start();

                axios.get('/api/operaciones/compras-tripulacion')
                    /*.then(({ data }) => {
                        this.purchases = data;
                        this.$Progress.finish();
                    })*/

                    .then(data => {
                    this.purchases = data.data;
                     console.log(this.purchases);
                        for(let i = 0; i < this.purchases.data.length; i++) {
                            var eldato = this.purchases.data[i];
                            this.datos.push({status:eldato.status,id:eldato.id,confirmacion:this.$options.filters.myDate(eldato.confirmed_at),cliente:eldato.client,barco:eldato.ship,presentacion:this.$options.filters.myDate(eldato.upload_date),real:this.$options.filters.myDate(eldato.real_download_date),protocolar:this.$options.filters.myDate(eldato.download_date),estado:eldato.status.name,ghost_quotation:eldato.ghost_quotation,quotation:eldato.quotation,accion:true});
                        }
                       console.log(this.datos);
                     })
                    .catch(() => {
                        this.$Progress.fail();
                        this.$launchError('Error al obtener listado de compras');
                    })
                    .finally(() => this.loading = false);
            }
        }
    }
</script>

<template>
    <div>
        <!-- breadcrumb -->
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Facturar cotización de Emergencia</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link to="/">Inicio</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link to="/logistica/tripulaciones">Tripulaciones</router-link>
                            </li>
                            <li class="breadcrumb-item active">Facturar cotización</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>

        <!-- creación -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col">
                        <div class="card">
                            <loading v-if="loading"/>

                            <div class="card-body">
                                <!-- formulario -->
                                <div class="row mb-3" v-if="hasInvoice">
                                    <div class="col">
                                        <span>
                                            <strong>Fecha de factura:</strong> {{ quotation.invoice.created_at | myDate }}.
                                        </span>
                                    </div>
                                    <div class="col">
                                        <span>
                                            <strong>Cuenta de gastos:</strong>
                                            {{ quotation.invoice.bank_account.account_number }} -
                                            {{ quotation.invoice.bank_account.account_name }}.
                                        </span>
                                    </div>
                                    <div class="col">
                                        <span>
                                            <strong>Orden de compra:</strong> {{ quotation.invoice.purchase_order }}.
                                        </span>
                                    </div>
                                     <div class="col">
                                        <span>
                                            <strong>Fecha orden de compra:</strong> {{ quotation.invoice.purchase_order_date }}.
                                        </span>
                                    </div>
                                    <div class="col">
                                        <span>
                                            <strong>Número de factura:</strong> {{ quotation.invoice.bill_number }}.
                                        </span>
                                    </div>
                                </div>

                                <form class="row mb-3" @submit.prevent="confirmQuotation" v-else>
                                    <div class="col form-group">
                                        <label>Cuenta de gastos</label>
                                        <select class="form-control" v-model="form.bank_account_id"
                                                :class="{ 'is-invalid': form.errors.has('bank_account_id') }">
                                            <option :value="null" disabled>Escoja una cuenta de gastos</option>
                                            <option :value="a.id" v-for="a in bankAccounts">
                                                {{ a.account_number }} - {{ a.account_name }}
                                            </option>
                                        </select>
                                        <has-error :form="form" field="bank_account_id"/>
                                    </div>

                                    <div class="col form-group">
                                        <label>Orden de compra</label>
                                        <input type="text" v-model="form.purchase_order" class="form-control"
                                               :class="{ 'is-invalid': form.errors.has('purchase_order') }">
                                        <has-error :form="form" field="purchase_order"/>
                                    </div>

                                    <div class="col form-group">
                                        <label>Fecha Orden de compra</label>
                                        <input type="date" v-model="form.purchase_order_date" class="form-control"
                                               :class="{ 'is-invalid': form.errors.has('purchase_order_date') }">
                                        <has-error :form="form" field="purchase_order_date"/>
                                    </div>

                                    <div class="col form-group">
                                        <label>Número de factura</label>
                                        <input type="text" v-model="form.bill_number" class="form-control"
                                               :class="{ 'is-invalid': form.errors.has('bill_number') }">
                                        <has-error :form="form" field="bill_number"/>
                                    </div>

                                    <div class="col mt-4">
                                        <button type="submit" class="btn btn-primary float-right">
                                            Confirmar
                                        </button>
                                    </div>
                                </form>

                                <!-- pasajes -->
                                <h4 class="mb-4">Pasajes</h4>

                                <div class="row">
                                    <div class="col table-responsive p-0">
                                        <table class="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th class="align-middle">#</th>
                                                    <th class="align-middle">Aerolínea</th>
                                                    <th class="align-middle">Tramo</th>
                                                    <th class="align-middle">Ruta</th>
                                                    <th class="align-middle">Fecha</th>
                                                    <th class="align-middle">Hora salida</th>
                                                    <th class="align-middle">Hora llegada</th>
                                                    <th class="align-middle">Tarifa</th>
                                                    <th class="align-middle">Cantidad</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(t, i) in quotation.tickets">
                                                    <td class="align-middle">{{ i + 1 }}</td>
                                                    <td class="align-middle">{{ t.airline.name }}</td>
                                                    <td class="align-middle">{{ t.flight_stretch.name }}</td>
                                                    <td class="align-middle">
                                                        {{ t.departure_airport.iata }} -
                                                        {{ t.arrival_airport.iata }}
                                                    </td>
                                                    <td class="align-middle">{{ t.flight_date | myDate }}</td>
                                                    <td class="align-middle">{{ t.departure_time | myHour }}</td>
                                                    <td class="align-middle">{{ t.arrival_time | myHour }}</td>
                                                    <td class="align-middle">
                                                        ${{ t.price | dotNumber }}
                                                        <strong v-if="t.tickets.length > 0">
                                                            (Total ${{ t.price * t.tickets.length }})
                                                        </strong>
                                                    </td>
                                                    <td class="align-middle">{{ t.tickets.length }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <!-- trabajadores -->
                                <h4 class="mb-4">Tripulantes</h4>

                                <div class="row">
                                    <div class="col table-responsive p-0">
                                        <table class="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th class="align-middle">Nombre</th>
                                                    <th class="align-middle">Rut</th>
                                                    <th class="align-middle">Rol</th>
                                                    <th class="align-middle">Pasaje ida</th>
                                                    <th class="align-middle">Pasaje vuelta</th>
                                                    <th class="align-middle">Observaciones</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="w in quotation.crew.workers" v-if="w.is_replacement==0">
                                                    <td class="align-middle">{{ w.worker.name }} {{ w.worker.last_name }}</td>
                                                    <td class="align-middle">{{ w.worker.rut }}</td>
                                                    <td class="align-middle">{{ w.worker.roles.map(r => r.name) | list }}</td>
                                                    <td class="align-middle">
                                                        #{{ getTicketNumber(w.id, 'Ida') }}
                                                    </td>
                                                    <td class="align-middle">
                                                        #{{ getTicketNumber(w.id, 'Vuelta') }}
                                                    </td>
                                                    <td class="align-middle">
                                                        {{ w.observations ? w.observations: '-' }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Loading from "../Common/Loading";

    export default {
        components: { Loading },
        computed: {
            id () {
                return this.$route.params.id;
            },
            hasInvoice () {
                return this.loading === false && this.quotation.invoice !== null;
            }
        },
        created () {
            this.getBankAccounts();
            this.getQuotation();
        },
        data () {
            return {
                bankAccounts: [],
                form: new Form({
                    bank_account_id: null,
                    bill_number: null,
                    purchase_order: null,
                    purchase_order_date: null
                }),
                loading: true,
                quotation: {
                    crew: { workers: [] },
                    invoice: {
                        bank_account: {
                            account_name: null,
                            account_number: null
                        },
                        created_at: null
                    },
                    tickets: []
                }
            }
        },
        methods: {
            confirmQuotation () {
                if (!this.hasInvoice) {
                    Swal.fire({
                        title: '¿Estás seguro que deseas facturar esta cotización?',
                        text: 'No podrás revertir esta acción',
                        showCancelButton: true,
                        confirmButtonColor: '#d33',
                        cancelButtonColor: '#3085d6',
                        confirmButtonText: 'Si',
                        cancelButtonText: 'No'
                    }).then((result) => {
                        if (result.value) {
                            this.form.post(`/api/logistica/cotizaciones/${this.id}/facturar-cotizacion`)
                                .then(() => {
                                    Swal.fire(
                                        'Éxito',
                                        'La cotización fue facturada correctamente',
                                        'success'
                                    );

                                    this.getQuotation();
                                }).catch(() => Swal.fire(
                                'Error',
                                'No se pudo facturar la cotización',
                                'warning'
                            ));
                        }
                    });
                }
            },

            getBankAccounts () {
                if (!this.hasInvoice) {
                    axios.get('/api/logistica/cuentas-bancarias')
                        .then(({ data }) => this.bankAccounts = data.data)
                        .catch(() => this.$launchError('Error al obtener listado de cuentas bancarias'));
                }
            },

            getTicketNumber (crewWorkerId, stretch) {
                let index = null;

                this.quotation.tickets.forEach((t, i) => {
                    if (index === null && t.flight_stretch.name === stretch) {
                        t.tickets.forEach(w => {
                            if (w.crew_worker_id === crewWorkerId) index = i;
                        });
                    }
                });

                return index + 1;
            },

            getQuotation () {
                this.loading = true;
                this.$Progress.start();

                axios.get(`/api/logistica/cotizaciones/${this.id}`)
                    .then(({ data }) => {
                        this.quotation = data.data;
                        this.$Progress.finish();
                    })
                    .catch(() => {
                        this.$launchError('Error al obtener información de cotización');
                        this.$Progress.fail();
                    })
                    .finally(() => this.loading = false);
            }
        }
    }
</script>

<template>
    <form @submit.prevent="editUser">
        <user-form :form="form" :is-edition="true"/>

        <button type="submit" class="btn btn-primary float-right">Guardar</button>
    </form>
</template>

<script>
    import Form from "vform/src";
    import UserForm from "../Users/Form";
    import eventHub from "../../eventHub";

    export default {
        components: { UserForm },
        created () {
            this.$Progress.start();
            this.getUser();
            this.$Progress.finish();
        },
        data () {
            return {
                form: new Form({
                    id: '',
                    name: '',
                    last_name: '',
                    code: '',
                    address: '',
                    email: '',
                    rut: '',
                    birthdate: '',
                    phone_number: '',
                    position_id: null,
                    region_id: null,
                    commune_id: null,
                    marital_status_id: null
                })
            }
        },
        methods: {
            validaRut: function(rutCompleto) {
                rutCompleto = rutCompleto.replace("‐","-");
                if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test( rutCompleto ))
                    return false;
                var tmp     = rutCompleto.split('-');
                var digv    = tmp[1];
                var rut     = tmp[0];
                if ( digv == 'K' ) digv = 'k' ;

                return (this.dv(rut) == digv );
            },
            dv : function(T){
                var M=0,S=1;
                for(;T;T=Math.floor(T/10))
                    S=(S+T%10*(9-M++%6))%11;
                return S?S-1:'k';
            },
            editUser () {
                 if(this.validaRut(this.form.rut)){
                    this.$Progress.start();

                    this.form.put('/api/usuarios/' + this.$parent.id)
                    .then(() => {
                        this.$Progress.finish();
                        this.$launchSuccess('Éxito al editar usuario');
                        eventHub.$emit('getUser');
                    })
                    .catch(() => {
                        this.$Progress.fail();
                        this.$launchError('Error al editar usuario');
                    });
                }else{
                     this.$launchError('Rut no válido');
                }

            },

            getUser () {
                this.$nextTick(() => {
                    this.form.fill(this.$parent.user);
                    this.form.region_id = this.$parent.user.commune.region_id;
                });
            }
        },
        watch: {
            '$parent.user' () {
                this.getUser();
            }
        }
    }
</script>

<template>
    <div>
        <!-- breadcrumb -->
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Calificar trabajador</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link to="/">Inicio</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link to="/admin/trabajadores">Trabajadores</router-link>
                            </li>
                            <li class="breadcrumb-item active">Calificar trabajador</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>

        <!--  -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col form-group">
                                        <label for="fecha">Fecha calificación</label>
                                        <input id="fecha" class="form-control" type="date" v-model="qualificationDate">
                                    </div>

                                    <div class="col form-group">
                                        <label for="ano">Año</label>
                                        <select id="ano" class="form-control" type="date"
                                                v-model.number="qualificationYear">
                                            <option :value="null" disabled>Escoja un año</option>
                                            <option :value="2019">2019</option>
                                            <option :value="2020">2020</option>
                                            <option :value="2021">2021</option>
                                            <option :value="2022">2022</option>
                                            <option :value="2023">2023</option>
                                        </select>
                                    </div>

                                    <div class="col form-group">
                                        <label for="half">Semestre</label>
                                        <select id="half" class="form-control" type="date"
                                                v-model.number="qualificationHalf">
                                            <option :value="null" disabled>Escoja un semestre</option>
                                            <option :value="1">Primero</option>
                                            <option :value="2">Segundo</option>
                                        </select>
                                    </div>
                                </div>

                                <form @submit.prevent="saveQualification">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Criterio</th>
                                                <th v-for="g in grades">{{ g === 0 ? 'NC': g }}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(c, i) in criteria">
                                                <td>{{ c.name }}</td>
                                                <td v-for="g in grades">
                                                    <input :name="c.name" type="radio" :value="g"
                                                           v-model="qualifications[i].grade">
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <div>
                                        <!-- TODO: alinear v -->
                                        <span class="float-left">
                                            El promedio es: {{ average }}
                                        </span>
                                        <button type="submit" class="btn btn-success float-right">Guardar</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        computed: {
            average () {
                if (this.qualifications.length > 0) {
                    return ((this.qualifications.map(q => parseInt(q.grade))
                        .reduce((a, b) => a + b)) / this.qualifications.length)
                        .toFixed(1);
                }
            }
        },
        created () {
            this.$Progress.start();
            this.getCriteria();
            this.$Progress.finish();
        },
        data () {
            return {
                criteria: [],
                grades: [0, 1, 2, 3, 4, 5, 6, 7],
                qualificationDate: null,
                qualificationHalf: null,
                qualificationYear: null,
                qualifications: []
            }
        },
        methods: {
            getCriteria () {
                axios.get('/api/supervisor/criterios-calificacion')
                    .then(({ data }) => {
                        this.criteria = data.data;
                        this.criteria.forEach(c => this.qualifications.push({ criteria_id: c.id, grade: 0 }));
                    })
                    .catch(() => this.$launchError('Error al obtener listado de criterios de calificación'));
            },

            saveQualification () {
                axios.post('/api/supervisor/calificacion-trabajador/' + this.$route.params.id, {
                    qualification: this.qualificationDate,
                    year: this.qualificationYear,
                    half: this.qualificationHalf,
                    qualifications: this.qualifications
                })
                    .then(() => this.$launchSuccess('Éxito al guardar calificación'))
                    .catch(() => this.$launchError('Error al guardar calificación'));
            }
        }
    }
</script>

<template>
    <div>
        <workers v-if="!hasRequest"/>
        <e-p-p-delivery v-if="!loading"/>
    </div>
</template>

<script>
    import EPPDelivery from "./EPP/EPPDelivery";
    import Workers from "./EPP/Workers";

    export default {
        components: { EPPDelivery, Workers },
        computed: {
            epp: {
                get () {
                    return this.$parent.epp;
                },
                set (v) {
                    this.$parent.epp = v;
                }
            },
            hasRequest () {
                return this.$parent.hasRequest;
            },
            loading: {
                get () {
                    return this.$parent.loading;
                },
                set (v) {
                    this.$parent.loading = v;
                }
            },
            workers: {
                get () {
                    return this.$parent.workers;
                },
                set (v) {
                    this.$parent.workers = v;
                }
            },
            workersEPPForm: {
                get () {
                    return this.$parent.workersEPPForm;
                },
                set (v) {
                    this.$parent.workersEPPForm = v;
                }
            }
        },
        created () {
            if (this.epp.length === 0 && this.workers.length === 0) this.getEPP();
        },
        methods: {
            getEPP () {
                this.loading = true;

                axios.get('/api/supervisor/epp')
                    .then(({ data }) => this.epp = data.data)
                    .catch(() => this.$launchError())
                    .finally(() => this.getWorkers());
            },

            getWorkers () {
                axios.get(`/api/supervisor/${this.$parent.id}/trabajadores/`)
                    .then(({ data }) => this.workers = data.data)
                    .catch(() => this.$launchError())
                    .finally(() => {
                        // arma el objeto formulario
                        let w = this.workers.map(w => w.id);

                        this.epp.forEach(e => {
                            this.workersEPPForm[e.id] = {};

                            w.forEach(i => this.workersEPPForm[e.id][i] = null);
                        });

                        this.$nextTick(() => this.loading = false);
                    });
            }
        }
    }
</script>

<template>
    <div>
        <!-- breadcrumb -->
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Facturar Pasaje</h1>

                      
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link to="/">Inicio</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link to="/">administracion</router-link>
                            </li>
                            <li class="breadcrumb-item active">Editar Pasaje</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>

        <!-- creación -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col">
                        <div class="card">
                            <loading v-if="loading"/>

                            <div class="card-body">
                        
                   
                          <div class="row align-items-center">
                              <h3 class="col card-title">Facturas</h3>

                              <div class="col-6">
                                
                              </div>

                              <div class="col-2">
                                 <button class="btn btn-primary float-right" 
                                                
                                                @click="setInvoice(null)">
                                            Añadir Factura
                                        </button>
                              </div>
                              
                          </div>
                   


                        <div class="card-body table-responsive p-0">
                          <table class="table table-hover" v-if="quotations.length>0">
                              <thead>
                                  <tr>
                                      <th>#</th>
                                                     <th>Cuenta Bancaria</th>
                                                    <th>Nº Factura</th>
                                                   
                                                    <th>Proveedor</th>
                                                    <th>Tipo Proveedor</th>
                                                    <th>Acciones</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr v-for="(t,i) in quotations" >
                                

                                                    <td class="align-middle">FE{{ t.index }}</td>
                                                    <td class="align-middle">{{ t.account_number }} - {{ t.account_name }}</td>
                                                    <td class="align-middle">{{ t.invoice_number }}</td>
                                                 
                                                    <td class="align-middle">{{ t.supplier }}</td>
                                                    <td class="align-middle">Pasaje</td>
                         
                                     <td>
                                  
                                                        <a href="#" @click.prevent="setInvoice(t.index)"
                                                           v-tooltip="'Editar Factura'">
                                                            <i class="fa green fa-edit"/>
                                                        </a>
                                                    
                                                        <span >/</span>
                                                        <a href="#" @click.prevent="deleteInvoice(t.index)"
                                                           v-tooltip="'Eliminar factura'">
                                                            <i class="fa red fa-times"/>
                                                        </a>
                                       </td>
                                  </tr>
                              </tbody>
                          </table>
                           <p class="text-center mb-4" style="margin-top:20px;" v-else>
                                            <i>No se han agregado facturas aún.</i>
                            </p>
                      </div>
                                <!-- fin creacion de facturas-->
    


                               <div class="card-body table-responsive p-0" style="margin-top:50px;">
                                     <div class="align-items-center">
                                                <h3 class="col card-title">Datos de Pasaje</h3>
                                            </div>
                          <table class="table table-hover">
                              <thead>
                                  <tr>
                                      <th class="align-middle">Pasajeros</th>
                                      <th class="align-middle">Aerolinea</th>
                                      <th class="align-middle">Código Vuelo</th>
                                      <th class="align-middle">Tarifa unitaria (x{{many}})</th>
                                      <th class="align-middle">Dif. Tarifa Total (x{{many}})</th>
                                      <th class="align-middle">Afecto</th>
                                      <th class="align-middle">Tramo</th>
                                      <th class="align-middle">F. Vuelo</th>
                                      <th class="align-middle">F. Venc.</th>
                                      <th class="align-middle">H. Partida</th>
                                      <th class="align-middle">H. Llegada</th>
                                      <th class="align-middle">A. Origen</th>
                                      <th class="align-middle">A. Destino</th>
                                       <th class="align-middle">Orden Compra</th>
                                        <th class="align-middle">F. Orden Compra</th>
                                         <th class="align-middle">Factura</th>
                                        <th class="align-middle">Opciones</th>
                               
                                  </tr>
                              </thead>
                              <tbody>
                                 
                                  <tr v-if="datos!=null">
                                      <td class="align-middle">{{ datos.passengers }}</td>
                                      <td class="align-middle">{{ datos.airline_name }}</td>
                                      <td class="align-middle">{{ form.code }}</td>
                                      <td class="align-middle">{{ form.price | dotNumber}}</td>
                                      <td class="align-middle">{{ form.extra_price | dotNumber}}</td>
                                      <td class="align-middle">{{ form.dearness | dotNumber}}</td>
                             
                                      <td class="align-middle">{{ stretchType(datos.stretch) }}</td>
                                      <td class="align-middle">{{ datos.flight_date }}</td>
                                          <td class="align-middle">{{ datos.expiration_date }}</td>
                                      <td class="align-middle">{{ datos.departure_time }}</td>
                                      <td class="align-middle">{{ datos.arrival_time }}</td>
                                      <td class="align-middle">{{ datos.airport1 }}</td>
                                      <td class="align-middle">{{ datos.airport2 }}</td> 
                                      <td class="align-middle">{{ form.purchase_order }}</td>
                                       <td class="align-middle">{{ form.purchase_order_date }}</td>

                                       <!-- asociar factura a pasaje-->
                                            <td v-if="datos.invoice_id!=null" class="align-middle">FE{{ datos.invoice_id }}</td>
                                                     <td v-else class="align-middle"> - </td>
                                                   

                                                    <td class="align-middle">       
                                                        <a v-if="datos.passengers>0" href="#" @click.prevent="associateInvoice(id,datos.invoice_id,'Pasaje','',null)" 
                                                           v-tooltip="'Asignar Factura a Pasaje'">
                                                           
                                                            <i class="fas green fa-file-invoice" v-if="datos.invoice_id!=null"/>
                                                            <i class="fas gray fa-file-invoice" v-else/>
                                                        </a>


                                                        <span v-if="datos.invoice_id!=null && datos.passengers>0" >/</span>
                                                        <a href="#" @click.prevent="desassociateInvoice(id,'Pasaje','')" v-if="datos.invoice_id!=null && datos.passengers>0"
                                                           v-tooltip="'Eliminar factura'">
                                                            <i class="fa red fa-times"/>
                                                        </a>

                                                    </td>
                                       <!-- fin asociar factura a pasaje-->
                          
                                   
                                  </tr>
                              </tbody>
                      </table>
                      </div>
                 



                                 <div class="mt-4">
                                    <div class="row" v-if="selectedData.length === 0 && selectedDataWorkers.length === 0">
                                        <span class="text-center w-100">
                                            <i>Aún no se han asociado personas o tripulantes a este pasaje</i>
                                        </span>
                                    </div>
                                    <div v-else>

                                      
                   

                                        <div class="table-responsive p-0">
                                               <div class="align-items-center">
                                                <h3 class="col card-title">Pasajeros</h3>
                                            </div>
                                            <table class="table table-hover"  style="margin-top:30px !important;">
                                            <thead>
                                                <tr>
                                                    <th class="align-middle">Nombre</th>
                                                    <th class="align-middle">Apellido</th>
                                                    <th class="align-middle">Rut</th>
                                                    <th class="align-middle">Monto Maleta</th>
                                                    <th class="align-middle">Facturar Monto Maleta</th>
                                                    <th class="align-middle">Multa</th>
                                                    <th class="align-middle">Facturar Monto Multa</th>
                                                    <th class="align-middle">Monto Extra</th>
                                                    <th class="align-middle">Observacion</th>
                                                   
                                                   <th class="align-middle">Asociar Fact. Maleta</th>
                                                    <th class="align-middle">Asociar Fact. Multa</th>
                                                    
                                               
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(i,index) in selectedData">
                                                   
                                                    <td class="align-middle">{{ i.name }}</td>
                                                    <td class="align-middle">{{ i.last_name }}</td>
                                                    <td class="align-middle">{{ i.rut }}</td>
                                                    <td class="align-middle"><input type="number" min="0" v-model="i.carry_price" class="form-control" readonly/></td>
                                                    <td class="align-middle"><input type="checkbox"  v-model="i.quotation" class="form-control" disabled/></td>
                                                    <td class="align-middle"><input type="number"  v-model="i.penalty" readonly class="form-control"/></td>
                                                    <td class="align-middle"><input type="checkbox"  v-model="i.penalty_quotation" disabled class="form-control"/></td>
                                                    <td class="align-middle"><input type="number" v-model="i.extra_price" readonly class="form-control"/></td>
                                                    <td class="align-middle"><input type="text" v-model="i.observation" readonly class="form-control"/></td>
                                               
                                                     <td class="align-middle">
                                                         <span v-if="i.invoice_id!=null && i.quotation==1">FE{{ i.invoice_id }}</span>
                                                        <a href="#" v-if="i.quotation==1" @click.prevent="associateInvoice(id,i.invoice_id,'Maleta','Persona',i.person_ticket_id)" 
                                                           v-tooltip="'Asignar Factura a Maleta'">
                                                            <i class="fas green fa-file-invoice" v-if="i.invoice_id!=null"/>
                                                            <i class="fas gray fa-file-invoice" v-else/>
                                                        </a>


                                                        <span v-if="i.invoice_id!=null && i.quotation==1" >/</span>
                                                        <a href="#" @click.prevent="desassociateInvoice(i.person_ticket_id,'Maleta','Persona')" v-if="i.invoice_id!=null && i.quotation==1"
                                                           v-tooltip="'Quitar factura'">
                                                            <i class="fa red fa-times"/>
                                                        </a>
                                                    </td>


                                                     <td class="align-middle">
                                                         <span v-if="i.penalty_invoice_id!=null  && i.penalty_quotation==1">FE{{ i.penalty_invoice_id }}</span>
                                                        <a href="#" v-if="i.penalty_quotation==1" @click.prevent="associateInvoice(id,i.penalty_invoice_id,'Multa','Persona',i.person_ticket_id)" 
                                                           v-tooltip="'Asignar Factura a Maleta'">
                                                            <i class="fas green fa-file-invoice" v-if="i.penalty_invoice_id!=null"/>
                                                            <i class="fas gray fa-file-invoice" v-else/>
                                                        </a>


                                                        <span v-if="i.penalty_invoice_id!=null && i.penalty_quotation==1" >/</span>
                                                        <a href="#" @click.prevent="desassociateInvoice(i.person_ticket_id,'Multa','Persona')" v-if="i.penalty_invoice_id!=null && i.penalty_quotation==1"
                                                           v-tooltip="'Quitar factura'">
                                                            <i class="fa red fa-times"/>
                                                        </a>
                                                    </td>
                                                </tr>



                                                 <tr v-for="(i,index) in selectedDataWorkers">
                                                      
                                                    <td class="align-middle">{{ i.name }}</td>
                                                    <td class="align-middle">{{ i.last_name }}</td>
                                                    <td class="align-middle">{{ i.rut }}</td>
                                                    <td class="align-middle"><input type="number" v-model="i.carry_price" readonly class="form-control"/></td>
                                                    <td class="align-middle"><input type="checkbox"  v-model="i.quotation" disabled class="form-control"/></td>
                                                    <td class="align-middle"><input type="number" readonly v-model="i.penalty" class="form-control"/></td>
                                                    <td class="align-middle"><input type="checkbox"  v-model="i.penalty_quotation" disabled class="form-control"/></td>
                                                    <td class="align-middle"><input type="number" v-model="i.extra_price" readonly class="form-control"/></td>
                                                    <td class="align-middle"><input type="text" v-model="i.observation" readonly class="form-control"/></td>
                                                  
                                                   <td class="align-middle">
                                                        <span v-if="i.invoice_id!=null && i.quotation==1">FE{{ i.invoice_id }}</span>
                                                        <a href="#" v-if="i.quotation==1" @click.prevent="associateInvoice(id,i.invoice_id,'Maleta','Tripulante',i.worker_ticket_id)" 
                                                           v-tooltip="'Asignar Factura a Maleta'">
                                                            <i class="fas green fa-file-invoice" v-if="i.invoice_id!=null"/>
                                                            <i class="fas gray fa-file-invoice" v-else/>
                                                        </a>


                                                        <span v-if="i.invoice_id!=null && i.quotation==1" >/</span>
                                                        <a href="#" @click.prevent="desassociateInvoice(i.worker_ticket_id,'Maleta','Tripulante')" v-if="i.invoice_id!=null && i.quotation==1"
                                                           v-tooltip="'Quitar factura'">
                                                            <i class="fa red fa-times"/>
                                                        </a>
                                                    </td>


                                                     <td class="align-middle">
                                                          <span v-if="i.penalty_invoice_id!=null && i.penalty_quotation==1">FE{{ i.penalty_invoice_id }}</span>
                                                        <a href="#" v-if="i.penalty_quotation==1" @click.prevent="associateInvoice(id,i.penalty_invoice_id,'Multa','Tripulante',i.worker_ticket_id)" 
                                                           v-tooltip="'Asignar Factura a Maleta'">
                                                            <i class="fas green fa-file-invoice" v-if="i.penalty_invoice_id!=null"/>
                                                            <i class="fas gray fa-file-invoice" v-else/>
                                                        </a>


                                                        <span v-if="i.penalty_invoice_id!=null && i.penalty_quotation==1" >/</span>
                                                        <a href="#" @click.prevent="desassociateInvoice(i.worker_ticket_id,'Multa','Tripulante')" v-if="i.penalty_invoice_id!=null && i.penalty_quotation==1"
                                                           v-tooltip="'Quitar factura'">
                                                            <i class="fa red fa-times"/>
                                                        </a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        </div>
                                    </div>
                                </div>

                                   <!-- <div class="col mt-4">
                                        <button type="button" class="btn btn-success float-right">
                                            Actualizar Facturacion
                                        </button>
                                    </div>-->
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>




        <!-- inicio modal-->

         <div class="modal fade" id="form-modal-person" tabindex="-1" role="dialog" aria-labelledby="form-dialog"
               aria-hidden="true" v-if="showModal" data-backdrop="static" data-keyboard="false">
             
        


     <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <!-- header -->
            <div class="modal-header align-items-center">
                <h5 class="modal-title">Añadir Personas</h5>

                <form class="ml-auto w-50" @submit.prevent="getPersons">
                    <input type="text" v-model="searchInput" class="form-control"
                           placeholder="Ingrese nombre, apellido o rut de persona">
                </form>

                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <!-- body -->
            <div class="modal-body container">
        
                <!-- tabla -->
                <div class="table-responsive p-0">
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Nombre</th>
                                <th>Apellido</th>
                                <th>Rut</th>
                                
                            </tr>
                        </thead>
                        <tbody>
                        
                            <tr v-for="w in persons.data">
                                <td class="align-middle">
                                    <input type="checkbox" v-model="selected" :value="w.id"
                                           @change="toggleSelection(w.id)">
                                </td>
                                <td class="align-middle">
                                    <span>
                                        {{ w.name }} 
                                    </span>
                                </td>
                                 <td class="align-middle">
                                    <span>
                                       {{ w.last_name }}
                                    </span>
                                </td>
                                <td class="align-middle">{{ w.rut }}</td>

                               
                            </tr>
                        </tbody>
                    </table>

                 <pagination :data="persons" @pagination-change-page="getPersons"/>
                </div>

                <!-- selección -->
                <div class="tag-input" v-if="selectedData.length > 0">
                    <div v-for="s in selectedData" class="tag-input__tag">
                        <span @click="unselect(s.id)">x</span>
                        {{ s.name }}
                    </div>
                </div>
                <span v-else><i>Aún no se han escogido personas</i></span>

                <!-- footer -->
                <div class="modal-footer">
                   
                    <button type="button" class="btn btn-primary" data-dismiss="modal">
                        Aceptar
                    </button>
                </div>
            </div>
        </div>
    </div>
      </div>
        <!-- fin modal -->


  <!--modal tripulantes-->

         <div class="modal fade" id="form-modal-worker" tabindex="-1" role="dialog" aria-labelledby="form-dialog"
               aria-hidden="true" v-if="showModal" data-backdrop="static" data-keyboard="false">
             
        


     <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <!-- header -->
            <div class="modal-header align-items-center">
                <h5 class="modal-title">Añadir Tripulantes</h5>

                <form class="ml-auto w-50" @submit.prevent="getWorkers">
                    <input type="text" v-model="searchInputWorker" class="form-control"
                           placeholder="Ingrese nombre, apellido o rut de persona">
                </form>

                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <!-- body -->
            <div class="modal-body container">
        
                <!-- tabla -->
                <div class="table-responsive p-0">
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Nombre</th>
                                 <th>Apellido</th>
                                <th>Rut</th>
                            </tr>
                        </thead>
                        <tbody>
                    
                            <tr v-for="w in workers.data">
                                <td class="align-middle">
                                    <input type="checkbox" v-model="selectedWorkers" :value="w.id"
                                           @change="toggleSelectionWorkers(w.id)">
                                </td>
                                <td class="align-middle">
                                    <span>
                                        {{ w.name }} 
                                    </span>
                                </td>
                                 <td class="align-middle">
                                    <span>
                                       {{ w.last_name }}
                                    </span>
                                </td>
                                <td class="align-middle">{{ w.rut }}</td>
                            </tr>
                        </tbody>
                    </table>

                 <pagination :data="workers" @pagination-change-page="getWorkers"/>
                </div>

                <!-- selección -->
                <div class="tag-input" v-if="selectedDataWorkers.length > 0">
                    <div v-for="s in selectedDataWorkers" class="tag-input__tag">
                        <span @click="unselectWorkers(s.id)">x</span>
                        {{ s.name }}
                    </div>
                </div>
                <span v-else><i>Aún no se han escogido tripulantes</i></span>

                <!-- footer -->
                <div class="modal-footer">
                   
                    <button type="button" class="btn btn-primary" data-dismiss="modal">
                        Aceptar
                    </button>
                </div>
            </div>
        </div>
    </div>
      </div>
        <!--fin modal tripulantes--->


                        <div class="modal fade" id="invoice-modal" tabindex="-1" role="dialog"
                             aria-labelledby="form-dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                            <invoice-modal-extra :invoice-id="selectedInvoice" :supplier-type="supplierType" v-if="showInvoiceModal" :supplier-id="supplier_id" :ticket-id="id"/>
                        </div>


                        <div class="modal fade" id="associate-invoice-modal" tabindex="-1" role="dialog"
                             aria-labelledby="form-dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                            <associate-invoice-extra-external :person-id="person_id" :person-type="persontype" :association-type="type" :supplier-id="supplier_id" :ticket-id="id" :associate-id="selectedAssociate" :associate-selected-id="selectedAssociateId"  v-if="showAssociateInvoiceModal"/>
                        </div>
        
    </div>
</template>

<script>
 import Loading from "./../Common/Loading";
    import moment from "moment";
      import InvoiceModalExtra from "./InvoiceModalExtraExternal";
     import AssociateInvoiceExtraExternal from "./AssociateInvoiceExtraExternal";
     import eventHub from "../../eventHub";

    export default {
        components: { Loading,InvoiceModalExtra,AssociateInvoiceExtraExternal},
        created () {
          this.$Progress.start();
            //this.getWorkers();
            //this.getPersons();
           // this.getAirlines();
           // this.getAirports();
           // this.getFlightStretches();
            this.getData();
            this.getFacturas();
            this.$Progress.finish();



            eventHub.$on('reloadAssociation', () => {
                $('#associate-invoice-modal').modal('hide');

               
            });

              this.$nextTick(() => $('#associate-invoice-modal').on('hidden.bs.modal', () => {
                     this.selectedAssociate= null;
                    this.selectedAssociateId= null;
                    this.selectedSupplierId= null;
                    this.showAssociateInvoiceModal = false;

                     this.$nextTick(() => this.getData());
                
            }));

            eventHub.$on('reloadinvoiceExtra', () => {     

                 
                $('#invoice-modal').modal('hide');

                      
            });

             this.$nextTick(() => $('#invoice-modal').on('hidden.bs.modal', () => {
                this.selectedInvoice = null;
                this.showInvoiceModal = false;     
                this.$nextTick(() => this.getFacturas());
                
            }));
           // this.form.induction_date = moment(new Date()).format('yyyy-MM-dd');
        },
        computed: {
            id () {
                return this.$route.params.id;
            }
        },
        data () {
            return {
                person_id:null,
                persontype:"",
                type:"",
                showInvoiceModal: false,
                showAssociateInvoiceModal:false,
                quotations:[],
                loading: false,
                submit: false,
                suppliers:[],
                persons:{},
                 many:0,
                workers:{},
                selectedWorkers: [],
                selected: [],
                tostock: [],
                tostockWorkers: [],
                tolost: [],
                tolostWorkers: [],
                selectedData: [],
                selectedDataWorkers: [],
                searchInput:'',
                searchInputWorker:'',
                showModal: false,
                airlines:{},
                airports:{},
                flightStretches:{},
                stocks:{},
                datos:null,
                have_stock:false,
                is_stock:false,
                stocks:{},
                vouchers:{},
                novoucher:0,
                nostock:0,
                loaded:0,
                editable:1,
                original_payment_type:null,
                original_voucher_id:null,
                original_stock_id:null,
                original_airline_id:null,
                final_total_price:0,
                
                formStock:new Form({
                    person_ticket_id:null,
                    ticket_id:null
                }),
                form: new Form({
                    purchase_order:null,
                    purchase_order_date:null,
                    id: null,
                    flight_stretch_id: null,
                    flight_date: null,
                    departure_time: null,
                    arrival_time: null,
                    departure_airport_id: null,
                    arrival_airport_id: null,
                    extra_price:0,
                    carry_price:0,
                    price: 0,
                    tostock:null,
                    tostockWorkers:null,
                    tolost:null,
                    tolostWorkers:null,
                    airline_id: null,
                    stock_ticket_id: null,
                    tarifa_plus:0,
                    selected:[],
                    selectedWorkers:[],
                    isedit:1,
                    ticket_id:null,
                    penalty:0,
                    dearness:0,
                    stock_id:null,
                    voucher_id:null,
                    code:null,
                    expiration_date:null,
                    passengers:0,
                    total_price:0,
                    total_extra_price:0,
                    payment_price:0,
                    real_total_price:0,
                    ticket_status_id:null
                }),
            }
        },
         watch: {
           /*'selectedDataWorkers.penalty' () {
            
                this.calculate();
            },*/
            /*
            'selectedData' () {
                 this.many = this.selectedDataWorkers.length + this.selectedData.length;
                if (this.many==1 || this.many==0){
                    //this.form.stock_ticket_id = null;
                    this.nostock = 0;
                } else{
                    if(this.form.stock_ticket_id==1){
                        this.form.stock_ticket_id = null;
                    }
                    this.nostock = 1;
                }

               this.calculate();
            },*/

             'form.voucher_id' () {
                
                     
                            this.calculate();
                              
                     
            },
            'form.stock_id' () {
                
                    
                        this.calculate();
                    
                  
            },
            'form.stock_ticket_id' () {
                if(this.form.stock_ticket_id==1){
                    this.form.voucher_id = null;
                }
                if(this.form.stock_ticket_id==2){
                    this.form.stock_id = null;
                }

                if(this.form.stock_ticket_id==null){
                    this.form.stock_id = null;
                    this.form.voucher_id = null;
                }

                this.calculate();
            },
            'form.price' () {
                this.calculate();
            },
            'stocks' () {
                if(this.loaded == 1 && this.form.stock_ticket_id == 1){
                     if(this.stocks.length==0){
                        this.nostock=1;
                        this.form.stock_ticket_id = null;
                    }else{
                        this.many = this.selectedDataWorkers.length + this.selectedData.length;
                            if (this.many==1 || this.many==0){
                                this.nostock = 0;
                            } else{
                                if(this.form.stock_ticket_id==1){
                                    this.form.stock_ticket_id = null;
                                }
                                this.nostock = 1;
                            }
                        this.calculate();
                    }
                }
            },
            'vouchers' () {
                if(this.loaded==1 && this.form.stock_ticket_id == 2){
                    if(this.vouchers.length==0){
                    this.novoucher=1;
                    this.form.stock_ticket_id = null;
                    }else{
                            this.novoucher=0;
                            this.calculate();
                    }
                }
            },
            
            
        },
        methods: {
            getFacturas(){
                this.quotations = [];
                axios.get('/api/administracion/facturacion/facturas-externas/'+this.id)
                    .then(({ data }) => this.quotations = data.data)
                    .catch(() => this.$launchError('Error al obtener listado de facturas'))
                    .finally(() => {
                        this.$Progress.finish();
                        this.loading = false;
                    });
            },
            deleteInvoice(index){
                 Swal.fire({
                                title: '¿Estás seguro que deseas quitar la factura?',
                                text: 'Puedes agregar otra luego',
                                showCancelButton: true,
                                confirmButtonColor: '#d33',
                                cancelButtonColor: '#3085d6',
                                confirmButtonText: 'Si',
                                cancelButtonText: 'No'
                            }).then((result) => {
                                if (result.value) {


                                     axios.delete('/api/administracion/facturacion/elimina-factura-externa/'+index+'/'+this.id)
                                        .then(({ data }) => this.getData(),this.getFacturas())
                                        .catch(() => this.$launchError('Error al obtener listado de facturas'))
                                        .finally(() => {
                                            this.$Progress.finish();
                                            this.loading = false;
                                        });

                                    // let j = this.quotations.findIndex(w => w.index == index);
                                    /*this.transfers.data.forEach(w => { 
                                            if(w.invoice_id == index){
                                                w.invoice_id = null;
                                            }
                                    }); 
                                  

                                      this.datos.forEach(w => { 
                                            if(w.invoice_id == index){
                                                w.invoice_id = null;
                                            }
                                    }); */
                                   /// this.quotations.splice(j, 1);
                                }
                            });
              
            },
            stretchType(type){
               if(type==1){
                return "Ida";
               } 
               return "Vuelta";
            },
              setInvoice(id=null){
                    this.selectedInvoice= id;
                    this.showInvoiceModal = true;
                    this.supplierType = 5;
                    this.$nextTick(() => $('#invoice-modal').modal('show'));
            },
            desassociateInvoice(id,tipo,tipopersona){
                 Swal.fire({
                    title: '¿Estás seguro que desea quitar la factura ?',
                    text: 'Puedes agregar otra mas tarde',
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Si',
                    cancelButtonText: 'No'
                }).then((result) => {
                    if (result.value) {
                         axios.post(`/api/administracion/facturacion/desasocia-factura-externa`, {
                            id: id,
                            type: tipo,
                            person_type:tipopersona
                        })
                        .then(() => {
                            this.getData();
                            this.$launchSuccess(`Éxito al quitar factura`);
                        })
                        .catch(() => this.$launchError(`Error al quitar factura`));  
                    }
                }); 
            },
             associateInvoice(id,invoice_id,tipo,persona,personid){
                    this.type = tipo;
                    this.selectedAssociate= id;
                    this.selectedAssociateId= invoice_id;
                    this.selectedSupplierId= this.datos.supplier_id;
                    this.persontype= persona;
                    this.person_id = personid;
        
                this.showAssociateInvoiceModal = true;
                this.$nextTick(() => $('#associate-invoice-modal').modal('show'));
            },
            changeVoucherId(){
               /*  if(this.loaded == 1){
                         if(this.form.voucher_id==null){
                     this.form.extra_price = 0;
                 }else{
                     this.calculate();
                 }
                }*/
              
            },
            changeStockId(){
          /* if(this.loaded == 1){
                        if(this.form.stock_id==null){
                     this.form.extra_price = 0;
                 }else{
                     this.calculate();
                 }
                }*/
            },
            changeStockTicketId(){
                /*if(this.loaded == 1){
                    this.calculate();
                }*/
               
            },
            changePrice(){
                 /*if(this.loaded == 1){
                    this.calculate();
                }*/
            },
            changeStocks(){
                /*  if(this.loaded == 1){
                      if(this.stocks.length==0){
                            this.nostock=1;
                            this.form.stock_ticket_id = null;
                        }else{
                            this.many = this.selectedDataWorkers.length + this.selectedData.length;
                                if (this.many==1 || this.many==0){
                                    this.nostock = 0;
                                } else{
                                    if(this.form.stock_ticket_id==1){
                                        this.form.stock_ticket_id = null;
                                    }
                                    this.nostock = 1;
                                }
                            this.calculate();
                        }
                }*7
            },
            changeVouchers(){
                /*if(this.loaded == 1){
                      if(this.vouchers.length==0){
                            this.novoucher=1;
                            this.form.stock_ticket_id = null;
                        }else{
                                this.novoucher=0;
                                this.calculate();
                        }
                }*/
            },
           /* changeAirline:function(event){
                alert(event.target.value);
                },*/
            changePenalty(){
                this.calculate();
            },
            changeEdit(){
                /*if(this.form.code!='' && this.form.code!=null){
                    this.editable = 0;
                }else{
                    this.editable = 1;
                }*/
            },
            changeAirline(event){
                    if (event.target.value!==null) {

                    if(this.form.airline_id==this.original_airline_id){
                        this.datos.payment_type = this.original_payment_type;
                        this.datos.voucher_id = this.original_voucher_id;
                        this.datos.stock_ticket_id = this.original_stock_id;

                    }else{

                        this.form.stock_ticket_id = null;
                        this.datos.voucher_id = null;
                        this.datos.stock_ticket_id = null;
                    }
                    
                    this.nostock = 1;
                    this.novoucher = 1;
                    this.getVouchers(event.target.value);
                    this.getStocks(event.target.value);

                   }
        
            },
            calculate(){      
                if(this.loaded == 1){

                    let real_total = 0;

                    this.selectedDataWorkers.forEach(d => {
                                 
                                    if(d.penalty!==null && d.penalty!==''){
                                       real_total = parseInt(real_total) + parseInt(d.penalty);
                                    }
                                    
                    });

                    this.selectedData.forEach(d => {
                                   
                                    if(d.penalty!==null && d.penalty!==''){
                                       real_total = parseInt(real_total) + parseInt(d.penalty);
                                    }
                                    
                    });

                    if(this.form.penalty!==null && this.form.penalty!==''){
                        real_total = parseInt(real_total) + parseInt(this.form.penalty);
                    }
                    if(this.form.dearness!==null && this.form.dearness!==''){
                        real_total = parseInt(real_total) + parseInt(this.form.dearness);
                    }

                     this.many = this.selectedDataWorkers.length + this.selectedData.length;

                  

                     if(this.form.voucher_id==null && this.form.stock_id==null){
                        this.form.extra_price =  parseInt(real_total);
                        this.final_total_price = parseInt(real_total) + (parseInt(this.form.price) * parseInt(this.many));
                        this.form.real_total_price = parseInt(real_total) + (parseInt(this.form.price) * parseInt(this.many)) ;
                        this.form.payment_price = 0;
                    }


                     if(this.form.stock_ticket_id==2 && this.vouchers.length>0){
                         if(this.form.voucher_id!=null){
                                let w = this.vouchers.find(w => w.id === this.form.voucher_id);
                                if(this.many>0){
                                let diff = (parseInt(this.form.price) * parseInt(this.many)) + parseInt(real_total);
                                this.form.payment_price = w.price;
                                if(diff>w.price){
                                    this.form.extra_price = (w.price-diff) *-1;
                                    this.final_total_price =  parseInt(this.form.extra_price) + (parseInt(this.form.price) * parseInt(this.many));
                                    this.form.real_total_price = parseInt(this.form.extra_price);
                                }else{
                                    this.form.extra_price = 0;
                                    this.form.real_total_price = parseInt(this.form.extra_price) + parseInt(real_total) + (parseInt(this.form.price) * parseInt(this.many)) ;
                                }
                                } 
                         }    
                    }

                    if(this.form.stock_ticket_id==1 && this.stocks.length>0){
                        if(this.form.stock_id!=null){                         
                                let w = this.stocks.find(w => w.id === this.form.stock_id);
                                if(this.many>0){
                                let diff = (parseInt(this.form.price) * parseInt(this.many)) + parseInt(real_total);
                                this.form.payment_price = w.price;
                                if(diff>w.price){
                                    this.form.extra_price = (w.price-diff) *-1;
                                    this.final_total_price = parseInt(this.form.extra_price) + (parseInt(this.form.price) * parseInt(this.many));
                                    this.form.real_total_price = parseInt(this.form.extra_price);
                                }else{
                                    this.form.extra_price = 0;
                                    this.form.real_total_price = parseInt(this.form.extra_price) + parseInt(real_total) + (parseInt(this.form.price) * parseInt(this.many)) ;
                                }
                                } 
                        }                
                    }

                    if(this.form.stock_ticket_id==null){
                        this.form.extra_price =  parseInt(real_total);
                        this.final_total_price = parseInt(real_total) + (parseInt(this.form.price) * parseInt(this.many));
                        this.form.real_total_price = parseInt(real_total) + (parseInt(this.form.price) * parseInt(this.many)) ;
                        this.form.payment_price = 0;
                    }
                }        
               
              
            },
             unselect (id) {
                this.selected.splice(this.selected.findIndex(s => s === id), 1);
                this.selectedData.splice(this.selectedData.findIndex(s => s.id === id), 1);
                this.calculate();
            },
            unselectWorkers (id) {
                this.selectedWorkers.splice(this.selectedWorkers.findIndex(s => s === id), 1);
                this.selectedDataWorkers.splice(this.selectedDataWorkers.findIndex(s => s.id === id), 1);
                this.calculate();
            },
             removestock (id,index) {
                this.tostock.splice(this.tostock.findIndex(s => s.person_ticket_id === id), 1);
                this.have_stock = 0;
                this.selectedData[index].stock = 0;
               
            },
             removelost (id,index) {
                this.tolost.splice(this.tolost.findIndex(s => s.person_ticket_id === id), 1);
                this.selectedData[index].ticket_status_id = 3;
               
            },
            removestockWorkers (id,index) {
                this.tostockWorkers.splice(this.tostockWorkers.findIndex(s => s.worker_ticket_id === id), 1);
                this.have_stock = 0;
                this.selectedDataWorkers[index].stock = 0;
               
            },

             removelostWorkers (id,index) {
                this.tolostWorkers.splice(this.tolostWorkers.findIndex(s => s.worker_ticket_id === id), 1);
                this.selectedDataWorkers[index].ticket_status_id = 3;
               
            },
            toggleSelection (id) {
                if (this.selected.includes(id)) {
                    let w = this.persons.data.find(w => w.id === id);

                    this.selectedData.push({
                        id: id,
                        rut: w.rut,
                        last_name: w.last_name,
                        name: w.name,
                        carry_price: 0,
                        stock:0,
                        iseditable:1,
                        quotation:false,
                        penalty_quotation:false,
                        extra_price:0,
                        observation:'',
                        penalty:0
                    });
                } else {
                    this.selectedData.splice(this.selectedData.findIndex(s => s.id === id), 1);
                }
            },
              toggleSelectionWorkers (id) {
                if (this.selectedWorkers.includes(id)) {
                    let w = this.workers.data.find(w => w.id === id);

                    this.selectedDataWorkers.push({
                        id: id,
                        rut: w.rut,
                        last_name: w.last_name,
                        name: w.name,
                        carry_price: 0,
                        stock:0,
                        iseditable:1,
                         quotation:false,
                         penalty_quotation:false,
                        extra_price:0,
                        observation:'',
                        penalty:0
                    });
                } else {
                    this.selectedDataWorkers.splice(this.selectedDataWorkers.findIndex(s => s.id === id), 1);
                }
            },
            personModal () {
                this.getPersons();
                this.showModal = true;
                this.$nextTick(() => $('#form-modal-person').modal('show'));
            },
              setTicket(){
                 if(this.many==0){
                      this.$launchError('No puede guardar si no ha agregado personas o tripulantes');
                  }else{
                         Swal.fire({
                    title: '¿Estás seguro que deseas guardar este pasaje?',
                    text: 'Los cambios a Stock y Eliminacion no pueden ser revertidos',
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Si',
                    cancelButtonText: 'No'
                }).then((result) => {
                    if (result.value) {
                        this.form.ticket_id = this.id;
                        this.form.selected = this.selectedData;
                        this.form.selectedWorkers = this.selectedDataWorkers;
                        this.form.tostock = this.tostock;
                        this.form.tostockWorkers = this.tostockWorkers;

                        this.form.tolost = this.tolost;
                        this.form.tolostWorkers = this.tolostWorkers;
                        
                        this.form.passengers = this.many;
                        this.form.total_price = this.final_total_price;
                        this.form.total_extra_price = (this.form.extra_price/this.many);

                        if(this.form.stock_ticket_id==1){
                            this.form.voucher_id = null;
                        }
                        if(this.form.stock_ticket_id==2){
                            this.form.stock_id = null;
                        }
                        if(this.form.stock_ticket_id==null){
                            this.form.stock_id = null;
                            this.form.voucher_id = null;
                        }
                            
                         this.form.post('/api/administracion/personas/nuevo/pasaje')
                        .then((data) => {
                            //this.persons = [];
                            //this.selectedData= [];
                            //this.selected = [];
                            //this.form.reset();

                             this.tostock.forEach(d => {
                                  let t = this.selectedData.findIndex(w => w.id === d.id);
                                  this.selectedData[t].iseditable = 0;
                                  
                              
                             });

                             this.tostockWorkers.forEach(d => {
                                  let t = this.selectedDataWorkers.findIndex(w => w.id === d.id);
                                  this.selectedDataWorkers[t].iseditable = 0;
                                 
                              
                             });


                             this.tolost.forEach(d => {
                                  let t = this.selectedData.findIndex(w => w.id === d.id);
                                  this.selectedData[t].editable_lost = 0;
                              
                             });

                             this.tolostWorkers.forEach(d => {
                                  let t = this.selectedDataWorkers.findIndex(w => w.id === d.id);
                                  this.selectedDataWorkers[t].editable_lost = 0;
                                 
                              
                             });

                               if(this.form.code!='' && this.form.code!=null){
                                    this.editable = 0;
                                }else{
                                    this.editable = 1;
                                }
                  
                            this.have_stock = data.data.data.have_stock;
                            this.is_stock = data.data.data.is_stock;
                            this.$launchSuccess('Se guardo el pasaje correctamente');
                            this.$Progress.finish();
                            //window.location.href = '/api/editar-pasaje/'+this.id;
                            
                        })
                        .catch(() => {
                            this.$Progress.fail();
                            this.$launchError('Error al guardar pasaje');
                        });
                        }
                    });  
                  }     
            },
            toStock(person_ticket_id,index,id,carry_price){
                if(this.form.code==null  || this.form.code==''){
                    this.$launchError('Debe agregar codigo de vuelo para pasar a stock');
                }else{
                    if (this.tostock.includes(id)) {
                  
                    } else {
                        this.tostock.push({person_ticket_id:person_ticket_id,id:id,carry_price:carry_price});
                        this.have_stock = 1;
                        this.selectedData[index].stock = 1;
                    }
                }
                
            },
            toLost(person_ticket_id,index,id,carry_price){
                if(this.form.code==null  || this.form.code==''){
                    this.$launchError('Debe agregar codigo de vuelo para pasar a stock');
                }else{
                    if (this.tolost.includes(id)) {
                  
                    } else {
                        this.tolost.push({person_ticket_id:person_ticket_id,id:id,carry_price:carry_price});
                        this.selectedData[index].ticket_status_id = 5;
                       // this.selectedData[index].stock = 1;
                    }
                }
                
            },
           
            toStockWorkers(worker_ticket_id,index,id,carry_price){
                if(this.form.code==null || this.form.code==''){
                    this.$launchError('Debe agregar codigo de vuelo para pasar a stock');
                }else{
                    if (this.tostockWorkers.includes(id)) {
                  
                    } else {
                        this.tostockWorkers.push({worker_ticket_id:worker_ticket_id,id:id,carry_price:carry_price});
                        this.have_stock = 1;
                        this.selectedDataWorkers[index].stock = 1;
                    }
                }
                
            },
             toLostWorkers(worker_ticket_id,index,id,carry_price){
                if(this.form.code==null || this.form.code==''){
                    this.$launchError('Debe agregar codigo de vuelo para marcar como perdido');
                }else{
                    if (this.tolostWorkers.includes(id)) {
                  
                    } else {
                        this.tolostWorkers.push({worker_ticket_id:worker_ticket_id,id:id,carry_price:carry_price});
                         this.selectedDataWorkers[index].ticket_status_id = 5;
                        //this.have_stock = 1;
                        //this.selectedDataWorkers[index].lost = 1;
                    }
                }
                
            },
             workerModal () {
                this.getWorkers();
                this.showModal = true;
                this.$nextTick(() => $('#form-modal-worker').modal('show'));
            },
             getWorkers (page = 1) {
                this.$Progress.start();
                this.loading = true;

                axios.get('/api/administracion/workers?page=' + page + (this.searchInputWorker ? `&search=${this.searchInputWorker}`: ''))
                    .then(({ data }) => this.workers = data.data)
                    .catch(() => this.$launchError('Error al obtener listado de tripulantes'))
                    .finally(() => {
                        this.$Progress.finish();
                        this.loading = false;
                    });
                   
            },
            setStock(person_ticket_id,index){
                Swal.fire({
                    title: '¿Estás seguro que desea enviar este pasaje a stock?',
                    text: 'No podrás revertir esta acción',
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Si',
                    cancelButtonText: 'No'
                }).then((result) => {
                    if (result.value) {
                        this.formStock.person_ticket_id = person_ticket_id;
                        this.formStock.ticket_id = this.id;
                        this.formStock.post('/api/administracion/personas/pasajes/a-stock')
                        .then((data) => {
           
                            this.is_stock = data.data.data;
                            this.have_stock = 1;
                            this.selectedData[index].stock = 1;
                            this.$launchSuccess('Se guardo el pasaje correctamente');
                            this.$Progress.finish();
                        })
                        .catch(() => {
                            this.$Progress.fail();
                            this.$launchError('Error al guardar pasaje');
                        });
                        }
                });     
            },
            getData(){
                this.loading = true;
                this.$Progress.start();

                axios.get('/api/administracion/find-pasaje-external/'+this.id)
                    .then(({ data }) => this.datos = data.data)
                    .catch(() => {
                        this.$launchError('Error al obtener traslado');
                        this.$Progress.fail();
                    })
                    .finally(() => {
                        this.loading = false;
                        this.$Progress.finish();

                      
                      

                    this.form.airline_id= this.datos.airline;
                    this.supplier_id = this.datos.supplier_id;
             
                    this.form.stock_ticket_id =this.datos.payment_type;
                    this.original_payment_type = this.datos.payment_type;
                    this.original_voucher_id = this.datos.voucher_id;
                    this.original_stock_id = this.datos.stock_ticket_id;
                    this.original_airline_id = this.datos.airline;
                
                   
                    this.form.flight_stretch_id= this.datos.stretch;
                    this.form.flight_date= this.datos.flight_date;
                    this.form.departure_time= this.datos.departure_time;
                    this.form.arrival_time= this.datos.arrival_time;
                    this.form.departure_airport_id= this.datos.departure_airport_id;
                    this.form.arrival_airport_id= this.datos.arrival_airport_id;
                    this.form.extra_price= this.datos.extra_price;
                    this.form.carry_price= 0;
                    this.form.ticket_status_id= this.datos.ticket_status_id;
                    this.form.price= this.datos.price;           
                    this.form.tarifa_plus= this.datos.plus;
                    this.have_stock = this.datos.have_stock;
                    this.is_stock = this.datos.is_stock;
                    if(this.datos.dearness!=null){
                         this.form.dearness = this.datos.dearness;
                    }else{
                        this.form.dearness = 0;
                    }
                   
                    this.form.penalty = this.datos.penalty;
                    this.form.code = this.datos.code;
                    this.form.expiration_date = this.datos.expiration_date;
                    this.form.purchase_order = this.datos.oc;
                    this.form.purchase_order_date = this.datos.oc_date;
                    if(this.datos.code!=null && this.datos.code!=''){
                        this.editable = 0;
                    }

                
                    
         

                    
         
             
             
                           
                    this.many = this.datos.passengers;

                    this.selectedData = [];

                    this.datos.persons.forEach(d => {
                                this.selected.push(d.id); 
                                let editable = 1;
                                let editable_lost = 1;
                                if(d.stock==1){
                                    editable = 0;
                                }
                                if(d.ticket_status_id==5){
                                    editable_lost = 0;
                                }
                                this.selectedData.push({id:d.id,
                                    name:d.name,
                                    last_name:d.last_name,
                                    rut:d.rut,
                                    carry_price:d.carry_price,
                                    person_ticket_id:d.person_ticket_id,
                                    stock:d.stock,
                                    iseditable:editable,
                                    quotation:d.quotation,
                                    penalty_quotation:d.penalty_quotation,
                                    extra_price:d.extra_price,
                                    observation:d.observation,
                                    penalty:d.penalty,
                                    ticket_status_id:d.ticket_status_id,
                                    editable_lost: editable_lost,
                                    invoice_id:d.invoice_id,
                                    penalty_invoice_id:d.penalty_invoice_id
                                    });
                            });
                        

                     this.selectedDataWorkers = [];
                     this.datos.workers.forEach(d => {
                                this.selectedWorkers.push(d.id); 
                                let editable = 1;
                                let editable_lost = 1;
                                if(d.stock==1){
                                    editable = 0;
                                }
                                if(d.ticket_status_id==5){
                                    editable_lost = 0;
                                }
                                this.selectedDataWorkers.push({
                                    id:d.id,
                                    name:d.name,
                                    last_name:d.last_name,
                                    rut:d.rut,
                                    carry_price:d.carry_price,
                                    worker_ticket_id:d.worker_ticket_id,
                                    stock:d.stock,
                                    iseditable:editable,
                                    quotation:d.quotation,
                                    penalty_quotation:d.penalty_quotation,
                                    extra_price:d.extra_price,
                                    observation:d.observation,
                                    penalty:d.penalty,
                                    ticket_status_id:d.ticket_status_id,
                                    editable_lost: editable_lost,
                                     invoice_id:d.invoice_id,
                                    penalty_invoice_id:d.penalty_invoice_id
                                    });
                            });

                                this.loaded =1;
                                this.calculate();
                         
                    });
            },

        
            getPersons (page = 1) {
                this.$Progress.start();
                this.loading = true;

                axios.get('/api/administracion/personas?page=' + page + (this.searchInput ? `&search=${this.searchInput}`: ''))
                    .then(({ data }) => this.persons = data.data)
                    .catch(() => this.$launchError('Error al obtener listado de personas'))
                    .finally(() => {
                        this.$Progress.finish();
                        this.loading = false;
                    });
                   
            },
            getAirlines () {
                axios.get('/api/logistica/aerolineas')
                    .then(({ data }) => this.airlines = data.data)
                    .catch(() => this.$launchError('Error al obtener aerolíneas'));
            },

            getAirports () {
                axios.get('/api/logistica/aeropuertos')
                    .then(({ data }) => this.airports = data.data)
                    .catch(() => this.$launchError('Error al obtener aeropuertos'));
            },

            getFlightStretches () {
                axios.get('/api/logistica/tramos')
                    .then(({ data }) => this.flightStretches = data.data)
                    .catch(() => this.$launchError('Error al obtener tramos'));
            },
            setPaymentMode(){
                if(this.form.stock_ticket_id==1){
                    this.getStocks(this.form.airline_id);
                    
                }
                if(this.form.stock_ticket_id==2){
                    this.getVouchers(this.form.airline_id);
                }
            },

              getVouchers (id) {
                  if(this.datos.voucher_id==null){
                    axios.get('/api/administracion/pasajes/vouchers/'+id)
                    .then(({ data }) => this.vouchers = data.data)
                    .catch();
                  }else{
                    axios.get('/api/administracion/pasajes/vouchersWithUsed/'+id+'/'+this.datos.voucher_id+'/'+this.id)
                    .then(({ data }) => this.vouchers = data.data,this.form.voucher_id = this.datos.voucher_id)
                    .catch();
                  }
           

                    
            },
            getStocks (id) {

                if(this.datos.stock_ticket_id==null){
                    axios.get('/api/administracion/pasajes/stocks/'+id)
                    .then(({ data }) => this.stocks = data.data)
                    .catch();
                    
                }else{
                    axios.get('/api/administracion/pasajes/stocksWithUsed/'+id+'/'+this.datos.stock_ticket_id)
                    .then(({ data }) => this.stocks = data.data,this.form.stock_id = this.datos.stock_ticket_id)
                    .catch();
                    
                }

             
                    
            },
        }
    }
</script>


<template>

  <section class="content">
      <div class="container-fluid">
          <div class="row">


              <div class="col-12">
                  <div class="card">
                      <loading v-if="loading"/>
                        <!--

                    <div class="card-header">
                          <div class="row align-items-center">
                              <h3 class="col card-title">Facturas</h3>

                              <div class="col-6">
                                
                              </div>

                              <div class="col-2">
                                 <button class="btn btn-primary float-right" 
                                                
                                                @click="setInvoice(null)">
                                            Añadir Factura
                                        </button>
                              </div>
                              
                          </div>
                      </div>
                      <div class="card-body table-responsive p-0">
                          <table class="table table-hover" v-if="quotations.length>0">
                              <thead>
                                  <tr>
                                      <th>#</th>
                                                     <th>Cuenta Bancaria</th>
                                                    <th>Nº Factura</th>
                                                   
                                                    <th>Proveedor</th>
                                                    <th>Tipo Proveedor</th>
                                                    <th>Acciones</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr v-for="(t,i) in quotations" >

                                                    <td class="align-middle">FT{{ t.index }}</td>
                                                    <td class="align-middle">{{ t.account_number }} - {{ t.account_name }}</td>
                                                    <td class="align-middle">{{ t.invoice_number }}</td>
                                                 
                                                    <td class="align-middle">{{ t.supplier }}</td>
                                                    <td class="align-middle">{{ t.supplier_type }}</td>
                         
                                     <td>
                                     <span style="display:none;">{{t.transfers}}</span>
                                                        <a href="#" @click.prevent="setInvoice(t.index)"
                                                           v-tooltip="'Editar Factura'">
                                                            <i class="fa green fa-edit"/>
                                                        </a>
                                                    
                                                        <span >/</span>
                                                        <a href="#" @click.prevent="deleteInvoice(t.index)"
                                                           v-tooltip="'Eliminar factura'">
                                                            <i class="fa red fa-times"/>
                                                        </a>
                                       </td>
                                  </tr>
                              </tbody>
                          </table>
                           <p class="text-center mb-4" style="margin-top:20px;" v-else>
                                            <i>No se han agregado facturas aún.</i>
                            </p>
                      </div>
                      <div class="card-footer">
                          <pagination :data="transfers" @pagination-change-page="getTransfers"/>
                      </div>-->

                    <!-- -->

                      <div class="card-header">
                          <div class="row align-items-center">
                              <h3 class="col card-title">Pasajes Facturables</h3>

                              <div class="col-6">
                                  <!--<form @submit.prevent="getTransfers">
                                      <input type="text" v-model="searchInput" class="form-control"
                                             placeholder="Ingrese busqueda por fecha subida, fecha bajada, proveedor, observacion, precio">
                                  </form>-->
                              </div>

                            <!--  <div class="col-2">
                                    <button type="button" v-if="can" class="btn btn-success float-right" @click="saveQuotation" style="color:#ffffff !important;">
                                    Guardar
                                </button>
                              </div>-->

                          </div>
                      </div>




<div style="height:1px !important;">
<v-app >

          <v-card >
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Búsqueda"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
  <v-data-table 
      :headers="headers"
      :items="datos"
      :search="search"
       :loading="loading"
       :items-per-page="30"
       no-results-text="No se han encontrado pasajes"
       no-data-text="No existen pasajes"
      class="elevation-1"
        loading-text="Cargando... Espere"
         :footer-props="{
           'items-per-page-text':'Pasajes por página',
           'items-per-page-options': [20, 50, 100, -1]
      }"
    >
 <template v-slot:item.accion="{ item, index }">


                      
                                           
                                                         <a :href="`/facturar-pasaje/${item.id}`"  v-if="item.passengers>0 && item.code!='-'" 
                                                           v-tooltip="'Ir a Facturar Pasaje'">
                                                              <v-icon small color="green">
                                                            fa-file-text-o
                                                            </v-icon>    
                                                        </a>

                                                        <!--<span v-if="item.invoice_id!=null && item.passengers>0" >/</span>
                                                        <a href="#" v-if="item.invoice_id!=null && item.passengers>0" @click.prevent="dessasociateInvoice(item.invoice_id,item.id)"
                                                           v-tooltip="'Quitar Factura a Pasaje'">
                                                               <v-icon small color="red">
                                                                fa-times
                                                                </v-icon>
                                                        </a>-->
                                         
  </template>
  <template v-slot:footer.page-text="items"> {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }} </template>
</v-data-table>
</v-card>



  </v-app>
</div>

 
    



                    <!--
                      <div class="card-body table-responsive p-0">
                          <table class="table table-hover">
                              <thead>
                                  <tr>
                                      <th class="align-middle">Pasajeros</th>
                                      <th class="align-middle">Aerolinea</th>
                                      <th class="align-middle">Código</th>
                                      <th class="align-middle">T. Unitaria</th>
                                      <th class="align-middle">T. Total</th>
                                      <th class="align-middle">Plus</th>
                                      <th class="align-middle">Tramo</th>
                                      <th class="align-middle">F. Vuelo</th>
                                      <th class="align-middle">H. Partida</th>
                                      <th class="align-middle">H. Llegada</th>
                                      <th class="align-middle">A. Origen</th>
                                      <th class="align-middle">A. Destino</th>
                                      <th class="align-middle">M. Maleta</th>
                                      <th class="align-middle">T. Extra</th>
                                      <th class="align-middle">Tripulacion</th>
                                      <th class="align-middle">Opciones</th>
                                  </tr>
                              </thead>
                              <tbody>
                                 
                                  <tr v-for="t in transfers.data"  >
                                   
                                      <td class="align-middle">{{ t.passengers }}</td>
                                      <td class="align-middle">{{ t.airline }}</td>
                                      <td class="align-middle">{{ t.code }}</td>
                                      <td class="align-middle">{{ t.price | dotNumber}}</td>
                                      <td class="align-middle">{{ t.total_price | dotNumber}}</td>
                                      <td class="align-middle">{{ plustype(t.plus) }}</td>
                                      <td class="align-middle">{{ t.stretch }}</td>
                                      <td class="align-middle">{{ t.flight_date }}</td>
                                      <td class="align-middle">{{ t.departure_time }}</td>
                                      <td class="align-middle">{{ t.arrival_time }}</td>
                                      <td class="align-middle">{{ t.airport1 }}</td>
                                      <td class="align-middle">{{ t.airport2 }}</td>
                                      <td class="align-middle">{{ t.carry | dotNumber}}</td>
                                      <td class="align-middle">{{ t.extra_price | dotNumber}}</td>
                                       <td class="align-middle">{{ t.crew }}</td>
                                      <td>
                                                         <a href="#" v-if="t.passengers>0 && t.code!='-'" @click.prevent="associateInvoice(t.id,t.supplier_id,t.invoice_id)"
                                                           v-tooltip="'Asociar Factura a Pasaje'">
                                                            <i class="fa green fa-file-invoice" v-if="t.invoice_id!=null"/>
                                                            <i class="fa gray fa-file-invoice" v-else/>
                                                        </a>

                                                        <span v-if="t.invoice_id!=null && t.passengers>0" >/</span>
                                                        <a href="#" v-if="t.invoice_id!=null && t.passengers>0" @click.prevent="dessasociateInvoice(t.invoice_id,t.id)"
                                                           v-tooltip="'Quitar Factura a Pasaje'">
                                                             <i class="fa red fa-times"/>
                                                        </a>
                                      </td>
                                  </tr>
                              </tbody>
                      </table>
                      </div>
                    -->


                     <!-- <div class="card-footer">
                          <button type="button" v-if="can" class="btn btn-success float-right" @click="saveQuotation">
                                    Guardar
                                </button>

                          <pagination :data="transfers" @pagination-change-page="getTransfers"/>
                      </div>-->

                      
                  </div>
                   
              </div>

                             



                        <div class="modal fade" id="invoice-modal" tabindex="-1" role="dialog"
                             aria-labelledby="form-dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                            <invoice-modal-extra :invoice-id="selectedInvoice" :supplier-type="supplierType" v-if="showInvoiceModal"/>
                        </div>


                        <div class="modal fade" id="associate-invoice-modal" tabindex="-1" role="dialog"
                             aria-labelledby="form-dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                            <associate-invoice-extra :supplier-id="selectedSupplierId" :associate-id="selectedAssociate" :associate-selected-id="selectedAssociateId"  v-if="showAssociateInvoiceModal"/>
                        </div>

          </div>
      </div>
  </section>

</template>

<script>
    import Loading from "./../Common/Loading";
    import InvoiceModalExtra from "./InvoiceModalExtra";
     import AssociateInvoiceExtra from "./AssociateInvoiceExtra";
     import eventHub from "../../eventHub";

    export default {
        components: { Loading,InvoiceModalExtra,AssociateInvoiceExtra},
        computed: {
            can () {
                    let many = 0
                    this.datos.forEach(w => { 
                                            if(w.passengers > 0 && w.code!='-'){
                                                many = many+1;
                                            }
                    }); 
                    console.log(many);
                    if(many==0){
                        return false;
                    }
                    return true;
                    
            }
        },
        created () {
            this.$Progress.start();
            this.getTransfers();
            this.$Progress.finish();

             eventHub.$on('reloadAssociation', () => {
                $('#associate-invoice-modal').modal('hide');
            });

              this.$nextTick(() => $('#associate-invoice-modal').on('hidden.bs.modal', () => {
                     this.selectedAssociate= null;
                    this.selectedAssociateId= null;
                    this.selectedSupplierId= null;
                    this.showAssociateInvoiceModal = false;
                
            }));

            eventHub.$on('reloadinvoiceExtra', () => {
                $('#invoice-modal').modal('hide');
            });

             this.$nextTick(() => $('#invoice-modal').on('hidden.bs.modal', () => {
                this.selectedInvoice = null;
                this.showInvoiceModal = false;
                
            }));

           
        },
        mounted(){
          
        },
        data () {
            return {
                loading: true,
                searchInput: null,
                transfers: { data:[]},
                selectedInvoice:null,
                showInvoiceModal: false,
                supplierType:null,
                selectedSupplierId:null,
                selectedAssociate: null,
                selectedAssociateId:null,
                showAssociateInvoiceModal:false,
                test:"lo que sea",
                quotations:[],
                 search: '',
                headers: [                 
                { text: 'Pasajeros', align: 'start',sortable: false,value: 'passengers'},
                { text: 'Aerolinea', value: 'aerolinea' },
                { text: 'Código', value: 'code' },
                { text: 'T. Unitaria', value: 'unitaria' },
                { text: 'T. Total', value: 'total' },
                { text: 'OC', value: 'plus' },
                { text: 'Tramo', value: 'tramo' },
                { text: 'F. Vuelo', value: 'vuelo' },
                { text: 'H. Partida', value: 'partida' },
                { text: 'H. Llegada', value: 'llegada' },
                { text: 'A. Origen', value: 'origen' },
                { text: 'A. Destino', value: 'destino' },
                { text: 'M. Maleta', value: 'maleta' },
                { text: 'T. Extra', value: 'extra' },
                /*{ text: 'Tripulacion', value: 'tripulacion' },*/
                { text: 'Opciones', value: 'accion', sortable: false },
                ],
                datos:[], 

            }
        },
        methods: {
            
              quotation(plus){
                if(plus==1){
                    return "Si";
                }
                return "No";
            },
             plustype(plus){
                if(plus==1){
                    return "Si";
                }
                return "No";
            },
            
            getTransfers (page = 1) {
                this.$Progress.start();
                this.loading = true;

                axios.get('/api/administracion/pasajes-facturables')
                     .then(data => {
                    this.datos = [];
                    this.transfers = data.data;
                     console.log(this.transfers);
                     console.log(this.transfers.data.length);
                        for(let i = 0; i < this.transfers.data.length; i++) {
                            var t = this.transfers.data[i];
                            var valor = this.$options.filters.dotNumber(t.price );
                            var total = this.$options.filters.dotNumber(t.total_price );
                            var maleta = this.$options.filters.dotNumber(t.carry );
                            var extra = this.$options.filters.dotNumber(t.extra_price );         
                            this.datos.push({invoice_id:t.invoice_id,supplier_id:t.supplier_id,id:t.id,tripulacion:t.crew,extra:extra,maleta:maleta,destino:t.airport2,origen:t.airport1 ,llegada:t.arrival_time,partida:t.departure_time,passengers:t.passengers,aerolinea:t.airline,code:t.code,unitaria:"$"+valor,total:"$"+total,plus:t.oc,tramo:t.stretch,vuelo:t.flight_date,accion:true});
                        }
                       console.log(this.datos);
                     })
                    .catch(() => this.$launchError('Error al obtener listado de pasajes'))
                    .finally(() => {
                        this.$Progress.finish();
                        this.loading = false;
                    });
            },

               setInvoice(id=null){
                    this.selectedInvoice= id;
                    this.showInvoiceModal = true;
                    this.supplierType = 5;
                    this.$nextTick(() => $('#invoice-modal').modal('show'));
            },
            associateInvoice(id,idsupplier,invoice_id){
                    this.selectedAssociate= id;
                    this.selectedAssociateId= invoice_id;
                    this.selectedSupplierId= idsupplier;
                    this.showAssociateInvoiceModal = true;
                    this.$nextTick(() => $('#associate-invoice-modal').modal('show'));
            },
            dessasociateInvoice(index, deletevalue){
             
                                     let j = this.quotations.find(w => w.index == index);
                                     console.log(j);

                                        var index = j.transfers.findIndex(x => x.id==deletevalue); 
                                         console.log(index);
                                        j.transfers.splice(index, 1);

                                        var elimina = this.transfers.data.find(x => x.id==deletevalue); 
                                         console.log(elimina);
                                        elimina.invoice_id = null;

                                        var eliminadato = this.datos.find(x => x.id==deletevalue); 
                                         console.log(elimina);
                                        eliminadato.invoice_id = null;
                                
                           
               
              
            },
            deleteInvoice(index){
                 Swal.fire({
                                title: '¿Estás seguro que deseas quitar la factura?',
                                text: 'Puedes agregar otra luego',
                                showCancelButton: true,
                                confirmButtonColor: '#d33',
                                cancelButtonColor: '#3085d6',
                                confirmButtonText: 'Si',
                                cancelButtonText: 'No'
                            }).then((result) => {
                                if (result.value) {
                                     let j = this.quotations.findIndex(w => w.index == index);
                                    this.transfers.data.forEach(w => { 
                                            if(w.invoice_id == index){
                                                w.invoice_id = null;
                                            }
                                    }); 
                                  

                                      this.datos.forEach(w => { 
                                            if(w.invoice_id == index){
                                                w.invoice_id = null;
                                            }
                                    }); 
                                    this.quotations.splice(j, 1);
                                }
                            });
              
            },
            saveQuotation(){
                    if(this.quotations.length>0){
                            this.$launchSuccess('Se guardo todo correctamente');

                                axios.post(`/api/administracion/facturacion/facturar`, {
                                    quotations: this.quotations,
                                    type:'tickets'
                                })
                                    .then(() => {
                                        ///eventHub.$emit('reloadAccommodation');
                                        this.quotations.forEach(w => { 
                                            
                                                w.transfers = [];
                                            
                                         }); 

                                        this.getTransfers();
                                        this.$launchSuccess(`Éxito al asociar factura`);
                                    })
                                    .catch(() => this.$launchError(`Error al asociar factura`));    


                    }else{
                            this.$launchError('No hay nada que guardar');
                    }
            },


            deleteWorker (id) {
                Swal.fire({
                    title: '¿Estás seguro que deseas eliminar este traslado?',
                    text: 'No podrás revertir esta acción',
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Si',
                    cancelButtonText: 'No'
                }).then((result) => {
                    if (result.value) {
                        this.form.delete('/api/administracion/personas/' + id).then(() => {
                            Swal.fire(
                                'Éxito',
                                'La persona fue eliminada correctamente',
                                'success'
                            );

                            this.getPersons();
                        }).catch(() => Swal.fire(
                            'Error',
                            'Error al eliminar persona',
                            'warning'
                        ));
                    }
                });
            }
        }
    }
</script>

<style>
  .v-application--wrap {
    min-height: 0vh !important;
  }
</style>
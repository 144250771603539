<template>
 <v-app>
  <section class="content">
      <div class="container-fluid">
          <div class="row">
              <div class="col-12">
                  <div class="card">
                      <loading v-if="loading"/>

                      <div class="card-header">
                          <div class="row align-items-center">
                              <h3 class="col card-title">Pasajes Perdidos</h3>

                          </div>
                      </div>

                       <v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Búsqueda"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
  <v-data-table
      :headers="headers"
      :items="datos"
      :search="search"
       :loading="loading"
       :items-per-page="30"
       no-results-text="No se han encontrado pasajes"
       no-data-text="No existen pasajes"
      class="elevation-1"
        loading-text="Cargando... Espere"
         :footer-props="{
           'items-per-page-text':'Pasajes Perdidos por página',
           'items-per-page-options': [20, 50, 100, -1]
      }"
    >
  <template v-slot:footer.page-text="items"> {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }} </template>
</v-data-table>
</v-card> 


                      <!--
                      <div class="card-body table-responsive p-0">
                          <table class="table table-hover">
                              <thead>
                                  <tr>
                                      <th class="align-middle">Aerolinea</th>
                                      <th class="align-middle">Código</th>
                                      <th class="align-middle">Monto</th>
                                      <th class="align-middle">Plus</th>
                                      <th class="align-middle">Tramo</th>
                                      <th class="align-middle">F. Vuelo</th>        
                                      <th class="align-middle">A. Origen</th>
                                      <th class="align-middle">A. Destino</th>
                                      <th class="align-middle">Origen Stock</th>
                                      <th class="align-middle">Rut</th> 
                                      <th class="align-middle">Rol</th> 
                                      <th class="align-middle">Fecha Marcado Perdido</th> 
                                      <th class="align-middle">Usuario Marcó Perdido</th> 
                                   
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr v-for="t in tickets.data">
                                      <td class="align-middle">{{ t.airline }}</td>
                                      <td class="align-middle">{{t.code}}</td>
                                      <td class="align-middle">{{ t.price | dotNumber}}</td>
                                      <td class="align-middle">{{ plustype(t.plus) }}</td>
                                      <td class="align-middle">{{ t.stretch }}</td>
                                      <td class="align-middle">{{ t.flight_date }}</td>
                                      <td class="align-middle">{{ t.airport1 }}</td>
                                      <td class="align-middle">{{ t.airport2 }}</td>
                                      <td class="align-middle">{{ origintype(t.is_carry)  }}</td>
                                      <td class="align-middle">{{t.rut}}</td>  
                                      <td class="align-middle">{{ t.role}}</td> 
                                       <td class="align-middle">{{ t.lost_datetime}}</td>   
                                          <td class="align-middle">{{ t.lost_name}}</td> 
                                                       
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                      <div class="card-footer">
                          <pagination :data="tickets" @pagination-change-page="getTickets"/>
                      </div>

                      -->
                  </div>
              </div>
          </div>

       
      </div>
  </section>
   </v-app>
</template>

<script>
    import Loading from "./Common/Loading";

    export default {
        components: { Loading},
        created () {
            this.$Progress.start();
            this.getTickets();
            this.$Progress.finish();
        },
        data () {
            return {
                loading: true,
                searchInput: null,
                tickets: { data:[]},
                form: new Form({
                    stock_ticket_id:null
                }),
                 search: '',
                headers: [
                {
                    text: 'Aerolinea',
                    align: 'start',
                    sortable: false,
                    value: 'aerolinea',
                },
                { text: 'Código', value: 'codigo' },
                { text: 'Monto', value: 'monto' },
                { text: 'OC', value: 'plus' },
                { text: 'Tramo', value: 'tramo' },
                { text: 'F. Vuelo', value: 'vuelo' },
                { text: 'A. Origen', value: 'origen' },
                { text: 'A. Destino', value: 'destino' },
                { text: 'Origen Stock', value: 'stock' },
                { text: 'Rut', value: 'rut' },
                { text: 'Rol', value: 'rol' },
                { text: 'Fecha Marcado Perdido', value: 'fecha' },
                { text: 'Usuario Marcó Perdido', value: 'usuario' },
                ],
                datos:[], 
            }
        },
        methods: {
            plustype(plus){
                if(plus==1){
                    return "Si";
                }
                return "No";
            },
            origintype(plus){
                if(plus==1){
                    return "Maleta";
                }
                return "Pasaje";
            },
             voucher (id) {
                Swal.fire({
                            title: '¿Estás seguro que deseas convertir este pasaje a Voucher?',
                            text: 'No podrás revertir esta acción',
                            showCancelButton: true,
                            confirmButtonColor: '#d33',
                            cancelButtonColor: '#3085d6',
                            confirmButtonText: 'Si',
                            cancelButtonText: 'No'
                        }).then((result) => {
                            if (result.value) {
                                this.form.stock_ticket_id = id;
                                this.form.post('/api/administracion/personas/pasajes/a-voucher')
                                .then(() => {
                                    this.$launchSuccess('Pasaje convertido a Voucher correctamente');
                                    this.$Progress.finish();
                                    this.getTickets();
                                })
                                .catch(() => {
                                    this.$Progress.fail();
                                    this.$launchError('Error al generar a voucher');
                                });
                                }
                        });  
            },
            getTickets (page = 1) {
                this.$Progress.start();
                this.loading = true;

                axios.get('/api/administracion/pasajes-perdidos')
                    //.then(({ data }) => this.tickets = data)
                     .then(data => {
                    this.tickets = data.data;
                     console.log(this.tickets);
                     console.log(this.tickets.data.length);
                        for(let i = 0; i < this.tickets.data.length; i++) {
                            var t = this.tickets.data[i];
                            var valor = this.$options.filters.dotNumber(t.price );
                            this.datos.push({aerolinea:t.airline,codigo:t.code,monto:"$"+valor,plus:t.oc,tramo:t.stretch,vuelo:t.flight_date,origen:t.airport1,destino:t.airport2,stock:this.origintype(t.is_carry) ,rut:t.rut,rol:t.role,id:t.id,fecha:t.lost_datetime,usuario:t.lost_name});
                        }
                       console.log(this.datos);
                     })
                    .catch(() => this.$launchError('Error al obtener listado de pasajes perdidos'))
                    .finally(() => {
                        this.$Progress.finish();
                        this.loading = false;
                    });
            },


        
        }
    }
</script>

<template>
    <div>
        <div class="row">
            <div class="col form-group">
                <label>Nombres</label>
                <input v-model="form.name" type="text" name="nombres" class="form-control"
                       :class="{ 'is-invalid': form.errors.has('name') }">
                <has-error :form="form" field="name"/>
            </div>

            <div class="col form-group">
                <label>Apellidos</label>
                <input v-model="form.last_name" type="text" name="apellidos"
                       class="form-control" :class="{ 'is-invalid': form.errors.has('last_name') }">
                <has-error :form="form" field="last_name"/>
            </div>
        </div>

        <div class="row">
         <div class="col form-group">
                <label>Cargo</label>
                <input v-model="form.role" type="text" name="role"
                       class="form-control" :class="{ 'is-invalid': form.errors.has('role') }">
                <has-error :form="form" field="role"/>
            </div>
        

         <div class="col form-group">
                <label>Telefono</label>
                <input v-model="form.phone" type="text" name="phone"
                       class="form-control" :class="{ 'is-invalid': form.errors.has('phone') }">
                <has-error :form="form" field="phone"/>
            </div>
        </div>

        <div class="row">
            <div class="col form-group">
                <label>Email</label>
                <input v-model="form.email" type="email" name="email"
                       class="form-control" :class="{ 'is-invalid': form.errors.has('email') }">
                <has-error :form="form" field="email"/>
            </div>

            <div class="col form-group">
                <label>Rut</label>
                <input v-model="form.rut" type="text" name="rut" placeholder="Sin puntos y con guión" maxlength = "10" minlength="9"
                       class="form-control" :class="{ 'is-invalid': form.errors.has('rut') }">
                <has-error :form="form" field="rut"/>
            </div>
        </div>



    </div>
</template>

<script>
    export default {
        props: {
            isEdition: {
                type: Boolean,
                default: false
            },
            form: Object
        },
        created () {
        },
        data () {
            return {

            }
        },
        methods: {
            isNumber: function(evt) {
                evt = (evt) ? evt : window.event;
                var charCode = (evt.which) ? evt.which : evt.keyCode;
                if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                    evt.preventDefault();;
                } else {
                    return true;
                }
            },
        }
    }
</script>

<template>
    <form @submit.prevent="saveDocument">
        <div class="row">
            <div class="col form-group">
                <label>Tipo de documento</label>
                <select class="form-control" v-model="form.document_type_id"
                        :class="{ 'is-invalid': form.errors.has('document_type_id') }">
                    <option :value="null" disabled>Escoja un tipo de documento</option>
                    <option :value="t.id" v-for="t in types" :disabled='t.attribute_id!=0 && $parent.worker.attribute_id==1'>{{ t.name }}</option>
                </select>
                <has-error :form="form" field="document_type_id"/>
            </div>

            <div class="col form-group" v-if="form.document_type_id==6 || form.document_type_id==7 || form.document_type_id==8 || form.document_type_id==9 || form.document_type_id==10">
                <label>Fecha de Inicio</label>
                <input v-model="form.date_start" type="date" name="inicio"
                       class="form-control" :class="{ 'is-invalid': form.errors.has('date_start') }">
                <has-error :form="form" field="date_start"/>
            </div>

             <div class="col form-group" v-if="form.document_type_id==6 || form.document_type_id==7 || form.document_type_id==8 || form.document_type_id==9 || form.document_type_id==10">
                <label>Fecha de Fin</label>
                <input v-model="form.date_end" type="date" name="fin"
                       class="form-control" :class="{ 'is-invalid': form.errors.has('date_end') }">
                <has-error :form="form" field="date_end"/>
            </div>


        </div>
        <div class="row">

           
                                        <div class="col form-group">
                                            <label>Archivo</label><br>
                                            <div class="custom-file">
                                                <input id="certificate" type="file" class="custom-file-input"
                                                       @change.prevent="upload" accept="application/pdf">
                                                <label class="custom-file-label" for="certificate">
                                                    {{ fileName ? fileName : 'Escoja un archivo' }}
                                                </label>
                                            </div>
                                        </div>
                            


             <div class="col form-group">
                <label>Observacion</label>
                <input v-model="form.observation" type="text" name="observacion"
                       class="form-control">
            </div>



        </div>

        <button type="submit" class="btn btn-primary float-right" :disabled="submit">
            <i class="fa fa-spinner fa-spin mr-2" v-if="submit"></i>
            {{ submit ? 'Guardando' : 'Guardar' }}
        </button>
    </form>
</template>

<script>
    export default {
        created () {
            this.getDocumentTypes();
        },
        data () {
            return {
                types: [],
                certificate:null,
                fileName:null,
                form: new Form({
                    document_type_id: null,
                    date_start: null,
                    date_end:null,
                    observation:null
                }),
                submit: false
            }
        },
        methods: {
            getDocumentTypes () {
                this.$Progress.start();
                this.$parent.loading = true;

                axios.get('/api/tipos-documentos-extra')
                    .then(({ data }) => {
                        this.types = data.data;
                        this.$Progress.finish();
                    })
                    .catch(() => {
                        this.$Progress.fail();
                        this.$launchError('Error al obtener listado de tipos de documentos');
                    })
                    .finally(() => this.$parent.loading = false);
            },
            upload (e) {
                const f = e.target.files[0];
                this.certificate = f;
                this.fileName = f.name;
            },

            saveDocument () {
                Swal.fire({
                    title: '¿Estás seguro que deseas guardar este documento?',
                    text: 'No podrás revertir esta acción',
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Si',
                    cancelButtonText: 'No'
                }).then((result) => {
                    if (result.value) {
                        /////
                        this.$Progress.start();
                        this.submit = true;
                        let formData = new FormData();
                        formData.append('document_type_id', this.form.document_type_id);
                        formData.append('date_start', this.form.date_start);
                        formData.append('date_end', this.form.date_end);
                        formData.append('fileName', this.fileName);
                        formData.append('certificate', this.certificate);
                        formData.append('observation', this.form.observation);

                        axios.post('/api/trabajadores/' + this.$route.params.id + '/documentosExtra', formData, {
                            headers: { 'Content-Type': 'multipart/form-data' }
                        })
                            .then(() => {
                                Swal.fire(
                                    'Éxito',
                                    'El documento fue registrado correctamente',
                                    'success'
                                );
                                this.form.document_type_id = null;
                                this.form.date_start = null;
                                this.form.date_end = null;
                                this.fileName = null;
                                this.form.observation = null;
                                this.certificate = null;
                                this.$Progress.finish();
                                this.form.reset();
                                this.submit =false;

                                
                            })
                            .catch(() => {
                                Swal.fire(
                                    'Error',
                                    'No se pudo registrar el documento',
                                    'warning'
                                )
                                this.$Progress.fail();
                                this.form.reset();
                                this.submit = false;
                            });

                        /////

                    }
                });
            }
        }
    }
</script>

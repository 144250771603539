<template>
    <div id="edit-ship-container">
        <!-- breadcrumb -->
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Editar embarcación</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link to="/">Inicio</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link to="/admin/embarcaciones">Embarcaciones</router-link>
                            </li>
                            <li class="breadcrumb-item active">Editar embarcación</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>

        <!-- edición -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col">
                        <div class="card">
                            <loading v-if="loading"/>

                            <div class="card-header">
                                <ul class="nav nav-pills">
                                    <li class="nav-item" v-for="t in tabs">
                                        <a class="nav-link" :class="{'active': tab === t.component}"
                                           @click="tab = t.component">
                                            {{ t.title }}
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div class="card-body">
                                <component :is="tab"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Certificates from "./EditShip/Certificates";
    import Headlines from "./EditShip/Headlines";
    import Loading from "./Common/Loading";
    import Specifications from "./EditShip/Specifications";
    import TechnicalSpecifications from "./EditShip/TechnicalSpecifications";
    import Blueprints from "./EditShip/Blueprints";

    export default {
        components: { Certificates, Headlines, Loading, Specifications, TechnicalSpecifications,Blueprints },
        computed: {
            id () {
                return this.$route.params.id;
            }
        },
        data () {
            return {
                loading: true,
                tab: 'Specifications',
                tabs: [
                    { title: 'Especificaciones', component: 'Specifications' },
                    { title: 'Certificados', component: 'Certificates' },
                    { title: 'Titulares', component: 'Headlines' },
                    { title: 'Esp. técnicas', component: 'TechnicalSpecifications' },
                    { title: 'Planos', component: 'Blueprints' }
                ]
            }
        }
    }
</script>

<template>
<v-app>
  <section class="content">
      <div class="container-fluid">
          <div class="row">
              <div class="col-12">
                  <div class="card">
                      <loading v-if="loading"/>

                      <!-- header -->
                      <div class="card-header">
                          <h3 class="card-title">Listado de embarcaciones</h3>

                          <div class="card-tools">
                              <button v-if="$gate.isJefeflota() || $gate.isJefeoperaciones() || $gate.isSupervisor() || $gate.isGerente() || $gate.isAdmin()"  type="button" class="btn btn-sm btn-primary" @click="creationModal">
                                  <i class="fa fa-plus-square"></i>
                                  Nueva embarcación
                              </button>
                          </div>
                      </div>


<v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Búsqueda"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
  <v-data-table
      :headers="headers"
      :items="datos"
      :search="search"
       :loading="loading"
       :items-per-page="30"
       no-results-text="No se han encontrado embarcaciones"
       no-data-text="No existen embarcaciones"
      class="elevation-1"
        loading-text="Cargando... Espere"
         :footer-props="{
           'items-per-page-text':'Embarcaciones por página',
           'items-per-page-options': [20, 50, 100, -1]
      }"
    >
 <template v-slot:item.accion="{ item, index }">

                                            


                                           <router-link :to="'/admin/embarcaciones/' + item.id"
                                                       v-tooltip="'Ficha de la embarcación'">
                                            
                                                 <v-icon small color="green">
                                          fa fa-info-circle
                                                </v-icon>
                                          </router-link>
                                          <span v-if="((item.supervisor_id === $user.user_data().id) && $gate.isSupervisor()) || $gate.isAdmin() || $gate.isGerente() || $gate.isPlanificacion() || $gate.isServiciotaller()">/</span>
                                          <router-link v-tooltip="'Editar embarcación'" v-if="((item.supervisor_id === $user.user_data().id) && $gate.isSupervisor()) || $gate.isAdmin() || $gate.isGerente() || $gate.isPlanificacion() || $gate.isServiciotaller()" :to="`/admin/embarcaciones/${item.id}/editar`">
                                              
                                               <v-icon small style="color: #3490DC !important;">
                                          fa fa-edit 
                                                </v-icon>
                                          </router-link>
                                          <span v-if="$gate.isSupervisor() || $gate.isAdmin() || $gate.isGerente() || $gate.isPlanificacion() || $gate.isServiciotaller()">/</span>
                                          <router-link v-if="$gate.isSupervisor() || $gate.isAdmin() || $gate.isGerente() || $gate.isPlanificacion() || $gate.isServiciotaller()" :to="`/admin/embarcaciones/${item.id}/revista`"
                                                       v-tooltip="'Ingresar revista'">
                                            
                                              <v-icon small color="orange">
                                          fas fa-hard-hat
                                                </v-icon>
                                          </router-link>
                                          <span v-if="$gate.isSupervisor() || $gate.isAdmin() || $gate.isGerente() || $gate.isPlanificacion() || $gate.isServiciotaller()">/</span>
                                          <router-link v-if="$gate.isSupervisor() || $gate.isAdmin() || $gate.isGerente() || $gate.isPlanificacion() || $gate.isServiciotaller()" :to="`/admin/embarcaciones/${item.id}/carena`"
                                                       v-tooltip="'Ingresar carena'">
                                            
                                              <v-icon small color="green">
                                          fa fa-wrench
                                                </v-icon>
                                          </router-link>
                                          <span v-if="$gate.isAdmin()">/</span>
                                          <a href="#" v-if="$gate.isAdmin()" @click="deleteShip(item.id)" v-tooltip="'Eliminar embarcación'">

                                           
                                                <v-icon small color="red">
                                          fa fa-trash
                                                </v-icon>
                                          </a>

                                                                                             
  </template>
  <template v-slot:footer.page-text="items"> {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }} </template>
</v-data-table>
</v-card> 
                      <!-- 
                      <div class="card-body table-responsive p-0">
                          <table class="table table-hover">
                              <thead>
                                  <tr>
                                      <th class="align-middle">Barco</th>
                                      <th class="align-middle">Código</th>
                                      <th class="align-middle">Matrícula</th>
                                      <th class="align-middle">Iden. llamada</th>
                                      <th class="align-middle">Habitabilidad máxima</th>
                                      <th class="align-middle">Última carena</th>
                                      <th class="align-middle">Última revista</th>
                                      <th class="align-middle">Supervisor</th>
                                      <th class="align-middle">Acciones</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr v-for="e in ships.data" :key="e.id">
                                      <td class="align-middle">{{ e.name }}</td>
                                      <td class="align-middle">{{ e.code }}</td>
                                      <td class="align-middle">{{ e.plate }}</td>
                                      <td class="align-middle">{{ e.call_identifier }}</td>
                                      <td class="align-middle">{{ e.capacity }}</td>
                                      <td class="align-middle">{{ e.last_careen | myDate }}</td>
                                      <td class="align-middle">{{ e.last_review | myDate }}</td>
                                      <td class="align-middle">
                                          <span v-if="e.supervisor">
                                            {{ e.supervisor.name }} {{ e.supervisor.last_name }}
                                          </span>
                                          <span v-else>-</span>
                                      </td>
                                      <td class="align-middle">
                                          <router-link :to="'/admin/embarcaciones/' + e.id"
                                                       v-tooltip="'Ficha de la embarcación'">
                                              <i class="fa fa-info-circle green"></i>
                                          </router-link>
                                          <span v-if="((e.supervisor_id === $user.user_data().id) && $gate.isSupervisor()) || $gate.isAdmin() || $gate.isGerente() || $gate.isPlanificacion() || $gate.isServiciotaller()">/</span>
                                          <router-link v-tooltip="'Editar embarcación'" v-if="((e.supervisor_id === $user.user_data().id) && $gate.isSupervisor()) || $gate.isAdmin() || $gate.isGerente() || $gate.isPlanificacion() || $gate.isServiciotaller()" :to="`/admin/embarcaciones/${e.id}/editar`">
                                              <i class="fa fa-edit blue"></i>
                                          </router-link>
                                          <span v-if="$gate.isSupervisor() || $gate.isAdmin() || $gate.isGerente() || $gate.isPlanificacion() || $gate.isServiciotaller()">/</span>
                                          <router-link v-if="$gate.isSupervisor() || $gate.isAdmin() || $gate.isGerente() || $gate.isPlanificacion() || $gate.isServiciotaller()" :to="`/admin/embarcaciones/${e.id}/revista`"
                                                       v-tooltip="'Ingresar revista'">
                                              <i class="fa fa-hard-hat orange"></i>
                                          </router-link>
                                          <span v-if="$gate.isSupervisor() || $gate.isAdmin() || $gate.isGerente() || $gate.isPlanificacion() || $gate.isServiciotaller()">/</span>
                                          <router-link v-if="$gate.isSupervisor() || $gate.isAdmin() || $gate.isGerente() || $gate.isPlanificacion() || $gate.isServiciotaller()" :to="`/admin/embarcaciones/${e.id}/carena`"
                                                       v-tooltip="'Ingresar carena'">
                                              <i class="fa fa-wrench green"></i>
                                          </router-link>
                                          <span v-if="$gate.isAdmin()">/</span>
                                          <a href="#" v-if="$gate.isAdmin()" @click="deleteShip(e.id)" v-tooltip="'Eliminar embarcación'">

                                              <i class="fa fa-trash red"></i>
                                          </a>
                                      </td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>

                     
                      <div class="card-footer">
                          <pagination :data="ships" @pagination-change-page="getShips"/>
                      </div>

                      -->
                  </div>
              </div>
          </div>

          <!-- Modal -->
          <div class="modal fade" id="form-modal" tabindex="-1" role="dialog" aria-labelledby="form-dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
              <div class="modal-dialog" role="document">
                  <div class="modal-content">
                      <div class="modal-header">
                          <h5 class="modal-title">Crear nueva embarcación</h5>
                          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                          </button>
                      </div>

                      <form @submit.prevent="createShip">
                          <div class="modal-body container">
                              <ship-form :form="form" :supervisors="supervisors"
                                         :ship-types="shipTypes"/>

                              <div class="modal-footer">
                                  <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                                  <button type="submit" class="btn btn-primary">Crear</button>
                              </div>
                          </div>
                      </form>
                  </div>
              </div>
          </div>
      </div>
  </section>
  </v-app>
</template>

<script>
    import Loading from "./Common/Loading";
    import ShipForm from "./Ships/Form";

    export default {
        components: { Loading, ShipForm },
        created () {
            this.$Progress.start();
            this.getShips();
            this.$Progress.finish();
        },
        data () {
            return {
                form: new Form({
                    id : '',
                    name: '',
                    code: '',
                    plate: '',
                    call_identifier: '',
                    capacity: '',
                    engines: '',
                    generators: '',
                    supervisor_id: null,
                    type_id: null
                }),
                loading: true,
                ships : {},
                shipTypes: null,
                supervisors: null,
                search:'',
                headers: [
                {
                    text: 'Barco',
                    align: 'start',
                    value: 'barco',
                },
                { text: 'Código', value: 'codigo' },
                { text: 'Matrícula', value: 'matricula' },
                { text: 'Iden. llamada', value: 'llamada' },
                { text: 'Habitabilidad máxima', value: 'maximo' },
                { text: 'Última carena', value: 'carena' },
                { text: 'Última revista', value: 'revista' },
                { text: 'Supervisor', value: 'supervisor' },
                { text: 'Acciones', value: 'accion', sortable: false },
                ],
                datos:[],
            }
        },
        methods: {
            getShips (page = 1) {
                this.loading = true;
                this.$Progress.start();

                axios.get('/api/embarcaciones')
                    //.then(({ data }) => this.ships = data.data)
                    .then(data => {
                    this.datos = [];
                    this.ships = data.data;
                     console.log(this.ships);
                        for(let i = 0; i < this.ships.data.length; i++) {
                            var t = this.ships.data[i];
                            var carena = this.$options.filters.myDate(t.last_careen ); 
                            var revista = this.$options.filters.myDate(t.last_review );  
                            var supervisor = 'Sin Supervisor';
                            if (t.supervisor !== null) {
                                supervisor = this.$options.filters.capitalize(t.supervisor.name)+" "+this.$options.filters.capitalize(t.supervisor.last_name );  
                            }
                            this.datos.push({barco:t.name ,codigo:t.code ,llamada:t.call_identifier,maximo:t.capacity,carena:carena,revista:revista,supervisor:supervisor,id:t.id,accion:true});
                        }
                       console.log(this.datos);
                       this.$Progress.finish();
                     })
                    .catch(() => this.$launchError('Error al obtener listado de embarcaciones'))
                    .finally(() => this.loading = false);

                this.$Progress.finish();
            },

            getSupervisors () {
                if (this.supervisors === null) {
                    axios.get('/api/supervisores').then(({ data }) => this.supervisors = data.data)
                        .catch(() => this.$launchError('Error al obtener listado de supervisores'));
                }
            },

            getShipTypes () {
                if (this.shipTypes === null) {
                    axios.get('/api/tipos-embarcaciones').then(({ data }) => this.shipTypes = data.data)
                        .catch(() => this.$launchError('Error al obtener listado de tipos de embarcaciones'));
                }
            },

            creationModal () {
                this.getSupervisors();
                this.getShipTypes();
                this.form.reset();
                $('#form-modal').modal('show');
            },

            createShip () {
                this.$Progress.start();

                this.form.post('/api/embarcaciones')
                    .then(() => {
                        $('#form-modal').modal('hide');
                        this.$launchSuccess('La embarcación fue creada correctamente')
                        this.$Progress.finish();
                        this.getShips();
                    })
                    .catch(() => {
                        this.$Progress.fail();
                        this.$launchError('Error al crear embarcación');
                    });
            },

            deleteShip (id) {
                Swal.fire({
                    title: '¿Estás seguro que deseas eliminar la embarcación?',
                    text: 'No podrás revertir esta acción',
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Si',
                    cancelButtonText: 'No'
                }).then((result) => {
                    if (result.value) {
                        this.form.delete('/api/embarcaciones/' + id).then(() => {
                            Swal.fire(
                                'Éxito',
                                'La embarcación fue eliminada correctamente',
                                'success'
                            );

                            this.getShips();
                        }).catch(() => Swal.fire(
                            'Error',
                            'No se pudo eliminar la embarcación',
                            'warning'
                        ));
                    }
                });
            }
        }
    }
</script>

<template>
    <div>
        <div v-if="hasSpecifications">
            <h4 class="mb-4">Características generales</h4>

            <table class="table">
                <tr>
                    <td class="align-middle"><strong>Armador:</strong></td>
                    <td class="align-middle">{{ specifications.shipowner | dash }}</td>
                </tr>

                <tr>
                    <td class="align-middle"><strong>Puerto de matrícula:</strong></td>
                    <td class="align-middle">{{ specifications.plate_port | dash }}</td>
                </tr>

                <tr>
                    <td class="align-middle"><strong>Tonelaje de Registro Grueso T.R.G.:</strong></td>
                    <td class="align-middle">{{ specifications.trg | dash }}</td>
                </tr>

                <tr>
                    <td class="align-middle"><strong>Bollard pull:</strong></td>
                    <td class="align-middle" v-if="specifications.bollard_pull">
                        {{ specifications.bollard_pull }} ton.
                    </td>
                    <td v-else>-</td>
                </tr>

                <tr>
                    <td class="align-middle"><strong>Desplazamiento liviano:</strong></td>
                    <td class="align-middle" v-if="specifications.light_displacement">
                        {{ specifications.light_displacement }} ton.
                    </td>
                    <td v-else>-</td>
                </tr>
            </table>

            <h4 class="mt-4 mb-4">Características de construcción</h4>

            <table class="table">
                <tr>
                    <td class="align-middle"><strong>Año de construcción:</strong></td>
                    <td class="align-middle">{{ specifications.year_of_construction | dash }}</td>
                </tr>

                <tr>
                    <td class="align-middle"><strong>País:</strong></td>
                    <td class="align-middle">{{ specifications.country | dash }}</td>
                </tr>

                <tr>
                    <td class="align-middle"><strong>Astilleros:</strong></td>
                    <td class="align-middle">{{ specifications.shipyards | dash }}</td>
                </tr>

                <tr>
                    <td class="align-middle"><strong>Eslora total:</strong></td>
                    <td class="align-middle" v-if="specifications.total_length">
                        {{ specifications.total_length }} mts.
                    </td>
                    <td v-else>-</td>
                </tr>

                <tr>
                    <td class="align-middle"><strong>Manga:</strong></td>
                    <td class="align-middle" v-if="specifications.sleeve">
                        {{ specifications.sleeve }} mts.
                    </td>
                    <td v-else>-</td>
                </tr>

                <tr>
                    <td class="align-middle"><strong>Puntal a cubierta principal:</strong></td>
                    <td class="align-middle" v-if="specifications.strut_to_main_deck">
                        {{ specifications.strut_to_main_deck }} mts.
                    </td>
                    <td v-else>-</td>
                </tr>

                <tr>
                    <td class="align-middle"><strong>Capacidad estanque combustible:</strong></td>
                    <td class="align-middle" v-if="specifications.fuel_tank_capacity">
                        {{ specifications.fuel_tank_capacity }} lts.
                    </td>
                    <td v-else>-</td>
                </tr>

                <tr>
                    <td class="align-middle"><strong>Capacidad estanque agua dulce:</strong></td>
                    <td class="align-middle" v-if="specifications.fresh_water_pond_capacity">
                        {{ specifications.fresh_water_pond_capacity }} lts.
                    </td>
                    <td v-else>-</td>
                </tr>

                <tr>
                    <td class="align-middle"><strong>Observaciones:</strong></td>
                    <td class="align-middle">{{ specifications.observations | dash }}</td>
                </tr>
            </table>

            <h4 class="mt-4 mb-4">Propulsión</h4>

            <table class="table">
                <tr>
                    <td class="align-middle"><strong>Motor principal:</strong></td>
                    <td class="align-middle">{{ specifications.main_engine | dash }}</td>
                </tr>

                <tr>
                    <td class="align-middle"><strong>Potencia:</strong></td>
                    <td class="align-middle">{{ specifications.power | dash }}</td>
                </tr>

                <tr>
                    <td class="align-middle"><strong>Serie motor:</strong></td>
                    <td class="align-middle">{{ specifications.motor_series | dash }}</td>
                </tr>

                <tr>
                    <td class="align-middle"><strong>Caja de transmisión:</strong></td>
                    <td class="align-middle">{{ specifications.transmission_box | dash }}</td>
                </tr>

                <tr>
                    <td class="align-middle"><strong>Serie caja de transmisión:</strong></td>
                    <td class="align-middle">{{ specifications.transmission_box_series | dash }}</td>
                </tr>

                <tr>
                    <td class="align-middle"><strong>Relación:</strong></td>
                    <td class="align-middle">{{ specifications.relation | dash }}</td>
                </tr>

                <tr>
                    <td class="align-middle"><strong>Velocidad de crucero:</strong></td>
                    <td class="align-middle" v-if="specifications.cruising_speed">
                        {{ specifications.cruising_speed }} nudos.
                    </td>
                    <td v-else>-</td>
                </tr>

                <tr>
                    <td class="align-middle"><strong>Consumo de combustible:</strong></td>
                    <td class="align-middle" v-if="specifications.fuel_consumption">
                        {{ specifications.fuel_consumption }} litros / hora.
                    </td>
                    <td v-else>-</td>
                </tr>

                <tr>
                    <td class="align-middle"><strong>Tipo de hélice:</strong></td>
                    <td class="align-middle">{{ specifications.propeller_type | dash }}</td>
                </tr>
            </table>
        </div>
        <span v-else><i>No se han ingresado especificaciones técnicas a esta embarcación.</i></span>
    </div>
</template>

<script>
    export default {
        computed: {
            hasSpecifications () {
                return this.specifications !== null;
            }
        },
        created () {
            this.getTechnicalSpecifications();
        },
        data () {
            return {
                specifications: null
            }
        },
        methods: {
            getTechnicalSpecifications () {
                this.$Progress.start();
                this.loading = true;

                axios.get('/api/embarcaciones/' + this.$route.params.id + '/especificaciones-tecnicas')
                    .then(({ data }) => {
                        this.specifications = data.data;
                        this.$Progress.finish();
                    })
                    .catch(() => {
                        this.$launchError('Error al obtener especificaciones técnicas de embarcación');
                        this.$Progress.fail();
                    })
                    .finally(() => this.loading = false);
            }
        }
    }
</script>

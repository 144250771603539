<template>
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <!-- header -->
            <div class="modal-header">
                <h5 class="modal-title">{{ title }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <!-- body -->
            <div class="modal-body container">
                <!-- alerta -->
            
                <!-- tabla -->
                
                <div class="table-responsive p-0">
                    <table class="table table-hover" v-if="theworkers.length>0">
                        <thead>
                            <tr>
                                <th class="align-middle"></th>
                                <th class="align-middle">Código Pasaje</th>
                                <th class="align-middle">Nombre</th>
                                <th class="align-middle">Rut</th>
                                <th class="align-middle">Rol</th>
                               
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="w in theworkers" :class="{ 'table-danger': w.with_emergency }" v-if="w.deleted_at==null">
                                <td class="align-middle">
                                
                                   <!-- <input :value="w.id" type="checkbox" v-model="form[w.id].selected"
                                           :disabled="form[w.id].enabled==false">-->

                                     <input :value="w.id" type="checkbox" v-model="form[w.id].selected"
                                           v-if="onlyOne === false" :disabled="form[w.id].enabled==false">
                                    <input :value="w.id" type="radio" v-model="onlyOneSelected" v-else :disabled="form[w.id].enabled==false" >
                                  
                                </td>
                                 <td class="align-middle" v-if="w.intransfer!=null">PJ{{w.intransfer.ticket_group_id}}</td>
                                <td class="align-middle" v-else>Sin Pasaje</td>
                                <td class="align-middle">{{ w.name }} {{ w.last_name}}</td>
                                <td class="align-middle">{{ w.rut }}</td>
                                <td class="align-middle">{{ w.roles}}</td>
                               
                            </tr>
                        </tbody>
                    </table>

                    <p class="text-center mb-0" v-else>
                        <i>No hay tripulantes disponibles para asociar a este traslado.</i>
                    </p>
                </div>
            </div>

            <!-- footer -->
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                <button type="button" class="btn btn-primary" @click="save">
                    Guardar
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import eventHub from "../../../eventHub";

    export default {
        props: ['ticketId', 'onlyOne','crewId'],
        computed: {
            title () {
                return 'Asociar tripulantes a pasaje PJ'+this.ticketId;
            },

        },
        created () {
            this.getWorkers();
        },
        data () {
            return {
                theworkers:{},
                onlyOneSelected: null,
                form: {}
            }
        },
        methods: {
            fillForm () {
                this.theworkers.forEach(w => { 
                if(w.used == 1){
                    this.form[w.id] = { 
                    id: w.id,
                    selected: true,
                    enabled: true
                }
                if(this.onlyOne){
                    this.onlyOneSelected = w.id;
                }
                }if(w.used==0){
                    this.form[w.id] = { 
                    id: w.id,
                    selected: true,
                    enabled:false
                }
                }
                if(w.used==2){
                    this.form[w.id] = { 
                    id: w.id,
                    selected: false,
                    enabled:true
                }}
                });
            },
            save(){
                  let elticket = this.$parent.quotation.tickets.find(t => t.id === this.ticketId);
                    console.log(elticket)
                 
                 

                  if(elticket.flight_stretch_id==1){
                      this.newcalc(this.ticketId,'ida',1);
                  }
                  if(elticket.flight_stretch_id==2){
                      this.newcalc(this.ticketId,'vuelta',1);
                  }

            

                  
                //  elticket.edited = 1;
                //console.log(this.$parent.quotation.tickets[elticket]);

                if(this.onlyOneSelected!=null){
                    
                     console.log(this.$parent.quotation.tickets);
                     axios.post(`/api/administracion/pasajes/asociar-trabajador`, {
                        ticket_id: this.ticketId,
                        worker_id: this.onlyOneSelected,
                        extra_price:elticket.extra_price,
                        unitary_extra_price:elticket.unitary_extra_price,
                        total_price:elticket.total_price
                    })
                        .then(() => {
                            eventHub.$emit('reloadQuotationWorkers');
                            this.$launchSuccess(`Éxito al asociar pasaje`);
                        })
                        .catch(() => this.$launchError(`Error al asociar pasaje`));
                }else{
                    let w = [];

                    Object.keys(this.form).forEach(k => {
                        let i = this.form[k];

                         w.push({ worker_id: i.id,selected:i.selected,enabled:i.enabled });
                    });
                   // console.log(w);

                    console.log(this.$parent.quotation.tickets);
                    axios.post(`/api/administracion/pasajes/asociar-trabajadores`, {
                        ticket_id: this.ticketId,
                        workers: w,
                        tickets:this.$parent.quotation.tickets
                    })
                        .then(() => {
                            eventHub.$emit('reloadQuotationWorkers');
                            this.$launchSuccess(`Éxito al asociar pasaje`);
                        })
                        .catch(() => this.$launchError(`Error al asociar pasaje`));
                }
                   
            },

                newcalc(ticketid,tipo,editado){



                   let elticket = this.$parent.quotation.tickets.findIndex(t => t.id === ticketid);
                   let value = 0;
                   let suma = 0;


                       /*this.$parent.quotation.crew.workers.forEach((t, i) => {                      
                            if(tipo=='ida' && t.ticketida!=null){
                                if(t.ticketida.ticket_group_id === ticketid){
                                    if(t.ticketida.penalty!='' && t.ticketida.penalty!=null){
                                        suma = parseInt(suma) + parseInt(t.ticketida.penalty);  
                                    }else{
                                        suma = parseInt(suma) + 0;  
                                    }        
                                    }
                            }
                            if(tipo=='vuelta' && t.ticketvuelta!=null){
                                if(t.ticketvuelta.ticket_group_id === ticketid){
                                    if(t.ticketvuelta.penalty!='' && t.ticketvuelta.penalty!=null){
                                        suma = parseInt(suma) + parseInt(t.ticketvuelta.penalty);  
                                    }else{
                                        suma = parseInt(suma) + 0;  
                                    }          
                                    }
                            }  
                        });*/


                    if(this.$parent.quotation.tickets[elticket].payment_type==null){
                        let many = 0;
                            Object.keys(this.form).forEach(k => {
                                let i = this.form[k];
                                if(i.selected===true){

                                    let t =  this.$parent.quotation.crew.workers.find(j => j.worker_id == i.id);

                                    if(tipo=='ida' && t.ticketida!=null){
                                        if(t.ticketida.ticket_group_id === ticketid){
                                            if(t.ticketida.penalty!='' && t.ticketida.penalty!=null){
                                                suma = parseInt(suma) + parseInt(t.ticketida.penalty);  
                                            }else{
                                                suma = parseInt(suma) + 0;  
                                            }        
                                            }
                                    }
                                    if(tipo=='vuelta' && t.ticketvuelta!=null){
                                        if(t.ticketvuelta.ticket_group_id === ticketid){
                                            if(t.ticketvuelta.penalty!='' && t.ticketvuelta.penalty!=null){
                                                suma = parseInt(suma) + parseInt(t.ticketvuelta.penalty);  
                                            }else{
                                                suma = parseInt(suma) + 0;  
                                            }          
                                            }
                                    }  


                                    many=many+1;
                                }
            
                            });   
                        
                        
                        this.$parent.quotation.tickets[elticket].passengers = many;
                        let sumatoria = parseInt(suma) +  (parseInt(this.$parent.quotation.tickets[elticket].dearness) + parseInt(this.$parent.quotation.tickets[elticket].penalty) + (parseInt(this.$parent.quotation.tickets[elticket].price) * parseInt( this.$parent.quotation.tickets[elticket].passengers))); 
                        this.$parent.quotation.tickets[elticket].extra_price = parseInt(sumatoria);
                        if(parseInt(this.$parent.quotation.tickets[elticket].passengers)>0){
                            this.$parent.quotation.tickets[elticket].unitary_extra_price = parseInt(this.$parent.quotation.tickets[elticket].extra_price) / parseInt( this.$parent.quotation.tickets[elticket].passengers);
                        }else{
                            this.$parent.quotation.tickets[elticket].unitary_extra_price = 0;
                        }                 
                        this.$parent.quotation.tickets[elticket].total_price = parseInt(sumatoria);  
                        this.$parent.quotation.tickets[elticket].edited = editado;
                   }


                   if(this.$parent.quotation.tickets[elticket].payment_type==1){
                        this.$parent.quotation.tickets[elticket].temporal_extra_price = parseInt(suma);
                        let sumatoria = (parseInt(suma) + parseInt(this.$parent.quotation.tickets[elticket].dearness) + parseInt(this.$parent.quotation.tickets[elticket].penalty) + parseInt(this.$parent.quotation.tickets[elticket].price));
                        if(parseInt(sumatoria) > parseInt(this.$parent.quotation.tickets[elticket].payment_use_price)){
                              this.$parent.quotation.tickets[elticket].extra_price = parseInt(sumatoria) - parseInt(this.$parent.quotation.tickets[elticket].payment_use_price) ;
                              if(parseInt(this.$parent.quotation.tickets[elticket].passengers)>0){
                                  this.$parent.quotation.tickets[elticket].unitary_extra_price = parseInt(this.$parent.quotation.tickets[elticket].extra_price);
                              }else{
                                this.$parent.quotation.tickets[elticket].unitary_extra_price = 0;
                              }               
                        }else{
                              this.$parent.quotation.tickets[elticket].extra_price = 0;
                              this.$parent.quotation.tickets[elticket].unitary_extra_price = 0; 
                        }
                        this.$parent.quotation.tickets[elticket].total_price = parseInt(sumatoria);  
                        this.$parent.quotation.tickets[elticket].edited = editado;
                   }

                      //  console.log("a");
                     //console.log(this.$parent.quotation.tickets[elticket]);
                   if(this.$parent.quotation.tickets[elticket].payment_type==2){
                      // if(this.$parent.quotation.tickets[elticket].passengers===0){
                        
                            let many = 0;
                            Object.keys(this.form).forEach(k => {
                                let i = this.form[k];
                                if(i.selected===true){

                                    let t =  this.$parent.quotation.crew.workers.find(j => j.worker_id == i.id);

                                    if(tipo=='ida' && t.ticketida!=null){
                                        if(t.ticketida.ticket_group_id === ticketid){
                                            if(t.ticketida.penalty!='' && t.ticketida.penalty!=null){
                                                suma = parseInt(suma) + parseInt(t.ticketida.penalty);  
                                            }else{
                                                suma = parseInt(suma) + 0;  
                                            }        
                                            }
                                    }
                                    if(tipo=='vuelta' && t.ticketvuelta!=null){
                                        if(t.ticketvuelta.ticket_group_id === ticketid){
                                            if(t.ticketvuelta.penalty!='' && t.ticketvuelta.penalty!=null){
                                                suma = parseInt(suma) + parseInt(t.ticketvuelta.penalty);  
                                            }else{
                                                suma = parseInt(suma) + 0;  
                                            }          
                                            }
                                    }  

                                    many=many+1;
                                }
            
                            });
                          
                           this.$parent.quotation.tickets[elticket].passengers = many;
                      // }
                        if(many>0){
                            this.$parent.quotation.tickets[elticket].temporal_extra_price = parseInt(suma);
                            let sumatoria = (parseInt(suma) + parseInt(this.$parent.quotation.tickets[elticket].dearness) + parseInt(this.$parent.quotation.tickets[elticket].penalty) + (parseInt(this.$parent.quotation.tickets[elticket].price) * parseInt( this.$parent.quotation.tickets[elticket].passengers)));
                            if(parseInt(sumatoria) > parseInt(this.$parent.quotation.tickets[elticket].payment_use_price)){
                                this.$parent.quotation.tickets[elticket].extra_price = parseInt(sumatoria) - parseInt(this.$parent.quotation.tickets[elticket].payment_use_price) ;
                                if(parseInt(this.$parent.quotation.tickets[elticket].passengers)>0){
                                    this.$parent.quotation.tickets[elticket].unitary_extra_price = parseInt(this.$parent.quotation.tickets[elticket].extra_price) / parseInt(this.$parent.quotation.tickets[elticket].passengers) ;
                                }else{
                                    this.$parent.quotation.tickets[elticket].unitary_extra_price = 0;
                                }
                            }else{
                                this.$parent.quotation.tickets[elticket].extra_price = 0;
                                this.$parent.quotation.tickets[elticket].unitary_extra_price = 0;
                            }
                            this.$parent.quotation.tickets[elticket].total_price = parseInt(sumatoria);  
                            this.$parent.quotation.tickets[elticket].edited = editado;
                        }else{
                            let sumatoria =  parseInt(this.$parent.quotation.tickets[elticket].dearness) + parseInt(this.$parent.quotation.tickets[elticket].penalty);
                            if(parseInt(sumatoria) > parseInt(this.$parent.quotation.tickets[elticket].payment_use_price)){
                              this.$parent.quotation.tickets[elticket].extra_price = parseInt(sumatoria) - parseInt(this.$parent.quotation.tickets[elticket].payment_use_price) ;
                            }else{
                              this.$parent.quotation.tickets[elticket].extra_price = 0;
                            }
                            this.$parent.quotation.tickets[elticket].unitary_extra_price = 0;
                            this.$parent.quotation.tickets[elticket].total_price = parseInt(sumatoria);  
                            this.$parent.quotation.tickets[elticket].edited = editado;
                        }
                        
                   }
                  // console.log(this.$parent.quotation.tickets[elticket]);
                  
            },

             getWorkers () {
                axios.get('/api/administracion/tripulantes/pasajes/'+this.ticketId+'/'+this.crewId)
                    .then(({ data }) => {
                       // console.log(data);
                        this.theworkers = data;
                        this.fillForm();
                        this.$Progress.finish();
                    })
                    .catch(() => {
                        this.$launchError('Error al obtener información de cotización');
                        this.$Progress.fail();
                    });
            },
        },
        watch: {
           /* workers () {
                this.form = {};
                this.fillForm();
            }*/
        }
    }
</script>

<template>
    <form @submit.prevent="saveDocument">
        <div class="row">
            <div class="col form-group">
                <label>Tipo de documento</label>
                <select class="form-control" v-model="form.document_type_id"
                        :class="{ 'is-invalid': form.errors.has('document_type_id') }">
                    <option :value="null" disabled>Escoja un tipo de documento</option>
                    <option :value="t.id" v-for="t in types">{{ t.name }}</option>
                </select>
                <has-error :form="form" field="document_type_id"/>
            </div>

            <div class="col form-group">
                <label>Fecha de vencimiento</label>
                <input v-model="form.expiration_date" type="date" name="vencimiento"
                       class="form-control" :class="{ 'is-invalid': form.errors.has('expiration_date') }">
                <has-error :form="form" field="expiration_date"/>
            </div>
        </div>

        <button type="submit" class="btn btn-primary float-right" :disabled="submit">
            <i class="fa fa-spinner fa-spin mr-2" v-if="submit"></i>
            {{ submit ? 'Guardando' : 'Guardar' }}
        </button>
    </form>
</template>

<script>
    export default {
        created () {
            this.getDocumentTypes();
        },
        data () {
            return {
                types: [],
                form: new Form({
                    document_type_id: null,
                    expiration_date: null
                }),
                submit: false
            }
        },
        methods: {
            getDocumentTypes () {
                this.$Progress.start();
                this.$parent.loading = true;

                axios.get('/api/tipos-documentos')
                    .then(({ data }) => {
                        this.types = data.data;
                        this.$Progress.finish();
                    })
                    .catch(() => {
                        this.$Progress.fail();
                        this.$launchError('Error al obtener listado de tipos de documentos');
                    })
                    .finally(() => this.$parent.loading = false);
            },

            saveDocument () {
                Swal.fire({
                    title: '¿Estás seguro que deseas guardar este documento?',
                    text: 'No podrás revertir esta acción',
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Si',
                    cancelButtonText: 'No'
                }).then((result) => {
                    if (result.value) {
                        this.submit = true;
                        this.$Progress.start();

                        this.form.post('/api/trabajadores/' + this.$route.params.id + '/documentos').then(() => {
                            Swal.fire(
                                'Éxito',
                                'El documento fue guardado correctamente',
                                'success'
                            );

                            this.$Progress.finish();
                            this.form.reset();
                        }).catch(() => {
                            Swal.fire(
                                'Error',
                                'No se pudo guardar el documento',
                                'warning'
                            );
                            this.$Progress.fail();
                        }).finally(() => this.submit = false);
                    }
                });
            }
        }
    }
</script>

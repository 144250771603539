<template>
    <div>
        <h4 class="mb-4">EPP</h4>

        <div class="table-responsive" v-if="hasDeliveries">
            <table class="table table hover">
                <thead>
                    <tr>
                        <th class="align-middle">Nombre</th>
                        <th class="align-middle" v-for="w in workers">{{ w.worker.name }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="e in deliveries">
                        <td class="align-middle">{{ e.epp.item.name }}</td>
                        <td class="align-middle" v-for="w in workers">
                            {{ getSize(e.epp.id, w.worker.id) }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <span v-else><i>No se añadieron EPP a la solicitud de abastecimiento.</i></span>
    </div>
</template>

<script>
    export default {
        computed: {
            hasDeliveries () {
                return this.deliveries.length > 0;
            },
            deliveries () {
                return this.$parent.supplyRequest.epp_deliveries;
            },
            workers () {
                return this.$parent.workers.filter(w => w.filtered === 0);
            }
        },
        methods: {
            getSize (eppId, workerId) {
                const e = this.deliveries
                    .find(d => d.epp.id === eppId && d.worker_id === workerId);

                return e ? (e.epp.name === '' ? 'STD': e.epp.name) : '-';
            }
        }
    }
</script>
